<template>
  <v-card class="justify-space-between">
    <v-card-title class="text-h6"> Export Dataset </v-card-title>
    <v-spacer></v-spacer>
    <v-card-subtitle
      >Download a Flow generated dataset. By default, all raster files
      associated with the dataset will be set to download.
      <span class="d-block pt-2"
        >If the dataset is a time series split into multiple files, you may
        optionally select a range of years to download using the slider.</span
      >
      <span
        v-if="inputDataset && inputDataset.DatasetSize > 2e9"
        class="d-block pt-2"
        ><strong>Tip: </strong>For larger datasets like this one, try selecting
        a smaller range if the download fails.</span
      >
    </v-card-subtitle>
    <v-form class="px-6">
      <v-text-field
        outlined
        label="Input Raster Dataset"
        :value="inputDataset ? inputDataset.DatasetName : inputDataset"
        readonly
        :disabled="disableNewInputDataset"
        @focus="openDatasetSelectorOverlay"
        hide-details
      ></v-text-field>
      <v-overlay
        v-model="dataSelectorOverlay"
        opacity="0.8"
        class="selector-overlay"
        :dark="false"
      >
        <v-row class="d-flex justify-center">
          <v-col cols="11" sm="6" md="10" lg="10">
            <dataset-selector
              :single-select="true"
              :selection-mode="true"
              :crudActions="false"
              :batch-select="false"
              :dataTableHeight="300"
              :active-project-tag="false"
              :initial-type-filters="['Raster']"
              @update-dataset="updateDataset"
            ></dataset-selector>
          </v-col>
        </v-row>
        <div class="text-center">
          <v-btn text color="success" @click="dataSelectorOverlay = false"
            >Add</v-btn
          >
          <v-btn text color="warning" @click="closeDatasetSelector()"
            >cancel</v-btn
          >
        </div>
      </v-overlay>

      <v-card v-if="datesAvailable" class="my-5">
        <v-row v-if="datesAvailable.length > 0" class="d-flex justify-center">
          <v-card-subtitle class="ml-2"
            >Available Years - Range Selected:
            <span class="font-weight-bold"
              >{{ datesSelected[0] }} - {{ datesSelected[1] }}</span
            ></v-card-subtitle
          >
        </v-row>
        <v-row class="px-6" v-if="datesAvailable.length > 1">
          <v-range-slider
            :min="min"
            :max="max"
            v-model="datesSelected"
            ticks="always"
            tick-size="4"
            :color="$store.state.settings.appColor"
            track-color="light-grey"
          >
            <template v-slot:prepend>
              <p class="text-caption mt-1">
                {{ datesAvailable[0].sliderDate }}
              </p>
            </template>

            <template v-slot:append>
              <p class="text-caption mt-1">
                {{ datesAvailable[datesAvailable.length - 1].sliderDate }}
              </p>
            </template>
          </v-range-slider>
        </v-row>
      </v-card>
    </v-form>

    <v-card
      v-if="uri"
      class="d-flex flex-column justify-center align-center px-6 elevation-0"
    >
      <v-card-subtitle class="py-0">
        If the download has not started automatically, please click below.
      </v-card-subtitle>
      <v-card-actions class="pt-0">
        <v-btn
          color="blue"
          class="d-flex justify-center text-capitalize"
          text
          @click.prevent="downloadURI(uri)"
          >Download Now</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-divider v-if="uri"></v-divider>
    <v-card-actions class="d-flex justify-center">
      <v-btn v-if="dataset" color="warning" text @click="closeExportTool()">
        Cancel
      </v-btn>
      <v-btn
        text
        @click="downloadDataset"
        :disabled="!inputDataset || (datesAvailable && !datesSelected.length)"
        :loading="toolRunning"
        >Run</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { exportDataset } from "@/api/geoprocessing.js";
import DatasetSelector from "@/components/data/DatasetSelector.vue";

export default {
  name: "ExportDatasetTool",

  components: { DatasetSelector },

  props: {
    dataset: { type: Object, default: null },
  },

  data() {
    return {
      inputDataset: null,
      disableNewInputDataset: false,
      startDate: null,
      endDate: null,
      min: 0,
      max: 1,
      datesAvailable: [],
      datesSelected: [],
      dataSelectorOverlay: false,
      isLoading: false,
      exportTool: false,
      toolRunning: false,
      uri: null,
    };
  },
  methods: {
    openDatasetSelectorOverlay() {
      this.dataSelectorOverlay = true;
    },
    closeDatasetSelector() {
      this.dataSelectorOverlay = false;
    },

    closeExportTool() {
      this.$emit("close-export-dialog");
    },
    updateDataset(dataset) {
      this.inputDataset = dataset;
      if (dataset.DateRange !== "N/A") {
        this.datasetYears(dataset);
      } else {
        this.datesAvailable = null;
      }
      this.dataSelectorOverlay = false;
    },

    datasetYears(dataset) {
      let yearRange = dataset.DateRange.split("/");
      let startYear = yearRange[0];
      let endYear = yearRange[1];

      // min to end date
      const range = (start, stop, step) =>
        Array.from(
          { length: (stop - start) / step + 1 },
          (_, i) => start + i * step
        );

      this.datesAvailable = range(
        parseInt(startYear.split("-")[0]),
        endYear.split("-")[0],
        1
      );

      this.min = this.datesAvailable[0];
      this.max = this.datesAvailable[this.datesAvailable.length - 1];

      this.datesAvailable = this.datesAvailable.map((date) => ({
        sliderDate: date,
        date: `${date.toString()}-${startYear.split("-")[1]}-${
          startYear.split("-")[2]
        }`,
      }));

      this.datesSelected = [
        this.datesAvailable[0].sliderDate,
        this.datesAvailable[this.datesAvailable.length - 1].sliderDate,
      ];
    },

    downloadURI(uri) {
      let link = document.createElement("a");
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    downloadDataset() {
      const findDate = (datesAvailable, sliderDate) => {
        return datesAvailable.find((date) => {
          return date.sliderDate === sliderDate;
        });
      };

      this.toolRunning = true;
      let dataset = this.inputDataset;

      let requestBody = {
        DatasetOwner: dataset.DatasetOwner,
        DatasetName: dataset.DatasetName,
      };

      // Add start and end dates to request body for time independent datasets
      if (this.datesAvailable) {
        requestBody.DateRangeStart = findDate(
          this.datesAvailable,
          this.startDate
        ).date;
        requestBody.DateRangeEnd = findDate(
          this.datesAvailable,
          this.endDate
        ).date;
      }

      exportDataset(requestBody)
        .then((response) => {
          if (response.Warnings) {
            this.$showAlert({
              text: response.Warnings,
              type: "warning",
            });
          }
          let uri = response.ZipUrl;
          this.downloadURI(uri);
          this.uri = response.ZipUrl;
        })
        .catch((error) => {
          if (error) {
            this.$showAlert({
              text: error,
              type: "error",
            });
          }
          if (error.response.data.Errors) {
            this.$showAlert({
              text: error.response.data.Errors,
              type: "error",
            });
          }
        })
        .finally(() => {
          this.toolRunning = false;
        });
    },
  },

  watch: {
    datesSelected() {
      if (this.datesSelected.length === this.datesAvailable.length) {
        this.startDate = null;
        this.endDate = null;
      }
      // if single year is selected
      else if (this.datesSelected.length === 1) {
        this.startDate = this.datesSelected[0];
        this.endDate = this.datesSelected[0];
      }
      // if multiple years are selected
      else {
        this.startDate = this.datesSelected[0];
        this.endDate = this.datesSelected[this.datesSelected.length - 1];
      }
    },
  },

  // define datasetSelector as a dependency
  beforeCreate() {
    this.$options.components.DatasetSelector =
      require("@/components/data/DatasetSelector.vue").default;
  },

  created() {
    if (this.dataset) {
      this.updateDataset(this.dataset);
      this.disableNewInputDataset = true;
    }
  },
};
</script>

<template>
  <v-card elevation="0" class="content-wrapper">
    <v-card-title
      >{{ toolDefinition.ExpressionName.replace("CAPI ", "") }}
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            :color="showToolHelp ? $store.state.settings.appColor : 'grey'"
            dark
            v-bind="attrs"
            v-on="on"
            icon
            @click="showToolHelp = !showToolHelp"
          >
            <v-icon small> mdi-information </v-icon>
          </v-btn>
        </template>
        <span>{{ showToolHelp ? "Hide tool help" : "Show tool help" }}</span>
      </v-tooltip></v-card-title
    >

    <v-card-subtitle v-show="showToolHelp">
      {{ toolDescription }}</v-card-subtitle
    >

    <div class="content-container">
      <v-subheader class="pl-10 text--black font-weight-bold"
        >Inputs:</v-subheader
      >

      <!-- change analysis tool -->
      <v-card-text
        v-if="toolDefinition.ExpressionName.includes('Change Analysis')"
        class="inputs-container"
      >
        <!-- input devices from expression -->

        <!-- form with some dynamically generated inputs based on previous responses -->

        <v-form>
          <!-- dataset/layer combination -->
          <v-row dense class="d-flex flex-wrap" justify="space-between">
            <v-col cols="12">
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1"
                  >Land Cover Dataset</v-card-subtitle
                >
                <v-select
                  class="text-body-1 font-weight-medium black--text pl-2 py-2"
                  item-text="displayName"
                  return-object
                  v-model="changeAnalysisInputs.landCoverDatasetInput"
                  :items="landCoverDatasets"
                  outlined
                  :color="$store.state.settings.appColor"
                  hide-details
                  dense
                  @change="getLandCoverDataset"
                />
              </v-card>
            </v-col>

            <!-- <v-select
              v-show="false"
              v-model="
                updatedInputDeviceDefinitions['Pre-conversion Land Cover Types']
                  .InputDeviceValue.feature_name
              "
            /> -->
          </v-row>

          <!-- categories pre/post type -->
          <v-row dense class="d-flex flex-wrap" justify="space-between">
            <v-col cols="12" md="6">
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1"
                  >Post Conversion Type(s)</v-card-subtitle
                >

                <v-select
                  class="text-body-1 font-weight-medium black--text pl-2 py-2"
                  multiple
                  v-model="changeAnalysisInputs.postConversionInputs"
                  :items="
                    filteredTypes(
                      landCoverLayerSymbology,
                      changeAnalysisInputs.preConversionInputs
                    )
                  "
                  outlined
                  :color="$store.state.settings.appColor"
                  hide-details
                  dense
                  @blur="
                    selectAll(
                      'preConversionInputs',
                      filteredTypes(
                        landCoverLayerSymbology,
                        changeAnalysisInputs.postConversionInputs
                      )
                    )
                  "
                />
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1"
                  >Pre Conversion Type(s)</v-card-subtitle
                >
                <v-select
                  class="text-body-1 font-weight-medium black--text pl-2 py-2"
                  v-model="changeAnalysisInputs.preConversionInputs"
                  multiple
                  :items="
                    filteredTypes(
                      landCoverLayerSymbology,
                      changeAnalysisInputs.postConversionInputs
                    )
                  "
                  outlined
                  :color="$store.state.settings.appColor"
                  hide-details
                  clearable
                  dense
                />
              </v-card>
            </v-col>
          </v-row>
          <v-row dense class="d-flex flex-wrap" justify="space-between">
            <v-col cols="12" md="6">
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1"
                  >Study Area Dataset</v-card-subtitle
                >
                <v-select
                  class="text-body-1 font-weight-medium black--text pl-2 py-2"
                  :items="availableStudyAreas"
                  item-text="DatasetName"
                  return-object
                  v-model="changeAnalysisInputs.studyAreaDatasetInput"
                  outlined
                  :color="$store.state.settings.appColor"
                  hide-details
                  dense
                  @change="
                    (selectedDataset) => {
                      this.changeAnalysisInputs.studyAreaLayerOptions =
                        selectedDataset.FilterFieldValues.sort();
                    }
                  "
                />
              </v-card>
            </v-col>

            <v-col cols="12" md="6">
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1">AOI</v-card-subtitle>

                <v-select
                  :disabled="!changeAnalysisInputs.studyAreaDatasetInput"
                  class="text-body-1 font-weight-medium black--text pl-2 py-2"
                  v-model="changeAnalysisInputs.studyAreaLayerInput"
                  :items="changeAnalysisInputs.studyAreaLayerOptions"
                  outlined
                  :color="$store.state.settings.appColor"
                  hide-details
                  dense
                />
              </v-card>
            </v-col>
          </v-row>

          <!-- start and end dates -->
          <v-row dense class="d-flex flex-wrap" justify="space-between">
            <v-col cols="12" md="6">
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1">Start Year</v-card-subtitle>
                <v-select
                  class="text-body-1 font-weight-medium black--text pl-2 py-2"
                  v-model="changeAnalysisInputs.startYearInput"
                  :items="landCoverDatasetAvailableYears"
                  item-text="simplifiedDate"
                  return-object
                  outlined
                  :color="$store.state.settings.appColor"
                  hide-details
                  dense
                />
              </v-card>
            </v-col>

            <v-col cols="12" md="6">
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1">End Year</v-card-subtitle>

                <v-select
                  class="text-body-1 font-weight-medium black--text pl-2 py-2"
                  v-model="changeAnalysisInputs.endYearInput"
                  :items="
                    filteredYears(
                      landCoverDatasetAvailableYears,
                      changeAnalysisInputs.startYearInput,
                      true
                    )
                  "
                  item-text="simplifiedDate"
                  return-object
                  outlined
                  :color="$store.state.settings.appColor"
                  hide-details
                  dense
                />
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <!-- natural land cover risk tool -->
      <v-card-text
        class="inputs-container"
        v-else-if="
          toolDefinition.ExpressionName.includes('Natural Land Cover Risk')
        "
      >
        <!-- input devices from expression -->

        <!-- form with some dynamically generated inputs based on previous responses -->
        <v-form>
          <v-row dense class="d-flex flex-wrap" justify="space-between">
            <!-- dataset/layer combination -->
            <v-col cols="12" md="6">
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1"
                  >Land Cover Dataset</v-card-subtitle
                >
                <v-select
                  class="text-body-1 font-weight-medium black--text pl-2 py-2"
                  item-text="displayName"
                  return-object
                  v-model="naturalLandCoverAnalysisInputs.landCoverDatasetInput"
                  :items="landCoverDatasets"
                  outlined
                  :color="$store.state.settings.appColor"
                  hide-details
                  dense
                  @change="getLandCoverDataset"
                />
              </v-card>
            </v-col>

            <!-- natural land cover types -->
            <v-col cols="12" md="6">
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1"
                  >Natural Land Cover Type(s)</v-card-subtitle
                >
                <v-select
                  class="text-body-1 font-weight-medium black--text pl-2 py-2"
                  v-model="naturalLandCoverAnalysisInputs.naturalLandTypeInputs"
                  multiple
                  :items="landCoverLayerNaturalLandTypes"
                  outlined
                  :color="$store.state.settings.appColor"
                  hide-details
                  dense
                />
              </v-card>
            </v-col>
          </v-row>

          <!-- study area/layer combinations -->
          <v-row dense class="d-flex flex-wrap" justify="space-between">
            <v-col cols="12" md="6">
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1"
                  >Study Area Dataset</v-card-subtitle
                >
                <v-select
                  class="text-body-1 font-weight-medium black--text pl-2 py-2"
                  :items="availableStudyAreas"
                  item-text="DatasetName"
                  return-object
                  v-model="naturalLandCoverAnalysisInputs.studyAreaDatasetInput"
                  outlined
                  :color="$store.state.settings.appColor"
                  hide-details
                  dense
                  @change="
                    (selectedDataset) => {
                      this.naturalLandCoverAnalysisInputs.studyAreaLayerOptions =
                        selectedDataset.FilterFieldValues.sort();
                    }
                  "
                />
              </v-card>
            </v-col>

            <v-col cols="12" md="6">
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1">AOI</v-card-subtitle>

                <v-select
                  :disabled="
                    !naturalLandCoverAnalysisInputs.studyAreaDatasetInput
                  "
                  class="text-body-1 font-weight-medium black--text pl-2 py-2"
                  v-model="naturalLandCoverAnalysisInputs.studyAreaLayerInput"
                  :items="naturalLandCoverAnalysisInputs.studyAreaLayerOptions"
                  outlined
                  :color="$store.state.settings.appColor"
                  hide-details
                  dense
                />
              </v-card>
            </v-col>
          </v-row>

          <!-- analysis type -->
          <v-row dense class="d-flex flex-wrap" justify="space-between">
            <!-- projected or historical analysis -->
            <v-col cols="12" md="6">
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1"
                  >Projected or Historical Data</v-card-subtitle
                >

                <v-select
                  class="text-body-1 font-weight-medium black--text pl-2 py-2"
                  outlined
                  :color="$store.state.settings.appColor"
                  hide-details
                  dense
                  :items="[
                    { title: 'Projected', value: 1 },
                    { title: 'Historical', value: 0 },
                  ]"
                  item-text="title"
                  item-value="value"
                  v-model="naturalLandCoverAnalysisInputs.analysisType"
                  :disabled="
                    !naturalLandCoverAnalysisInputs.landCoverDatasetInput
                  "
                  @change="setRiskAnalysisData"
                >
                </v-select>
              </v-card>
            </v-col>
            <!-- cropland projection datasets -->
            <v-col
              cols="12"
              md="6"
              v-if="
                naturalLandCoverAnalysisInputs.analysisType &&
                naturalLandCoverAnalysisInputs.analysisType === 1
              "
            >
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1"
                  >Cropland Projection Dataset</v-card-subtitle
                >
                <v-select
                  class="text-body-1 font-weight-medium black--text pl-2 py-2"
                  :items="availableCroplandProjections"
                  item-text="DatasetName"
                  return-object
                  v-model="
                    naturalLandCoverAnalysisInputs.croplandProjectionDatasetInput
                  "
                  outlined
                  :color="$store.state.settings.appColor"
                  hide-details
                  dense
                  @change="getCropProjectionDataset"
                />
              </v-card>
            </v-col>
            <!-- anthropogenic land cover types -->
            <v-col
              cols="12"
              md="6"
              v-else-if="naturalLandCoverAnalysisInputs.analysisType === 0"
            >
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1"
                  >Anthropogenic Land Cover Type(s)</v-card-subtitle
                >
                <v-select
                  class="text-body-1 font-weight-medium black--text pl-2 py-2"
                  v-model="
                    naturalLandCoverAnalysisInputs.anthropogenicLandCoverInputs
                  "
                  multiple
                  :items="
                    naturalLandCoverAnalysisInputs.currentAnthropogenicLandCoverCategories
                  "
                  outlined
                  :color="$store.state.settings.appColor"
                  hide-details
                  dense
                />
              </v-card>
            </v-col>
          </v-row>

          <!-- start and end dates -->
          <v-row dense class="d-flex flex-wrap" justify="space-between">
            <v-col
              cols="12"
              md="6"
              v-show="naturalLandCoverAnalysisInputs.analysisType === 0"
            >
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1">Start Year</v-card-subtitle>
                <v-select
                  class="text-body-1 font-weight-medium black--text pl-2 py-2"
                  v-model="naturalLandCoverAnalysisInputs.startYearInput"
                  :items="
                    filteredYears(
                      landCoverDatasetAvailableYears,
                      landCoverDatasetAvailableYears[
                        landCoverDatasetAvailableYears.length - 1
                      ],
                      false
                    )
                  "
                  item-text="simplifiedDate"
                  return-object
                  outlined
                  :color="$store.state.settings.appColor"
                  hide-details
                  dense
                />
              </v-card>
            </v-col>

            <v-col
              cols="12"
              md="6"
              v-show="naturalLandCoverAnalysisInputs.analysisType === 1"
            >
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1"
                  >Projection End Year</v-card-subtitle
                >

                <v-select
                  class="text-body-1 font-weight-medium black--text pl-2 py-2"
                  v-model="naturalLandCoverAnalysisInputs.endYearInput"
                  :items="
                    filteredYears(
                      naturalLandCoverAnalysisInputs.availableProjectionYears,
                      naturalLandCoverAnalysisInputs
                        .availableProjectionYears[0],
                      true
                    )
                  "
                  item-text="simplifiedDate"
                  return-object
                  outlined
                  :color="$store.state.settings.appColor"
                  hide-details
                  dense
                />
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <!-- restoration prioritization tool -->
      <v-card-text
        v-if="
          toolDefinition.ExpressionName.includes('Restoration Prioritization')
        "
        class="inputs-container"
      >
        <!-- input devices from expression -->

        <!-- form with some dynamically generated inputs based on previous responses -->

        <v-form>
          <!-- dataset/layer combination -->
          <v-row dense class="d-flex flex-wrap" justify="space-between">
            <v-col cols="12">
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1"
                  >Land Cover Dataset</v-card-subtitle
                >
                <v-select
                  class="text-body-1 font-weight-medium black--text pl-2 py-2"
                  item-text="displayName"
                  return-object
                  v-model="
                    restorationPrioritizationInputs.landCoverDatasetInput
                  "
                  :items="landCoverDatasets"
                  outlined
                  :color="$store.state.settings.appColor"
                  hide-details
                  dense
                  @change="getLandCoverDataset"
                />
              </v-card>
            </v-col>
          </v-row>

          <!-- categories farmland/natural -->
          <v-row dense class="d-flex flex-wrap" justify="space-between">
            <v-col cols="12" md="6">
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1"
                  >Crop Type(s)</v-card-subtitle
                >
                <v-select
                  class="text-body-1 font-weight-medium black--text pl-2 py-2"
                  v-model="restorationPrioritizationInputs.farmlandTypeInputs"
                  multiple
                  :items="landCoverLayerCropTypes"
                  outlined
                  :color="$store.state.settings.appColor"
                  hide-details
                  dense
                />
              </v-card>
            </v-col>

            <v-col cols="12" md="6">
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1"
                  >Natural Type(s)</v-card-subtitle
                >

                <v-select
                  class="text-body-1 font-weight-medium black--text pl-2 py-2"
                  multiple
                  v-model="
                    restorationPrioritizationInputs.naturalLandTypeInputs
                  "
                  :items="landCoverLayerNaturalLandTypes"
                  outlined
                  :color="$store.state.settings.appColor"
                  hide-details
                  dense
                />
              </v-card>
            </v-col>
          </v-row>

          <!-- study area dataset and layer -->
          <v-row dense class="d-flex flex-wrap" justify="space-between">
            <v-col cols="12" md="6">
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1"
                  >Study Area Dataset</v-card-subtitle
                >
                <v-select
                  class="text-body-1 font-weight-medium black--text pl-2 py-2"
                  :items="availableStudyAreas"
                  item-text="DatasetName"
                  return-object
                  v-model="
                    restorationPrioritizationInputs.studyAreaDatasetInput
                  "
                  outlined
                  :color="$store.state.settings.appColor"
                  hide-details
                  dense
                  @change="
                    (selectedDataset) => {
                      this.restorationPrioritizationInputs.studyAreaLayerOptions =
                        selectedDataset.FilterFieldValues.sort();
                    }
                  "
                />
              </v-card>
            </v-col>

            <v-col cols="12" md="6">
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1">AOI</v-card-subtitle>

                <v-select
                  :disabled="
                    !restorationPrioritizationInputs.studyAreaDatasetInput
                  "
                  class="text-body-1 font-weight-medium black--text pl-2 py-2"
                  v-model="restorationPrioritizationInputs.studyAreaLayerInput"
                  :items="restorationPrioritizationInputs.studyAreaLayerOptions"
                  outlined
                  :color="$store.state.settings.appColor"
                  hide-details
                  dense
                />
              </v-card>
            </v-col>
          </v-row>

          <!-- marginal capability threshold  -->
          <v-row dense class="d-flex flex-wrap" justify="space-between">
            <v-col cols="12">
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1"
                  >Marginal Capability Threshold</v-card-subtitle
                >

                <v-select
                  class="text-body-1 font-weight-medium black--text pl-2 py-2"
                  outlined
                  :color="$store.state.settings.appColor"
                  hide-details
                  dense
                  :items="
                    restorationPrioritizationInputs.marginalCapabilityThresholdOptions
                  "
                  item-text="title"
                  item-value="value"
                  v-model="
                    restorationPrioritizationInputs.marginalCapabilityThresholdInput
                  "
                >
                </v-select>
              </v-card>
            </v-col>
          </v-row>

          <!-- prioritization radius -->
          <v-row dense class="d-flex flex-wrap" justify="space-between">
            <!-- projected or historical analysis -->
            <v-col cols="12">
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1"
                  >Prioritization Radius (m)</v-card-subtitle
                >

                <v-slider
                  class="text-body-1 align-center font-weight-medium black--text pl-2 py-2"
                  outlined
                  :color="$store.state.settings.appColor"
                  :track-color="$store.state.settings.appColor"
                  hide-details
                  dense
                  :min="
                    inputDeviceDefinitions['Prioritization Radius (m)']
                      .InputDeviceMinValue
                  "
                  :max="
                    inputDeviceDefinitions['Prioritization Radius (m)']
                      .InputDeviceMaxValue
                  "
                  step="100"
                  v-model="
                    restorationPrioritizationInputs.prioritizationRadiusInput
                  "
                >
                  <template v-slot:append>
                    <!-- <v-text-field
                      v-model="
                        restorationPrioritizationInputs.prioritizationRadiusInput
                      "
                      type="number"
                      single-line
                      class="d-flex align-center justify-start text-body-1 font-weight-medium black--text pl-1 mb-0"
                      style="width: 70px"
                    >
                      {{
                        restorationPrioritizationInputs.prioritizationRadiusInput
                      }}
                    </v-text-field> -->
                    <p
                      class="d-flex align-center justify-start text-body-1 font-weight-medium black--text pl-1 mb-0"
                      style="width: 70px"
                    >
                      {{
                        restorationPrioritizationInputs.prioritizationRadiusInput
                      }}
                    </p>
                  </template>
                </v-slider>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-subheader class="pl-10 text--black font-weight-bold"
        >Outputs:</v-subheader
      >

      <v-text-field
        class="output-container px-4"
        label="Dataset Name"
        v-model="outputDatasetNameInput"
        dense
        outlined
        full-width
      ></v-text-field>
      <v-card-actions class="output-container px-0">
        <!-- run button -->
        <v-btn
          @click="runAnalysis"
          :loading="runButtonLoading"
          rounded
          elevation="0"
          block
        >
          <v-icon left>mdi-play</v-icon> Run
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import {
  getDataset,
  getDateList,
  newSimulationRun,
  updateInputDeviceValue,
} from "@/api/v2.js";
import { getVirtualDatasetLayer } from "../../api/v2";

export default {
  components: {},

  props: {
    toolDefinition: {
      type: Object,
    },
    inputDeviceDefinitions: {
      type: Object,
    },
  },

  data() {
    return {
      historyMode: false,
      showToolHelp: false,
      outputDatasetNameInput: null,
      runButtonLoading: false,

      landCoverLayerSymbology: [],
      landCoverLayerNaturalLandTypes: [],
      landCoverLayerCropTypes: [],
      landCoverDatasetAvailableYears: [],

      updatedInputDeviceDefinitions: { ...this.inputDeviceDefinitions },

      changeAnalysisInputs: {
        landCoverDatasetInput: null,
        preConversionInputs: [],
        postConversionInputs: [],
        studyAreaDatasetInput: null,
        studyAreaLayerOptions: null,
        studyAreaLayerInput: null,
        startYearInput: null,
        endYearInput: null,
      },

      naturalLandCoverAnalysisInputs: {
        landCoverDatasetInput: null,
        naturalLandTypeInputs: [],
        studyAreaDatasetInput: null,
        studyAreaLayerOptions: null,
        analysisType: null,
        croplandProjectionDatasetInput: null,
        croplandProjectionLayerInput: "Default",
        currentAnthropogenicLandCoverDataset: null,
        currentAnthropogenicLandCoverCategories: [],
        anthropogenicLandCoverInputs: [],
        studyAreaLayerInput: null,
        availableProjectionYears: [],
        startYearInput: null,
        endYearInput: null,
      },

      restorationPrioritizationInputs: {
        landCoverDatasetInput: null,
        naturalLandTypeInputs: [],
        farmlandTypeInputs: [],
        studyAreaDatasetInput: null,
        studyAreaLayerOptions: null,
        studyAreaLayerInput: null,
        marginalCapabilityThresholdOptions: [
          { title: "No Limitations", value: 1 },
          { title: "Moderate Limitations", value: 2 },
          { title: "Moderately Severe Limitations", value: 3 },
          { title: "Severe Limitations", value: 4 },
          { title: "Forage Improvement Feasible", value: 5 },
          { title: "Forage Improvement Not Feasible", value: 6 },
        ],
        marginalCapabilityThresholdInput: null,
        prioritizationRadiusInput: null,
        startYearInput: null,
        endYearInput: null,
      },
    };
  },

  watch: {
    previousRun(run) {
      if (run) this.historyMode = true;
    },
  },

  computed: {
    project() {
      return this.$store.state.project.activeProject;
    },

    landCoverDatasets() {
      return this.$store.state.appData["CAPI_ANALYSIS_TOOLS"].landCoverDatasets;
    },

    availableStudyAreas() {
      return this.$store.state.accountData.datasetList.datasets.AllDatasets.filter(
        (dataset) => dataset.Tags.includes("Study Area")
      );
    },

    availableCroplandProjections() {
      return this.$store.state.accountData.datasetList.datasets.AllDatasets.filter(
        (dataset) => dataset.Tags.includes("Crop projection")
      );
    },

    toolDescription() {
      return this.$store.state.accountData.expressionList.expressions.AllExpressions.find(
        (expression) =>
          expression.ExpressionName === this.toolDefinition.ExpressionName
      ).Description;
    },
  },

  methods: {
    async runAnalysis() {
      this.runButtonLoading = true;
      let projectOwner = this.project.Settings.ProjectOwner;
      let projectName = this.project.Settings.ProjectName;
      let expressionName = this.toolDefinition.ExpressionName;
      let scenarioName;
      const inputDevices = structuredClone(this.inputDeviceDefinitions);

      if (expressionName.includes("Change Analysis")) {
        scenarioName = this.outputDatasetNameInput;

        // pre conversion data input device
        inputDevices["Pre-conversion Land Cover Types"].InputDeviceValue = {
          name: this.changeAnalysisInputs.landCoverDatasetInput.datasetName,
          categories: this.changeAnalysisInputs.preConversionInputs,
          feature_name:
            this.changeAnalysisInputs.landCoverDatasetInput.layerName,
          set_time: this.changeAnalysisInputs.startYearInput.simplifiedDate,
          as_mask: true,
        };

        // pre conversion negative data input device
        inputDevices[
          "Pre-conversion Land Cover Types negative"
        ].InputDeviceValue = {
          name: this.changeAnalysisInputs.landCoverDatasetInput.datasetName,
          categories: this.changeAnalysisInputs.preConversionInputs,
          feature_name:
            this.changeAnalysisInputs.landCoverDatasetInput.layerName,
          set_time: this.changeAnalysisInputs.endYearInput.simplifiedDate,
          as_mask: true,
        };

        // post conversion data input device
        inputDevices["Post-conversion Land Cover Types"].InputDeviceValue = {
          name: this.changeAnalysisInputs.landCoverDatasetInput.datasetName,
          categories: this.changeAnalysisInputs.postConversionInputs,
          feature_name:
            this.changeAnalysisInputs.landCoverDatasetInput.layerName,
          set_time: this.changeAnalysisInputs.endYearInput.simplifiedDate,
          as_mask: true,
        };

        // post conversion negative data input device
        inputDevices[
          "Post-conversion Land Cover Types negative"
        ].InputDeviceValue = {
          name: this.changeAnalysisInputs.landCoverDatasetInput.datasetName,
          categories: this.changeAnalysisInputs.postConversionInputs,
          feature_name:
            this.changeAnalysisInputs.landCoverDatasetInput.layerName,
          set_time: this.changeAnalysisInputs.startYearInput.simplifiedDate,
          as_mask: true,
        };

        // study area literal input device
        inputDevices["Study Area Dataset"].InputDeviceValue =
          this.changeAnalysisInputs.studyAreaDatasetInput.DatasetName;

        inputDevices["Study Area"].InputDeviceValue =
          this.changeAnalysisInputs.studyAreaLayerInput;
      }

      if (expressionName.includes("Natural Land Cover")) {
        scenarioName = this.outputDatasetNameInput;

        // natural land cover data input device
        inputDevices["Natural Land Cover"].InputDeviceValue = {
          name: this.naturalLandCoverAnalysisInputs.landCoverDatasetInput
            .datasetName,
          categories: this.naturalLandCoverAnalysisInputs.naturalLandTypeInputs,
          feature_name:
            this.naturalLandCoverAnalysisInputs.landCoverDatasetInput.layerName,
          set_time:
            this.landCoverDatasetAvailableYears[
              this.landCoverDatasetAvailableYears.length - 1
            ].simplifiedDate,
          as_mask: true,
        };

        // study area literal input device
        inputDevices["Study Area Dataset"].InputDeviceValue =
          this.naturalLandCoverAnalysisInputs.studyAreaDatasetInput.DatasetName;

        inputDevices["Study Area"].InputDeviceValue =
          this.naturalLandCoverAnalysisInputs.studyAreaLayerInput;

        // analysis type literal input device
        inputDevices["Analysis Type"].InputDeviceValue =
          this.naturalLandCoverAnalysisInputs.analysisType;

        // set historical inputs
        if (this.naturalLandCoverAnalysisInputs.analysisType === 0) {
          // historic anthropogenic land cover data input device
          inputDevices["Historical Anthropogenic Land Cover"].InputDeviceValue =
            {
              name: this.naturalLandCoverAnalysisInputs
                .currentAnthropogenicLandCoverDataset.datasetName,
              categories:
                this.naturalLandCoverAnalysisInputs
                  .anthropogenicLandCoverInputs,
              feature_name:
                this.naturalLandCoverAnalysisInputs
                  .currentAnthropogenicLandCoverDataset.layerName,
              set_time:
                this.naturalLandCoverAnalysisInputs.startYearInput
                  .simplifiedDate,
              as_mask: true,
            };

          // current anthropogenic land cover data input device
          inputDevices["Current Anthropogenic Land Cover"].InputDeviceValue = {
            name: this.naturalLandCoverAnalysisInputs
              .currentAnthropogenicLandCoverDataset.datasetName,
            categories:
              this.naturalLandCoverAnalysisInputs.anthropogenicLandCoverInputs,
            feature_name:
              this.naturalLandCoverAnalysisInputs
                .currentAnthropogenicLandCoverDataset.layerName,
            set_time:
              this.landCoverDatasetAvailableYears[
                this.landCoverDatasetAvailableYears.length - 1
              ].simplifiedDate,
            as_mask: true,
          };
        }

        // set projection inputs
        else {
          // cropland projection data input device
          inputDevices["Cropland Projection Dataset"].InputDeviceValue = {
            name: this.naturalLandCoverAnalysisInputs
              .croplandProjectionDatasetInput.DatasetName,
            feature_name:
              this.naturalLandCoverAnalysisInputs.croplandProjectionLayerInput,
            set_time:
              this.naturalLandCoverAnalysisInputs.endYearInput.simplifiedDate,
            as_mask: true,
          };
        }
      }

      if (expressionName.includes("Restoration Prioritization")) {
        scenarioName = this.outputDatasetNameInput;

        // natural land cover data input device
        inputDevices["Natural land"].InputDeviceValue = {
          name: this.restorationPrioritizationInputs.landCoverDatasetInput
            .datasetName,
          categories:
            this.restorationPrioritizationInputs.naturalLandTypeInputs,
          feature_name:
            this.restorationPrioritizationInputs.landCoverDatasetInput
              .layerName,
          set_time:
            this.landCoverDatasetAvailableYears[
              this.landCoverDatasetAvailableYears.length - 1
            ].simplifiedDate,
          as_mask: true,
        };

        // farmland cover data input device
        inputDevices["Farmland"].InputDeviceValue = {
          name: this.restorationPrioritizationInputs.landCoverDatasetInput
            .datasetName,
          categories: this.restorationPrioritizationInputs.farmlandTypeInputs,
          feature_name:
            this.restorationPrioritizationInputs.landCoverDatasetInput
              .layerName,
          set_time:
            this.landCoverDatasetAvailableYears[
              this.landCoverDatasetAvailableYears.length - 1
            ].simplifiedDate,
          as_mask: true,
        };

        // study area literal input device
        inputDevices["Study Area Dataset"].InputDeviceValue =
          this.restorationPrioritizationInputs.studyAreaDatasetInput.DatasetName;

        inputDevices["Study Area"].InputDeviceValue =
          this.restorationPrioritizationInputs.studyAreaLayerInput;

        // analysis type literal input device
        inputDevices["Marginal Capability Threshold"].InputDeviceValue =
          this.restorationPrioritizationInputs.marginalCapabilityThresholdInput;

        // prioritization slider input device
        inputDevices["Prioritization Radius (m)"].InputDeviceValue =
          this.restorationPrioritizationInputs.prioritizationRadiusInput;
      }

      await Promise.all(
        Object.entries(inputDevices).map(async (inputDevice) => {
          const inputDeviceName = inputDevice[0];
          const newValue = inputDevice[1].InputDeviceValue;

          return updateInputDeviceValue(inputDeviceName, newValue);
        })
      );

      try {
        const run = await newSimulationRun(
          projectName,
          projectOwner,
          expressionName,
          this.$store.state.account.user.attributes.email,
          scenarioName
        );

        if (run.Errors) {
          throw new Error(run.Errors);
        }

        this.$emit("update-project", {
          projectName,
          projectOwner,
          scenarioName,
        });
      } catch (error) {
        this.$showAlert({
          text: error,
          type: "error",
        });
      } finally {
        this.runButtonLoading = false;
      }
    },

    resetInputs() {
      this.changeAnalysisInputs.preConversionInputs = [];
      this.changeAnalysisInputs.postConversionInputs = [];
      this.changeAnalysisInputs.startYearInput = null;
      this.changeAnalysisInputs.endYearInput = null;
      this.naturalLandCoverAnalysisInputs.naturalLandTypeInputs = [];
      this.naturalLandCoverAnalysisInputs.startYearInput = null;
      this.naturalLandCoverAnalysisInputs.endYearInput = null;
    },

    resetValue() {
      const inputDevices = this.updatedInputDeviceDefinitions;

      inputDevices.forEach((input) => {
        // if the default value is an object, make a copy
        let defaultValue = input.InputDeviceDefaultValue;
        if (Array.isArray(defaultValue)) {
          input.InputDeviceValue = [];
        } else if (typeof defaultValue == "object") {
          input.InputDeviceValue = Object.keys(...defaultValue);
        } else {
          input.InputDeviceValue = null;
        }

        this.onValueUpdate();
      });
    },

    async getLandCoverDataset(datasetInformation) {
      // clear all inputs
      this.resetInputs();

      try {
        const dataset = await getDataset(
          datasetInformation.datasetName,
          datasetInformation.datasetOwner
        );
        if (dataset.Errors) {
          throw new Error(dataset.Errors);
        }

        // get layer
        try {
          const landCoverLayer = await getVirtualDatasetLayer(
            datasetInformation.datasetName,
            datasetInformation.datasetOwner,
            datasetInformation.layerName
          );
          if (dataset.Errors) {
            throw new Error(dataset.Errors);
          }
          // get symbology
          const landCoverLayerSymbologyName = landCoverLayer.SymbologyName;

          // populate conversion type list
          this.landCoverLayerSymbology = Object.keys(
            dataset.Symbology[landCoverLayerSymbologyName].Categories
          ).sort();

          // set natural type list
          this.landCoverLayerNaturalLandTypes =
            datasetInformation.naturalTypes.sort();

          // set crop type list
          this.landCoverLayerCropTypes = datasetInformation.cropTypes.sort();

          const dateList = await this.buildDatesList(
            landCoverLayer.StartDate,
            landCoverLayer.EndDate,
            landCoverLayer.Timestep
          );

          // data prior to 2013 is incomplete for usage in this tool
          if (datasetInformation.datasetName === "Annual Crop Inventory")
            this.landCoverDatasetAvailableYears = dateList.filter(
              (date) => date.simplifiedDate >= 2013
            );
          else this.landCoverDatasetAvailableYears = dateList;
        } catch (error) {
          return error;
        }
      } catch (error) {
        this.$showAlert({
          text: error,
          type: "error",
        });
      } finally {
        this.datasetLoading = false;
      }
    },

    async getCropProjectionDataset(datasetInformation) {
      try {
        const dataset = await getDataset(
          datasetInformation.DatasetName,
          datasetInformation.DatasetOwner
        );
        if (dataset.Errors) {
          throw new Error(dataset.Errors);
        }

        // get layer
        try {
          const croplandLayer = await getVirtualDatasetLayer(
            datasetInformation.DatasetName,
            datasetInformation.DatasetOwner,
            "Default"
          );
          if (dataset.Errors) {
            throw new Error(dataset.Errors);
          }

          const dateList = await this.buildDatesList(
            croplandLayer.StartDate,
            croplandLayer.EndDate,
            croplandLayer.Timestep
          );

          this.naturalLandCoverAnalysisInputs.availableProjectionYears =
            dateList;
        } catch (error) {
          return error;
        }
      } catch (error) {
        this.$showAlert({
          text: error,
          type: "error",
        });
      } finally {
        this.datasetLoading = false;
      }
    },

    async buildDatesList(startDate, endDate, timestep) {
      const dateList = await getDateList(startDate, endDate, timestep);

      return dateList.Dates.map((date) => ({
        stringDate: date,
        simplifiedDate: parseInt(date.split("-")[0]),
      }));
    },

    filteredTypes(fullList, selectedList) {
      const remainingTypes = fullList.filter(
        (category) => !selectedList.includes(category)
      );

      return remainingTypes;
    },

    selectAll(target, selections) {
      this.changeAnalysisInputs[target] = selections;
    },

    filteredYears(fullList, yearToFilter, sortByAscending) {
      try {
        if (sortByAscending)
          return fullList.filter(
            (year) => year.simplifiedDate > yearToFilter.simplifiedDate
          );
        else
          return fullList.filter(
            (year) => year.simplifiedDate < yearToFilter.simplifiedDate
          );
      } catch (error) {
        return [];
      }
    },

    setRiskAnalysisData(dataType) {
      if (dataType === 0) {
        this.naturalLandCoverAnalysisInputs.currentAnthropogenicLandCoverDataset =
          this.naturalLandCoverAnalysisInputs.landCoverDatasetInput;

        // set list
        this.naturalLandCoverAnalysisInputs.currentAnthropogenicLandCoverCategories =
          [
            ...this.naturalLandCoverAnalysisInputs.landCoverDatasetInput
              .cropTypes,
            ...this.naturalLandCoverAnalysisInputs.landCoverDatasetInput
              .otherAnthropogenicTypes,
          ];

        // select all
        this.naturalLandCoverAnalysisInputs.anthropogenicLandCoverInputs =
          this.naturalLandCoverAnalysisInputs.currentAnthropogenicLandCoverCategories;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 100px);
  overflow: hidden;
}

.content-container {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: calc(100vh - 250px);
}

.inputs-container {
  flex: 1;
  height: 45vh;
  margin: 2em auto 3em auto;
  padding: 2em 1.2em 2em 0.5em;
  width: 95%;
  border: 0.1em solid #cac4d0;
  border-radius: 12px;
  overflow-y: auto;
}

.input-headers {
  font: 500 black 1.5em;
}

.v-subheader {
  height: 1em;
}

.output-container {
  height: 5vh;
  margin: 1em auto 1em auto;
  width: 65%;
}

/* Medium devices (landscape tablets, 768px and less) */
@media only screen and (max-width: 768px) {
  .inputs-container {
    width: 90%;
  }
  .output-container {
    width: 90%;
  }
}
</style>

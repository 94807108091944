<template>
  <div>
    <v-overlay
      class="d-flex align-center"
      width="100%"
      v-model="viewExpression"
      :dark="false"
    >
      <expression-overlay
        v-model="viewExpression"
        :input-expr="this.simulation"
      ></expression-overlay>
    </v-overlay>
    <v-list subheader>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle> Expression name </v-list-item-subtitle>
          <v-list-item-title
            >{{ simulation ? simulation.ExpressionName : "" }}

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  x-small
                  fab
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="viewExpression = true"
                >
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
              </template>
              <span>Open in expression editor</span>
            </v-tooltip>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle>Expression owner</v-list-item-subtitle>
          <v-list-item-title>{{
            simulation.ExpressionOwner
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle
            >Expression added to project</v-list-item-subtitle
          >
          <v-list-item-title>{{ simulationCreatedAt }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle
            >Scenario settings last modified</v-list-item-subtitle
          >
          <v-list-item-title>{{ simulationUpdatedAt }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle>Number of scenarios run</v-list-item-subtitle>
          <v-list-item-title>{{
            simulation.Runs ? simulation.Runs.length : 0
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>Execution order</v-list-item-subtitle>
          <p>
            Configure the order in which this expression runs, and if it should
            run after or with the previous expression.
          </p>
          <v-row style="margin-top: 0.1em; display: flex; align-items: start">
            <v-col cols="6" md="4" v-if="previousSimulation || nextSimulation">
              <v-btn
                outlined
                class="ma-2"
                :disabled="previousSimulation == null"
                @click="swapOrderWith(previousSimulation)"
                :loading="swapIsLoading"
                width="100%"
              >
                <v-icon left>mdi-chevron-up</v-icon> Move up
              </v-btn>
              <v-btn
                outlined
                class="ma-2"
                :disabled="nextSimulation == null"
                @click="swapOrderWith(nextSimulation)"
                :loading="swapIsLoading"
                width="100%"
              >
                <v-icon left>mdi-chevron-down</v-icon> Move down
              </v-btn>
            </v-col>
            <v-col cols="6" md="8">
              <v-select
                :items="['After previous', 'With previous', 'Standalone only']"
                outlined
                dense
                v-model="simulation.RunOrder"
                label="Execution order"
                hint="Controls whether this expression runs WITH or AFTER the previous expression."
                persistent-hint
                @change="registerChange('RunOrder')"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-subheader>Scenario Settings</v-subheader>

      <v-list-item v-show="!isTimeInvariant">
        <v-list-item-content>
          <v-list-item-subtitle>Scenario period*</v-list-item-subtitle>
          <p>
            Pick a time step and set the start date and end date. To evaluate
            time in reverse, the end date should be before the start date.
          </p>
          <v-row style="margin-top: 0.1em; display: flex; align-items: start">
            <v-col cols="4">
              <v-select
                v-model="simulation.Timestep"
                :items="['Decadally', 'Annually', 'Monthly', 'Daily']"
                label="Time step"
                @input="registerChange('Timestep')"
                :color="$store.state.settings.appColor"
                outlined
                dense
                :disabled="simulation.ReadOnlySettings.includes('Timestep')"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <date-field
                v-model="simulation.StartDate"
                :precision="simulation.Timestep"
                label="Start at"
                @input="registerChange('StartDate')"
                :disabled="simulation.ReadOnlySettings.includes('StartDate')"
              ></date-field>
            </v-col>
            <v-col cols="4">
              <date-field
                v-model="simulation.EndDate"
                :precision="simulation.Timestep"
                label="End at"
                @input="registerChange('EndDate')"
                :disabled="simulation.ReadOnlySettings.includes('EndDate')"
              ></date-field>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>

      <div style="margin-bottom: 2em">
        <v-subheader>
          Advanced
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="expandAdvanced = !expandAdvanced"
              >
                <v-icon>{{
                  expandAdvanced ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </template>
            <span
              >Advanced settings can be used to help debug expression runs
            </span>
          </v-tooltip>
        </v-subheader>

        <v-expand-transition>
          <v-list-item v-show="expandAdvanced">
            <v-list-item-content>
              <v-row class="d-flex align-center justify-space-between">
                <v-col cols="12" md="3">
                  <v-select
                    class="pl-1"
                    :items="memorySelectOptions"
                    label="Memory"
                    v-model="simulation.Memory"
                    @change="registerChange('Memory')"
                    :disabled="simulation.ReadOnlySettings.includes('Memory')"
                  ></v-select>
                </v-col>

                <!-- currently removed from UI until feature ready -->
                <!-- <v-col cols="12" md="3">
                  <v-switch
                    style="margin: auto; width: 50%;"
                    disabled
                    v-model="simulation.Profile"
                    label="Profile Mode"
                    @change="registerChange('Profile')"
                    dense
                  ></v-switch>
                </v-col> -->
                <v-col
                  cols="12"
                  md="9"
                  class="d-flex advanced-options flex-wrap"
                >
                  <v-switch
                    v-model="simulation.Debug"
                    label="Debug Mode"
                    @change="registerChange('Debug')"
                    dense
                  ></v-switch>
                  <v-switch
                    v-model="simulation.DeveloperMode"
                    label="Developer Mode"
                    @change="registerChange('DeveloperMode')"
                    dense
                  ></v-switch>
                  <v-switch
                    v-model="simulation.GenerateCOGs"
                    label="Generate COGs"
                    @change="registerChange('GenerateCOGs')"
                    dense
                  ></v-switch>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    style="padding-left: 1em"
                    :items="['ProjectScenarioExpression', 'ExpressionContext']"
                    label="Ouput Naming Scheme"
                    v-model="simulation.OutputNomenclature"
                    @change="registerChange('OutputNomenclature')"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Cache Lifetime"
                    v-model="simulation.CacheLifetime"
                    type="number"
                    :rules="[checkCacheLifetime]"
                    @change="updateCacheLifetime"
                    :disabled="
                      simulation.ReadOnlySettings.includes('CacheLifetime')
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-expand-transition>
      </div>
      <v-divider></v-divider>

      <v-subheader>Run expression</v-subheader>
      <!-- <v-subheader>Run expression (on its own)</v-subheader> -->

      <v-list-item>
        <v-list-item-content>
          <v-text-field
            label="Scenario name*"
            v-model="scenarioName"
            dense
            outlined
            :rules="[checkUnique]"
            :disabled="isRunnableOrReasonsNotRunnable !== true"
          ></v-text-field>
          <v-btn
            :disabled="!canRun"
            @click="runSimulation"
            :loading="runButtonLoading"
          >
            <v-icon left>mdi-play</v-icon> Run
          </v-btn>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list v-if="isRunnableOrReasonsNotRunnable !== true" dense>
      <v-list-item
        v-for="reason in isRunnableOrReasonsNotRunnable"
        :key="reason"
      >
        <v-list-item-icon>
          <v-icon color="warning">mdi-alert-circle</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="reason"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { debounce } from "vuetify/lib/util/helpers";
import ExpressionOverlay from "@/components/expressions/ExpressionOverlay.vue";
import DateField from "@/components/projects/DateField.vue";
import {
  formatDateString,
  updateSimulationSettings,
  swapSimulationOrder,
  newSimulationRun,
} from "@/api/v2";
import { simulationCanRun } from "@/api/helpers";
export default {
  name: "SimulationSettings",
  components: {
    ExpressionOverlay,
    DateField,
  },
  props: {
    // TODO: we should be mutating a copy of the simulations
    simulation: { type: Object, default: null },
    previousSimulation: { type: Object, default: null },
    nextSimulation: { type: Object, default: null },
    inputDevices: { type: Object, default: null },
    activeProject: { type: Object, default: () => {} },
  },
  data() {
    return {
      expandAdvanced: false,
      memorySelectOptions: [
        "8 GB",
        "12 GB",
        "16 GB",
        "24 GB",
        "30 GB",
        "60 GB",
        "120 GB",
      ],
      changedSettings: new Set(),
      debouncedAutosave: null,
      selectExpression: false,
      scenarioName: null,
      swapIsLoading: false,
      expressionEditorDialog: false,
      viewExpression: false,
      runButtonLoading: false,
      saveNeeded: false,
    };
  },
  computed: {
    isTimeInvariant() {
      return (
        this.simulation.StartDate === "ind" && this.simulation.EndDate === "ind"
      );
    },
    simulationRunNames() {
      if (this.simulation && this.simulation.Runs === null) {
        return [];
      } else {
        return this.simulation.Runs.map((run) => {
          return run.ScenarioName;
        });
      }
    },
    simulationCreatedAt() {
      if (this.simulation !== null) {
        return formatDateString(this.simulation.CreatedAt);
      } else {
        return null;
      }
    },
    simulationUpdatedAt() {
      if (this.simulation !== null) {
        return formatDateString(this.simulation.UpdatedAt);
      } else {
        return null;
      }
    },
    currentSimulation() {
      return this.simulation;
    },
    isRunnableOrReasonsNotRunnable() {
      return simulationCanRun(this.activeProject, this.simulation);
    },
    canRun() {
      return (
        // study area must be selected or unhandled error called
        this.isRunnableOrReasonsNotRunnable === true &&
        this.scenarioName &&
        this.checkUnique(this.scenarioName) === true
      );
    },
  },
  methods: {
    initializeConfig() {
      this.changedSettings = new Set();
      if (this.simulation.Memory === null) this.simulation.Memory = "8 GB";
      if (this.simulation.Debug === null) this.simulation.Debug = false;
      if (this.simulation.Profile === null) this.simulation.Profile = false;
    },
    registerChange(key) {
      this.changedSettings.add(key);
      this.runButtonLoading = true; // not sending request, show "loading" to block sending new run request
      this.$emit("autosave-in-progress", true); // tell parent that an autosave is in progress
      this.debouncedAutosave(this);
    },
    swapOrderWith(otherSimulation) {
      const swappedWithNext =
        otherSimulation.SortValue > this.simulation.SortValue;
      this.swapIsLoading = true;
      const projectOwner = this.simulation.ProjectOwner;
      const projectName = this.simulation.ProjectName;
      swapSimulationOrder(
        projectName,
        projectOwner,
        this.simulation.ExpressionName,
        otherSimulation.ExpressionName
      ).then(() => {
        this.swapIsLoading = false;
        this.$store.dispatch("project/loadProject", {
          projectOwner,
          projectName,
        });
        if (swappedWithNext) {
          this.$emit("swapped-with-next");
        } else {
          this.$emit("swapped-with-previous");
        }
      });
    },
    setExpression(expr) {
      this.simulation.Expression = expr.ExpressionName;
      this.selectExpression = false;
      this.registerChange("Expression");
    },
    checkDateFormat(value) {
      return (
        /^[0-9]{4}-[01][0-9]-[0-3][0-9]/.test(value) ||
        "Date format must be 'YYYY-MM-DD' (e.g., 2021-01-01)"
      );
    },
    updateDateStart(date) {
      this.saveNeeded = true;
      this.simulation.StartDate = date;
      this.registerChange("StartDate");
    },
    updateDateEnd(date) {
      this.saveNeeded = true;
      this.simulation.EndDate = date;
      this.registerChange("EndDate");
    },
    checkUnique(value) {
      return (
        !this.simulationRunNames.includes(value) || "Scenario already exists"
      );
    },
    checkNotEmpty(value) {
      return value.length > 0 || "Scenario name is required";
    },
    checkCacheLifetime(value) {
      return (
        parseInt(value) >= 0 || "Must be a number greater than or equal to zero"
      );
    },
    updateCacheLifetime(value) {
      value = parseInt(value);
      if (isNaN(value)) {
        return;
      }
      this.simulation.CacheLifetime = value;
      this.registerChange("CacheLifetime");
    },
    runSimulation() {
      this.runButtonLoading = true;
      let projectOwner = this.simulation.ProjectOwner;
      let projectName = this.simulation.ProjectName;
      let expressionName = this.simulation.ExpressionName;
      let scenarioName = this.scenarioName;
      newSimulationRun(
        projectName,
        projectOwner,
        expressionName,
        this.$store.state.account.user.attributes.email,
        scenarioName
      )
        .then((response) => {
          if (response.Warnings) {
            this.$showAlert({ text: response.Warnings, type: "warning" });
          }
          this.$store.dispatch("project/loadProject", {
            projectOwner,
            projectName,
          });
        })
        .catch((e) => {
          if (e.response && e.response.data.Errors) {
            this.$showAlert({ text: e.response.data.Errors, type: "error" });
          } else {
            throw e;
          }
        })
        .finally(() => {
          this.runButtonLoading = false;
        });
    },
  },
  beforeMount() {
    this.debouncedAutosave = debounce(function (self) {
      const settings = self.simulation;
      const newSettings = Array.from(self.changedSettings).reduce(
        (obj, key) => ((obj[key] = settings[key]), obj),
        {}
      );
      self.changedSettings = new Set();
      const projectOwner =
        self.$store.state.project.activeProject.Properties.ProjectOwner;
      const projectName =
        self.$store.state.project.activeProject.Properties.ProjectName;
      const expressionName = self.simulation.ExpressionName;
      updateSimulationSettings(
        projectName,
        projectOwner,
        expressionName,
        newSettings
      ).then((response) => {
        if (response.Errors) {
          self.$showAlert({ text: response.Errors, type: "error" });
        }
        if (response.Warnings) {
          self.$showAlert({ text: response.Warnings, type: "warning" });
        }
        self.runButtonLoading = false; // not sending request, show "loading" to block sending new run request
        self.$emit("autosave-in-progress", false); // tell parent that autosave is done
        self.$store
          .dispatch("project/loadProject", {
            projectOwner,
            projectName,
          })
          .finally(() => {
            self._data.saveNeeded = false;
          });
      });
    }, 10000);
    this.initializeConfig();
  },
};
</script>

<style scoped>
.advanced-options {
  gap: 1rem;
  align-content: center;
}
</style>

<template>
  <v-autocomplete
    class="pt-2"
    v-model="selectedLayers"
    :items="layers"
    :multiple="allowMultiple"
    item-text="LayerName"
    return-object
    :disabled="layers.length === 0"
    :color="$store.state.settings.appColor"
    hide-details
    dense
    auto-select-first
    @blur="resetToDefault"
  >
    <template v-slot:selection="{ item, index }">
      <span v-if="index === 0" class="text-caption">
        {{ item.LayerName }}
      </span>
      <span v-else-if="index > 0 && index < 10" class="text-caption"
        >{{ `, ${item.LayerName} ` }}
      </span>
      <span v-else-if="index === 10" class="grey--text text-caption">
        {{ `(+${selectedLayers.length - 10} others)` }}
      </span>
    </template>
    <template v-slot:item="{ item, on, attrs }">
      <template v-if="item.LayerName === 'Default'">
        <v-list-item v-bind="attrs" v-on="on">
          <v-divider></v-divider>
          <v-list-item-content>
            <v-list-item-title class="d-flex justify-center">
              {{ item.LayerName }}</v-list-item-title
            >
          </v-list-item-content>
          <v-divider></v-divider>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item v-bind="attrs" v-on="on">
          <v-list-item-content>
            <v-list-item-title> {{ item.LayerName }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "DatasetLayerSelector",

  props: {
    dataset: {
      type: Object,
      default: null,
    },
    allowMultiple: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      layers: [],
      selectedLayers: [],
    };
  },

  watch: {
    layers(values) {
      // multiple
      if (this.allowMultiple) {
        this.selectedLayers.push(values[0]);
      }

      // single object
      else this.selectedLayers = values[0];
    },

    selectedLayers() {
      // remove default from selection if others selected
      if (this.allowMultiple) {
        if (this.selectedLayers.length > 1) {
          if (
            this.selectedLayers.some((layer) => layer.LayerName === "Default")
          ) {
            this.removeDefault();
          }
        }
      }

      if (this.layers.length === 0) this.$emit("select-layer", null);
      if (this.layers.length > 0)
        this.$emit("select-layer", this.selectedLayers);
    },
  },

  methods: {
    resetToDefault() {
      if (this.allowMultiple && this.selectedLayers.length === 0) {
        this.selectedLayers.push(this.layers[0]);
      }
    },

    removeDefault() {
      this.selectedLayers.shift();
    },

    setLayers() {
      const hasLayers = this.dataset?.Layers;
      this.layers =
        hasLayers === null || hasLayers === undefined ? [] : hasLayers;
    },
  },
  mounted() {
    this.setLayers();
  },
};
</script>

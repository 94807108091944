<template>
  <div>
    <v-row class="d-flex justify-space-between align-center">
      <v-col cols="12" md="6">
        <div>
          <v-file-input
            accept=".zip, .gpkg, .geojson, .kml"
            hint="Accepts: .zip(.shp), .gpkg, .geojson, .kml"
            persistent-hint
            label="Vector File"
            placeholder=""
            persistent-placeholder
            truncate-length="30"
            show-size
            v-model="file"
            @change="updateFileInfo"
          >
            <template v-slot:selection="{ text }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text-wrap text-subtitle-2"
                    v-bind="attrs"
                    v-on="on"
                    >{{ text }}</span
                  >
                </template>
                <span>{{ filename }}</span>
              </v-tooltip>
            </template>
          </v-file-input>
        </div>
      </v-col>

      <v-col cols="1" class="d-none d-md-block"
        ><v-divider vertical></v-divider
      ></v-col>
      <v-col cols="12" md="5">
        <div class="d-flex d-md-block justify-md-center">
          <!-- <v-text-field
            dense
            :disabled="!file"
            class="filter-field py-2 px-1"
            @change="updateFileInfo"
            v-model="vectorOptions.filterField"
            placeholder="Optional"
            persistent-placeholder
            hint="Text attribute field to create selectable features"
          >
            <template v-slot:label>
              <div>
                Filter Field
                <v-tooltip
                  left
                  content-class="tool-tip"
                  color="white"
                  max-width="300px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-bind="attrs" v-on="on">
                      mdi-information
                    </v-icon>
                  </template>
                  <v-card>
                    <v-card-title> Filter Field </v-card-title>
                    <v-card-subtitle class="pt-2 text-subtitle-2">
                      If the dataset contains features that should be filterable
                      based on a text attribute field such as a feature name,
                      this property must be explicity set during import. ex. A
                      dataset that contains country polygons, may be filterable
                      by a value such as COUNTRY_NAME.
                    </v-card-subtitle>
                  </v-card>
                </v-tooltip>
              </div>
            </template>
          </v-text-field>
          <v-text-field
            dense
            :disabled="!file && !vectorOptions.filterField"
            class="value-field py-2 px-1"
            @change="updateFileInfo"
            v-model="vectorOptions.valueField"
            placeholder="Optional"
            persistent-placeholder
            hint="Numerical attribute field to use in calculations"
          >
            <template v-slot:label>
              <div>
                Value Field
                <v-tooltip
                  left
                  content-class="tool-tip"
                  color="white"
                  max-width="300px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-bind="attrs" v-on="on">
                      mdi-information
                    </v-icon>
                  </template>
                  <v-card>
                    <v-card-title> Value Field </v-card-title>
                    <v-card-subtitle class="pt-2 text-subtitle-2">
                      If the filtered features should have a numerical value
                      field associated with the geometry to be used in
                      calculations, such as population density or area, this
                      property must be explicity set during import. ex. A
                      dataset that contains city polygons, may be used in
                      expressions by querying the POPULATION value field. A
                      filter field must be set to use this property.
                    </v-card-subtitle>
                  </v-card>
                </v-tooltip>
              </div>
            </template>
          </v-text-field> -->
          <v-text-field
            dense
            :disabled="!file"
            class="layer-name py-2 px-1"
            @change="updateFileInfo"
            v-model="vectorOptions.layerName"
            placeholder="Optional"
            persistent-placeholder
            hint="Layer name from file"
          >
            <template v-slot:label>
              <div>
                Layer Name
                <v-tooltip
                  left
                  content-class="tool-tip"
                  color="white"
                  max-width="300px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-bind="attrs" v-on="on">
                      mdi-information
                    </v-icon>
                  </template>
                  <v-card>
                    <v-card-title> Layer Name </v-card-title>
                    <v-card-subtitle class="pt-2 text-subtitle-2">
                      If the dataset being imported has multiple internal
                      layers, you must explicity select that layer you wish to
                      import by setting this property. By default, the first
                      internal layer will be selected if multiple layers exist.
                      File types such as .KML or .GPKG may have multiple
                      internal layers.
                    </v-card-subtitle>
                  </v-card>
                </v-tooltip>
              </div>
            </template>
          </v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col cols="12" md="10">
        <upload-file-form-date-selector
          :filename="filename"
          @update-date-range="updateDateRange"
        >
        </upload-file-form-date-selector>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UploadFileFormDateSelector from "@/components/datasets/UploadFileFormDateSelector.vue";
export default {
  name: "UploadFileFormVectorFile",

  components: {
    UploadFileFormDateSelector,
  },

  data() {
    return {
      file: null,
      filename: null,
      dateRange: { start: "ind", end: "ind" },
      vectorOptions: {
        filterField: null,
        valueField: null,
        layerName: null,
      },
    };
  },
  methods: {
    updateFileInfo() {
      this.file ? (this.filename = this.file.name) : (this.filename = null);

      const updatedFile = {
        file: this.file,
        filename: this.filename,
        dateRange: this.dateRange,
        vectorOptions: {
          filterField: this.vectorOptions.filterField,
          valueField: this.vectorOptions.valueField,
          layerName: this.vectorOptions.layerName,
        },
      };
      this.$emit("update-file", updatedFile);
    },
    updateDateRange(newDateRange) {
      if (newDateRange.start) {
        this.dateRange = newDateRange;
      } else {
        this.dateRange = { start: "ind", end: "ind" };
      }
      this.updateFileInfo();
    },
  },
};
</script>

<style scoped>
.tool-tip {
  opacity: 1 !important;
  padding: 0;
  color: white;
}
</style>

<template>
  <div class="d-flex flex-column px-2">
    <v-list class="d-flex flex-column" dense>
      <!-- basic details -->
      <v-list-group class="px-0" :value="true">
        <template v-slot:activator>
          <v-list-item-title class="list-group-title"
            >Basic Details</v-list-item-title
          >
        </template>
        <v-list-item two-line>
          <v-list-item-content class="py-0">
            <v-list-item-title>Name </v-list-item-title>
            <v-list-item-subtitle class="text-wrap">
              {{ fullDataset.DatasetName }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <!-- dates -->
        <div class="d-flex">
          <!-- date created -->
          <v-list-item two-line>
            <v-list-item-content class="py-0">
              <v-list-item-title>Date Created</v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                {{ formatDateString(fullDataset.CreatedAt) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!-- date updated -->
          <v-list-item two-line>
            <v-list-item-content class="py-0">
              <v-list-item-title>Last Updated</v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                {{ formatDateString(fullDataset.UpdatedAt) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>

        <!-- ownership -->
        <div class="d-flex">
          <!-- date updated -->
          <v-list-item two-line>
            <v-list-item-content class="py-0">
              <v-list-item-title>Dataset Owner</v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                {{ fullDataset.DatasetOwner }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!-- edit permissions -->
          <v-list-item two-line>
            <v-list-item-content class="py-0">
              <v-list-item-title>Edit Permission</v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                {{ editPermission ? "true" : "false" }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>

        <!-- description -->
        <v-list-item two-line>
          <v-list-item-content class="py-0">
            <v-list-item-title
              >Description
              <v-icon
                v-if="editPermission"
                small
                @click="editDescription = !editDescription"
                >mdi-pencil</v-icon
              ></v-list-item-title
            >
            <v-list-item-subtitle v-if="!editDescription" class="text-wrap">
              {{ description }}
            </v-list-item-subtitle>
            <v-textarea
              v-else
              dense
              v-model="description"
              auto-grow
              class="list-subtitle"
              @change="updateDatasetInfo('editDescription')"
              @blur="closeEditField('editDescription')"
            >
            </v-textarea>
          </v-list-item-content>
        </v-list-item>

        <!-- tags -->
        <v-list-item
          two-line
          dense
          v-click-outside="{
            handler: closeTagField,
          }"
        >
          <v-list-item-content class="py-0 mt-2">
            <v-list-item-title
              >Tags
              <v-icon v-if="editPermission" small @click="editTags = !editTags"
                >mdi-pencil</v-icon
              ></v-list-item-title
            >

            <dataset-tag-manager
              class="d-flex"
              :dataset="dataset"
              :editTags="editTags"
            ></dataset-tag-manager>
          </v-list-item-content>
        </v-list-item>

        <!-- citation -->
        <v-list-item two-line class="pb-0">
          <v-list-item-content class="py-0">
            <v-list-item-title
              >Citations
              <v-icon
                v-if="editPermission"
                small
                @click="editCitation = !editCitation"
                >mdi-pencil</v-icon
              ></v-list-item-title
            >
            <v-list-item-subtitle v-if="!editCitation" class="text-wrap">
              {{ citation }}
            </v-list-item-subtitle>
            <v-textarea
              v-else
              hint="Paste the contents of a .ris file"
              dense
              v-model="citation"
              auto-grow
              class="list-sub-title"
              @change="updateDatasetInfo('editCitation')"
              @blur="closeEditField('editCitation')"
            >
            </v-textarea>

            <!-- no file upload at the moment -->
            <!-- <v-file-input
              v-else
              label="Select a .ris file"
              placeholder="Select a .ris file"
              accept=".ris"
              prepend-icon="mdi-file-document-plus"
              dense
              class="v-list-subtitle"
              @change="updateDatasetInfo('editCitation')"
              @blur="closeEditField('editCitation')"
            ></v-file-input> -->
          </v-list-item-content>
        </v-list-item>

        <!-- acknowledgements -->
        <v-list-item two-line>
          <v-list-item-content class="py-0">
            <v-list-item-title
              >Acknowledgements
              <v-icon
                v-if="editPermission"
                small
                @click="editAcknowledgements = !editAcknowledgements"
                >mdi-pencil</v-icon
              ></v-list-item-title
            >
            <v-list-item-subtitle
              v-if="!editAcknowledgements"
              class="text-wrap"
            >
              {{ acknowledgements }}
            </v-list-item-subtitle>
            <v-textarea
              v-else
              dense
              v-model="acknowledgements"
              auto-grow
              class="list-sub-title"
              @change="updateDatasetInfo('editAcknowledgements')"
              @blur="closeEditField('editAcknowledgements')"
            >
            </v-textarea>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <!-- data details -->
      <v-list-group class="px-0" :value="true">
        <template v-slot:activator>
          <v-list-item-title class="list-group-title"
            >Data Details</v-list-item-title
          >
        </template>
        <!-- data size -->
        <v-list-item two-line>
          <v-list-item-content class="py-0">
            <v-list-item-title>Size</v-list-item-title>
            <v-list-item-subtitle class="text-wrap">
              {{ formatDataSize(fullDataset.DatasetSize) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <div class="d-flex">
          <!-- dataset type -->
          <v-list-item two-line>
            <v-list-item-content class="py-0">
              <v-list-item-title>Dataset Type</v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                {{ fullDataset.DatasetType }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!-- type of dataset -->
          <v-list-item two-line>
            <!-- raster types -->
            <v-list-item-content
              class="py-0"
              v-if="fullDataset.DatasetType === 'Raster'"
            >
              <v-list-item-title> Type of Raster Data </v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                {{
                  fullDataset.TypeOfData
                    ? fullDataset.TypeOfData
                    : "Not specified"
                }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <!-- vector types -->
            <v-list-item-content
              class="py-0"
              v-else-if="fullDataset.DatasetType === 'Vector'"
            >
              <v-list-item-title> Geometry Type </v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                {{
                  fullDataset.GeometryType
                    ? fullDataset.GeometryType
                    : "Not specified"
                }}

                <!-- // : datasetFiles.Files.length > 0 
                // ? datasetFiles.Files[0].geometry -->
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>

        <!-- raster specific attributes-->
        <!-- temporal range & file count-->
        <div class="d-flex" v-if="fullDataset.DatasetType === 'Raster'">
          <v-list-item two-line>
            <v-list-item-content class="py-0">
              <v-list-item-title>Temporal Range</v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                {{
                  fullDataset.DateRangeStart
                    ? `${fullDataset.DateRangeStart}/${fullDataset.DateRangeEnd}`
                    : dataset.DateRange
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content class="py-0">
              <v-list-item-title>Number of Files</v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                {{
                  fullDataset.DatasetType === "Raster"
                    ? fullDataset.NumberOfFiles
                    : "n/a"
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>

        <!-- vector specific attributes-->
        <div v-if="fullDataset.DatasetType === 'Vector'">
          <v-list-item two-line>
            <v-list-item-content class="py-0">
              <v-list-item-title>Layer Count</v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                {{
                  fullDataset.FilterFieldValues
                    ? fullDataset.FilterFieldValues.length
                    : "Not specified"
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content class="py-0">
              <v-list-item-title>Layers</v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                {{
                  fullDataset.FilterFieldValues
                    ? sortCaseInsensitive(fullDataset.FilterFieldValues)
                    : "Not specified"
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>

        <!-- table specific attributes-->
        <div v-if="fullDataset.DatasetType === 'Table'">
          <v-list-item two-line>
            <!-- <v-list-item-content class="py-0">
              <v-list-item-title>Feature Count</v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                {{
                  fullDataset.FilterFieldValues
                    ? fullDataset.FilterFieldValues.length
                    : "Not specified"
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content class="py-0">
              <v-list-item-title>Features</v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                {{
                  fullDataset.FilterFieldValues
                    ? sortCaseInsensitive(fullDataset.FilterFieldValues)
                    : "Not specified"
                }}
              </v-list-item-subtitle>
            </v-list-item-content> -->
          </v-list-item>
        </div>

        <!-- common attributes for raster and table -->

        <!-- units - set by expression -->
        <v-list-item
          two-line
          v-if="
            fullDataset.DatasetType === 'Raster' ||
            fullDataset.DatasetType === 'Vector'
          "
        >
          <v-list-item-content class="py-0">
            <v-list-item-title
              >Units
              <v-icon
                v-if="editPermission"
                small
                @click="editUnits = !editUnits"
                >mdi-pencil</v-icon
              ></v-list-item-title
            >
            <v-list-item-subtitle v-if="!editUnits" class="text-wrap">
              {{ units }}
            </v-list-item-subtitle>
            <v-text-field
              v-else
              dense
              v-model="units"
              auto-grow
              class="v-list-subtitle"
              @change="updateDatasetInfo('editUnits')"
              @blur="closeEditField('editUnits')"
            >
            </v-text-field>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="
            fullDataset.DatasetType === 'Raster' ||
            fullDataset.DatasetType === 'Vector'
          "
        >
          <v-list-item-content class="py-0">
            <v-list-item-title>Spatial Extent</v-list-item-title>
            <v-list class="py-0" dense>
              <v-list-item class="extent-list-item">
                <v-list-item-subtitle class="text-wrap">
                  {{ `Xmin: ${fullDataset.SpatialExtentXmin}` }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item class="extent-list-item">
                <v-list-item-subtitle class="text-wrap">
                  {{ `Xmax: ${fullDataset.SpatialExtentXmax}` }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item class="extent-list-item">
                <v-list-item-subtitle class="text-wrap">
                  {{ `Ymin: ${fullDataset.SpatialExtentYmin}` }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item class="extent-list-item">
                <v-list-item-subtitle class="text-wrap">
                  {{ `Ymax: ${fullDataset.SpatialExtentYmax}` }}
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <!-- scenario -->
      <v-list-group class="px-0" :value="true">
        <template v-slot:activator>
          <v-list-item-title class="list-group-title">{{
            fullDataset.Scenario
              ? `Scenario (${fullDataset.ScenarioName})`
              : "Scenario"
          }}</v-list-item-title>
        </template>
        <v-list-item>
          <v-list-item-content class="py-0">
            <pre
              v-if="
                fullDataset.DatasetType === 'Raster' && fullDataset.Scenario
              "
              id="scenario-text"
              >{{ fullDataset.Scenario }}</pre
            >
            <v-list-item-subtitle v-else>n/a</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </div>
</template>

<script>
import DatasetTagManager from "@/components/datasets/DatasetTagManager.vue";
import { formatDateString } from "@/api/v2.js";
import { updateDatasetInfo } from "@/api/v2.js";
import { formatDataSize, sortCaseInsensitive } from "@/helpers/formatting.js";

export default {
  name: "DatasetPropertiesInformation",

  components: {
    DatasetTagManager,
  },

  props: {
    dataset: { type: Object, default: () => {} },
    fullDataset: {
      type: Object,
      default() {
        return {
          Acknowledgements: "",
          CitationRIS: "",
          Description: "",
          Units: "",
        };
      },
    },
    datasetFiles: { type: Array, default: () => [] },
    editPermission: { type: Boolean, default: false },
  },

  data() {
    return {
      description: this.fullDataset.Description,
      editDescription: false,
      units: this.fullDataset.Units,
      editUnits: false,
      citation: this.fullDataset.CitationRIS,
      editCitation: false,
      acknowledgements: this.fullDataset.Acknowledgements,
      editAcknowledgements: false,
      editTags: false,
    };
  },

  methods: {
    formatDateString,
    formatDataSize,
    sortCaseInsensitive,

    closeEditField(property) {
      if (property) {
        let propertyUpdated = property;
        this[propertyUpdated] = false;
      } else {
        this.editTags = false;
      }
    },

    closeTagField() {
      this.editTags = false;
    },

    updateDatasetInfo(property) {
      const datasetName = this.fullDataset.DatasetName;
      const datasetOwner = this.fullDataset.DatasetOwner;
      const newInfo = {
        Description: this.description,
        Units: this.units,
        CitationRIS: this.citation,
        Acknowledgements: this.acknowledgements,
      };

      this.closeEditField(property);

      updateDatasetInfo(datasetName, datasetOwner, newInfo)
        .then((response) => {
          this.$emit("update-dataset-info", newInfo);
          if (response.Warnings) {
            this.$showAlert({
              text: response.Warnings,
              type: "warning",
            });
          }
        })
        .catch((errors) => {
          if (errors) {
            this.$showAlert({
              text: errors,
              type: "error",
            });
          }
          if (errors.response.data.Errors) {
            this.$showAlert({
              text: errors.response.data.Errors,
              type: "error",
            });
          }
        });
    },

    // will be used for file input
    // updatedDatasetCitation() {
    //   console.log("nothing here yet");
    // },
  },
};
</script>

<style scoped>
v-list-item-subtitle {
  font-weight: 400 !important;
  font-size: 0.8rem !important;
  line-height: 1.25rem;
}
.list-subtitle {
  font-weight: 400 !important;
  font-size: 0.8rem !important;
  line-height: 1.25rem;
}

.list-group-title {
  font-size: 1rem !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
}

pre {
  font-family: "courier new", courier, monospace;
  font-size: 0.8em;
  height: auto;
  overflow-x: auto;
}

.extent-list-item {
  padding: 0;
  min-height: fit-content;
}
</style>

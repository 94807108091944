<template>
  <div class="pa-2">
    <job-list></job-list>
  </div>
</template>

<script>
import JobList from "@/components/tables/JobList.vue";

export default {
  name: "JobQueues",

  components: {
    JobList,
  },
};
</script>

<template>
  <v-card light flat>
    <div class="pa-2">
      <div class="d-flex align-center">
        {{ layerName }}<v-spacer></v-spacer
        ><v-btn icon small @click="removeDataset()">
          <v-icon small color="error">mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="d-flex justify-space-between flex-nowrap pa-2">
        <div class="d-flex justify-space-around flex-nowrap">
          <v-dialog
            v-model="showDialogLayerEditor"
            max-width="600px"
            scrollable
          >
            <template v-slot:activator="{ on: dialog, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...dialog }"
                    icon
                    small
                  >
                    <v-icon>mdi-information</v-icon>
                  </v-btn>
                </template>
                Show Layer Properties
              </v-tooltip>
            </template>

            <dataset-properties-information-quick-look
              :layer="flowLayer.layer"
              :dataset="flowLayer.dataset"
            >
            </dataset-properties-information-quick-look>
          </v-dialog>
          <map-drawer-layer-stats
            :flow-layer="flowLayer"
            :disabled="flowLayer.mapLayerPurpose === 'StudyAreaMapLayer'"
            class="ml-2"
          >
          </map-drawer-layer-stats>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                small
                @click="showInTableDrawer = !showInTableDrawer"
                :disabled="!hasAssociatedTables"
                :loading="tableDrawerLoading"
              >
                <v-icon :color="inTableDrawer ? 'orange' : 'grey'"
                  >mdi-table</v-icon
                >
              </v-btn>
            </template>
            <span>
              {{
                !inTableDrawer
                  ? "View associated tables"
                  : "Remove tables from panel"
              }}
            </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                small
                @click="showInQueryBuilder(activeQuery)"
                :disabled="!isQueryable"
              >
                <v-icon :color="activeQuery ? 'orange' : 'grey'"
                  >mdi-filter</v-icon
                >
              </v-btn>
            </template>
            <span>
              {{
                !activeQuery ? "Add query to layer" : "Remove query from layer"
              }}
            </span>
          </v-tooltip>
          <map-layer-symbology
            :flow-layer="flowLayer"
            :edit-permissions="editPermissions"
            :allow-advanced-symbology="!limitedFunctionality"
          ></map-layer-symbology>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon small @click="zoomToExtent">
                <v-icon>mdi-target</v-icon>
              </v-btn>
            </template>
            Zoom to layer extent
          </v-tooltip>
        </div>
        <div class="d-flex align-center flex-wrap">
          <v-btn-toggle v-model="layerOptions" multiple>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  small
                  @change="layerVisibility = !layerVisibility"
                >
                  <v-icon :color="layerVisibility ? 'green' : 'grey'">{{
                    layerVisibility ? "mdi-eye" : "mdi-eye-off"
                  }}</v-icon>
                </v-btn>
              </template>
              {{ layerVisibility ? "Hide layer on map" : "Show layer on map" }}
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  small
                  :disabled="
                    flowLayer.mapLayerPurpose === 'StudyAreaMapLayer' ||
                    !isTimeSeries
                  "
                  @change="showInChart = !showInChart"
                >
                  <v-icon :color="showInChart ? 'blue' : 'grey'"
                    >mdi-chart-line</v-icon
                  >
                </v-btn>
              </template>
              <span>
                {{
                  showInChart
                    ? "Remove layer stats from chart"
                    : "Add layer stats to chart"
                }}
              </span>
            </v-tooltip>
          </v-btn-toggle>
        </div>
      </div>
    </div>

    <map-layer-features
      v-if="allowFeatureSelection.allow"
      :flow-layer="flowLayer"
    ></map-layer-features>
  </v-card>
</template>

<script>
import MapDrawerLayerStats from "@/components/mapping/MapDrawerLayerStats.vue";
import MapLayerSymbology from "@/components/mapping/MapLayerSymbology.vue";
import MapLayerFeatures from "@/components/mapping/MapLayerFeatures.vue";
import DatasetPropertiesInformationQuickLook from "@/components/datasets/DatasetPropertiesInformationQuickLook.vue";

export default {
  name: "MapLayer",

  components: {
    MapDrawerLayerStats,
    MapLayerSymbology,
    MapLayerFeatures,
    DatasetPropertiesInformationQuickLook,
  },

  props: {
    flowLayer: Object,
    limitedFunctionality: { type: Boolean, default: true },
  },

  data() {
    return {
      // toggle visibility on when layer mounts, chart off
      layerOptions: [0],
      layerVisibility: true,
      showInChart: false,
      showInTableDrawer: false,
      tableDrawerLoading: false,
      showDialogLayerEditor: false,
      virtualLayerMode: true,
    };
  },

  watch: {
    showInChart(value) {
      if (value) {
        this.$store.commit("mapping/addLayerToChart", this.flowLayer);
      } else {
        this.$store.commit("mapping/removeLayerFromChart", this.flowLayer);
      }
    },
    showInTableDrawer(value) {
      if (value) {
        this.updateTableDrawer();
      } else {
        this.$store.commit("mapping/removeLayerTableDrawer", this.flowLayer);
      }
    },
    mapLayerVisible(value) {
      this.layerVisibility = value;
    },
    layerVisibility(value) {
      this.$store.commit("mapping/toggleFlowLayerVisibility", {
        datasetKey: this.flowLayer.key,
        visibility: value ? "visible" : "none",
      });
    },
  },

  computed: {
    chartDatasets() {
      return this.$store.state.mapping.chartData;
    },
    limitedDataset() {
      return this.$store.state.accountData.datasetList.datasets.AllDatasets.find(
        (dataset) =>
          dataset.DatasetName === this.flowLayer.dataset.DatasetName &&
          dataset.DatasetOwner === this.flowLayer.dataset.DatasetOwner
      );
    },
    layerName() {
      return this.flowLayer.dataset.Style.DisplayName;
    },
    datasetLayerNames() {
      return this.flowLayer.dataset.Layers.map((layer) => layer.LayerName);
    },
    editPermissions() {
      return this.checkEditPermissions();
    },
    mapLayerVisible() {
      return this.$store.state.mapping.visibleDatasets.includes(
        this.flowLayer.key
      );
    },
    currentDisplayedLayerFile() {
      return this.flowLayer.files.filter(
        (file) => file.mapLayer.layout.visibility === "visible"
      );
    },
    approxExtent() {
      return this.flowLayer.files[0].stats;
    },

    allowFeatureSelection() {
      return this.$store.state.settings.dataSelectionType.allowFeatureSelection;
    },
    isTimeSeries() {
      return !!this.flowLayer.layer.Dates;
    },
    hasAssociatedTables() {
      return !!this.flowLayer.layer.AssociatedTables;
    },
    inTableDrawer() {
      if (
        this.$store.state.mapping.tableData?.find(
          (table) => table.key === this.flowLayer.key
        )
      )
        return true;
      else return false;
    },
    isQueryable() {
      return (
        this.flowLayer.dataset.DatasetType === "Raster" &&
        this.flowLayer.mapLayerPurpose === "DatasetMapLayer"
      );
    },

    activeQuery() {
      const queryLoaded = this.$store.state.mapping.queryBuilderData.find(
        (dataset) => dataset.originalLayer?.key === this.flowLayer.key
      );

      if (queryLoaded === undefined || queryLoaded === -1) return false;
      else return true;
    },
  },

  methods: {
    removeDataset() {
      this.$store.commit("mapping/removeLayerFromQueryBuilder", this.flowLayer);
      this.$store.commit("mapping/removeLayerTableDrawer", this.flowLayer);
      this.$store.commit("mapping/removeLayerFromChart", this.flowLayer);
      this.$store.commit("mapping/removeDataset", this.flowLayer);
    },

    checkEditPermissions() {
      const hasPermissions = this.$store.getters[
        "account/checkEditPermissions"
      ](this.flowLayer.dataset.Tags);

      return hasPermissions;
    },

    async updateTableDrawer() {
      this.tableDrawerLoading = true;
      await this.$store.dispatch("mapping/getAssociatedTables", this.flowLayer);
      this.tableDrawerLoading = false;
    },

    // Updates value for opacity and commits opacity property information to store
    layerOpacity(opacitySliderValue) {
      this.$store.commit("mapping/updateLayerOpacity", {
        key: this.flowLayer.key,
        opacityValue: opacitySliderValue,
      });
    },
    updateDataset(dataset) {
      this.$emit("update-dataset", dataset);
    },
    zoomToExtent() {
      this.$store.commit(
        "mapping/zoomToLayerExtent",
        this.currentDisplayedLayerFile[0]
      );
    },
    closeDatasetPropertiesDialog() {
      this.dialogDatasetProperties = false;
    },

    showChartInitially(mapLayerPurpose, isTimeSeries, chartDatasetsCount) {
      if (
        mapLayerPurpose === "DatasetMapLayer" &&
        isTimeSeries &&
        chartDatasetsCount === 0
      ) {
        return true;
      } else false;
    },

    showInQueryBuilder(activeQuery) {
      if (activeQuery)
        this.$store.commit(
          "mapping/removeLayerFromQueryBuilder",
          this.flowLayer
        );
      else this.$store.commit("mapping/addLayerToQueryBuilder", this.flowLayer);
    },
  },

  mounted() {
    this.showInChart = this.showChartInitially(
      this.flowLayer.mapLayerPurpose,
      this.isTimeSeries,
      this.chartDatasets.length
    );

    if (this.hasAssociatedTables) {
      this.showInTableDrawer = true;
    }
  },
};
</script>

<style scoped></style>

<template>
  <v-card>
    <div class="px-5">
      <v-card-title class="text-h5 py-6"
        >Create a new grazing season</v-card-title
      >
      <v-card-subtitle class="pb-4">
        There are two options to create a new grazing season:
        <ul class="pb-3">
          <li>
            <strong>Option 1:</strong> Import existing grazing season data from
            an Excel spreadsheet using the provided templates.
          </li>
          <li>
            <strong>Option 2:</strong> Choose a season type, either open or
            closed, and manually enter the details. If this is a new ranch
          </li>
        </ul>
        If you choose to fill in the details manually, the form will be
        pre-filled with data from the previous season (if available). You can
        also customize the season plan by using the optional planning features
        (available features may vary depending on the season type).
      </v-card-subtitle>

      <v-list
        subheader
        dense
        class="d-flex flex-row align-center justify-space-between flex-wrap"
      >
        <v-subheader class="py-0 px-4 font-weight-bold"
          >Grazing Season Plan Templates:</v-subheader
        >

        <div class="d-flex flex-row justify-space-between">
          <v-list-item dense>
            <v-list-item-content>
              <v-list-item-title>Open Season</v-list-item-title>
              <v-list-item-subtitle
                ><a
                  class="py-2"
                  href="https://alces-flow-data.s3.us-west-2.amazonaws.com/resources/Grazing+Season+(Open).xlsx"
                  >Download</a
                ></v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Closed Season</v-list-item-title>
              <v-list-item-subtitle
                ><a
                  class="py-2"
                  href="https://alces-flow-data.s3.us-west-2.amazonaws.com/resources/Grazing+Season+(Open).xlsx"
                  >Download</a
                ></v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </div>

    <v-card-text>
      <!-- season files -->
      <v-form
        v-model="seasonFiles.formValid"
        ref="seasonFilesForm"
        id="new-season-wizard"
      >
        <section id="season-files">
          <v-subheader class="text-h6 black--text pl-0"
            >Import Grazing Season Files</v-subheader
          >

          <v-row v-for="row in seasonFiles.formRows" :key="row.question">
            <v-col cols="4">
              <p class="text-body-2 black--text">
                {{ row.question }}
              </p>
            </v-col>
            <v-col cols="4" class="d-flex justify-space-between">
              <v-radio-group row mandatory v-model="row.response" hide-details>
                <v-radio
                  v-for="(question, index) in row.questionOptions"
                  :key="index"
                  :label="question.label"
                  :value="question.value"
                  :disabled="row.disabled"
                  color="black"
                ></v-radio></v-radio-group
            ></v-col>
            <v-col cols="12" lg="4" v-if="row.response">
              <v-file-input
                class="text-body-2 text-no-wrap"
                style="overflow-x: hidden"
                :disabled="row.userFileInput.props.disabled"
                :accept="row.userFileInput.props.accept"
                v-model="row.userFileInput.file"
                :rules="row.userFileInput.props.rules"
                :placeholder="row.userFileInput.props.placeholder"
                single-line
                color="black"
                flat
                outlined
                truncate-length="15"
                :prepend-icon="row.userFileInput.props.icon"
                :clearable="false"
                :required="row.response"
              >
              </v-file-input>
            </v-col>
          </v-row>
        </section>

        <section
          id="season-parameters"
          v-if="!seasonFiles.formRows.GrazingRecord.response"
        >
          <div class="d-flex justify-space-between">
            <v-subheader class="text-h6 black--text pl-0"
              >Initialize Season Plan Details</v-subheader
            >
          </div>

          <v-row>
            <v-col cols="4">
              <p class="text-body-2 black--text">
                {{ seasonType.question }}
              </p></v-col
            >

            <v-col cols="4" class="d-flex justify-space-between">
              <v-radio-group
                v-model="seasonType.response"
                row
                mandatory
                hide-details
                :disabled="seasonType.disabled"
              >
                <v-radio
                  v-for="(question, index) in seasonType.questionOptions"
                  :key="index"
                  :label="question.label"
                  :value="question.value"
                  color="black"
                ></v-radio> </v-radio-group
            ></v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex justify-center">
              <v-btn
                class="text-capitalize"
                @click="
                  populateForm(currentUser, ranchName, seasonType, seasonFiles)
                "
                color="primary"
                :loading="initialFormPopulating"
                :disabled="initialFormPopulating || initialFormResponse"
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>

          <div v-if="initialFormResponse">
            <v-row>
              <v-col cols="4">
                <p class="text-body-2 black--text">First day of season:</p>
              </v-col>
              <v-col cols="6">
                <v-menu
                  v-model="firstDayMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  :nudge-right="40"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="formBody.StartDate"
                      hint="YYYY-MM-DD"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-on="on"
                      flat
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formBody.StartDate"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <p class="text-body-2 black--text">
                  Estimated last day of season:
                </p>
              </v-col>
              <v-col cols="6">
                <v-menu
                  v-model="lastDayMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  :nudge-right="40"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="formBody.EndDate"
                      hint="YYYY-MM-DD"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-on="on"
                      flat
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formBody.EndDate"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <p class="text-body-2 black--text">Recovery period bounds:</p>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="formBody.RecoveryDaysMin"
                  type="number"
                  hint="Min days"
                  persistent-hint
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="formBody.RecoveryDaysMax"
                  type="number"
                  hint="Max days"
                  persistent-hint
                  flat
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </section>

        <section
          id="season-grazing-cells"
          class="d-flex flex-column flex-nowrap overflow-x-auto"
          v-if="initialFormResponse"
        >
          <v-container
            id="grazing-cell-table-container"
            class="d-flex flex-column"
          >
            <v-subheader class="text-h6 black--text pl-0">Cells</v-subheader>
            <v-row class="d-flex flex-nowrap header">
              <v-col cols="3">
                <v-text-field
                  class="d-flex align-center"
                  v-model="newGrazingCellName"
                  append-outer-icon="mdi-plus"
                  @click:append-outer="addGrazingCell"
                  @keyup.enter="addGrazingCell"
                  placeholder="New grazing cell"
                  :rules="[
                    (v) =>
                      !formBody.Cells.some((cell) => cell.CellName === v) ||
                      'Cell names must be unique',
                  ]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="1"></v-col>
              <v-col
                cols="3"
                v-for="(grazingCell, index) in formBody.Cells"
                :key="grazingCell._key"
              >
                <!-- allow removal of new cells -->
                <div class="remove-icon-container">
                  <div class="remove-cell-icon">
                    <v-icon
                      color="error"
                      @click="removeGrazingCell(grazingCell)"
                      >mdi-close-circle</v-icon
                    >
                  </div>

                  <v-text-field
                    class="d-flex align-center"
                    v-model="grazingCell.CellName"
                    flat
                    outlined
                    :disabled="index > 0"
                  >
                  </v-text-field>
                </div>
              </v-col>
            </v-row>

            <v-subheader class="text-h6 black--text pl-0 pb-2"
              >Paddocks</v-subheader
            >
            <v-row class="d-flex flex-nowrap" v-if="formBody.Cells.length > 0">
              <v-col cols="4"></v-col>
              <v-col
                v-for="grazingCell in formBody.Cells"
                :key="grazingCell._key"
                cols="3"
                class="d-flex justify-center"
              >
                <v-select
                  multiple
                  :items="grazingCell.PaddockIDs"
                  v-model="grazingCell.PaddockIDs"
                  label="Paddocks assigned to cell"
                  persistent-placeholder
                  placeholder="Select paddocks"
                  outlined
                  flat
                  no-data-text=""
                >
                  <template v-slot:prepend-item>
                    <v-list-item class="grey--text text-caption"
                      >Assigned Paddocks</v-list-item
                    >
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item
                      :key="item"
                      ripple
                      color="grey-lighten-3"
                      @mousedown.prevent
                      @click="unAssignPaddock(grazingCell, item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ item }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:append-item>
                    <v-divider class="mt-2"></v-divider>
                    <v-list-item class="grey--text text-caption"
                      >Unassigned Paddocks</v-list-item
                    >
                    <v-list-item
                      v-for="paddock in unAssignedPaddockIDs"
                      :key="paddock"
                      ripple
                      @mousedown.prevent
                      @click="assignPaddock(grazingCell, paddock)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ paddock }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ index }">
                    <span v-if="index === 0" class="grey--text text-caption">
                      ({{ grazingCell.PaddockIDs.length }}) paddocks
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <div>
              <v-row
                v-if="seasonType.response === 'Open'"
                class="d-flex flex-nowrap"
              >
                <v-col cols="4">
                  <p class="text-body-2 black--text">Initial recovery time:</p>
                </v-col>
                <v-col
                  cols="3"
                  v-for="grazingCell in formBody.Cells"
                  :key="grazingCell._key"
                >
                  <v-text-field
                    v-model="grazingCell.InitialRecoveryDays"
                    placeholder="0"
                    flat
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row
                v-if="seasonType.response === 'Closed'"
                class="d-flex flex-nowrap"
              >
                <v-col cols="4">
                  <p class="text-body-2 black--text">
                    Estimated days of bulk feeding:
                  </p>
                </v-col>
                <v-col
                  cols="3"
                  v-for="grazingCell in formBody.Cells"
                  :key="grazingCell._key"
                >
                  <v-text-field
                    v-model="grazingCell.EstimatedDaysOfBulkFeeding"
                    placeholder="0"
                    flat
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row
                v-if="seasonType.response === 'Closed'"
                class="d-flex flex-nowrap"
              >
                <v-col cols="4">
                  <p class="text-body-2 black--text">Number of selections:</p>
                </v-col>
                <v-col
                  cols="3"
                  v-for="grazingCell in formBody.Cells"
                  :key="grazingCell._key"
                >
                  <v-text-field
                    v-model="grazingCell.NumberOfSelections"
                    placeholder="0"
                    flat
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>

            <div>
              <v-subheader class="text-h6 black--text pl-0">Herds</v-subheader>
            </div>

            <v-row class="d-flex flex-nowrap">
              <v-col cols="3">
                <v-text-field
                  class="d-flex align-center"
                  v-model="newHerdName"
                  append-outer-icon="mdi-plus"
                  @click:append-outer="addHerd"
                  @keyup.enter="addHerd"
                  placeholder="New herd"
                  :rules="[
                    (v) =>
                      !HerdsNames.some((herd) => herd === v) ||
                      'Herd names must be unique',
                  ]"
                >
                </v-text-field>

                <p>Unassigned Herds</p>
                <p v-for="herd in unAssignedHerdNames" :key="herd">
                  {{ herd }}
                </p>
              </v-col>
              <v-col cols="1"></v-col>
              <v-col
                v-for="grazingCell in formBody.Cells"
                :key="grazingCell._key"
                cols="3"
                class="d-flex justify-center"
              >
                <v-select
                  multiple
                  :items="grazingCell.HerdsNames"
                  v-model="grazingCell.HerdsNames"
                  label="Herds associated with cell"
                  persistent-placeholder
                  placeholder="Select herds"
                  outlined
                  flat
                  no-data-text=""
                >
                  <template v-slot:prepend-item>
                    <v-list-item class="grey--text text-caption"
                      >Associated Herds</v-list-item
                    >
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item
                      :key="item"
                      ripple
                      color="grey-lighten-3"
                      @mousedown.prevent
                      @click="unAssignHerd(grazingCell, item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ item }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <template v-slot:append-item>
                    <v-divider class="mt-2"></v-divider>
                    <v-list-item class="grey--text text-caption"
                      >Available Herds</v-list-item
                    >
                    <v-list-item
                      v-for="herd in unAssignedHerdNames"
                      :key="herd"
                      ripple
                      @mousedown.prevent
                      @click="assignHerd(grazingCell, herd)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ herd }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <template v-slot:selection="{ index }">
                    <span v-if="index === 0" class="grey--text text-caption">
                      ({{ grazingCell.HerdsNames.length }}) herds
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        class="text-capitalize"
        @click="cancelNewSeason()"
        :disabled="creatingNewSeason"
        >Cancel</v-btn
      >
      <v-btn
        text
        class="text-capitalize"
        @click="createNewSeason(currentUser, ranchName, seasonFiles, formBody)"
        :loading="creatingNewSeason"
        >Create Season</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { getPresignedPost } from "@/api/v2.js";

import {
  uploadSeasonFiles,
  uploadPaddockBoundaries,
  postNewSeasonData,
  getNewSeasonData,
} from "@/api/geoprocessing.js";

export default {
  name: "NewSeason",

  props: {
    ranchName: {
      type: String,
    },
  },

  data() {
    return {
      seasonFiles: {
        formValid: false,
        formRows: {
          PaddockBoundaries: {
            question:
              "Import the paddock boundaries from a mapping vector file, such as a .kml, .gpkg or a .zip file containing the .shp and supporting files.",
            questionOptions: [
              // { label: "No", value: false },
              // { label: "Yes", value: true },
            ],
            disabled: true,
            response: true,
            userFileInput: {
              props: {
                accept: ".zip, .gpkg, .geojson, .kml",
                label: "Paddock Boundaries Vector File",
                placeholder: "Paddock Boundaries.kml",
                icon: "mdi-file-upload",
                rules: [(v) => !!v || "Paddock boundaries are required"],
                disabled: false,
              },
              file: null,
              uploading: false,
              s3URI: null,
            },
          },
          GrazingRecord: {
            question: "Import the season grazing plan from an Excel file?",
            questionOptions: [
              { label: "No", value: false },
              { label: "Yes", value: true },
            ],
            disabled: false,
            response: false,
            userFileInput: {
              props: {
                accept: ".xlsx",
                label: "Grazing Season Excel File",
                placeholder: "Grazing Season Plan.xlsx",
                icon: "mdi-table-arrow-up",
                rules: [(v) => !!v || "Grazing season plan file is required"],
              },
              file: null,
              uploading: false,
              s3URI: null,
            },
          },
        },
      },

      seasonType: {
        question: "Select a season type:",
        questionOptions: [
          { label: "Open", value: "Open" },
          { label: "Closed", value: "Closed" },
        ],
        disabled: false,
        response: "Open",
      },

      initialFormPopulating: false,
      initialFormResponse: null,

      formBody: {
        RanchName: "",
        PaddockBoundariesS3Uris: [],
        Query: null,
        StartDate: "",
        EndDate: "",
        RanchOwner: "",
        SeasonType: "",
        RecoveryDaysMin: 0,
        RecoveryDaysMax: 0,
        Cells: [
          {
            CellName: "",
            PaddockIDs: [],
            HerdsNames: [],
            InitialRecoveryDays: 0,
            EstimatedDaysOfBulkFeeding: 0,
            NumberOfSelections: 0,
          },
        ],

        SeasonName: "",
        TimeZone: "",
        ForageCarryoverRecords: [],
      },
      originalFormBody: {},

      firstDayMenu: false,
      lastDayMenu: false,
      grazingCellTemplate: {
        CellName: "",
        PaddockIDs: [],
        HerdsNames: [],
        InitialRecoveryDays: null,
        EstimatedDaysOfBulkFeeding: null,
        NumberOfSelections: null,
      },
      grazingCells: [],
      HerdsNames: [],
      newGrazingCellName: "",
      newHerdName: "",

      creatingNewSeason: false,
    };
  },

  computed: {
    formsValid() {
      return this.seasonFiles.formValid;
    },

    currentUser() {
      return this.$store.state.account.user.attributes.email;
    },

    unAssignedPaddockIDs() {
      // Calculate unassigned paddocks based on all paddocks and selected paddocks
      const allPaddocks = this.formBody.PaddockIDs;
      const assignedPaddocks = this.getAllAssignedPaddocks();
      return allPaddocks
        .filter((paddock) => !assignedPaddocks.includes(paddock))
        .sort((a, b) => a - b);
    },

    unAssignedHerdNames() {
      // Calculate unassigned herds based on all cells and new herds
      const allHerds = this.HerdsNames;
      const assignedHerds = this.getAllAssignedHerds();
      return allHerds
        .filter((herd) => !assignedHerds.includes(herd))
        .sort((a, b) => a - b);
    },
  },

  methods: {
    cancelNewSeason() {
      this.$refs.seasonFilesForm.reset();
      this.$emit("close-new-season-dialog");
    },

    async createNewSeason(currentUser, ranchName, seasonFiles, formBody) {
      this.creatingNewSeason = true;

      if (seasonFiles.formRows["GrazingRecord"].response) {
        try {
          if (!this.$refs.seasonFilesForm.validate()) {
            throw new Error("Validation failed");
          }

          await this.uploadFilesToS3(seasonFiles);

          await this.submitSeasonFiles(
            ranchName,
            seasonFiles.formRows["GrazingRecord"].userFileInput.s3URI,
            [seasonFiles.formRows["PaddockBoundaries"].userFileInput.s3URI]
          );

          await this.handleSeasonUploadSuccess(currentUser, ranchName);
        } catch (error) {
          this.handleUploadError(error);
        } finally {
          this.creatingNewSeason = false;
        }
      } else {
        try {
          await uploadPaddockBoundaries(
            ranchName,
            [seasonFiles.formRows["PaddockBoundaries"].userFileInput.s3URI],
            null,
            formBody.StartDate,
            formBody.EndDate
          );

          await this.handleSeasonUploadSuccess(
            currentUser,
            ranchName,
            formBody
          );
        } catch (error) {
          this.handleUploadError(error);
        } finally {
          this.creatingNewSeason = false;
        }
      }
    },

    async uploadFilesToS3(seasonFiles) {
      for (const value of Object.values(seasonFiles.formRows)) {
        value.disabled = true;
        value.userFileInput.props.disabled = true;

        if (value.response) {
          try {
            const response = await this.uploadNewFile(value.userFileInput.file);
            if (response) {
              value.userFileInput.s3URI = response;
              value.userFileInput.uploading = false;
            }
          } catch (error) {
            console.error("Error uploading file to S3:", error);
            value.userFileInput.props.disabled = false;
            throw error;
          }
        }
      }
    },

    async uploadNewFile(file) {
      try {
        const preSignedFileUrl = await getPresignedPost(file.name);

        let bodyFormData = new FormData();
        for (const [key, value] of Object.entries(
          preSignedFileUrl.FormFields
        )) {
          bodyFormData.append(key, value);
        }
        bodyFormData.append("file", file);

        const response = await fetch(preSignedFileUrl.Url, {
          method: "POST",
          body: bodyFormData,
        });

        if (!response.ok) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }

        return `s3://alces-flow-data/${preSignedFileUrl.FormFields.key}`;
      } catch (error) {
        console.error("Error uploading file:", error);
        throw error;
      }
    },

    async populateForm(ranchOwner, ranchName, seasonType, seasonFiles) {
      if (this.$refs.seasonFilesForm.validate()) {
        try {
          this.initialFormPopulating = true;
          this.seasonType.disabled = true;

          await this.uploadFilesToS3(seasonFiles);

          const predictedFormData = await getNewSeasonData({
            PaddockBoundariesS3Uris: [
              seasonFiles.formRows["PaddockBoundaries"].userFileInput.s3URI,
            ],
            Query: null,
            RanchName: ranchName,
            RanchOwner: ranchOwner,
            SeasonType: seasonType.response,
          });

          // add a key to each cell
          predictedFormData.Cells.map((cell) => {
            cell._key = this.createRandomKey();
            return cell;
          });

          this.HerdsNames = this.getAllAssignedHerds();

          this.formBody = structuredClone(predictedFormData);
          this.originalFormBody = structuredClone(predictedFormData);

          this.initialFormResponse = true;
        } catch (error) {
          this.handleUploadError(error);
        } finally {
          this.initialFormPopulating = false;
        }
      }
    },

    async submitSeasonFiles(ranchName, grazingRecordURI, paddockBoundariesURI) {
      try {
        //  seasonSubmittedPart2
        await uploadSeasonFiles(
          ranchName,
          grazingRecordURI,
          paddockBoundariesURI,
          null,
          2
        );

        try {
          //  seasonSubmittedPart1
          await uploadSeasonFiles(
            ranchName,
            grazingRecordURI,
            paddockBoundariesURI,
            null,
            1
          );
        } catch (error) {
          if (error.response.status >= 500) throw error;
        }
      } catch (error) {
        console.error("Error submitting season parts:", error);
        throw error;
      }
    },

    // Handling successful upload of season files
    async handleSeasonUploadSuccess(currentUser, ranchName, formBody = null) {
      try {
        // update season data from ui form
        if (formBody) {
          await postNewSeasonData(formBody);
        }

        // refresh updated map dataset
        await this.$store.dispatch("mapping/getSingleDataset", {
          datasetName: ranchName,
          datasetOwner: currentUser,
        });
      } catch (error) {
        console.error("Error getting updated ranch dataset:", error);
        throw error;
      }

      this.$showAlert({
        text: "Season successfully added. <br> It may take up to 10 minutes for the season to be viewable in the map.",
        type: "success",
        timeout: 3000,
      });

      try {
        // refresh updated season list
        await this.$store.dispatch("accountData/refreshSeasonList", {
          datasetName: ranchName,
          datasetOwner: currentUser,
        });
      } catch (error) {
        console.error("Error getting updated season list:", error);
        throw error;
      }

      this.$emit("close-new-season-dialog");
    },

    handleUploadError(error) {
      // Handle different types of errors uniformly or specifically
      if (error.response) {
        this.$showAlert({
          text: error.response.data.Errors || error.response.data.message,
          type: "error",
        });
      } else {
        this.$showAlert({
          text: error.message || error,
          type: "error",
        });
      }
    },

    addGrazingCell() {
      if (
        this.newGrazingCellName &&
        !this.formBody.Cells.some(
          (cell) => cell.CellName === this.newGrazingCellName
        )
      ) {
        const newGrazingCell = { ...this.grazingCellTemplate };
        newGrazingCell.CellName = this.newGrazingCellName;
        newGrazingCell._key = this.createRandomKey();
        this.formBody.Cells.push(newGrazingCell);
        this.newGrazingCellName = "";
      }
    },

    createRandomKey() {
      let r = (Math.random() + 1).toString(36).substring(7);
      return r;
    },

    removeGrazingCell(grazingCell) {
      const index = this.formBody.Cells.findIndex(
        (cell) => cell._key === grazingCell._key
      );

      if (index !== -1) {
        // unassign all paddocks before removing cell
        this.unAssignAllPaddocks(grazingCell);

        this.formBody.Cells.splice(index, 1);
      }
    },

    getAllAssignedPaddocks() {
      let paddockNames = new Set();

      this.formBody.Cells.forEach((cell) => {
        cell.PaddockIDs.forEach((id) => {
          paddockNames.add(id);
        });
      });

      return Array.from(paddockNames);
    },

    assignPaddock(grazingCell, paddock) {
      grazingCell.PaddockIDs.push(paddock);
    },

    unAssignPaddock(grazingCell, paddock) {
      const index = grazingCell.PaddockIDs.indexOf(paddock);
      if (index !== -1) {
        grazingCell.PaddockIDs.splice(index, 1);
      }
    },

    unAssignAllPaddocks(grazingCell) {
      let index = grazingCell.PaddockIDs.length - 1;

      while (index > 0) {
        grazingCell.PaddockIDs.splice(index, 1);
        index--;
      }
    },

    getAllAssignedHerds() {
      let herdNames = new Set();

      this.formBody.Cells.forEach((cell) => {
        cell.HerdsNames.forEach((herd) => {
          herdNames.add(herd);
        });
      });

      return Array.from(herdNames);
    },

    assignHerd(grazingCell, herd) {
      grazingCell.HerdsNames.push(herd);
    },

    unAssignHerd(grazingCell, herd) {
      const index = grazingCell.HerdsNames.indexOf(herd);
      if (index !== -1) {
        grazingCell.HerdsNames.splice(index, 1);
      }
    },

    addHerd() {
      if (
        this.newHerdName &&
        !this.HerdsNames.some((herd) => herd === this.newHerdName)
      ) {
        this.HerdsNames.push(this.newHerdName);
        this.newHerdName = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#new-season-wizard {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#grazing-cell-table-container {
  overflow-x: auto;
  gap: 1rem;
}

#season-files,
#season-parameters,
#season-grazing-cells {
  padding: 10px;
  border-radius: 4px;
  background-color: #e1e1e0;
}

.remove-icon-container {
  position: relative;
}

.remove-cell-icon {
  position: absolute;
  top: 4px;
  right: 0px;
  z-index: 2;
}

::v-deep .v-text-field--outlined .v-input__control .v-input__slot {
  background-color: white;
  min-height: unset;
}

::v-deep .v-select--outlined .v-input__control .v-input__slot {
  background-color: white;
  min-height: unset;
}

::v-deep .v-text-field--outlined .v-input__prepend-outer,
::v-deep .v-text-field--outlined .v-input__append-outer {
  margin-top: 6px;
}
</style>

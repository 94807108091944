var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column justify-center"},[_vm._m(0),_c('code',{staticStyle:{"padding-bottom":"15px"}},[_vm._v("if(condition, result if true, result if false)")]),_c('v-divider'),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('v-divider'),_vm._m(5),_vm._m(6)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v("The "),_c('code',[_vm._v("if")]),_vm._v(" method takes 3 arguments in the form:")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v("Conditions are created using the "),_c('code',[_vm._v("cond")]),_vm._v(" method. For example:")])
},function (){var _vm=this,_c=_vm._self._c;return _c('code',[_vm._v("# Select all data with a value of 22"),_c('br'),_vm._v("if(cond(data(name=\"landcover\") == 22), ...")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v("The \"result if true\" and \"result if false\" arguments can be "),_c('code',[_vm._v("None")]),_vm._v(" (no data), a single number, or another expression. For Example:")])
},function (){var _vm=this,_c=_vm._self._c;return _c('code',[_vm._v("# Apply school age where the condition is true, and otherwise discard"),_c('br'),_vm._v("if(cond(data(name=\"landcover\") == 22), data(name=\"School Age\"), None)")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v("Multiple conditions can be nested using the conditional operators (see "),_c('code',[_vm._v("cond")]),_vm._v("). For example:")])
},function (){var _vm=this,_c=_vm._self._c;return _c('code',[_vm._v("# Select all data with a value of 22 or 68"),_c('br'),_vm._v("if(cond(data(name=\"landcover\") == 22) | cond(data(name=\"landcover\") == 68), data(name=\"School Age\"), None)")])
}]

export { render, staticRenderFns }
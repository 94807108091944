<template>
  <div>
    <v-data-table
      v-model="expanded"
      :headers="headers"
      :items="jobList"
      item-value="id"
      :items-per-page="30"
      show-expand
      single-expand
      dense
      :no-data-text="noDataText"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="reloadJobList">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td class="text-caption" :colspan="headers.length">
          <table>
            <tbody>
              <tr>
                <td>ID</td>
                <td>{{ item.id }}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{{ item.name }}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>{{ item.status }}</td>
              </tr>
              <tr>
                <td>Started At</td>
                <td>{{ item.startedAt }}</td>
              </tr>
              <tr>
                <td>Stopped At</td>
                <td>{{ item.stoppedAt }}</td>
              </tr>
              <tr>
                <td>Log Stream</td>
                <td>
                  {{ item.logStreamName }}
                  <v-btn
                    v-if="item.logStreamName"
                    @click="
                      log.dialog = true;
                      (log.text = 'Loading...'),
                        (logStreamName = item.logStreamName);
                    "
                    icon
                    x-small
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                  <v-dialog
                    v-model="log.dialog"
                    max-height="90%"
                    width="90%"
                    max-width="120em"
                    scrollable
                  >
                    <v-card>
                      <v-card-title>
                        Log Stream: {{ logStreamName }}
                      </v-card-title>
                      <v-card-text>
                        <pre id="log-text">{{ log.text }}</pre>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </td>
              </tr>
              <tr>
                <td>Tags</td>
                <td>{{ item.tags }}</td>
              </tr>
              <tr>
                <td>Job Definition</td>
                <td>{{ item.jobDefinitionArn }}</td>
              </tr>
            </tbody>
          </table>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { getJobList, getLogText } from "@/api/v2.js";

export default {
  name: "JobList",

  props: {
    title: {
      type: String,
      default: "Batch Jobs",
    },
    jobQueue: {
      type: String,
      default: "flow-ec2-job-queue",
    },
  },

  data() {
    return {
      jobList: [],
      logStreamName: null,
      log: {
        dialog: false,
        text: "foobar",
      },
      expanded: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Status", value: "status" },
        { text: "Started At", value: "startedAt" },
        { text: "", value: "data-table-expand" },
      ],
      noDataText: "No data available",
    };
  },

  methods: {
    reloadJobList() {
      this.jobList = [];

      getJobList(this.jobQueue)
        .then((jobList) => {
          this.jobList = jobList;
        })
        .catch(() => {
          this.noDataText = "Fetching data has failed. Please try again.";
        });
    },
  },

  watch: {
    logStreamName() {
      if (!this.logStreamName) {
        this.log.text = "";
      }
      getLogText(this.logStreamName).then((data) => {
        this.log.text = data.text;
      });
    },
  },

  beforeMount() {
    this.reloadJobList();
  },
};
</script>

<style scoped>
table {
  margin-top: 1em;
  margin-bottom: 1em;
}
table tbody td {
  padding-right: 4em;
}
#log-text {
  text-align: left !important;
}
</style>

<template>
  <v-sheet v-if="projectPage === 'Variables'" style="flex: 1">
    <v-row class="fill-height flex-wrap" no-gutters>
      <v-col cols="12" md="3" style="max-width: 400px">
        <v-navigation-drawer permanent width="400">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h6"> Variables </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-switch v-model="selectAll" inset></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>All expressions</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-switch v-model="groupBySimulation" inset></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Group by expression</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                Expressions
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list dense nav>
            <v-list-item
              v-for="sim in this.activeProject.Simulations"
              :key="`select.${sim.ExpressionName}`"
              two-line
            >
              <v-list-item-action>
                <v-checkbox
                  v-model="selectedSimulations"
                  :value="sim.ExpressionName"
                  :disabled="selectAll"
                ></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title class="text-wrap">
                  {{ sim.ExpressionName }}
                </v-list-item-title>

                <v-list-item-subtitle>
                  {{ sim.ExpressionOwner }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>
        </v-navigation-drawer>
      </v-col>

      <v-col cols="12" lg="9" id="variables-main-content">
        <div
          v-if="Object.keys(inputDevices).length === 0"
          class="d-flex justify-center align-center pa-4"
        >
          <v-icon>mdi-robot-confused-outline</v-icon>

          <v-card-subtitle>No variables.</v-card-subtitle>
        </div>

        <div v-if="!groupBySimulation">
          <div v-for="group in inputDeviceGroupNames" :key="`display.${group}`">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h5 font-weight-bold">
                  {{ group }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list>
              <v-list-item
                v-for="inputDeviceName in inputDeviceGroups[group]"
                :key="`device.${group}.${inputDeviceName}`"
              >
                <v-list-item-content>
                  <div class="py-1 px-2 variable-container">
                    <div class="text-h6 font-weight-regular">
                      {{ inputDeviceName }}
                    </div>

                    <div class="text-caption px-1">
                      {{ inputDevices[inputDeviceName].InputDeviceDescription }}
                    </div>

                    <expression-variable
                      :modelValue="
                        inputDevices[inputDeviceName].InputDeviceValue
                      "
                      @update:modelValue="
                        (newValue) =>
                          (inputDevices[inputDeviceName].InputDeviceValue =
                            newValue)
                      "
                      :definition="inputDevices[inputDeviceName]"
                      :name="inputDeviceName"
                    />
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </div>

        <div v-else>
          <div
            v-for="sim in orderedSelectedSimulations"
            :key="`display.${sim.ExpressionName}`"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h5 font-weight-bold">
                  {{ sim.ExpressionName }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list>
              <v-list-item
                v-for="inputDeviceName in sortedSimulationInputDeviceNames[
                  sim.ExpressionName
                ]"
                :key="`device.${sim.ExpressionName}.${inputDeviceName}`"
              >
                <v-list-item-content>
                  <div class="py-1 px-2 variable-container">
                    <div>
                      <div>
                        <div class="text-h6 font-weight-regular">
                          {{ inputDeviceName }}
                        </div>

                        <div class="text-caption px-1">
                          {{
                            inputDevices[inputDeviceName].InputDeviceDescription
                          }}
                        </div>
                      </div>
                    </div>

                    <expression-variable
                      :modelValue="
                        inputDevices[inputDeviceName].InputDeviceValue
                      "
                      @update:modelValue="
                        (newValue) =>
                          (inputDevices[inputDeviceName].InputDeviceValue =
                            newValue)
                      "
                      :definition="inputDevices[inputDeviceName]"
                      :name="inputDeviceName"
                    />
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import ExpressionVariable from "@/components/projects/ExpressionVariable.vue";

export default {
  name: "ProjectVariables",

  components: {
    ExpressionVariable,
  },

  props: {
    projectPage: { type: String, default: null },
    project: { type: Object, default: () => {} },
  },

  data() {
    return {
      selectedSimulations: [],
      selectAll: true,
      groupBySimulation: false,
    };
  },

  watch: {
    // Is this meant to reset to select all when page changes?
    projectPage() {
      this.selectedSimulations = this.activeProject.Simulations.map(
        (sim) => sim.ExpressionName
      );
    },

    selectAll(newValue) {
      if (newValue) {
        this.selectedSimulations = this.activeProject.Simulations.map(
          (sim) => sim.ExpressionName
        );
      }
    },
  },

  computed: {
    activeProject() {
      return this.project.activeProject;
    },

    inputDevices() {
      return this.activeProject.InputDevices;
    },

    simulationsByName() {
      let simsByName = {};
      for (
        let index = 0;
        index < this.activeProject.Simulations.length;
        index++
      ) {
        const element = this.activeProject.Simulations[index];
        simsByName[element.ExpressionName] = element;
      }
      return simsByName;
    },
    orderedSelectedSimulations() {
      return this.activeProject.Simulations.filter((sim) => {
        return (
          this.selectedSimulations.includes(sim.ExpressionName) &&
          sim.InputDeviceNames.length > 0
        );
      });
    },
    inputDeviceGroups() {
      let groups = {};
      this.orderedSelectedSimulations.forEach((sim) => {
        sim.InputDeviceNames.forEach((inputDeviceName) => {
          let group_name = this.inputDevices[inputDeviceName].InputDeviceGroup;
          if (group_name == null) {
            group_name = "No group";
          }
          if (!(group_name in groups)) {
            groups[group_name] = new Set();
          }
          groups[group_name].add(inputDeviceName);
        });
      });
      const group_names = Object.keys(groups);
      group_names.forEach((group_name) => {
        groups[group_name] = [...groups[group_name]];
        groups[group_name].sort((a, b) => {
          let valueA = null;
          if (
            Object.prototype.hasOwnProperty.call(
              this.inputDevices[a],
              "InputDeviceSortValue"
            )
          ) {
            valueA = this.inputDevices[a].InputDeviceSortValue;
          } else {
            valueA = 0;
          }
          let valueB = null;
          if (
            Object.prototype.hasOwnProperty.call(
              this.inputDevices[b],
              "InputDeviceSortValue"
            )
          ) {
            valueB = this.inputDevices[b].InputDeviceSortValue;
          } else {
            valueB = 0;
          }
          return valueA - valueB;
        });
      });
      return groups;
    },
    inputDeviceGroupNames() {
      let groupNames = [...Object.keys(this.inputDeviceGroups)];
      groupNames.sort();
      if (this.activeProject.Settings.HiddenInputDeviceGroups != null) {
        groupNames = groupNames.filter((name) => {
          return !this.activeProject.Settings.HiddenInputDeviceGroups.includes(
            name
          );
        });
      }
      return groupNames;
    },
    orderedSelectedInputDeviceNames() {
      let allInputDeviceNames = new Set();
      this.orderedSelectedSimulations.forEach((sim) => {
        sim.InputDeviceNames.forEach((inputDeviceName) =>
          allInputDeviceNames.add(inputDeviceName)
        );
      });
      allInputDeviceNames = Array.from(allInputDeviceNames);
      allInputDeviceNames.sort();
      return allInputDeviceNames;
    },
    sortedSimulationInputDeviceNames() {
      let simulationInputDeviceNames = {};
      this.orderedSelectedSimulations.forEach((sim) => {
        let simInputDevices = [...sim.InputDeviceNames];
        simInputDevices.sort((a, b) => {
          let valueA = null;
          if (
            Object.prototype.hasOwnProperty.call(
              this.inputDevices[a],
              "InputDeviceSortValue"
            )
          ) {
            valueA = this.inputDevices[a].InputDeviceSortValue;
          } else {
            valueA = 0;
          }
          let valueB = null;
          if (
            Object.prototype.hasOwnProperty.call(
              this.inputDevices[b],
              "InputDeviceSortValue"
            )
          ) {
            valueB = this.inputDevices[b].InputDeviceSortValue;
          } else {
            valueB = 0;
          }
          return valueA - valueB;
        });
        simulationInputDeviceNames[sim.ExpressionName] = simInputDevices;
      });
      return simulationInputDeviceNames;
    },
  },

  beforeMount() {},
};
</script>

<style scoped>
#variables-main-content {
  max-width: 800px;
}
.variable-container {
  border: 1px solid;
  border-radius: 5px;
  border-color: grey;
}
</style>

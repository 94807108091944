<template>
  <v-card max-height="600px">
    <!-- raster form -->
    <div v-if="dataset.DatasetType === 'Raster'" class="upload-card">
      <v-card-title> Add New Files </v-card-title>
      <v-card-subtitle>
        Adding a geotiff raster file to an existing Flow dataset will update the
        dataset by appending the new files as additional sub-files.
      </v-card-subtitle>
      <v-card-subtitle>
        If the file being added is to be part of a time series dataset, define
        the temporal range of the file by setting the from start date and the
        until end date.
      </v-card-subtitle>

      <v-card-subtitle class="pt-0">
        ex. A dataset with a temporal range of 1 year would appear as follows:
        <span class="d-block">
          <strong
            >From Start Date: 2001-01-01, Until End Date: 2002-01-01.</strong
          ></span
        >
      </v-card-subtitle>

      <v-card-subtitle class="pt-0">
        ex. A dataset with a temporal range of 10 years would appear as follows:
        <span class="d-block">
          <strong
            >From Start Date: 1990-01-01, Until End Date: 2000-01-01.</strong
          ></span
        >
      </v-card-subtitle>
      <v-card-text>
        <v-list three-line dense>
          <template v-for="(file, index) in files">
            <v-list-item :key="file.id" class="px-0">
              <v-list-item-content>
                <upload-file-form-raster-file
                  @update-file="updateFileInfo(file, $event)"
                >
                </upload-file-form-raster-file>
              </v-list-item-content>

              <v-list-item-action>
                <div class="d-flex align-center justify-center">
                  <v-btn icon @click="removeFile(file)">
                    <v-icon small color="red">mdi-minus-circle</v-icon>
                  </v-btn>
                </div>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="index < files.length - 1" :key="index"></v-divider>
          </template>
          <v-list-item>
            <v-list-item-action>
              <v-list-item-action-text
                >Add additional file</v-list-item-action-text
              >
              <v-btn icon @click="addFileItem">
                <v-icon color="green">mdi-plus-circle</v-icon>
              </v-btn>
            </v-list-item-action></v-list-item
          >
        </v-list>
      </v-card-text>
    </div>

    <!-- vector form -->
    <div v-else-if="dataset.DatasetType === 'Vector'" class="upload-card">
      <v-card-title> Add a New File </v-card-title>
      <v-card-subtitle>
        A Flow vector dataset can contain a single file or multiple files that
        represent dataset features. A vector file can be added to the dataset by
        uploading a supported file type:
        <strong>
          A zip folder (.zip) containing an Esri shapefile (.shp) and supporting
          files, a QGIS geopackage (.gpkg), a geojson file (.geojson) or a Key
          Markup Language file (.kml).</strong
        >
      </v-card-subtitle>
      <v-card-subtitle>
        If the file being added is to be part of a time series dataset, define
        the temporal range of the file by setting the from start date and the
        until end date.
      </v-card-subtitle>

      <v-card-subtitle class="pt-0">
        ex. A dataset with a temporal range of 1 year would appear as follows:
        <span class="d-block">
          <strong
            >From Start Date: 2001-01-01, Until End Date: 2002-01-01.</strong
          ></span
        >
      </v-card-subtitle>

      <v-card-subtitle class="pt-0">
        ex. A dataset with a temporal range of 10 years would appear as follows:
        <span class="d-block">
          <strong
            >From Start Date: 1990-01-01, Until End Date: 2000-01-01.</strong
          ></span
        >
      </v-card-subtitle>

      <v-card-subtitle>
        Each file added to the dataset must have a unique date range and
        temporal coverage should be continuous. Leave both fields blank if the
        dataset is time independent.
      </v-card-subtitle>

      <v-card-text>
        <v-list three-line dense>
          <template v-for="(file, index) in files">
            <v-list-item :key="file.id" class="px-0">
              <v-list-item-content>
                <upload-file-form-vector-file
                  @update-file="updateFileInfo(file, $event)"
                >
                </upload-file-form-vector-file>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="index < files.length - 1" :key="index"></v-divider>
          </template>
        </v-list>
      </v-card-text>
    </div>

    <!-- table form -->
    <div v-else-if="dataset.DatasetType === 'Table'" class="upload-card">
      <v-card-title> Add a New Table File </v-card-title>
      <v-card-subtitle>
        <!-- TODO:add description for table dataset -->
      </v-card-subtitle>

      <v-card-text>
        <v-list three-line dense>
          <template v-for="(file, index) in files">
            <v-list-item :key="file.id" class="px-0">
              <v-list-item-content>
                <upload-file-form-table-file
                  @update-file="updateFileInfo(file, $event)"
                >
                </upload-file-form-table-file>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="index < files.length - 1" :key="index"></v-divider>
          </template>
        </v-list>
      </v-card-text>
    </div>

    <!-- actions -->
    <v-card-actions class="d-flex justify-center">
      <v-btn text @click="closeDialog()"> Cancel </v-btn>
      <v-btn
        :disabled="formValidated"
        :loading="submittingUploads"
        color="warning"
        text
        @click="uploadNewFile"
      >
        Upload Files
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { getPresignedPost, uploadDatasetFile } from "@/api/v2.js";

import UploadFileFormRasterFile from "@/components/datasets/UploadFileFormRasterFile.vue";
import UploadFileFormVectorFile from "@/components/datasets/UploadFileFormVectorFile.vue";
import UploadFileFormTableFile from "@/components/datasets/UploadFileFormTableFile.vue";

export default {
  name: "UploadFileForm",

  components: {
    UploadFileFormRasterFile,
    UploadFileFormVectorFile,
    UploadFileFormTableFile,
  },

  props: {
    dataset: { type: Object, default: () => {} },
  },

  data() {
    return {
      dialog: false,
      newFileTemplate: {
        id: 100,
        file: null,
        filename: null,
        dateRange: { start: "ind", end: "ind" },
        vectorOptions: {
          filterField: null,
          valueField: null,
          layerName: null,
        },
        tableOptions: {
          indexColumn: null,
        },
      },

      files: [],
      submittingUploads: false,
    };
  },

  computed: {
    formValidated() {
      return !(this.files.length > 0 && this.files[0].file);
    },
  },

  methods: {
    updateFileInfo(file, updatedFile) {
      file.filename = updatedFile.filename;
      file.file = updatedFile.file;
      file.dateRange = updatedFile.dateRange;
      file.vectorOptions = updatedFile.vectorOptions;
      file.tableOptions = updatedFile.tableOptions;
    },

    addFileItem() {
      this.newFileTemplate.id++;
      this.files.push({ ...this.newFileTemplate });
    },

    removeFile(removeFile) {
      const fileToRemove = this.files.findIndex(
        (file) => file.id === removeFile.id
      );

      this.files.splice(fileToRemove, 1);
    },

    async uploadToS3(url, bodyFormData) {
      const result = fetch(url, {
        method: "POST",
        body: bodyFormData,
      });

      return result;
    },

    async uploadNewFile() {
      this.submittingUploads = true;
      for (const file of this.files) {
        if (file.file) {
          try {
            //  get presigned url for posting to S3 temp folder
            const preSignedFileUrl = await getPresignedPost(file.filename);
            if (preSignedFileUrl.Warnings) {
              this.$showAlert({
                text: preSignedFileUrl.Warnings,
                type: "warning",
              });
            }

            // create post to s3 multi-part/form
            let bodyFormData = new FormData();
            for (const [key, value] of Object.entries(
              preSignedFileUrl.FormFields
            )) {
              bodyFormData.append(key, value);
            }
            bodyFormData.append("file", file.file);

            // post file to temp S3 folder
            await fetch(preSignedFileUrl.Url, {
              method: "POST",
              body: bodyFormData,
            });

            let uploadDatasetFileBody;
            // create post to raster dataset upload body
            if (this.dataset.DatasetType === "Raster")
              uploadDatasetFileBody = {
                DatasetName: this.dataset.DatasetName,
                DatasetOwner: this.dataset.DatasetOwner,
                DatasetType: "Raster",
                UploadedFiles: [
                  {
                    UploadInstanceId: preSignedFileUrl.UploadId,
                    Filename: preSignedFileUrl.UploadFilename,
                    DateRangeStart: file.dateRange.start,
                    DateRangeEnd: file.dateRange.end,
                  },
                ],
              };
            else if (this.dataset.DatasetType === "Vector")
              uploadDatasetFileBody = {
                DatasetName: this.dataset.DatasetName,
                DatasetOwner: this.dataset.DatasetOwner,
                DatasetType: "Vector",
                UploadedFiles: [
                  {
                    UploadInstanceId: preSignedFileUrl.UploadId,
                    Filename: preSignedFileUrl.UploadFilename,
                    DateRangeStart: file.dateRange.start,
                    DateRangeEnd: file.dateRange.end,
                  },
                ],
                // optional values
                FilterField: file.vectorOptions.filterField,
                ValueField: file.vectorOptions.valueField,
                LayerName: file.vectorOptions.layerName,
              };
            else if (this.dataset.DatasetType === "Table")
              uploadDatasetFileBody = {
                DatasetName: this.dataset.DatasetName,
                DatasetOwner: this.dataset.DatasetOwner,
                DatasetType: "Table",
                UploadedFiles: [
                  {
                    UploadInstanceId: preSignedFileUrl.UploadId,
                    Filename: preSignedFileUrl.UploadFilename,
                    DateRangeStart: file.dateRange.start,
                    DateRangeEnd: file.dateRange.end,
                  },
                ],
                // optional values
                IndexColumn: file.tableOptions.indexColumn,
              };

            // remove keys that are left null as they are optional
            Object.keys(uploadDatasetFileBody).forEach((key) => {
              if (uploadDatasetFileBody[key] === null) {
                delete uploadDatasetFileBody[key];
              }
            });

            // post file to dataset
            const uploadedFile = await uploadDatasetFile(uploadDatasetFileBody);
            if (uploadedFile.Warnings) {
              this.$showAlert({
                text: uploadedFile.Warnings,
                type: "warning",
              });
            }
            this.$showAlert({
              text: "Upload jobs were successfully submitted. Status of the jobs can be monitored through the 'Upload Logs' tab in the 'Dataset Properties' dialog",
              type: "success",
            });

            this.closeDialog();
          } catch (error) {
            if (error) {
              this.$showAlert({
                text: error,
                type: "error",
              });
            }
            if (error.response.data.Errors) {
              this.$showAlert({
                text: error.response.data.Errors,
                type: "error",
              });
            }
            this.submittingUploads = true;
          }
        }
      }
      this.submittingUploads = false;
    },

    closeDialog() {
      this.$emit("close-upload-form");
    },
  },
  mounted() {
    this.addFileItem();
  },
};
</script>

<style scoped>
.upload-card {
  overflow-y: auto;
}
</style>

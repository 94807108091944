<template>
  <v-card class="pt-2">
    <v-card-title class="text-h5">Datasets</v-card-title>

    <tag-filter
      :is-loading="isLoading"
      :all-tagged-items="datasetsToBeFiltered"
      :tag-selected-in-parent="clickedTag"
      :default-tags="defaultTags"
      :filter-by-project="filterByProject"
      :filter-by-expression="filterByExpression"
      @updated="updatedDatasetsFilteredByTags"
      @clear-selected-tag="clearClickedTag"
    >
    </tag-filter>

    <v-data-table
      :height="dataTableHeight"
      class="data-table py-2 elevation-1"
      :mobile-breakpoint="1000"
      :search="search"
      :headers="defaultHeaders"
      fixed-header
      :items="datasetsAfterTagFilter"
      loading="isLoading"
      loading-text="Loading Datasets... Please wait"
      :show-select="batchEdit"
      :single-select="singleSelect"
      :disable-pagination="disablePagination"
      :hide-default-footer="hideFooter"
      v-model="selectedDatasets"
      item-key="CreatedAt"
      @click:row="rowSelected"
    >
      <template v-slot:top>
        <v-dialog v-model="dialogShare" max-width="500px" scrollable persistent>
          <data-share
            v-if="dialogShare"
            :selected-data="selectedDatasets"
            :share-type="'Datasets'"
            :batch-edit="batchEdit"
            @close-share-dialog="closeShareDialog()"
          >
          </data-share>
        </v-dialog>

        <v-dialog
          v-model="dialogExport"
          max-width="500px"
          scrollable
          persistent
        >
          <export-dataset-tool
            v-if="dialogExport"
            :dataset="selectedDatasets[0]"
            @close-export-dialog="closeExportDialog()"
          >
          </export-dataset-tool>
        </v-dialog>

        <v-dialog
          v-model="dialogDatasetProperties"
          max-width="600px"
          scrollable
          :persistent="!publicViewMode"
        >
          <dataset-properties
            v-if="dialogDatasetProperties && !publicViewMode"
            :dataset="datasetProperties"
            :start-nav-item="datasetPropertiesNavItem"
            @close-properties="closeDatasetPropertiesDialog()"
          >
          </dataset-properties>

          <dataset-properties-information-quick-look
            v-else-if="dialogDatasetProperties && publicViewMode"
            :dataset="datasetProperties"
          >
          </dataset-properties-information-quick-look>
        </v-dialog>

        <v-dialog v-model="dialogNewDataset" max-width="600px" persistent>
          <new-dataset
            v-if="dialogNewDataset"
            :datasets="datasets"
            @close-new-dataset-dialog="closeNewDatasetDialog"
          >
          </new-dataset>
        </v-dialog>
        <v-row>
          <v-col
            cols="12"
            class="d-flex-wrap d-lg-flex align-center justify-center"
          >
            <v-col cols="12" lg="5">
              <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Search for a Dataset"
                hide-details
                :color="$store.state.settings.appColor"
              ></v-text-field>
            </v-col>
            <v-divider
              v-if="
                selectionMode || (singleSelect && selectedDatasets.length > 0)
              "
              class="mx-2 d-none d-md-flex"
              inset
              vertical
            ></v-divider>
            <v-col
              v-if="selectionMode || singleSelect"
              cols="12"
              sm="10"
              lg="5"
              class="d-lg-flex align-center justify-center"
            >
              <div v-if="selectionMode">
                <v-row justify="center">
                  <v-col cols="4" md="5" class="d-flex justify-end">
                    <h3 class="text-subtitle-2 align-self-center">
                      Dataset Selected:
                    </h3>
                  </v-col>
                  <v-col cols="8" md="7">
                    <span class="text-body-2 d-inline">
                      {{
                        dataset ? dataset.DatasetName : `No Dataset Selected`
                      }}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" md="5" class="d-flex justify-end">
                    <h3 class="text-subtitle-2 pr-2 align-self-center">
                      Layer Selected:
                    </h3>
                  </v-col>
                  <v-col cols="8" md="7">
                    <dataset-layer-selector
                      :key="dataset.DatasetName"
                      v-if="selectionMode && dataset"
                      :dataset="dataset"
                      :allow-multiple="multiLayerSelect"
                      @select-layer="addLayer"
                    ></dataset-layer-selector>
                  </v-col>
                </v-row>
              </div>
              <div v-if="!selectionMode && !batchSelect && singleSelect">
                <v-list dense>
                  <h3 class="text-subtitle-2">
                    {{
                      selectedDatasets.length > 0
                        ? "Datasets selected:"
                        : "No datasets Selected"
                    }}
                  </h3>

                  <v-list-item
                    class="text-body-2"
                    v-for="(dataset, i) in selectedDatasets"
                    :key="i"
                  >
                    {{ dataset.DatasetName }}
                  </v-list-item>
                </v-list>
              </div>
            </v-col>
          </v-col>
        </v-row>

        <v-row v-if="selectedDatasets.length > 1">
          <v-col cols="12" class="d-flex-wrap d-md-flex justify-center">
            <v-col cols="12" md="5" lg="3">
              <v-btn
                v-if="selectedDatasets.length > 1"
                v-show="batchEdit && crudActions"
                block
                color="blue"
                @click.stop="shareDatasets"
                ><v-icon class="px-2">mdi-share</v-icon>Share Selected
                <span class="font-weight-black px-1">
                  {{ `(${selectedDatasets.length})` }}
                </span>
              </v-btn>
            </v-col>

            <v-divider class="mx-2 d-none d-md-flex" inset vertical></v-divider>
            <v-col cols="12" md="5" lg="3">
              <v-btn
                v-show="batchEdit && crudActions"
                v-if="selectedDatasets.length > 1"
                block
                color="red"
                @click="deleteDatasets(selectedDatasets)"
                ><v-icon class="px-2">mdi-delete</v-icon>Delete Selected
                <span class="font-weight-black px-1">
                  {{ `(${selectedDatasets.length})` }}
                </span>
              </v-btn>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="justify-end">
          <v-col
            v-if="crudActions"
            cols="12"
            md="5"
            class="d-flex align-center pa-6"
          >
            <v-btn
              color="secondary"
              outlined
              block
              @click="newDataset()"
              :disabled="isLoading"
              ><v-icon>mdi-plus</v-icon>New Dataset</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="7" class="d-flex mb-2" v-if="!publicViewMode">
            <v-switch
              v-if="showEmptyDatasetsOnlyToggle"
              v-model="showEmptyDatasetsOnly"
              class="px-2"
              hide-details
              dense
            >
              <template v-slot:label>
                <p class="text-body-2 mb-0">Empty datasets only</p>
              </template></v-switch
            >
            <v-switch
              v-if="showCurrentUserDatasetsOnlyToggle"
              v-model="showCurrentUserDatasetsOnly"
              class="px-2"
              hide-details
              dense
            >
              <template v-slot:label>
                <p class="text-body-2 mb-0">My datasets only</p>
              </template></v-switch
            >
            <v-switch
              v-if="batchSelect"
              v-model="batchEdit"
              class="px-2"
              hide-details
              dense
            >
              <template v-slot:label>
                <p class="text-body-2 mb-0">Batch select</p>
              </template></v-switch
            >
          </v-col>
          <v-col class="pr-6 d-flex justify-end align-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  id="dataset-list-refresh"
                  class="d-flex flex-column align-end"
                >
                  <p class="mb-0 text-caption font-weight-medium">
                    Last Updated:
                    <span class="text-caption"> {{ lastUpdated }}</span>
                  </p>
                </div>
                <v-btn
                  class="pl-2"
                  :loading="datasetListLoading"
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  @click="refreshDatasetList"
                >
                  <v-icon> mdi-refresh </v-icon>
                </v-btn>
              </template>
              <span>Refresh the dataset list</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.datasetInfo="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              @click.stop="showDatasetProperties(item)"
            >
              mdi-information
            </v-icon>
          </template>
          <span>Show Dataset Properties</span>
        </v-tooltip>
      </template>
      <template v-slot:item.CreatedAt="{ item }">
        {{ convertedDate(item.CreatedAt) }}
      </template>
      <template v-slot:item.DatasetOwner="{ item }">
        <span>{{ item.DatasetOwner }}</span>
      </template>
      <template v-slot:item.UpdatedAt="{ item }">
        {{ convertedDate(item.UpdatedAt) }}
      </template>
      <template v-slot:item.DatasetType="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              v-if="item.DatasetType === 'Vector' && item.HasFilterField"
            >
              mdi-card-multiple
            </v-icon>
            <v-icon
              v-bind="attrs"
              v-on="on"
              v-if="item.DatasetType === 'Vector' && !item.HasFilterField"
            >
              mdi-vector-polygon
            </v-icon>
            <v-icon
              v-bind="attrs"
              v-on="on"
              v-if="item.DatasetType === 'Raster'"
            >
              mdi-data-matrix
            </v-icon>
            <v-icon
              v-bind="attrs"
              v-on="on"
              v-if="item.DatasetType === 'Table'"
            >
              mdi-table-large
            </v-icon>
          </template>
          <span>{{ item.DatasetType }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.Tags="{ item }">
        <v-chip-group column>
          <v-chip
            v-for="(tag, i) in item.Tags"
            :key="i"
            x-small
            ripple
            @click.stop="updateFilterTags(tag)"
            :color="getChipColor(tag)"
          >
            {{ tag }}
          </v-chip>
        </v-chip-group>
      </template>
      <template v-slot:item.actions="{ item }">
        <td class="action-column">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click.stop="shareDatasets(item)"
              >
                mdi-share
              </v-icon>
            </template>
            <span>Share a Dataset</span>
          </v-tooltip>

          <!-- disabled for now -->
          <!-- <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="item.DatasetType === 'Raster'"
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click.stop="exportDataset(item)"
              >
                mdi-download
              </v-icon>
            </template>

            <span>Export a Dataset</span>
          </v-tooltip> -->
          <v-tooltip left v-if="item.DatasetOwner === currentUser">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click.stop="deleteDatasets(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Delete a Dataset</span>
          </v-tooltip>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { getDataset, deleteDataset, formatDateString } from "@/api/v2.js";
import { sortCaseInsensitive } from "@/helpers/formatting.js";
import { getChipColor } from "@/helpers/chipColors.js";
import DataShare from "@/components/data/DataShare.vue";
import DatasetLayerSelector from "@/components/data/DatasetLayerSelector.vue";
import ExportDatasetTool from "@/components/data/ExportDatasetTool.vue";
import DatasetProperties from "@/components/datasets/DatasetProperties.vue";
import DatasetPropertiesInformationQuickLook from "@/components/datasets/DatasetPropertiesInformationQuickLook.vue";
import NewDataset from "@/components/datasets/NewDataset.vue";
import TagFilter from "@/components/data/TagFilter.vue";

export default {
  name: "DatasetSelector",

  components: {
    DataShare,
    DatasetLayerSelector,
    ExportDatasetTool,
    DatasetProperties,
    DatasetPropertiesInformationQuickLook,
    NewDataset,
    TagFilter,
  },

  props: {
    selectionMode: {
      type: Boolean,
      default: false,
    },
    batchSelect: {
      type: Boolean,
      default: true,
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    multiLayerSelect: {
      type: Boolean,
      default: false,
    },
    showEmptyDatasetsOnlyToggle: {
      type: Boolean,
      default: true,
    },
    showCurrentUserDatasetsOnlyToggle: {
      type: Boolean,
      default: true,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    clearSelections: {
      type: Boolean,
      default: false,
    },
    crudActions: {
      type: Boolean,
      default: true,
    },
    dataTableHeight: {
      type: Number,
      default: 700,
    },
    activeProjectTag: {
      type: Boolean,
      default: true,
    },
    initialTags: {
      type: Array,
      default(rawProps) {
        if (rawProps) {
          return [...rawProps];
        } else {
          return [];
        }
      },
    },
    filterByProject: {
      type: Boolean,
      default: true,
    },
    filterByExpression: {
      type: Boolean,
      default: true,
    },
    initialSearch: {
      type: String,
      default: null,
    },
    // initialNameFilter: {
    //   type: String,
    //   default: null,
    // },
    initialTypeFilters: {
      type: Array,
      default: () => ["Vector", "Raster", "Table"],
    },
  },

  data() {
    return {
      isLoading: false,
      datasetListLoading: false,
      defaultTags: [],
      typeFilter: null,
      nameFilter: null,
      dataset: null,
      datasetLoading: false,
      search: "",
      expanded: [],
      dialogShare: false,
      dialogExport: false,
      datasetProperties: null,
      dialogDatasetProperties: false,
      datasetPropertiesNavItem: null,
      dialogNewDataset: false,
      batchEdit: false,
      showEmptyDatasetsOnly: false,
      showCurrentUserDatasetsOnly: false,
      selectedDatasets: [],
      datasetsAfterTagFilter: [],
      clickedTag: null,
    };
  },

  computed: {
    datasets() {
      return this.$store.state.accountData.datasetList.datasets;
    },

    publicViewMode() {
      return !this.crudActions;
    },

    lastUpdated() {
      return formatDateString(
        this.$store.state.accountData.datasetList.lastUpdated
      );
    },

    currentUser() {
      return this.$store.state.account.user.attributes.email;
    },

    allDatasets() {
      return this.datasets.AllDatasets;
    },

    emptyDatasetsOnly() {
      if (this.allDatasets) {
        return this.allDatasets.filter((ds) => {
          return ds.NumberOfFiles === 0;
        });
      } else {
        return [];
      }
    },

    currentUserDatasetsOnly() {
      if (this.allDatasets) {
        return this.allDatasets.filter((ds) => {
          return ds.DatasetOwner === this.currentUser;
        });
      } else {
        return [];
      }
    },

    emptyCurrentUserDatasetsOnly() {
      if (this.allDatasets) {
        return this.allDatasets.filter((ds) => {
          return ds.DatasetOwner === this.currentUser && ds.NumberOfFiles === 0;
        });
      } else {
        return [];
      }
    },

    datasetsToBeFiltered() {
      return this.showEmptyDatasetsOnly && this.showCurrentUserDatasetsOnly
        ? this.emptyCurrentUserDatasetsOnly
        : this.showEmptyDatasetsOnly
        ? this.emptyDatasetsOnly
        : this.showCurrentUserDatasetsOnly
        ? this.currentUserDatasetsOnly
        : this.allDatasets;
    },

    allTags() {
      return sortCaseInsensitive(this.datasets.AllTags);
    },

    defaultHeaders() {
      let headers = [
        { value: "datasetInfo", sortable: false },
        {
          text: `Dataset Name`,
          align: "start",
          sortable: true,
          value: `DatasetName`,
        },

        { text: "Created", value: "CreatedAt" },
        { text: "Created By", value: `DatasetOwner` },
        { text: "Coverage", value: "DateRange" },
        {
          text: "Type",
          value: "DatasetType",
          filter: this.filterByType,
        },
        {
          text: "Tags",
          value: "Tags",
        },
      ];

      // render actions header if prop passed
      if (this.crudActions && !this.batchEdit) {
        headers.push({
          text: "Actions",
          value: "actions",
          sortable: false,
        });
      }

      return headers;
    },

    availableHeaders() {
      let availableHeaders = [];
      for (const [key] of Object.entries(this.datasets.AllDatasets[0])) {
        let header = {
          text: key.split(/(?=[A-Z])/).join(" "),
          value: key,
        };
        availableHeaders.push(header);
      }
      return availableHeaders;
    },
  },
  watch: {
    selectedDatasets() {
      if (this.selectedDatasets.length > 1 && this.batchSelect) {
        this.batchEdit = true;
      } else {
        this.batchEdit = false;
      }
    },
    clearSelections() {
      this.clearDatasets();
    },
  },

  methods: {
    getChipColor,
    sortCaseInsensitive,
    // converts date to user local time
    convertedDate(utcDate) {
      let date = new Date(utcDate);
      let localDate = date.toLocaleString();
      return localDate;
    },
    filterByType(value) {
      return this.typeFilters.includes(value);
    },

    // updates tag list if tag clicked on within data table row
    updateFilterTags(tag) {
      this.clickedTag = tag;
    },

    // clear clicked tag
    clearClickedTag() {
      this.clickedTag = null;
    },

    updatedDatasetsFilteredByTags(datasets) {
      this.datasetsAfterTagFilter = datasets;
    },

    // selects row from single click rather than checkbox
    rowSelected(item, data) {
      if (!data.isSelected && !this.selectionMode) {
        this.selectedDatasets.push(data.item);
      } else {
        const datasetIndex = (dataset) =>
          dataset.DatasetName === data.item.DatasetName;
        this.selectedDatasets.splice(
          this.selectedDatasets.findIndex(datasetIndex),
          1
        );
      }

      // selects a dataset and fetches more details
      if (!this.singleSelect && !this.batchEdit && this.selectionMode) {
        this.selectDataset(data.item);
      }

      // selects a single dataset and does not fetch more details
      if (this.singleSelect && !this.batchEdit && this.selectionMode) {
        this.$emit("update-dataset", data.item);
      }

      // selects an array of items and fetches more details
      if (this.singleSelect && !this.batchEdit && !this.selectionMode) {
        this.selectDatasets(this.selectedDatasets);
      }
    },

    selectDataset(ds) {
      // updates dataset to full dataset details
      getDataset(ds.DatasetName, ds.DatasetOwner)
        .then((dataset) => {
          this.dataset = dataset;
          this.datasetLoading = true;
          if (this.selectionMode) {
            const emitDataset = () => {
              this.$emit("update-dataset", dataset);
              // this.$emit("update-layer", null);
            };
            emitDataset();
          }
        })
        .catch((errors) => {
          if (errors.response.data.Errors) {
            this.$showAlert({
              text: errors.response.data.Errors,
              type: "error",
            });
          }
          if (errors.response.data.Warnings) {
            this.$showAlert({
              text: errors.response.data.Warnings,
              type: "warning",
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    selectDatasets(datasets) {
      // Wait until files have loaded
      const emitDatasets = () => {
        this.$emit("update-datasets", datasets);
      };

      emitDatasets();
    },

    addLayer(layers) {
      if (this.selectionMode) this.$emit("update-layer", layers);
    },

    clearDatasets() {
      this.dataset = null;
      (this.selectedDatasets = []), this.$emit("selections-cleared");
    },

    async refreshDatasetList() {
      this.datasetListLoading = true;
      const response = await this.$store.dispatch(
        "accountData/refreshDatasetList"
      );
      this.datasetListLoading = false;
      return response;
    },

    // Crud Actions

    // New Dataset
    newDataset() {
      this.dialogNewDataset = true;
    },

    closeNewDatasetDialog(dataset) {
      this.dialogNewDataset = false;
      if (dataset) {
        this.showDatasetProperties(dataset, true);
      }
    },

    // Share Datasets
    shareDatasets(item) {
      this.isLoading = true;
      if (!this.batchEdit) {
        this.selectedDatasets = [];
        getDataset(item.DatasetName, item.DatasetOwner)
          .then((dataset) => {
            this.selectedDatasets.push(dataset);
            this.dialogShare = true;
            if (dataset.Warnings) {
              this.$showAlert({
                text: dataset.Warnings,
                type: "warning",
              });
            }
          })
          .catch((error) => {
            if (error) {
              this.$showAlert({
                text: error,
                type: "error",
              });
            }
            if (error.response.data.Errors) {
              this.$showAlert({
                text: error.response.data.Errors,
                type: "error",
              });
            }
          });
      } else {
        this.dialogShare = true;
      }
    },

    closeShareDialog() {
      this.selectedDatasets = [];
      this.dialogShare = false;
    },

    // Delete Datasets

    // takes an array of or a single datasets as input
    deleteDatasets(datasets) {
      let isBatch = Array.isArray(datasets);

      if (!isBatch) {
        datasets = new Array(datasets);
      }
      const datasetNames = [];

      datasets.forEach((dataset) => {
        datasetNames.push(dataset.DatasetName);
      });

      this.$showAlert({
        text:
          datasets.length > 1
            ? `<b>Are you sure you want to delete (${
                datasets.length
              }) datasets, this cannot be undone?</b> <br>- ${datasetNames.join(
                " <br>- "
              )}`
            : `<b>Are you sure you want to delete this dataset, this cannot be undone?</b> <br>- ${datasets[0].DatasetName}`,
        type: "warning",
        choice: true,
      }).then((confirmed) => {
        if (confirmed) {
          this.isLoading = true;
          let promises = [];

          for (let i = 0; i < datasets.length; i++) {
            promises.push(
              deleteDataset(datasets[i].DatasetName, datasets[i].DatasetOwner)
            );
          }
          Promise.all(promises)
            .then((response) => {
              if (response.Warnings) {
                this.$showAlert({
                  text: response.Warnings,
                  type: "warning",
                });
              }
              this.refreshDatasetList();
              this.$showAlert({
                text:
                  datasets.length > 1
                    ? `(${datasets.length}) Datasets were successfully deleted`
                    : `The Dataset was successfully deleted`,
                timeout: 4000,
                type: "success",
              });
            })
            .catch((errors) => {
              if (errors) {
                this.$showAlert({
                  text: errors,
                  type: "error",
                });
              }
              if (errors.response.data.Errors) {
                this.$showAlert({
                  text: errors.response.data.Errors,
                  type: "error",
                });
              }
            })
            .finally(() => {
              this.selectedDatasets = [];
              this.isLoading = false;
            });
        }
      });
    },

    exportDataset(item) {
      this.selectedDatasets.push(item);
      this.dialogExport = true;
    },

    closeExportDialog() {
      this.selectedDatasets = [];
      this.dialogExport = false;
    },

    showDatasetProperties(dataset, newDataset = null) {
      this.datasetProperties = dataset;

      if (newDataset) {
        this.datasetPropertiesNavItem = 2;
      }

      this.dialogDatasetProperties = true;
    },

    closeDatasetPropertiesDialog() {
      this.datasetProperties = null;
      this.datasetPropertiesNavItem = null;
      this.dialogDatasetProperties = false;
    },
  },
  created() {
    // add default tags from props

    if (
      this.initialTags.length == 0 &&
      this.$store.state.project.activeProject &&
      this.$store.state.settings.dataSelectionType?.allowProjectFilter &&
      this.activeProjectTag
    ) {
      // filter by project name if project loaded
      let projectNameTag = `PROJECT:${this.$store.state.project.activeProject.Properties.ProjectName}`;
      this.defaultTags.push(projectNameTag);
    } else {
      this.defaultTags = this.initialTags;
    }

    //set pre filter data from props
    if (this.initialTypeFilters) {
      this.typeFilters = this.initialTypeFilters;
    }

    if (this.initialNameFilter) {
      this.nameFilter = this.initialNameFilter;
    }
  },

  // Should add a beforeRouteEnter to fetch data if needed
};
</script>

<style scoped>
.data-table >>> tbody td {
  cursor: pointer;
}

.data-table >>> tbody td.action-column {
  cursor: default;
}

#dataset-list-refresh {
  text-align: center;
}
</style>

<template>
  <div class="layer-editor-properties" min-width="340" max-width="600">
    <div class="loading-overlay">
      <v-skeleton-loader
        type="heading, list-item, heading, list-item, heading, list-item, heading, list-item, heading, list-item"
        :loading="loading"
        height=""
      >
        <div>
          <!-- quick look information -->
          <div>
            <!-- TypeOfData -->
            <v-subheader class="property-headers"> Type </v-subheader>
            <v-row class="py-2 my-0" justify="end">
              <v-col cols="12" sm="7" class="pt-0 d-flex justify-center">
                <p v-if="!allowEditing">{{ value.TypeOfData }}</p>
                <v-select
                  v-else
                  v-model="value.TypeOfData"
                  :items="typeOfDataOptions"
                  item-text="name"
                  item-value="value"
                  dense
                  solo
                  :disabled="!allowEditing"
                  :color="$store.state.settings.appColor"
                >
                </v-select>
              </v-col>
            </v-row>
            <!-- Details -->
            <v-subheader class="property-headers"> Units </v-subheader>
            <v-row class="py-2 my-0" justify="end">
              <v-col cols="12" sm="7" class="pt-0 d-flex justify-center">
                <p v-if="!allowEditing">{{ value.Units }}</p>
                <v-text-field
                  v-else
                  v-model="value.Units"
                  class="ma-0 pa-0"
                  dense
                  outlined
                  hide-details
                  :disabled="!allowEditing"
                  hint="Display units"
                  background-color="white"
                  :color="$store.state.settings.appColor"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-subheader class="property-headers">
              {{ informationOnlyMode ? "Layer Description" : "Description" }}
            </v-subheader>
            <v-row class="py-2 my-0">
              <v-col cols="12" class="pt-0">
                <p v-if="!allowEditing">{{ value.Description }}</p>

                <v-textarea
                  v-else
                  v-model="value.Description"
                  class="mr-0 pr-2"
                  dense
                  outlined
                  hide-details
                  auto-grow
                  :disabled="!allowEditing"
                  background-color="white"
                  :color="$store.state.settings.appColor"
                >
                </v-textarea>
              </v-col>
            </v-row>

            <div v-if="informationOnlyMode">
              <v-subheader class="property-headers">
                Dataset Description
              </v-subheader>
              <v-row class="py-2 my-0">
                <v-col cols="12" class="pt-0">
                  <p>{{ fullDataset.Description }}</p>
                </v-col>
              </v-row>
              <v-subheader class="property-headers"> Citation </v-subheader>
              <v-row class="py-2 my-0">
                <v-col cols="12" class="pt-0">
                  <p v-if="informationOnlyMode">
                    {{ fullDataset.CitationRIS }}
                  </p>
                </v-col>
              </v-row>
            </div>
          </div>

          <!-- all  information  -->
          <div v-show="!informationOnlyMode">
            <!-- Dates -->
            <div>
              <v-subheader class="property-headers"> Dates </v-subheader>
              <v-row class="py-2 my-0" justify="end">
                <v-col cols="7" sm="8" class="pt-0">
                  <v-subheader class="property-titles">
                    Does the layer have its own start/end date?
                  </v-subheader>
                </v-col>
                <v-col cols="5" sm="4">
                  <v-switch
                    v-model="hasCustomDateRange"
                    hide-details
                    :label="hasCustomDateRange ? 'Yes' : 'No'"
                    dense
                    class="ma-0 pa-0"
                    :disabled="!allowEditing"
                    :color="$store.state.settings.appColor"
                  ></v-switch>
                </v-col>
              </v-row>
              <div v-show="hasCustomDateRange">
                <v-row class="py-2">
                  <v-col cols="6">
                    <div>Start at:</div>
                    <div>
                      <v-text-field
                        v-model="value.StartDate"
                        class="ma-0 pa-0"
                        dense
                        outlined
                        background-color="white"
                        hide-details
                        placeholder="yyyy-mm-dd"
                        :disabled="!allowEditing"
                        :color="$store.state.settings.appColor"
                      >
                      </v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div>End at:</div>
                    <div>
                      <v-text-field
                        v-model="value.EndDate"
                        class="ma-0 pa-0"
                        dense
                        outlined
                        background-color="white"
                        hide-details
                        placeholder="yyyy-mm-dd"
                        :disabled="!allowEditing"
                        :color="$store.state.settings.appColor"
                      >
                      </v-text-field>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <v-row class="py-2 my-0">
                <v-col cols="12" sm="5">
                  <v-subheader class="property-titles">
                    What is the frequency of the data?
                  </v-subheader>
                </v-col>
                <v-col cols="12" sm="7">
                  <v-select
                    v-model="value.Timestep"
                    :items="timestepOptions"
                    item-text="name"
                    item-value="value"
                    dense
                    solo
                    :disabled="!allowEditing"
                    :color="$store.state.settings.appColor"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </div>

            <!-- Vector Specific Properties-->
            <div v-show="datasetType === 'Vector'">
              <!-- Query -->
              <v-subheader class="property-headers"> Query </v-subheader>
              <v-row class="py-2 my-0">
                <v-col cols="7" sm="8">
                  <v-subheader class="property-titles">
                    Does the attribute table require pre-processing?
                  </v-subheader>
                </v-col>
                <v-col cols="5" sm="4">
                  <v-switch
                    v-model="hasQuery"
                    hide-details
                    :label="hasQuery ? 'Yes' : 'No'"
                    dense
                    class="ma-0 pa-0"
                    :disabled="!allowEditing"
                    :color="$store.state.settings.appColor"
                  ></v-switch>
                </v-col>
              </v-row>

              <v-card v-show="hasQuery" class="mb-4">
                <div class="d-flex">
                  <textarea id="query-textarea" />
                </div>
              </v-card>

              <div
                v-show="
                  value.TypeOfData === 'Continuous' ||
                  value.TypeOfData === 'Categorical'
                "
              >
                <!-- ValueField -->
                <v-subheader class="property-headers"> Value </v-subheader>
                <v-row class="py-2 my-0">
                  <v-col cols="12" sm="5">
                    <v-subheader class="property-titles">
                      What attribute field points to the value?
                    </v-subheader>
                  </v-col>
                  <v-col cols="12" sm="7">
                    <v-combobox
                      v-model="value.ValueField"
                      :items="fieldNames"
                      :disabled="!allowEditing"
                      hint="Select from available attribute field names. Attributes from a table join will appear here."
                      clearable
                      dense
                      solo
                      :color="$store.state.settings.appColor"
                      :loading="refreshingFieldNames"
                      loader-height="4"
                    >
                    </v-combobox>
                  </v-col>
                </v-row>
              </div>
            </div>

            <!-- Symbology -->
            <!-- <div v-show="value.TypeOfData === 'Categorical'"> -->
            <div>
              <v-subheader class="property-headers">Symbology</v-subheader>
              <v-row class="py-2 my-0">
                <v-col cols="12">
                  <v-subheader class="property-titles">
                    When the layer loads, should a symbology be applied?
                  </v-subheader>
                </v-col>
              </v-row>

              <v-row class="py-2 my-0" justify="end">
                <v-col cols="7">
                  <v-autocomplete
                    v-model="value.SymbologyName"
                    :items="datasetSymbologies"
                    item-text="Name"
                    item-value="Name"
                    dense
                    solo
                    :disabled="!allowEditing"
                    :color="$store.state.settings.appColor"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </div>

            <!-- Vector FilterField and FilterValue -->
            <div v-show="datasetType === 'Vector'">
              <v-subheader class="property-headers"> Filtering </v-subheader>
              <v-row class="py-2 my-0">
                <v-col cols="7" sm="8">
                  <v-subheader class="property-titles">
                    Only show features if an attribute equals a value?
                  </v-subheader>
                </v-col>
                <v-col cols="5" sm="4">
                  <v-switch
                    v-model="hasFilter"
                    hide-details
                    :label="hasFilter ? 'Yes' : 'No'"
                    dense
                    class="ma-0 pa-0"
                    :disabled="!allowEditing"
                    :color="$store.state.settings.appColor"
                  ></v-switch>
                </v-col>
              </v-row>

              <div v-if="hasFilter">
                <v-row class="py-2 my-0">
                  <v-col cols="12" sm="5">
                    <v-subheader class="property-titles">
                      What attribute field should be used for filtering?
                    </v-subheader>
                  </v-col>
                  <v-col cols="12" sm="7">
                    <v-combobox
                      v-model="value.FilterField"
                      :items="fieldNames"
                      :disabled="!allowEditing"
                      hint="Select from available attribute field names or enter an expected attribute from a table join."
                      clearable
                      solo
                      dense
                      :color="$store.state.settings.appColor"
                      :loading="refreshingFieldNames"
                      loader-height="4"
                    >
                    </v-combobox>
                  </v-col>
                </v-row>
                <div>
                  <v-row class="py-2 my-0">
                    <v-col cols="12" sm="5">
                      <v-subheader class="property-titles">
                        What value must the attribute equal?
                      </v-subheader>
                    </v-col>
                    <v-col cols="12" sm="7">
                      <v-text-field
                        v-model="value.FilterValue"
                        class="ma-0 pa-0"
                        dense
                        outlined
                        background-color="white"
                        hide-details
                        :color="$store.state.settings.appColor"
                        :disabled="!allowEditing"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <div class="py-2 my-0" v-if="!this.virtualLayerMode">
                    <v-subheader class="property-titles">
                      This layer configuration can used to create a new layer
                      for every value that matches {{ value.FilterField }}.
                    </v-subheader>

                    <div class="d-flex justify-center pt-6">
                      <v-btn
                        small
                        class="px-6 text-capitalize"
                        :loading="duplicatingLayer"
                        @click="duplicateLayer"
                        color="#adced1"
                        :disabled="!value.FilterValue"
                        ><v-icon left>mdi-content-copy</v-icon>Duplicate
                        Layer</v-btn
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-skeleton-loader>
    </div>
  </div>
</template>

<script>
import { getDatasetLayer, updateDatasetLayer } from "@/api/v2.js";
import { getLayerFields, duplicateFilteredLayer } from "@/api/geoprocessing.js";
import CodeMirror from "codemirror";
import "@/assets/styles/codemirror.css";
import "codemirror/addon/edit/matchbrackets";
import "codemirror/addon/edit/closebrackets";

export default {
  name: "LayerEditor",

  props: {
    datasetName: { type: String },
    datasetOwner: { type: String },
    datasetType: { type: String },
    fullDataset: { type: Object },
    layerName: { type: String },
    layerNames: { type: Array },
    edit: { type: Boolean, default: false },
    virtualLayerMode: { type: Boolean, default: false },
    informationOnlyMode: { type: Boolean, default: false },
  },

  data() {
    return {
      value: this.emptyLayerValue(),
      initialValue: this.emptyLayerValue(),
      loading: true,
      allowEditing: this.edit,
      hasQuery: false,
      queryValue: "",
      flowCategories: [],
      hasFilter: false,
      fieldNames: [],
      hasCustomDateRange: false,
      cm: null,
      querySaving: false,
      refreshingFieldNames: false,
      duplicatingLayer: false,
    };
  },

  computed: {
    typeOfDataOptions() {
      let options;
      if (this.datasetType === "Raster") {
        options = [
          {
            value: "Continuous",
            name: "Quantitative",
            hint: "Numerical value of interest",
          },
          {
            value: "Categorical",
            name: "Categorical",
            hint: "Features or pixel values refer to a class within a category",
          },
          {
            value: "Mask",
            name: "Area of Interest",
            hint: "Study areas, Footprints, Regions, Masks",
          },
        ];
      } else {
        options = [
          {
            value: "Continuous",
            name: "Quantitative",
            hint: "Numerical value of interest",
          },
          // TODO: disabled for now
          // {
          //   value: "Categorical",
          //   name: "Categorical",
          //   hint: "Features or pixel values refer to a class within a category",
          // },
          {
            value: "Mask",
            name: "Area of Interest",
            hint: "Study areas, Footprints, Regions, Masks",
          },
        ];
      }

      return options;
    },
    timestepOptions() {
      return [
        { value: "Decadally", name: "Every 10 years" },
        { value: "SemiDecadally", name: "Every 5 years" },
        { value: "Annually", name: "Every year" },
        { value: "Monthly", name: "Every month" },
        { value: "Daily", name: "Every day" },
      ];
    },
    layer() {
      let value = { ...this.value };
      value.Query = this.queryValue;
      value = this.formatLayerObject(value);
      return value;
    },
    initialLayer() {
      return this.formatLayerObject(this.initialValue);
    },
    datasetSymbologies() {
      const symbologies = this.fullDataset.Symbology;
      const symbologyArray = Object.keys(symbologies).map(
        (key) => symbologies[key]
      );
      symbologyArray.unshift("");

      return symbologyArray;
    },
  },
  watch: {
    edit(value) {
      this.allowEditing = value;
    },
    allowEditing(value) {
      this.cm.setOption("readOnly", value ? false : "nocursor");
      if (!value) {
        this.emitChange();
      }
    },
  },

  methods: {
    emptyLayerValue() {
      return {
        TypeOfData: "Continuous",
        Units: null,
        Description: null,
        Query: null,
        FilterField: null,
        FilterValue: null,
        ValueField: null,
        DatasetName: this.datasetName,
        DatasetOwner: this.datasetOwner,
        LayerName: this.layerName,
        SymbologyName: "None",
        Dates: null,
      };
    },
    async loadLayerDetails() {
      this.loading = true;

      try {
        const datasetLayer = await getDatasetLayer(
          this.datasetName,
          this.datasetOwner,
          this.layerName
        );

        if (datasetLayer.Warnings) {
          this.$showAlert({
            text: datasetLayer.Warnings,
            type: "warning",
          });
        }

        this.initialValue = { ...datasetLayer };
        this.value = { ...datasetLayer };

        this.hasQuery = this.value.Query !== null;
        this.queryValue = this.hasQuery ? this.value.Query : "";
        this.hasCustomDateRange =
          this.value.StartDate !== null || this.value.EndDate !== null;
        this.hasFilter = this.value.FilterField !== null;

        // get file names from a vector dataset
        this.emitChange();
      } catch (error) {
        // if no layer found
        this.resetLayerDetails();
        this.emitChange();
      } finally {
        this.loading = false;
      }
    },

    resetLayerDetails() {
      this.initialValue = this.emptyLayerValue();
      this.value = this.emptyLayerValue();
      this.hasQuery = false;
      this.queryValue = "";
      this.hasFilter = false;
      this.loading = false;
    },

    formatLayerObject(obj) {
      if (obj === null) {
        return null;
      }
      let layer = { ...obj };
      if (
        layer.DatasetOwner !== this.datasetOwner ||
        layer.DatasetName !== this.datasetName ||
        layer.LayerName !== this.layerName
      ) {
        return null;
      }
      // Can't update these keys
      const removeKeys = [
        "Errors",
        "Warnings",
        "GeometryType",
        "LayerCode",
        "Dates",
        "DefaultEndDate",
      ];
      removeKeys.forEach((key) => {
        if (layer[key] !== undefined) {
          delete layer[key];
        }
      });
      if (!this.hasQuery || layer.Query === "") {
        layer.Query = null;
      }
      if (layer.SymbologyName === "None") {
        layer.SymbologyName = null;
      }
      if (
        !this.hasFilter ||
        layer.FilterField === "" ||
        layer.FilterValue === ""
      ) {
        layer.FilterField = null;
        layer.FilterValue = null;
      }
      if (!this.hasCustomDateRange) {
        layer.StartDate = null;
        layer.EndDate = null;
      }
      if (layer.StartDate === "") {
        layer.StartDate = null;
      }
      if (layer.EndDate === "") {
        layer.EndDate = null;
      }
      return layer;
    },
    emitChange() {
      if (this.layer !== null) {
        let isVirtualLayer =
          JSON.stringify(this.layer) !== JSON.stringify(this.initialLayer);
        this.$emit("change", this.layer, isVirtualLayer);
      }
    },

    async saveQuery() {
      if (!this.virtualLayerMode) {
        // offer to save layer to get access to new field names
        if (this.layer.Query !== this.value.Query) {
          // const confirmMessage = await this.$showAlert({
          //   text: `The query has been modified, which may change the available attributes.
          //   Saving the layer will provide access to the new attribute values if applicable.
          // <br> <br> Would like to save the layer now?`,
          //   type: "warning",
          //   choice: true,
          // });
          // if (this.layer) {
          // }
          try {
            this.allowEditing = false;
            try {
              this.querySaving = true;
              const updatedLayer = await updateDatasetLayer(this.layer);

              if (updatedLayer.Warnings) {
                this.$showAlert({
                  text: updatedLayer.Warnings,
                  type: "warning",
                });
              }

              this.emitChange();
              this.value.Query = this.layer.Query;
            } catch (error) {
              throw "There was an error saving the layer.";
            }

            try {
              const datasetInfo = this.value;
              const refreshedLayerList = await this.refreshLayerFields(
                datasetInfo
              );

              if (refreshedLayerList.Warnings) {
                this.$showAlert({
                  text: refreshedLayerList.Warnings,
                  type: "warning",
                });
              }
            } catch (error) {
              //
            }
          } catch (error) {
            this.$showAlert({
              text: error,
              type: "error",
            });
          } finally {
            this.querySaving = false;
            this.allowEditing = true;
          }
        }
      }
    },

    async refreshLayerFields(datasetInfo) {
      this.refreshingFieldNames = true;
      try {
        const layerFieldNames = await getLayerFields(datasetInfo);
        this.fieldNames = layerFieldNames.FieldNames;

        if (layerFieldNames.Warnings) {
          this.$showAlert({
            text: layerFieldNames.Warnings,
            type: "warning",
          });
        }
      } catch (error) {
        this.$showAlert({
          text: "There was an error populating the attribute list, check for errors in the query.",
          type: "error",
        });
      } finally {
        this.refreshingFieldNames = false;
      }
    },

    async duplicateLayer() {
      const confirmMessage = await this.$showAlert({
        text: `This configuration will now be saved and a new layer will be created for each value matching ${this.layer.FilterField},
          If a layer already exists, it will be updated to match this configuration.
          <br><br> Would you like to continue?`,
        type: "warning",
        choice: true,
      });

      if (confirmMessage) {
        this.duplicatingLayer = true;

        try {
          this.allowEditing = false;
          try {
            const updatedLayer = await updateDatasetLayer(this.layer);

            if (updatedLayer.Warnings) {
              this.$showAlert({
                text: updatedLayer.Warnings,
                type: "warning",
              });
            }

            this.emitChange();
          } catch (error) {
            throw "There was an error saving the layer.";
          }

          try {
            const duplicatedLayers = await duplicateFilteredLayer(
              this.datasetName,
              this.datasetOwner,
              this.layerName
            );

            if (duplicatedLayers.Warnings) {
              this.$showAlert({
                text: duplicatedLayers.Warnings,
                type: "warning",
              });
            }
          } catch (error) {
            throw "There was an error duplicating the layer.";
          }
          this.$showAlert({
            text: "Success! <br> <br> Please close the properties window for changes to be visible.",
            timeout: 4000,
            type: "success",
          });
        } catch (error) {
          this.$showAlert({
            text: error,
            type: "error",
          });
        } finally {
          this.duplicatingLayer = false;
          this.allowEditing = true;
        }
      }
    },

    initializeCodeMirror() {
      this.cm = CodeMirror.fromTextArea(
        document.getElementById("query-textarea"),
        {
          theme: "default",
          lineNumbers: true,
          showCursorWhenSelecting: true,
          lineWrapping: false,
          fixedGutter: false,
          autoCloseBrackets: true,
          matchBrackets: true,
          smartIndent: false,
          readOnly: this.edit ? false : "nocursor",
          mode: null,
        }
      );

      this.cm.setSize(350, 100);
      this.cm.setValue(this.queryValue);

      this.cm.on("change", (cm) => {
        this.queryValue = cm.getValue();
      });

      this.cm.on("blur", () => {
        this.saveQuery();
      });

      this.cm.refresh();
    },
  },

  async created() {
    await this.loadLayerDetails(
      this.datasetName,
      this.datasetOwner,
      this.layerName
    );

    this.initializeCodeMirror();
  },

  mounted() {
    if (this.datasetType === "Vector" && !this.informationOnlyMode) {
      this.refreshLayerFields(this.value);
    }
  },
};
</script>

<style scoped>
.property-headers {
  color: rgba(0, 0, 0, 1);
  font-size: 0.8125rem;
  font-weight: 500;
  padding: 6px 0;
  height: initial;
}
.property-titles {
  color: rgba(0, 0, 0, 1);
  font-size: 0.8125rem;
  padding-left: 0;
  height: initial;
}
</style>

<template>
  <v-card v-show="mapTimes.length > 1">
    <v-card-text class="pa-2">
      <v-row class="d-flex flex-column-reverse flex-md-row flex-wrap">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-wrap flex-sm-nowrap justify-center align-center justify-md-space-between"
        >
          <div>
            <v-card-subtitle class="text-center pa-1">
              Time Controls
            </v-card-subtitle>
            <v-select
              class="px-2"
              v-model="selectedTimeStepType"
              :items="timeStepTypes"
              item-value="value"
              item-text="text"
              :item-disabled="shouldDisable"
              return-object
              hide-details
              dense
              :disabled="disallowTimestepSelection"
              @change="generateNewMapTimes"
            >
            </v-select>
          </div>

          <div class="d-flex flex-column justify-center">
            <div class="mx-auto">
              <v-btn icon @click="timeStep--" :disabled="timeStep === 0">
                <v-icon :color="$store.state.settings.appColor">
                  mdi-menu-left
                </v-icon>
              </v-btn>

              <v-btn
                icon
                @click="timeStep++"
                :disabled="timeStep === sliderMax"
              >
                <v-icon :color="$store.state.settings.appColor">
                  mdi-menu-right
                </v-icon>
              </v-btn>
            </div>

            <div class="mx-auto">
              <v-btn text small outlined @click="initPreload()">Preload</v-btn>
            </div>
          </div>
        </v-col>
        <v-divider vertical inset></v-divider>
        <v-col
          cols="12"
          md="8"
          class="d-flex flex-column justify-space-between"
        >
          <div class="d-flex justify-center">
            <code class="current-time text--black">{{
              selectedTimeStepType ? formatMapTimeLabels(mapTime) : "-"
            }}</code>
          </div>
          <div class="time-slider d-flex align-center">
            <v-slider
              v-if="sliderMax"
              class="px-4"
              v-model="timeStep"
              :color="$store.state.settings.appColor"
              track-color="grey"
              step="1"
              tick-size="4"
              ticks="always"
              :max="sliderMax"
              hide-details
              dense
            >
              <template v-slot:prepend>
                <code class="mr-4">{{
                  selectedTimeStepType ? formatMapTimeLabels(mapTimes[0]) : "-"
                }}</code>
              </template>
              <template v-slot:append>
                <code>{{
                  selectedTimeStepType
                    ? formatMapTimeLabels(mapTimes[mapTimes.length - 1])
                    : "-"
                }}</code>
              </template>
            </v-slider>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "MapDataViewerTimeSlider",

  props: {
    mapTimes: { type: Array },
    datasets: { type: Array },
    disallowTimestepSelection: { type: Boolean, default: true },
  },

  data() {
    return {
      timeStepTypes: [
        {
          value: "Daily",
          text: "Daily",
          interval: 1,
          disabled: false,
        },
        {
          value: "Monthly",
          text: "Monthly",
          interval: 1,
          disabled: false,
        },
        {
          value: "Annually",
          text: "Annual",
          interval: 1,
          disabled: false,
        },
        {
          value: "SemiDecadally",
          text: "Semi-Decadal",
          interval: 5,
          disabled: false,
        },
        {
          value: "Decadally",
          text: "Decadal",
          interval: 10,
          disabled: false,
        },
      ],
      selectedTimeStepType: null,
      timeStep: 0,
      generatedMapTimes: [],
      timeControlMenu: false,
    };
  },

  watch: {
    mapTime(newTime) {
      this.updatedMapTime(newTime);
    },
    mapTimes() {
      this.generateNewMapTimes();
    },
    availableTimeSteps(steps) {
      if (!this.selectedTimeStepType) {
        const timeStepType = steps[0];

        this.selectedTimeStepType = this.timeStepTypes.find(
          (type) => type.value === timeStepType
        );
      }
    },
  },

  computed: {
    sliderMax() {
      return this.generatedMapTimes.length - 1;
    },
    mapTime() {
      return !this.generatedMapTimes.length
        ? null
        : this.generatedMapTimes[this.timeStep];
    },

    availableTimeSteps() {
      const existingTimeSteps = new Set();

      this.datasets.forEach((dataset) => {
        if (dataset.layer.Timestep) {
          existingTimeSteps.add(dataset.layer.Timestep);
        }
      });

      return [...existingTimeSteps];
    },
  },

  methods: {
    setInitialTimeStep() {
      this.selectedTimeStepType = this.timeStepTypes.find(
        (type) => type.value === this.availableTimeSteps[0]
      );
    },
    updatedMapTime(newTime) {
      this.$emit("map-time-updated", newTime);
    },
    formatMapTimeLabels(time) {
      const timeStepType = this.selectedTimeStepType.value;

      const weekdays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "November",
        "October",
        "December",
      ];

      let day = time.getDay();
      let date = time.getDate();
      let month = time.getMonth() + 1;
      let year = time.getFullYear();

      switch (timeStepType) {
        case "Daily":
          return `${weekdays[day]} ${months[month]} ${date} ${year}`;
        case "Monthly":
          return `${months[month]} ${year}`;
        case "Annually":
        case "SemiDecadally":
        case "Decadally":
          return `${year}`;
        default:
          return "-";
      }
    },
    generateNewMapTimes() {
      // start date
      const startDate = this.mapTimes[0];

      // end date
      const endDate = this.mapTimes[this.mapTimes.length - 1];

      // array of dates
      let datesArray = [];

      if (this.selectedTimeStepType) {
        const timeStep = this.selectedTimeStepType.value;

        const interval = this.selectedTimeStepType.interval;

        if (timeStep === "Daily") {
          // loop from start date to end date
          for (
            let date = new Date(startDate);
            date <= endDate;
            date.setDate(date.getDate() + interval)
          ) {
            datesArray.push(new Date(date));
          }
        } else if (timeStep === "Monthly") {
          //months
          for (
            let date = new Date(startDate);
            date <= endDate;
            date.setMonth(date.getMonth() + interval)
          ) {
            datesArray.push(new Date(date));
          }
        }
        //years
        else {
          for (
            let date = new Date(startDate);
            date <= endDate;
            date.setFullYear(date.getFullYear() + interval)
          ) {
            datesArray.push(new Date(date));
          }
        }
      } else {
        datesArray = this.mapTimes;
      }

      this.generatedMapTimes = datesArray;
      this.timeStep = 0;
    },
    initPreload() {
      this.timeStep = 0;
      let i = 1;

      const nextIncrement = () => {
        if (i === this.generatedMapTimes.length) return;
        setTimeout(() => {
          this.timeStep = i;
          i++;
          nextIncrement();
        }, 1000);
      };

      nextIncrement();

      this.timeStep = 0;
    },
    shouldDisable(selectionType) {
      const available = this.availableTimeSteps.find(
        (type) => type === selectionType.value
      );

      if (available !== undefined) return false;
      else return true;
    },
  },
  created() {
    this.generateNewMapTimes();
    this.setInitialTimeStep();
  },
};
</script>

<style scoped>
.current-time {
  border: solid;
  border-radius: 2px;
}
</style>

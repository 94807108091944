import {
  getDatasetList,
  getDataset,
  getExpressionList,
  getProjectList,
  getTable,
  updateTableRow,
  // deleteTableRow,
} from "@/api/v2";
// import { updateSeasonTableRow } from "@/api/geoprocessing";
import { formatTable } from "@/helpers/formatting";

const state = {
  datasetList: {
    lastUpdated: null,
    datasets: {
      AllDatasets: [],
      AllTags: [],
      Errors: null,
      Warnings: null,
    },
    updatedRequired: false,
    fetchingUpdates: false,
  },
  expressionList: {
    lastUpdated: null,
    expressions: {
      AllExpressions: [],
      AllTags: [],
      Errors: null,
      Warnings: null,
    },
    updatedRequired: false,
    fetchingUpdates: false,
  },
  projectList: {
    lastUpdated: null,
    projects: {
      AllProjects: [],
      AllTags: [],
      Errors: null,
      Warnings: null,
    },
    updatedRequired: false,
    fetchingUpdates: false,
  },
  seasonList: {
    seasonDataset: null,
    ranchName: "",
    lastUpdated: null,
    seasons: [],
    seasonTable: null,
    fetchingUpdates: false,
  },
};
const mutations = {
  updateDatasetList(state, datasetResponse) {
    state.datasetList.datasets = datasetResponse;
    state.datasetList.lastUpdated = new Date();
    state.datasetList.updatedRequired = false;
  },
  datasetListUpdateRequired(state) {
    state.datasetList.updatedRequired = true;
  },

  fetchingDatasetList(state, booleanValue) {
    state.datasetList.fetchingUpdates = booleanValue;
  },

  // updates a single dataset state
  updateDataset(state, updatedDataset) {
    const dataset = state.datasetList.datasets.AllDatasets.find(
      (dataset) => dataset.DatasetName === updatedDataset.DatasetName
    );
    Object.assign(dataset, updatedDataset);
  },

  // TODO: addDataset(state, newDataset) {},

  // TODO: removeDataset(state, datasetToRemove) {},

  // makes updates to dataset list based on response object from deleting, adding, modifying datasets, etc.
  // TODO: modifyDatasetList(state, modifiedDatasets) {
  //   switch (modifiedDatasets) {
  //     case modifiedDatasets.DeletedDatasets:
  //       console.log(modifiedDatasets.DeletedDatasets);
  //       break;
  //   }
  // },

  updateExpressionList(state, expressionResponse) {
    state.expressionList.expressions = expressionResponse;
    state.expressionList.lastUpdated = new Date();
    state.expressionList.updatedRequired = false;
  },
  expressionListUpdateRequired(state) {
    state.expressionList.updatedRequired = true;
  },

  fetchingExpressionList(state, booleanValue) {
    state.expressionList.fetchingUpdates = booleanValue;
  },

  updateProjectList(state, projectResponse) {
    state.projectList.projects = projectResponse;
    state.projectList.lastUpdated = new Date();
    state.projectList.updatedRequired = false;
  },
  projectListUpdateRequired(state) {
    state.projectList.updatedRequired = true;
  },
  fetchingProjectList(state, booleanValue) {
    state.projectList.fetchingUpdates = booleanValue;
  },

  updateSeasonList(state, { ranchName, seasonDataset, refreshedSeasons }) {
    // compile season object

    const formattedTable = formatTable(refreshedSeasons);

    state.seasonList.seasonDataset = seasonDataset;
    state.seasonList.ranchName = ranchName;

    state.seasonList.seasonTable = refreshedSeasons;
    state.seasonList.seasons = formattedTable;
    state.seasonList.lastUpdated = new Date();
    state.seasonList.updatedRequired = false;
  },
  seasonListUpdateRequired(state) {
    state.seasonList.updatedRequired = true;
  },
  fetchingSeasonList(state, booleanValue) {
    state.seasonList.fetchingUpdates = booleanValue;
  },
  clearSeasonList(state) {
    state.seasonList = {
      ranchName: "",
      lastUpdated: null,
      seasons: [],
      seasonTable: null,
      fetchingUpdates: false,
    };
  },

  updateSeason(state, updatedSeason) {
    // find season by some key and update season list value
    const season = state.seasonList.seasons.find(
      (season) => season.StartDate === updatedSeason.StartDate
    );

    Object.assign(season, updatedSeason);

    return season;
  },

  resetAccountData(state) {
    state.datasetList = {};
    state.expressionList = {};
    state.projectList = {};
    state.seasonList = [];
  },
};
const getters = {};
const actions = {
  async refreshDatasetList({ commit }) {
    try {
      commit("fetchingDatasetList", true);
      const refreshedDatasets = await getDatasetList();

      // throw error if response contains flow error
      if (refreshedDatasets.Errors) {
        throw refreshedDatasets.Errors;
      }

      refreshedDatasets.AllDatasets.sort((a, b) => {
        const itemA = a.DatasetName.toUpperCase();
        const itemB = b.DatasetName.toUpperCase();
        if (itemA < itemB) {
          return -1;
        }
        if (itemA > itemB) {
          return 1;
        }
        // names must be equal
        return 0;
      });

      commit("updateDatasetList", refreshedDatasets);

      // show warning after list has been refreshed
      if (refreshedDatasets.Warnings) {
        this.commit(
          "alert/showAlert",
          {
            text: refreshedDatasets.Warnings,
            type: "warning",
          },
          { root: true }
        );
      }

      return refreshedDatasets;
    } catch (error) {
      this.commit(
        "alert/showAlert",
        {
          text: error,
          type: "error",
        },
        { root: true }
      );
    } finally {
      commit("fetchingDatasetList", false);
    }
  },
  async refreshExpressionList({ commit }) {
    commit("fetchingExpressionList", true);
    try {
      const refreshedExpressions = await getExpressionList();

      // throw error if response contains flow error
      if (refreshedExpressions.Errors) {
        throw refreshedExpressions.Errors;
      }

      refreshedExpressions.AllExpressions.sort((a, b) => {
        const itemA = a.ExpressionName.toUpperCase();
        const itemB = b.ExpressionName.toUpperCase();
        if (itemA < itemB) {
          return -1;
        }
        if (itemA > itemB) {
          return 1;
        }
        // names must be equal
        return 0;
      });

      commit("updateExpressionList", refreshedExpressions);

      // show warning after list has been refreshed
      if (refreshedExpressions.Warnings) {
        this.commit(
          "alert/showAlert",
          {
            text: refreshedExpressions.Warnings,
            type: "warning",
          },
          { root: true }
        );
      }
    } catch (error) {
      this.commit(
        "alert/showAlert",
        {
          text: error,
          type: "error",
        },
        { root: true }
      );
    } finally {
      commit("fetchingExpressionList", false);
    }
  },
  async refreshProjectList({ commit }) {
    try {
      commit("fetchingProjectList", true);
      const refreshedProjects = await getProjectList();

      // throw error if response contains flow error
      if (refreshedProjects.Errors) {
        throw refreshedProjects.Errors;
      }

      // sort by date initially
      refreshedProjects.AllProjects.sort(
        (a, b) => new Date(b.UpdatedAt) - new Date(a.UpdatedAt)
      );

      commit("updateProjectList", refreshedProjects);

      // show warning after list has been refreshed
      if (refreshedProjects.Warnings) {
        this.commit(
          "alert/showAlert",
          {
            text: refreshedProjects.Warnings,
            type: "warning",
          },
          { root: true }
        );
      }

      return refreshedProjects;
    } catch (error) {
      this.commit(
        "alert/showAlert",
        {
          text: error,
          type: "error",
        },
        { root: true }
      );
    } finally {
      commit("fetchingProjectList", false);
    }
  },

  async refreshSeasonList({ commit }, ranchInfo) {
    try {
      commit("fetchingSeasonList", true);

      const seasonDataset = await getDataset(
        `${ranchInfo.datasetName}/Season`,
        ranchInfo.datasetOwner
      );

      if (seasonDataset.Files.length === 0) {
        const noSeasonsError = `<strong>It appears no seasons have been uploaded yet for this ranch.</strong>
          <br/> To continue, add a new season through the Season Manager page.`;

        throw new Error(noSeasonsError);
      }

      const fileId = seasonDataset.Files[0].Files[0].TablePK;

      const refreshedSeasons = await getTable(
        seasonDataset.DatasetName,
        seasonDataset.DatasetOwner,
        fileId,
        false
      );

      // throw error if response contains flow error
      if (refreshedSeasons.Errors) {
        throw refreshedSeasons.Errors;
      }

      refreshedSeasons.ranchName = ranchInfo.datasetName;

      commit("updateSeasonList", {
        ranchName: ranchInfo.datasetName,
        seasonDataset,
        refreshedSeasons,
      });

      // show warning after list has been refreshed
      if (refreshedSeasons.Warnings) {
        this.commit(
          "alert/showAlert",
          {
            text: refreshedSeasons.Warnings,
            type: "warning",
          },
          { root: true }
        );
      }

      return refreshedSeasons;
    } catch (error) {
      this.commit(
        "alert/showAlert",
        {
          text: error,
          type: "error",
        },
        { root: true }
      );
    } finally {
      commit("fetchingSeasonList", false);
    }
  },

  // async updateSeason({ commit }, newDataInfo) {
  //   try {
  //     commit("fetchingSeasonList", true);
  //     const updatedSeason = await updateSeasonTableRow(
  //       newDataInfo.ranchOwner,
  //       newDataInfo.ranchName,
  //       newDataInfo.newRowData
  //     );

  //     // throw error if response contains flow error
  //     if (updatedSeason.Errors) {
  //       throw updatedSeason.Errors;
  //     }

  //     console.log(updatedSeason);

  //     const seasonUpdated = commit("updateSeason", updatedSeason);

  //     // show warning after list has been refreshed
  //     if (updatedSeason.Warnings) {
  //       this.commit(
  //         "alert/showAlert",
  //         {
  //           text: updatedSeason.Warnings,
  //           type: "warning",
  //         },
  //         { root: true }
  //       );
  //     }

  //     return seasonUpdated;
  //   } catch (error) {
  //     this.commit(
  //       "alert/showAlert",
  //       {
  //         text: error,
  //         type: "error",
  //       },
  //       { root: true }
  //     );
  //   } finally {
  //     commit("fetchingSeasonList", false);
  //   }
  // },

  async updateTable({ state, commit }, newDataInfo) {
    const ranchName = state.seasonList.ranchName;
    const seasonDataset = state.seasonList.seasonDataset;

    try {
      const updatedTable = await updateTableRow(
        newDataInfo.tableInformation,
        Object.values(newDataInfo.newRowData)
      );

      // throw error if response contains flow error
      if (updatedTable.Errors) {
        throw updatedTable.Errors;
      }

      // show warning after list has been refreshed
      if (updatedTable.Warnings) {
        this.commit(
          "alert/showAlert",
          {
            text: updatedTable.Warnings,
            type: "warning",
          },
          { root: true }
        );
      }

      const refreshedSeasons = await getTable(
        newDataInfo.tableInformation.DatasetName,
        newDataInfo.tableInformation.DatasetOwner,
        newDataInfo.tableInformation.FileId,
        false
      );

      // throw error if response contains flow error
      if (refreshedSeasons.Errors) {
        throw refreshedSeasons.Errors;
      }

      refreshedSeasons.ranchName = ranchName;

      commit("updateSeasonList", {
        ranchName: ranchName,
        seasonDataset,
        refreshedSeasons,
      });

      // show warning after list has been refreshed
      if (refreshedSeasons.Warnings) {
        this.commit(
          "alert/showAlert",
          {
            text: refreshedSeasons.Warnings,
            type: "warning",
          },
          { root: true }
        );
      }

      return refreshedSeasons;
      // const seasonUpdated = commit("updateSeason", updatedSeason);
    } catch (error) {
      this.commit(
        "alert/showAlert",
        {
          text: error,
          type: "error",
        },
        { root: true }
      );
    } finally {
      console.log("complete");
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import { getProject, getRunStatus } from "@/api/v2";

const state = {
  activeProjectIsLoading: false,
  activeProject: null,
};

const mutations = {
  setProject(state, { project, isLoading }) {
    state.activeProject = project;
    state.activeProjectIsLoading = isLoading;
  },

  updateProjectPermissions(state, projectPermissions) {
    state.activeProject.Properties.Tags = projectPermissions;
  },
  updateSimulation(state, updatedScenario) {
    let update = state.activeProject.Simulations.map((simulation) => {
      if (simulation.ExpressionName === updatedScenario.ExpressionName) {
        const updatedRuns = simulation.Runs.map((run) => {
          if (run.ScenarioName === updatedScenario.ScenarioName) {
            run = updatedScenario;
            return run;
          }
          return run;
        });

        simulation.Runs = updatedRuns;

        return simulation;
      }
      return simulation;
    });

    console.log(update);

    state.activeProject.Simulations = update;
  },
};
const getters = {};

const actions = {
  loadProject({ commit }, { projectOwner, projectName }) {
    return new Promise((resolve, reject) => {
      getProject(projectName, projectOwner).then(
        (res) => {
          commit("setProject", { project: res, isLoading: false });
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  updateSimulationStatus({ commit }, scenario) {
    let projectOwner = scenario.ProjectOwner;
    let projectName = scenario.ProjectName;
    let expressionName = scenario.ExpressionName;
    let runOwner = scenario.RunOwner;
    let scenarioName = scenario.ScenarioName;
    // prevent api calls if done or failed
    if (scenario.Status === "Done" || scenario.Status === "Failed") {
      return;
    } else {
      getRunStatus(
        projectName,
        projectOwner,
        expressionName,
        runOwner,
        scenarioName
      )
        .then((response) => {
          if (!response.Warnings) {
            // Updates local state only
            const newScenarioStatus = {
              statusDetails: {
                Status: response.Status,
                Progress: response.Progress,
              },
            };

            let updatedScenario = {
              ...scenario,
              ...newScenarioStatus.statusDetails,
            };

            commit("updateSimulation", updatedScenario);

            // refresh dataset list when status is returned "Done"
            if (response.Status === "Done") {
              this.dispatch("accountData/refreshDatasetList", {
                root: true,
              });
            }
          }

          return response;
        })
        .catch((error) => {
          this.commit(
            "alert/showAlert",
            {
              text: error,
              type: "error",
            },
            { root: true }
          );
        });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div
    class="editable-container"
    id="editable-container-id"
    ref="editableContainer"
  >
    <!-- <h1
      id="map-title"
      class="editable"
      contenteditable="true"
      ref="title"
      data-form-type="other"
    >
      Example title text
    </h1> -->
    <!-- <div id="legend" class="legend" ref="legend">Legend</div> -->
    <div
      id="map-legend"
      class="map-legend"
      data-form-type="other"
      v-if="currentDatasets.length > 0"
    >
      <div v-for="(dataset, index) in currentDatasets" :key="index">
        <div class="legend-item-container">
          <h4
            contenteditable="true"
            style="text-align: center"
            class="mb-0"
            v-if="dataset.layer.TypeOfData !== 'Mask'"
          >
            {{ dataset.dataset.Style.DisplayName }}
          </h4>
          <map-data-viewer-printable-legend-item :dataset="dataset">
          </map-data-viewer-printable-legend-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MapDataViewerPrintableLegendItem from "@/components/mapping/MapDataViewerPrintableLegendItem.vue";
import html2canvas from "html2canvas";
import "mapbox-gl/dist/mapbox-gl.css";
// import MapGenerator from "../../../../modules/map-generator";

// import { DPI, Format, Unit, Size } from "../../../../modules/map-generator";

export default {
  components: {
    MapDataViewerPrintableLegendItem,
  },

  props: {
    map: null,
    accessToken: null,
    mapCanvas: {
      type: Element,
    },
  },

  data() {
    return {
      mapOverlayCanvas: null,
    };
  },
  computed: {
    currentDatasets() {
      return this.$store.state.mapping.datasets.filter((dataset) => {
        return (
          dataset.dataset.DatasetType === "Raster" ||
          (dataset.dataset.DatasetType === "Vector" &&
            dataset.mapLayerPurpose !== "StudyAreaMapLayer")
        );
      });
    },
  },

  methods: {
    // getCanvasContainer() {
    //   const canvasContainer = this.map.getCanvasContainer();
    // },

    mountControl() {
      // Custom control
      class ExportMapElementsControl {
        constructor() {
          this.onDocumentClick = this.onDocumentClick.bind(this);
        }

        onAdd(map) {
          this.map = map;

          this.controlContainer = document.createElement("div");
          this.controlContainer.classList.add("mapboxgl-ctrl");
          this.controlContainer.classList.add("mapboxgl-ctrl-group");
          this.exportContainer = document.createElement("div");
          this.exportContainer.classList.add("mapboxgl-export-list");
          this.exportMenuButton = document.createElement("button");
          this.exportMenuButton.classList.add("mapboxgl-ctrl-icon");
          this.exportMenuButton.classList.add("mapboxgl-export-menu-control");
          this.exportMenuButton.title = "Show/Hide Printable Legend";
          this.exportMenuButton.type = "button";
          this.exportMenuButton.addEventListener("click", () => {
            this.showExportMenu();
          });
          document.addEventListener("click", this.onDocumentClick);
          this.controlContainer.appendChild(this.exportMenuButton);

          this.generateButton = document.createElement("button");
          this.generateButton.type = "button";

          this.generateButton.classList.add("generate-map-elements");
          this.generateButton.classList.add("mapboxgl-ctrl-icon");
          this.generateButton.style.display = "none";
          this.generateButton.addEventListener(
            "click",
            this.captureAndSave.bind(this)
          );
          this.generateButton.title = "Save Printable Map Elements";
          this.controlContainer.appendChild(this.generateButton);

          this.showMenu = false;

          return this.controlContainer;
        }

        onRemove() {
          if (
            !this.controlContainer ||
            !this.controlContainer.parentNode ||
            !this.map ||
            !this.exportButton
          ) {
            return;
          }
          this.exportButton.removeEventListener("click", this.onDocumentClick);
          this.controlContainer.parentNode.removeChild(this.controlContainer);
          document.removeEventListener("click", this.onDocumentClick);
          this.map = undefined;
        }

        showExportMenu() {
          this.showMenu = !this.showMenu;
          this.showPrintableMapElements = !this.showPrintableMapElements;

          if (this.showMenu) {
            this.generateButton.style.display = "block";

            if (document.getElementById("map-legend")) {
              document.getElementById("map-legend").style.display = "flex";
            }
          } else {
            this.generateButton.style.display = "none";

            if (document.getElementById("map-legend")) {
              document.getElementById("map-legend").style.display = "none";
            }
          }
        }

        onDocumentClick(event) {
          if (
            this.controlContainer &&
            !this.controlContainer.contains(event.target) &&
            this.exportContainer &&
            this.exportButton
          ) {
            this.exportContainer.style.display = "none";
            this.exportButton.style.display = "block";
          }
        }

        captureAndSave() {
          // grab elements to print\
          const legendElement = document.getElementById("map-legend");
          // const titleElement = document.getElementById("map-title");
          const scaleElement = document.getElementsByClassName(
            "mapboxgl-ctrl-scale"
          )[0];

          scaleElement.setAttribute("id", "map-scale");

          // const toPixels = (length, conversionFactor = 96) => {
          //   conversionFactor /= 25.4;
          //   return `${conversionFactor * length}`;
          // };

          const printableOverlayElements = [
            scaleElement,
            legendElement,
            // titleElement,
          ];

          printableOverlayElements.forEach((element) => {
            if (element) {
              html2canvas(element, {
                scale: 1,
                backgroundColor: null,
              }).then((canvas) => {
                const link = document.createElement("a");
                link.href = canvas.toDataURL("image/png");
                link.download = `${element.id}.png`;
                link.click();
              });
            }
          });
        }
      }

      const mapElementsControl = new ExportMapElementsControl();
      this.map.addControl(mapElementsControl, "bottom-right");
    },

    // captureAndSave() {
    //   // grab elements to print\
    //   const legendElement = document.getElementById("map-legend");
    //   // const titleElement = document.getElementById("map-title");
    //   const scaleElement = document.getElementsByClassName(
    //     "mapboxgl-ctrl-scale"
    //   )[0];

    //   scaleElement.setAttribute("id", "map-scale");

    //   // const toPixels = (length, conversionFactor = 96) => {
    //   //   conversionFactor /= 25.4;
    //   //   return `${conversionFactor * length}`;
    //   // };

    //   const printableOverlayElements = [
    //     scaleElement,
    //     legendElement,
    //     // titleElement,
    //   ];

    //   printableOverlayElements.forEach((element) => {
    //     if (element) {
    //       html2canvas(element, {
    //         scale: 1,
    //         backgroundColor: null,
    //       }).then((canvas) => {
    //         const link = document.createElement("a");
    //         link.href = canvas.toDataURL("image/png");
    //         link.download = `${element.id}.png`;
    //         link.click();
    //       });
    //     }
    //   });

    //   // alternate workflow

    //   //   // let printedMap = new MapGenerator(
    //   //   //   this.map,
    //   //   //   Size.A3,
    //   //   //   DPI[300],
    //   //   //   Format.PNG,
    //   //   //   Unit.mm,
    //   //   //   this.accessToken,
    //   //   //   canvas
    //   //   // );

    //   //   // printedMap.generate();
    // },
  },

  mounted() {
    this.mountControl();
  },
};
</script>

<style>
/* .editable-container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
} */

#map-title {
  position: absolute;
  /* width: 300px; */
  min-width: 25%;
  max-width: 50%;
  /* position: absolute; */
  left: 50%;
  transform: translate(-50%, 0);
  top: 0px;
  text-align: center;
  padding: 10px;
  background-color: white;
}

.map-legend {
  display: none;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 3px;
  bottom: 300px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 10px;
  position: absolute;
  left: 100px;
  z-index: 1;
}

.map-legend div span {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin-right: 5px;
  width: 10px;
}

/* #editable-container-id > div.mapboxgl-ctrl.mapboxgl-ctrl-scale {
  position: absolute;
  bottom: 30px;
  left: 400px;
} */

.mapboxgl-export-menu-control {
  display: none;
}

.mapboxgl-ctrl-group .mapboxgl-export-list button {
  background: none;
  border: none;
  cursor: pointer;
  display: block;
  font-size: 14px;
  padding: 8px 8px 6px;
  text-align: right;
  width: 100%;
  height: auto;
  text-align: center;
}
/* 
.mapboxgl-export-list button.active {
  font-weight: bold;
}

.mapboxgl-export-list button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.mapboxgl-export-list button + button {
  border-top: 1px solid #ddd;
} */

.mapboxgl-export-menu-control {
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>map-legend</title><path d="M9,3L3.36,4.9C3.15,4.97 3,5.15 3,5.38V20.5A0.5,0.5 0 0,0 3.5,21L3.66,20.97L9,18.9L15,21L20.64,19.1C20.85,19.03 21,18.85 21,18.62V3.5A0.5,0.5 0 0,0 20.5,3L20.34,3.03L15,5.1L9,3M8,5.45V17.15L5,18.31V6.46L8,5.45M10,5.47L14,6.87V18.53L10,17.13V5.47M19,5.7V17.54L16,18.55V6.86L19,5.7M7.46,6.3L5.57,6.97V9.12L7.46,8.45V6.3M7.46,9.05L5.57,9.72V11.87L7.46,11.2V9.05M7.46,11.8L5.57,12.47V14.62L7.46,13.95V11.8M7.46,14.55L5.57,15.22V17.37L7.46,16.7V14.55Z" /></svg>');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
}

.generate-map-elements {
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>content-save</title><path d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z" /></svg>');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
}
</style>

<template>
  <div class="d-flex justify-center align-center pa-5" v-if="initializing">
    <v-progress-circular indeterminate :color="color"></v-progress-circular>
  </div>
  <div v-else>
    <v-select
      v-model="modelParams.units"
      label="Select Units"
      :items="unitNames"
      @input="update"
      :color="color"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "CellSize",

  props: { params: Object },

  data() {
    return {
      initializing: true,
      modelParams: { units: "square metres" },
      unitNames: [
        "square metres",
        "square kilometres",
        "hectares",
        "square miles",
        "acres",
      ],
    };
  },

  computed: {
    color() {
      return this.$store.state.settings.appColor;
    },
  },

  methods: {
    update() {
      this.$emit("submit-params", this.modelParams);
    },
  },

  beforeMount() {
    this.modelParams = { ...this.modelParams, ...this.params };
    this.update()
    setTimeout(() => (this.initializing = false), 100);
  },
};
</script>

<template>
  <v-container
    v-if="projectPage === 'Project'"
    no-gutters
    fluid
    id="project-settings-container"
  >
    <v-row
      v-if="
        activeProjectProperties != null &&
        activeProjectProperties.CreatedFromProjectName != null
      "
    >
      <v-col cols="12" class="d-flex flex-column">
        <v-card class="flex d-flex flex-column">
          <v-card-title>Update Project</v-card-title>

          <v-card-text>
            <p>
              Click 'Fetch Updates' to retrieve updates from the source project.
              Note that this will overwrite any changes you made to project
              settings (e.g, Description or Resolution) or expression settings
              (e.g., Scenario period).
            </p>

            <p>
              Source Project:
              <b>{{ activeProjectProperties.CreatedFromProjectName }}</b>
              ({{ activeProjectProperties.CreatedFromProjectOwner }})
            </p>

            <v-btn block @click="doCopyProject" style="margin-top: 1em">
              <v-icon left>mdi-sync</v-icon>
              Fetch Updates
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" md="6" class="d-flex flex-column">
        <v-card
          class="flex d-flex flex-column"
          :loading="
            changedSettings.has('Description') ||
            changedSettings.has('ProjectImage')
          "
        >
          <div class="d-flex justify-lg-space-between">
            <v-card-title>About</v-card-title>

            <v-card-actions class="ml-auto">
              <v-dialog
                scrollable
                hide-overlay
                max-width="800px"
                v-model="editProjectImage"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">
                          mdi-image-edit
                        </v-icon>
                      </template>

                      <span>Change image banner</span>
                    </v-tooltip>
                  </v-btn>
                </template>

                <project-image-selector
                  v-if="editProjectImage"
                  :input-image="settings.ProjectImage"
                  @update-image="onProjectImageUpdate"
                />
              </v-dialog>
            </v-card-actions>
          </div>

          <v-card-text>
            Add a project description to tell other people what your project is
            used for.
          </v-card-text>

          <v-textarea
            class="ma-4 align-content-start"
            auto-grow
            label="Description"
            v-model="settings.Description"
            @keyup="onDescriptionUpdate"
          />
        </v-card>
      </v-col>

      <v-col cols="12" md="6" class="d-flex flex-column">
        <v-card
          class="flex d-flex flex-column"
          :loading="changedSettings.has('StudyAreaDatasetName')"
        >
          <v-card-title>Study Area*</v-card-title>

          <v-card-text>
            <p>
              Pick a study region. This is the region where expressions are
              evaluated. You can select an individual layer from a vector
              dataset that contains a single polygon or from a raster dataset
              where the area is defined by a single grouping of pixels. For
              vector datasets, choosing the "Default" layer will define the
              study region by all polygon features within.
            </p>

            <v-row>
              <v-col cols="6">
                <v-list>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle>Dataset*</v-list-item-subtitle>

                      <v-list-item-title>
                        {{ settings.StudyAreaDatasetName || "None" }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>

              <v-col cols="6">
                <v-list>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle>Layer</v-list-item-subtitle>

                      <v-list-item-title>
                        {{
                          settings.StudyAreaName
                            ? formatLayerName(settings.StudyAreaName)
                            : settings.StudyAreaDatasetName &&
                              !settings.StudyAreaName
                            ? "Default"
                            : "None"
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>

          <v-row class="ma-1 align-content-end">
            <v-col cols="12">
              <v-dialog
                scrollable
                v-model="editStudyArea"
                width="80%"
                height="80%"
                persistent
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" block>
                    <v-icon>mdi-vector-rectangle</v-icon>
                    Change
                  </v-btn>
                </template>

                <v-card id="selector-overlay-card">
                  <v-card-text class="selector-overlay-card-content pa-0">
                    <dataset-selector
                      :selection-mode="true"
                      :batch-select="false"
                      :single-select="false"
                      :multi-layer-select="true"
                      :crud-actions="false"
                      :initial-tags="['Study Area']"
                      :data-table-height="400"
                      :filter-by-project="false"
                      :filter-by-expression="false"
                      @update-dataset="onStudyAreaDatasetUpdate"
                      @update-layer="onStudyAreaNameUpdate"
                    ></dataset-selector>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions class="d-flex justify-center align-center">
                    <div class="text-center">
                      <v-btn
                        :disabled="!selectedStudyArea"
                        text
                        @click="confirmStudyAreaDialog"
                      >
                        Update study area
                      </v-btn>

                      <v-btn
                        color="warning"
                        text
                        @click="cancelStudyAreaDialog"
                      >
                        Cancel
                      </v-btn>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" class="d-flex flex-column">
        <v-card
          class="flex d-flex flex-column"
          :loading="changedSettings.has('CRS')"
        >
          <v-card-title>Coordinate System*</v-card-title>

          <v-card-text>
            <p>
              Pick a coordinate system for your project. For most use cases you
              can pick a predefined coordinate system from the drop-down below.
            </p>

            <project-crs-selector
              :projectCRS="settings.CRS"
              @update-crs="onCRSUpdate"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" class="d-flex flex-column">
        <v-card class="flex d-flex flex-column">
          <v-card-title>Permissions</v-card-title>

          <v-card-text>
            <p>
              Give others
              <b>view</b>
              or
              <b>edit</b>
              access to your project. You can grant access to an individual or a
              group of users. Giving someone
              <b>view</b>
              access allows them to open your project, see your scenarios, and
              see the expressions and datasets used in your project. Giving
              someone
              <b>edit</b>
              access also allows them to edit your project settings, run new
              scenarios, delete existing scenarios, and add or remove
              expressions to your project.
            </p>

            <p>
              Sharing your project automatically grants
              <b>view</b>
              access to all the expressions and datasets used in your project.
              To grant
              <b>edit</b>
              access to an expression, use the expression manager. To revoke a
              user's or group's access to an expression or dataset, use the
              expression manager and dataset manager.
            </p>
            <p>A project can only be deleted by its owner.</p>
          </v-card-text>

          <v-divider class="mx-8 mt-7"></v-divider>

          <v-subheader> Current Permissions </v-subheader>
          <v-list dense two-line class="d-flex justify-start flex-wrap">
            <v-list-item
              class="userPermission"
              max-width="30px"
              dense
              v-for="permission in currentSharedPermissions"
              :key="permission.user"
            >
              <v-list-item-content class="pa-0">
                <v-list-item-title class="text-wrap">
                  {{ permission.user || "" }}
                </v-list-item-title>

                <v-list-item-subtitle>
                  {{
                    permission.edit
                      ? "view & edit"
                      : permission.view
                      ? "view"
                      : ""
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-row class="ma-1 align-content-end">
            <v-col cols="12">
              <v-dialog
                v-model="dialogShare"
                max-width="500px"
                scrollable
                persistent
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn block v-bind="attrs" v-on="on">
                    <v-icon left>mdi-share</v-icon>
                    Share Project
                  </v-btn>
                </template>

                <data-share
                  :selected-data="[project.activeProject]"
                  :share-type="'Project'"
                  @close-share-dialog="dialogShare = false"
                >
                </data-share>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" class="d-flex flex-column align">
        <v-card
          class="flex d-flex flex-column justify-space-evenly"
          :loading="
            changedSettings.has('CellSizeX') || changedSettings.has('CellSizeY')
          "
        >
          <v-card-title>Resolution*</v-card-title>

          <v-card-text>
            <p>
              Define the resolution that you want expressions evaluated at. The
              units of these numbers correspond to the units of your project's
              coordinate system.
            </p>

            <p>
              This is project specific, but a general rule of thumb is to pick a
              resolution so that the width and height of your study region is
              divided into 1,000&mdash;10,000 pixels horizontally and
              vertically. Alternatively, you enter "auto" to use a resolution
              that is approximately 1/1,000th of your study region's width and
              height.
            </p>
          </v-card-text>

          <div class="d-flex flex-column pa-4">
            <v-text-field
              v-model="settings.CellSizeX"
              label="X-direction*"
              :rules="[validateCellSize]"
              @keyup="onPixelSizeXUpdate"
              placeholder="auto"
              clearable
            >
            </v-text-field>

            <v-text-field
              v-model="settings.CellSizeY"
              label="Y-direction*"
              :rules="[validateCellSize]"
              @keyup="onPixelSizeYUpdate"
              placeholder="auto"
              clearable
            >
            </v-text-field>
          </div>
        </v-card>
      </v-col>

      <v-col cols="6" class="d-flex flex-column">
        <v-card
          class="flex d-flex flex-column"
          :loading="changedSettings.has('HiddenInputDeviceGroups')"
        >
          <v-card-title>Hidden Variable Groups</v-card-title>

          <v-card-text>
            <p>
              Pick any variable groups that you want to hide in your project.
            </p>

            <v-select
              :items="inputDeviceGroups"
              v-model="settings.HiddenInputDeviceGroups"
              :color="$store.state.settings.appColor"
              label="Hidden groups"
              hide-details
              multiple
              @change="onHiddenInputDeviceGroupsUpdate"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="6"
        class="d-flex flex-column"
        v-if="
          activeProjectProperties.ProjectOwner ===
          $store.state.account.user.attributes.email
        "
      >
        <v-card class="flex d-flex flex-column">
          <v-card-title>Delete Project</v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="10" class="d-flex flex-column">
                <p>
                  Deleting your project will also delete your project's
                  scenarios and datasets. This cannot be undone.
                </p>

                <p>
                  Note that expressions added to your project are not affected.
                </p>
              </v-col>

              <v-col
                cols="12"
                md="2"
                class="d-flex justify-center align-center"
              >
                <v-btn icon @click="doDeleteProject">
                  <v-icon color="red"> mdi-delete </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { debounce } from "vuetify/lib/util/helpers";
import ProjectImageSelector from "@/components/projects/ProjectImageSelector.vue";
import ProjectCrsSelector from "@/components/projects/ProjectCrsSelector.vue";
import DatasetSelector from "@/components/data/DatasetSelector.vue";
import DataShare from "@/components/data/DataShare.vue";
import { updateProjectSettings, deleteProject, copyProject } from "@/api/v2";
export default {
  name: "ProjectSettings",
  components: {
    ProjectImageSelector,
    ProjectCrsSelector,
    DatasetSelector,
    DataShare,
  },
  props: {
    projectPage: String,
    project: { type: Object, default: () => {} },
  },
  data() {
    return {
      dialogShare: false,
      editProjectImage: false,
      editStudyArea: false,
      selectedStudyArea: null,
      selectedLayer: null,
      settings: {
        CRS: null,
        CellSizeX: null,
        CellSizeY: null,
        StudyAreaName: null,
        Description: null,
        ProjectImage: null,
        HiddenInputDeviceGroups: null,
      },
      changedSettings: new Set(),
      debouncedAutosave: null,
      settingsHiddenInputDeviceGroups: [],
    };
  },
  computed: {
    activeProject() {
      return this.project.activeProject;
    },
    activeProjectProperties() {
      if (this.activeProject == null) {
        return null;
      } else {
        return this.activeProject.Properties;
      }
    },

    inputDeviceGroups() {
      let inputDeviceGroups = new Set();
      if (!this.activeProject) {
        return [];
      }
      Object.keys(this.activeProject.InputDevices).forEach(
        (inputDeviceName) => {
          let inputDeviceGroup =
            this.activeProject.InputDevices[inputDeviceName].InputDeviceGroup;
          if (inputDeviceGroup == null) {
            inputDeviceGroup = "No group";
          }
          inputDeviceGroups.add(inputDeviceGroup);
        }
      );
      inputDeviceGroups = [...inputDeviceGroups];
      inputDeviceGroups.sort();
      return inputDeviceGroups;
    },

    currentSharedPermissions() {
      let currentPermissions = [];

      // parses tags for edit/view permissions
      this.activeProject.Properties.Tags.forEach((tag) => {
        let modifiedTag = tag.split(":");

        if (
          modifiedTag.includes("EDIT") &&
          !modifiedTag.includes(this.$store.state.account.user.attributes.email)
        ) {
          currentPermissions.push({
            user: modifiedTag[1],
            edit: true,
            view: true,
          });
        }
        if (
          modifiedTag.includes("VIEW") &&
          !modifiedTag.includes(this.$store.state.account.user.attributes.email)
        ) {
          currentPermissions.push({
            user: modifiedTag[1],
            edit: false,
            view: true,
          });
        }
      });

      // merge user permissions
      let mergeUserPermissions = currentPermissions.reduce(
        (a, { user, edit, view }) => {
          let permission = a.find((perm) => perm.user === user);

          if (!permission) {
            return [...a, { user, edit: edit, view: view }];
          }
          permission.edit = permission.edit ? permission.edit : edit;
          return a;
        },
        []
      );

      return mergeUserPermissions;
    },
  },
  methods: {
    initializeProjectSettings() {
      this.settings = this.activeProject.Settings;
    },
    onDescriptionUpdate() {
      this.changedSettings.add("Description");
      this.debouncedAutosave(this, this.settings, this.changedSettings);
    },
    onProjectImageUpdate(newImage) {
      this.settings.ProjectImage = newImage;
      this.editProjectImage = false;
      this.changedSettings.add("ProjectImage");
      this.debouncedAutosave(this, this.settings, this.changedSettings);
    },
    onPixelSizeXUpdate() {
      if (this.validateCellSize(this.settings.CellSizeX)) {
        this.changedSettings.add("CellSizeX");
        this.debouncedAutosave(this, this.settings, this.changedSettings);
      }
    },
    onPixelSizeYUpdate() {
      if (this.validateCellSize(this.settings.CellSizeY)) {
        this.changedSettings.add("CellSizeY");
        this.debouncedAutosave(this, this.settings, this.changedSettings);
      }
    },
    onHiddenInputDeviceGroupsUpdate() {
      this.changedSettings.add("HiddenInputDeviceGroups");
      this.debouncedAutosave(this, this.settings, this.changedSettings);
    },
    onStudyAreaUpdate(newStudyArea) {
      this.settings.StudyAreaName = newStudyArea.studyAreaName;
      this.editStudyArea = false;
      this.changedSettings.add("StudyAreaName");
      this.debouncedAutosave(this, this.settings, this.changedSettings);
    },
    onCRSUpdate(newCRS) {
      this.settings.CRS = newCRS;
      this.changedSettings.add("CRS");
      this.debouncedAutosave(this, this.settings, this.changedSettings);
    },
    validateCellSize(value) {
      let isValid =
        (!isNaN(value) && !isNaN(parseFloat(value))) || value === "auto";
      if (isValid) {
        return true;
      } else {
        return "Must be a number or 'auto'";
      }
    },
    onStudyAreaDatasetUpdate(dataset) {
      this.selectedStudyArea = dataset;
    },
    onStudyAreaNameUpdate(newValue) {
      let layerNames;
      // null
      if (!newValue) layerNames = newValue;
      // multiple
      else if (newValue instanceof Array) {
        layerNames = newValue.map((layer) => layer.LayerName);
        // single from array
        if (layerNames.length === 1) layerNames = layerNames[0];
      }
      // single
      else layerNames = newValue.LayerName;

      this.selectedLayer = layerNames;
    },
    closeShareDialog() {
      this.dialogShare = false;
    },
    confirmStudyAreaDialog() {
      this.settings.StudyAreaDatasetName = this.selectedStudyArea.DatasetName;
      this.changedSettings.add("StudyAreaDatasetName");
      this.settings.StudyAreaName = this.selectedLayer;
      this.changedSettings.add("StudyAreaName");

      this.debouncedAutosave(this, this.settings, this.changedSettings);

      this.editStudyArea = false;
    },
    cancelStudyAreaDialog() {
      this.selectedStudyArea = null;
      this.selectedLayer = null;
      this.editStudyArea = false;
    },
    doDeleteProject() {
      const projectOwner = this.activeProject.Properties.ProjectOwner;
      const projectName = this.activeProject.Properties.ProjectName;
      this.$showAlert({
        text: `Are you sure you want to delete '${projectName}'?`,
        type: "warning",
        choice: true,
      }).then((confirmed) => {
        if (confirmed) {
          this.$showLoadingOverlay({
            title: "Deleting, please wait.",
            updateMessage: `Deleting <strong>${projectName}<strong>`,
          });
          deleteProject(projectName, projectOwner)
            .then((response) => {
              if (response.Warnings) {
                this.$showAlert({ text: response.Warnings, type: "warning" });
              }
              this.$nextTick(() => {
                this.$router.push("project-dashboard");
              });
            })
            .catch((error) => {
              let errorMessage = error;
              if (error.response) {
                errorMessage = error.response.data.Errors;
              }
              this.$showAlert({ text: errorMessage, type: "error" });
            })
            .finally(() => {
              this.$nextTick(() => {
                this.$showLoadingOverlay();

                // re-route to project-dashboard anyways if there was a 502 network error, project may have deleted.
                this.$router.push("project-dashboard");
              });
            });
        }
      });
    },
    doCopyProject() {
      const projectName = this.activeProject.Properties.ProjectName;
      copyProject(
        projectName,
        this.activeProject.Properties.ProjectOwner,
        this.activeProject.Properties.CreatedFromProjectName,
        this.activeProject.Properties.CreatedFromProjectOwner
      ).then((response) => {
        if (response.Errors) {
          this.$showAlert({ text: response.Errors, type: "error" });
        }
        if (response.Warnings) {
          this.$showAlert({ text: response.Warnings, type: "warning" });
        }
      });
    },

    formatLayerName(names) {
      if (Array.isArray(names)) {
        if (names.length < 10) {
          const nameString = names.map((name) => `${name}`).join(", ");
          return nameString;
        } else if (names.length === 1) {
          return names;
        } else {
          const limitedNames = names.slice(0, 9);
          const nameString = `${limitedNames[0]} +${names.length - 1}others`;
          return nameString;
        }
      } else {
        return names;
      }
    },
  },
  beforeMount() {
    this.debouncedAutosave = debounce(function (
      self,
      settings,
      changedSettings
    ) {
      const newSettings = Array.from(changedSettings).reduce(
        (obj, key) => ((obj[key] = settings[key]), obj),
        {}
      );
      self.changedSettings = new Set();
      const projectOwner =
        self.$store.state.project.activeProject.Properties.ProjectOwner;
      const projectName =
        self.$store.state.project.activeProject.Properties.ProjectName;
      updateProjectSettings(projectName, projectOwner, newSettings).then(
        (response) => {
          if (response.Errors) {
            self.$showAlert({ text: response.Errors, type: "error" });
          }
          if (response.Warnings) {
            self.$showAlert({ text: response.Warnings, type: "warning" });
          }
          self.$store.dispatch("project/loadProject", {
            projectOwner,
            projectName,
          });
        }
      );
    },
    3000);
    this.initializeProjectSettings();
  },
};
</script>

<style scoped>
.image-selector-thumb {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  height: 200px;
}

.userPermission {
  display: block;
  flex: none;
}

#project-settings-container {
  max-width: 1200px; /* 400 for nav drawer + 800 not-a-drawer */
  margin: initial;
}

.selector-overlay-card-content {
  overflow-x: hidden;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .image-selector-thumb {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 500px;
    height: 200px;
  }
  /* #project-settings-container {
    max-width: 1600px;
    margin: auto;
  } */
}
</style>

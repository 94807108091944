import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

import "@aws-amplify/ui-vue";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import { Hub } from "aws-amplify";

Amplify.configure(awsconfig);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

Vue.prototype.$showAlert = (alert) => {
  store.commit("alert/showAlert", alert);
  if (alert.choice) {
    return new Promise((resolve) => {
      const waiter = () => {
        if (alert.confirmed === true) {
          store.commit("alert/showAlert", null);
          resolve(true);
        } else if (alert.confirmed === false) {
          store.commit("alert/showAlert", null);
          resolve(false);
        } else setTimeout(waiter, 100);
      };
      waiter();
    });
  } else if (alert.timeout) {
    setTimeout(() => {
      store.commit("alert/showAlert", null);
    }, alert.timeout);
  }
};

Vue.prototype.$showLoadingOverlay = (loaderDetails) => {
  store.commit("loadingOverlay/showLoadingOverlay", loaderDetails);
};

// listen for authentication changes and re-direct as needed
Hub.listen("auth", (data) => {
  switch (data.payload.event) {
    case "signIn":
      router.push({ path: "/" });
      break;
    case "signOut":
      // clears sessionStorage accountData before log out
      store.commit("accountData/resetAccountData");
      router.push({ name: "Login" });
      // reload page to clear vuex store
      location.reload();
      break;
  }
});

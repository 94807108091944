<template>

  <v-text-field
    v-model="internalValue"
    @change="roundDateToPrecision"
    :rules="[checkDateFormat]"
    :color="$store.state.settings.appColor"
    :label="label"
    :hint="hint"
    :placeholder="placeholder"
    :disabled="disabled"
    outlined
    dense
  >

  </v-text-field>

</template>

<script>
export default {
  name: "DateField",
  props: {
    value: null,
    precision: String,
    label: String,
    disabled: Boolean,
  },
  data() {
    return {
      internalValue: this.value, // if this.value is updated, update internalValue
    };
  },
  computed: {
    placeholder() {
      switch (this.precision) {
        case "Decade":
        case "Decadally":
          return "YYY0";
        case "Year":
        case "Annual":
        case "Annually":
          return "YYYY";
        case "Month":
        case "Monthly":
          return "YYYY-MM";
        case "Day":
        case "Daily":
          return "YYYY-MM-DD";
        default:
          return "Internal error";
      }
    },
    hint() {
      return `Format: ${this.placeholder}`;
    },
  },
  watch: {
    precision(newValue) {
      this.internalValue = new Date().toISOString().slice(0, 10); // reset date
      if (newValue == "Decadally") {
        this.internalValue =
          this.internalValue.substring(0, 3) +
          "0" +
          this.internalValue.substring(4);
      }
      this.roundDateToPrecision();
    },
    value(newValue) {
      this.internalValue = newValue;
    },
  },
  methods: {
    roundDateToPrecision() {
      let dateRegex = null;
      switch (this.precision) {
        case "Decade":
        case "Decadally":
          dateRegex = /^(\d{3}0)(-\d{2}(-\d{2})?)?$/;
          break;
        case "Year":
        case "Annual":
        case "Annually":
          dateRegex = /^(\d{4})(-\d{2}(-\d{2})?)?$/;
          break;
        case "Month":
        case "Monthly":
          dateRegex = /^(\d{4}-\d{2})(-(\d{2}))?$/;
          break;
        case "Day":
        case "Daily":
          dateRegex = /^(\d{4}-\d{2}-\d{2})?$/;
          break;
      }
      let matches = this.internalValue.match(dateRegex);
      if (
        matches != null &&
        matches.length >= 2 &&
        this.checkDateFormat(this.internalValue) === true
      ) {
        this.internalValue = matches[1];
        this.$emit("input", this.internalValue);
      }
    },
    checkDateFormat(dateString) {
      let dateRegex = null;
      let hint = null;
      switch (this.precision) {
        case "Decade":
        case "Decadally":
          dateRegex = /^(\d{3}0)(-\d{2}(-\d{2})?)?$/;
          hint =
            "Invalid date. Expected format is YYY0 (e.g., '2020' or '2080').";
          break;

        case "Year":
        case "Annual":
        case "Annually":
          dateRegex = /^(\d{4})(-\d{2}(-\d{2})?)?$/;
          hint =
            "Invalid date. Expected format is YYYY (e.g., '2018' or '2072').";
          break;
        case "Month":
        case "Monthly":
          dateRegex = /^(\d{4}-\d{2})(-(\d{2}))?$/;
          hint =
            "Invalid date. Expected format is YYYY-MM (e.g., '2018-03' or '2072-09').";
          break;
        case "Day":
        case "Daily":
          dateRegex = /^(\d{4}-\d{2}-\d{2})?$/;
          hint =
            "Invalid date. Expected format is YYYY-MM-DD (e.g., '2018-03-01' or '2072-09-01').";
          break;
      }
      if (dateRegex.test(dateString) && !isNaN(Date.parse(dateString))) {
        return true;
      } else {
        return hint;
      }
    },
  },
};
</script>

<style scoped>

</style>


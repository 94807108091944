<template>
  <div>
    <v-menu v-model="symbologyMenu" :close-on-content-click="false" offset-y>
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn v-bind="attrs" v-on="{ ...tooltip, ...menu }" icon small>
              <v-icon>mdi-palette</v-icon>
            </v-btn>
          </template>
          Change symbology
        </v-tooltip>
      </template>

      <v-card class="pa-4" width="500px">
        <!-- quick menu -->
        <v-row
          class="py-2 align-center justify-space-between"
          v-if="showQuickMenuColor"
        >
          <v-col cols="4">
            <v-subheader class="property-titles text-caption text-color-black">
              Colour
            </v-subheader>
          </v-col>

          <v-col
            cols="8"
            v-if="
              flowLayer.dataset.DatasetType === 'Raster' &&
              selectedDisplayType === 'quantitative'
            "
          >
            <v-select
              v-model="displayParams.colormap_name"
              @change="updateRasterColor"
              dense
              :items="linearColorRamps"
              item-disabled="disabled"
              item-text="text"
              item-value="cmap"
              label="Select"
              hide-hint
              hide-details
              single-line
              :color="$store.state.settings.appColor"
            ></v-select>
          </v-col>

          <v-col
            class="mx-auto"
            cols="3"
            v-if="
              flowLayer.dataset.DatasetType === 'Vector' &&
              selectedDisplayType === 'mask'
            "
          >
            <the-color-picker
              :color="formatRGBColorString(inputColormap[layerName].Color)"
              @updated-color="updateMaskColor($event)"
              :single="true"
            ></the-color-picker>
          </v-col>
        </v-row>

        <v-row class="py-2 align-center justify-space-between">
          <v-col cols="3">
            <v-subheader
              class="d-flex property-titles text-caption text-color-black"
            >
              Opacity
            </v-subheader>
          </v-col>

          <v-col cols="8">
            <v-slider
              v-model="opacitySliderValue"
              hide-hint
              hide-details
              @input="layerOpacity(opacitySliderValue)"
              min="0"
              max="100"
              dense
              :color="$store.state.settings.appColor"
            >
              <template v-slot:append>
                <v-text-field
                  class="opacity-value mt-0 pt-0"
                  style="width: 60px"
                  type="number"
                  suffix="%"
                  v-model="opacitySliderValue"
                  @input="layerOpacity(opacitySliderValue)"
                  min="0"
                  max="100"
                  dense
                  :color="$store.state.settings.appColor"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                ></v-text-field>
              </template>
            </v-slider>
          </v-col>
        </v-row>

        <v-divider class="py-2"></v-divider>

        <!-- advanced menu -->
        <v-row
          v-if="flowLayer.layer.TypeOfData !== 'Mask' && allowAdvancedSymbology"
        >
          <v-spacer></v-spacer>
          <v-dialog
            scrollable
            max-width="700px"
            v-model="advancedMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
            hide-overlay
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                class="text-caption text-capitalize"
                small
                text
                v-bind="attrs"
                v-on="on"
                @click="lastAppliedColormap = inputColormap"
                >Show Advanced Properties</v-btn
              >
            </template>

            <v-card class="advanced-menu d-flex flex-column pa-4">
              <v-row class="pb-2 flex-grow-0">
                <v-col cols="4">
                  <v-subheader
                    class="d-flex property-titles text-caption text-color-black"
                  >
                    Display Type
                  </v-subheader>
                </v-col>

                <v-col cols="8">
                  <v-subheader class="pl-0 text-capitalize">{{
                    selectedDisplayType
                  }}</v-subheader>
                </v-col>
              </v-row>

              <v-divider class="py-1"></v-divider>

              <v-card-text>
                <v-row
                  v-if="
                    flowLayer.dataset.DatasetType === 'Vector' ||
                    (flowLayer.dataset.DatasetType === 'Raster' &&
                      selectedDisplayType === 'categorical-discrete')
                  "
                  no-gutters
                  class="d-flex align-center flex-grow-0 mt-1 mb-2"
                >
                  <div class="d-flex justify-start flex-wrap">
                    <div class="pa-1">
                      <v-btn
                        :loading="classifying"
                        x-small
                        @click="
                          setDisplay(
                            flowLayer.dataset.DatasetType,
                            selectedDisplayType
                          )
                        "
                      >
                        Classify</v-btn
                      >
                    </div>

                    <div class="pa-1">
                      <v-btn x-small @click="removeAllCategories">
                        Clear All
                      </v-btn>
                    </div>
                  </div>

                  <v-spacer></v-spacer>

                  <div
                    class="d-flex"
                    v-if="
                      flowLayer.dataset.DatasetType === 'Vector' &&
                      selectedDisplayType === 'quantitative'
                    "
                  >
                    <v-subheader
                      class="d-flex property-titles pl-0 text-caption text-color-black"
                    >
                      Classes
                    </v-subheader>
                    <v-text-field
                      dense
                      outlined
                      type="number"
                      v-model="inputIntervalClasses"
                      @change="classifyByInterval"
                    ></v-text-field>
                  </div>
                </v-row>

                <!-- Raster Quantitative-->
                <div
                  v-if="
                    flowLayer.dataset.DatasetType === 'Raster' &&
                    selectedDisplayType === 'quantitative'
                  "
                >
                  <v-row class="py-2">
                    <v-col cols="4">
                      <v-subheader
                        class="property-titles text-caption text-color-black"
                      >
                        Colour
                      </v-subheader>
                    </v-col>

                    <v-col cols="8">
                      <v-select
                        v-model="displayParams.colormap_name"
                        dense
                        :items="linearColorRamps"
                        item-disabled="disabled"
                        item-text="text"
                        item-value="cmap"
                        label="Select"
                        hide-hint
                        hide-details
                        single-line
                        :color="$store.state.settings.appColor"
                      ></v-select>
                    </v-col>
                  </v-row>
                </div>

                <!-- Vector Quantitative-->
                <div
                  v-if="
                    flowLayer.dataset.DatasetType === 'Vector' &&
                    selectedDisplayType === 'quantitative'
                  "
                >
                  <div class="categories-container">
                    <div>
                      <symbology-category-list
                        v-if="advancedMenu"
                        :colormap="inputColormap"
                        :new-classified-list="newClassifiedList"
                        :mode="'Intervals'"
                        @category-list-valid="isCategoryListValid"
                        @colormap-updated="updateColormap"
                      ></symbology-category-list>
                    </div>
                  </div>
                </div>

                <!--TODO:  Categorical Interval -->
                <!-- <div v-if="selectedDisplayType === 'categorical-interval'">
                  <div class="categories-container">
                    <transition-group name="fade">
                      <v-row
                        class="rescale-values d-flex px-3 align-center justify-space-between"
                        v-for="(interval, index) in inputColormap"
                        :key="interval.key"
                      >
                        <v-col cols="4" align-self="end" class="d-flex">
                          <v-text-field
                            v-model="interval.min"
                            label="Min"
                            hide-hint
                            hide-details
                          ></v-text-field>
                          <v-text-field
                            v-model="interval.max"
                            label="Max"
                            hide-hint
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <v-col cols="2" class="d-flex flex-column align-center">
                          <the-color-picker
                            :category="interval"
                          ></the-color-picker>
                        </v-col>
                        <v-col cols="4" align-self="end">
                          <v-text-field
                            v-model="interval.label"
                            type="text"
                            class="px-2"
                            label="Label"
                            hide-hint
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="1">
                          <v-icon @click="removeCategory(index)" color="error"
                            >mdi-close</v-icon
                          >
                        </v-col>
                      </v-row>
                    </transition-group>
                  </div>
                </div> -->

                <!-- Discrete Categorical -->
                <div
                  v-if="
                    flowLayer.dataset.DatasetType === 'Raster' &&
                    selectedDisplayType === 'categorical-discrete'
                  "
                >
                  <div class="categories-container">
                    <div>
                      <symbology-category-list
                        v-if="advancedMenu"
                        :colormap="inputColormap"
                        :new-classified-list="newClassifiedList"
                        mode="Categories"
                        @category-list-valid="isCategoryListValid"
                        @colormap-updated="updateColormap"
                      ></symbology-category-list>
                    </div>
                  </div>
                </div>

                <!-- rescale and nodata inputs -->
                <div
                  class="pt-6"
                  v-if="flowLayer.dataset.DatasetType === 'Raster'"
                >
                  <p class="d-block text-caption">
                    The rescale and nodata values can be modified to help
                    troubleshoot display issues between raster data types.
                    Generally, these do not need to be adjusted.
                    <!-- An example is a negative nodata value which appears to be a
                    unique and valid number, however needs to be removed from
                    the min/max . -->
                  </p>

                  <v-row class="pb-2">
                    <v-col cols="4">
                      <v-subheader
                        class="d-flex property-titles text-caption text-color-black"
                      >
                        Rescale
                      </v-subheader>
                    </v-col>
                    <v-col cols="8" class="rescale-values d-flex px-3">
                      <v-text-field
                        disabled
                        type="number"
                        class="px-2"
                        label="Min"
                        v-model="inputMin"
                      ></v-text-field>
                      <v-text-field
                        disabled
                        type="number"
                        class="px-2"
                        label="Max"
                        v-model="inputMax"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="pb-2">
                    <v-col cols="4">
                      <v-subheader
                        class="d-flex property-titles text-caption text-color-black"
                      >
                        Nodata Value
                      </v-subheader>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        disabled
                        type="number"
                        persistent-hint
                        v-model="displayParams.nodata"
                        hint="Override the internal nodata value"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>

              <v-divider class="py-2"></v-divider>
              <v-card-actions>
                <v-row>
                  <div class="align-center saved-styles">
                    <v-select
                      v-model="selectedSavedSymbology"
                      placeholder="Dataset Symbology"
                      :items="savedDatasetSymbology"
                      item-text="Name"
                      return-object
                      prepend-icon="mdi-palette"
                      outlined
                      dense
                      :menu-props="{ top: true, offsetY: true }"
                      hide-details
                      :color="$store.state.settings.appColor"
                      @change="setFromFlowCategoryStyle"
                    >
                      <!-- <template v-slot:selection="{ item }">
                        <span class="text-truncate">
                          {{ item.name }}
                          <span class="text-caption font-italic"
                            >{{
                              item.default === true
                                ? "&nbsp;" + "- Default"
                                : ""
                            }}
                          </span>
                        </span>
                      </template>
                      <template v-slot:item="{ item, on, attrs }">
                        <template>
                          <v-list-item v-bind="attrs" v-on="on">
                            <v-list-item-content>
                              <v-list-item-title class="d-flex justify-center">
                                {{ item.name }}
                                <span class="text-caption font-italic"
                                  >{{
                                    item.default === true
                                      ? "&nbsp;" + "- Default"
                                      : ""
                                  }}
                                </span></v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </template> -->
                    </v-select>
                  </div>
                  <div class="pl-2 align-self-center">
                    <map-layer-save-symbology
                      v-if="allowSaving"
                      :dataset="flowLayer"
                      :new-symbology="saveableSymbology"
                      :enabled="!applyDisabled"
                      @get-symbology="formatSaveableSymbology"
                    >
                    </map-layer-save-symbology>
                  </div>
                  <v-spacer></v-spacer>
                  <div class="d-flex align-center">
                    <v-btn color="warning" text @click="cancelAdvancedMenu"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="success"
                      text
                      @click="
                        flowLayer.dataset.DatasetType === 'Raster'
                          ? updateRasterColor()
                          : updateVectorColor()
                      "
                      :disabled="applyDisabled"
                    >
                      Apply
                    </v-btn>
                  </div>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { getCogStatistics, buildTileJsonURL } from "@/api/titiler";
import {
  flowSymbologyToTiTiler,
  newCategoryClass,
  createIntervals,
  formatFlowSymbology,
  formatEditableSymbology,
  formatSaveableCategoryObject,
  formatCSSColor,
  formatRGBColorString,
  buildMapboxExpression,
  check8BitRange,
} from "@/api/mapping.js";
import SymbologyCategoryList from "@/components/mapping/SymbologyCategoryList.vue";
import MapLayerSaveSymbology from "@/components/mapping/MapLayerSaveSymbology.vue";
import TheColorPicker from "@/components/mapping/TheColorPicker.vue";

export default {
  name: "MapLayerSymbology",

  components: {
    SymbologyCategoryList,
    MapLayerSaveSymbology,
    TheColorPicker,
  },

  props: {
    flowLayer: { type: Object, required: true },
    editPermissions: { type: Boolean, required: true },
    allowAdvancedSymbology: { type: Boolean, default: true },
  },

  data() {
    return {
      datasetUniqueValues: [],
      internalColormap: null,
      lastAppliedColormap: {},
      inputColormap: {},
      classifying: false,
      newClassifiedList: [],
      symbologyMenu: false,
      advancedMenu: false,
      categoryListValid: true,
      saveSymbologyMenu: false,
      saveableSymbology: {},
      selectedSavedSymbology: null,
      opacitySlider: true,
      opacitySliderValue: 100,
      displayTypes: [
        "quantitative",
        "categorical-discrete",
        "categorical-interval",
        "mask",
      ],
      selectedDisplayType: null,
      allFilesMin: null,
      allFilesMax: null,
      inputMin: null,
      inputMax: null,
      inputIntervalClasses: 10,
      displayParams: {
        nodata: null,
        colormap_name: null,
        colormap: null,
      },

      linearColorRamps: [
        { cmap: null, text: "Custom", disabled: true },
        { cmap: "gist_gray", text: "Internal" },
        { cmap: "hsv", text: "HSV" },
        { cmap: "cividis", text: "Cividis" },
        { cmap: "viridis", text: "Viridis" },
        { cmap: "RdYlGn", text: "Red-Yellow-Green" },
        { cmap: "RdYlGn_r", text: "Green-Yellow-Red" },
        { cmap: "cool", text: "Cool" },
        { cmap: "rainbow", text: "Rainbow" },
        { cmap: "magma", text: "Magma" },
        { cmap: "gist_earth", text: "Earth" },
        { cmap: "ocean", text: "Ocean" },
        { cmap: "terrain", text: "Terrain" },
        { cmap: "seismic", text: "Seismic" },
      ],
    };
  },

  computed: {
    savedDatasetSymbology() {
      const allSymbology = Object.keys(this.flowLayer.dataset.Symbology).map(
        (key) => this.flowLayer.dataset.Symbology[key]
      );

      return allSymbology.filter(
        (symbology) => symbology.Type === this.selectedDisplayType
      );
    },

    applyDisabled() {
      if (
        !this.categoryListValid ||
        Object.keys(this.inputColormap).length === 0
      ) {
        return true;
      } else {
        return false;
      }
    },

    showQuickMenuColor() {
      let show = false;
      if (
        this.selectedDisplayType &&
        this.flowLayer.dataset.DatasetType === "Raster" &&
        this.selectedDisplayType === "quantitative"
      ) {
        show = true;
      } else if (
        this.selectedDisplayType &&
        this.flowLayer.dataset.DatasetType === "Vector" &&
        this.selectedDisplayType === "mask"
      ) {
        show = true;
      }

      return show;
    },

    allowSaving() {
      let allow = false;

      if (this.editPermissions) {
        allow = true;
      }

      if (
        this.flowLayer.dataset.DatasetType === "Raster" &&
        this.selectedDisplayType === "quantitative"
      ) {
        allow = false;
      }

      return allow;
    },

    layerName() {
      return this.flowLayer.layer.LayerName;
    },
  },

  watch: {
    "displayParams.colormap"(customColormap) {
      if (customColormap) this.displayParams.colormap_name = "Custom";
    },
  },

  methods: {
    // Updates value for opacity and commits opacity property information to store
    layerOpacity(opacitySliderValue) {
      this.$store.commit("mapping/updateLayerOpacity", {
        key: this.flowLayer.key,
        opacityValue: opacitySliderValue,
      });
    },

    setStatsRanges() {
      // display min and max across all files for viewing only
      this.allFilesMin = this.flowLayer.files[0].datasetsMinMax.minVal;
      this.allFilesMax = this.flowLayer.files[0].datasetsMinMax.maxVal;

      // display min and max across all files and can be modified by user
      this.inputMin = this.flowLayer.files[0].datasetsMinMax.minVal;
      this.inputMax = this.flowLayer.files[0].datasetsMinMax.maxVal;
    },

    cancelAdvancedMenu() {
      this.advancedMenu = false;
      this.inputColormap = this.lastAppliedColormap;
    },

    setDefaultDisplay() {
      this.selectedDisplayType = this.flowLayer.files[0].colormap.displayType;
    },

    // TODO: set from type
    setFromInternal(internalColormap) {
      this.selectedDisplayType = internalColormap.displayType;
    },

    isCategoryListValid(validity) {
      this.categoryListValid = validity;
    },

    updateColormap(updatedColormap) {
      // reformat colormap into saveable format
      this.inputColormap = formatFlowSymbology(updatedColormap);
    },

    initializeInternalStyleProperty() {
      // create array of classes from all files
      if (this.flowLayer.files[0].colormap.internalColormap) {
        const colormaps = this.flowLayer.files.map(
          (file) => file.colormap.internalColormap
        );

        const uniqueClasses = [
          ...new Map(
            colormaps.map((category) => [category["values"], category])
          ).values(),
        ];

        this.internalColormap = {
          displayType: this.flowLayer.files[0].colormap.displayType,
          style: uniqueClasses.flat(),
        };
      } else {
        this.internalColormap = null;
      }
    },

    setInitialOpacityValue(mapLayer) {
      const fillType =
        this.flowLayer.dataset.DatasetType === "Raster" ? "raster" : "fill";

      const opacitySliderValue = mapLayer.paint[`${fillType}-opacity`] * 100;

      return opacitySliderValue;
    },

    setFromFlowCategoryStyle(flowCategoryStyle) {
      const flowSymbology = structuredClone(flowCategoryStyle);
      this.selectedDisplayType = flowSymbology.Type;
      this.selectedSavedSymbology = flowSymbology;
      this.inputColormap = flowSymbology.Style.classes;
      this.inputIntervalClasses = Object.keys(
        flowSymbology.Style.classes
      ).length;
      this.newClassifiedList = formatEditableSymbology(
        flowSymbology.Style.classes
      );
      this.opacitySliderValue = this.setInitialOpacityValue(
        this.flowLayer.files[0].mapLayer
      );
    },

    setFromLegendSymbology(symbology) {
      const legendSymbology = structuredClone(symbology);
      this.selectedDisplayType = legendSymbology.displayType;
      this.inputColormap = legendSymbology.legendColormap;
      this.inputIntervalClasses = Object.keys(
        legendSymbology.legendColormap
      ).length;
      this.opacitySliderValue = this.setInitialOpacityValue(
        this.flowLayer.files[0].mapLayer
      );
    },

    setDisplay(typeOfData, displayType) {
      if (typeOfData === "Raster") {
        switch (displayType) {
          // TODO: disabled for now
          // case "Discrete (Interval)":
          //   this.classifyByInterval(this.inputIntervalClasses);
          //   break;

          case "categorical-discrete":
            this.classifyByUnique();

            break;

          default:
            break;
        }
      } else if (typeOfData === "Vector") {
        switch (displayType) {
          case "quantitative":
            this.classifyByInterval(this.inputIntervalClasses);
            break;

          // case "Categorical":
          //   // TODO: disabled for now
          //   this.classifyByUnique();
          //   break;

          default:
            break;
        }
      }
    },

    formatRGBColorString,

    formatSaveableSymbology() {
      const symbologyTemplate = {
        DatasetName: this.flowLayer.layer.DatasetName,
        DatasetOwner: this.flowLayer.layer.DatasetOwner,
        Name: this.flowLayer.layer.SymbologyName
          ? this.flowLayer.layer.SymbologyName
          : "",
        Type: this.selectedDisplayType,
        Style: {
          base: 1,
          cmap: this.displayParams.colormap_name,
          classes: this.inputColormap,
          valueField: this.flowLayer.layer.ValueField,
        },
        Categories: formatSaveableCategoryObject(this.inputColormap),
      };

      this.saveableSymbology = symbologyTemplate;

      return symbologyTemplate;
    },

    async classifyByUnique() {
      this.classifying = true;
      let categories = [];
      let uniqueValues = [];

      await Promise.all(
        this.flowLayer.files.map(async (file) => {
          const params = {
            url: file.baseURL,
            bidx: file.colormap.displayParams.bidx,
            categorical: true,
            // histogram_bins: this.inputIntervalClasses,
          };

          if (this.displayParams.nodata) {
            params.nodata = this.displayParams.nodata;
          }

          const statistics = await getCogStatistics(params);

          statistics["1"].histogram[1].forEach((value) =>
            uniqueValues.push(value)
          );
        })
      );
      this.datasetUniqueValues = [...new Set(uniqueValues)];

      this.datasetUniqueValues.sort((a, b) => a - b);

      // from histogram
      this.datasetUniqueValues.forEach((uniqueValue) => {
        const newCategory = newCategoryClass(String(uniqueValue));

        // max 100 categories
        if (categories.length < 100) {
          categories.push(newCategory);
        }
      });

      // TODO: fix merging internal colormap with unique value classification
      // try to fill in the blanks from the internal colortable
      // if (this.internalColormap) {
      //   // const categoriesMapped = new Map(
      //   //   this.internalColormap.style.map((cat) => [cat.value, cat])
      //   // );
      //   // const categoriesMapped = new Map(
      //   //   Object.keys(this.internalColormap.style).map((cat) => ({
      //   //     Label: key,
      //   //     ...this.internalColormap.style[key],
      //   //   }))
      //   // );

      //   console.log(this.internalColormap);

      //   const categoriesMapped = new Map(
      //     Object.keys(this.internalColormap.style).map((c) => [c.Values, c])
      //   );

      //   console.log(categoriesMapped);

      //   const matchedCategories = categories.map((cat) => ({
      //     ...cat,
      //     ...categoriesMapped.get(cat.Values),
      //   }));

      //   categories = matchedCategories;
      //   console.log(matchedCategories);
      // }

      this.newClassifiedList = categories;
      this.classifying = false;
    },

    classifyByInterval() {
      const classes = this.inputIntervalClasses;
      let min = parseFloat(this.allFilesMin);
      let max = parseFloat(this.allFilesMax);
      const type = this.flowLayer.dataset.DatasetType;

      const intervals = createIntervals(
        classes,
        min,
        max,
        [
          '{"r":241,"g":238,"b":246,"a":1}',
          '{"r":189,"g":201,"b":225,"a":1}',
          '{"r":116,"g":169,"b":207,"a":1}',
          '{"r":43,"g":140,"b":190,"a":1}',
          '{"r":4,"g":90,"b":141,"a":1}',
        ],
        type
      );

      if (intervals) {
        this.newClassifiedList = intervals;
      }
    },

    removeCategory(index) {
      this.inputColormap.splice(index, 1);
    },

    removeAllCategories() {
      this.newClassifiedList = [];
    },

    updateMaskColor(newColor) {
      // update local colormap
      this.inputColormap[this.layerName].Color = JSON.stringify(newColor);

      // update files colormaps and mapLayer
      {
        this.flowLayer.files.forEach((file) => {
          file.mapLayer.paint["fill-color"] = formatCSSColor(newColor);

          file.colormap = {
            legendColormap: this.inputColormap,
            flowCategoryStyle: null,
          };
        });
      }

      this.updateVectorColor();
    },

    updateRasterColor(value) {
      // delay map change requests until clicks "Apply" when defining a custom color
      if (value !== "Custom") {

        this.flowLayer.files.forEach((file) => {
          // common params
          const params = {
            url: file.baseURL,
            bidx: 1,
            maxzoom: 20,
            format: "png",
          };

          // dtype specific params
          const dtype = file.extentLayer.source.properties.dtype;

          // LINEAR
          // or continuous (with 256 values from 0 to 255)
          // all data types
          // requires min max values for rescaling if not int8 (uses min,max by default)
          // can use predefined colormaps only
          // can use internal or user nodata value
          // alpha value can be internal or user set

          if (this.selectedDisplayType === "quantitative") {
            if (this.displayParams.colormap_name !== "gist_gray") {
              // set a linear colormap, don't define property if gist_gray
              params.colormap_name = this.displayParams.colormap_name;
            }

            // must rescale values to 0-255 using min and max for any dtype != byte if linear ramps to be used.
            if (["uint8", "int8"].indexOf(dtype) === -1) {
              // must rescale to 0,255 if data min max is within 8bit range but dtype differs.
              params.rescale = `${this.inputMin},${this.inputMax}`;
            }

            // can override no data value if needed
            if (this.displayParams.nodata) {
              params.nodata = this.displayParams.nodata;
            }
          }

          // DISCRETE (Categorical)
          // requires user defined colormap setting classes
          // can have sparse values
          // class = {"value": [r,g,b,a]}
          // classes ex. = { "0": [123,50,148], "73": [194,165,207], "125": [247,247,247], "212": [166,219,160], "255": [0,136,55] }
          // custom colormap sets the break points
          // alpha (nodata) value must be set 0 or 255

          if (this.selectedDisplayType === "categorical-discrete") {
            // if min max within byte range and bit depth is greater than 8, rescale values must be 0,255

            if (check8BitRange(dtype, this.inputMin, this.inputMax)) {
              params.rescale = `0,255`;
            } else {
              // params.rescale = `${this.inputMin},${this.inputMax}`;
              delete params.rescale;
            }

            // can override no data value if wanted
            if (this.displayParams.nodata) {
              params.nodata = this.displayParams.nodata;
            }

            this.displayParams.colormap = JSON.stringify(
              flowSymbologyToTiTiler(this.inputColormap)
            );

            params.colormap = this.displayParams.colormap;

            file.colormap.legendColormap = this.inputColormap;
          }

          // INTERVAL
          // interval
          // all data types
          // requires user defined colormap setting interval range classes
          // alpha value should be either 0 or 255
          // max of previous class should equal min of next class
          // class = [[range.min, range.max], [r,g,b,a]]
          // class ex. = [[[-1.0,-0.2],[0,0,0,1]]]
          // float classes ex. = [[[-1.0,-0.2],[0,0,0,1]],[[-0.2,0],[165,0,38,1]],[[0,0.1],[215,48,39,1]],[[0.1,0.2],[244,109,67,1]],[[0.2,0.3],[253,174,97,1]]]
          // float classes ex. = [[[25,100],[0,0,0,1]],[[100,110],[165,0,38,1]],[[110, 120],[215,48,39,1]]]

          // TODO: categorical interval not being used right now
          // if (
          //   this.selectedDisplayType.label === "Categorical"
          // ) {
          //   // can override no data value if wanted
          //   if (this.displayParams.nodata) {
          //     params.nodata = this.displayParams.nodata;
          //   }
          //   this.displayParams.colormap = JSON.stringify(this.tiTilerColorMap);

          //   params.colormap = this.displayParams.colormap;
          //   params.return_mask = true;
          // }


          const url = buildTileJsonURL(params);

          file.mapLayer.source.url = url;

          // store current colormap information for legend entry generation
          file.colormap = {
            ...file.colormap,
            displayType: this.selectedDisplayType,
            inputMin: this.inputMin,
            inputMax: this.inputMax,
            displayParams: params,
            internalColormap: this.internalColormap,
            flowCategoryStyle: this.flowCategoryStyle,
          };
        });

        // update dataset state
        this.$store.commit("mapping/updateLayerSymbology", this.flowLayer);
      }
      this.advancedMenu = false;
      this.lastAppliedColormap = this.inputColormap;
    },

    updateVectorColor() {
      switch (this.selectedDisplayType) {
        case "quantitative": {
          // build expression
          const mapLayerExpression = buildMapboxExpression(
            this.flowLayer.layer.ValueField,
            this.inputColormap
          );

          // update mapLayer paint property with new expression
          this.flowLayer.files.forEach((file) => {
            file.mapLayer.paint["fill-color"] = mapLayerExpression;

            file.colormap = {
              displayType: this.selectedDisplayType,
              inputMin: this.inputMin,
              inputMax: this.inputMax,
              legendColormap: this.inputColormap,
              flowCategoryStyle: this.flowCategoryStyle,
            };
          });
          break;
        }

        default:
          break;
      }

      // commit changes to store
      this.$store.commit("mapping/updateLayerSymbology", this.flowLayer);
      this.advancedMenu = false;
    },
  },

  mounted() {
    // set default display params to the first files (common) and merge with user configurable, only used for raster.
    this.displayParams = {
      ...this.displayParams,
      ...this.flowLayer.files[0].colormap.displayParams,
    };

    // this is not common amongst the dataset files, delete.
    delete this.displayParams.url;

    this.setStatsRanges();

    // set display type from first file
    this.selectedDisplayType = this.flowLayer.files[0].colormap.displayType;

    // only for raster
    this.initializeInternalStyleProperty();

    // set from saved symbology
    if (this.flowLayer.files[0].colormap.flowCategoryStyle) {
      this.setFromFlowCategoryStyle(
        this.flowLayer.files[0].colormap.flowCategoryStyle
      );
    }

    // set from a legend colormap
    else if (this.flowLayer.files[0].colormap.legendColormap) {
      this.setFromLegendSymbology(this.flowLayer.files[0].colormap);
    }

    // set from internalColormap
    else if (this.internalColormap) {
      this.setFromInternal(this.internalColormap);
    }
  },
};
</script>

<style scoped>
.property-titles {
  color: rgba(0, 0, 0, 0.87);
  text-align: end;
}
.v-input,
.v-select {
  font-size: 0.8rem !important;
  padding-right: 0px !important;
}

.advanced-menu {
  /* overflow-y: auto; */
  margin: auto;
  min-width: 380px;
  height: 85vh;
  width: 700px;
}

.categories-container {
  padding: 4px;
  border: 2px solid rgba(160, 163, 165, 0.61);
  border-radius: 5px;
  background-color: rgb(236, 239, 243);
  height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.saved-styles {
  max-width: 200px;
}
</style>

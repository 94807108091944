import Vue from "vue";
import VueRouter from "vue-router";
import base from "./base.js";
import custom from "./custom.js";
import { Auth } from "aws-amplify";
import store from "../store/";

Vue.use(VueRouter);

const routes = [
  // Default or custom routes here
  ...base,
  ...custom,
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  let authUser;

  if (to.meta.requiresAuth) {
    // check if route allows public auth
    if (to.meta.allowPublicAuth) {
      next();
    } else {
      // make sure the user is authenticated
      try {
        authUser = await Auth.currentAuthenticatedUser();
      } catch (error) {
        // redirect user to login page if no auth
        next({ name: "Login" });
      }

      if (authUser) {
        // check if route requires admin auth
        if (to.meta.requiresAdminAuth) {
          // check if user has admin auth
          if (
            authUser.signInUserSession.idToken.payload["cognito:groups"].some(
              (groupName) => groupName.includes("admin")
            )
          ) {
            // allow route if user has admin auth
            next();
          }

          // prevent route if accessing an admin page with incorrect admin permissions and warn user.
          else {
            store.commit("alert/showAlert", {
              text: `<strong>Permission Denied</strong>
          </br>You don't have permission to view the ${to.name} page.`,
              type: "error",
            });
          }
        }

        // check if user has public auth only
        if (
          authUser.signInUserSession.idToken.payload["cognito:groups"].every(
            (groupName) => groupName.includes("public")
          )
        ) {
          // prevent route if accessing a  page with incorrect permissions and warn user.
          store.commit("alert/showAlert", {
            text: `<strong>Permission Denied</strong>
            </br>You don't have permission to view the ${to.name} page.`,
            type: "error",
          });
        }

        // allow route if only basic (analyst level) auth is required
        else {
          next();
        }
      }
    }
  }

  // allow route if no auth is required
  else next();
});

export default router;

<template>
  <div class="d-flex flex-column">
    <v-chip-group column>
      <v-chip
        v-for="tag in datasetTags"
        class="pa-2"
        :key="tag"
        :ripple="false"
        x-small
        :close="editTags && !isReservedTag(tag)"
        close-icon="mdi-delete"
        :color="getChipColor(tag)"
        @click:close="removeDatasetTag(tag)"
      >
        {{ tag }}
      </v-chip>
    </v-chip-group>

    <v-combobox
      ref="form"
      lazy-validation
      v-if="editTags"
      :rules="nameRules"
      label="Add new tag"
      :items="filteredAllTags"
      v-model="newTag"
      :search-input.sync="search"
      chips
      deletable-chips
      small-chips
      hide-details
      clearable
      outlined
      dense
      :color="$store.state.settings.appColor"
      loading="isLoading"
      @change="addDatasetTag(newTag)"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              No results matching "<strong>{{ newTag }}</strong
              >". Press <kbd>enter</kbd> to create a new one
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import { addDatasetTag, removeDatasetTag } from "@/api/v2.js";
import { sortCaseInsensitive } from "@/helpers/formatting.js";
import { getChipColor } from "@/helpers/chipColors.js";

export default {
  name: "DatasetTagManager",

  props: {
    dataset: { type: Object, default: () => {} },
    datasetList: { type: Object, default: () => {} },
    editTags: { type: Boolean, default: false },
  },

  data() {
    return {
      isLoading: false,
      newTag: null,
      search: "",
      originalTags: this.dataset.Tags,
      datasetTags: this.dataset.Tags,
    };
  },

  computed: {
    filteredReservedTags() {
      // filter for reserved tags
      const filteredList =
        this.$store.state.accountData.datasetList.datasets.AllTags.filter(
          (tag) => !this.isReservedTag(tag)
        );
      return filteredList;
    },

    // sort tags a-z
    sortedTags() {
      return sortCaseInsensitive(this.filteredReservedTags);
    },

    // finally filter for tags already in dataset
    filteredAllTags() {
      return this.sortedTags.filter((tag) => !this.datasetTags.includes(tag));
    },

    nameRules() {
      const rules = [];

      // tag name already exists in user list
      if (this.datasetTags) {
        const rule = (v) =>
          !this.datasetTags.find((tag) => tag === v) ||
          "There is an already a tag with that name";

        rules.push(rule);
      }

      return rules;
    },
  },

  methods: {
    getChipColor,
    sortCaseInsensitive,

    isReservedTag(tag) {
      const re = new RegExp("[A-Z]+:.*");
      return re.test(tag);
    },

    addDatasetTag(tag) {
      if (this.newTag.length > 0) {
        // add tag to end of list
        this.datasetTags.push(tag);
        this.$nextTick(() => {
          this.search = "";
          this.newTag = null;
        });

        addDatasetTag(this.dataset.DatasetName, this.dataset.DatasetOwner, tag)
          .then((response) => {
            if (response.Warnings) {
              this.$showAlert({
                text: response.Warnings,
                type: "warning",
              });
            }
          })
          .catch((errors) => {
            // remove tag from list if error
            this.removeTagFromList(tag);
            if (errors) {
              this.$showAlert({
                text: errors,
                type: "error",
              });
            }
            if (errors.response.data.Errors) {
              this.$showAlert({
                text: errors.response.data.Errors,
                type: "error",
              });
            }
          });
      }
    },

    removeTagFromList(tag) {
      const index = this.datasetTags.indexOf(tag);
      let removedTag;
      if (index !== -1) {
        removedTag = this.datasetTags.splice(index, 1);
      }

      // return tag incase it needs to be put back
      return [index, removedTag[0]];
    },

    removeDatasetTag(tag) {
      // remove tag immediately
      let [index, removedTag] = this.removeTagFromList(tag);

      removeDatasetTag(this.dataset.DatasetName, this.dataset.DatasetOwner, tag)
        .then((response) => {
          if (response.Warnings) {
            this.$showAlert({
              text: response.Warnings,
              type: "warning",
            });
          }
        })
        .catch((errors) => {
          // put tag back if error
          this.datasetTags.splice(index, 0, removedTag);

          if (errors) {
            this.$showAlert({
              text: errors,
              type: "error",
            });
          }
          if (errors.response.data.Errors) {
            this.$showAlert({
              text: errors.response.data.Errors,
              type: "error",
            });
          }
        });
    },
  },
};
</script>

<style></style>

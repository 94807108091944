<template>
  <!-- number input device -->

  <v-text-field
    class="text-body-1 font-weight-medium black--text mx-0 py-2"
    v-if="definition.InputDeviceType === 'number'"
    :placeholder="`${definition.InputDeviceDefaultValue}`"
    type="number"
    v-model="value"
    :rules="[validateNumber]"
    :color="$store.state.settings.appColor"
    @input="onValueUpdate()"
    outlined
    hide-details
    dense
    append-outer-icon="mdi-restore"
    @click:append-outer="resetValue"
  />

  <!-- slider input device -->

  <v-slider
    v-else-if="definition.InputDeviceType === 'slider'"
    class="justify-end align-center py-1 pl-2 mr-0"
    v-model="value"
    :color="$store.state.settings.appColor"
    :track-color="$store.state.settings.appColor"
    :max="definition.InputDeviceMaxValue"
    :min="definition.InputDeviceMinValue"
    step="any"
    hide-details
    dense
    @change="onValueUpdate()"
  >
    <template v-slot:prepend>
      <p
        class="d-flex align-center justify-start text-body-1 font-weight-medium black--text pl-1 mb-0"
        style="width: 60px"
      >
        {{ preparedValue(value) }}
      </p>
    </template>

    <template v-slot:append>
      <v-btn icon @click="resetValue" class="d-flex align-center justify-end">
        <v-icon> mdi-restore </v-icon>
      </v-btn>
    </template>
  </v-slider>

  <!-- select input device -->

  <v-select
    class="text-body-1 font-weight-medium black--text pl-2 py-2"
    v-else-if="definition.InputDeviceType === 'select'"
    :items="definition.InputDeviceValueOptions"
    v-model="value"
    :color="$store.state.settings.appColor"
    @change="onValueUpdate()"
    hide-details
    dense
    append-outer-icon="mdi-restore"
    @click:append-outer="resetValue"
  />

  <!-- data input device -->

  <div
    v-else-if="definition.InputDeviceType === 'data'"
    :color="$store.state.settings.appColor"
  >
    <v-row
      dense
      class="d-flex flex-wrap"
      justify="space-between"
      align="center"
    >
      <v-col cols="12" md="6">
        <v-card elevation="0">
          <v-card-subtitle class="pb-0 px-1">Dataset*</v-card-subtitle>

          <v-card-text class="px-1 text-body-1 font-weight-medium black--text">
            {{ value.name || "None" }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="8" md="4">
        <v-card elevation="0">
          <v-card-subtitle class="pb-0 px-1">Layer(s)</v-card-subtitle>

          <v-card-text class="px-1 text-body-1 font-weight-medium black--text">
            {{
              value.feature_name
                ? formatLayerNames(value.feature_name)
                : value.name
                ? "Default"
                : "None"
            }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="4"
        md="1"
        class="align-self-end align-self-md-center pb-sm-4 pr-0"
      >
        <div class="d-flex justify-center">
          <v-btn icon @click="resetValue">
            <v-icon> mdi-restore </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row class="flex-wrap my-0 px-2" justify="space-between" align="center">
      <v-col cols="12" md="4">
        <v-dialog
          v-model="showDatasetSelector"
          width="80%"
          height="80%"
          persistent
          scrollable
        >
          <template v-slot:activator="{ on, attrs }">
            <div>
              <v-btn small v-bind="attrs" v-on="on">
                <v-icon>mdi-vector-rectangle</v-icon>
                Change Dataset
              </v-btn>
            </div>
          </template>
          <v-card class="selector-overlay-card">
            <v-card-text>
              <dataset-selector
                v-if="showDatasetSelector"
                :selection-mode="true"
                :batch-select="false"
                :single-select="false"
                :multi-layer-select="true"
                :crud-actions="false"
                :active-project-tag="false"
                :initial-type-filters="['Raster', 'Vector']"
                :initial-tags="
                  this.definition.InputDeviceDataTag
                    ? [this.definition.InputDeviceDataTag]
                    : []
                "
                :data-table-height="300"
                @update-dataset="updateDataset"
                @update-layer="updateLayer"
              ></dataset-selector>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions class="d-flex justify-center align-center">
              <v-btn text @click="confirmDatasetDialog">Apply</v-btn>

              <v-btn color="warning" text @click="cancelDatasetDialog">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="6" md="4">
        <v-card elevation="0">
          <v-card-subtitle class="py-0 px-1">Set Time</v-card-subtitle>
          <v-text-field
            type="number"
            v-model="value.set_time"
            :color="$store.state.settings.appColor"
            class="pb-3"
            prepend-icon="mdi-timeline-clock-outline"
            hide-details
            @change="onValueUpdate()"
          ></v-text-field>
        </v-card>
      </v-col>
      <v-col cols="6" md="4">
        <v-card elevation="0">
          <v-card-subtitle class="py-0 px-1">Snap Time</v-card-subtitle>
          <v-select
            v-model="value.snap_time"
            :items="snapTimeOptions"
            :color="$store.state.settings.appColor"
            class="pb-3"
            prepend-icon="mdi-routes-clock"
            hide-details
            @change="onValueUpdate()"
          ></v-select>
        </v-card>
      </v-col>
    </v-row>
  </div>

  <!-- select data input device -->
  <div
    v-else-if="definition.InputDeviceType === 'select-data'"
    :color="$store.state.settings.appColor"
  >
    <!-- dataset/layer combination -->
    <v-row
      dense
      class="d-flex flex-wrap"
      justify="space-between"
      align="center"
    >
      <v-col cols="6">
        <v-card elevation="0">
          <v-card-subtitle class="pb-0 px-1"
            >Land Cover Dataset</v-card-subtitle
          >
          <v-select
            class="text-body-1 font-weight-medium black--text pl-2 py-2"
            :items="['Annual Crop Inventory', 'Dataset 2', 'Dataset 3']"
            outlined
            v-model="value"
            :color="$store.state.settings.appColor"
            hide-details
            dense
          />
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card elevation="0">
          <v-card-subtitle class="pb-0 px-1">Layer</v-card-subtitle>

          <v-select
            class="text-body-1 font-weight-medium black--text pl-2 py-2"
            :items="['Default', 'Layer 2', 'Layer 3']"
            outlined
            v-model="value"
            :color="$store.state.settings.appColor"
            hide-details
            dense
          />
        </v-card>
      </v-col>
    </v-row>

    <!-- categories pre/post type -->
    <v-row
      dense
      class="d-flex flex-wrap"
      justify="space-between"
      align="center"
    >
      <v-col cols="6">
        <v-card elevation="0">
          <v-card-subtitle class="pb-0 px-1"
            >Pre Conversion Type(s)</v-card-subtitle
          >
          <v-select
            class="text-body-1 font-weight-medium black--text pl-2 py-2"
            :items="['Cropland', 'Type 2', 'Type 3']"
            outlined
            v-model="value"
            :color="$store.state.settings.appColor"
            hide-details
            dense
          />
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card elevation="0">
          <v-card-subtitle class="pb-0 px-1"
            >Post Conversion Type(s)</v-card-subtitle
          >

          <v-select
            class="text-body-1 font-weight-medium black--text pl-2 py-2"
            :items="['Urban', 'Type 2', 'Type 3']"
            outlined
            v-model="value"
            :color="$store.state.settings.appColor"
            hide-details
            dense
          />
        </v-card>
      </v-col>
    </v-row>
  </div>

  <!-- footprints input device -->

  <div
    v-else-if="definition.InputDeviceType === 'footprints'"
    :color="$store.state.settings.appColor"
  >
    <v-list>
      <v-list-item v-for="(dataset, index) in value" :key="index">
        <v-list-item-content>
          <v-row dense class="d-flex flex-wrap justify-space-between">
            <v-col cols="12" md="6">
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1">Dataset*</v-card-subtitle>

                <v-card-text
                  class="px-1 text-body-1 font-weight-medium black--text"
                >
                  {{ dataset.name || "None" }}
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="8" md="4">
              <v-card elevation="0">
                <v-card-subtitle class="pb-0 px-1">Layer(s)</v-card-subtitle>

                <v-card-text
                  class="px-1 text-body-1 font-weight-medium black--text"
                >
                  {{
                    dataset.feature_name
                      ? formatLayerNames(dataset.feature_name)
                      : dataset.name
                      ? "Default"
                      : "None"
                  }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn icon @click="removeArrayElement(index)">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-dialog
            v-model="showDatasetSelector"
            width="80%"
            height="80%"
            persistent
            scrollable
          >
            <template v-slot:activator="{ on, attrs }">
              <div>
                <v-btn v-bind="attrs" v-on="on" block>
                  <v-icon left>mdi-plus</v-icon>
                  Add footprint
                </v-btn>
              </div>
            </template>

            <v-card class="selector-overlay-card">
              <v-card-text class="selector-overlay-card-content pa-0">
                <dataset-selector
                  v-if="showDatasetSelector"
                  :selection-mode="true"
                  :batch-select="false"
                  :single-select="false"
                  :multi-layer-select="true"
                  :crud-actions="false"
                  :active-project-tag="false"
                  :initial-tags="
                    this.definition.InputDeviceDataTag
                      ? [this.definition.InputDeviceDataTag]
                      : []
                  "
                  :initial-type-filters="['Vector']"
                  :data-table-height="300"
                  @update-dataset="updateDataset"
                  @update-layer="updateLayer"
                ></dataset-selector>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions class="d-flex justify-center align-center">
                <v-btn text @click="addFootprintDataset">Add</v-btn>

                <v-btn color="warning" text @click="cancelDatasetDialog">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn icon @click="resetValue">
            <v-icon> mdi-restore </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { debounce } from "vuetify/lib/util/helpers";

import { updateInputDeviceValue } from "@/api/v2";
import DatasetSelector from "@/components/data/DatasetSelector.vue";

export default {
  name: "ExpressionVariable",

  components: {
    DatasetSelector,
  },

  props: {
    modelValue: null,
    name: String,
    definition: Object,
  },

  data() {
    return {
      debouncedAutosave: null, // function to execute debounced autosave
      tempDataset: {}, // target object for receiving events from dataset manager
      value: this.modelValue,
      showDatasetSelector: false,
      snapTimeOptions: [
        { text: "", value: null },
        { text: "closest", value: "closest" },
        { text: "forward", value: "forward" },
        { text: "backward", value: "backward" },
      ],
    };
  },

  computed: {
    inputDeviceType() {
      return this.definition.InputDeviceType;
    },
  },

  methods: {
    validateNumber(text) {
      if (!isNaN(text) && !isNaN(parseFloat(text))) {
        return true;
      } else {
        return "Must be a number";
      }
    },

    removeArrayElement(index) {
      this.value = [
        ...this.value.slice(0, index),
        ...this.value.slice(index + 1),
      ];
      this.onValueUpdate();
    },

    formatLayerNames(names) {
      if (Array.isArray(names)) {
        if (names.length < 10) {
          const nameString = names.map((name) => `${name}`).join(", ");
          return nameString;
        } else if (names.length === 1) {
          return names;
        } else {
          const limitedNames = names.slice(0, 9);
          const nameString = `${limitedNames[0]} +${names.length - 1}others`;
          return nameString;
        }
      } else {
        return names;
      }
    },

    preparedValue(val) {
      const sigFigs = (x, n) => +x.toPrecision(n);
      return sigFigs(val, 2);
    },

    // preparedTimeValues(val) {
    //   if (val === "none")
    // },

    resetValue() {
      // if the default value is an object, make a copy
      let defaultValue = this.definition.InputDeviceDefaultValue;
      if (Array.isArray(defaultValue)) {
        this.value = [...defaultValue];
      } else if (typeof defaultValue == "object") {
        this.value = { ...defaultValue };
      } else {
        this.value = defaultValue;
      }

      this.onValueUpdate();
    },

    onValueUpdate() {
      let isValid = false;

      switch (this.definition.InputDeviceType) {
        case "number":
          isValid |= this.validateNumber(this.value);
          break;
        case "slider":
          this.value = this.preparedValue(this.value);
          isValid |= this.validateNumber(this.value);
          break;
        case "select":
          isValid |= this.InputDeviceValueOptions.includes(this.value);
          break;
        case "data":
          this.value.set_time === ""
            ? (this.value.set_time = null)
            : this.value.set_time;
          isValid |= this.value.name != null;

          break;
        case "footprints":
          isValid = true;
          break;
        default:
          break;
      }

      if (isValid) {
        this.$emit("update:modelValue", this.value);
        this.debouncedAutosave(this, this.value);
      }
    },

    updateDataset(newValue) {
      this.tempDataset.name = newValue.DatasetName;
      this.tempDataset.feature_name = null;
    },

    // TODO: update to layer instead of feature_name
    updateLayer(newValue) {
      let layerNames;
      // null
      if (!newValue) layerNames = newValue;
      // multiple
      else if (newValue instanceof Array) {
        layerNames = newValue.map((layer) => layer.LayerName);
        // single from array
        if (layerNames.length === 1) layerNames = layerNames[0];
      }
      // single
      else layerNames = newValue.LayerName;

      this.tempDataset.feature_name = layerNames;
    },

    confirmDatasetDialog() {
      this.value = { ...this.value, ...this.tempDataset };
      this.onValueUpdate();
      this.showDatasetSelector = false;
    },
    addFootprintDataset() {
      this.value.push({ ...this.tempDataset });
      this.onValueUpdate();
      this.showDatasetSelector = false;
    },
    cancelDatasetDialog() {
      this.showDatasetSelector = false;
    },
  },

  beforeMount() {
    this.debouncedAutosave = debounce(function (self, newValue) {
      updateInputDeviceValue(self.name, newValue).then((response) => {
        if (response.Errors) {
          self.$showAlert({ text: response.Errors, type: "error" });
        }
        if (response.Warnings) {
          self.$showAlert({ text: response.Warnings, type: "warning" });
        }
      });
    }, 2000);
  },
};
</script>
<style scoped>
.selector-overlay-card {
  max-height: 90vh;
  overflow: scroll;
}
.selector-overlay-card-content {
  overflow-x: hidden;
}
</style>

<template>
  <!-- This page is blank, however can be used as a landing page -->
  <div></div>
</template>

<script>
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";

export default {
  name: "Home",
  data() {
    return { isLoading: false };
  },

  computed: {
    authenticated() {
      return (
        !this.$store.state.settings.authRequired ||
        this.$store.state.account.authenticated
      );
    },
    appName() {
      return this.$store.state.settings.appName;
    },
  },

  methods: {
    loadSplashScreen(value) {
      this.$emit("show-splash-screen", value);
    },
  },

  beforeCreate() {
    if (this.$store.state.settings.authRequired) {
      onAuthUIStateChange((authState, authData) => {
        this.$store.commit("account/changeUser", authData);
        this.$store.commit("account/changeAuth", authState);
      });
      Auth.currentAuthenticatedUser()
        .then((user) => {
          this.$store.commit("account/changeUser", user);
          this.$store.commit("account/changeAuth", "signedin");
        })
        .catch((err) => {
          console.warn(err);
        });
    }
  },
  // load initial user data
  async created() {
    const appData = this.$store.state.settings.appData;
    const accountData = this.$store.state.settings.accountData;

    try {
      await Auth.currentAuthenticatedUser();

      if (
        !this.$store.state.accountData.datasetList.lastUpdated ||
        !this.$store.state.accountData.expressionList.lastUpdated ||
        !this.$store.state.accountData.projectList.lastUpdated
      ) {
        // show splash screen first time hydrating data store.
        this.loadSplashScreen(true);

        await Promise.all(
          appData.map(async (action) => {
            await this.$store.dispatch(action.path, action.value);
          })
        );

        await Promise.all(
          accountData.map(async (action) => {
            await this.$store.dispatch(action.path);
          })
        );

        // show splash screen first time hydrating data store.
        this.loadSplashScreen(false);
        this.$router.push({ path: "/data-viewer" });
      } else {
        appData.forEach((action) => {
          this.$store.dispatch(action.path, action.value);
        });

        accountData.forEach((action) => {
          this.$store.dispatch(action.path);
        });
        this.$router.push({ path: "/data-viewer" });
      }
    } catch (error) {
      if (this.$store.state.settings.dataViewerSettings.allowPublicAccess) {
        // push to data-viewer to handle auth
        this.$router.push({ path: "/data-viewer" });
      } else {
        this.$router.push({ path: "/login" });
      }
    }
  },

  beforeDestroy() {
    return onAuthUIStateChange;
  },
};
</script>

<style scoped>
.bg {
  background-image: linear-gradient(#345c9650, rgb(92, 131, 160, 0.9)),
    url("../assets/img/bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* removes overlflow of container */
amplify-authenticator {
  --container-height: 100%;
}

/* height required to show in dataviewer */
#app-bar {
  max-height: 65px;
}

#logo-title {
  color: white;
  text-align: center;
}

#footer {
  background-color: #657f93;
}

#alces-flow-logo {
  margin-right: 1em;
}
</style>

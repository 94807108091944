<template>
  <div
    class="d-flex pa-5 justify-center"
    style="color: #e65247;"
    v-if="!method"
  >
    <v-icon>mdi-robot-confused-outline</v-icon>
    <h4 style="padding-left: 30px;">Unknown method "{{ methodName }}"</h4>
  </div>
  <div class="pa-5" v-else>
    <v-banner elevation="1" two-line>
      <v-icon slot="icon" :color="$store.state.settings.appColor" size="36">
        mdi-iframe-variable-outline
      </v-icon>
      {{ methodName }}<br />
      <v-subheader>{{ method.description }}</v-subheader>

      <template v-slot:actions v-if="method.component">
        <v-btn
          icon
          :color="$store.state.settings.appColor"
          @click="$emit('open-method-editor')"
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </template>
      <template v-slot:actions v-else>
        <v-subheader>{{ method.altText ? method.altText : "" }}</v-subheader>
      </template>
    </v-banner>
    <v-simple-table v-if="method.params.length > 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Parameter
            </th>
            <th class="text-left">
              Required
            </th>
            <th class="text-left">
              Default
            </th>
            <th class="text-left">
              Description
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="param in method.params" :key="param.name">
            <td>{{ param.name }}</td>
            <td>
              <v-icon small>{{ param.required ? "mdi-check" : null }}</v-icon>
            </td>
            <td>{{ param.default !== null ? param.default : "-" }}</td>
            <td>{{ param.description }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div
      v-else
      class="d-flex pa-3 justify-center"
      v-html="
        method.altText
          ? method.altText
          : '<v-subtext>This method has no named parameters</v-subtext>'
      "
    ></div>
  </div>
</template>

<script>
export default {
  name: "MethodPreview",

  props: {
    methodName: String,
  },

  computed: {
    method() {
      const method = this.$store.getters["expressions/routeFromMethodName"](
        this.methodName
      );
      return method;
    },
  },
};
</script>

<style scoped></style>

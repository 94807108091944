<template>
  <v-card>
    <v-card-title class="text-h5">New Expression</v-card-title>
    <v-spacer></v-spacer
    ><v-card-subtitle class="subtitle-2"
      >Create a new expression by entering a descriptive, unique
      name</v-card-subtitle
    >

    <v-card-text>
      <div v-if="creating" class="d-flex justify-center">
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
      <v-form v-else ref="form" v-model="valid">
        <v-text-field
          label="Expression Name"
          v-model.trim="name"
          required
          :rules="nameRules"
          clearable
          :color="$store.state.settings.appColor"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="warning" text @click="closeNew">Cancel</v-btn>
      <v-btn text @click="newExpressionConfirm()" :disabled="!formValid"
        >Create New</v-btn
      >
      <v-dialog>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" text :disabled="!formValid">
            Duplicate Existing
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-h5">Expressions</v-card-title>
          <expression-selector
            :single-select="true"
            :crud-actions="false"
            :selection-mode="true"
            @expression-selected="newExpressionConfirm($event)"
          ></expression-selector>
        </v-card>
      </v-dialog>

      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import { getExpression, newExpression, duplicateExpression } from "@/api/v2.js";
import ExpressionSelector from "@/components/data/ExpressionSelector.vue";

export default {
  name: "NewExpression",

  components: {
    ExpressionSelector,
  },

  props: {
    expressions: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isLoading: false,
      overlay: false,
      name: null,
      search: null,
      valid: false,
      creating: false,
      dialogNewExpression: false,
      selectedExpression: null,
    };
  },

  computed: {
    isDark() {
      return this.$store.getters["settings/isDark"];
    },

    nameRules() {
      const rules = [];
      const max = 80;
      // name length
      if (max) {
        const rule = (v) =>
          (v || "").length <= max ||
          `A maximum of ${max} characters is allowed`;
        rules.push(rule);
      }

      // required
      rules.push((v) => !!v || "A name is required");

      // can't contain characters
      rules.push(
        (v) =>
          (v && !/([#\\])/.test(v)) ||
          "Expression names cannot contain the following characters (#, \\)"
      );

      // expression name already exists
      if (this.expressions) {
        const rule = (v) =>
          !this.expressions.AllExpressions.find(
            (expression) => expression.ExpressionName === `${v}`
          ) || "There is an already an expression with that name";

        rules.push(rule);
      }

      return rules;
    },

    formValid() {
      return this.valid;
    },
  },

  // TODO: add get expression list on new expression

  methods: {
    closeNew() {
      this.$refs.form.reset();
      this.$emit("close-new");
    },
    validateField() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    async newExpressionConfirm(expressionToDuplicate) {
      this.creating = true;

      const expressionName = this.name;
      let currentUser = this.$store.state.account.user.attributes.email;
      try {
        if (expressionToDuplicate) {
          await duplicateExpression(
            expressionName,
            currentUser,
            expressionToDuplicate.ExpressionName,
            expressionToDuplicate.ExpressionOwner
          );
        } else {
          await newExpression(expressionName, currentUser);
        }
        await new Promise((resolve) => setTimeout(resolve, 3000));
        const response = await getExpression(expressionName, currentUser);
        if (!response.Errors && !response.Warnings) {
          this.$emit("update", response);
          this.$store.dispatch("accountData/refreshExpressionList");
        }
      } catch (errors) {
        if (errors.response.data.Errors) {
          this.$showAlert({
            text: errors.response.data.Errors,
            type: "error",
          });
        } else if (errors.response.data.Warnings) {
          this.$showAlert({
            text: errors.response.data.Warnings,
            type: "warning",
          });
        } else {
          this.$showAlert({
            text: errors,
            type: "error",
          });
        }
      } finally {
        this.creating = false;
      }
    },
  },
};
</script>

<style></style>

<template>
  <v-dialog v-model="showSplash" scrollable max-width="70%" persistent>
    <v-card
      v-if="!showLoginSplash"
      class="splash-card"
      color="blue-grey lighten-4"
    >
      <v-row align="center" justify="center" class="py-2">
        <v-col cols="6" md="12" class="d-flex justify-center">
          <v-img
            class="mx-auto"
            :aspect-ratio="16 / 9"
            max-width="200"
            contain
            src="../assets/img/logo.png"
          >
          </v-img>
        </v-col>
      </v-row>

      <v-card-title style="word-break: break-word">{{ title }} </v-card-title>
      <v-card-text class="text-area">
        <p
          v-html="value"
          v-for="(value, key) in descriptionText"
          :key="key"
        ></p>
      </v-card-text>

      <v-card-actions
        class="d-flex justify-space-between align-center flex-column login-buttons"
      >
        <v-btn @click="enter" color="primary" large class="text-capitalize"
          >Enter</v-btn
        >

        <v-btn text @click="login" small class="text-capitalize ml-0"
          >Analyst Sign In
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      v-if="showLoginSplash"
      class="analyst-login-card"
      color="blue-grey lighten-4"
    >
      <v-card-text>
        <div class="d-flex just-start">
          <v-btn
            @click="showLoginSplash = !showLoginSplash"
            icon
            large
            class="text-capitalize"
            ><v-icon>mdi-arrow-left</v-icon></v-btn
          >
        </div>

        <v-spacer></v-spacer>
        <amplify-auth title="Analyst Sign in"></amplify-auth>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { Auth } from "aws-amplify";
import AmplifyAuth from "./auth/AmplifyAuth.vue";

Auth.configure({
  authenticationFlowType: "USER_SRP_AUTH",
});

export default {
  components: {
    AmplifyAuth,
  },

  props: {
    title: { default: "Welcome to the Agriculture Mapping Tool" },
    logo: { type: String, default: "../assets/img/logo.png" },
    descriptionText: {
      type: Object,
      default() {
        return {
          paragraph1:
            "The web application applies publicly available datasets to map agricultural land attributes, land management practices, and agri-environmental indicators. Dynamic maps and graphs allow users to view change in Canadian agriculture over time for a range of geographies. A user-friendly query builder makes it possible to integrate agricultural datasets to map farmland that satisfies criteria related to the adoption of management practices and environmental status. Additional analysis tools expand the scope of the tool to include analysis of landscape change, assessment of risk of natural land cover conversion, and prioritization of marginal farmland restoration.",
          paragraph2:
            "To improve the availability of agricultural information, the data viewer for generating maps and charts of Canadian agricultural data is publicly available, as is the query builder for integrated analysis of agricultural data. This provides public access to a powerful yet user-friendly tool for exploring Canadian agricultural land use. The analysis tools for change analysis, assessment of risk of natural land cover conversion, and prioritization of marginal farmland restoration require a user account to access in order to manage data processing and storage load associated with their application.  The data viewer, query builder, and analysis tools are described in a user-guide available here: <a href='https://s3.us-west-2.amazonaws.com/capi-alces-flow.com-public-assets/Agriculture+Mapping+Tool+Guide.pdf' target='_blank' rel='noopener noreferrer'>User Guide<a/>.",
          paragraph3:
            "For more information, please contact the Canadian Agri-Food Policy Institute.",
        };
      },
    },
  },

  data() {
    return {
      showSplash: true,
      showLoginSplash: false,
    };
  },

  computed: {
    isAuthenticated() {
      return this.$store.state.account.authenticated;
    },

    publicAccessCredentials() {
      return {
        username: process.env.VUE_APP_AWS_PUBLIC_AUTH_UNAME,
        password: process.env.VUE_APP_AWS_PUBLIC_AUTH_PWORD,
      };
    },
  },

  methods: {
    async enter() {
      if (!this.isAuthenticated) {
        // authenticate using public access credentials
        await this.signIn(
          this.publicAccessCredentials.username,
          this.publicAccessCredentials.password
        );
      }

      this.showSplash = false; // Hide the splash screen
    },
    async login() {
      if (this.isAuthenticated) {
        // sign out public user
        await this.signOut();
      }

      this.showLoginSplash = true;
    },

    async signIn(username, password) {
      try {
        await Auth.signIn(username, password);
      } catch (error) {
        console.log("error signing in: ", error);
      }
    },

    async signOut() {
      try {
        await Auth.signOut();
      } catch (error) {
        console.log("error signing out: ", error);
      }
    },
  },
};
</script>

<style scoped>
.splash-card {
  text-align: center;
  padding: 20px;
  overflow-y: hidden;
}

.analyst-login-card {
  text-align: center;
  padding: 20px;
  overflow-y: hidden;
}

.text-area {
  text-align: start;
  overflow: auto;
}

.login-buttons {
  gap: 1rem;
}
</style>

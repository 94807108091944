const state = {
  loader: null,
};

const defaultLoader = {
  loader: {
    title: "Loading, please wait",
    updateMessage: "This should just take a few moments..",
    timeEstimate: null,
    currentStepValue: null,
  },
};

const mutations = {
  showLoadingOverlay(state, loaderDetails) {
    if (state.loader) {
      state.loader = null;
    } else {
      state.loader = { ...defaultLoader.loader, ...loaderDetails };
    }
  },
  // updateLoadingStatus(state, loadingStatus) {
  //   state.currentStepValue = loadingStatus.currentStepValue;
  //   state.updateMessage = loadingStatus.message;
  // },
  // resetLoader(state) {
  //   state.loader = null;
  // },
};
const getters = {};
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <!-- <div class="table-drawer-container"> -->
  <v-card :height="drawerHeight" style="border: 2px solid black">
    <v-card-title :class="!minimizeDrawer ? 'pa-4' : 'pa-0'">
      <v-spacer></v-spacer>
      <!-- <span class="text-h6" v-if="!minimizeDrawer">
        {{ selectedTable.tableDataset.Description }}</span
      > -->
      <v-spacer></v-spacer>
      <template v-if="tablePanelOpen">
        <v-btn
          icon
          @click="
            expandDrawer = !expandDrawer;
            minimizeDrawer = false;
          "
        >
          <v-icon> mdi-arrow-expand </v-icon>
        </v-btn>
        <v-btn
          icon
          @click="
            expandDrawer = false;
            minimizeDrawer = !minimizeDrawer;
          "
        >
          <v-icon>{{
            minimizeDrawer ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </v-btn>
        <v-btn icon @click="removeTables">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-card-title>
    <v-row v-show="!minimizeDrawer" class="px-2" no-gutters>
      <v-col cols="12" sm="6" lg="4">
        <v-select
          v-model="selectedTable"
          class="px-2"
          outlined
          filled
          dense
          hide-details
          :items="availableTables"
          :item-text="formatItemText"
          return-object
          color="black"
          :loading="tableListLoading"
          @change="selectTable"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" lg="8">
        <p class="px-1 text-caption">
          {{ tableDescription }}
        </p>
      </v-col>
    </v-row>

    <data-table
      v-if="selectedTable"
      v-show="!minimizeDrawer"
      :table-information="selectedTable"
      :updated-selected-row="updatedSelectedTableRow"
      no-data-text="This table doesn't have any rows yet."
      :data-table-height="tableHeight"
      @property-headers="setPropertyHeaders"
      @selected-row="selectRow"
    >
    </data-table>
  </v-card>
</template>

<script>
import DataTable from "@/components/tables/DataTable.vue";

export default {
  components: { DataTable },

  props: {
    tableData: { type: Array, default: () => [] },
    tablePanelOpen: { type: Boolean, default: false },
  },

  data() {
    return {
      drawerResizing: false,
      tableListLoading: false,
      expandDrawer: false,
      minimizeDrawer: false,
      availableTables: null,
      selectedTable: null,
      currentSelectedTableRow: null,
      currentTablePropertyHeaders: [],
      updatedSelectedTableRow: null,
    };
  },

  watch: {
    storeRowData(selectedRow) {
      this.currentSelectedTableRow = selectedRow;
      this.updatedSelectedTableRow = selectedRow;
    },

    expandDrawer() {
      this.$emit("drawer-resizing");
    },
    minimizeDrawer() {
      this.$emit("drawer-resizing");
    },
  },

  computed: {
    storeRowData() {
      return this.$store.state.mapping.tableEditorData.selectedRow;
    },

    tableDescription() {
      const tableDataset = this.currentFocusedMapLayer.associatedTableData.find(
        (currentTable) =>
          currentTable.tableDataset.DatasetName ===
          this.selectedTable.DatasetName
      );

      return tableDataset ? tableDataset.tableDataset.Description : "";
    },

    drawerHeight() {
      return this.expandDrawer ? "65vh" : this.minimizeDrawer ? "5vh" : "40vh";
    },

    tableHeight() {
      return this.expandDrawer ? "45vh" : "20vh";
    },
  },

  methods: {
    // remove ranch name from item names
    formatItemText: (item) => item.DatasetName.split("/")[1],

    selectRow(newRow) {
      this.currentSelectedTableRow = newRow;

      this.$store.commit("mapping/addRowToTableEditor", {
        table: this.selectedTable,
        tableHeaders: this.currentTablePropertyHeaders,
        selectedRow: this.currentSelectedTableRow,
      });

      this.$emit("open-data-panel");
    },

    setPropertyHeaders(propertyHeaders) {
      this.currentTablePropertyHeaders = propertyHeaders;

      this.$store.commit("mapping/addRowToTableEditor", {
        table: this.selectedTable,
        tableHeaders: propertyHeaders,
        selectedRow: null,
      });

      this.$emit("open-data-panel");
    },

    selectTable(table) {
      this.selectedTable = table;
    },
    removeTables() {
      this.$store.commit(
        "mapping/removeLayerTableDrawer",
        this.currentFocusedMapLayer
      );
    },
  },

  mounted() {
    this.currentFocusedMapLayer = this.tableData[0];

    this.availableTables = this.currentFocusedMapLayer.associatedTableData.map(
      (table) => table.table
    );

    this.selectedTable = this.availableTables[0];
  },

  beforeDestroy() {
    this.$emit("drawer-resizing");
  },
};
</script>

<style lang="scss" scoped>
::v-deep tbody {
  tr.v-data-table__selected {
    background-color: #f8f4e3 !important;
  }
}

::v-deep tbody {
  tr:hover {
    background-color: #f8f4e3 !important;
  }
}
::v-deep .v-data-table-header {
  background-color: #ececec;
  // background-color: #f8f4e3;
}
</style>

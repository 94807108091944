<template>
  <v-card class="pa-4" max-width="600px">
    <v-card-subtitle>
      Please select a {{ selectionTitle }} or enter the name of a new
      {{ selectionTitle }} to continue.
    </v-card-subtitle>

    <v-combobox
      ref="combobox"
      class="px-10"
      solos
      dense
      hide-details
      v-model="selectedDataset"
      :items="userDatasetList"
      item-text="DatasetName"
      :search-input.sync="search"
      return-object
      auto-select-first
      :disabled="datasetLoading"
      :loading="datasetLoading"
      @keydown="validateSearch"
    >
    </v-combobox>
    <div class="d-flex justify-center">
      <v-btn
        class="mt-4"
        color="primary"
        small
        @click="fetchDataset(selectedDataset)"
        :disabled="!selectedDataset && !createNewDataset"
        >{{ createDataset ? "Create" : "Load" }} {{ selectionTitle }}</v-btn
      >
    </div>
  </v-card>
</template>

<script>
import { getDataset } from "@/api/v2.js";

export default {
  name: "SingleDatasetSelector",

  props: {
    selectionTitle: {
      type: String,
      default: "Ranch",
    },

    tagFilter: {
      type: String,
      default: "Paddock Boundaries",
    },
    createNewDataset: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      search: null,
      selectedDataset: null,
      datasetLoading: false,
      createDataset: false,
      // datasetListLoading: true,
    };
  },

  computed: {
    userDatasetList() {
      return this.$store.state.accountData.datasetList.datasets.AllDatasets.filter(
        (dataset) => dataset.Tags.includes(this.tagFilter)
      );
    },
  },

  methods: {
    async fetchDataset(selectedDataset) {
      this.datasetLoading = true;

      if (this.createDataset) {
        this.$router.push({
          name: "Season Manager",
          params: { newRanchName: selectedDataset },
        });

        // clear current selected dataset and season list
        this.$store.commit("mapping/clearSingleDataset");
        this.$store.commit("accountData/clearSeasonList");

        this.$store.commit("settings/updateSubAppName", selectedDataset);

        this.datasetLoading = false;
        return;
      } else {
        try {
          const dataset = await getDataset(
            selectedDataset.DatasetName,
            selectedDataset.DatasetOwner
          );
          if (dataset.Errors) {
            throw new Error(dataset.Errors);
          }
          if (dataset.Warnings) {
            this.$showAlert({
              text: dataset.Warnings,
              type: "warning",
            });
          }

          this.$emit("update-dataset", dataset);

          this.$store.commit(
            "settings/updateSubAppName",
            selectedDataset.DatasetName
          );
        } catch (error) {
          this.$showAlert({
            text: error,
            type: "error",
          });
        } finally {
          this.datasetLoading = false;
        }
      }
    },

    blurSearch() {
      this.$refs.combobox.blur();
    },

    validateSearch() {
      this.selectedDataset = this.search;
      if (typeof this.selectedDataset === "string") {
        this.createDataset = true;
      } else {
        this.createDataset = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <!-- <span style="font-size: 12px;">invalid</span> -->
    <v-card width="100%" color="rgb(41, 49, 56)" class="editor">
      <div class="d-flex pa-1">
        <!-- TODO:make text box larger and methods scrollable -->
        <textarea :id="`codemirrortextarea-${idx}`" />

        <div class="d-flex flex-column align-center">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :color="savingNeeded ? 'warning' : 'success'"
                v-bind="attrs"
                v-on="on"
                >{{
                  savingNeeded
                    ? "mdi-alert-circle-outline"
                    : "mdi-checkbox-marked-circle-outline"
                }}</v-icon
              >
            </template>
            <span>{{
              savingNeeded ? "Unsaved changes" : "All changes saved"
            }}</span>
          </v-tooltip>

          <v-progress-circular
            indeterminate
            v-if="saving"
            size="20"
            width="2"
            color="white"
          >
          </v-progress-circular>
          <v-tooltip left v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="saveExpr()"
                ><v-icon>mdi-content-save-outline</v-icon></v-btn
              >
            </template>
            <span>Save</span>
          </v-tooltip>
        </div>
      </div>
    </v-card>
    <v-card class="bottom">
      <expression-nest
        :expression="expression"
        @next-expression="nextExpression"
        @add-method="applyAutocomplete"
        v-if="showNested"
      ></expression-nest>
      <auto-complete
        v-if="autocomplete"
        :token="autocomplete"
        @choose-autocomplete="applyAutocomplete"
      ></auto-complete>
      <method-preview
        v-if="!autocomplete && method.name"
        :method-name="method.name.token"
        @open-method-editor="methodOverlay = true"
      >
      </method-preview>
    </v-card>

    <v-overlay :value="methodOverlay" :dark="false">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="10">
          <v-card>
            <div class="d-flex justify-center">
              <v-card-text class="method-card">
                <methods
                  :name="currentMethod ? currentMethod.component : ''"
                  :params="currentMethod ? currentMethod.params : {}"
                  @submit-params="updateStagingParams"
                  @immediate-submit-params="submitUIParams()"
                  @new-expression="nextExpression"
                ></methods>
              </v-card-text>
            </div>
            <v-divider></v-divider>
            <div class="d-flex pa-2 justify-center">
              <v-btn small @click="methodOverlay = false" text>close</v-btn>
              <v-btn small @click="submitUIParams()" text>submit</v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-overlay>
  </div>
</template>

<script>
import CodeMirror from "codemirror";
import "@/assets/styles/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/mode/python/python";
import "codemirror/addon/edit/matchbrackets";
import "codemirror/addon/edit/closebrackets";
import "codemirror/addon/hint/show-hint";

import AutoComplete from "@/components/expressions/AutoComplete.vue";
import MethodPreview from "@/components/expressions/MethodPreview.vue";
import ExpressionNest from "@/components/expressions/ExpressionNest.vue";
import Methods from "@/components/expressions/Methods.vue";

import { updateExpression } from "@/api/v2.js";

export default {
  name: "ExpressionEditor",

  components: {
    AutoComplete,
    MethodPreview,
    ExpressionNest,
    Methods,
  },

  props: {
    expr: Object,
    idx: { type: Number, required: true },
  },

  data() {
    return {
      cm: null, // Object to house codemirror instance
      savedExpression: null, // Provided by props, and updated on save
      expression: null,
      showNested: true,
      autocomplete: null,
      method: {
        key: null,
      },
      methodOverlay: false,
      stagingParams: null,
      saving: false,
    };
  },

  computed: {
    savingNeeded() {
      return this.savedExpression !== this.expression;
    },

    cursorPosition() {
      return this.cm.getCursor().ch;
    },

    globalCursorPosition() {
      const cursor = this.cm.getCursor();
      let globalCursor = 0;
      let line = 0;

      while (line !== cursor.line) {
        // Account for length of line plus newline char
        globalCursor += this.cm.getLine(line).length + 1;
        line++;
      }

      return globalCursor + cursor.ch;
    },
    currentMethod() {
      /*
      Collect a method name and parameters associated with a cursor position in the expression string
      */
      const parseToken = (token, cursorDelta) => {
        if (!token) return;
        const re = /^[a-z0-9_]+/gim;
        const match = re.exec(token.value.split("").reverse().join(""));
        if (match) {
          return {
            token: match[0].split("").reverse().join(""),
            start: token.end - match[0].length + cursorDelta,
            end: token.end + cursorDelta,
          };
        } else return;
      };

      const parseParams = (token) => {
        if (!token) return {};

        const step = (token, track) => {
          if (token[track.charIdx]) track.value += token[track.charIdx];
          track.charIdx++;
        };

        const traverse = (token, track) => {
          /*
          Traverse through all strings and nested bracket types
          */
          let traversed = false;

          if (["'", '"'].includes(token[track.charIdx])) {
            // Strings
            traversed = true;

            const quote = token[track.charIdx];

            // Step in
            step(token, track);

            while (token[track.charIdx] && token[track.charIdx] !== quote) {
              step(token, track);
            }
          } else if (["(", "[", "{"].includes(token[track.charIdx])) {
            // Brackets
            traversed = true;

            const opener = token[track.charIdx];
            const closer = {
              "(": ")",
              "[": "]",
              "{": "}",
            }[opener];

            // Step in
            step(token, track);

            while (token[track.charIdx] && token[track.charIdx] !== closer) {
              let traversed = traverse(token, track);
              if (!traversed) step(token, track);
            }
          }

          if (traversed) step(token, track);
          return traversed;
        };

        const parseParam = (param, paramDict, pos) => {
          if (!param) return;

          let track = {
            value: "",
            charIdx: 0,
          };
          let key, val;
          while (param[track.charIdx]) {
            traverse(param, track);

            if (
              param[track.charIdx] === "=" &&
              param[track.charIdx + 1] !== "=" &&
              param[track.charIdx - 1] !== "="
            ) {
              key = track.value;
              track.value = "";
              track.charIdx++;
              continue;
            }

            step(param, track);
          }

          val = track.value;
          if (!key) key = pos;

          if (val === "False") val = false;
          else if (val === "True") val = true;
          else if (val[0] === '"' || val[0] === "'") val = val.slice(1, -1);
          else if (!isNaN(parseFloat(val))) val = val.toString();

          paramDict[key] = val;
        };

        let paramDict = {};
        let track = {
          value: "",
          charIdx: 0,
        };
        let pos = 0;
        while (token[track.charIdx]) {
          traverse(token, track);

          // Skip white space
          while (token[track.charIdx] === " ") track.charIdx++;

          if (token[track.charIdx] === ",") {
            // Add to params and reset the tracker
            parseParam(track.value, paramDict, pos);
            pos++;
            track.value = "";
            track.charIdx++;
            continue;
          }

          // Increment
          step(token, track);
        }

        // Last one
        parseParam(track.value, paramDict, pos);

        return paramDict;
      };

      const splitBrackets = (s) => {
        /*
        Split a string into tokens and the contents between brackets
        */
        if (!s) return [];

        const traverseIfString = (token, track) => {
          if (["'", '"'].includes(track.char)) {
            let quote = track.char;
            track.value += track.char;

            track.charIdx++;
            track.char = token[track.charIdx];
            while (track.char && track.char !== quote) {
              track.value += track.char;
              track.charIdx++;
              track.char = token[track.charIdx];
            }
          }
        };

        let components = [];
        let start = 0;
        let track = {
          value: "",
          charIdx: 0,
          char: s[0],
        };
        while (track.char) {
          if (track.char === "(") {
            if (track.value) {
              components.push({
                name: "token",
                value: track.value,
                start,
                end: track.charIdx,
              });
              track.value = "";
              start = track.charIdx + 1;
            }

            // Traverse to the opposing bracket
            let closeCnt = 0;
            let closed = false;
            while (track.char && !closed) {
              track.charIdx++;
              track.char = s[track.charIdx];

              traverseIfString(s, track);

              if (track.char === "(") {
                closeCnt++;
              } else if (track.char === ")") {
                closeCnt--;
                closed = closeCnt < 0;
              }

              if (!closed) track.value += track.char;
            }

            components.push({
              name: "params",
              value: track.value ? track.value : null,
              start,
              end: track.charIdx,
            });
            track.value = "";
            start = track.charIdx + 1;
          }

          if (track.char !== ")") track.value += track.char;

          traverseIfString(s, track);

          track.charIdx++;
          track.char = s[track.charIdx];
        }

        if (track.value)
          components.push({
            name: "token",
            value: track.value,
            start,
            end: track.charIdx,
          });

        return components;
      };

      const bracketNest = (s, cursor, cursorDelta, method) => {
        /*
        Recursively traverse until the cursor's location in brackets is identified.
        Then collect the token before the brackets and the parameters between
        */
        const nextNest = splitBrackets(s);

        let token = null;
        nextNest.forEach((b) => {
          if (b.name === "token") token = b;
          else if (
            b.name === "params" &&
            cursor >= b.start &&
            cursor <= b.end
          ) {
            method.name = parseToken(token, cursorDelta);
            method.params = parseParams(b.value);
            method.start = b.start + cursorDelta;
            method.end = b.end + cursorDelta;

            bracketNest(
              b.value,
              cursor - b.start,
              b.start + cursorDelta,
              method
            );
          }
        });
      };

      // A mutable obj to record the last visited bracket in a nest
      let method = {
        name: null,
        params: {},
        start: null,
        end: null,
      };

      if (this.cm)
        bracketNest(this.cm.getValue(), this.globalCursorPosition, 0, method);

      if (method.name) {
        const _method = this.$store.getters["expressions/routeFromMethodName"](
          method.name.token
        );

        if (_method) method.component = _method.component;
      }

      return method;
    },
  },

  watch: {
    savingNeeded(newVal) {
      this.$emit("expr-modified", newVal);
    },
  },

  methods: {
    initialize() {
      this.cm = CodeMirror.fromTextArea(
        document.getElementById(`codemirrortextarea-${this.idx}`),
        {
          theme: "material",
          showCursorWhenSelecting: true,
          lineWrapping: true,
          autoCloseBrackets: true,
          matchBrackets: true,
          smartIndent: false,
          mode: "python",
          extraKeys: { Tab: this.keyupAutocomplete },
        }
      );

      this.cm.setValue(this.expression);

      this.cm.setSize(null, "400px");

      this.cm.on("change", (cm) => {
        this.expression = cm.getValue();
      });

      this.cm.on("cursorActivity", () => {
        this.updateActivity();
      });

      this.cm.refresh();
    },
    updateActivity() {
      // Detect cursor location and update view accordingly
      const token = this.cm.getTokenAt(this.cm.getCursor(true));
      const method = this.currentMethod;
      this.autocomplete = null;
      this.showNested = false;

      if (method.name) {
        this.method = { ...this.method, ...method };

        if (`s${method.name.start}e${method.name.end}` !== this.method.key) {
          // Newly clicked into method
          this.method.key = `s${method.name.start}e${method.name.end}`;
        } else {
          // Check for an autocomplete if in param value
          if (
            ["variable", "keyword", "operator"].includes(token.type) &&
            Object.values(method.params).some((p) => {
              return p === token.string;
            })
          ) {
            delete this.method.name;
            this.autocomplete = token.string;
          }
        }
        return;
      } else {
        this.method = {
          key: null,
        };
      }

      if (["variable", "keyword", "operator"].includes(token.type)) {
        this.autocomplete = token.string;
      } else {
        this.showNested = true;
      }
    },

    applyAutocomplete(ac) {
      // Autocomplete when autocomplete parameters are provided (from ExpressionAutocomplete or keyupAutocomplete)
      const cursor = this.cm.getCursor(true);
      const token = this.cm.getTokenAt(cursor);
      const completeText = ac.method.s + `${ac.method.noParams ? "" : "()"}`;

      this.cm.replaceRange(
        completeText,
        { line: cursor.line, ch: token.start },
        { line: cursor.line, ch: token.end }
      );

      if (!ac.method.noParams) {
        setTimeout(() => {
          let cursor = this.cm.getCursor(true);
          cursor.ch--;
          this.cm.setCursor(cursor);
        }, 50);
      }
    },

    keyupAutocomplete() {
      // Tab-based autocomplete functionality
      const params = this.$store.getters["expressions/autocompleteMatches"](
        this.autocomplete
      );
      if (Object.keys(params).length > 0) {
        const type = Object.keys(params)[0];
        const ac = {
          type: type,
          method: params[type][0],
        };
        this.applyAutocomplete(ac);
      }
    },

    nextExpression(exp) {
      this.$emit("next-expression", exp);
    },

    updateStagingParams(params) {
      this.stagingParams = params;
    },

    submitUIParams() {
      if (this.stagingParams) {
        // TODO: Should this update or replace parameters? (from devin?)
        const params = this.stagingParams;

        // Filter non-null
        let newParams = {};
        Object.keys(params).forEach((key) => {
          let value = params[key];
          if (value !== null && value !== undefined) {
            newParams[key] = params[key];
          }
        });

        // Cast types and create string
        const newParamString = Object.keys(newParams)
          .map((key) => {
            let paramValue = newParams[key];

            const floatNum = parseFloat(paramValue);
            if (!isNaN(floatNum)) {
              const intNum = parseInt(paramValue);
              if (floatNum === intNum) paramValue = intNum;
              else paramValue = floatNum;
            } else if (typeof paramValue === "string") {
              // Defined as new expression code
              if (paramValue.startsWith("<__code__>")) {
                paramValue = paramValue.slice(10);
              } else if (!(paramValue[0] === '"' || paramValue[0] === "'"))
                paramValue = "'" + paramValue + "'";
            } else if (Array.isArray(paramValue)) {
              let arrayString = paramValue.map((value) => `'${value}'`);

              paramValue = "[" + arrayString + "]";
            } else if (typeof paramValue === "boolean")
              paramValue = paramValue ? "True" : "False";

            return `${key}=${paramValue}`;
          })
          .join(", ");

        this.expression =
          this.expression.slice(0, this.currentMethod.start) +
          newParamString +
          this.expression.slice(this.currentMethod.end);
        this.cm.setValue(this.expression);
      }
      this.methodOverlay = false;
    },

    async saveExpr() {
      this.saving = true;

      try {
        const response = await updateExpression(
          this.expr.ExpressionName,
          this.expr.ExpressionOwner,
          this.expression
        );

        if (!response.Errors && !response.Warnings) {
          this.savedExpression = this.expression;
        }

        // save with warning
        else if (response.Warnings) {
          this.savedExpression = this.expression;
          this.$showAlert({
            text: `Expression was saved with warning:

            ${response.Warnings}`,
            type: "warning",
          });
        }
      } catch (errors) {
        if (errors.response.data.Errors) {
          this.$showAlert({
            text: errors.response.data.Errors,
            type: "error",
          });
        } else {
          this.$showAlert({
            text: errors,
            type: "error",
          });
        }
      } finally {
        this.saving = false;
      }
    },
  },

  beforeMount() {
    // Update from props
    if (!this.expr.Expression) {
      this.savedExpression = "";
      this.expression = "";
    } else {
      this.savedExpression = this.expr.Expression;
      this.expression = this.expr.Expression;
    }
  },

  mounted() {
    this.initialize();
  },
};
</script>

<style scoped>
.method-card {
  overflow: auto;
  max-height: 500px;
}
.bottom {
  height: 400px;
  overflow: auto;
}
</style>

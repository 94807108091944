<template>
  <v-card
    id="properties-window"
    class="mx-auto"
    min-width="340"
    max-width="600"
  >
    <v-system-bar window class="flex justify-center">
      <h3 class="mx-auto">Dataset Properties</h3>
      <v-icon id="bar-icon" color="error" @click="closeWindow"
        >mdi-close</v-icon
      >
    </v-system-bar>

    <div id="window-content" class="d-flex">
      <div id="nav-drawer-container">
        <v-navigation-drawer
          id="dataset-nav-drawer"
          v-model="drawer"
          :mini-variant="$vuetify.breakpoint.xsOnly"
          mini-variant-width="58"
          permanent
          width="150"
        >
          <v-list nav dense>
            <v-list-item-group
              v-model="group"
              active-class="black--text text--accent-4"
            >
              <v-list-item
                v-for="(item, index) in navItems"
                :key="item.title"
                :disabled="index === group"
                class="d-flex"
              >
                <v-list-item-icon class="mr-2">
                  <v-tooltip bottom nudge-right="10">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">{{ item.icon }}</v-icon>
                    </template>
                    <span>{{ item.title }}</span>
                  </v-tooltip>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </div>

      <!-- nav components -->
      <v-card-text id="nav-content">
        <dataset-properties-information
          v-if="group === 0 && fullDataset"
          :dataset="limitedDataset"
          :full-dataset="fullDataset"
          :dataset-files="datasetFiles"
          :edit-permission="hasEditPermissions"
          @update-dataset-info="updateInfo"
        >
        </dataset-properties-information>
        <dataset-properties-layers
          v-if="group === 1 && fullDataset"
          :full-dataset="fullDataset"
          :edit-permission="hasEditPermissions"
          :pre-select-layer-name="preSelectLayerName"
          @update-dataset-layer-list="updateDatasetLayerList"
        >
        </dataset-properties-layers>
        <dataset-properties-files
          v-if="group === 2 && fullDataset"
          :dataset="limitedDataset"
          :full-dataset="fullDataset"
          :dataset-files="datasetFiles"
          :edit-permission="hasEditPermissions"
        >
        </dataset-properties-files>
        <dataset-properties-upload-logs
          v-if="group === 3"
          :dataset="limitedDataset"
        >
        </dataset-properties-upload-logs>
        <dataset-properties-symbology
          v-if="group === 4"
          :full-dataset="fullDataset"
          :edit-permissions="hasEditPermissions"
          @update-style-object="updateFullDatasetColormaps"
        >
        </dataset-properties-symbology>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import DatasetPropertiesInformation from "@/components/datasets/DatasetPropertiesInformation.vue";
import DatasetPropertiesLayers from "@/components/datasets/DatasetPropertiesLayers.vue";
import DatasetPropertiesFiles from "@/components/datasets/DatasetPropertiesFiles.vue";
import DatasetPropertiesUploadLogs from "@/components/datasets/DatasetPropertiesUploadLogs.vue";
import DatasetPropertiesSymbology from "@/components/datasets/DatasetPropertiesSymbology.vue";
import { getDataset } from "@/api/v2.js";

export default {
  name: "DatasetProperties",

  components: {
    DatasetPropertiesInformation,
    DatasetPropertiesLayers,
    DatasetPropertiesFiles,
    DatasetPropertiesUploadLogs,
    DatasetPropertiesSymbology,
  },

  props: {
    dataset: { type: Object, default: () => {} },
    startNavItem: { type: Number, default: () => {} },
    preSelectLayerName: { type: String, default: "Default" },
    // TODO:
    // isMapLayer: { type: Boolean, default: false },
  },

  data() {
    return {
      datasetLoading: false,
      drawer: false,
      navItems: [
        { title: "Information", icon: "mdi-information" },
        { title: "Layers", icon: "mdi-layers" },
        { title: "Files", icon: "mdi-folder-information" },
        { title: "Upload Logs", icon: "mdi-clipboard-text-clock-outline" },
        { title: "Symbology", icon: "mdi-palette" },
      ],
      group: null,
      limitedDataset: this.dataset,
      fullDataset: null,
      datasetFiles: null,
      hasEditPermissions: false,
    };
  },

  watch: {
    group() {
      this.drawer = false;
    },
  },

  methods: {
    updateInfo(updatedDetails) {
      this.limitedDataset = { ...this.limitedDataset, ...updatedDetails };
      this.$store.commit("accountData/updateDataset", this.limitedDataset);
    },
    updateDatasetLayerList(updatedList) {
      this.fullDataset.FilterFieldValues = updatedList;
    },
    closeWindow() {
      this.$emit("close-properties");
    },
    checkEditPermissions() {
      const hasPermissions = this.$store.getters[
        "account/checkEditPermissions"
      ](this.fullDataset.Tags);

      this.hasEditPermissions = hasPermissions;
    },
    updateFullDatasetColormaps(colormaps) {
      this.fullDataset.Style.Colormap = colormaps;
    },

    getDataset() {
      getDataset(this.dataset.DatasetName, this.dataset.DatasetOwner)
        .then((response) => {
          this.fullDataset = response;
          this.checkEditPermissions();
          this.datasetFiles = response.Files;

          if (response.Warnings) {
            this.$showAlert({
              text: response.Warnings,
              type: "warning",
            });
          }
        })
        .catch((error) => {
          if (error) {
            this.$showAlert({
              text: error,
              type: "error",
            });
          }
          if (error.response.data.Errors) {
            this.$showAlert({
              text: error.response.data.Errors,
              type: "error",
            });
          }
        })
        .finally(() => {
          this.datasetLoading = false;
        });
    },
  },

  created() {
    this.group = 0;
    this.datasetLoading = true;
    this.getDataset();
  },

  mounted() {
    if (!this.startNavItem) {
      this.group = 0;
    } else {
      this.group = this.startNavItem;
    }
  },
};
</script>

<style scoped>
#properties-window {
  overflow: hidden;
}

#nav-drawer-container {
  min-width: 58px;
}
#dataset-nav-drawer {
  top: 32px !important;
  height: 768px !important;
}
#nav-content {
  width: 480px;
  overflow-y: scroll;
  height: 768px !important;
}

#bar-icon {
  position: absolute;
  right: 0;
}
</style>

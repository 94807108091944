<template>
  <v-card min-width="340" max-width="600" max-height="500" v-if="fullDataset">
    <v-card-title :style="`background-color: #e0e0e0;`" class="mb-2">
      {{ layer ? "Layer Information" : "Dataset Information" }}
    </v-card-title>
    <v-card-text v-if="layer">
      <!-- TypeOfData -->
      <v-card-subtitle class="property-headers"> Type </v-card-subtitle>
      <v-row class="py-2 my-0" justify="end">
        <v-col cols="12" sm="7" class="pt-0 d-flex justify-center">
          <p>{{ layer.TypeOfData }}</p>
        </v-col>
      </v-row>
      <!-- Details -->
      <v-card-subtitle class="property-headers"> Units </v-card-subtitle>
      <v-row class="py-2 my-0" justify="end">
        <v-col cols="12" sm="7" class="pt-0 d-flex justify-center">
          <p>{{ layer.Units }}</p>
        </v-col>
      </v-row>
      <v-card-subtitle class="property-headers">
        Layer Description
      </v-card-subtitle>
      <p>{{ layer.Description }}</p>

      <v-card-subtitle class="property-headers">
        Dataset Description
      </v-card-subtitle>
      <p>{{ fullDataset.Description }}</p>

      <v-card-subtitle class="property-headers"> Citation </v-card-subtitle>
      <p>
        {{ fullDataset.CitationRIS }}
      </p>
    </v-card-text>
    <v-card-text v-else>
      <!-- TypeOfData -->
      <v-card-subtitle class="property-headers"> Dataset Type </v-card-subtitle>
      <v-row class="py-2 my-0" justify="end">
        <v-col cols="12" sm="7" class="pt-0 d-flex justify-center">
          <p>{{ fullDataset.DatasetType }}</p>
        </v-col>
      </v-row>
      <!-- Details -->
      <v-card-subtitle class="property-headers"> Units </v-card-subtitle>
      <v-row class="py-2 my-0" justify="end">
        <v-col cols="12" sm="7" class="pt-0 d-flex justify-center">
          <p>{{ fullDataset.Units }}</p>
        </v-col>
      </v-row>

      <v-card-subtitle class="property-headers">
        Dataset Description
      </v-card-subtitle>
      <p>{{ fullDataset.Description }}</p>
      <v-card-subtitle class="property-headers"> Citation </v-card-subtitle>
      <p>
        {{ fullDataset.CitationRIS }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import { getDataset } from "@/api/v2.js";

export default {
  props: {
    dataset: {
      type: Object,
    },
    layer: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      fullDataset: null,
      datasetLoading: false,
    };
  },

  computed: {},

  methods: {
    getDataset(datasetName, datasetOwner) {
      getDataset(datasetName, datasetOwner)
        .then((response) => {
          this.fullDataset = response;

          if (response.Warnings) {
            this.$showAlert({
              text: response.Warnings,
              type: "warning",
            });
          }
        })
        .catch((error) => {
          if (error) {
            this.$showAlert({
              text: error,
              type: "error",
            });
          }
          if (error.response.data.Errors) {
            this.$showAlert({
              text: error.response.data.Errors,
              type: "error",
            });
          }
        })
        .finally(() => {
          this.datasetLoading = false;
        });
    },
  },

  created() {
    this.datasetLoading = true;
    this.getDataset(this.dataset.DatasetName, this.dataset.DatasetOwner);
  },
};
</script>

<style scoped>
.property-headers {
  color: rgba(0, 0, 0, 1);
  padding: 6px 0;
}
</style>

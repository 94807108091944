<template>
  <div>
    <expression-selector
      :selection-mode="true"
      :single-select="true"
      :batch-select="false"
      :crud-actions="false"
      @new-expression="$emit('new-expression')"
      @expression-selected="update"
    ></expression-selector>
  </div>
</template>

<script>
import ExpressionSelector from "@/components/data/ExpressionSelector.vue";

export default {
  name: "Expression",

  components: {
    ExpressionSelector,
  },

  props: { params: Object },

  data() {
    return {
      expression: null,
      expressions: [],
      modelParams: {
        name: null,
        // cat: null,
      },
    };
  },

  // TODO:test loading expression from expression method
  methods: {
    update(expression) {
      if (expression) {
        this.modelParams.name = `"${expression.ExpressionName}"`;
        // this.modelParams.cat = `"${expression.cat}"`;
        this.$emit("submit-params", this.modelParams);
        this.$emit("immediate-submit-params");
      }
    },
  },

  beforeMount() {
    this.modelParams = { ...this.modelParams, ...this.params };
  },
};
</script>

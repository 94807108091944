<template>
  <v-menu v-model="showMenu" :close-on-content-click="false" top offset-x>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        small
        v-bind="attrs"
        v-on="on"
        @click="getSaveableSymbology"
        :disabled="!enabled"
      >
        <v-icon>mdi-content-save-cog</v-icon></v-btn
      >
    </template>
    <v-card class="save-symbology-menu" max-width="400">
      <v-card-title class="text-h6"> Save new symbology</v-card-title>
      <v-card-subtitle class="text-caption pt-2">
        Symbology states can be saved to the dataset and managed from within the
        "Symbology" tab in the dataset properties window. Symbology can be
        applied to a dataset's layer from within the "Layers" tab.
      </v-card-subtitle>
      <v-form ref="form" v-model="valid">
        <v-card-text class="d-flex">
          <v-text-field
            v-model="symbologyName"
            dense
            label="Name"
            :rules="[rules.required]"
            maxlength="40"
          >
          </v-text-field>
        </v-card-text>
      </v-form>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          text
          @click="saveSymbology"
          :disabled="!valid"
          :loading="loading"
          >{{
            this.newSymbology.Name === this.symbologyName
              ? "Update & Save"
              : "Save as New"
          }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "MapLayerSaveSymbology",

  props: {
    dataset: { type: Object, required: true },
    newSymbology: { type: Object, required: true },
    savedSymbology: { type: Array, default: () => [] },
    enabled: { type: Boolean, default: true },
  },

  data() {
    return {
      symbologyName: "",
      showMenu: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      valid: true,
      loading: false,
    };
  },

  watch: {
    newSymbology(symbology) {
      this.symbologyName = symbology.Name;
    },
  },

  computed: {
    existingSymbologyNames() {
      return this.dataset.dataset.Symbology;
    },
  },

  methods: {
    async saveSymbology() {
      this.loading = true;
      const symbology = { ...this.newSymbology };

      symbology.Name = this.symbologyName;

      try {
        await this.$store.dispatch("mapping/updateDatasetSymbology", symbology);
      } finally {
        this.loading = false;
        this.showMenu = false;
      }
    },

    getSaveableSymbology() {
      this.$emit("get-symbology");
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          icon
          small
          :disabled="disabled"
          @click="statsLayerOverlay = !statsLayerOverlay"
        >
          <v-icon>mdi-numeric</v-icon>
        </v-btn>
      </template>
      {{
        disabled ? "No associated statistics" : "View layer statistics table"
      }}
    </v-tooltip>
    <v-dialog max-width="1000" v-model="statsLayerOverlay" scrollable>
      <v-card
        light
        v-if="
          (this.fullResolutionStatsAvailable ||
            this.lowResolutionStatsAvailable) &&
          this.groupFeaturesBy === 'none'
        "
        class="pa-4 card-container"
      >
        <div v-if="!this.fullResolutionStatsAvailable">
          <v-subheader class="px-4 pb-4"
            >Note: Statistics shown are an approximation based on a lower
            resolution sample of the image. Actual statistics are now being
            calculated and should update within 5 - 30 mins depending on the
            size of the study area.
          </v-subheader>
          <v-divider></v-divider>
          <v-subheader
            v-if="this.statsAttempts > 15"
            class="text--red px-4 pt-4"
            >There seems to be an issue with calculating the statistics. Please
            check again later. If this message persists, contact an
            administrator.
          </v-subheader>
        </div>

        <v-card-text class="data-table-container px-0">
          <v-data-table
            fixed-header
            height="70vh"
            :items-per-page="15"
            :headers="headers"
            :items="filteredFileStats ? filteredFileStats : preparedFileStats"
            dense
          >
            <!-- This template looks for headers with formatters and executes them -->
            <template
              v-for="header in headers.filter((header) =>
                header.hasOwnProperty('formatter')
              )"
              v-slot:[`item.${header.value}`]="{ item }"
            >
              <template v-if="header.hasOwnProperty('formatter')">
                {{ header.formatter(item[header.value]) }}
              </template>
              <template v-else>
                {{ item[header.value] }}
              </template>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <v-card
        light
        v-else-if="
          this.featureStatsAvailable && this.groupFeaturesBy !== 'none'
        "
        class="pa-4"
      >
        <v-data-table
          :items-per-page="15"
          :headers="featureStatsHeaders"
          :items="preparedFeatureStats"
        >
          <!-- <template v-slot:top>
            <div
              class="ml-auto d-flex align-center justify-end"
              style="font-size: small"
            >
              <span class="mb-0 pr-1" style="font-weight: bolder"
                >Summarize by:</span
              >
              <select
                class="px-2"
                style="
                  background-color: lightblue;
                  max-width: 8.25em;
                  border: 1px solid black;
                  border-radius: 10%;
                "
                v-model="summarizeDisplayValuesBy"
              >
                <option disabled value="">-</option>
                <option>Sum</option>
                <option>Average</option>
              </select>
            </div>
          </template> -->
        </v-data-table>
      </v-card>

      <v-card
        v-else
        light
        flat
        class="d-flex pa-2 justify-center align-center"
        height="350"
      >
        <v-card-title class="grey--text">
          {{ statsMessage }}
        </v-card-title>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getDatasetLayerStats } from "@/api/geoprocessing.js";
import { formatNumber } from "@/helpers/formatting.js";

export default {
  name: "MapDrawerLayerStats",

  props: {
    flowLayer: { type: Object, default: null },
    disabled: { type: Boolean, default: false },
    rebuildStats: { type: Boolean, default: false },
  },

  data() {
    return {
      statsLayerOverlay: false,
      statisticsUpdating: false,
      preparedFileStats: [],
      featureStatsAvailable: false,
      featureStatsAttempts: 0,
      preparedFeatureStats: [],
      filteredFileStats: [],
      statsMessage: "Stats unavailable at this time. Please try again later.",
      errorMessage: null,
      areaUnits: "km2",
      statsIntervalId: null,
      statsAttempts: 0,
      summarizeDisplayValuesBy: "",
    };
  },

  computed: {
    headers() {
      let allHeaders = [];
      if (
        this.lowResolutionStatsAvailable ||
        this.fullResolutionStatsAvailable
      ) {
        const defaultHeaders = [
          { text: "Time Min.", value: "start" },
          { text: "Time Max.", value: "end" },
          {
            text: "Representative Precision (m)",
            value: "representativePrecision",
          },
        ];

        let categories = [];
        let uniqueValues = [];
        let formattedCategoryHeaders = [];
        let defaultContinuousHeaders = [
          {
            text: "Min.",
            value: "min",
            formatter: (x) => this.formatNumber(x),
          },
          {
            text: "Max.",
            value: "max",
            formatter: (x) => this.formatNumber(x),
          },
          {
            text: "Mean",
            value: "mean",
            formatter: (x) => this.formatNumber(x),
          },
          {
            text: "Sum",
            value: "sum",
            formatter: (x) => this.formatNumber(x),
          },
          {
            text: "Standard Deviation",
            value: "standardDev",
            formatter: (x) => this.formatNumber(x),
          },
        ];

        if (this.flowLayer.layer.TypeOfData === "Categorical") {
          this.flowLayer.files.forEach((file) => {
            const savedStats = file.stats.fullResolutionStats
              ? file.stats.fullResolutionStats
              : file.stats.lowResolutionStats;
            const categoryHeaders = this.uniqueCategories(
              savedStats.CategoryNames
            );
            categories.push(categoryHeaders);
          });

          uniqueValues = [...new Set(Object.values(categories.flat()))].sort();

          formattedCategoryHeaders = uniqueValues.map((value) => ({
            text: `${value}(${this.areaUnits})`,
            value: `${value}`,
            formatter: (x) => this.formatNumber(x),
          }));
        }

        if (this.flowLayer.layer.TypeOfData === "Continuous") {
          formattedCategoryHeaders = defaultContinuousHeaders;
        }

        allHeaders = [...defaultHeaders, ...formattedCategoryHeaders];
      }
      return allHeaders;
    },

    studyAreas() {
      return this.$store.state.mapping.datasets.filter(
        (dataset) => dataset.mapLayerPurpose === "StudyAreaMapLayer"
      );
    },

    queryBuilderData() {
      return this.$store.state.mapping.queryBuilderData;
    },

    fullResolutionStatsAvailable() {
      return this.flowLayer.files.every(
        (file) => file.stats.fullResolutionStats !== null
      );
    },

    lowResolutionStatsAvailable() {
      return this.flowLayer.files.every(
        (file) => file.stats.lowResolutionStats !== null
      );
    },

    fullResolutionStatsLastUpdated() {
      let lastUpdated = null;
      if (this.fullResolutionStatsAvailable && !this.statisticsUpdating) {
        const allStatTimes = this.flowLayer.files.map((file) => {
          return file.stats.fullResolutionStatsLastUpdated?.toString();
        });

        const uniqueTimes = new Set(allStatTimes);

        const singleTime = [...uniqueTimes];

        lastUpdated = singleTime[0];
      }
      return lastUpdated;
    },

    lowResolutionStatsLastUpdated() {
      const allStatTimes = this.flowLayer.files.map((file) =>
        file.stats.lowResolutionStatsLastUpdated.toString()
      );

      const uniqueTimes = new Set(allStatTimes);

      const lastUpdated = [...uniqueTimes];

      return lastUpdated[0];
    },

    fileAttributeTablesAvailable() {
      return this.flowLayer.files.every(
        (file) => file.fileAttributeTable !== null
      );
    },

    visibleFeatures() {
      return this.flowLayer.visibleFeatures;
    },

    hiddenCategories() {
      return this.flowLayer.hiddenCategories;
    },

    groupFeaturesBy() {
      let defaultGroupBy = "";

      if (this.flowLayer.groupFeaturesBy) {
        defaultGroupBy = this.flowLayer.groupFeaturesBy;
      }

      return defaultGroupBy;
    },

    featureStatsHeaders() {
      let allHeaders = [];
      if (this.featureStatsAvailable) {
        const defaultHeaders = [
          { text: "Time Min.", value: "start" },
          { text: "Time Max.", value: "end" },
          {
            text: "Representative Precision (m)",
            value: "representativePrecision",
          },
        ];

        let visibleFeatures = this.visibleFeatures;

        let formattedFeatureHeaders = visibleFeatures.map((value) => ({
          text: `${value} (sum)`,
          value: value,
          formatter: (x) => {
            this.formatNumber(x);
          },
        }));

        allHeaders = [...defaultHeaders, ...formattedFeatureHeaders];
      }

      return allHeaders;
    },
  },
  watch: {
    fullResolutionStatsAvailable(newValue, oldValue) {
      if (oldValue && !newValue) {
        // first try
        this.calculateFullStatistics();
      }
    },

    lowResolutionStatsLastUpdated(newDate) {
      if (newDate !== null || newDate !== undefined) {
        this.prepareFileStats();
      }
    },

    fullResolutionStatsLastUpdated(newDate) {
      if (newDate !== null || newDate !== undefined) {
        this.prepareFileStats();
      }
    },

    fileAttributeTablesAvailable(newValue) {
      if (newValue) {
        this.prepareFeatureStats();
      }
    },

    visibleFeatures(featureArray) {
      if (featureArray.length > 0) {
        // prepare featureStats
        this.prepareFeatureStats();
      }
    },
    hiddenCategories(updatedHiddenCategoryList) {
      const filteredFileLists = this.preparedFileStats.map((file) => {
        return Object.keys(file)
          .filter(
            (categoryKey) => !updatedHiddenCategoryList.includes(categoryKey)
          )
          .reduce((object, categoryKey) => {
            return Object.assign(object, {
              [categoryKey]: file[categoryKey],
            });
          }, {});
      });

      this.filteredFileStats = filteredFileLists;
    },
  },
  methods: {
    formatNumber: (x) => (typeof x === "number" ? formatNumber(x) : "no data"),

    uniqueCategories(categories) {
      let availableHeaders = new Set();
      for (var i = 0; i < categories.length; i++) {
        availableHeaders.add(categories[i]);
      }
      return [...availableHeaders];
    },

    prepareFileStats() {
      const data = this.flowLayer.files.map((file) => {
        const savedStats = file.stats.fullResolutionStats
          ? file.stats.fullResolutionStats
          : file.stats.lowResolutionStats;

        const defaultData = {
          start: file.time.start,
          end: file.time.end,
          representativePrecision: savedStats.RepresentativePrecision,
        };

        let formattedData = {};

        // categorical
        if (this.flowLayer.layer.TypeOfData === "Categorical") {
          formattedData = this.formatCategoryData(
            savedStats.CategoryNames,
            savedStats.CategoryAreas
          );
        }

        // continuous
        else if (this.flowLayer.layer.TypeOfData === "Continuous") {
          formattedData = {
            min: savedStats.Min,
            max: savedStats.Max,
            mean: savedStats.Mean,
            sum: savedStats.Sum,
            standardDev: savedStats.StandardDeviation,
          };
        }

        return {
          ...defaultData,
          ...formattedData,
        };
      });

      this.preparedFileStats = data;
      this.$store.commit("mapping/updateDataset", this.flowLayer);
    },

    prepareFeatureStats() {
      let data = this.flowLayer.files.map((file) => {
        const savedStats = file.fileAttributeTable.formattedTable;
        const groupBy = this.groupFeaturesBy;
        const valueField = this.flowLayer.layer.ValueField;

        const defaultData = {
          start: file.time.start,
          end: file.time.end,
          representativePrecision: null,
        };

        let formattedData = {};
        let count = {};

        // group feature values together, find sum of like features as default
        for (let i = 0; i < savedStats.length; i++) {
          const keyName = savedStats[i][groupBy];

          if (Object.hasOwn(formattedData, keyName)) {
            formattedData[keyName] += savedStats[i][valueField];
            count[keyName] += 1;
          } else {
            formattedData[keyName] = savedStats[i][valueField];
            count[keyName] = 1;
          }
        }

        if (Object.hasOwn(formattedData, "")) {
          formattedData[`Unnamed ${groupBy}`] = formattedData[""];
          delete formattedData[""];
        }

        const combinedData = {
          ...defaultData,
          ...formattedData,
        };

        file.stats.featureValueStats = combinedData;

        return combinedData;
      });

      // update file stats object
      this.$store.commit("mapping/updateDataset", this.flowLayer);
      this.preparedFeatureStats = data;
      this.featureStatsAvailable = true;
    },

    formatCategoryData(categories, values) {
      const data = {};
      for (let i = 0; i < categories.length; i++) {
        const keyName = `${categories[i]}`;
        data[keyName] = this.convertSqmToSkm(values[i]);
      }
      return data;
    },

    convertSqmToSkm(value) {
      let km2 = value / 1000000;
      let newValue = 0;
      if (km2 === 0.0) newValue = 0;
      if (km2 <= 0.01) newValue = parseFloat(km2.toPrecision(4));
      else newValue = parseFloat(km2.toFixed(2));
      return newValue;
    },

    convertSqmToHa(value) {
      let newValue = 0;
      if (value !== 0.0) newValue = (value / 10000).toFixed(2);
      return newValue;
    },

    useQueryLayerMasks() {
      const isQueryLayer = (queryBuilderData) =>
        queryBuilderData.find(
          (dataset) => dataset.originalLayer.key === this.flowLayer.key
        );

      return isQueryLayer(this.$store.state.mapping.queryBuilderData) !==
        undefined
        ? this.$store.state.mapping.queryBuilderLayerMasks.map(
            (mask) => mask.dataLayer
          )
        : [];
    },

    async calculateFullStatistics() {
      this.statisticsUpdating = true;
      this.statsAttempts += 1;

      const copyOfFlowLayer = structuredClone(this.flowLayer);

      const datasetStatsPromise = Promise.allSettled(
        copyOfFlowLayer.files.map(async (file) => {
          const datasetInfo = this.flowLayer.layer;

          const copyOfFile = structuredClone(file);

          if (file.time.start !== "ind") {
            datasetInfo.date = file.time.start;
          }

          let studyAreaInfo = [];

          if (
            this.studyAreas.length > 0 &&
            this.flowLayer.mapLayerPurpose !== "StudyAreaMapLayer"
          ) {
            studyAreaInfo.push(this.studyAreas[0].layer);
          }

          // try retrieving full resolution statistics for file
          let fullResolutionStats = null;

          const useQueryLayers = this.useQueryLayerMasks();

          const stats = await getDatasetLayerStats(
            { ...datasetInfo },
            [...studyAreaInfo],
            useQueryLayers.map((maskLayer) => maskLayer.layer),
            file.time.start === "ind" &&
              useQueryLayers.some((maskLayer) => maskLayer.layer.Timestep)
              ? useQueryLayers[0].files[0].time.start
              : null,
            25,
            4
          );

          if (!stats.Errors && !stats.Warnings) {
            fullResolutionStats = stats;

            // update full resolution stats in file
            copyOfFile.stats.fullResolutionStatsLastUpdated = new Date();
            copyOfFile.stats.fullResolutionStats = fullResolutionStats;
          }

          return copyOfFile;
        })
      );

      const datasetStatsStatues = await datasetStatsPromise;

      // check if any of the returned stats have errors or have not completed yet
      const allStatsAvailable = datasetStatsStatues.every(
        (promise) => promise.status === "fulfilled"
      );

      if (allStatsAvailable) {
        // cancel stats interval
        this.clearStatsInterval();
        this.statisticsUpdating = false;
        this.statsAttempts = 0;

        copyOfFlowLayer.files = datasetStatsStatues.map(
          (fileStatus) => fileStatus.value
        );

        this.$store.commit("mapping/updateDataset", copyOfFlowLayer);
      } else {
        if (this.statsIntervalId === null)
          this.statsIntervalId = setInterval(
            this.calculateFullStatistics(),
            60000
          );
      }
    },

    clearStatsInterval() {
      // clear intervals for any stats being calculated.
      clearInterval(this.statsIntervalId);
      this.statsIntervalId = null;
    },
  },

  created() {
    if (this.flowLayer.mapLayerPurpose !== "StudyAreaMapLayer") {
      this.prepareFileStats();
    }
  },

  mounted() {
    this.calculateFullStatistics();
  },

  beforeDestroy() {
    // clear intervals for any stats being calculated.
    this.clearStatsInterval();
  },
};
</script>

<style scoped>
.card-container {
  overflow: hidden;
}

::v-deep .v-data-table__wrapper {
  margin-bottom: 2em;
}

::v-deep .v-data-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  background: white;
}
</style>

<template>
  <div>
    <div class="d-flex justify-end px-4 align-center">
      <p v-if="statusLastUpdated" class="mb-0">
        Last updated: {{ formatDateString(statusLastUpdated) }}
      </p>
      <v-btn
        :disabled="
          scenario.Status === 'Failed' || scenario.Status === 'Done'
            ? true
            : false
        "
        @click="updateStatus(scenario)"
        icon
        :loading="statusLoading"
      >
        <v-icon color="black">mdi-refresh</v-icon>
      </v-btn>
    </div>

    <v-stepper
      alt-labels
      flat
      :value="getStatusIndex(scenario.Status)"
      class="pa-md-6"
    >
      <v-stepper-header>
        <v-stepper-step
          step="1"
          :complete="getStatusIndex(scenario.Status) > 1"
        >
          Pending
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          step="2"
          :complete="getStatusIndex(scenario.Status) > 2"
        >
          Provisioning
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          step="3"
          :complete="getStatusIndex(scenario.Status) > 3"
          :rules="[() => scenario.Status !== 'Failed']"
          error-icon="mdi-alert-octagon"
        >
          In Progress
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          step="4"
          :complete="getStatusIndex(scenario.Status) >= 4"
          :rules="[() => scenario.Status !== 'Failed']"
          error-icon="mdi-close-circle"
          :color="checkStatus(scenario.Status)"
        >
          {{ scenario.Status === "Failed" ? "Failed" : "Done" }}
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>

    <div class="d-flex align-center px-6">
      <v-subheader>Progress</v-subheader>
      <v-progress-linear :value="scenario.Progress" height="25">
        <strong>{{ Math.round(scenario.Progress) }}%</strong>
      </v-progress-linear>
    </div>
  </div>
</template>

<script>
import { formatDateString } from "@/api/v2";
// import { getRunStatus, formatDateString } from "@/api/v2";
export default {
  props: {
    simulation: Object,
  },

  data() {
    return {
      statusLoading: false,
      statusLastUpdated: null,
    };
  },

  computed: {
    scenario() {
      return this.simulation;
    },
  },

  methods: {
    formatDateString,
    checkStatus(status) {
      if (status === "Done") {
        return "success";
      } else if (status === "Failed") {
        return "red";
      } else if (status === "In Progress") {
        return "blue";
      } else if (status === "Provisioning") {
        return "blue";
      } else if (status === "Pending") {
        return "cyan";
      } else {
        return "yellow";
      }
    },

    getStatusIndex(status) {
      if (status === null) {
        return 0;
      } else if (status === "Pending") {
        return 1;
      } else if (status === "Provisioning") {
        return 2;
      } else if (status === "In Progress") {
        return 3;
      } else {
        return 4;
      }
    },

    async updateStatus(scenario) {
      this.statusLoading = true;
      await this.$store.dispatch("project/updateSimulationStatus", scenario);
      this.statusLastUpdated = new Date();
      this.statusLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <v-dialog v-model="loader" overlay-opacity="0" persistent max-width="300">
    <v-card :color="appColor" :dark="isDark" id="loading-card">
      <v-card-title
        class="text-subtitle-1"
        id="main-message"
        v-html="loader.title"
      >
      </v-card-title>
      <div class="progress-container px-5">
        <v-progress-linear
          :indeterminate="!loader.currentStepValue"
          height="5"
          :color="isDark ? 'teal' : 'blue-grey'"
          :value="loader.currentStepValue"
        ></v-progress-linear>
      </div>
      <v-card-text
        class="text-body-2 pt-2"
        v-html="loader.updateMessage"
      ></v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TheLoadingOverlay",

  computed: {
    appColor() {
      return this.$store.state.settings.appColor;
    },
    isDark() {
      return this.$store.getters["settings/isDark"];
    },
    loader() {
      if (this.$store.state.loadingOverlay.loader) {
        return this.$store.state.loadingOverlay.loader;
      } else {
        return {
          title: "Loading, please wait",
          updateMessage: "This should just take a few moments..",
          timeEstimate: null,
          currentStepValue: null,
        };
      }
    },
  },

  mounted() {
    if (this.loader.timeEstimate) {
      setTimeout(() => {
        this.$store.commit("loadingOverlay/resetLoader");
      }, this.loader.timeEstimate);
    }
  },
};
</script>

<style scoped>
#loading-card {
  white-space: break-spaces;
}

#main-message {
  word-break: normal !important;
}
</style>

<template>
  <div class="px-5 pb-1">
    <!-- :TODO add expansion panel with each dataset? or alternative  -->
    <v-data-table
      height="300px"
      :items="userList"
      :headers="userHeaders"
      group-by="category"
      item-key="user"
      hide-default-footer
      disable-pagination
      :loading="isLoading"
      loading-text="Loading user permissions... Please wait"
    >
      <template v-slot:item.user="{ item }">
        {{ item.user }}
        <div class="d-inline">
          <v-progress-circular
            class="ml-6"
            v-if="requestingPermissions.includes(item.user)"
            :size="16"
            :width="2"
            color="blue"
            indeterminate
          ></v-progress-circular>
        </div>
      </template>
      <template v-slot:item.view="{ item }">
        <v-checkbox
          v-model="item.view"
          :disabled="
            item.edit ||
              item.user === $store.state.account.user.attributes.email
          "
          @change="modifyPermissions(item, 'view')"
          :color="$store.state.settings.appColor"
        >
        </v-checkbox>
      </template>
      <template v-slot:item.edit="{ item }">
        <v-checkbox
          v-model="item.edit"
          :disabled="item.user === $store.state.account.user.attributes.email"
          @change="modifyPermissions(item, 'edit')"
          :color="$store.state.settings.appColor"
        >
        </v-checkbox>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { getUserList } from "@/api/v2.js";

export default {
  name: "DataShareUserList",

  props: {
    userPermissions: { type: Array, default: () => [] },
    requestingPermissions: { type: Array, default: () => [] },
    selectedData: { type: Array, default: () => [] },
  },

  data() {
    return {
      isLoading: false,
      userList: [],
      userPermissionLoading: null,
    };
  },

  computed: {
    // adds permission fields to each user
    userHeaders() {
      let headers = [
        {
          text: "User",
          align: "start",
          sortable: true,
          value: "user",
          groupable: true,
        },

        { text: "View", sortable: true, value: "view" },
        { text: "Edit", sortable: true, value: "edit" },
        {
          text: "Category",
          sortable: true,
          value: "category",
        },
      ];

      return headers;
    },
  },

  methods: {
    modifyPermissions(userPermission, checkboxModified) {
      this.userPermissionLoading = userPermission;
      let userList = this.userList;
      if (checkboxModified === "edit") {
        if (userPermission.edit) {
          // preselect view send add edit tag
          if (userPermission.edit && !userPermission.view) {
            userPermission.view = !userPermission.view;
          }
          // send add edit tag
          this.$emit(
            "add-permission",
            userPermission,
            checkboxModified,
            userList
          );
        } else {
          // send remove edit tag
          this.$emit(
            "remove-permission",
            userPermission,
            checkboxModified,
            userList
          );
        }
      } else if (checkboxModified === "view") {
        if (userPermission.view) {
          // send add view tag
          this.$emit(
            "add-permission",
            userPermission,
            checkboxModified,
            userList
          );
        } else {
          // send remove view tag
          this.$emit(
            "remove-permission",
            userPermission,
            checkboxModified,
            userList
          );
        }
      }
    },
  },

  created() {
    this.isLoading = true;
    getUserList()
      .then((sortedUsers) => {
        let userList = sortedUsers.Users.map((user) => {
          // check for email and group users to groups or user
          const email = user.includes("@");
          let defaultPermission = {
            user: user,
            view: false,
            edit: false,
            category: email ? "User" : "Group",
          };

          const userPermission = this.userPermissions.filter((perm) => {
            if (perm.user === user) {
              return perm;
            }
          });
          return userPermission[0]
            ? { ...defaultPermission, ...userPermission[0] }
            : defaultPermission;
        });
        this.userList = userList;
      })
      .catch((error) => {
        if (error.response.data.Warnings) {
          this.$showAlert({
            text: error.response.data.Warnings,
            type: "warning",
          });
        } else if (error.response.data.Errors) {
          this.$showAlert({
            text: error.response.data.Errors,
            type: "error",
          });
        } else {
          this.$showAlert({
            text: error,
            type: "error",
          });
        }
      })
      .finally(() => (this.isLoading = false));
  },
};
</script>

<style></style>

<template>
  <v-fade-transition>
    <div v-if="value" class="expression-container">
      <div class="d-flex justify-end">
        <v-btn icon small>
          <v-icon color="error" @click="closeEditor">mdi-close</v-icon>
        </v-btn>
      </div>

      <expression-viewer
        :show-back-button="false"
        :input-expr="inputExpr"
        @saving-required="pauseForSave"
      ></expression-viewer>
    </div>
  </v-fade-transition>
</template>

<script>
import ExpressionViewer from "@/components/expressions/ExpressionViewer.vue";

export default {
  name: "ExpressionOverlay",

  components: {
    ExpressionViewer,
  },

  props: {
    inputExpr: Object,
    value: Boolean,
  },

  data() {
    return {
      closeAllowed: true,
    };
  },

  methods: {
    pauseForSave(saveRequired) {
      this.closeAllowed = !saveRequired;
    },

    closeEditor() {
      if (this.closeAllowed) {
        this.$emit("input", false);
      } else {
        this.$showAlert({
          text: `Unsaved changes within your expression will be lost if you close this window
        <br> <b>Would you like to continue without saving?</b> `,
          type: "warning",
          choice: true,
        }).then((confirmed) => {
          if (confirmed) this.$emit("input", false);
        });
      }
    },
  },
};
</script>

<style scoped>
.expression-container {
  width: 90vw;
  padding: 10px;
  background-color: white;
  overflow-y: auto;
}
</style>

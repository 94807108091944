<template>
  <v-card class="drawer-bg" min-height="100%">
    <v-card-title class="py-2">
      <v-spacer></v-spacer>
      <v-icon dark class="my-auto mx-0 pr-4">mdi-layers</v-icon>
      <span class="text-h6">Layers</span>
      <v-spacer></v-spacer>
      <div v-if="layerPanelOpen">
        <!-- <v-btn icon @click="layerPanelOpen = false"> -->
        <v-btn icon @click="$emit('close-layer-panel')">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </div>
    </v-card-title>

    <v-divider></v-divider>

    <v-list dense style="background-color: transparent">
      <!-- basemap -->
      <v-list-item>
        <v-list-item-icon class="my-auto mx-0 pr-4">
          <v-icon dark>mdi-map</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="mb-0">Basemap</v-list-item-title>
        </v-list-item-content>
        <v-menu bottom offset-x nudge-right="15" dense>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-action class="list-action">
              <v-list-item-action-text
                v-bind="attrs"
                v-on="on"
                class="list-action-text white--text"
                v-text="basemap.title"
              ></v-list-item-action-text>
            </v-list-item-action>
          </template>
          <v-list style="background-color: #ffffff">
            <v-list-item-group mandatory v-model="basemap">
              <v-list-item
                v-for="(basemap, index) in basemaps"
                :key="index"
                :value="basemap"
              >
                <v-list-item-title class="list-action-menu-text">{{
                  basemap.title
                }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-list-item>

      <div v-if="dataSelectionType === 'DatasetSelector'">
        <!-- TODO: Layout selector disabled for now -->
        <!-- <v-list-item>
          <v-list-item-icon class="my-auto mx-0 pr-4">
            <v-icon dark>mdi-view-dashboard-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="mb-0">Layout</v-list-item-title>
          </v-list-item-content>
          <v-menu bottom offset-x nudge-right="15" dense>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-action class="list-action">
                <v-list-item-action-text
                  v-bind="attrs"
                  v-on="on"
                  class="list-action-text white--text"
                  v-text="layout"
                ></v-list-item-action-text>
              </v-list-item-action>
            </template>
            <v-list>
              <v-list-item-group mandatory :v-bind="layout">
                <v-list-item
                  v-for="(layout, index) in layouts"
                  :key="index"
                  :value="layout"
                  @change="changeLayout(layout)"
                >
                  <v-list-item-title class="list-action-menu-text">{{
                    layout
                  }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-list-item> -->
        <v-list-item>
          <v-list-item-icon class="my-auto mx-0 pr-4">
            <v-icon dark>mdi-map-marker-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="mb-0"
              >Study Area</v-list-item-title
            ></v-list-item-content
          >
          <v-dialog
            v-model="studyAreaDatasetSelector"
            width="80%"
            height="80%"
            persistent
            scrollable
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-action class="list-action">
                <v-list-item-action-text
                  v-bind="attrs"
                  v-on="on"
                  v-for="studyArea in studyAreas"
                  :key="studyArea.key"
                  class="list-action-text white--text"
                  @click="openStudyAreaDatasetSelector(studyArea)"
                  v-text="
                    studyArea.layer.LayerName !== 'Default'
                      ? studyArea.layer.LayerName
                      : studyArea.layer.DatasetName
                  "
                ></v-list-item-action-text>
                <v-list-item-action-text
                  v-if="studyAreas.length < studyAreasPermitted"
                  v-bind="attrs"
                  v-on="on"
                  class="list-action-text"
                  @click="openStudyAreaDatasetSelector()"
                  >Add Study Area</v-list-item-action-text
                >
              </v-list-item-action>
            </template>
            <v-card v-if="studyAreaDatasetSelector">
              <v-card-text class="selector-overlay-card-content pa-0">
                <dataset-selector
                  :single-select="false"
                  :selection-mode="true"
                  :batch-select="false"
                  :dataTableHeight="300"
                  :initial-type-filters="['Vector']"
                  :initial-tags="['Study Area']"
                  :multi-layer-select="false"
                  :crud-actions="!limitedFunctionality"
                  @update-dataset="updateDataset"
                  @update-layer="updateLayer"
                ></dataset-selector>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="d-flex justify-center align-center">
                <v-btn
                  :disabled="!selectedDataset"
                  :loading="datasetLoading"
                  text
                  color="success"
                  @click="addDataset('StudyAreaMapLayer')"
                  >Add</v-btn
                >
                <v-btn
                  text
                  color="warning"
                  @click="closeStudyAreaDatasetSelector"
                  >Cancel</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon class="my-auto mx-0 pr-4">
            <v-icon dark>mdi-layers-search-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="mb-0">Datasets</v-list-item-title>
          </v-list-item-content>
          <v-dialog
            v-model="datasetSelector"
            width="80%"
            height="80%"
            persistent
            scrollable
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-action class="list-action">
                <v-list-item-action-text
                  v-bind="attrs"
                  v-on="on"
                  class="list-action-text"
                  @click="openDatasetSelector"
                  >Add Layer</v-list-item-action-text
                >
              </v-list-item-action>
            </template>
            <v-card v-if="datasetSelector">
              <v-card-text class="selector-overlay-card-content pa-0">
                <dataset-selector
                  :single-select="false"
                  :selection-mode="true"
                  :batch-select="false"
                  :dataTableHeight="300"
                  :initial-type-filters="['Raster', 'Vector']"
                  :multi-layer-select="false"
                  :crud-actions="!limitedFunctionality"
                  @update-dataset="updateDataset"
                  @update-layer="updateLayer"
                ></dataset-selector>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="d-flex justify-center align-center">
                <v-btn
                  :disabled="!selectedDataset"
                  :loading="datasetLoading"
                  text
                  color="success"
                  @click="addDataset('DatasetMapLayer')"
                  >Add</v-btn
                >
                <v-btn text color="warning" @click="closeDatasetSelector"
                  >Cancel</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-list-item>

        <v-divider></v-divider>
      </div>

      <!-- expedited layer load -->
      <map-drawer-layer-loader
        v-else
        :disable-inputs="datasetLoading"
        @updated-layer-details="loadDatasetLayer"
        @update-dataset="updateDataset"
      >
      </map-drawer-layer-loader>

      <div class="pa-2" style="overflow-y: hidden">
        <map-layer
          v-for="(item, index) in datasets"
          :flow-layer="item"
          :key="`${item.dataset.DatasetName}-${index}`"
          :index="index"
          :limited-functionality="limitedFunctionality"
          style="overflow-y: hidden"
          class="pb-2 mb-2"
          @update-dataset="updateDataset"
        ></map-layer>
      </div>
    </v-list>
  </v-card>
</template>

<script>
import { getVirtualDatasetLayer } from "@/api/v2";
import DatasetSelector from "@/components/data/DatasetSelector.vue";
import MapDrawerLayerLoader from "@/components/mapping/MapDrawerLayerLoader.vue";
import MapLayer from "@/components/mapping/MapLayer.vue";

export default {
  name: "MapDrawer",

  components: {
    DatasetSelector,
    MapLayer,
    MapDrawerLayerLoader,
  },

  props: {
    layerPanelOpen: { type: Boolean, value: false },
    dataSelectionType: { type: String, default: "DatasetSelector" },
    limitedFunctionality: { type: Boolean, value: false },
  },

  data() {
    return {
      drawer: false,
      basemap: null,
      layout: "Simple",
      layouts: ["Simple", "Two Study Areas"],
      selectedDataset: null,
      datasetLoading: false,
      datasetFiles: [],
      selectedLayer: null,
      studyAreaDatasetSelector: false,
      changeStudyArea: null,
      datasetSelector: false,
      statsLayerOverlay: false,
    };
  },

  computed: {
    basemaps() {
      return this.$store.state.mapping.basemaps;
    },

    studyAreas() {
      return this.$store.state.mapping.datasets.filter(
        (dataset) => dataset.mapLayerPurpose === "StudyAreaMapLayer"
      );
    },

    mapExtent() {
      return this.$store.state.settings.mapSettings.maxMapBounds;
    },

    studyAreasPermitted() {
      let studyAreas;
      switch (this.layout) {
        case "Two Study Areas":
          studyAreas = 2;
          break;

        default:
          studyAreas = 1;
          break;
      }
      return studyAreas;
    },

    datasets() {
      return this.$store.state.mapping.datasets;
    },

    chartPopup() {
      return this.dataChart;
    },

    allowFeatureSelection() {
      return this.$store.state.settings.dataSelectionType.allowFeatureSelection
        .allow;
    },
  },

  watch: {
    basemap(newVal) {
      this.$store.commit("mapping/changeBasemap", newVal);
    },
    drawer() {
      this.$emit("map-drawer", this.drawer);
    },
    chartPopup() {
      this.drawer = false;
    },
  },

  methods: {
    // TODO: disabled while Layout selector disabled
    changeLayout(layout) {
      if (layout !== "Two Study Areas" && this.studyAreas.length === 2) {
        this.$showAlert({
          text: `Changing the layout will remove the study area <b>${this.studyAreas[1].layer.LayerName}</b> and all associated calculated statistics for loaded datasets, do you wish to continue?`,
          type: "warning",
          choice: true,
        }).then((confirmed) => {
          if (confirmed) {
            this.layout = layout;
            this.$store.commit("mapping/removeDataset", this.studyAreas[1]);
          }
        });
      } else this.layout = layout;
    },

    // TODO: warn user about changing time period or study area to recalculate statistics
    // changeStudyArea() {
    //   if (layout !== "Two Study Areas" && this.studyAreas.length === 2) {
    //     this.$showAlert({
    //       text: `Changing the layout will remove the study area <b>${this.studyAreas[1].layer.LayerName}</b> and all associated statistics calculated for loaded datasets, do you wish to continue?`,
    //       type: "warning",
    //       choice: true,
    //     }).then((confirmed) => {
    //       if (confirmed) {
    //         this.layout = layout;
    //         this.$store.commit("mapping/removeDataset", this.studyAreas[1]);
    //       }
    //     });
    //   }
    // },
    openDatasetSelector() {
      this.datasetSelector = true;
    },
    closeDatasetSelector() {
      this.datasetSelector = false;
      // this.selectedDataset = null;
      // this.selectedLayer = null;
    },
    openStudyAreaDatasetSelector(studyArea) {
      this.studyAreaDatasetSelector = true;
      this.changeStudyArea = studyArea;
    },
    closeStudyAreaDatasetSelector() {
      this.studyAreaDatasetSelector = false;
      // this.selectedDataset = null;
      // this.selectedLayer = null;
      this.changeStudyArea = null;
    },
    updateDataset(ds) {
      this.selectedDataset = ds;
    },

    async updateLayer(layer) {
      try {
        const layerTemplate = await getVirtualDatasetLayer(
          layer.DatasetName,
          layer.DatasetOwner,
          layer.LayerName
        );

        // preserve certain fields
        layerTemplate.LayerName = layer.LayerName;

        this.selectedLayer = layerTemplate;
      } catch (error) {
        this.$store.commit("alert/showErrorAlert", error);
      }
    },

    hasLayers() {
      const hasLayers = this.dataset?.Layers;

      const selectableLayers =
        hasLayers === null || hasLayers === undefined ? null : hasLayers;

      return selectableLayers;
    },

    resetState() {
      this.closeDatasetSelector();
      this.closeStudyAreaDatasetSelector();
      this.datasetLoading = false;
    },

    // load layer from expedited layer load
    loadDatasetLayer(layer) {
      if (!layer) {
        this.datasetLoading = true;
      } else {
        // remove all layers
        this.$store.commit("mapping/removeAllLayers");

        this.selectedLayer = layer;

        this.addDataset("DatasetMapLayer");
      }
    },

    async addDataset(context) {
      this.datasetLoading = true;

      try {
        if (context === "StudyAreaMapLayer") {
          // remove old study area
          if (this.changeStudyArea) {
            this.$store.commit("mapping/removeDataset", this.changeStudyArea);
          }
        }

        const details = {
          selectedDataset: this.selectedDataset,
          selectedLayer: this.selectedLayer,
          mapExtent: this.mapExtent,
          studyAreas: this.studyAreas,
          layerMasks: [],
          layerContext: context,
          allowFeatureSelection: this.allowFeatureSelection,
        };

        await this.$store.dispatch("mapping/addDataset", details);
      } catch (error) {
        this.$showAlert({
          text: `There was an unknown error while building the map layer.`,
          type: "error",
        });
      } finally {
        this.resetState();
      }
    },
  },

  created() {
    // set default basemap
    // this.basemap = this.basemaps.find((basemap) => basemap.title === "Light");
    this.basemap = this.$store.state.settings.mapSettings.initialBasemap
      ? this.$store.state.settings.mapSettings.initialBasemap
      : this.basemaps.find((basemap) => basemap.title === "Light");
  },
};
</script>

<style scoped>
.drawer-bg {
  background-image: linear-gradient(
      to bottom,
      rgb(131, 146, 156),
      rgba(44, 62, 80, 0.8)
    ),
    url("../../assets/img/bg.jpg");

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.drawer-button {
  position: absolute;
  top: 50px;
  left: 0px;
  z-index: 1;
}
.drawer-button-close {
  position: absolute;
  top: 50px;
  left: 300px;
  z-index: 1;
}

.selector-overlay-card-content {
  overflow-x: hidden;
}
.v-navigation-drawer {
  transition: all 0s;
}

.list-action {
  width: 50%;
  justify-content: end;
}

.list-action-text {
  font-size: 0.8em;
  text-align: end;
}
.list-action-text:hover {
  filter: invert(75%);
}

.list-action-menu-text {
  font-size: 0.8em;
}
</style>

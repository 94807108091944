<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="grey" dark v-bind="attrs" v-on="on">
        JWT <v-icon right dark> mdi-lock-open </v-icon></v-btn
      >
    </template>
    <v-card class="flow-group-box">
      <v-card-title>JSON Web Token</v-card-title>
      <v-card-text>{{ jwt }}</v-card-text>
      <v-card-actions>
        <v-btn color="success" block @click="copyToClipboard()"
          ><v-icon>mdi-content-copy</v-icon>
          Copy
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  name: "GetJWT",

  data() {
    return {
      dialog: false,
      jwt: "None",
    };
  },

  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.jwt);
    },
  },

  beforeMount() {
    Auth.currentSession().then((authData) => {
      this.jwt = authData.idToken.jwtToken;
    });
  },
};
</script>

<style scoped></style>

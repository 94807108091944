<template>
  <div>
    <div
      v-if="sequenceIsInvalid || sequenceHasNoDocs"
      style="
        height: 100ch;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <span style="color: #abb2bf; font-size: 3em"> No documentation </span>
    </div>
    <div v-else style="max-width: 60em; margin: 3em">
      <h3 style="margin-bottom: 0.2em" v-if="description">Description</h3>
      <p id="description"></p>
      <div v-if="sequence.metadata.parameters !== undefined">
        <h3 style="margin-bottom: 0.2em">Parameters</h3>
        <div
          v-for="(param_metadata, param_name) in sequence.metadata.parameters"
          :key="param_name"
        >
          <span style="float: left">
            {{
              param_name +
              (!param_metadata.optional && param_metadata.default === undefined
                ? "*"
                : "") +
              (param_metadata.type !== undefined ? ":" : "")
            }}
            <i> {{ param_metadata.type }}</i>
          </span>
          <span
            v-if="
              !param_metadata.optional && param_metadata.default === undefined
            "
            style="float: right"
          >
            (*required)
          </span>
          <br />
          <p
            style="margin-left: 2em"
            v-if="param_metadata.description !== undefined"
          >
            {{ param_metadata.description }}
          </p>
        </div>
      </div>

      <div v-if="sequence.metadata.inputs !== undefined">
        <h3 style="margin-bottom: 0.2em">Inputs</h3>
        <div
          v-for="input_metadata in sequence.metadata.inputs"
          :key="input_metadata.name"
        >
          <span style="float: left">
            {{
              (input_metadata.conditional ? "[" : "") +
              input_metadata.name +
              (input_metadata.conditional ? "]" : "") +
              (input_metadata.type !== undefined ? ":" : "")
            }}
            <i> {{ input_metadata.type }}</i>
          </span>
          <br />
          <p
            style="margin-left: 2em"
            v-if="input_metadata.description !== undefined"
          >
            {{ input_metadata.description }}
          </p>
        </div>
      </div>

      <div v-if="sequence.metadata.outputs !== undefined">
        <h3 style="margin-bottom: 0.2em">Outputs</h3>
        <div
          v-for="output_metadata in sequence.metadata.outputs"
          :key="output_metadata.name"
        >
          <span style="float: left">
            {{
              (output_metadata.conditional ? "[" : "") +
              output_metadata.name +
              (output_metadata.conditional ? "]" : "") +
              (output_metadata.type !== undefined ? ":" : "")
            }}
            <i> {{ output_metadata.type }}</i>
          </span>
          <br />
          <p
            style="margin-left: 2em"
            v-if="output_metadata.description !== undefined"
          >
            {{ output_metadata.description }}
          </p>
        </div>
      </div>

      <div v-if="sequence.metadata.references !== undefined">
        <h3 style="margin-bottom: 0.2em">References</h3>
        <div
          v-for="(reference_metadata, index) in sequence.metadata.references"
          :key="reference_metadata.name"
        >
          <span>{{ `[${index + 1}]` }}</span>
          <span style="margin-left: 1em">
            <a
              v-if="reference_metadata.url !== undefined"
              :href="reference_metadata.url"
              target="_blank"
              >{{ reference_metadata.text }}</a
            >
            <span v-else> {{ reference_metadata.text }}</span>
          </span>
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { parse } from "json5";
export default {
  name: "SequenceDocs",

  props: {
    buffer: {
      type: String,
      default: "",
    },
  },

  computed: {
    sequence() {
      try {
        let seq = parse(this.buffer);
        return seq;
      } catch (err) {
        return null;
      }
    },
    sequenceIsInvalid() {
      return this.sequence == null;
    },
    sequenceHasNoDocs() {
      return this.sequence.metadata === undefined;
    },
    references() {
      if (
        this.sequenceHasNoDocs ||
        this.sequence.metadata.references === undefined
      ) {
        return [];
      } else {
        return this.sequence.metadata.references;
      }
    },
    description() {
      if (
        this.sequenceHasNoDocs ||
        this.sequence.metadata.description == undefined
      ) {
        return "";
      } else {
        let refsWithUrl = [];
        for (let i = 0; i < this.references.length; ++i) {
          if (this.references[i].url !== undefined) {
            refsWithUrl.push(i);
          }
        }
        let searchValues = refsWithUrl.map((refIndex) => `[${refIndex + 1}]`);
        let replaceValues = refsWithUrl.map(
          (refIndex) =>
            `<a href="${this.references[refIndex].url}" target="_blank">[${
              refIndex + 1
            }]</a>`
        );
        let description = this.sequence.metadata.description;
        for (let i = 0; i < searchValues.length; ++i) {
          description = description.replace(searchValues[i], replaceValues[i]);
        }
        return description;
      }
    },
  },

  watch: {
    description() {
      this.updateDescription();
    },
  },

  methods: {
    updateDescription() {
      document.getElementById("description").innerHTML = this.description;
    },
  },
  mounted() {
    this.updateDescription();
  },
};
</script>

<style scoped>
.v-btn {
  background-color: #282c34;
  color: #abb2bf;
}

.v-icon {
  background-color: inherit !important;
  color: inherit !important;
}
</style>

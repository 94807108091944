<template>
  <v-card flat>
    <v-row no-gutters class="pb-2 px-1">
      <v-expansion-panels accordion flat :value="autoExpand">
        <v-expansion-panel>
          <v-expansion-panel-header class="px-2 py-0">
            <v-select
              :disabled="!featureTablesAvailable"
              v-model="selectedGroupFeaturesBy"
              hide-details
              dense
              solo
              :items="groupByFilters"
              class="px-4"
              color="black"
              @change="updateFeatureList"
              @click.stop=""
            >
              <template v-slot:selection="{ item }">
                <span class="grey--text text-caption">
                  {{ item }}
                </span>
              </template>
              <template v-slot:prepend>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-format-list-group</v-icon>
                  </template>
                  <span>Group features by</span>
                </v-tooltip>
              </template>
            </v-select>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row
              no-gutters
              class="px-2 py-2 d-flex align-center"
              v-for="feature in uniqueLayerFeatures"
              :key="feature"
            >
              <v-col cols="6">
                <p class="pl-3 mb-0">
                  {{
                    feature.length > 0
                      ? feature
                      : `Unnamed ${selectedGroupFeaturesBy}`
                  }}
                </p>
              </v-col>
              <v-col cols="6" class="d-flex justify-end">
                <v-checkbox
                  class="mt-0 pt-0"
                  v-model="selectedFeatures"
                  :value="feature"
                  color="black"
                  hide-details
                >
                </v-checkbox>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    flowLayer: {
      type: Object,
    },
  },

  data() {
    return {
      uniqueLayerFeatures: [],
      selectedFeatures: [],
      debouncedFeatures: [],
      selectedGroupFeaturesBy: null,
      attributeTableAttempts: 0,
      featureTablesAvailable: false,
      autoExpand: 0,
    };
  },

  watch: {
    featureTablesAvailable(newVal) {
      if (
        newVal &&
        this.flowLayer.layer.GroupByFilters.includes("Paddock ID")
      ) {
        this.selectedGroupFeaturesBy = "Paddock ID";
      }

      this.updateFeatureList(this.selectedGroupFeaturesBy);
    },

    selectedFeatures(newFeatures) {
      this.$store.commit("mapping/updateLayerFeatureFilters", {
        dataset: this.flowLayer,
        selectedFeatures: newFeatures,
        groupByFilter: this.selectedGroupFeaturesBy,
      });
    },
  },

  computed: {
    groupByFilters() {
      return [...[""], ...this.flowLayer.layer.GroupByFilters];
    },

    studyAreas() {
      return this.$store.state.mapping.datasets.filter(
        (dataset) => dataset.mapLayerPurpose === "StudyAreaMapLayer"
      );
    },
  },

  methods: {
    async getFileAttributeTables(flowLayer, studyAreas) {
      this.attributeTableAttempts += 1;

      try {
        await this.$store.dispatch("mapping/getFeatureAttributeTables", {
          mapLayer: flowLayer,
          studyAreaInfo: studyAreas,
        });

        this.featureTablesAvailable = true;
      } catch (error) {
        console.log(`Error getting feature tables: ${error}`);
        // keep on trying until success
        console.log("Trying again in 60 seconds");
        if (!this.featureTablesAvailable) {
          setTimeout(() => {
            this.getFileAttributeTables(this.flowLayer, this.studyAreas);
          }, 60000);
        }
      }
    },
    // create list of unique features across years
    createFeatureList(idField, fileAttributeTables) {
      const uniqueIds = new Set();

      // attribute to look for and its index
      const idAttributeFieldIndex = (field) => field === idField;

      fileAttributeTables.forEach((table) => {
        if (!table) {
          console.log("Table missing while creating feature list");
        }

        const idFieldIndex = table.Columns.findIndex(idAttributeFieldIndex);

        if (idFieldIndex > -1) {
          table.Rows.forEach((row) => {
            const id = row[idFieldIndex];

            if (id === "") {
              uniqueIds.add(`Unnamed ${idField}`);
              // uniqueIds.add(id);
            } else if (id === null) {
              return;
            } else {
              uniqueIds.add(id);
            }
          });
        }
      });

      return [...uniqueIds].sort();
    },

    updateFeatureList(filterVal) {
      this.uniqueLayerFeatures = this.createFeatureList(
        filterVal,
        this.flowLayer.files.map((file) => file.fileAttributeTable)
      );

      // set initial checkbox state to true
      this.selectedFeatures = this.uniqueLayerFeatures;
    },
  },

  created() {
    if (!this.featureTablesAvailable) {
      setTimeout(() => {
        this.getFileAttributeTables(this.flowLayer, this.studyAreas);
      }, 1000);
    }
  },
};
</script>

<style scoped>
.legend-title {
  width: 60%;
  font-size: 0.875rem !important;
  font-weight: 500;
  line-height: 1.1rem;
}

.legend-subtitle {
  width: 40%;
  font-size: 0.775rem !important;
  color: rgba(0, 0, 0, 0.6);
  line-height: 1.1rem;
}

.legend-content {
  justify-content: space-between;
  padding: 0px;
}
</style>

<template>
  <v-menu
    v-model="colorPicker"
    top
    nudge-bottom="105"
    nudge-left="16"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <div :style="swatchStyle(newColor)" v-on="on" />
    </template>
    <v-card>
      <v-card-text class="pa-0">
        <v-color-picker
          flat
          v-model="newColor"
          dot-size="30"
          hide-mode-switch
          :mode="this.mode"
          @update:color="updateColor"
        ></v-color-picker>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "TheColorPicker",

  props: {
    color: { type: String },
    single: { type: Boolean, default: false },
  },

  data() {
    return {
      colorPicker: false,
      mode: "rgba",
      newColor: null,
    };
  },

  watch: {
    color(color) {
      if (!this.single) this.modeTest(color);
    },
  },

  methods: {
    swatchStyle(color) {
      const { r, g, b, a } = color;
      const { colorPicker } = this;

      const backgroundColor =
        this.mode === "rgba" ? `rgba(${r},${g},${b},${a})` : color;

      return {
        backgroundColor: backgroundColor,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: colorPicker ? "50%" : "4px",
        border: "solid 1px",
        transition: "border-radius 200ms ease-in-out",
      };
    },

    modeTest(color) {
      // Hex
      const hexReg = /^#([0-9a-f]{3}){1,2}$/i;

      this.mode = hexReg.test(color) ? "hex" : "rgba";
      this.newColor = hexReg.test(color) ? color : JSON.parse(color);
    },

    colorString(color) {
      return this.mode === "rgba" ? JSON.stringify(color.rgba) : color.hexa;
    },

    updateColor(newColor) {
      const colorString = this.colorString(newColor);
      // this.color = colorString;
      this.$emit("updated-color", colorString);
    },
  },

  created() {
    this.modeTest(this.color);
  },
};
</script>


// these are lighten-4 material colors
const chipColors = [
    "#FFCDD2",
    "#F8BBD0",
    "#E1BEE7",
    "#D1C4E9",
    "#C5CAE9",
    "#BBDEFB",
    "#B3E5FC",
    "#B2EBF2",
    "#B2DFDB",
    "#C8E6C9",
    "#DCEDC8",
    "#F0F4C3",
    "#FFF9C4",
    "#FFECB3",
    "#FFE0B2",
    "#FFCCBC",
    "#D7CCC8",
    "#CFD8DC",
];

function getChipColor(name) {
    let index = 0;
    if (name.length >= 2) {
        index = (name.charCodeAt(0) + name.charCodeAt(1)) % chipColors.length;
    }
    return chipColors[index];
}

function deleteChip(chipList, chipValue) {
    let index = chipList.indexOf(chipValue);
    if (index !== -1) {
        chipList.splice(index, 1);
    }
    return chipList;
}

export {
    getChipColor,
    deleteChip
}

<template>
  <div class="sequence-editor-container">
    <div dense nav class="nav-nav">
      <v-btn-toggle
        tile
        flat
        mandatory
        v-model="mode"
        style="background-color: inherit"
      >
        <v-btn>
          <v-icon>mdi-file-multiple</v-icon>
        </v-btn>
        <v-btn>
          <v-icon>mdi-file-compare</v-icon>
        </v-btn>
        <v-btn>
          <v-icon>mdi-book-open-variant</v-icon>
        </v-btn>
      </v-btn-toggle>
    </div>

    <v-navigation-drawer
      permanent
      absolute
      class="nav"
      expand-on-hover
      mini-variant-width="20rem"
      width="45rem"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ modeIsExplorer ? "Explorer" : "Changes" }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-dialog v-model="dialogs.newFile" width="45em">
        <v-card>
          <v-card-actions>
            <v-text-field
              label="New file..."
              v-model="dialogs.path"
              autofocus
              @keydown.enter.prevent="
                () => {
                  newFileBuffer(dialogs.path + '.json5', '');
                  dialogs.newFile = false;
                  dialogs.path = '';
                }
              "
              dark
            >
              <template v-slot:append>
                <span>.json5</span>
              </template>
            </v-text-field>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogs.copyFile" width="45em">
        <v-card>
          <v-card-actions>
            <v-text-field
              label="Copy to..."
              v-model="dialogs.path"
              autofocus
              @keydown.enter.prevent="copyFile"
              dark
            >
              <template v-slot:append>
                <span>.json5</span>
              </template>
            </v-text-field>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogs.renameFile" width="45em">
        <v-card>
          <v-card-actions>
            <v-text-field
              label="Rename to..."
              v-model="dialogs.path"
              autofocus
              @keydown.enter.prevent="renameFile"
              dark
            >
              <template v-slot:append>
                <span>.json5</span>
              </template>
            </v-text-field>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogs.deleteFile" width="45em">
        <v-card>
          <v-card-title>Confirm Delete</v-card-title>
          <v-card-text>
            Are you sure you want to delete
            <strong>{{ fileContextMenu.selectedFileId }}</strong
            >? This cannot be undone.
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="dialogs.deleteFile = false"
              style="background-color: #61afef; color: #282c34"
            >
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              @click="deleteFile()"
              style="background-color: #e06c75; color: #282c34"
            >
              Permanently Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-menu
        v-model="fileContextMenu.show"
        :position-x="fileContextMenu.x"
        :position-y="fileContextMenu.y"
        absolute
      >
        <v-list dense tile>
          <v-list-item
            v-for="item in contextMenuItems"
            :key="item.name"
            @click="dialogs[item.dialogKey] = true"
          >
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-treeview
        v-if="modeIsExplorer || modeIsDocumentation"
        :active.sync="navTreeviewSelectedBuffers"
        :items="sequenceTree"
        activatable
        open-on-click
        dense
        flat
        @update:active="setActiveBuffer"
        color="#ABB2BF"
        style="color: inherit !important"
      >
        <template v-slot:prepend="{ item, open }">
          <div @contextmenu.prevent="showContextMenu($event, item)">
            <v-icon small v-if="!item.file" style="color: inherit !important">
              {{ open ? "mdi-folder-open" : "mdi-folder" }}
            </v-icon>
            <v-icon small v-else style="color: inherit !important">
              {{ files[item.file] }}
            </v-icon>
          </div>
        </template>
        <template v-slot:label="{ item }">
          <div @contextmenu.prevent="showContextMenu($event, item)">
            {{ item.name }}
          </div>
        </template>
      </v-treeview>
      <div v-else-if="modeIsChanges">
        <v-list dense>
          <v-btn
            block
            style="background-color: #61afef; color: #282c34"
            :disabled="this.compare.bufferA === this.compare.bufferB"
            @click="bufferSave"
          >
            Save Changes
          </v-btn>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Compare to:</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item-group v-model="compare.nameA">
            <v-list-item
              v-for="option in active.bufferNames"
              :key="option.name"
              :value="option.value"
              link
            >
              <v-list-item-content>
                <v-list-item-title>{{ option.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="option in activeBuffersAvailableVersions"
              :key="option.versionId"
              :value="option.versionId"
              disabled
              link
            >
              <v-list-item-content>
                <v-list-item-title
                  >Saved at {{ option.lastModified }} ({{
                    option.versionId
                  }})</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </v-navigation-drawer>
    <div class="main-area">
      <div class="buffer-tabs">
        <v-slide-group show-arrows>
          <v-slide-item v-for="(item, id) in buffers" :key="id">
            <v-btn
              class="mx-2 buffer-tab"
              :input-value="item.isActive"
              tile
              active-class="inverted-colors"
              depressed
              small
              @click="setActiveBuffer([id])"
            >
              {{ item.name }}
              <v-icon v-if="item.isModified">mdi-circle-medium</v-icon>
              <v-btn
                icon
                x-small
                class="buffer-tab-close"
                @click="closeBuffer(id)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-btn>
          </v-slide-item>
        </v-slide-group>
        <v-system-bar class="inverted-colors" color="#ABB2BF">
          {{ this.activeURI }}
          <v-btn
            v-if="this.activeURI != null"
            class="inverted-colors"
            icon
            x-small
            @click="copyUriToClipboard()"
          >
            <v-icon x-small>mdi-content-copy</v-icon>
          </v-btn>
        </v-system-bar>
      </div>

      <sequence-code-editor
        :buffer="active.buffer"
        :id="active.id"
        @update="bufferUpdate"
        @save="bufferSave"
        :key="active.id"
        v-if="modeIsExplorer"
      >
      </sequence-code-editor>
      <sequence-merge
        :bufferA="compare.bufferA"
        :bufferB="compare.bufferB"
        :key="active.id"
        v-else-if="modeIsChanges"
      >
      </sequence-merge>
      <sequence-docs
        :buffer="active.buffer"
        :key="active.id"
        v-else-if="modeIsDocumentation"
      >
      </sequence-docs>
    </div>
  </div>
</template>

<script>
import {
  getSequenceRaw,
  putSequenceRaw,
  deleteSequence,
  renameSequence,
} from "@/api/v2.js";
import SequenceCodeEditor from "@/components/sequence/SequenceCodeEditor.vue";
import SequenceMerge from "@/components/sequence/SequenceMerge.vue";
import SequenceDocs from "@/components/sequence/SequenceDocs.vue";
export default {
  name: "SequenceEditor",
  components: {
    SequenceCodeEditor,
    SequenceMerge,
    SequenceDocs,
  },
  data() {
    return {
      navTreeviewSelectedBuffers: [],
      files: {
        json: "mdi-code-json",
      },
      active: {
        buffer: null,
        id: null,
        bufferNames: [{ name: "Last Save", value: "originalValue" }],
      },
      compare: {
        nameA: "originalValue",
        nameB: "currentValue",
        bufferA: "",
        bufferB: "",
      },
      buffers: {},
      mode: 0,
      newFile: {
        path: "",
      },
      dialogs: {
        newFile: false,
        copyFile: false,
        deleteFile: false,
        renameFile: false,
        path: "",
      },
      dummyFileIds: [],
      fileContextMenu: {
        x: 0,
        y: 0,
        show: false,
        selectedFileId: null,
        selectedPrefix: null,
      },
      fileContextMenuItems: [
        { name: "New File", dialogKey: "newFile", folder: true },
        { name: "Copy", dialogKey: "copyFile", file: true },
        { name: "Delete", dialogKey: "deleteFile", file: true },
        { name: "Rename", dialogKey: "renameFile", file: true },
      ],
      expressionSequenceList: [],
    };
  },
  watch: {
    mode() {
      this.updateBuffers();
    },
  },
  computed: {
    modeIsExplorer() {
      return this.mode === 0 || this.mode === undefined;
    },
    modeIsChanges() {
      return this.mode === 1;
    },
    modeIsDocumentation() {
      return this.mode === 2;
    },
    contextMenuItems() {
      if (this.fileContextMenu.selectedFileId !== undefined) {
        return this.fileContextMenuItems.filter((item) => Boolean(item.file));
      } else {
        return this.fileContextMenuItems.filter((item) => Boolean(item.folder));
      }
    },
    currentUser() {
      return this.$store.state.account.user.attributes.email;
    },
    allDummies() {
      return [...this.dummyFileIds].map((id) => {
        return {
          ExpressionOwner: id.split("/")[0],
          ExpressionName: id.split("/").slice(1).join("/"),
        };
      });
    },
    allSequences() {
      let expressions = [...this.expressionSequenceList];
      expressions.push(...this.allDummies);
      expressions = expressions.sort((a, b) =>
        a.ExpressionName > b.ExpressionName ? 1 : -1
      );
      return expressions;
    },
    sequenceTree() {
      let items = [];
      for (let i = 0; i < this.allSequences.length; ++i) {
        const seq = this.allSequences[i];
        const heirarchy = [
          seq.ExpressionOwner,
          ...seq.ExpressionName.split("/"),
        ];
        let j = 0;
        let currItem = items;
        do {
          const key = heirarchy[j];
          const isLast = j == heirarchy.length - 1;
          const prefix = heirarchy.slice(0, j + 1).join("/");
          if (isLast) {
            let id = `${seq.ExpressionOwner}/${seq.ExpressionName}`;
            currItem.push({
              isDummy: this.dummyFileIds.includes(id),
              name: key,
              file: "json",
              id: id,
              prefix: prefix,
            });
          } else {
            let nextItem = currItem.find((item) => item.name == key);
            if (!nextItem) {
              nextItem = { name: key, prefix: prefix, children: [] };
              currItem.push(nextItem);
            }
            currItem = nextItem.children;
          }
          j++;
        } while (j < heirarchy.length);
      }
      return items;
    },
    activeBuffersAvailableVersions() {
      if (this.active.id in this.buffers) {
        return this.buffers[this.active.id].versions;
      } else {
        return [];
      }
    },

    activeURI() {
      if (this.active.id !== null) {
        return `s3://landscape-stk/sequences/${this.active.id}`;
      } else {
        return null;
      }
    },
  },
  methods: {
    // buffer operations
    bufferUpdate(text) {
      // receives updates to buffer from codemirror
      if (this.active.id in this.buffers) {
        this.buffers[this.active.id].currentValue = text;
        this.buffers[this.active.id].isModified =
          this.buffers[this.active.id].currentValue !=
          this.buffers[this.active.id].originalValue;
      }
    },
    bufferSave() {
      // callback for "save"
      if (this.active.id in this.buffers) {
        putSequenceRaw(
          this.buffers[this.active.id].owner,
          this.buffers[this.active.id].path,
          this.buffers[this.active.id].currentValue
        ).then(() => {
          this.buffers[this.active.id].originalValue =
            this.buffers[this.active.id].currentValue;
          this.buffers[this.active.id].isModified = false;
          this.refreshSequenceList();
        });
      }
      this.tryRemoveDummyFile(this.active.id); // saved file might be a dummy
      this.mode = 0; // switch to explorer since originalValue changed (changes cannot be updated inplace)
    },

    closeBuffer(id) {
      // close an open buffer
      if (id in this.buffers) {
        this.$delete(this.buffers, id);
      }
      this.tryRemoveDummyFile(id); // might be a dummy file
      this.active.id = null;
      this.active.buffer = "";
      this.compare.bufferA = "";
      this.compare.bufferB = "";
      this.navTreeviewSelectedBuffers = [];
      for (let firstId in this.buffers) {
        this.setActiveBuffer([firstId]);
        break;
      }
    },

    safeSelectBuffer(id) {
      // selects a buffer as the active buffer in the window
      for (let bufId in this.buffers) {
        this.buffers[bufId].isActive = bufId === id;
      }
      this.active.id = id;
      this.updateBuffers();
      if (id === null) {
        this.navTreeviewSelectedBuffers = [];
      } else {
        this.navTreeviewSelectedBuffers = [id];
      }
    },

    updateBuffers() {
      // callback to update child prop buffers
      if (this.active.id in this.buffers) {
        this.compare.bufferB = this.buffers[this.active.id][this.compare.nameB];
        this.compare.bufferA = this.buffers[this.active.id][this.compare.nameA];
        this.active.buffer = this.buffers[this.active.id].currentValue; // this is required
      } else {
        this.compare.bufferA = "";
        this.compare.bufferB = "";
        this.active.buffer = "";
      }
    },

    setActiveBuffer(id) {
      // switches the active buffer; if the buffer doesn't already exist, create a new buffer
      this.safeSelectBuffer(null);
      id = id[0];
      if (!id) {
        return;
      }
      if (id in this.buffers) {
        this.active.buffer = this.buffers[id].currentValue;
        this.safeSelectBuffer(id);
      } else {
        let temp = id.split("/");
        let owner = temp[0];
        let path = temp.slice(1).join("/");
        getSequenceRaw(owner, path).then((data) => {
          this.newBuffer(id, {
            originalValue: data.text,
            currentValue: data.text,
            versions: data.versions,
          });
        });
      }
    },

    newBuffer(
      id,
      {
        originalValue = null,
        currentValue = "",
        isModified = false,
        isActive = true,
        versions = [],
      } = {}
    ) {
      // open a new buffer
      let temp = id.split("/");
      this.$set(this.buffers, id, {
        originalValue: originalValue,
        currentValue: currentValue,
        isModified: isModified,
        name: temp[temp.length - 1],
        isActive: isActive,
        owner: temp[0],
        path: temp.slice(1).join("/"),
        versions: versions,
      });
      if (isActive) {
        this.active.buffer = currentValue;
        this.safeSelectBuffer(id);
      }
    },

    // context menu
    showContextMenu(e, item) {
      // right click menu on files/folders

      // set context menu state
      e.preventDefault();
      this.fileContextMenu.show = false;
      this.fileContextMenu.x = e.clientX;
      this.fileContextMenu.y = e.clientY;
      this.fileContextMenu.selectedFileId = item.id; // file (else undefined)
      this.fileContextMenu.selectedPrefix = item.prefix; // folder (else undefined)

      if (this.fileContextMenu.selectedFileId) {
        // initialize dialog v-text-field to path without ".json5"
        this.dialogs.path = this.fileContextMenu.selectedFileId
          .split("/")
          .slice(1)
          .join("/")
          .slice(0, -6);
      } else {
        // initialize dialog v-text-field to id without email prefix
        this.dialogs.path = this.fileContextMenu.selectedPrefix
          .split("/")
          .slice(1)
          .join("/");
      }

      let dontShowMenu = false;
      dontShowMenu |= this.dummyFileIds.includes(item.id); // dont show context menu on dummy files
      dontShowMenu |=
        this.fileContextMenu.selectedPrefix !== undefined &&
        this.fileContextMenu.selectedPrefix.split("/")[0] != this.currentUser; // dont show context menu in other's folders

      if (!dontShowMenu) {
        this.$nextTick(() => {
          this.fileContextMenu.show = true;
        });
      }
    },

    // file operations
    newFileBuffer(path, initialText) {
      // open a new buffer with initial text (e.g., new file '', copy file '<copied-contents>')
      let id = `${this.currentUser}/${path}`;
      getSequenceRaw(this.currentUser, path)
        .then((data) => {
          this.newBuffer(id, {
            originalValue: data.text,
            currentValue: initialText,
            versions: data.versions,
          });
        })
        .catch(() => {
          this.dummyFileIds.push(id);
          this.newBuffer(id, {
            originalValue: null,
            currentValue: initialText,
          });
        });
    },

    tryRemoveDummyFile(id) {
      // remove the ID from the dummy file IDs if it is present
      let index = this.dummyFileIds.indexOf(id);
      if (index !== -1) {
        this.dummyFileIds.splice(index, 1);
      }
    },

    copyFile() {
      // copy a file
      let temp = this.fileContextMenu.selectedFileId.split("/");
      getSequenceRaw(temp[0], temp.slice(1).join("/")).then((data) => {
        this.newFileBuffer(this.dialogs.path + ".json5", data.text);
        this.dialogs.copyFile = false;
        this.dialogs.path = "";
      });
    },

    renameFile() {
      // rename a file
      renameSequence(
        this.fileContextMenu.selectedFileId.split("/")[0],
        this.fileContextMenu.selectedFileId.split("/").slice(1).join("/"),
        this.currentUser,
        this.dialogs.path + ".json5"
      ).then(() => {
        this.closeBuffer(this.fileContextMenu.selectedFileId);
        this.refreshSequenceList();
        this.dialogs.path = "";
        this.dialogs.renameFile = false;
      });
    },

    deleteFile() {
      deleteSequence(
        this.fileContextMenu.selectedFileId.split("/")[0],
        this.fileContextMenu.selectedFileId.split("/").slice(1).join("/")
      ).then(() => {
        this.closeBuffer(this.fileContextMenu.selectedFileId);
        this.refreshSequenceList();
        this.dialogs.deleteFile = false;
      });
    },

    // sequence tree
    refreshSequenceList() {
      let numBefore = this.expressionSequenceList.length;
      this.$store.dispatch("accountData/refreshExpressionList").then(() => {
        this.updateExpressionSequenceList();
        console.log(
          `Updated sequence list. Before: ${numBefore}. After: ${this.expressionSequenceList.length}.`
        );
      });
    },

    updateExpressionSequenceList() {
      this.expressionSequenceList = [
        ...this.$store.state.accountData.expressionList.expressions.AllExpressions.filter(
          (item) => item.Tags.includes("SEQUENCE")
        ),
      ];
    },

    // other
    copyUriToClipboard() {
      if (this.activeURI != null) {
        navigator.clipboard.writeText(this.activeURI);
      }
    },
  },
  beforeMount() {
    this.updateExpressionSequenceList();
  },
};
</script>

<style scoped>
/* General */
.v-btn {
  background-color: #282c34;
  color: #abb2bf;
}

.v-btn.inverted-colors {
  background-color: #abb2bf !important;
  color: #282c34 !important;
}

.v-icon {
  background-color: inherit !important;
  color: inherit !important;
}

.v-list {
  background-color: #282c34;
  color: #abb2bf;
}

.v-list-item {
  background-color: inherit !important;
  color: inherit !important;
}

.v-card {
  background-color: #282c34;
  color: #abb2bf;
}

.v-card__title {
  color: #abb2bf !important;
}
.v-card__text {
  color: #abb2bf !important;
}

/* Outside Navigation Bar */
.nav-nav {
  background-color: #282c34;
  width: 50px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
}

.nav-nav .v-btn {
  background-color: #282c34;
  padding: 1em;
  margin-top: 1em;
}

.nav-nav .v-icon {
  color: #abb2bf !important;
}

.nav-nav .v-btn-toggle {
  flex-direction: column;
}

/* Inside Navigation Bar */
.nav {
  background-color: #24282f !important;
  color: #abb2bf !important;
  overflow-x: scroll;
  font-size: 0.8em;
  left: 50px;
}

.nav .v-list {
  background-color: #24282f !important;
  color: #abb2bf !important;
}
.nav .v-list-item {
  margin-top: 1em;
}

.main-area {
  background-color: #282c34;
  color: #abb2bf;
  margin-left: 23.1rem;
  width: calc(100% - 5rem);
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

/* Buffer tabs */
.buffer-tabs {
  height: 3em;
  background-color: #282c34 !important;
}
.buffer-tab {
  text-transform: none !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  margin-right: 0.5em !important;
  background-color: #282c34 !important;
  color: #abb2bf !important;
}

.buffer-tab-close {
  margin-left: 0.6em;
  background-color: inherit !important;
  color: inherit !important;
}

/* Other */

.sequence-editor-container {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}
</style>

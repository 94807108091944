<template>
  <div class="legend-item" name="printable-legend-item">
    <v-list-item
      v-if="currentProperties.type === 'Raster'"
      class="legend-item-content"
    >
      <v-list-item-content legend-printable="true">
        <div class="colormap-yaxis">
          <!-- canvas for drawing raster colormaps-->
          <!-- hide canvas if not needed for a linear scale-->
          <canvas
            v-show="legendType === 'Gradient'"
            ref="colormapCanvas"
            v-bind:id="currentProperties.id"
            class="colormapCanvas"
            width="50"
            height="1024"
          >
          </canvas>

          <!-- plot continuous raster values -->
          <!-- <ul
            v-if="legendType === 'Gradient'"
            ref="yaxis"
            class="colormap-continuous-yaxis-values"
          >
            <li
              v-for="index in yAxisValues.length"
              :key="index"
              class="colormap-continuous-yaxis-value"
            >
              {{
                yAxisValues[index - 1].value === 0
                  ? yAxisValues[index - 1].value.toFixed(1)
                  : yAxisValues[index - 1].value > 0.001
                  ? yAxisValues[index - 1].value.toFixed(4)
                  : yAxisValues[index - 1].value > 100000
                  ? yAxisValues[index - 1].value.toExponential(4)
                  : yAxisValues[index - 1].value.toExponential(3)
              }}
            </li>
          </ul> -->
          <!-- plot continuous vector label values -->
          <ul
            ref="yaxis"
            class="colormap-continuous-yaxis-values"
            v-if="legendType === 'Gradient'"
          >
            <li
              v-for="interval in yAxisValues"
              :key="interval.label"
              class="colormap-continuous-yaxis-value"
            >
              {{ interval.label }}
            </li>
          </ul>
        </div>

        <div v-if="legendType === 'Classes'">
          <v-list class="pb-0" dense>
            <template>
              <v-list-item>
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      color="black"
                      :input-value="!active"
                      :indeterminate="someCategoriesUnselected"
                      v-model="allCategoriesVisible"
                      @change="selectAllCategories"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content legend-printable="true">
                    <v-list-item-title
                      style="color: grey"
                      v-text="
                        `${
                          yAxisValues.length - unselectedCategories.length
                        } selected`
                      "
                    ></v-list-item-title> </v-list-item-content
                ></template>

                <!-- <v-spacer></v-spacer> -->
              </v-list-item>
            </template>
          </v-list>
          <v-list flat dense>
            <v-list-item-group
              v-model="unselectedCategories"
              multiple
              active-class="unselected-category"
            >
              <v-list-item
                v-for="category in yAxisValues"
                :key="category.label"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="!active"
                      color="black"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-icon>
                    <v-icon large :color="category.color">
                      mdi-square-rounded
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      v-text="category.label"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      v-else-if="currentProperties.type === 'Vector'"
      class="legend-item-content"
    >
      <v-list-item-content v-if="legendType === 'Gradient'">
        <div class="colormap-yaxis">
          <div class="vector-continuous" :style="vectorGradient"></div>

          <!-- plot continuous vector label values -->
          <ul ref="yaxis" class="colormap-continuous-yaxis-values">
            <li
              v-for="interval in yAxisValues"
              :key="interval.label"
              class="colormap-continuous-yaxis-value"
            >
              {{ interval.label }}
            </li>
          </ul>
        </div>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import { evaluate_cmap } from "js-colormaps";
import { formatCSSColor, formatEditableSymbology } from "@/api/mapping.js";

export default {
  name: "MapDataViewerLegendItem",

  props: {
    dataset: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      categories: null,
      yAxisValues: [],
      legendType: "",
      vectorGradient: null,
      unselectedCategories: [],
      allCategoriesVisible: true,
    };
  },

  computed: {
    currentProperties() {
      let currentDataset = this.dataset;

      return {
        name: currentDataset.dataset.DatasetName,
        id: currentDataset.dataset.DatasetName,
        units: currentDataset.layer.Units,
        type: currentDataset.dataset.DatasetType,
        layerType: currentDataset.layer.TypeOfData,
        minValue: currentDataset.files[0].colormap.inputMin,
        maxValue: currentDataset.files[0].colormap.inputMax,
        colormap: currentDataset.files[0].colormap,
        opacity:
          currentDataset.files[0].mapLayer.paint[
            `${currentDataset.files[0].mapLayer.type}-opacity`
          ] * 100,
      };
    },

    noCategoriesUnselected() {
      return this.unselectedCategories.length === 0;
    },

    allCategoriesUnselected() {
      return this.yAxisValues.length === this.unselectedCategories.length;
    },

    someCategoriesUnselected() {
      return !this.allCategoriesUnselected && !this.noCategoriesUnselected;
    },
  },

  watch: {
    currentProperties() {
      this.updateLegend();
    },
    unselectedCategories(unselectedCategories) {
      const unselectedCategoryLabels = unselectedCategories.map(
        (categoryIndex) => this.yAxisValues[categoryIndex].label
      );

      this.$store.commit("mapping/updateLayerRasterFilter", {
        dataset: this.dataset,
        unselectedCategories: unselectedCategoryLabels,
      });
    },

    allCategoriesUnselected(value) {
      if (value) {
        this.allCategoriesVisible = false;
      }
    },

    noCategoriesUnselected(value) {
      if (value) {
        this.allCategoriesVisible = true;
      }
    },
  },

  methods: {
    plotColormap(colormap, opacity) {
      //Evaluates colour at position of x between 0-1 for colormap provided (Assumes a linear scale of 0-1, rescaling will be needed if interpolation method changes)
      let canvas = this.$refs.colormapCanvas;
      let ctx = canvas.getContext("2d");

      if (colormap !== null) {
        for (let x = 0; x <= 256; x++) {
          let color = evaluate_cmap(
            x / 256,
            colormap.endsWith("_r") ? colormap.replace("_r", "") : colormap,
            colormap.endsWith("_r") ? true : false
          );
          let r = color[0];
          let g = color[1];
          let b = color[2];
          ctx.fillStyle = `rgba(${r}, ${g}, ${b}, ${opacity})`;
          ctx.fillRect(
            0,
            (x * canvas.height) / 256,
            canvas.width,
            canvas.height / 256
          );
        }
      }
    },

    displayGradientLegend(vectorColormap) {
      const uniqueYAxisValues = formatEditableSymbology(vectorColormap);

      if (this.currentProperties.type === "Vector") {
        let cssString = `background: linear-gradient(180deg, ${uniqueYAxisValues
          .map((cat) => formatCSSColor(cat.color))
          .join(", ")})`;
        this.vectorGradient = cssString;
      }

      this.yAxisValues = uniqueYAxisValues;
    },

    displayClassLegend(colormap) {
      this.yAxisValues.length = 0;

      const uniqueYAxisValues = formatEditableSymbology(colormap).map(
        (category) => {
          category.color = formatCSSColor(category.color);
          return category;
        }
      );

      this.yAxisValues = uniqueYAxisValues;
    },

    updateLegend() {
      if (this.currentProperties.type === "Raster") {
        const colormapName =
          this.currentProperties.colormap.displayParams?.colormap_name;

        // plot canvas first even if not used.
        this.plotColormap(
          colormapName === null ||
            colormapName === undefined ||
            colormapName === "Custom"
            ? "gist_gray"
            : colormapName,
          this.currentProperties.opacity / 100
        );

        // legend colormap
        if (this.currentProperties.colormap.legendColormap) {
          if (this.currentProperties.layerType === "Categorical") {
            this.legendType = "Classes";
            this.displayClassLegend(
              this.currentProperties.colormap.legendColormap
            );
          } else if (this.currentProperties.layerType === "Continuous") {
            this.legendType = "Gradient";
            this.displayGradientLegend(
              this.currentProperties.colormap.legendColormap
            );
          } else if (this.currentProperties.layerType === "Mask") {
            this.legendType = "Classes";
          }
        }

        // Set by an internal colormap
        else if (
          !colormapName &&
          this.currentProperties.colormap.internalColormap
        ) {
          this.legendType = "Classes";
          this.displayClassLegend(
            this.currentProperties.colormap.internalColormap.style
          );
        }
      }

      if (this.currentProperties.type === "Vector") {
        if (this.currentProperties.layerType === "Continuous") {
          this.legendType = "Gradient";
          this.displayGradientLegend(
            this.currentProperties.colormap.legendColormap
          );
        }
      }
    },

    selectAllCategories(active) {
      const newSelection = [];

      if (!active)
        this.yAxisValues.forEach((category, index) => {
          newSelection.push(index);
        });

      this.unselectedCategories = newSelection;
    },
  },

  mounted() {
    this.updateLegend();
  },
};
</script>

<style scoped>
.legend-item {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  overflow-x: hidden;
}

.legend-item-content {
  justify-content: center;
}

.colormap-yaxis {
  display: flex;
  justify-content: center;
}

.colormapCanvas {
  width: 32px;
  height: 256px;
  border: 1px solid black;
}

ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style-type: none;
}

.colormap-discrete-yaxis-values {
  padding-left: 3px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.colormap-discrete-yaxis-value {
  font-size: 0.8em;
  font-weight: bold;
}

.unselected-category {
  opacity: 0.5;
}

.colormap-discrete-categorical-yaxis-values {
  font-size: 0.8em;
  font-weight: bold;
  padding-left: 3px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.colormap-discrete-categorical-yaxis-value,
.colormap-discrete-categorical-yaxis-label {
  align-self: center;
}

.colormap-continuous-yaxis-values {
  padding-left: 3px;
}

.colormap-continuous-yaxis-value {
  font-size: 0.8em;
  font-weight: bold;
}

.legend-units {
  white-space: normal;
}

.vector-continuous {
  height: 256px;
  width: 32px;
  border: 1px solid black;
}
</style>

<template>
  <div>
    <v-card>
      <v-card-title class="pb-0">Dataset Symbology</v-card-title>
      <div class="pa-4" v-if="Object.keys(savedSymbology).length > 0">
        <v-card-subtitle class="text-caption pt-0 pl-0"
          >If you have edit permissions, you can remove unwanted symbology. If
          you wish to create a new symbology this can be accomplished from
          within the Data Viewer.</v-card-subtitle
        >
        <v-card-text class="styles-container">
          <v-list two-line>
            <v-list-item-group
              v-model="selectedSymbology"
              active-class="grey--text"
              mandatory
            >
              <template v-for="(symbology, index) in savedSymbology">
                <v-list-item :key="symbology.Name">
                  <template v-slot:default="{}">
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="symbology.Name"
                      ></v-list-item-title>

                      <v-list-item-subtitle
                        class="text--primary"
                        v-text="symbology.Type"
                      ></v-list-item-subtitle>

                      <v-list-item-subtitle
                        v-text="symbology.DatasetOwner"
                      ></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-icon
                        :disabled="!editPermissions"
                        @click.stop="deleteDatasetSymbology(index)"
                        color="red lighten-1"
                      >
                        mdi-delete
                      </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>

                <v-divider
                  v-if="index < savedSymbology.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </div>
      <div v-else class="pa-4 mx-auto">
        <v-card-subtitle class="text-caption pt-0 pl-0">
          There are no saved symbology yet associated with this dataset. If you
          have edit permissions you can create and save symbology to datasets
          from within the Data Viewer.
        </v-card-subtitle>
      </div>
    </v-card>
    <v-card class="preview-section" v-if="savedSymbology.length > 0">
      <div class="d-flex flex-wrap align-center pb-0">
        <v-card-title>Preview</v-card-title>
        <v-btn
          text
          small
          @click="showParsed = !showParsed"
          :disabled="showParsed"
          >Parsed</v-btn
        >
        <v-btn
          text
          small
          @click="showParsed = !showParsed"
          :disabled="!showParsed"
          >Raw</v-btn
        >
      </div>

      <div class="pa-4">
        <v-card-text
          v-if="showParsed && selectedSymbology !== null"
          class="preview-container"
        >
          <v-row
            v-if="showParsed"
            class="d-flex align-center justify-space-between pt-2"
          >
            <v-col cols="3">
              <h3
                v-if="
                  savedSymbology[selectedSymbology].displayType ===
                  'Discrete (Categorical)'
                "
              >
                Value
              </h3>
              <h3
                v-if="
                  savedSymbology[selectedSymbology].displayType ===
                  'Discrete (Interval)'
                "
              >
                Min/Max
              </h3>
            </v-col>

            <v-col cols="3"> <h3>Colour</h3> </v-col>

            <v-col cols="6">
              <h3>Label</h3>
            </v-col>
          </v-row>
          <v-row
            class="d-flex align-content-center justify-space-between mx-0"
            v-for="category in formatColormap(
              savedSymbology[selectedSymbology].Style.classes
            )"
            :key="category"
          >
            <v-col cols="3">
              <div
                v-if="
                  savedSymbology[selectedSymbology].Type ===
                  'categorical-discrete'
                "
              >
                {{ category.Value }}
              </div>
              <div
                v-if="
                  savedSymbology[selectedSymbology].displayType ===
                  'Discrete (Interval)'
                "
              >
                {{ `(${category.min}, ${category.max})` }}
              </div>
            </v-col>

            <v-col cols="3" class="d-flex flex-column align-center">
              <div :symbology="swatchStyle(category.color)" />
            </v-col>

            <v-col cols="6">
              <div class="category-label">
                {{ category.label }}
              </div>
            </v-col>
          </v-row></v-card-text
        >
        <v-card-text
          v-if="!showParsed && selectedSymbology !== null"
          class="preview-container"
        >
          <v-textarea
            v-text="rawStyle(savedSymbology[selectedSymbology].Style)"
          ></v-textarea
        ></v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>
// import { removeDefaultStyle } from "@/api/mapping.js";

export default {
  name: "DatasetPropertiesSymbology",

  props: {
    fullDataset: { type: Object, required: true },
    editPermissions: { type: Boolean, default: false, required: true },
  },

  data() {
    return {
      selectedSymbology: 0,
      showParsed: true,
    };
  },

  computed: {
    savedSymbology() {
      return this.fullDataset.Symbology;
    },
  },

  methods: {
    formatColormap(colormap) {
      // create a new object from each class, where the label is a new property that can be edited.

      const tempColormap = Object.keys(colormap).map((key) => ({
        Label: key,
        ...colormap[key],
      }));

      return tempColormap;
    },

    addStyle() {
      this.savedSymbology.unshift({ name: "symbology", symbology: {} });
    },

    // setDefaultStyle(newDefaultStyle) {
    //   // remove previous default symbology
    //   const updatedStyles = removeDefaultStyle(
    //     this.savedSymbology,
    //     this.defaultStyle
    //   );

    //   // set new default
    //   this.defaultStyle = newDefaultStyle;
    //   this.updateDatasetSymbology(this.fullDataset, updatedStyles);
    // },

    async deleteDatasetSymbology(index) {
      const symbology = this.savedSymbology[index];

      const deletedSymbology = await this.$store.dispatch(
        "mapping/deleteDatasetSymbology",
        symbology
      );

      if (deletedSymbology) {
        this.selectedSymbology = null;
        delete this.savedSymbology[symbology.Name];
      }

      // this.$emit("update-symbology-object", updatedStyles);
    },

    rawStyle(symbology) {
      return JSON.stringify(symbology);
    },
    swatchStyle(color) {
      const { r, g, b, a } = color;
      return {
        backgroundColor: `rgba(${r},${g},${b},${a})`,
        height: "30px",
        width: "30px",
        borderRadius: "4px",
      };
    },
  },

  mounted() {},
};
</script>

<symbology scoped>
.styles-container {
  height: 30vh;
  border: 1px solid rgba(160, 163, 165, 0.61);
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
}

.preview-section {
  flex: 1 1 auto;
}

.preview-container {
  text-align: center;
  border: 1px solid rgba(160, 163, 165, 0.61);
  border-radius: 5px;
  background-color: rgb(236, 239, 243);
  height: 32vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.category-label {
  /* text-align: start; */
}
</symbology>

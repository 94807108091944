<template>
  <div class="pa-2">
    <div class="header-bg pa-4">
      <div v-if="newProjectBeingCreated" class="d-flex justify-center">
        <v-progress-circular indeterminate color="white"></v-progress-circular>
      </div>
      <v-row
        v-else
        row
        wrap
        align-center
        class="justify-space-between align-center"
      >
        <v-col cols="12" md="8" lg="8">
          <div>
            <v-text-field
              outlined
              hide-details="true"
              light
              v-model="projectSearch"
              prepend-icon="mdi-magnify"
              label="Search Projects"
              :color="$store.state.settings.appColor"
              dense
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="4" lg="3">
          <div class="d-flex align-center">
            <v-dialog max-width="500" v-model="newProjectDialog">
              <template v-slot:activator="{ on, attrs }">
                <v-btn block v-bind="attrs" v-on="on">
                  <v-icon>mdi-plus</v-icon>
                  New Project
                </v-btn>
              </template>
              <v-card v-if="!loadingProjects">
                <v-card-title class="text-h5">New Project</v-card-title>
                <v-card-subtitle class="pt-2 subtitle-2">
                  Create a new blank project or one from a template that has
                  been shared with you.
                </v-card-subtitle>
                <div
                  v-if="newProjectBeingCreated"
                  class="d-flex justify-center"
                >
                  <v-progress-circular indeterminate></v-progress-circular>
                </div>
                <v-form ref="form" v-model="formValid" lazy-validation>
                  <div class="d-flex px-6 justify-center">
                    <v-text-field
                      label="Project Name"
                      v-model="newProjectName"
                      :rules="rules"
                    ></v-text-field>
                  </div>
                  <div class="d-flex px-6 justify-center">
                    <v-select
                      max-width="300"
                      v-model="templateSelected"
                      placeholder="Available Templates"
                      clearable
                      :items="sharedProjects"
                      item-value="ProjectName"
                      append="ProjectOwner"
                      :hint="
                        templateSelected
                          ? `Template Selected`
                          : 'Choose an available template'
                      "
                      return-object
                      persistent-hint
                    >
                      <template v-slot:item="{ item }">
                        <span class="font-weight-bold pr-2">{{
                          item.ProjectName
                        }}</span>
                        {{ `(${item.ProjectOwner})` }}
                      </template>
                      <template v-slot:selection="{ item }">
                        <span class="font-weight-bold pr-2">{{
                          item.ProjectName
                        }}</span>
                        {{ `(${item.ProjectOwner})` }}
                      </template>
                    </v-select>
                  </div>
                </v-form>

                <v-card-actions class="justify-center">
                  <v-btn
                    color="warning"
                    text
                    :disabled="!formValid || this.newProjectName.length === 0"
                    @click="createProject"
                    :loading="newProjectBeingCreated"
                  >
                    Create
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-col>
      </v-row>
    </div>

    <project-list
      :project-list="filteredProjects"
      :project-loading="projectLoading"
      @open-project="openProject"
    ></project-list>
  </div>
</template>

<script>
import { copyProject, newProject } from "@/api/v2";

import ProjectList from "@/components/projects/ProjectList.vue";

export default {
  name: "ProjectDashboard",

  components: {
    ProjectList,
  },

  data() {
    return {
      loadingProjects: false,
      projectLoading: null,
      projects: [],
      projectToEdit: null,
      projectSearch: "",
      projectOverlay: false,
      newProjectName: "",
      formValid: true,
      templateSelected: null,
      newProjectDialog: false,
      newProjectBeingCreated: false,
    };
  },

  computed: {
    projectList() {
      return this.$store.state.accountData.projectList.projects;
    },

    filteredProjects() {
      if (this.projectSearch) {
        return this.projectList.AllProjects.filter((project) => {
          return project.ProjectName.toLowerCase().includes(
            this.projectSearch.toLowerCase()
          );
        });
      } else return this.projectList.AllProjects;
    },

    needsSaving() {
      return true;
    },

    userProjects() {
      return this.projectList.AllProjects.filter((project) => {
        return (
          project.ProjectOwner ===
          this.$store.state.account.user.attributes.email
        );
      });
    },

    sharedProjects() {
      return this.projectList.AllProjects.filter((project) => {
        return (
          project.ProjectOwner !==
          this.$store.state.account.user.attributes.email
        );
      });
    },

    rules() {
      const rules = [];

      const rule = (v) =>
        (v || "").length > 0 || `Project name cannot be empty`;

      rules.push(rule);

      for (let i = 0; i < this.userProjects.length; i++) {
        const rule = (v) =>
          (!!v && v) !== this.userProjects[i].ProjectName ||
          "A project with this name already exists";

        rules.push(rule);
      }

      return rules;
    },
  },

  watch: {
    templateSelected(templateSelected) {
      if (templateSelected) {
        this.newProjectName = templateSelected.ProjectName;
      }
    },
    newProjectName: "validateField",
  },

  methods: {
    createProject() {
      this.newProjectBeingCreated = true;
      if (this.templateSelected) {
        this.createProjectFromTemplate();
      } else {
        newProject(
          this.newProjectName,
          this.$store.state.account.user.attributes.email
        )
          .then((response) => {
            if (response.Warnings) {
              this.$showAlert({ text: response.Warnings, type: "warning" });
            }

            this.$store
              .dispatch("accountData/refreshProjectList")
              .then((response) => {
                let project = response.AllProjects.find(
                  (project) => project.ProjectName === this.newProjectName
                );
                this.newProjectDialog = false;
                this.openProject(project);
              });
          })
          .catch((error) => {
            let errorMessage = error;
            if (error.response) {
              errorMessage = error.response.data.Errors;
            }
            this.$showAlert({ text: errorMessage, type: "error" });
          })
          .finally(() => {
            this.newProjectBeingCreated = false;
            this.newProjectDialog = false;
          });
      }
    },

    createProjectFromTemplate() {
      copyProject(
        this.newProjectName,
        this.$store.state.account.user.attributes.email,
        this.templateSelected.ProjectName,
        this.templateSelected.ProjectOwner
      )
        .then((response) => {
          if (response.Warnings) {
            this.$showAlert({
              text: response.Warnings,
              type: "warning",
            });
          }
          this.$store
            .dispatch("accountData/refreshProjectList")
            .then((response) => {
              let project = response.AllProjects.find(
                (project) => project.ProjectName === this.newProjectName
              );
              this.newProjectDialog = false;
              this.openProject(project);
            });
        })
        .catch((error) => {
          this.$showAlert({
            text: error,
            type: "error",
          });
        })
        .finally(() => {
          this.newProjectBeingCreated = false;
          this.newProjectDialog = false;
        });
    },

    async openProject(project) {
      this.projectLoading = project;
      const projectOwner = project.ProjectOwner;
      const projectName = project.ProjectName;

      try {
        const response = await this.$store.dispatch("project/loadProject", {
          projectOwner,
          projectName,
        });

        if (response.Warnings) {
          this.$showAlert({
            text: response.Warnings,
            type: "warning",
          });
        }
        this.$router.push("project");
      } catch (error) {
        let errorMessage = error;
        if (error.response) {
          errorMessage = error.response.data.Errors;
        }
        this.$showAlert({ text: errorMessage, type: "error" });
      } finally {
        this.projectLoading = null;
      }
    },

    validateField() {
      this.$refs.form.validate();
    },
  },

  created() {
    this.$store.dispatch("accountData/refreshProjectList");
  },
};
</script>

<style scoped>
.header-bg {
  background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0)
    ),
    url("../assets/img/bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>

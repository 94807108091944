<template>
  <component
    v-bind:is="name"
    :params="params"
    :context="context"
    @submit-params="submitParams"
    @immediate-submit-params="immediateSubmitParams()"
    @new-expression="nextExpression"
  ></component>
</template>

<script>
import Expression from "@/components/expressions/methods/Expression.vue";
import Dataset from "@/components/expressions/methods/Dataset.vue";
import VectorData from "@/components/expressions/methods/VectorData.vue";
import FilterModelMethod from "@/components/expressions/methods/FilterModelMethod.vue";
import If from "@/components/expressions/methods/If.vue";
import Cond from "@/components/expressions/methods/Cond.vue";
import Random from "@/components/expressions/methods/Random.vue";
import Stats from "@/components/expressions/methods/Stats.vue";
import CellSize from "@/components/expressions/methods/CellSize.vue";
import Buffer from "@/components/expressions/methods/Buffer.vue";
import Patches from "@/components/expressions/methods/Patches.vue";
import MovingWindow from "@/components/expressions/methods/MovingWindow.vue";
import Normalize from "@/components/expressions/methods/Normalize.vue";
import LCP from "@/components/expressions/methods/LCP.vue";
import Result from "@/components/expressions/methods/Result.vue";

// import UnivariateModifier from "@/components/expressions/methods/UnivariateModifier";
// import BivariateModifier from "@/components/expressions/methods/BivariateModifier";
// import TemporalModifier from "@/components/expressions/methods/TemporalModifier";
// import RegionalModifier from "@/components/expressions/methods/RegionalModifier";
// import RegionStats from "@/components/expressions/methods/RegionStats";
// import Random from "@/components/expressions/methods/Random";
// import Transformation from "@/components/expressions/methods/Transformation";

export default {
  name: "Methods",

  components: {
    Dataset,
    VectorData,
    FilterModelMethod,
    Expression,
    If,
    Cond,
    Random,
    Stats,
    CellSize,
    Buffer,
    Patches,
    MovingWindow,
    Normalize,
    LCP,
    Result
  },

  props: {
    name: String,
    params: Object
  },

  computed: {
    context() {
      return {
        name
      };
    }
  },

  methods: {
    submitParams(params) {
      this.$emit("submit-params", params);
    },
    immediateSubmitParams() {
      this.$emit("immediate-submit-params");
    },
    nextExpression(expr) {
      this.$emit("new-expression", expr);
    }
  }
};
</script>

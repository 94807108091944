<template>
  <div>
    <div v-if="showBackButton" class="pb-3">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="goBack"
            v-bind="attrs"
            v-on="on"
            small
            depressed
            class="text-capitalize"
            ><v-icon medium>mdi-arrow-left</v-icon>Go Back</v-btn
          >
        </template>
        <span>Back to Expression Manager</span>
      </v-tooltip>
    </div>
    <div class="d-flex flex-row align-center">
      <div
        v-for="(expr, index) in exprNest"
        :key="index"
        class="d-flex pl-2 pr2 align-center"
      >
        <v-btn
          dense
          :text="expr.ExpressionName !== activeExpr.ExpressionName"
          :disabled="expr.ExpressionName === activeExpr.ExpressionName"
          :outlined="index === 0"
          x-small
          @click="activateExpr(index)"
        >
          {{ expr.ExpressionName }}
        </v-btn>
        <v-icon v-if="index !== exprNest.length - 1" x-small
          >mdi-arrow-left</v-icon
        >
      </div>
    </div>
    <div class="d-flex pt-5 pl-2 pr-2 flex-column justify-center">
      <expression-editor
        v-for="(expr, index) in exprNest"
        :key="index"
        :idx="index"
        :expr="expr"
        v-show="index === exprIdx && !loadingNext"
        @next-expression="nextExpression"
        @expr-modified="exprModified($event, index)"
        @update-meta="
          metaOverlay = true;
          dialogTagManager = true;
        "
      ></expression-editor>
      <v-progress-linear
        v-show="loadingNext"
        indeterminate
        :color="$store.state.settings.appColor"
      ></v-progress-linear>
    </div>
    <v-overlay :value="metaOverlay" :dark="false">
      <v-dialog
        v-model="dialogNewExpression"
        max-width="500px"
        scrollable
        persistent
      >
        <!-- TODO: -->
        <new-expression
          :expressions="expressions"
          :input-name="activeExpr ? activeExpr.name : null"
          :input-description="activeExpr ? activeExpr.description : null"
          :input-cat="activeExpr ? activeExpr.cat : null"
          @close-new="closeMeta()"
          @update="updateMeta"
        ></new-expression>
      </v-dialog>
    </v-overlay>
  </div>
</template>

<script>
import ExpressionEditor from "@/components/expressions/ExpressionEditor.vue";
import NewExpression from "@/components/expressions/NewExpression.vue";
import { getExpression } from "@/api/v2";

export default {
  name: "ExpressionViewer",

  components: {
    ExpressionEditor,
    NewExpression,
  },

  props: {
    inputExpr: {
      type: Object,
    },
    expressions: { type: Object },
    showBackButton: { type: Boolean, default: true },
  },

  data() {
    return {
      exprIdx: 0,
      exprNest: [],
      metaOverlay: false,
      loadingNext: false,
      dialogNewExpression: false,
      isLoading: false,
      goBackAllowed: true,
    };
  },

  computed: {
    activeExpr() {
      const expr = this.exprNest[this.exprIdx];
      if (!expr) return { id: 0 };
      else return expr;
    },
  },

  methods: {
    pauseForSave(saveRequired) {
      this.goBackAllowed = !saveRequired;
      this.$emit("saving-required", saveRequired);
    },

    goBack() {
      if (this.goBackAllowed) {
        this.$router.back();
      } else {
        this.$showAlert({
          text: `Unsaved changes within your expression will be lost if you go back now
        <br> <b>Would you like to continue without saving?</b> `,
          type: "warning",
          choice: true,
        }).then((confirmed) => {
          if (confirmed) this.$router.back();
        });
      }
    },

    initialize() {
      this.metaOverlay = true;
      this.dialogNewExpression = true;
    },
    activateExpr(idx) {
      this.exprIdx = idx;
    },

    // call from expression editor for saving, should be able to replace this with auto save feature
    exprModified(e, idx) {
      this.pauseForSave(e);

      this.exprNest[idx].needsSaving = e;
    },

    // call from expression editor to get typed expression
    nextExpression(exp) {
      if (!exp) {
        // Creating a new one
        this.exprNest = [
          ...this.exprNest.slice(0, this.exprIdx + 1),
          {},
          ...this.exprNest.slice(this.exprIdx + 1),
        ];
        this.exprIdx++;
        this.metaOverlay = true;
        return;
      }

      this.loadingNext = true;
      const exprName = Object.prototype.hasOwnProperty.call(
        exp,
        "ExpressionName"
      )
        ? exp.ExpressionName
        : exp.name; // kludge
      const exprOwner = Object.prototype.hasOwnProperty.call(
        exp,
        "ExpressionOwner"
      )
        ? exp.ExpressionOwner
        : exp.owner; // kludge

      getExpression(exprName, exprOwner)
        .then((response) => {
          if (!response.Errors && !response.Warnings) {
            const unsavedExprs = this.exprNest
              .slice(this.exprIdx + 1)
              .filter((expr) => {
                return expr.needsSaving;
              });
            if (unsavedExprs.length > 0) {
              this.$showAlert({
                text: `Expression${
                  unsavedExprs.length === 1 ? "" : "s"
                } ${unsavedExprs
                  .map((e) => {
                    return `"${e.name}"`;
                  })
                  .join(", ")} require${
                  unsavedExprs.length === 1 ? "s" : ""
                } saving`,
                type: "warning",
              });
            } else {
              response.needsSaving = false;
              this.exprNest = [
                ...this.exprNest.slice(0, this.exprIdx + 1),
                response,
              ];
              this.exprIdx++;
            }
          }
        })
        .catch((errors) => {
          if (errors.response.data.Errors) {
            this.$showAlert({
              text: errors.response.data.Errors,
              type: "error",
            });
          }
          if (errors.response.data.Warnings) {
            this.$showAlert({
              text: errors.response.data.Warnings,
              type: "warning",
            });
          }
        })
        .finally(() => (this.loadingNext = false));
    },

    closeMeta() {
      this.metaOverlay = false;
      this.dialogNewExpression = false;
      if (this.exprNest.length === 0)
        this.$router.push({ name: "Expression Manager" });
    },

    // call from new expression or expression method
    updateMeta(expression) {
      if (!this.exprNest || this.exprNest.length === 0) {
        // New expression created from Expression Navigation
        this.exprNest = [{ ...expression }];
      } else if (!this.activeExpr.updated && !this.activeExpr.name) {
        // Complete creating a new expression from Expression method
        this.exprNest = [
          ...this.exprNest.slice(0, this.exprIdx),
          ...this.exprNest.slice(this.exprIdx + 1),
        ];
        this.exprIdx--;
      } else {
        // Normal metadata update
        this.exprNest[this.exprIdx] = expression;
      }

      this.metaOverlay = false;
    },
  },

  created() {
    if (this.inputExpr) {
      this.isLoading = true;
      getExpression(
        this.inputExpr.ExpressionName,
        this.inputExpr.ExpressionOwner
      )
        .then((response) => {
          if (!response.Errors && !response.Warnings) {
            this.exprNest = [{ ...response }];
          }
        })
        .catch((errors) => {
          if (errors.response.data.Errors) {
            this.$showAlert({
              text: errors.response.data.Errors,
              type: "error",
            });
          }
          if (errors.response.data.Warnings) {
            this.$showAlert({
              text: errors.response.data.Warnings,
              type: "warning",
            });
          }
        })
        .finally(() => {
          this.selectedExpressions = [];
          this.isLoading = false;
        });
    } else {
      this.initialize();
    }
  },
};
</script>

import { Auth, API } from "aws-amplify";
import awsconfig from "@/aws-exports";
import { replaceSlash } from "./helpers";

// helper function to replace forward slash to back slash in expressionNames
// API will do the reverse and swap back

function encodeLayerDefinition(layer) {
  // remove any properties that aren't needed and that may have characters that cannot be encoded
  const prop1 = "VirtualLayerName";
  const prop2 = "LayerName";
  const prop3 = "Description";
  const prop4 = "Units";

  const {
    [prop1]: _removedProperty1,
    [prop2]: _removedProperty2,
    [prop3]: _removedProperty3,
    [prop4]: _removedProperty4,
    ...remainingObject
  } = layer;

  _removedProperty1 !== 0;
  _removedProperty2 !== 0;
  _removedProperty3 !== 0;
  _removedProperty4 !== 0;

  // stringify layer and encode as base64

  const layerDefinition = JSON.stringify(remainingObject);

  const encodedLayerDefinition = btoa(layerDefinition).replaceAll(" ", "+");

  return encodedLayerDefinition;
}

// generates a zip file containing rasters from a single flow dataset
async function exportDataset(requestBody) {
  let endpoint = `/dataset/download`;
  const authData = await Auth.currentSession();
  const response = await API.post("flow-geotools", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: requestBody,
  });

  return response;
}

// calculates dataset statistics
async function getDatasetLayerStats(
  datasetInfo,
  studyArea,
  layerMasks,
  dataLayerTimeInvariantSnapTime = null,
  timeout = null,
  vectorTileZoomLevel = null,
  overviewLevel = null
) {
  let endpoint = `/dataset/stats2?`;
  const authData = await Auth.currentSession();

  const dataLayer = encodeLayerDefinition(datasetInfo);

  const allMasks = [...layerMasks, ...studyArea];

  const headers = {
    Authorization: "Bearer " + authData.idToken.jwtToken,
    "data-layer": dataLayer,
  };

  if (allMasks.length > 0) {
    headers.masks = allMasks.map((layerMaskInfo) =>
      encodeLayerDefinition(layerMaskInfo)
    );
  }

  if (timeout) {
    endpoint += `timeout=${timeout}`;
  }

  if (dataLayerTimeInvariantSnapTime) {
    dataLayerTimeInvariantSnapTime = encodeURIComponent(
      dataLayerTimeInvariantSnapTime
    );
    endpoint += `&Date=${dataLayerTimeInvariantSnapTime}`;
  } else if (datasetInfo.date) {
    datasetInfo.date = encodeURIComponent(datasetInfo.date);
    endpoint += `&Date=${datasetInfo.date}`;
  }

  if (vectorTileZoomLevel) {
    endpoint += `&vector_tile_zoom_level=${vectorTileZoomLevel}`;
  }

  if (overviewLevel) {
    endpoint += `&overview_level=${overviewLevel}`;
  }

  const response = await API.get("flow-geotools", endpoint, {
    headers,
  });

  if (dataLayerTimeInvariantSnapTime) {
    response.Date = null;
  }

  return response;
}

// calculates dataset statistics
async function getApproxDatasetLayerStats(
  datasetInfo,
  studyArea,
  layerMasks,
  timeout = null,
  dataLayerTimeInvariantSnapTime = null
) {
  let endpoint = `/dataset/stats2/approx?`;

  const authData = await Auth.currentSession();

  const dataLayer = encodeLayerDefinition(datasetInfo);

  const allMasks = [...layerMasks, ...studyArea];

  const headers = {
    Authorization: "Bearer " + authData.idToken.jwtToken,
    "data-layer": dataLayer,
  };

  if (timeout) {
    endpoint += `timeout=${timeout}`;
  }

  if (dataLayerTimeInvariantSnapTime) {
    dataLayerTimeInvariantSnapTime = encodeURIComponent(
      dataLayerTimeInvariantSnapTime
    );
    endpoint += `&Date=${dataLayerTimeInvariantSnapTime}`;
  } else if (datasetInfo.date) {
    datasetInfo.date = encodeURIComponent(datasetInfo.date);
    endpoint += `&Date=${datasetInfo.date}`;
  }

  if (allMasks.length > 0) {
    headers.masks = allMasks.map((layerMaskInfo) =>
      encodeLayerDefinition(layerMaskInfo)
    );
  }

  const response = await API.get("flow-geotools", endpoint, {
    headers,
  });

  // update date to null
  if (dataLayerTimeInvariantSnapTime) {
    response.Date = null;
  }

  return response;
}

async function getDatasetMask(
  datasetInfo,
  mapExtent,
  date = "ind",
  negativeMask = true,
  vectorTileZoomLevel = 6
) {
  let endpoint = `/dataset/mask2?`;

  endpoint += `vector_tile_zoom_level=${vectorTileZoomLevel}`;

  if (date !== "ind") {
    date = encodeURIComponent(date);
    endpoint += `&Date=${date}`;
  }

  if (negativeMask) {
    endpoint += `&negative_mask=${negativeMask}&negative_mask_xmin=${mapExtent.sw[0]}&negative_mask_ymin=${mapExtent.sw[1]}&negative_mask_xmax=${mapExtent.ne[0]}&negative_mask_ymax=${mapExtent.ne[1]}`;
  } else endpoint += `&negative_mask=${negativeMask}`;

  const authData = await Auth.currentSession();
  const response = await API.get("flow-geotools", endpoint, {
    headers: {
      Authorization: "Bearer " + authData.idToken.jwtToken,
      "data-layer": encodeLayerDefinition(datasetInfo),
    },
  });

  return response;
}

async function getLayerFields(datasetInfo) {
  let endpoint = `/dataset/layer/fields2?`;

  const authData = await Auth.currentSession();
  const response = await API.get("flow-geotools", endpoint, {
    headers: {
      Authorization: "Bearer " + authData.idToken.jwtToken,
      "data-layer": encodeLayerDefinition(datasetInfo),
    },
  });

  return response;
}

// calculates dataset statistics
async function getDatasetLayerAttributeTable(
  datasetInfo,
  maskInfo,
  timeout = null,
  vectorTileZoomLevel = null
) {
  let endpoint = `/dataset/attribute-table?`;
  const authData = await Auth.currentSession();

  const dataLayer = encodeLayerDefinition(datasetInfo);
  const masks = encodeLayerDefinition(maskInfo);

  const headers = {
    Authorization: "Bearer " + authData.idToken.jwtToken,
    "data-layer": dataLayer,
  };

  if (timeout) {
    endpoint += `timeout=${timeout}`;
  }

  if (datasetInfo.date) {
    datasetInfo.date = encodeURIComponent(datasetInfo.date);
    endpoint += `&Date=${datasetInfo.date}`;
  }

  const hasMasks = (objName) => {
    return Object.keys(objName).length > 0;
  };

  if (hasMasks(maskInfo)) {
    headers.masks = [masks];
  }

  if (vectorTileZoomLevel) {
    endpoint += `&vector_tile_zoom_level=${vectorTileZoomLevel}`;
  }

  const response = await API.get("flow-geotools", endpoint, {
    headers,
  });

  return response;
}

// Duplicates the layer for every value that matches the filter field applied.
async function duplicateFilteredLayer(
  datasetName,
  datasetOwner,
  datasetLayerName
) {
  datasetName = replaceSlash(datasetName);
  datasetName = encodeURIComponent(datasetName);
  datasetOwner = encodeURIComponent(datasetOwner);
  datasetLayerName = encodeURIComponent(datasetLayerName);
  let endpoint = `/dataset/layer/copy-and-replace-for-each-filter-value?DatasetName=${datasetName}&DatasetOwner=${datasetOwner}&DatasetLayerName=${datasetLayerName}`;
  const authData = await Auth.currentSession();
  const response = await API.put("flow-geotools", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });

  return response;
}
// attribute joiner
function buildFlowPbfTileURL(selectedLayer, filePath, parameters) {
  const method = "pbf2";

  // stringify layer and encode as base64
  const layerDefinition = encodeLayerDefinition(selectedLayer);

  parameters["data-layer"] = layerDefinition;

  const urlParams = Object.keys(parameters)
    .map((i) => `${i}=${parameters[i]}`)
    .join("&");

  const url = `${awsconfig.aws_misc.flow_attribute_joiner_endpoint}${method}/${filePath}?${urlParams}`;

  return url;
}

// FWWF specific
async function getSeasonList(ranchOwner, ranchName) {
  ranchOwner = encodeURIComponent(ranchOwner);
  ranchName = encodeURIComponent(ranchName);

  let endpoint = `/fwwf/seasons/${ranchOwner}/${ranchName}`;

  const authData = await Auth.currentSession();
  const response = await API.get("flow-geotools", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });

  return response;
}

async function uploadSeasonFiles(
  ranchName,
  grazingRecordURI,
  paddockFileURIs,
  query = null,
  requestCount
) {
  const requestBody = {
    RanchName: ranchName,
    GrazingRecordS3Uri: grazingRecordURI,
    PaddockBoundariesS3Uris: paddockFileURIs,
  };

  if (query) requestBody.Query = query;

  const endpoint =
    requestCount === 1
      ? `/fwwf/paddock-boundaries-and-grazing-plan1/`
      : `/fwwf/paddock-boundaries-and-grazing-plan2/`;

  const authData = await Auth.currentSession();

  const response = await API.put("flow-geotools", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: requestBody,
  });

  return response;
}

// solo file upload
async function uploadPaddockBoundaries(
  ranchName,
  paddockFileURIs,
  query = null,
  startDate,
  endDate
) {
  const requestBody = {
    RanchName: ranchName,
    PaddockBoundariesS3Uris: paddockFileURIs,
    StartDate: startDate,
    EndDate: endDate,
  };

  if (query) requestBody.Query = query;

  const endpoint = `/fwwf/paddock-boundaries`;

  const authData = await Auth.currentSession();

  const response = await API.put("flow-geotools", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: requestBody,
  });

  return response;
}

// returns full dataset details
async function deleteSeason(tableInformation, sID) {
  let endpoint = `/fwwf/table/row/`;

  let body = {
    DatasetName: tableInformation.DatasetName,
    DatasetOwner: tableInformation.DatasetOwner,
    FileId: tableInformation.FileId,
    Index: sID,
  };

  const authData = await Auth.currentSession();
  const response = await API.del("flow-geotools", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body,
  });

  return response;
}

async function updateSeasonTableRow(ranchName, ranchOwner, rowInfo) {
  // const requestBody = {
  //   RanchName: ranchName,
  //   RanchOwner: ranchOwner,
  //   RowInfo: rowInfo,
  // };

  // let endpoint = `/fwwf/seasons/update-season`;

  // const authData = await Auth.currentSession();
  // const response = await API.put("flow-geotools", endpoint, {
  //   headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  //   body: requestBody,
  // });

  // return response

  // TODO: temp return updated row

  return { ...rowInfo, Errors: null, Warnings: null };
}

// returns full dataset details
async function getFWWFVirtualDatasetLayer(
  datasetName,
  datasetOwner,
  layerName,
  singleSeason
) {
  datasetName = replaceSlash(datasetName);
  datasetName = encodeURIComponent(datasetName);
  datasetOwner = encodeURIComponent(datasetOwner);
  layerName = encodeURIComponent(layerName);

  let endpoint = `/fwwf/virtual_layer?DatasetName=${datasetName}&DatasetOwner=${datasetOwner}&LayerName=${layerName}`;

  endpoint += singleSeason ? "&single_season=true" : "&single_season=false";

  const authData = await Auth.currentSession();
  const response = await API.get("flow-geotools", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });

  return response;
}

async function getNewSeasonData({
  RanchName,
  RanchOwner,
  SeasonType,
  PaddockBoundariesS3Uris,
  Query,
}) {
  let endpoint = `/fwwf/get-season-data`;

  let body = {
    PaddockBoundariesS3Uris,
    Query,
    RanchName,
    RanchOwner,
    SeasonType,
  };

  const authData = await Auth.currentSession();

  const response = await API.post("flow-geotools", endpoint, {
    headers: {
      Authorization: "Bearer " + authData.idToken.jwtToken,
    },
    body: body,
  });

  return response;
}

async function postNewSeasonData(formBody) {
  let endpoint = `/fwwf/new-season-data`;

  let body = structuredClone(formBody);

  delete body.Errors;
  delete body.Warnings;

  body.Cells.forEach((cell) => {
    delete cell._key;
  });

  const authData = await Auth.currentSession();

  const response = await API.post("flow-geotools", endpoint, {
    headers: {
      Authorization: "Bearer " + authData.idToken.jwtToken,
    },
    body: body,
  });

  return response;
}

async function getGrazingSheet(RanchName, Date, Format) {
  let endpoint = "/fwwf/get-grazing-sheet";

  let body = {
    RanchName,
    Date,
    Format,
  };

  const authData = await Auth.currentSession();

  const response = await API.post("flow-geotools", endpoint, {
    headers: {
      Authorization: "Bearer " + authData.idToken.jwtToken,
    },
    body: body,
  });

  return response;
}

export {
  exportDataset,
  getDatasetLayerStats,
  getApproxDatasetLayerStats,
  getDatasetMask,
  getLayerFields,
  getDatasetLayerAttributeTable,
  duplicateFilteredLayer,
  buildFlowPbfTileURL,
  encodeLayerDefinition,
  getSeasonList,
  uploadSeasonFiles,
  uploadPaddockBoundaries,
  updateSeasonTableRow,
  getFWWFVirtualDatasetLayer,
  deleteSeason,
  getNewSeasonData,
  postNewSeasonData,
  getGrazingSheet,
};

<template>
  <div>
    <v-card class="layer-select-card mb-4">
      <v-card-text>
        <v-subheader class="layer-title">Layer </v-subheader>
        <div class="layer-select-container pb-2 pl-0">
          <v-combobox
            v-if="!virtualLayerMode"
            v-model="layerName"
            class="layer-select-textbox"
            :items="layerNames"
            solo
            dense
            :hint="
              this.allActions.create.value ? 'Enter a new layer name' : null
            "
            :persistent-hint="this.allActions.create.value"
            clearable
            :hide-no-data="false"
            @blur="
              if (!layerName) {
                layerName = 'Default';
              }
            "
            :color="$store.state.settings.appColor"
          >
            <template v-slot:item="{ item, on, attrs }">
              <template v-if="item === 'Default'">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-divider></v-divider>
                  <v-list-item-content>
                    <v-list-item-title class="d-flex justify-center">
                      {{ item }}</v-list-item-title
                    >
                  </v-list-item-content>
                  <v-divider></v-divider>
                </v-list-item>
              </template>

              <template v-else>
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-content>
                    <v-list-item-title> {{ item }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </template>
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  + Create new layer (press <kbd>enter</kbd>)
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span class="text-body-2">{{ item }}</span>
            </template>
          </v-combobox>
          <v-select
            v-else
            v-model="layerName"
            :items="layerNames"
            solo
            dense
            hide-details
            :color="$store.state.settings.appColor"
          >
          </v-select>
          <div class="layer-select-button-container pl-4">
            <v-btn
              v-for="item in actions"
              :disabled="item.disabled"
              :key="item.name"
              @click="
                item.value = !item.value;
                if (item.callback) item.callback(item.value);
              "
              :loading="item.loading"
              fab
              x-small
              :dark="item.value"
              :color="item.value ? item.activeColor : item.deactivatedColor"
            >
              <v-icon>{{ item.icon }}</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="layer-editor-card mb-4" v-if="layerName">
      <v-card-text>
        <layer-editor
          :key="layerName"
          :dataset-name="datasetName"
          :dataset-owner="datasetOwner"
          :dataset-type="datasetType"
          :full-dataset="fullDataset"
          :layer-name="layerName"
          :layer-names="layerNames"
          :virtual-layer-mode="virtualLayerMode"
          :edit="allActions.edit.value"
          @change="onChange"
        >
        </layer-editor>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { updateDatasetLayer, deleteDatasetLayer } from "@/api/v2.js";
import LayerEditor from "@/components/datasets/LayerEditor.vue";

export default {
  name: "LayerSelector",

  components: {
    LayerEditor,
  },

  props: {
    datasetName: { type: String },
    datasetOwner: { type: String },
    datasetType: { type: String },
    fullDataset: { type: Object },
    initialLayerName: { type: String, default: "Default" },
    layerNames: {
      type: Array,
    },
    allowedActions: {
      type: Array,
      default: () => ["create", "edit", "save", "delete"],
    },
    virtualLayerMode: { type: Boolean, default: false },
  },

  data() {
    return {
      layerName: this.initialLayerName,
      layerValue: null,
      layerIsVirtual: false,

      allActions: {
        create: {
          name: "create",
          icon: "mdi-plus-thick",
          callback: this.onClickCreate,
          loading: false,
          value: false,
          activeColor: "success",
          deactivatedColor: null,
          disabled: false,
          order: 0,
        },
        edit: {
          name: "edit",
          icon: "mdi-pencil",
          callback: this.onClickEdit,
          loading: false,
          value: false,
          activeColor: "warning",
          deactivatedColor: null,
          disabled: false,
          order: 1,
        },
        save: {
          name: "save",
          icon: "mdi-content-save",
          callback: this.onClickSave,
          loading: false,
          value: false,
          activeColor: "secondary",
          deactivatedColor: "warning",
          disabled: true,
          order: 2,
        },
        delete: {
          name: "delete",
          icon: "mdi-delete",
          callback: this.onClickDelete,
          loading: false,
          value: false,
          activeColor: null,
          deactivatedColor: "error",
          disabled: this.initialLayerName === "Default",
          order: 3,
        },
      },
      // disabledActions: new Set(["save"]),
    };
  },
  watch: {
    layerName(layer) {
      this.allActions.edit.value = false;

      if (layer === null || layer === "") {
        console.log(layer, "nullish");
        this.allActions.create.value = true;
        this.allActions.delete.disabled = true;
        this.allActions.edit.disabled = true;
      } else if (layer === "Default") {
        console.log(layer, "default");
        this.allActions.delete.disabled = true;
        this.allActions.create.value = false;
      } else {
        console.log(layer, "else");
        this.allActions.create.value = false;
        this.allActions.edit.disabled = false;
      }
    },
  },

  computed: {
    actions() {
      let listOfActions = [];
      this.allowedActions.forEach((name) => {
        if (this.allActions[name] !== undefined) {
          listOfActions.push(this.allActions[name]);
        }
      });
      listOfActions.sort((a, b) => (a.order > b.order ? 1 : -1));
      return listOfActions;
    },
  },

  methods: {
    onClickEdit() {
      this.updateDisabledActions();
    },

    async saveLayer() {
      this.allActions.save.loading = true;
      const layer = { ...this.layerValue }; // important: create copy because this.layerValue might change

      await updateDatasetLayer(layer)
        .then((response) => {
          if (response.Warnings) {
            this.$showAlert({
              text: response.Warnings,
              type: "warning",
            });
          }
          //   if layerName is not in layerNames, add it if api was successful
          if (!this.layerNames.includes(this.layerName)) {
            const tempLayerNames = [...this.layerNames];
            tempLayerNames.push(this.layerName);
            this.$emit("update-layer-list", tempLayerNames);
          }
        })
        .catch(() => {
          this.$showAlert({
            text: "There was an error saving the layer.",
            type: "error",
          });
        })
        .finally(() => {
          this.allActions.create.loading = false;
          this.allActions.save.loading = false;
          this.allActions.save.disabled = true;
          this.allActions.save.value = false;
          this.allActions.create.disabled = false;
        });
    },
    autoSaveNewLayer() {
      this.allActions.create.loading = true;
      this.allActions.create.value = false;
      this.saveLayer();
    },
    onClickSave() {
      if (this.allActions.save.value) {
        this.saveLayer();
      }
    },
    onClickDelete() {
      if (this.allActions.delete.value) {
        const layer = { ...this.layerValue }; // important: create copy because this.layerValue might change
        this.$showAlert({
          text: `Are you sure you want to delete the layer '${layer.LayerName}'?`,
          type: "warning",
          choice: true,
        }).then((confirmed) => {
          if (confirmed) {
            this.allActions.delete.loading = true;
            deleteDatasetLayer(layer)
              .then((response) => {
                if (response.Warnings) {
                  this.$showAlert({
                    text: response.Warnings,
                    type: "warning",
                  });
                }

                const tempLayerNames = this.layerNames.filter((name) => {
                  return name !== layer.LayerName;
                });
                this.$emit("update-layer-list", tempLayerNames);
                this.layerName = "Default";
              })
              .catch(() => {
                this.$showAlert({
                  text: "There was an error deleting the layer.",
                  type: "error",
                });
              })
              .finally(() => {
                this.allActions.delete.loading = false;
                this.allActions.delete.value = false;
              });
          } else {
            this.allActions.delete.value = false;
            this.allActions.create.disabled = false;
          }
        });
      } else {
        //shouldn't happen
      }
    },
    onClickCreate(newVal) {
      this.layerName = null;

      if (!newVal) this.layerName = "Default";
    },

    onChange(layer, isVirtualLayer) {
      this.layerValue = layer;
      this.layerIsVirtual = isVirtualLayer;

      //   autosave a new layer
      if (!this.layerNames.includes(layer.LayerName)) {
        this.autoSaveNewLayer();
      }

      this.updateDisabledActions();
    },
    updateDisabledActions() {
      if (this.allActions.edit.value && this.layerName === "Default") {
        this.allActions.save.disabled = true;
        this.allActions.create.disabled = true;
        this.allActions.delete.disabled = true;
      } else if (this.allActions.edit.value) {
        this.allActions.save.disabled = true;
        this.allActions.create.disabled = true;
        this.allActions.delete.disabled = false;
      } else {
        this.allActions.delete.disabled = true;
        this.allActions.create.disabled = false;
        if (!this.allActions.save.disabled)
          this.allActions.create.disabled = false;
        if (this.layerIsVirtual) {
          this.allActions.save.disabled = false;
        } else {
          this.allActions.save.disabled = true;
        }
      }
    },
  },
};
</script>

<style scoped>
.layer-select-card {
  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: #eeeeee;
}
.layer-title {
  color: rgba(0, 0, 0, 1);
  font-size: 0.8125rem;
  font-weight: 500;
  padding: 0px 0px 4px 2px;
  height: initial;
}

.layer-select-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.layer-select-textbox {
  display: flex;
  flex: 1 0 11rem;
}

.layer-select-button-container {
  display: flex;
  flex: 1 0 2rem;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  gap: 0.5rem;
}

.layer-editor-card {
  background-color: #f6f6f6;
}
</style>

<template>
  <div v-if="expressions.length === 0" :style="`color: ${color};`">
    <div class="d-flex pa-5 justify-center">
      <v-icon>mdi-keyboard-outline</v-icon>
      <h4 style="padding-left: 30px">Start typing an Expression</h4>
    </div>
    <div class="d-flex flex-column justify-center">
      <v-autocomplete
        v-model="methodInfo"
        :items="allMethods"
        return-object
        item-text="s"
        outlined
        label="Search Available Methods"
        clearable
        :color="color"
      >
        <template v-slot:item="data">
          <template v-if="typeof data.item !== 'object'">
            <v-subheader v-text="data.item"></v-subheader>
            <v-divider></v-divider>
          </template>
          <template v-else>
            <v-list-item-content>
              {{ data.item.s }}
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
      <v-card v-if="methodInfo">
        <v-card-title>{{ methodInfo.s }}</v-card-title>
        <v-card-text>
          <div class="d-flex">
            {{ methodInfo.description }}
            <v-spacer></v-spacer>
            <v-btn @click="$emit('add-method', { method: methodInfo })" icon
              ><v-icon>mdi-open-in-new</v-icon></v-btn
            >
          </div>
        </v-card-text>
        <v-simple-table v-if="methodInfo.params.length > 0">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Parameter</th>
                <th class="text-left">Required</th>
                <th class="text-left">Default</th>
                <th class="text-left">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="param in methodInfo.params" :key="param.name">
                <td>{{ param.name }}</td>
                <td>
                  <v-icon small>{{
                    param.required ? "mdi-check" : null
                  }}</v-icon>
                </td>
                <td>{{ param.default !== null ? param.default : "-" }}</td>
                <td>{{ param.description }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </div>
  </div>
  <div v-else>
    <v-subheader>Nested Expressions</v-subheader>
    <v-divider></v-divider>
    <v-list dense class="pa-2" two-line>
      <v-list-item-group :color="$store.state.settings.appColor">
        <v-list-item
          v-for="(exp, i) in expressions"
          :key="i"
          @click="selectExpression(exp)"
        >
          <v-list-item-icon>
            <v-icon>mdi-function-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ exp.name }}</v-list-item-title>
            <v-list-item-subtitle v-if="exp.cat">{{
              exp.cat
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { nestedExpressions } from "@/common/expressionParserV2.js";

export default {
  name: "ExpressionNest",

  props: {
    expression: String,
  },

  data() {
    return {
      methodInfo: null,
    };
  },

  computed: {
    color() {
      return this.$store.state.settings.appColor;
    },
    allMethods() {
      const methods = this.$store.state.expressions.methods;
      const keys = Object.keys(methods);

      let allMethods = [];

      keys.forEach((key) => {
        allMethods.push(key);
        methods[key].forEach((method) => {
          allMethods.push(method);
        });
      });

      return allMethods;
    },

    expressions() {
      let allExpressions = nestedExpressions(this.expression);
      let currentUser = this.$store.state.account.user.attributes.email;
      allExpressions = allExpressions.map((targetExpression) => {
        let matches = this.$store.state.accountData.expressionList.expressions.AllExpressions.filter(
          (expression) => expression.ExpressionName == targetExpression.name
        );
        let match = null;
        if (matches.length == 1) {
          match = matches[0];
        } else {
          // multiple matches, so select the current users version
          matches = matches.filter(
            (expression) => expression.ExpressionOwner == currentUser
          );
          if (matches.length == 1) {
            match = matches[0];
          }
        }
        if (match == null) {
          alert(`Nested expression '${targetExpression.name}' does not exist`);
        }
        return { ...targetExpression, owner: match.ExpressionOwner };
      });
      return allExpressions;
    },
  },

  methods: {
    selectExpression(exp) {
      this.$emit("next-expression", exp);
    },
  },
};
</script>

<template>
  <div id="tag-filter-container" v-show="!isLoading" class="pa-2 mx-4">
    <v-row justify="center">
      <v-col class="d-flex flex-row" cols="12" lg="10" xlg="9">
        <v-combobox
          label="Filter by Tag"
          v-model="selectedCustomTags"
          :items="remainingCustomTags"
          chips
          deletable-chips
          small-chips
          clearable
          multiple
          hide-selected
          hide-details
          outlined
          :color="$store.state.settings.appColor"
          prepend-icon="mdi-tag"
          loading="isLoading"
          dense
        >
          <template v-slot:selection="data">
            <v-chip
              :input-value="data.selected"
              close
              small
              :color="getChipColor(data.item)"
              @input="remove(data.item)"
              @click:close="deleteChip(selectedCustomTags, data.item)"
            >
              {{ data.item }}
            </v-chip>
          </template>
        </v-combobox>
        <div
          class="pl-2 d-flex align-center"
          v-if="filterByProject || filterByExpression"
        >
          <v-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            small
            raised
            depressed
            text
            color="blue"
            class="text-capitalize"
            @click="expandAdvancedSearch = !expandAdvancedSearch"
          >
            <span> Additional Filtering Options </span>
            <v-icon color="black" right>{{
              expandAdvancedSearch ? "mdi-menu-up" : "mdi-menu-down"
            }}</v-icon>
          </v-btn>

          <v-tooltip v-else bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="expandAdvancedSearch = !expandAdvancedSearch"
                ><v-icon>mdi-tune</v-icon>
              </v-btn>
            </template>
            <span>Additional Filtering Options</span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>

    <v-expand-transition>
      <div v-show="expandAdvancedSearch">
        <v-row align="center" class="mt-3" v-if="filterByProject">
          <v-col cols="12" md="6" xl="4">
            <v-combobox
              label="Filter by Project"
              v-model="selectedProjectTags"
              :items="remainingProjectTags"
              chips
              deletable-chips
              small-chips
              clearable
              multiple
              hide-selected
              hide-details
              outlined
              :color="$store.state.settings.appColor"
              prepend-icon="mdi-book-multiple"
              loading="isLoading"
              dense
            >
              <template v-slot:selection="data">
                <v-chip
                  :input-value="data.selected"
                  close
                  small
                  :color="getChipColor(`PROJECT:${data.item}`)"
                  @input="remove(data.item)"
                  @click:close="deleteChip(selectedProjectTags, data.item)"
                >
                  {{ data.item }}
                </v-chip>
              </template>
            </v-combobox>
          </v-col>

          <v-col cols="12" md="6" xl="4" v-if="filterByProject">
            <v-combobox
              label="Filter by Scenario"
              v-model="selectedScenarioTags"
              :items="remainingScenarioTags"
              chips
              deletable-chips
              small-chips
              clearable
              multiple
              hide-selected
              hide-details
              outlined
              :color="$store.state.settings.appColor"
              prepend-icon="mdi-pine-tree"
              loading="isLoading"
              dense
            >
              <template v-slot:selection="data">
                <v-chip
                  :input-value="data.selected"
                  close
                  small
                  :color="getChipColor(`SCENARIO:${data.item}`)"
                  @input="remove(data.item)"
                  @click:close="deleteChip(selectedScenarioTags, data.item)"
                >
                  {{ data.item }}
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="12" xl="4" v-if="filterByExpression">
            <v-combobox
              label="Filter by Expression"
              v-model="selectedExpressionTags"
              :items="remainingExpressionTags"
              chips
              deletable-chips
              small-chips
              clearable
              multiple
              hide-selected
              hide-details
              outlined
              :color="$store.state.settings.appColor"
              prepend-icon="mdi-function-variant"
              loading="isLoading"
              dense
            >
              <template v-slot:selection="data">
                <v-chip
                  :input-value="data.selected"
                  close
                  small
                  :color="getChipColor(`EXPRESSION:${data.item}`)"
                  @input="remove(data.item)"
                  @click:close="deleteChip(selectedExpressionTags, data.item)"
                >
                  {{ data.item }}
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import { sortCaseInsensitive } from "@/helpers/formatting.js";
import { getChipColor, deleteChip } from "@/helpers/chipColors.js";

export default {
  name: "TagFilter",

  props: {
    isLoading: { type: Boolean, default: false },
    allTaggedItems: { type: Array, default: () => [] },
    tagSelectedInParent: { type: String, default: null },
    defaultTags: { type: Array, default: () => [] },
    filterByProject: { type: Boolean, default: true },
    filterByExpression: { type: Boolean, default: true },
  },

  data() {
    return {
      expandAdvancedSearch: false,
      selectedCustomTags: [],
      selectedProjectTags: [],
      selectedExpressionTags: [],
      selectedScenarioTags: [],
    };
  },
  computed: {
    remainingProjectTags() {
      return this.getRemainingTagsWithPrefix("PROJECT:");
    },

    remainingScenarioTags() {
      return this.getRemainingTagsWithPrefix("SCENARIO:");
    },

    remainingExpressionTags() {
      return this.getRemainingTagsWithPrefix("EXPRESSION:");
    },

    remainingCustomTags() {
      return this.remainingTags.filter((tag) => {
        return (
          !tag.startsWith("PROJECT:") &&
          !tag.startsWith("SCENARIO:") &&
          !tag.startsWith("EXPRESSION:")
        );
      });
    },

    selectedTags() {
      return [
        ...this.selectedCustomTags,
        ...this.addPrefixToEach("PROJECT:", this.selectedProjectTags),
        ...this.addPrefixToEach("EXPRESSION:", this.selectedExpressionTags),
        ...this.addPrefixToEach("SCENARIO:", this.selectedScenarioTags),
      ];
    },

    nonCustomTagCount() {
      return this.selectedTags.length - this.selectedCustomTags.length;
    },

    remainingTaggedItems() {
      let taggedItemList = this.allTaggedItems.filter((item) =>
        this.selectedTags.every((tag) => item.Tags.includes(tag))
      );
      this.$emit("updated", taggedItemList);
      return taggedItemList;
    },

    remainingTags() {
      let remaining = new Set();
      this.remainingTaggedItems.forEach((item) => {
        item.Tags.forEach(remaining.add, remaining);
      });
      const remainingAsArray = sortCaseInsensitive(Array.from(remaining));
      return remainingAsArray;
    },
  },

  watch: {
    tagSelectedInParent(tag) {
      this.addTag(tag);

      // clear click tag property in parent
      this.$emit("clear-selected-tag");
    },

    // open filter panel to show tags
    nonCustomTagCount(newCount, oldCount) {
      if (oldCount === 0 && newCount === 1) {
        this.expandAdvancedSearch = true;
      }
    },
  },

  methods: {
    getChipColor,
    deleteChip,

    getRemainingTagsWithPrefix(prefix) {
      const prefixLength = prefix.length;
      return this.remainingTags
        .filter((tag) => tag.startsWith(prefix))
        .map((tag) => {
          return tag.slice(prefixLength);
        });
    },
    addPrefixToEach(prefix, list) {
      return list.map((item) => {
        return prefix.concat(item);
      });
    },
    withoutPrefix(tag, prefix) {
      return tag.slice(prefix.length);
    },
    addTag(tag) {
      if (tag == null) {
        return;
      }

      if (tag.startsWith("PROJECT:")) {
        let tagWithoutPrefix = this.withoutPrefix(tag, "PROJECT:");
        if (!this.selectedProjectTags.includes(tagWithoutPrefix)) {
          this.selectedProjectTags.push(tagWithoutPrefix);
        } else {
          let tagIndex = this.selectedProjectTags.indexOf(tagWithoutPrefix);
          this.selectedProjectTags.splice(tagIndex, 1);
        }
      } else if (tag.startsWith("EXPRESSION:")) {
        let tagWithoutPrefix = this.withoutPrefix(tag, "EXPRESSION:");
        if (!this.selectedExpressionTags.includes(tagWithoutPrefix)) {
          this.selectedExpressionTags.push(tagWithoutPrefix);
        } else {
          let tagIndex = this.selectedExpressionTags.indexOf(tagWithoutPrefix);
          this.selectedExpressionTags.splice(tagIndex, 1);
        }
      } else if (tag.startsWith("SCENARIO:")) {
        let tagWithoutPrefix = this.withoutPrefix(tag, "SCENARIO:");
        if (!this.selectedScenarioTags.includes(tagWithoutPrefix)) {
          this.selectedScenarioTags.push(tagWithoutPrefix);
        } else {
          let tagIndex = this.selectedScenarioTags.indexOf(tagWithoutPrefix);
          this.selectedScenarioTags.splice(tagIndex, 1);
        }
      } else {
        if (!this.selectedCustomTags.includes(tag)) {
          this.selectedCustomTags.push(tag);
        } else {
          let tagIndex = this.selectedCustomTags.indexOf(tag);
          this.selectedCustomTags.splice(tagIndex, 1);
        }
      }
    },
  },

  created() {
    this.defaultTags.forEach((tag) => this.addTag(tag));
  },
};
</script>

<style scoped></style>

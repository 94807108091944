<template>
  <v-card elevation="0" class="content-wrapper">
    <div class="d-flex flex-wrap justify-space-between align-center py-3 px-6">
      <v-card-title
        class="text-h4 font-weight-bold d-flex flex-grow-1 justify-center flex-wrap"
        >{{ previousRun.ScenarioName }}
      </v-card-title>
      <div class="run-details-box flex-grow-1 flex-lg-grow-0 mx-4">
        <ul class="details-list">
          <li>
            Requested at:
            <span>{{ formatDateString(previousRun.CreatedAt) }}</span>
          </li>
          <li>
            Created by:
            <span>{{ previousRun.RunOwner }}</span>
          </li>
          <li>
            Status:
            <span>{{ previousRun.Status }}</span>
          </li>
          <li v-if="previousRun.RuntimeSeconds != null">
            Runtime:
            <span>{{ `${previousRun.RuntimeSeconds} seconds` }}</span>
          </li>

          <li v-if="previousRun.Status === 'Done'">
            Output Dataset:
            <span>{{ `${previousRun.ScenarioName}` }}</span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="addRunToDataviewer"
                  :loading="datasetLoading"
                >
                  <v-icon small color="black">mdi-open-in-new</v-icon></v-btn
                >
              </template>
              <span>Open dataset in map view</span>
              <!-- <span>Feature not yet available</span> -->
            </v-tooltip>
          </li>
        </ul>

        <div class="d-flex align-center justify-center flex-column">
          <v-btn icon @click="deleteRun(previousRun)">
            <v-icon color="red">mdi-trash-can</v-icon></v-btn
          >
        </div>
      </div>
    </div>

    <div class="content-container">
      <v-subheader class="pl-10 text--black font-weight-bold"
        >Inputs:</v-subheader
      >

      <!-- change analysis tool -->
      <v-card-text
        v-if="previousRun.ExpressionName.includes('Change Analysis')"
        class="inputs-container"
      >
        <!-- input devices from expression -->
        <!-- dataset/layer combination -->
        <v-row dense class="d-flex flex-wrap" justify="space-between">
          <v-col cols="12">
            <v-card elevation="0">
              <v-card-title class="input-titles"
                >Land Cover Dataset</v-card-title
              >
              <v-card-subtitle class="input-text">{{
                `${runSettings["Pre-conversion Land Cover Types"].name} - ${runSettings["Pre-conversion Land Cover Types"].feature_name}`
              }}</v-card-subtitle>
            </v-card>
          </v-col>

          <!-- categories pre/post type -->

          <v-col cols="12" md="6">
            <v-card elevation="0">
              <v-card-title class="input-titles"
                >Post Conversion Type(s)</v-card-title
              >
              <v-card-subtitle class="input-text">
                {{
                  runSettings[
                    "Post-conversion Land Cover Types"
                  ].categories.join(", ")
                }}
              </v-card-subtitle>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card elevation="0">
              <v-card-title class="input-titles"
                >Pre Conversion Type(s)</v-card-title
              >
              <v-card-subtitle class="input-text">
                {{
                  runSettings[
                    "Pre-conversion Land Cover Types"
                  ].categories.join(", ")
                }}
              </v-card-subtitle>
            </v-card>
          </v-col>

          <!-- study area and layer -->
          <v-col cols="12" md="6">
            <v-card elevation="0">
              <v-card-title class="input-titles"
                >Study Area Dataset</v-card-title
              >
              <v-card-subtitle class="input-text">
                {{ runSettings["Study Area Dataset"] }}
              </v-card-subtitle>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card elevation="0">
              <v-card-title class="input-titles">AOI</v-card-title>

              <v-card-subtitle class="input-text">
                {{ runSettings["Study Area"] }}
              </v-card-subtitle>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card elevation="0">
              <v-card-title class="input-titles">Start Year</v-card-title>
              <v-card-subtitle class="input-text">
                {{ runSettings["Pre-conversion Land Cover Types"].set_time }}
              </v-card-subtitle>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card elevation="0">
              <v-card-title class="input-titles">End Year</v-card-title>

              <v-card-subtitle class="input-text">
                {{ runSettings["Post-conversion Land Cover Types"].set_time }}
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- natural land cover risk tool -->
      <v-card-text
        class="inputs-container"
        v-if="previousRun.ExpressionName.includes('Natural Land Cover')"
      >
        <!-- dataset/layer combination -->
        <v-row dense class="d-flex flex-wrap" justify="space-between">
          <v-col cols="12" md="6">
            <v-card elevation="0">
              <v-card-title class="input-titles"
                >Land Cover Dataset</v-card-title
              >
              <v-card-subtitle class="input-text">{{
                `${runSettings["Natural Land Cover"].name} - ${runSettings["Natural Land Cover"].feature_name}`
              }}</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card elevation="0">
              <v-card-title class="input-titles"
                >Natural Land Cover Type(s)</v-card-title
              >
              <v-card-subtitle class="input-text">
                {{ runSettings["Natural Land Cover"].categories.join(", ") }}
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>

        <!-- study area -->
        <v-row dense class="d-flex flex-wrap" justify="space-between">
          <v-col cols="12" md="6">
            <v-card elevation="0">
              <v-card-title class="input-titles"
                >Study Area Dataset</v-card-title
              >
              <v-card-subtitle class="input-text">
                {{ runSettings["Study Area Dataset"] }}
              </v-card-subtitle>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card elevation="0">
              <v-card-title class="input-titles">AOI</v-card-title>

              <v-card-subtitle class="input-text">
                {{ runSettings["Study Area"] }}
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>

        <v-row dense class="d-flex flex-wrap" justify="space-between">
          <v-col cols="12" md="6">
            <v-card elevation="0">
              <v-card-title class="input-titles"
                >Projected or Historical Data</v-card-title
              >
              <v-card-subtitle class="input-text">
                {{
                  runSettings["Analysis Type"] === 0
                    ? "Historical"
                    : "Projected"
                }}
              </v-card-subtitle>
            </v-card>
          </v-col>

          <v-col cols="12" md="6" v-if="runSettings['Analysis Type'] === 0">
            <v-card elevation="0">
              <v-card-title class="input-titles"
                >Cropland Projection Dataset</v-card-title
              >
              <v-card-subtitle class="input-text">
                {{
                  `${runSettings["Historical Anthropogenic Land Cover"].name} - ${runSettings["Historical Anthropogenic Land Cover"].feature_name}`
                }}
              </v-card-subtitle>
            </v-card>
          </v-col>

          <v-col cols="12" md="6" v-else>
            <v-card elevation="0">
              <v-card-title class="input-titles"
                >Cropland Projection Dataset</v-card-title
              >
              <v-card-subtitle class="input-text">
                {{ runSettings["Cropland Projection Dataset"].name }}
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>

        <!-- start and end dates -->
        <v-row dense class="d-flex flex-wrap" justify="space-between">
          <v-col cols="6" v-if="runSettings['Analysis Type'] === 1">
            <v-card elevation="0">
              <v-card-title class="input-titles"
                >Projection End Year</v-card-title
              >
              <v-card-subtitle class="input-text">
                {{ runSettings["Cropland Projection Dataset"].set_time }}
              </v-card-subtitle>
            </v-card>
          </v-col>

          <v-col cols="6" v-else>
            <v-card elevation="0">
              <v-card-title class="input-titles">Start Year</v-card-title>

              <v-card-subtitle class="input-text">
                {{
                  runSettings["Historical Anthropogenic Land Cover"].set_time
                }}
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- restoration prioritization tool -->
      <v-card-text
        class="inputs-container"
        v-if="previousRun.ExpressionName.includes('Restoration Prioritization')"
      >
        <!-- dataset/layer combination -->
        <v-row dense class="d-flex flex-wrap" justify="space-between">
          <v-col cols="12">
            <v-card elevation="0">
              <v-card-title class="input-titles"
                >Land Cover Dataset</v-card-title
              >
              <v-card-subtitle class="input-text">{{
                `${runSettings["Natural land"].name} - ${runSettings["Natural land"].feature_name}`
              }}</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card elevation="0">
              <v-card-title class="input-titles">Crop Type(s)</v-card-title>
              <v-card-subtitle class="input-text">
                {{ runSettings["Farmland"].categories.join(", ") }}
              </v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card elevation="0">
              <v-card-title class="input-titles">Natural Type(s)</v-card-title>
              <v-card-subtitle class="input-text">
                {{ runSettings["Natural land"].categories.join(", ") }}
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>

        <!-- study area -->
        <v-row dense class="d-flex flex-wrap" justify="space-between">
          <v-col cols="12" md="6">
            <v-card elevation="0">
              <v-card-title class="input-titles"
                >Study Area Dataset</v-card-title
              >
              <v-card-subtitle class="input-text">
                {{ runSettings["Study Area Dataset"] }}
              </v-card-subtitle>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card elevation="0">
              <v-card-title class="input-titles">AOI</v-card-title>

              <v-card-subtitle class="input-text">
                {{ runSettings["Study Area"] }}
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>

        <v-row dense class="d-flex flex-wrap" justify="space-between">
          <v-col cols="12">
            <v-card elevation="0">
              <v-card-title class="input-titles"
                >Marginal Capability Threshold</v-card-title
              >
              <v-card-subtitle class="input-text">
                {{
                  findMarginalCapabilityThreshold(
                    runSettings["Marginal Capability Threshold"]
                  )
                }}
              </v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card elevation="0">
              <v-card-title class="input-titles"
                >Prioritization Radius (m)</v-card-title
              >
              <v-card-subtitle class="input-text">
                {{ runSettings["Prioritization Radius (m)"] }}
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- status -->
      <v-subheader class="pl-10 text--black font-weight-bold"
        >Status:</v-subheader
      >
      <div class="status-container">
        <simulation-status-stepper
          :key="previousRun.CreatedAt"
          :simulation="previousRun"
        ></simulation-status-stepper>
      </div>
    </div>
  </v-card>
</template>

<script>
import {
  deleteSimulationRun,
  formatDateString,
  getVirtualDatasetLayer,
  getDataset,
} from "@/api/v2.js";

import SimulationStatusStepper from "@/components/projects/SimulationStatusStepper.vue";

export default {
  components: { SimulationStatusStepper },

  props: {
    previousRun: {
      type: Object,
    },
  },

  data() {
    return {
      runButtonLoading: false,
      marginalCapabilityThresholdOptions: [
        { title: "No Limitations", value: 1 },
        { title: "Moderate Limitations", value: 2 },
        { title: "Moderately Severe Limitations", value: 3 },
        { title: "Severe Limitations", value: 4 },
        { title: "Forage Improvement Feasible", value: 5 },
        { title: "Forage Improvement Not Feasible", value: 6 },
      ],
      datasetLoading: false,
    };
  },

  watch: {},

  computed: {
    project() {
      return this.$store.state.project.activeProject;
    },

    runSettings() {
      return this.previousRun.Scenario.InputDevices;
    },
  },

  methods: {
    formatDateString,

    deleteRun(scenario) {
      this.$showAlert({
        text: `This also deletes the output dataset associated with the scenario run.
        <br> <b>Are you sure you want to delete this scenario, this cannot be undone?</b>
        <br>  - ${scenario.ScenarioName}`,
        type: "warning",
        choice: true,
      }).then((confirmed) => {
        if (confirmed) {
          const projectOwner = scenario.ProjectOwner;
          const projectName = scenario.ProjectName;

          this.$showLoadingOverlay({
            title: "Deleting, please wait.",
            updateMessage: `Deleting <b>${scenario.ScenarioName}<b>`,
          });
          deleteSimulationRun(
            projectName,
            projectOwner,
            scenario.ExpressionName,
            scenario.RunOwner,
            scenario.ScenarioName
          )
            .then((response) => {
              if (response.Warnings) {
                this.$showAlert({ text: response.Warnings, type: "warning" });
              }
              this.$store.dispatch("project/loadProject", {
                projectOwner,
                projectName,
              });
              this.$store.dispatch("accountData/refreshDatasetList");
            })
            .catch((error) => {
              let errorMessage = error;
              if (error.response) {
                errorMessage = error.response.data.Errors;
              }
              this.$showAlert({ text: errorMessage, type: "error" });
            })
            .finally(() => {
              this.$nextTick(() => {
                this.$showLoadingOverlay();

                // re-load project anyways to remove scenario if there was a 502 network error
                this.$store.dispatch("project/loadProject", {
                  projectOwner,
                  projectName,
                });
              });
            });
        }
      });
    },

    findMarginalCapabilityThreshold(value) {
      return this.marginalCapabilityThresholdOptions.find(
        (threshold) => threshold.value === value
      ).title;
    },

    async addRunToDataviewer() {
      this.datasetLoading = true;
      const { DatasetName, DatasetOwner } = this.previousRun.OutputDataset;

      try {
        const dataset = await getDataset(DatasetName, DatasetOwner);

        const layer = await getVirtualDatasetLayer(
          DatasetName,
          DatasetOwner,
          "Default"
        );

        // preserve certain fields
        layer.LayerName = this.previousRun.ScenarioName;

        const details = {
          selectedDataset: dataset,
          selectedLayer: layer,
          mapExtent: this.$store.state.settings.mapSettings.maxMapBounds,
          studyAreas: this.$store.state.mapping.datasets.filter(
            (dataset) => dataset.mapLayerPurpose === "StudyAreaMapLayer"
          ),
          layerMasks: [],
          layerContext: "DatasetMapLayer",
          allowFeatureSelection:
            this.$store.state.settings.dataSelectionType.allowFeatureSelection
              .allow,
        };

        await this.$store.dispatch("mapping/addDataset", details);

        this.$router.push("data-viewer");
      } catch (error) {
        this.$store.commit("alert/showErrorAlert", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 100px);
  overflow: hidden;
}
.run-details-box {
  display: flex;
  justify-content: space-between;
  background-color: #e9e9e9;
  border-radius: 12px;
  padding: 1em;
}

.content-container {
  flex: 1;
  overflow-y: auto;
  height: calc(100vh);
  padding: 1em 0 1em 0;
}

.inputs-container,
.status-container {
  height: 25vh;
  margin: 2em auto 2em auto;
  padding: 2em 1.2em 2em 0.5em;
  width: 95%;
  border: 0.1em solid #cac4d0;
  border-radius: 12px;
  overflow-y: auto;
}

.status-container {
  margin: 1em auto 1em auto;
  height: 25vh;
}

.details-list {
  list-style-type: none;
  padding: 1em 1.2em 1em 0.5em;
}

.v-subheader {
  height: 1em;
}

.details-list li {
  font: bold 0.9em/1.5em Roboto;
}

.details-list span {
  font: 0.8em Roboto;
}

.input-titles {
  font: bold 1.2em Roboto;
}

.input-text {
  font: 1em/2em Roboto;
}

/* Medium devices (landscape tablets, 768px and less) */
@media only screen and (max-width: 768px) {
  .inputs-container,
  .status-container {
    width: 95%;
  }
}
</style>

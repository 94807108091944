const nestedExpressions = (expression) => {
  let expressions = [];

  const matchParam = (s, param) => {
    const re = new RegExp(`${param}[ ]*=[ ]*`, "gm");
    let match = re.exec(s);
    if (match) {
      // Void if the next character is not a quote
      const quote = s[match.index + match[0].length];
      if (['"', "'"].includes(quote)) {
        match = RegExp(`^[^${quote}]+`, "gm").exec(
          s.slice(match.index + match[0].length + 1)
        );
        if (match) match = match[0];
      }
    }
    return match;
  };

  const re = /ex[ ]*\(/gm;
  let match = re.exec(expression);
  let substring, name, cat;
  while (match) {
    substring = expression.slice(match.index + match[0].length);
    name = matchParam(substring, "name");
    cat = matchParam(substring, "cat");

    if (name)
      expressions.push({name: cat ? `${cat}/${name}` : name});

    match = re.exec(expression);
  }
  
  return expressions;
};


export {
  nestedExpressions,
};

<template>
  <v-card class="pa-4">
    <v-card-title class="text-h5">Expressions</v-card-title>
    <expression-selector></expression-selector>
  </v-card>
</template>

<script>
import ExpressionSelector from "@/components/data/ExpressionSelector.vue";

export default {
  name: "DatasetManager",

  components: {
    ExpressionSelector,
  },
};
</script>

<style></style>

import {
  getCogInfo,
  getCogInfoGeoJson,
  buildTileJsonURL,
} from "@/api/titiler.js";

import {
  getApproxDatasetLayerStats,
  buildFlowPbfTileURL,
  encodeLayerDefinition,
} from "@/api/geoprocessing.js";

// import Gradient from "javascript-color-gradient";

import generateHslaColors from "@/helpers/hslColorGenerator";
import HSLAtoRGBA from "@/helpers/HSLAtoRGBA";
import hexToRGB from "@/helpers/hexToRGB";

export function flowSymbologyToTiTiler(classes) {
  // Hex
  const hexReg = /^#([0-9a-f]{3}){1,2}$/i;

  const finalClasses = {};

  let classArray = Object.keys(classes).map((key) => classes[key]);

  const formatColor = (colorString) => {
    const parsedColor = JSON.parse(colorString);

    // alpha value must be multiplied by 255 for TiTiler
    parsedColor.a = parsedColor.a * 255;

    // create RGBA array
    const newColor = Object.values(parsedColor);

    return newColor;
  };

  classArray.forEach((c) =>
    c.Values.forEach(
      (value) =>
        (finalClasses[value] = hexReg.test(c.Color)
          ? hexToRGB(c.Color)
          : formatColor(c.Color))
    )
  );

  return finalClasses;
}

export function classifyFromInternalColormap(colormap) {
  let categories = [];

  const internalColormap = Object.entries(colormap);
  internalColormap.forEach((uniqueValue) => {
    const newCategory = newCategoryClass(
      [uniqueValue[0]],
      `{"r":${uniqueValue[1][0]},"g":${uniqueValue[1][1]},"b":${
        uniqueValue[1][2]
      },"a":${uniqueValue[1][3] / 255}}`,
      uniqueValue[2] ? uniqueValue[2] : " "
    );

    categories.push(newCategory);
  });
  return categories;
}

export const updatePbfUrl = (fileUrl, newParams) => {
  // // update source tiles url (data-layer and negative flag)
  let url = new URL(fileUrl);

  let params = new URLSearchParams(url.search);

  Object.keys(newParams).forEach((i) => params.set(i, newParams[i]));

  params.toString();

  url.search = params.toString();

  return decodeURIComponent(url.href);
};

export const convertMapLayerToMask = (dataLayer) => {
  const newLayerIds = [];
  const updatedMapLayerFiles = dataLayer.files.map((file) => {
    const copyOfCurrentFile = structuredClone(file);
    const layerDefinitionCopy = structuredClone(dataLayer.layer);

    // update source tiles url (data-layer and background flag)
    const newUrlParams = {
      ["data-layer"]: encodeLayerDefinition(layerDefinitionCopy),
      background: 1,
    };

    // update mapBox layer information
    copyOfCurrentFile.mapLayer.source.tiles[0] = updatePbfUrl(
      file.mapLayer.source.tiles[0],
      newUrlParams
    );

    copyOfCurrentFile.mapLayer.id = `queryMask-${dataLayer.layer.Query}-${file.mapLayer.id}`;
    newLayerIds.push(copyOfCurrentFile.mapLayer.id);

    copyOfCurrentFile.mapLayer.paint = {
      "fill-opacity": 1,
      "fill-color": [
        "case",
        ["==", ["get", "FLOW_BACKGROUND"], 0],
        "rgba(0, 0, 0, 0)",
        ["==", ["get", "FLOW_BACKGROUND"], 1],
        "rgba(125, 125, 125, 1)",
        "red",
      ],
    };

    return copyOfCurrentFile;
  });

  dataLayer.files = updatedMapLayerFiles;
  dataLayer.styles.layerIDs = newLayerIds;

  return dataLayer;
};

export function newCategoryClass(
  uniqueValue = null,
  label = "",
  color = '{"r":0,"g":0,"b":0,"a":1}'
) {
  return {
    // Add a new class with an empty value
    label: label,
    values: [uniqueValue],
    color: color,
  };
}

export function newIntervalClass(
  min = null,
  max = null,
  label = "",
  color = '{"r":0,"g":0,"b":0,"a":1}'
) {
  return {
    label: label,
    values: [[min, max]],
    color: color,
  };
}

// convert json rgba color string to mapbox compatible css rgba() string
export function formatCSSColor(colorString) {
  const parsedColor = JSON.parse(colorString);

  const cssColor = `rgba(${parsedColor.r}, ${parsedColor.g}, ${parsedColor.b}, ${parsedColor.a})`;

  return cssColor;
}

export function formatRGBColorString(rgba) {
  let colors = ["r", "g", "b", "a"];

  let colorArr = rgba
    .slice(rgba.indexOf("(") + 1, rgba.indexOf(")"))
    .split(", ");

  let rgbaObj = new Object();

  colorArr.forEach((k, i) => {
    rgbaObj[colors[i]] = k;
  });

  return JSON.stringify(rgbaObj);
}

export function formatRGBColorStringFromArray(rgba) {
  let colors = ["r", "g", "b", "a"];

  let rgbaObj = new Object();

  rgba.forEach((k, i) => {
    rgbaObj[colors[i]] = k;
  });

  return JSON.stringify(rgbaObj);
}

// converts an editable category class array into flow saveable object format {label: {Color, Values}, label: {Color, Values}}
export function formatFlowSymbology(updatedColormap) {
  const formattedColormapArray = updatedColormap.map((cat) => ({
    [cat.label]: { Color: cat.color, Values: cat.values },
  }));

  const formattedColormapObject = Object.assign({}, ...formattedColormapArray);

  return formattedColormapObject;
}

// converts an editable category class array into flow saveable object format {label: {Color, Values}, label: {Color, Values}}
export function formatFlowSymbologyFromHexColorsObject(categories, hexColors) {
  const formattedColormapArray = Object.entries(categories).map(
    ([key, value]) => ({
      [key]: {
        Color: formatRGBColorStringFromArray(hexToRGB(hexColors[key])),
        Values: value,
      },
    })
  );

  const formattedColormapObject = Object.assign({}, ...formattedColormapArray);

  return formattedColormapObject;
}

// converts input map object into flow saveable object format {{label: [Values], OtherLabel: [Values]}}
export function formatSaveableCategoryObject(inputColormap) {
  const objectMap = Object.entries(inputColormap).map(([key, value]) => {
    const newObject = { [key]: value.Values };
    return newObject;
  });

  return Object.assign({}, ...objectMap);
}

// export function formatSaveableCategoryHexColors(inputColormap) {
//   const objectMap = Object.entries(inputColormap).map(([key, value]) => {
//     const newObject = { [key]: value.Values };
//     return newObject;
//   });

//   return Object.assign({}, ...objectMap);
// }

export function formatEditableSymbology(colormap) {
  // create a new array of class objects, where the label is a new property that can be edited.
  const unsortedList = Object.keys(colormap).map((key) => ({
    label: key,
    values: colormap[key].Values,
    color: colormap[key].Color,
  }));

  // sort list in ascending order
  const sortedList = unsortedList.sort(
    (a, b) => a.values[0][0] - b.values[0][0]
  );

  return sortedList;
}

export function createIntervals(classes, min, max, colors, type) {
  let result = [];
  let intervals = [];
  let length = (max - min) / classes;
  let i = min;

  while (i < max) {
    result.push([i, (i = Math.round((i + length) * 100) / 100)]);
  }

  for (let i = 0; i <= result.length - 1; i++) {
    // define values
    const minVal = result[i][0];
    const maxVal = result[i][1];

    // define label
    let label =
      i === 0
        ? `${type === "Raster" ? "" : "<="}${minVal.toFixed(2)}`
        : i === result.length - 1
        ? `> ${minVal.toFixed(2)}`
        : `${minVal.toFixed(2)}`;

    // if no color provided intervals are for a linear raster dataset legend
    const color = colors ? colors[i] : null;

    intervals.push(newIntervalClass(minVal, maxVal, label, color));
  }

  return intervals;
}

export function buildMapboxExpression(valueField, colormap) {
  const colormapCopy = structuredClone(colormap);

  const intervalList = Object.values(colormapCopy).map((interval) => {
    interval.Color = formatCSSColor(interval.Color);

    return interval;
  });

  // sort list in ascending order
  const sortedList = intervalList.sort(
    (a, b) => a.Values[0][0] - b.Values[0][0]
  );

  const interpolationExpression = [
    "interpolate",
    ["linear"],
    ["get", valueField],
  ];

  for (let i = 0; i < sortedList.length; i++) {
    interpolationExpression.push(parseFloat(sortedList[i].Values[0][0]));
    interpolationExpression.push(["to-color", sortedList[i].Color]);
  }

  // TODO: Alternate expression for removing false values (null, empty, NaN) not 0 though.
  // const falseCase = [
  //   "case",
  //   ["==", ["to-boolean", ["get", valueField]], false],
  // ];

  // const notZeroCase = [
  //   "case",
  //   ["!=", ["get", valueField], 0],
  //   "rgba(0,0,0,0)", // Set color to transparent if the value is false and not 0
  //   interpolationExpression, // Set color to interpolation expression color ramp if the value is false and equals 0 (to-boolean converts 0 to false)
  // ];

  // // combine expressions and Set fallback color to interpolation expression color ramp
  // const fullExpression = [
  //   ...falseCase,
  //   [...notZeroCase],
  //   [...interpolationExpression],
  // ];

  // return fullExpression;

  const nullCase = [
    "case",
    ["==", ["get", valueField], null],
    "rgba(0,0,0,0)", // Set color to transparent if the value is false and not 0
    interpolationExpression, // Set color to interpolation expression color ramp if the value is false and equals 0 (to-boolean converts 0 to false)
  ];

  return nullCase;
}

export function check8BitRange(dtype, min, max) {
  const intDtypes = {
    int8: { min: -128, max: 127 },
    uint8: { min: 0, max: 255 },
    int16: { min: -32768, max: 32737 },
    uint16: { min: 0, max: 65535 },
    int32: { min: -2147483648, max: 2147483647 },
    uint32: { min: 0, max: 4294967295 },
  };

  // check to see if min max of dataset falls within byte data type range and if dataset is not byte
  if (
    ["uint8", "int8"].indexOf(dtype) === -1 &&
    min >= intDtypes["uint8"].min &&
    max <= intDtypes["uint8"].max
  ) {
    return true;
  } else false;
}

// updates Layer.Dates array startDate values with an inclusive startDate and exclusive endDate
export function formatLayerDates(layerDates, defaultEndDate) {
  let newDatesList = [];

  if (!layerDates) {
    return layerDates;
  }

  // check for already formatted layer dates. this accommodates seasons from fwwf.
  else if (layerDates.every((date) => typeof date === "object")) {
    return layerDates;
  } else {
    // const { Dates } = layerDates;

    for (let i = 0; i < layerDates.length; i++) {
      if (i === layerDates.length - 1) {
        newDatesList.push({
          StartDate: layerDates[i],
          EndDate: defaultEndDate,
        });
      } else {
        newDatesList.push({
          StartDate: layerDates[i],
          EndDate: layerDates[i + 1],
        });
      }
    }
    return newDatesList;
  }
}

// creates extra files if needed for virtual layers
export function createExtraFiles(dataset, selectedLayer, formattedLayerDates) {
  let newFiles = [];

  // map only data file to each extra file needed setting the url to the tile server if vector
  if (dataset.DatasetType === "Vector") {
    if (formattedLayerDates) {
      for (let i = 0; i < formattedLayerDates.length; i++) {
        const copyOfFile = structuredClone(dataset.Files[0]);
        const params = {};

        // update dates
        copyOfFile.StartDate = formattedLayerDates[i].StartDate;
        copyOfFile.EndDate = formattedLayerDates[i].EndDate;

        params.time = copyOfFile.StartDate;

        const newUrl = buildFlowPbfTileURL(
          selectedLayer,
          copyOfFile.Files[0].FilePath,
          params
        );

        // replace FileUrl
        copyOfFile.Files[0].FileUrl = newUrl;

        newFiles.push(copyOfFile);
      }
    }
    // need to update the pbf url still
    else {
      const copyOfFile = structuredClone(dataset.Files[0]);

      const newUrl = buildFlowPbfTileURL(
        selectedLayer,
        copyOfFile.Files[0].FilePath,
        {}
      );

      // replace FileUrl
      copyOfFile.Files[0].FileUrl = newUrl;

      newFiles.push(copyOfFile);
    }
  }

  // Raster datasets
  else {
    if (formattedLayerDates) {
      // for each dataset file update start/end date if layerDate intersects range.
      formattedLayerDates.forEach((instanceDate) => {
        dataset.Files.map((file) => {
          if (instanceDate.StartDate === file.StartDate) {
            const copyOfFile = structuredClone(file);
            copyOfFile.EndDate = instanceDate.EndDate;
            newFiles.push(copyOfFile);
          }
        });
      });
    } else newFiles = dataset.Files;
  }

  return newFiles;
}

// Temp function to format display name
export function createTempLayerName(selectedDataset, selectedLayer) {
  // regular dataset with no scenario
  let name = `${selectedLayer.DatasetName}`;

  // specific name defined by virtual layer
  const virtualLayerName = selectedLayer.VirtualLayerName;

  if (virtualLayerName) {
    name = virtualLayerName;
  }

  // build layer name from other possible dataset configurations
  else {
    const nameArray = selectedDataset.DatasetName.split("/");

    const scenarioName = selectedDataset.Scenario
      ? selectedDataset.ScenarioName
      : null;

    // parses tags for scenario names
    const multipleScenarioNames = selectedDataset.Tags.reduce(function (
      filtered,
      tag
    ) {
      if (tag.includes("SCENARIO:")) {
        let modifiedTag = tag.split(":");
        filtered.push(modifiedTag[1]);
      }
      return filtered;
    },
    []);

    // cached dataset display name
    const re = new RegExp("([0-9a-f]{7})");

    const nestedDatasetName =
      re.test(selectedDataset.Style.DisplayName) &&
      this.selectedDataset.Scenario
        ? selectedDataset.Style.DisplayName.replace(
            re,
            selectedDataset.ScenarioName
          )
        : null;

    const nestedDatasetNames =
      re.test(selectedDataset.Style.DisplayName) && multipleScenarioNames
        ? selectedDataset.Style.DisplayName.replace(
            re,
            multipleScenarioNames.join(", ")
          )
        : null;

    // regular dataset with scenario and without display name
    const datasetScenarioName = selectedDataset.Scenario
      ? `${nameArray[nameArray.length - 1]} (${scenarioName})`
      : null;

    name = nestedDatasetNames
      ? nestedDatasetNames
      : nestedDatasetName
      ? nestedDatasetName
      : datasetScenarioName
      ? datasetScenarioName
      : name;

    // append layer name to all datasets
    name = `${name} - ${selectedLayer.LayerName}`;
  }

  return name;
}

// creating map layers
const getMetaData = async (
  dataset,
  selectedLayer,
  studyAreas,
  layerContext,
  layerMasks
  // mapExtent
) => {
  const allFiles = dataset.Files;
  // let studyAreaMask = null;
  let statsMin = [];
  let statsMax = [];
  let minVal = null;
  let maxVal = null;
  let flowCategoryStyle = null;
  let tiTilerStyle = null;

  // TODO:Alert user of individual datasets rejected
  const rejectedDatasets = [];

  if (selectedLayer.SymbologyName) {
    // initialize as object

    // try setting the symbology from a dataset symbology. If user deleted symbology, all layers that use it should have that removed.
    try {
      flowCategoryStyle = dataset.Symbology[selectedLayer.SymbologyName];

      if (selectedLayer.TypeOfData === "Categorical") {
        const allowedCategories = Object.keys(selectedLayer.Categories);

        // filter categories if layer categories differ from symbology
        flowCategoryStyle.Categories = Object.fromEntries(
          Object.entries(flowCategoryStyle.Categories).filter(([key]) =>
            allowedCategories.includes(key)
          )
        );

        // initialize the style object if category hex colors are provided
        if (
          flowCategoryStyle.CategoryColorsHex &&
          Object.keys(flowCategoryStyle.Style).length === 0
        ) {
          flowCategoryStyle.Style = {
            base: 1,
            cmap: "Custom",
            classes: formatFlowSymbologyFromHexColorsObject(
              flowCategoryStyle.Categories,
              flowCategoryStyle.CategoryColorsHex
            ),
            valueField: null,
          };
        }

        flowCategoryStyle.Style.classes = Object.fromEntries(
          Object.entries(flowCategoryStyle.Style.classes).filter(([key]) =>
            allowedCategories.includes(key)
          )
        );
      }

      if (dataset.DatasetType === "Raster") {
        tiTilerStyle = flowSymbologyToTiTiler(flowCategoryStyle.Style.classes);
      }
    } catch (error) {
      flowCategoryStyle = null;
    }
  }

  const acceptedDatasets = await Promise.all(
    allFiles.map(async (files) => {
      const startDate = files.StartDate;
      const endDate = files.EndDate;
      const displayDate = files.DisplayDate;
      let fileAttributeTable = null;
      let tifURL;
      let fileStats;
      let cogInfo;
      let cogInfoGeoJson;
      let fullResolutionStats = null;
      let otherLayerGeoJSONMasks = [];

      // check first file for file type
      const dataset = files.Files[0];
      const type = dataset.FileType === "pbf" ? "vector" : "raster";

      const datasetInfo = { ...selectedLayer };

      if (startDate !== "ind") datasetInfo.date = startDate;

      // try retrieving lower resolution statistics for file
      let lowResolutionStats = null;

      // use the study area and other layer masks to calculate stats.
      if (studyAreas.length > 0 && layerContext !== "StudyAreaMapLayer") {
        const stats = await getApproxDatasetLayerStats(
          { ...datasetInfo },
          [studyAreas[0].layer],
          layerMasks.map((maskLayer) => maskLayer.layer),
          23,
          startDate === "ind" &&
            layerMasks.some((masks) => masks.layer.Timestep)
            ? layerMasks[0].files[0].time.start
            : null
        );

        if (!stats.Errors && !stats.Warnings) {
          lowResolutionStats = stats;
        }
      } else {
        const stats = await getApproxDatasetLayerStats(
          { ...datasetInfo },
          [],
          [],
          23
        );

        if (!stats.Errors && !stats.Warnings) {
          lowResolutionStats = stats;
        }
      }

      // handle raster
      if (type === "raster") {
        tifURL = dataset.FileUrl;

        cogInfo = await getCogInfo(tifURL);

        cogInfoGeoJson = await getCogInfoGeoJson(tifURL);

        // store header stats
        fileStats = cogInfo.band_metadata[0]["1"];

        switch (selectedLayer.TypeOfData) {
          case "Continuous":
          case "Mask":
            if (lowResolutionStats || fullResolutionStats) {
              statsMin.push(
                fullResolutionStats
                  ? fullResolutionStats.Min
                  : lowResolutionStats.Min
              );
              statsMax.push(
                fullResolutionStats
                  ? fullResolutionStats.Max
                  : lowResolutionStats.Max
              );
            }

            break;

          case "Categorical":
            statsMin.push(fileStats.STATISTICS_MINIMUM);
            statsMax.push(fileStats.STATISTICS_MAXIMUM);

            break;

          default:
            break;
        }
      }

      // handle vector
      if (type === "vector") {
        switch (selectedLayer.TypeOfData) {
          // handle continuous and mask types the same
          case "Continuous":
          case "Mask":
          case "Categorical":
            if (lowResolutionStats || fullResolutionStats) {
              statsMin.push(
                fullResolutionStats
                  ? fullResolutionStats.Percentile2
                  : lowResolutionStats.Percentile2
              );

              statsMax.push(
                fullResolutionStats
                  ? fullResolutionStats.Percentile98
                  : lowResolutionStats.Percentile98
              );
            }

            break;
          default:
            break;
        }
      }

      // check dataset for valid statistics
      if (lowResolutionStats || fullResolutionStats) {
        return {
          cogInfo,
          cogInfoGeo: cogInfoGeoJson,
          fileStats,
          fileAttributeTable,
          lowResolutionStats,
          fullResolutionStats,
          otherLayerGeoJSONMasks,
          tifURL,
          type,
          dataset,
          startDate,
          endDate,
          displayDate,
          flowCategoryStyle,
          tiTilerStyle,
        };
      } else {
        // store rejected datasets
        rejectedDatasets.push(startDate);
      }
    })

    // remove datasets with no valid data, Alert user of rejected years
  ).then((datasets) => {
    if (rejectedDatasets.length > 0) {
      throw new Error(
        `There was an issue building the layer. Please try again later.`
      );
    }

    return datasets.filter((dataset) => typeof dataset !== "undefined");
  });

  // find most lower and upper bounds of all files
  minVal = Math.min(...statsMin.filter((val) => val !== null));
  maxVal = Math.max(...statsMax.filter((val) => val !== null));

  // TODO: fwwf  if the layer symbology defines either the min or max values to use, use those instead
  // if (selectedLayer.MinValue) minVal = selectedLayer.MinValue;
  // if (selectedLayer.MaxValue) maxVal = selectedLayer.MaxValue;

  return {
    datasetsMin: minVal,
    datasetsMax: maxVal,
    datasets: acceptedDatasets,
    // datasetGeoJSONMask: studyAreaMask,
  };
};

// function buildMaskMapLayer(id, maskResponse, startDate, endDate) {
//   const mapLayer = {
//     id: id,
//     source: {
//       type: "geojson",
//       data: {
//         type: "Feature",
//         geometry: {
//           ...maskResponse,
//         },
//       },
//     },
//     layout: { visibility: "visible" },
//   };

//   mapLayer.type = "fill";
//   mapLayer.paint = {
//     "fill-opacity": 1,
//     // "fill-outline-color": "rgba(255, 255, 255, 1)",
//     "fill-color": "rgba(125, 125, 125, 1)",
//   };

//   const preparedMask = {
//     mapLayer,
//     time: { start, end, displayDate },
//   };

//   return preparedMask;
// }

// function rebuildMapLayerAsMask(layerMask) {
//   layerMask.mapLayer.paint = {
//     "fill-opacity": 1,
//     "fill-color": [
//       "case",
//       ["==", ["get", "FLOW_BACKGROUND"], 0],
//       "rgba(0, 0, 0, 0)",
//       ["==", ["get", "FLOW_BACKGROUND"], 1],
//       "rgba(125, 125, 125, 1)",
//       "red",
//     ],
//   };

//   // layerMask.mapLayer.source.minzoom = 8;

//   // const start = startDate ? startDate : "ind";
//   // const end = endDate ? endDate : "ind";
//   // const displayDate = startDate ? startDate : "ind";

//   const preparedMask = {
//     layer: layerMask.layer,
//     mapLayer: layerMask.mapLayer,
//     time: layerMask.time,
//   };

//   return preparedMask;
// }

function buildDatasets(metadata, selectedLayer, layerContext) {
  let preparedDatasets = [];
  const colormap = {};
  let datasetsMinMax = null;

  // generate a colour to be shared by each file if mask type.
  const sharedColor =
    selectedLayer.TypeOfData === "Mask" && layerContext === "StudyAreaMapLayer"
      ? HSLAtoRGBA(generateHslaColors(50, 50, 1, 1))
      : selectedLayer.TypeOfData === "Mask"
      ? HSLAtoRGBA(generateHslaColors(50, 50, 1, 1))
      : null;

  for (let i = 0; i < metadata.datasets.length; i++) {
    let tileURL;
    let currentDataset = metadata.datasets[i];
    let preparedDataset;

    let extentGeoJson;
    let mapLayer;
    let preparedOtherLayerMasks = [];

    // if (currentDataset.otherLayerGeoJSONMasks.length > 0) {
    //   preparedOtherLayerMasks = currentDataset.otherLayerGeoJSONMasks.map(
    //     (layerMask) => rebuildMapLayerAsMask(structuredClone(layerMask))
    //   );
    // }

    // Vector Tiles
    if (currentDataset.dataset.FileType === "pbf") {
      let interpolationExpression = [];

      tileURL = currentDataset.dataset.FileUrl;

      // adds dataset minVal and maxVal property that can be used to create a unified legend entry for all the datasets.
      datasetsMinMax = {
        minVal: metadata.datasetsMin,
        maxVal: metadata.datasetsMax,
      };

      // this need to point to an individual extent for the expression
      const bounds = currentDataset.dataset.Extent;

      extentGeoJson = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [bounds[0], bounds[1]],
              [bounds[0], bounds[3]],
              [bounds[2], bounds[3]],
              [bounds[2], bounds[1]],
              [bounds[0], bounds[1]],
            ],
          ],
        },
      };

      let sourceLayer = currentDataset.dataset.LayerName.replaceAll(
        /[\s-]+/g,
        "_"
      ).replaceAll("'", "");

      // layer name for vector datasets
      mapLayer = {
        id: `flowSource-${currentDataset.dataset.DatasetName}+${selectedLayer.LayerName}+${currentDataset.startDate}-${currentDataset.endDate}`,
        source: {
          type: currentDataset.type,
          tiles: [tileURL],
        },
        "source-layer": sourceLayer,
        layout: {},
      };

      if (layerContext === "StudyAreaMapLayer") {
        tileURL = updatePbfUrl(currentDataset.dataset.FileUrl, {
          negative: 1,
        });

        mapLayer.source.tiles = [tileURL];
        mapLayer.id = `studyAreaMask-${currentDataset.dataset.DatasetName}+${selectedLayer.LayerName}+${currentDataset.startDate}-${currentDataset.endDate}`;
      }

      let filterExpression = null;

      if (selectedLayer.FilterField && layerContext !== "StudyAreaMapLayer") {
        filterExpression = [
          "==",
          selectedLayer.FilterField,
          selectedLayer.FilterValue,
        ];
      }

      // add filter if applicable
      if (filterExpression) mapLayer.filter = filterExpression;

      switch (selectedLayer.TypeOfData) {
        case "Continuous":
          {
            // https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#interpolate

            // default properties
            colormap.displayType = "quantitative";

            colormap.inputMin = metadata.datasetsMin ? metadata.datasetsMin : 0;
            colormap.inputMax = metadata.datasetsMax ? metadata.datasetsMax : 1;

            colormap.flowCategoryStyle = currentDataset.flowCategoryStyle;

            // set from saved symbology
            if (colormap.flowCategoryStyle) {
              colormap.legendColormap =
                colormap.flowCategoryStyle.Style.classes;

              interpolationExpression = buildMapboxExpression(
                selectedLayer.ValueField,
                colormap.flowCategoryStyle.Style.classes
              );

              colormap.mapboxExpression = interpolationExpression;
            }

            // set from default 6 classes
            else {
              const intervals =
                metadata.datasetsMin || metadata.datasetsMax ? 6 : 1;

              const intervalColormap = createIntervals(
                intervals,
                colormap.inputMin,
                colormap.inputMax,

                [
                  '{"r":50,"g":136,"b":189,"a":1}',
                  '{"r":153,"g":213,"b":148,"a":1}',
                  '{"r":230,"g":245,"b":152,"a":1}',
                  '{"r":254,"g":224,"b":139,"a":1}',
                  '{"r":252,"g":141,"b":89,"a":1}',
                  '{"r":213,"g":62,"b":79,"a":1}',
                ],

                "Vector"
              );

              console.log(intervalColormap);

              interpolationExpression = buildMapboxExpression(
                selectedLayer.ValueField,
                formatFlowSymbology(intervalColormap)
              );

              colormap.legendColormap = formatFlowSymbology(intervalColormap);
              colormap.mapboxExpression = interpolationExpression;
            }

            if (
              currentDataset.dataset.Geometry === "Polygon" ||
              currentDataset.dataset.Geometry === "Multi Polygon"
            ) {
              mapLayer.type = "fill";
              mapLayer.paint = {
                "fill-opacity": 1,
                "fill-outline-color": "rgba(0, 0, 0, 0)",
                "fill-color": colormap.mapboxExpression,
              };
            } else if (currentDataset.dataset.Geometry === "Point") {
              mapLayer.type = "circle";
              mapLayer.paint = {
                "circle-stroke-width": 0.2,
                "circle-stroke-color": "rgba(0, 0, 0, 1)",
                "circle-stroke-opacity": 0.5,
                "circle-radius": 3,
                "circle-color": colormap.mapboxExpression,
              };
            } else if (
              currentDataset.dataset.Geometry === "Line String" ||
              currentDataset.dataset.Geometry === "Multi Line String"
            ) {
              mapLayer.type = "line";
              mapLayer.paint = {
                "line-color": "rgba(0, 0, 0, 1)",
                "line-width": 1,
              };
            }
            // default to polygon
            else {
              mapLayer.type = "fill";
              mapLayer.paint = {
                "fill-opacity": 1,
                "fill-outline-color": "rgba(0, 0, 0, 0)",
                "fill-color": colormap.mapboxExpression,
              };
            }
          }

          break;

        case "Mask":
          colormap.displayType = "mask";
          colormap.flowCategoryStyle = currentDataset.flowCategoryStyle;
          colormap.legendColormap = {
            [selectedLayer.LayerName]: {
              Color: sharedColor,
              Values: null,
            },
          };

          if (
            currentDataset.dataset.Geometry === "Polygon" ||
            currentDataset.dataset.Geometry === "Multi Polygon"
          ) {
            mapLayer.type = "fill";
            mapLayer.paint = {
              "fill-opacity": 1,
              "fill-outline-color":
                layerContext === "StudyAreaMapLayer"
                  ? "rgba(255, 255, 255, 1)"
                  : "rgba(0, 0, 0, 1)",
              "fill-color":
                layerContext === "StudyAreaMapLayer"
                  ? "rgba(125, 125, 125, 1)"
                  : sharedColor,
            };
          } else if (currentDataset.dataset.Geometry === "Point") {
            mapLayer.type = "circle";
            mapLayer.paint = {
              "circle-stroke-width": 0.2,
              "circle-stroke-color": "rgba(0, 0, 0, 1)",
              "circle-stroke-opacity": 0.5,
              "circle-radius": 3,
              "circle-color": sharedColor,
            };
          } else if (
            currentDataset.dataset.Geometry === "Line String" ||
            currentDataset.dataset.Geometry === "Multi Line String"
          ) {
            mapLayer.type = "line";
            mapLayer.paint = {
              "line-color": "rgba(0, 0, 0, 1)",
              "line-width": 1,
            };
          }

          break;

        default:
          // handle all other types the same for now
          if (
            currentDataset.dataset.Geometry === "Polygon" ||
            currentDataset.dataset.Geometry === "Multi Polygon"
          ) {
            mapLayer.type = "fill";
            mapLayer.paint = {
              "fill-opacity": 1,
              "fill-outline-color": "rgba(0, 0, 0, 0.5)",
              "fill-color": sharedColor,
            };
          } else if (currentDataset.dataset.Geometry === "Point") {
            mapLayer.type = "circle";
            mapLayer.paint = {
              "circle-stroke-width": 0.2,
              "circle-stroke-color": "rgba(0, 0, 0, 1)",
              "circle-stroke-opacity": 0.5,
              "circle-radius": 3,
              "circle-color": sharedColor,
            };
          } else if (
            currentDataset.dataset.Geometry === "Line String" ||
            currentDataset.dataset.Geometry === "Multi Line String"
          ) {
            mapLayer.type = "line";
            mapLayer.paint = {
              "line-color": "rgba(0, 0, 0, 1)",
              "line-width": 1,
            };
          }
          // default to polygon
          else {
            mapLayer.type = "fill";
            mapLayer.paint = {
              "fill-opacity": 1,
              "fill-outline-color": "rgba(0, 0, 0, 0.5)",
              "fill-color": sharedColor,
            };
          }
          break;
      }

      // style for categorical

      // TODO: color values based on filter field
      // // default styling with no filter applied (styles all internal data and returns single map layer)
      // if (selectedLayer.LayerName.FilterField === "Default") {
      //   // build an expression to color by filterField and filterFieldValues

      //   const filterField = selectedLayer.FilterField;

      // const matchExpression = ["match", ["get", filterField]];

      //   for (const row of selectedLayer) {
      //     const color = `rgba(${randomColorValue()}, ${randomColorValue()}, ${randomColorValue()}, 1)`;
      //     matchExpression.push(row["FilterValue"], color);
      //   }
      // }

      // // default
      // matchExpression.push("rgba(0, 0, 0, 0)");

      // set style for "Default"

      // const randomColorValue = () => Math.floor(Math.random() * 255);

      // const randomColor = `rgba(${randomColorValue()}, ${randomColorValue()}, ${randomColorValue()}, 1)`;

      // const randomColor = () =>
      //   `#${Math.floor(Math.random() * 16777215).toString(16)}`;

      // if (
      //   currentDataset.dataset.Geometry === "Polygon" ||
      //   currentDataset.dataset.Geometry === "MultiPolygon"
      // ) {
      //   mapLayer.type = "fill";
      //   mapLayer.paint = {
      //     "fill-opacity": 0.8,
      //     "fill-outline-color": "rgba(0, 0, 0, 0.5)",
      //     "fill-color": generateHslaColors(50, 50, 1, 1),
      //     // "fill-color": [
      //     //   "rgb",
      //     //   // red is higher when feature.properties.temperature is higher
      //     //   ["get", selectedLayer.ValueField],
      //     //   // green is always zero
      //     //   0,
      //     //   // blue is higher when feature.properties.temperature is lower
      //     //   ["-", 100, ["get", selectedLayer.ValueField]],
      //     //   // "interpolate",
      //     //   // ["linear"],
      //     //   // ["get", selectedLayer.ValueField],
      //     //   // 0,
      //     //   // "#F2F12D",
      //     //   // 500000,
      //     //   // "#EED322",
      //     //   // 750000,
      //     //   // "#E6B71E",
      //     //   // 1000000,
      //     //   // "#DA9C20",
      //     //   // 2500000,
      //     //   // "#CA8323",
      //     //   // 5000000,
      //     //   // "#B86B25",
      //     //   // 7500000,
      //     //   // "#A25626",
      //     //   // 10000000,
      //     //   // "#8B4225",
      //     //   // 25000000,
      //     //   // "#723122",
      //     // ],
      //   };
      // } else if (currentDataset.dataset.Geometry === "Point") {
      //   mapLayer.type = "circle";
      //   mapLayer.paint = {
      //     "circle-stroke-width": 0.2,
      //     "circle-stroke-color": "rgba(0, 0, 0, 1)",
      //     "circle-stroke-opacity": 0.5,
      //     "circle-radius": 3,
      //     "circle-color": generateHslaColors(50, 50, 1, 1),
      //   };
      // } else if (currentDataset.dataset.Geometry === "LineString") {
      //   mapLayer.type = "line";
      //   mapLayer.paint = {
      //     "line-color": "rgba(0, 0, 0, 1)",
      //     "line-width": 1,
      //   };
      // } else {
      //   // default to polygon
      //   mapLayer.type = "fill";
      //   mapLayer.paint = {
      //     "fill-opacity": 0.8,
      //     "fill-outline-color": "rgba(0, 0, 0, 0.5)",
      //     "fill-color": generateHslaColors(50, 50, 1, 1),
      //     // "fill-color": [
      //     //   "rgb",
      //     //   // red is higher when feature.properties.temperature is higher
      //     //   ["get", selectedLayer.ValueField],
      //     //   // green is always zero
      //     //   0,
      //     //   // blue is higher when feature.properties.temperature is lower
      //     //   ["-", 100, ["get", selectedLayer.ValueField]],
      //     //   // "fill-color": [
      //     //   //   "interpolate",
      //     //   //   ["linear"],
      //     //   //   ["get", selectedLayer.ValueField],
      //     //   //   0,
      //     //   //   "#F2F12D",
      //     //   //   10000,
      //     //   //   "#EED322",
      //     //   //   30000,
      //     //   //   "#E6B71E",
      //     //   //   40000,
      //     //   //   "#DA9C20",
      //     //   //   50000,
      //     //   //   "#CA8323",
      //     //   //   60000,
      //     //   //   "#B86B25",
      //     //   //   75000,
      //     //   //   "#A25626",
      //     //   //   10000000,
      //     //   //   "#8B4225",
      //     //   //   25000000,
      //     //   //   "#723122",
      //     // ],
      //   };
      // }
    }

    // TODO: geojson

    // Rasters
    else {
      // if (
      //   [
      //     "uint8",
      //     "int8",
      //     "uint16",
      //     "int16",
      //     "uint32",
      //     "int32",
      //     "uint64",
      //     "int64",
      //   ].indexOf(currentDataset.cogInfo.dtype) === -1
      // ) {
      //   // set statistics for float type (units are dataset units)
      //   currentDataset.stats.STATISTICS_VALID_SUM =
      //     (currentDataset.cogInfo.width *
      //       currentDataset.cogInfo.height *
      //       currentDataset.stats.STATISTICS_MEAN *
      //       currentDataset.stats.STATISTICS_VALID_PERCENT) /
      //     100;
      // } else {
      //   // set statistics for integer based datasets
      //   currentDataset.stats.STATISTICS_VALID_SUM = "n/a";
      // }

      // adds dataset minVal and maxVal property that can be used to create a unified legend entry for all the datasets.
      datasetsMinMax = {
        minVal: metadata.datasetsMin,
        maxVal: metadata.datasetsMax,
      };

      const displayParams = {
        url: currentDataset.tifURL,
        bidx: 1,
        return_mask: true,
        maxzoom: 20,
        format: "png",
      };

      // don't return raster mask if other masks are needed from vector dataset
      // displayParams["return_mask"] =
      //   currentDataset.otherLayerGeoJSONMasks.length > 0 ? false : true;

      if (currentDataset.flowCategoryStyle) {
        displayParams.colormap = JSON.stringify(currentDataset.tiTilerStyle);
      }

      colormap.displayParams = displayParams;
      colormap.inputMin = metadata.datasetsMin;
      colormap.inputMax = metadata.datasetsMax;
      colormap.flowCategoryStyle = currentDataset.flowCategoryStyle;

      switch (selectedLayer.TypeOfData) {
        default:
          break;

        case "Continuous":
          {
            colormap.displayType = "quantitative";

            if (
              ["uint8", "int8"].indexOf(currentDataset.cogInfo.dtype) === -1
            ) {
              displayParams.rescale = `${metadata.datasetsMin},${metadata.datasetsMax}`;
            }

            // set legend from saved symbology
            if (colormap.flowCategoryStyle) {
              colormap.legendColormap =
                colormap.flowCategoryStyle.Style.classes;
              colormap.displayParams.colormap_name =
                currentDataset.flowCategoryStyle.Style.cmap;
            } else {
              // create default 5 breakpoints for continuous
              colormap.displayParams.colormap_name = "viridis";

              const intervalColormap = createIntervals(
                5,
                metadata.datasetsMin,
                metadata.datasetsMax,
                null,
                "Raster"
              );

              colormap.legendColormap = formatFlowSymbology(intervalColormap);
            }
          }

          break;

        case "Categorical":
          {
            colormap.displayType = "categorical-discrete";
            // check for internal colormap and set
            if (currentDataset.cogInfo.colorinterp[0] === "palette") {
              colormap.internalColormap = {};
              colormap.internalColormap = classifyFromInternalColormap(
                currentDataset.cogInfo.colormap
              );
            }

            if (colormap.flowCategoryStyle) {
              colormap.legendColormap =
                currentDataset.flowCategoryStyle.Style.classes;
            }

            if (
              check8BitRange(
                currentDataset.cogInfo.dtype,
                metadata.datasetsMin,
                metadata.datasetsMax
              )
            ) {
              displayParams.rescale = `0,255`;
            }
          }

          break;

        case "Mask":
          colormap.displayType = "mask";
          colormap.legendColormap = {
            [selectedLayer.LayerName]: {
              Color: sharedColor,
              Values: null,
            },
          };

          break;
      }

      // finally create tilejson
      const tileJson = buildTileJsonURL(colormap.displayParams);

      currentDataset.tileJson = tileJson;

      extentGeoJson = currentDataset.cogInfoGeo;
      mapLayer = {
        id: `flowSource-${currentDataset.dataset.DatasetName}+${selectedLayer.LayerName}+${currentDataset.startDate}-${currentDataset.endDate}`,
        type: "raster",
        source: {
          type: currentDataset.type,
          url: currentDataset.tileJson,
        },
        layout: { visibility: "none" },
        paint: {
          "raster-opacity": 1,
        },
      };
    }

    const start = currentDataset.startDate;
    const end = currentDataset.endDate;
    const displayDate = currentDataset.startDate;

    const fileAttributeTable = currentDataset.fileAttributeTable;

    preparedDataset = {
      extentLayer: {
        type: "geojson",
        source: extentGeoJson,
      },
      mapLayer,
      layerMasks: preparedOtherLayerMasks,
      baseURL: currentDataset.tifURL,
      colormap,
      stats: {
        featureValueStats: {},
        lowResolutionStats: currentDataset.lowResolutionStats,
        lowResolutionStatsLastUpdated: new Date(),
        fullResolutionStats: currentDataset.fullResolutionStats,
        fullResolutionStatsLastUpdated: null,
      },
      fileAttributeTable,
      time: { start, end, displayDate },
      datasetsMinMax,
    };

    preparedDatasets.push(preparedDataset);
  }

  return { preparedDatasets };
}

const filesFromDataset = async (
  dataset,
  selectedLayer,
  studyAreas,
  layerContext,
  layerMasks,
  mapExtent,
  allowFeatureSelection
) => {
  const metadata = await getMetaData(
    dataset,
    selectedLayer,
    studyAreas,
    layerContext,
    layerMasks,
    mapExtent,
    allowFeatureSelection
  );
  return buildDatasets(metadata, selectedLayer, layerContext);
};

export { filesFromDataset };

const state = {
  user: null,
  authenticated: false,
};
const mutations = {
  changeUser(state, user) {
    state.user = user;
  },
  changeAuth(state, authState) {
    state.authenticated = authState === "signedin";
  },
};
const getters = {
  // individuals may have permission granted either personally or from group membership
  checkEditPermissions: (state) => (tagList) => {
    const personalPermission = tagList.includes(
      `EDIT:${state.user.attributes.email}`
    );

    const groupPermissionIntersection = tagList.some((tag) =>
      state.user.signInUserSession.idToken.payload["cognito:groups"].includes(
        tag.replace("EDIT:", "")
      )
    );

    return personalPermission || groupPermissionIntersection;
  },
};
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <v-navigation-drawer permanent width="300">
    <div class="flex-box">
      <v-img
        class="image-thumb"
        gradient="to bottom right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0)"
        :src="
          require(`@/assets/project-img/${
            this.activeProject.Settings.ProjectImage
              ? this.activeProject.Settings.ProjectImage
              : 'jack-hamilton-jR311W7n9fk-unsplash.jpg'
          }`)
        "
      >
        <v-list nav dense>
          <v-list-item one-line dark>
            <v-list-item-content>
              <v-list-item-title class="text-h6 py-2 text-wrap">
                {{ this.activeProject.Properties.ProjectName }}
              </v-list-item-title>
              <v-list-item-subtitle class="pt-1">
                {{ this.activeProject.Properties.ProjectOwner }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line dark>
            <v-list-item-content>
              <v-list-item-subtitle>
                Last Modified:
                {{ formatDateString(this.activeProject.Properties.UpdatedAt) }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                Created:
                {{ formatDateString(this.activeProject.Properties.CreatedAt) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-img>

      <v-divider></v-divider>
    </div>

    <v-list nav dense>
      <v-list-item-group @change="changePage" v-model="page">
        <v-list-item
          v-for="(item, i) in pages"
          :key="i"
          :disabled="item.disabled || item.name === pages[page].name"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { formatDateString } from "@/api/v2";
export default {
  name: "ProjectNavDrawer",
  props: {
    project: { type: Object, default: () => {} },
  },
  data() {
    return {
      page: 0,
      pages: [
        { name: "Project", icon: "mdi-wrench", disabled: false },
        { name: "Variables", icon: "mdi-pencil", disabled: false },
        {
          name: "Expressions",
          icon: "mdi-function-variant",
          disabled: false,
        },
        // disabled for now
        // { name: "Results", icon: "mdi-map", disabled: true },
      ],
    };
  },
  computed: {
    activeProject() {
      return this.project.activeProject;
    },
  },
  methods: {
    formatDateString,
    changePage(newPage) {
      this.$emit("change-page", this.pages[newPage].name);
    },
  },
  mounted() {
    if (this.project.activeProject.Properties.CreatedFromProjectOwner) {
      let project = this.pages.splice(0, 1);
      this.pages.push(project[0]);
    }
  },
};
</script>

<style scoped>
.image-thumb {
  width: 100%;
  height: 175px;
}
</style>

<template>
  <v-app>
    <the-splash-screen v-if="isLoading" :is-loading="isLoading">
    </the-splash-screen>
    <alert v-if="alert !== null"></alert>

    <app-bar
      v-if="authenticated && !isLoading && !$route.meta.hideNavbar"
      id="app-bar"
      app
    ></app-bar>
    <v-main>
      <div class="bg">
        <v-container fluid :class="{ 'app-container-loading': isLoading }" style="padding: 0;">
          <router-view @show-splash-screen="showSplash"></router-view>
        </v-container>
      </div>
    </v-main>
    <v-footer
      id="footer"
      v-if="authenticated && !isLoading && !$route.meta.hideFooter"
    >
      <v-row>
        <v-col cols="12" class="d-flex justify-center align-center">
          <v-img
            id="alces-flow-logo"
            class="d-inline"
            contain
            max-width="50"
            :src="require(`@/assets/img/alces_flow_white.png`)"
          ></v-img>
          <div id="alces-flow-text" class="white--text text-subtitle-2">
            <span style="font-family: Roboto">ALCES </span
            ><span style="font-family: Nunito">flow</span>
          </div>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import Alert from "@/components/Alert";
import AppBar from "@/components/nav/AppBar";
import TheSplashScreen from "@/components/TheSplashScreen.vue";

export default {
  name: "App",
  components: {
    Alert,
    AppBar,
    TheSplashScreen,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    showSplash(value) {
      this.isLoading = value;
    },
  },
  computed: {
    authenticated() {
      return (
        !this.$store.state.settings.authRequired ||
        this.$store.state.account.authenticated
      );
    },
    alert() {
      return this.$store.state.alert.alert;
    },
  },
  // redirect to data-viewer on app reload (window refresh or window close)
  mounted() {
    if (this.$route.name !== "Login") this.$router.push({ path: "/" });
  },
};
</script>

<style lang="scss">
#app {
  font-family: Poppins, Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root {
  /* Colors */
  --amplify-primary-color: #000000;
  --amplify-font-family: "Poppins";
}
.bg {
  background-image: linear-gradient(#345c9650, rgb(92, 131, 160, 0.9)),
    url("./assets/img/bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.app-container-loading {
  padding: 0;
}
#app-bar {
  max-height: 65px;
}

#logo-title {
  color: white;
  text-align: center;
}

#footer {
  background-color: #657f93;
}

#alces-flow-logo {
  margin-right: 1em;
}
</style>

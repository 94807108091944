<template>
  <div>
    <div class="d-flex justify-start align-center pa-2">
      <v-img
        :src="require('@/assets/img/logo.png')"
        contain
        max-width="40"
      ></v-img>
      <h2 class="pl-2 font-weight-regular">Filter Models</h2>
    </div>
    <v-row>
      <v-col sm="12" md="6">
        <v-text-field
          label="Search for a Category"
          outlined
          prepend-icon="mdi-magnify"
          v-model="categorySearch"
          :loading="loadingCats"
          color="black"
          dense
        ></v-text-field>
        <div
          class="d-flex justify-center align-center"
          v-if="filteredCategories.length === 0"
        >
          <v-subheader>No Categories</v-subheader>
        </div>
        <v-slide-y-reverse-transition>
          <v-expansion-panels
            v-if="!loadingCats"
            :class="selectionMode ? 'category-panels' : null"
          >
            <v-expansion-panel
              v-for="item in filteredCategories"
              :key="item.name"
              @change="changeCat(item)"
            >
              <v-expansion-panel-header disable-icon-rotate>
                {{ item.name }}
                <template v-slot:actions>
                  <v-icon>
                    {{
                      category && item.name === category.name
                        ? "mdi-folder-open"
                        : "mdi-folder"
                    }}
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ item.description }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-slide-y-reverse-transition>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col sm="11" md="5">
        <v-text-field
          label="Search for a Filter Model"
          outlined
          prepend-icon="mdi-magnify"
          v-model="filterModelSearch"
          :loading="isLoading"
          :hint="
            category
              ? ''
              : 'Searches must start from the beginning of an Filter Model name'
          "
          :persistent-hint="!category"
          color="black"
          dense
        ></v-text-field>
        <div v-if="getExprsBtn && filterModels.length > 1">
          <v-btn dense text @click="$emit('all-models', filterModels)"
            >select all</v-btn
          >
        </div>
        <div v-if="selectionMode && filterModel">
          {{ `Selected: ${filterModel.name}` }}
        </div>
        <v-scroll-y-transition mode="out-in">
          <div
            v-if="filterModels.length === 0"
            class="text-h6 grey--text font-weight-light"
            style="align-self: center;"
          >
            Select a Category or search for a Filter Model
          </div>
          <v-list
            v-else
            dense
            :class="selectionMode ? 'filter-models' : null"
            two-line
          >
            <v-list-item-group :color="$store.state.settings.appColor">
              <v-list-item
                v-for="(exp, i) in filteredFilterModels"
                :key="i"
                @click="selectFilterModel(exp)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-function-variant</v-icon>
                </v-list-item-icon>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-content v-bind="attrs" v-on="on">
                      <v-list-item-title
                        v-html="
                          `<span class='text--primary'>${
                            exp.name.length > charLength
                              ? exp.name.slice(0, charLength - 3) + '...'
                              : exp.name
                          }</span> &mdash; ${exp.cat}`
                        "
                      ></v-list-item-title>

                      <v-list-item-subtitle>{{
                        exp.description.length > charLength
                          ? exp.description.slice(0, charLength - 3) + "..."
                          : exp.description
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <span>{{ exp.name }}</span>
                </v-tooltip>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-scroll-y-transition>
      </v-col>
      <v-col sm="1" v-if="!selectionMode">
        <v-btn text @click="newFilterModel()"
          ><v-icon>mdi-plus</v-icon>&nbsp;New</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import {
//   searchObjectNames,
//   userCategories,
//   objectNamesFromCategory,
// } from "@/api/crudq";

export default {
  name: "FilterModelSelector",

  props: {
    selectionMode: {
      type: Boolean,
      default: false,
    },
    getExprsBtn: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
      loadingCats: false,
      filterModelSearch: null,
      searchTimeout: null,
      filterModel: null,
      filterModels: [],
      category: null,
      categorySearch: null,
      categories: [],
      charLength: 80,
    };
  },

  computed: {
    filteredCategories() {
      return this.categories.filter((cat) => {
        return (
          !this.categorySearch ||
          cat.name.toLowerCase().includes(this.categorySearch.toLowerCase())
        );
      });
    },
    filteredFilterModels() {
      return this.filterModels.filter((expr) => {
        return (
          !this.filterModelSearch ||
          expr.name.toLowerCase().includes(this.filterModelSearch.toLowerCase())
        );
      });
    },
  },

  watch: {
    filterModelSearch(val) {
      if (this.category) {
        // Peform this search within loaded objects
        return;
      }

      if (this.searchTimeout) clearTimeout(this.searchTimeout);

      if (val) {
        this.searchTimeout = setTimeout(() => {
          val;
          this.isLoading = true;
          // searchObjectNames("filterModels", val)
          //   .then((res) => {
          //     if (res.success === false) {
          //       this.$showAlert({
          //         text: res.message,
          //         type: "error",
          //       });
          //     } else this.filterModels = res;
          //   })
          //   .catch((err) => {
          //     this.$showAlert({
          //       text: err,
          //       type: "error",
          //     });
          //   })
          //   .finally(() => (this.isLoading = false));
        }, 150);
      }
    },
  },

  methods: {
    async changeCat(category) {
      if (this.category && this.category.name === category.name) {
        // Reset
        this.category = null;
        this.filterModels = [];
        return;
      }
      this.category = category;
      this.isLoading = true;

      // objectNamesFromCategory("filterModels", category.name)
      //   .then((res) => {
      //     if (res.success === false) {
      //       this.$showAlert({
      //         text: res.message,
      //         type: "error",
      //       });
      //     } else this.filterModels = res;
      //   })
      //   .catch((err) => {
      //     this.$showAlert({
      //       text: err,
      //       type: "error",
      //     });
      //   })
      //   .finally(() => (this.isLoading = false));
    },
    newFilterModel() {
      if (this.selectionMode) this.$emit("new-model");
      else
        this.$router.push({
          name: "Filter Model Viewer",
        });
    },
    selectFilterModel(expr) {
      this.filterModel = expr;
      if (this.selectionMode) this.$emit("update-model", expr);
      else
        this.$router.push({
          name: "Filter Model Viewer",
          params: { inputExpr: expr },
        });
    },
  },

  beforeMount() {
    this.loadingCats = true;
    // userCategories("filterModels")
    //   .then((res) => {
    //     if (res.success === false) {
    //       this.$showAlert({
    //         text: res.message,
    //         type: "error",
    //       });
    //     } else this.categories = res;
    //   })
    //   .catch((err) => {
    //     this.$showAlert({
    //       text: err,
    //       type: "error",
    //     });
    //   })
    //   .finally(() => (this.loadingCats = false));
  },
};
</script>

<style scoped>
.category-panels {
  max-height: 250px;
  overflow: auto;
}
.filter-models {
  max-height: 250px;
  overflow: auto;
}
</style>

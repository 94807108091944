<template>
  <div class="d-flex flex-column justify-center">
    <span
      >The <code>cond</code> method is only used within the conditional portion
      of an <code>if</code> statement</span
    >
    <code style="padding-bottom: 15px;">cond(something == something)</code>
    <v-divider></v-divider>
    The condition is applied over all cells using unary operators, which
    include:
    <v-simple-table style="padding-bottom: 15px;">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Operator
            </th>
            <th class="text-left">
              Description
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><code>==</code></td>
            <td>Equal to</td>
          </tr>
          <tr>
            <td><code>!=</code></td>
            <td>Not equal to</td>
          </tr>
          <tr>
            <td><code>&</code></td>
            <td>Logical And</td>
          </tr>
          <tr>
            <td><code>|</code></td>
            <td>Logical Or</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "If",
};
</script>

<template>
  <div
    :height="drawerHeight"
    style="border: 2px solid black"
    class="drawer-container"
  >
    <v-card-title :class="!minimizeDrawer ? 'pa-2' : 'pa-0'">
      <span class="pl-2 text-h6">Query Builder</span>
      <v-spacer></v-spacer>
      <template v-if="queryBuilderPanelOpen">
        <!-- <v-btn
          icon
          @click="
            expandDrawer = !expandDrawer;
            minimizeDrawer = false;
          "
        >
          <v-icon> mdi-arrow-expand </v-icon>
        </v-btn> -->
        <v-btn
          icon
          @click="
            expandDrawer = false;
            minimizeDrawer = !minimizeDrawer;
          "
        >
          <v-icon>{{
            minimizeDrawer ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </v-btn>
      </template>
    </v-card-title>

    <div v-show="!minimizeDrawer" class="query-builder-container">
      <!-- <div v-show="!minimizeDrawer"> -->
      <query-builder
        :query-layer="storeQueryBuilderData[0]"
        :height="queryBuilderHeight"
        @minimize-query-builder="minimizeDrawer = !minimizeDrawer"
      ></query-builder>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import QueryBuilder from "@/components/mapping/QueryBuilder.vue";

export default {
  components: {
    QueryBuilder,
  },

  props: {
    queryBuilderPanelOpen: { type: Boolean, default: false },
  },

  data() {
    return {
      drawerResizing: false,
      expandDrawer: true,
      minimizeDrawer: false,
      currentTab: null,
    };
  },

  watch: {
    storeQueryBuilderData(storeData) {
      this.targetLayers = storeData.filter(
        (layer) => layer.LayerName === this.queryLayer.LayerName
      );
    },

    expandDrawer() {
      this.$emit("drawer-resizing");
    },
    minimizeDrawer() {
      this.$emit("drawer-resizing");
    },
  },

  computed: {
    drawerHeight() {
      return this.expandDrawer ? "65vh" : this.minimizeDrawer ? "5vh" : "65vh";
    },

    queryBuilderHeight() {
      return this.expandDrawer ? "65vh" : "20vh";
    },

    storeQueryBuilderData() {
      return this.$store.state.mapping.queryBuilderData;
    },
  },

  mounted() {},
  beforeDestroy() {
    this.$emit("drawer-resizing");
  },
};
</script>

<style lang="scss" scoped>
.drawer-container {
  display: flex;
  flex-direction: column;
  max-height: 75vh;
  overflow: hidden;
  background-color: white;
}

.query-builder-container {
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #000;
  margin: 1em 1em 1em 1em;
}
</style>

<template>
  <div>
    <v-form ref="”form”" v-model="listValid">
      <v-list class="category-class-list" v-if="mode === 'Categories'">
        <v-row
          class="class-list-headers d-flex px-3 align-center justify-space-between"
        >
          <v-col cols="3">
            <p>Values</p>
          </v-col>

          <v-col cols="2">
            <p>Colour</p>
          </v-col>
          <v-col cols="4">
            <p>Label</p>
          </v-col>
          <v-col cols="1"> </v-col>
        </v-row>
        <v-list-item
          v-for="(categoryClass, index) in tempColormap"
          :key="index"
        >
          <v-row
            class="rescale-values d-flex px-3 align-center justify-space-between"
          >
            <v-col cols="3" align-self="end">
              <v-text-field
                v-model="categoryClass.values"
                :rules="valueRules"
                hint="Accepts a single pixel value, a list of pixel values (ex. 1,2,3) or a range of pixel values (ex. 1-3)"
                @blur="
                  formatValues($event, categoryClass.values, 'values', index)
                "
              ></v-text-field>
            </v-col>

            <v-col cols="2" class="d-flex flex-column align-center">
              <the-color-picker
                :color="categoryClass.color"
                @updated-color="updatedClassColor($event, index)"
              ></the-color-picker>
            </v-col>
            <v-col cols="4" align-self="end">
              <v-text-field
                v-model="categoryClass.label"
                type="text"
                class="px-2"
                hide-hint
                :rules="labelRules"
                @focus="setCurrentClass($event, index)"
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-icon @click="removeClass(index)" color="error"
                >mdi-close</v-icon
              >
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
      <v-list class="category-class-list" v-else-if="mode === 'Intervals'">
        <v-row
          class="class-list-headers d-flex px-3 align-center justify-space-between"
        >
          <v-col cols="4"><p>Values</p></v-col>

          <v-col cols="2">
            <p>Colour</p>
          </v-col>
          <v-col cols="4">
            <p>Label</p>
          </v-col>
          <v-col cols="1"> </v-col>
        </v-row>
        <v-list-item
          v-for="(categoryClass, index) in tempColormap"
          :key="index"
        >
          <v-row
            class="rescale-values d-flex px-3 align-center justify-space-between"
          >
            <v-col cols="4" align-self="end" class="d-flex">
              <v-text-field
                class="pr-2"
                v-model.number="categoryClass.values[0][0]"
                label="Min"
                type="number"
                hide-hint
                hide-details
              ></v-text-field>
              <v-text-field
                class="pl -2"
                v-model.number="categoryClass.values[0][1]"
                label="Max"
                type="number"
                hide-hint
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="2" class="d-flex flex-column align-center">
              <the-color-picker
                :color="categoryClass.color"
                @updated-color="updatedClassColor($event, index)"
              ></the-color-picker>
            </v-col>
            <v-col cols="4" align-self="end">
              <v-text-field
                v-model="categoryClass.label"
                type="text"
                class="px-2"
                hide-hint
                :rules="labelRules"
                @focus="setCurrentClass($event, index)"
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-icon @click="removeClass(index)" color="error"
                >mdi-close</v-icon
              >
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </v-form>

    <div class="d-flex justify-end pr-4">
      <div>
        <v-btn icon>
          <v-icon color="green" @click="addNewClass">mdi-plus</v-icon>
        </v-btn>
        <span>Add Class</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  formatEditableSymbology,
  newCategoryClass,
  newIntervalClass,
} from "@/api/mapping.js";
import TheColorPicker from "@/components/mapping/TheColorPicker.vue";
export default {
  props: {
    colormap: { type: Object },
    newClassifiedList: { type: Array },
    mode: { type: String },
  },

  components: { TheColorPicker },

  data() {
    return {
      tempColormap: {},
      currentClass: null,
      listValid: false,
    };
  },

  watch: {
    listValid(validity) {
      this.$emit("category-list-valid", validity);
    },

    tempColormap: {
      handler(newColormap) {
        if (newColormap.length !== 0) {
          this.$emit("colormap-updated", newColormap);
        } else if (newColormap.length === 0) {
          this.$emit("colormap-updated", newColormap);
          this.listValid = false;
        }
      },
      deep: true,
    },

    newClassifiedList(newList) {
      this.tempColormap = newList;
    },
  },

  computed: {
    currentLabels() {
      return this.tempColormap.map((c) => c.Label.toLowerCase().trim());
    },
    valueRules() {
      const rules = [];

      // required
      rules.push((v) => !!v || "Value(s) are required");

      // characters: (value) =>
      rules.push(
        (v) =>
          /^[0-9,-.]+$/.test(v) || `Value(s) can only contain (0-9, ",", "-")`
      );

      return rules;
    },

    labelRules() {
      const rules = [];
      const max = 40;
      // name length
      if (max) {
        const rule = (v) =>
          (v || "").length <= max ||
          `A maximum of ${max} characters is allowed`;
        rules.push(rule);
      }

      // required
      rules.push((v) => !!v || "Label is required");

      // label name already exists
      // TODO: fix label rule, currently input is too eager
      // const rule = (v) =>
      //   !this.currentLabels.includes(v.toLowerCase().trim()) ||
      //   "Label must be unique";

      // rules.push(rule);

      return rules;
    },
  },

  methods: {
    addNewClass() {
      let newClass;

      if (this.mode === "Intervals") {
        newClass = newIntervalClass();
      } else {
        newClass = newCategoryClass();
      }

      // Add a new class with an empty value
      this.tempColormap.push(newClass);
    },
    removeClass(index) {
      // Remove a class from the tempColormap
      this.tempColormap.splice(index, 1);
    },
    setCurrentClass($event, index) {
      this.currentClass = index;
    },

    formatValues(event, values, field, index) {
      if (!Array.isArray(values)) {
        if (!/^[0-9,-]+$/.test(values)) {
          return;
        }

        let formattedValues = [];
        // trim white space

        const trimmedValue = values.replace(/\s/g, "");

        const rangeRegex = new RegExp("-").test(trimmedValue);
        const arrayRegex = new RegExp(",").test(trimmedValue);

        // string range
        if (rangeRegex) {
          const range = (start, stop, step) =>
            Array.from(
              { length: (stop - start) / step + 1 },
              (_, i) => start + i * step
            );

          formattedValues = trimmedValue.split("-");

          formattedValues = range(
            parseInt(formattedValues[0]),
            parseInt(formattedValues[1]),
            1
          );
        }

        // string array
        else if (arrayRegex) {
          formattedValues = trimmedValue
            .split(",")
            .map((value) => parseInt(value));
        }

        // single value
        else {
          formattedValues.push(parseInt(trimmedValue));
        }

        this.$set(this.tempColormap, index, {
          ...this.tempColormap[index],
          [field]: formattedValues,
        });
      }
    },

    updatedClassColor(newColor, index) {
      // update category color
      this.tempColormap[index].color = newColor;
    },
  },

  mounted() {
    // create a new object from each class, where the label is a new property that can be edited.
    this.tempColormap = formatEditableSymbology(this.colormap);
  },
};
</script>

<style scoped>
.category-class-list {
  background-color: transparent;
}

.class-list-headers {
  text-align: center;
}
</style>

<template>
  <v-overlay class="text-center" v-if="datasetListLoading">
    <v-progress-circular
      :size="50"
      :color="$store.state.settings.appColor"
      indeterminate
    ></v-progress-circular>
  </v-overlay>
  <v-dialog
    v-else-if="!datasetInformation"
    max-width="600px"
    height="80%"
    v-model="showSingleDatasetDialog"
    persistent
  >
    <single-dataset-selector
      :selection-title="$store.state.settings.dataSelectionType.selectionTitle"
      :tagFilter="$store.state.settings.dataSelectionType.tagFilter"
      :createNewDataset="
        $store.state.settings.dataSelectionType.allowCreateNewDataset
      "
      @update-dataset="updateDataset"
    >
    </single-dataset-selector>
  </v-dialog>
  <div v-else-if="datasetInformation && Data" style="overflow: auto">
    <v-list-item v-for="(selection, key) in selectionInformation" :key="key">
      <v-list-item-icon class="my-auto mx-0 pr-4">
        <v-icon dark>{{ selection.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="mb-0">{{ key }}</v-list-item-title>
      </v-list-item-content>

      <v-list-item-action class="list-action">
        <v-autocomplete
          solo
          dense
          hide-details
          :class="
            disableInputs ? 'list-action-text-disabled' : 'list-action-text'
          "
          :items="selection.options"
          v-model="selectedValues[key]"
          :item-text="selection.textProperty"
          :item-value="selection.valueProperty"
          :disabled="disableInputs"
          @change="selection.changeEvent"
        >
        </v-autocomplete>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
import { getFWWFVirtualDatasetLayer } from "@/api/geoprocessing";
import SingleDatasetSelector from "@/components/data/SingleDatasetSelector.vue";

export default {
  name: "MapDrawerLayerLoader",

  components: { SingleDatasetSelector },

  props: {
    disableInputs: { type: Boolean, default: false },
  },

  data() {
    return {
      selectionInformation: {
        // values that need to be updated in layer definition
        Season: {
          type: "menuList",
          source: "store",
          api: null,
          options: [],
          textProperty: (item) => `${item["SID"]}`,
          filters: null,
          valueProperty: (item) => item,
          changeEvent: (selectedSeason) => {
            delete this.selectedValues.Purpose;
            delete this.selectedValues.Data;
            delete this.selectedValues.Units;

            this.selectionInformation.Data.options = this.filteredData;

            // build purpose list
            this.selectionInformation.Purpose.options =
              this.generatePurposeList(this.selectionInformation.Data.options);

            // set start/end date or compile array of valid dates
            if (typeof selectedSeason !== "string") {
              this.startDate = selectedSeason["Season start date*"];
              this.endDate = selectedSeason["Season end date*"];
              this.dates = null;
            } else {
              // compute list of all dates
              this.dates = this.generateSeasonDatesList(selectedSeason);
              this.startDate = null;
              this.endDate = null;
            }
          },
          icon: "mdi-calendar-range",
          order: 3,
        },
        Purpose: {
          type: "menuList",
          source: "api",
          api: null,
          options: [],
          filters: null,
          changeEvent: () => {
            // remove previously selected data, timestep and units
            delete this.selectedValues.Data;
            delete this.selectedValues.Units;

            this.selectionInformation.Data.options = this.filteredData;
          },

          icon: "mdi-format-list-bulleted-type",
          order: 1,
        },
        Data: {
          type: "autocomplete",
          source: "api-appdata",
          api: null,
          options: [],
          filters: null,
          textProperty: (item) => item[0],
          valueProperty: (item) => item,
          changeEvent: (selectedData) => {
            // remove previously selected units
            delete this.selectedValues.Units;

            this.selectionInformation.Units.options = selectedData[1].Units;
          },

          icon: "mdi-layers-search",
          order: 2,
        },

        Units: {
          type: "menuList",
          source: "selectedLayer",
          api: null,
          options: [],
          filters: null,
          changeEvent: (selectedUnits) => {
            this.getVirtualLayerTemplate(
              `${this.selectedValues.Data[0]} (${selectedUnits})`
            );
          },
          icon: "mdi-ruler",
          order: 5,
        },
      },

      showSingleDatasetDialog: true,

      selectedValues: {},

      startDate: null,
      endDate: null,
      defaultEndDate: null,
      dates: null,
    };
  },

  watch: {
    disableInputs(disableInputs) {
      if (disableInputs)
        this.$showLoadingOverlay({
          title: "Loading, please wait.",
          updateMessage: `One Moment`,
        });
      else {
        this.$showLoadingOverlay();
      }
    },
  },

  computed: {
    datasetInformation() {
      return !!this.$store.state.mapping.singleDataset;
    },

    datasetListLoading() {
      return this.$store.state.accountData.datasetList.fetchingUpdates;
    },

    Data() {
      return this.$store.state.appData["FWWF_DATA_SELECTION"];
    },

    filteredData() {
      const selectedSeason = this.selectedValues?.Season;
      const selectedPurpose = this.selectedValues?.Purpose
        ? this.selectedValues.Purpose
        : false;

      const seasonType =
        typeof selectedSeason !== "string"
          ? `${selectedSeason["Season type"]}Season`
          : `${this.findSeasonType(selectedSeason.split("/")[1])}Season`;

      return Object.entries(this.Data)
        .filter(([key, val]) => {
          if (
            val[seasonType] &&
            (selectedPurpose ? val["Purposes"].includes(selectedPurpose) : true)
          ) {
            return [key, val];
          } else {
            [];
          }
        })
        .sort();
    },
  },

  methods: {
    async updateDataset(dataset) {
      this.$store.commit("mapping/setSingleDataset", dataset);

      await this.$store.dispatch("accountData/refreshSeasonList", {
        datasetName: dataset.DatasetName,
        datasetOwner: dataset.DatasetOwner,
      });

      // build season list
      this.selectionInformation.Season.options = this.generateSeasonList(
        this.$store.state.accountData.seasonList.seasons
      );

      this.$emit("update-dataset", dataset);
    },

    async getVirtualLayerTemplate(layerString) {
      // send initial empty emit to activate loading overlay early
      this.$emit("updated-layer-details");

      try {
        const isSingleSeason =
          typeof this.selectedValues.Season === "string" ? false : true;

        const layerTemplate = await getFWWFVirtualDatasetLayer(
          this.datasetInformation.DatasetName,
          this.datasetInformation.DatasetOwner,
          layerString,
          isSingleSeason
        );

        // this part is specific to fwwf at the moment and should be made dynamic
        // layerTemplate.Timestep = this.selectedValues.Data[1].TimestepIsAnnual
        //   ? "Annually"
        //   : null;
        layerTemplate.Units = this.selectedValues.Units;
        layerTemplate.Dates = this.dates;
        layerTemplate.StartDate = this.startDate;
        layerTemplate.EndDate = this.endDate;
        layerTemplate.DefaultEndDate = null;
        layerTemplate.VirtualLayerName = layerString;
        layerTemplate.GroupByFilters = this.selectedValues.Data[1].GroupByField;
        layerTemplate.LayerName = layerString;

        this.$emit("updated-layer-details", layerTemplate);
      } catch (error) {
        this.$showAlert({
          text: error,
          type: "error",
        });
      }
    },

    generateSeasonList(seasonList) {
      const seasons = [...seasonList];

      const uniqueSeasonNames = new Set();

      seasons.forEach((season) =>
        uniqueSeasonNames.add(season["Season name*"])
      );

      const sortedUniqueSeasonNames = [...uniqueSeasonNames].sort();

      // append unique season names with all years
      for (let i = sortedUniqueSeasonNames.length - 1; i >= 0; i--) {
        const seasonName = sortedUniqueSeasonNames[i];
        seasons.unshift(`All Years/${seasonName}`);
      }

      return seasons;
    },

    generatePurposeList(dataLayers) {
      const layers = [...dataLayers];

      const uniquePurposes = new Set();

      layers.forEach((layer) => {
        layer[1].Purposes.forEach((purpose) => uniquePurposes.add(purpose));
      });

      const sortedUniquePurposes = [...uniquePurposes].sort();

      return sortedUniquePurposes;
    },

    generateSeasonDatesList(seasonName) {
      const seasons = this.$store.state.accountData.seasonList.seasons;

      const filteredSeasons = seasons.filter(
        (season) => season["Season name*"] === seasonName.split("/")[1]
      );

      const seasonDates = filteredSeasons.map((season) => ({
        StartDate: season["Season start date*"],
        EndDate: season["Season end date*"],
      }));

      return seasonDates;
    },

    // finds first season type with same season name
    findSeasonType(seasonName) {
      const seasons = this.$store.state.accountData.seasonList.seasons;

      const season = seasons.find(
        (season) => season["Season name*"] === seasonName
      );

      return season["Season type"];
    },

    // deleteSubsequentSelections(orderNumber) {
    //   Object.entries(this.selectedValues).forEach;
    //   for (const [key, val] of this.selectedValues) {
    //     console.log(key, val, orderNumber);
    //   }
    // },
  },

  mounted() {
    if (this.datasetInformation) {
      // build season list
      this.selectionInformation.Season.options = this.generateSeasonList(
        this.$store.state.accountData.seasonList.seasons
      );

      this.$emit("update-dataset", this.datasetInformation);
    }
  },
};
</script>

<style scoped>
.list-action {
  width: 50%;
  justify-content: end;
}

.list-action-text {
  font-size: 0.8em;
  text-align: end;
}

.list-action-text-disabled {
  font-size: 0.8em;
  text-align: end;
}
.list-action-text:hover {
  filter: invert(75%);
}

.list-action-text-disabled:hover {
  filter: unset;
}

.list-action-menu-text {
  font-size: 0.8em;
}
</style>

// views
import DataViewer from "@/views/DataViewer.vue";

const custom = [
  // views
  {
    path: "/data-viewer",
    component: DataViewer,
    name: "Data Viewer",
    meta: { requiresAuth: true, allowPublicAuth: true },
  },
];

export default custom;

function generateHslaColors(saturation, lightness, alpha, amount) {
  let colors = [];
  let huedelta = Math.trunc(360 / amount);

  const randomValue = (min, max) =>
    Math.floor(Math.random() * (max - min) + 1) + min;

  // complete random single color
  if (amount === 1) {
    colors = `hsla(${randomValue(0, 360)}, ${randomValue(
      30,
      95
    )}%, ${randomValue(30, 80)}%, ${alpha})`;
  }

  // returns an array of colors that are different from one another
  else {
    for (let i = 0; i < amount; i++) {
      let hue = i * huedelta;
      colors.push(`hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`);
    }
  }

  return colors;
}

export default generateHslaColors;

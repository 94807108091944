<template>
  <div class="pa-2">
    <sequence-run-landscape-s-t-k></sequence-run-landscape-s-t-k>
  </div>
</template>

<script>
import SequenceRunLandscapeSTK from "@/components/sequence/SequenceRunLandscapeSTK.vue";

export default {
  name: "LandscapeSimulator",

  components: {
    SequenceRunLandscapeSTK,
  },
};
</script>

<template>
  <v-dialog v-model="showDocumentation" scrollable max-width="70%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        title="Show Help Documentation"
        class="help-button"
        fab
        dark
        small
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon dark> mdi-help </v-icon>
      </v-btn>
    </template>
    <v-card class="information-card" color="blue-grey lighten-4">
      <v-row align="center" justify="center" class="py-2">
        <v-col cols="6" md="12" class="d-flex justify-center">
          <v-img
            class="mx-auto"
            :aspect-ratio="16 / 9"
            max-width="200"
            contain
            :src="logoPath"
          >
          </v-img>
        </v-col>
      </v-row>

      <v-card-title style="word-break: break-word"
        >{{ overlayDetails.title }}
      </v-card-title>
      <v-card-text class="text-area">
        <p
          v-html="value"
          v-for="(value, key) in overlayDetails.descriptionText"
          :key="key"
        ></p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import logo from "@/assets/img/logo.png";

export default {
  data() {
    return {
      logoPath: null,
      showDocumentation: false,
    };
  },

  computed: {
    overlayDetails() {
      return this.$store.state.settings.dataViewerSettings.helpOverlayDetails;
    },
  },

  methods: {},

  async mounted() {
    if (this.overlayDetails.logo !== undefined || !this.overlayDetails.logo) {
      this.logoPath = logo;
    }
  },
};
</script>

<style lang="scss" scoped>
.information-card {
  text-align: center;
  padding: 20px;
  overflow-y: hidden;
}
.text-area {
  text-align: start;
  overflow: auto;
}
</style>

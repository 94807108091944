<template>
  <div class="d-flex justify-center align-center pa-5" v-if="initializing">
    <v-progress-circular indeterminate :color="color"></v-progress-circular>
  </div>
  <div v-else>
    <div class="pad-card">
      <v-subheader>Output type</v-subheader>
      <v-radio-group v-model="modelParams.gen_surface" row @change="update">
        <v-radio :color="color" label="Least Cost Surface" :value="true">
        </v-radio>
        <v-radio :color="color" label="Paths" :value="false"> </v-radio>
      </v-radio-group>
    </div>
    <v-divider></v-divider>
    <div class="pad-card">
      <v-subheader>Source Parameters</v-subheader>
      <v-row row wrap align-center justify-space-around>
        <v-col sm="6" class="pad-form">
          <v-text-field
            v-model="modelParams.num_sources"
            label="Number of sources"
            outline
            mask="######"
            @input="update"
            :color="color"
          >
          </v-text-field>
        </v-col>
        <v-col sm="6" class="pad-form">
          <v-select
            v-model="modelParams.source_method"
            :items="sourceMethods"
            label="Source Selection Method"
            @change="update"
            :color="color"
          >
          </v-select>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div class="pad-card" >
      <v-slide-y-reverse-transition>
        <div>
          <v-subheader>Endpoint Parameters </v-subheader>
          <v-row row wrap align-center justify-space-around>
            <v-col sm="4" class="pad-form">
              <v-text-field
                v-model="modelParams.num_endpoints"
                label="Number of Endpoints"
                outline
                mask="######"
                @input="update"
                :color="color"
              >
              </v-text-field>
            </v-col>
            <v-col sm="4" class="pad-form">
              <v-select
                v-model="modelParams.endpoint_method"
                :items="sourceMethods"
                label="Endpoint Selection Method"
                @change="update"
                :color="color"
              >
              </v-select>
            </v-col>
            <v-col sm="4" class="pad-form">
              <v-text-field
                v-model="modelParams.num_paths"
                label="Stochastic Iterations"
                outline
                mask="###"
                @input="update"
                :color="color"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-slide-y-reverse-transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "LCP",

  props: { params: Object },

  data() {
    return {
      initializing: true,
      modelParams: {
        gen_surface: true,
        num_sources: 1,
        source_method: "random from top 10%",
      },
      sourceMethods: [
        "random from top 10%",
        "random from top 25%",
        "max",
        "min",
      ],
    };
  },

  computed: {
    color() {
      return this.$store.state.settings.appColor;
    },
  },

  methods: {
    update() {
      this.$emit("submit-params", this.modelParams);
    },
  },

  beforeMount() {
    this.modelParams = { ...this.modelParams, ...this.params };
    this.update();
    setTimeout(() => (this.initializing = false), 100);
  },
};
</script>

<style scoped>
.pad-form {
  padding-right: 5px;
  padding-left: 5px;
}
.pad-card {
  padding: 10px;
}
</style>

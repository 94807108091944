<template>
  <expression-selector
    :single-select="true"
    :crud-actions="false"
    :selection-mode="true"
    :batch-select="false"
    :default-tags="['SEQUENCE']"
    :data-table-height="400"
    card-color="grey-lighten-5"
    @expression-selected="$emit('sequence-selected', $event)"
  ></expression-selector>
</template>

<script>
import ExpressionSelector from "@/components/data/ExpressionSelector.vue";

export default {
  name: "SequenceSelector",
  components: {
    ExpressionSelector,
  },
};
</script>

<style scoped></style>

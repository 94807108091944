<template>
  <v-card class="layer-configuration-container">
    <v-card-title>Layer Configurations</v-card-title>
    <v-card-subtitle
      v-if="fullDataset.DatasetType === 'Table'"
      class="text-caption pa-4"
    >
      Table datasets don't have configurable layers.
    </v-card-subtitle>
    <v-card-subtitle v-else-if="!hasFiles" class="text-caption pa-4">
      There are no files associated with this dataset yet. Please upload a file
      before configuring a layer.
    </v-card-subtitle>
    <layer-selector
      v-else
      class="ma-4"
      :dataset-name="fullDataset.DatasetName"
      :dataset-owner="fullDataset.DatasetOwner"
      :dataset-type="fullDataset.DatasetType"
      :full-dataset="fullDataset"
      :layer-names="sortedLayers"
      :initial-layer-name="preSelectLayerName"
      :virtual-layer-mode="virtualLayerMode"
      :allowedActions="allowedActions"
      @update-layer-list="updateLayerList"
    >
    </layer-selector>
  </v-card>
</template>

<script>
import LayerSelector from "@/components/datasets/LayerSelector.vue";
import { sortCaseInsensitive } from "@/helpers/formatting.js";

export default {
  name: "DatasetPropertiesLayers",

  components: {
    LayerSelector,
  },

  props: {
    fullDataset: { type: Object },
    preSelectLayerName: { type: String, default: "Default" },
    editPermission: { type: Boolean, default: false },
  },

  data() {
    return {
      //
    };
  },

  computed: {
    allowedActions() {
      if (this.editPermission) {
        return ["create", "edit", "save", "delete"];
      } else {
        return ["edit"];
      }
    },
    virtualLayerMode() {
      if (!this.editPermission) {
        return true;
      } else return false;
    },
    hasFiles() {
      if (this.fullDataset.NumberOfFiles > 0) {
        return true;
      } else {
        return false;
      }
    },
    layerList() {
      return this.fullDataset.FilterFieldValues;
    },
    sortedLayers() {
      const sortedLayers = sortCaseInsensitive(this.layerList);

      const isDefault = (layer) => layer === "Default";

      const defaultIndex = sortedLayers.findIndex(isDefault);

      sortedLayers.unshift(sortedLayers.splice(defaultIndex, 1)[0]);

      return sortedLayers;
    },
  },

  methods: {
    updateLayerList(updatedList) {
      this.$emit("update-dataset-layer-list", updatedList);
    },
  },
};
</script>

<style scoped>
.layer-configuration-container {
  height: 100%;
  overflow: auto;
}
</style>

const state = {
  authRequired: true,
  appName: process.env.VUE_APP_TITLE,
  appColor: "#657f93",
  alert: null,
  alertStatus: null,
  accountData: [
    { path: "accountData/refreshExpressionList" },
    { path: "accountData/refreshDatasetList" },
    { path: "accountData/refreshProjectList" },
  ],
  appData: [],

  acceptedRoutes: [
    "Project Dashboard",
    "Project",
    "Data Viewer",
    "Expression Manager",
    "Dataset Manager",
    "Landscape Simulator",
    "Sequence: Editor",
    "Job Queues",
  ],
  mapSettings: {
    center: [27, 34],
    zoom: 3,
    maxMapBounds: { sw: [-180, -85], ne: [180, 85] },
    initialBasemap: {
      title: "Satellite Streets",
      url: "mapbox://styles/mapbox/satellite-streets-v11",
    },
    outlineAllPolygons: false,
  },

  dataViewerSettings: {
    layerControlsTimestep: false,
    allowPublicAccess: false,
  },

  dataSelectionType: {
    type: "DatasetSelector",
    selectionTitle: null,
    tagFilter: null,
    allowCreateNewDataset: false,
    allowFeatureSelection: { allow: false, api: "some value" },
    allowProjectFilter: true,
  },

  environment: process.env.VUE_APP_MODE,
};
const mutations = {
  showAlert(state, alert) {
    state.alert = alert;
  },
  alertConfirm(state) {
    state.alertStatus = !state.alertStatus;
  },
};
const getters = {
  isDark: (state) => {
    let color = state.appColor;
    let r, g, b;
    if (color.match(/^rgb/)) {
      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(
        /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
      );

      r = color[1];
      g = color[2];
      b = color[3];
    } else {
      color = +(
        "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
      );

      r = color >> 16;
      g = (color >> 8) & 255;
      b = color & 255;
    }

    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
      return false;
    } else {
      return true;
    }
  },
};
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

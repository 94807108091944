<template>
  <div>
    <v-switch
      v-model="modelParams.single"
      @change="update"
      label="Generate a single number between 0 and 1"
      :color="color"
    ></v-switch>
    <v-slide-y-reverse-transition>
      <v-autocomplete
        v-model="modelParams.distribution"
        :items="distributions"
        label="Search for a distribution"
        item-value="name"
        item-text="name"
        persistent-hint
        :color="color"
      >
        <template v-slot:item="data">
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
            <v-list-item-subtitle
              v-html="data.item.description"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-slide-y-reverse-transition>
    <v-slide-y-reverse-transition>
      <div style="padding-top: 20px;">
        <span>{{ distribution.description }}</span>
        <div
          class="d-flex justify-center align-center"
          v-for="(arg, index) in distribution.args"
          :key="index"
        >
          <v-text-field
            type="number"
            v-model="modelParams[arg.name]"
            :label="arg.name"
            @input="update"
            :color="color"
          ></v-text-field>
        </div>
      </div>
    </v-slide-y-reverse-transition>
  </div>
</template>

<script>
export default {
  name: "Random",

  props: { params: Object },

  data() {
    return {
      initializing: true,
      modelParams: {
        single: true,
        distribution: "uniform",
      },

      distributions: [
        {
          name: "beta",
          description: "Draw samples from a Beta distribution",
          args: [
            { name: "alpha", value: 2 },
            { name: "beta", value: 3 },
          ],
        },
        {
          name: "binomial",
          description: "Draw samples from a binomial distribution",
          args: [
            { name: "trials", value: 10 },
            { name: "probability", value: 0.5 },
          ],
        },
        {
          name: "chisquare",
          description: "Draw samples from a chi-square distribution",
          args: [{ name: "freedom", value: 2 }],
        },
        {
          name: "dirichlet",
          description: "Draw samples from the Dirichlet distribution",
          args: [
            { name: "alpha_x", value: 2 },
            { name: "alpha_y", value: 2 },
          ],
        },
        {
          name: "exponential",
          description: "Draw samples from an exponential distribution",
          args: [{ name: "scale", value: 1 }],
        },
        {
          name: "f",
          description: "Draw samples from an F distribution",
          args: [
            { name: "freedom_numerator", value: 1 },
            { name: "freedom_denominator", value: 10 },
          ],
        },
        {
          name: "gamma",
          description: "Draw samples from a Gamma distribution",
          args: [
            { name: "shape", value: 2 },
            { name: "scale", value: 2 },
          ],
        },
        {
          name: "geometric",
          description: "Draw samples from the geometric distribution",
          args: [{ name: "probability", value: 0.5 }],
        },
        {
          name: "gumbel",
          description: "Draw samples from a Gumbel distribution",
          args: [
            { name: "mode", value: 0 },
            { name: "scale", value: 1 },
          ],
        },
        {
          name: "hypergeometric",
          description: "Draw samples from a Hypergeometric distribution",
          args: [
            { name: "n_good", value: 100 },
            { name: "n_bad", value: 2 },
            { name: "n_sample", value: 10 },
          ],
        },
        {
          name: "laplace",
          description:
            "Draw samples from the Laplace or double exponential distribution with specified location (or mean) and scale (decay)",
          args: [
            { name: "peak_position", value: 0 },
            { name: "decay", value: 1 },
          ],
        },
        {
          name: "logistic",
          description: "Draw samples from a logistic distribution",
          args: [
            { name: "location", value: 0 },
            { name: "scale", value: 1 },
          ],
        },
        {
          name: "lognormal",
          description: "Draw samples from a log-normal distribution",
          args: [
            { name: "mean", value: 0 },
            { name: "std", value: 1 },
          ],
        },
        {
          name: "logseries",
          description: "Draw samples from a logarithmic series distribution",
          args: [{ name: "shape", value: 5 }],
        },
        {
          name: "multinomial",
          description: "Draw samples from a multinomial distribution",
          args: [],
        },
        {
          name: "multivariate_normal",
          description:
            "Draw random samples from a multivariate normal distribution",
          args: [],
        },
        {
          name: "negative_binomial",
          description: "Draw samples from a negative binomial distribution",
          args: [],
        },
        {
          name: "noncentral_chisquare",
          description: "Draw samples from a noncentral chi-square distribution",
          args: [],
        },
        {
          name: "noncentral_f",
          description: "Draw samples from the noncentral F distribution",
          args: [],
        },
        {
          name: "normal",
          description:
            "Draw random samples from a normal (Gaussian) distribution",
          args: [
            { name: "mean", value: 0 },
            { name: "std", value: 1 },
          ],
        },
        {
          name: "pareto",
          description:
            "Draw samples from a Pareto II or Lomax distribution with specified shape",
          args: [],
        },
        {
          name: "poisson",
          description: "Draw samples from a Poisson distribution",
          args: [],
        },
        {
          name: "power",
          description:
            "Draws samples in [0, 1] from a power distribution with positive exponent a - 1",
          args: [],
        },
        {
          name: "rayleigh",
          description: "Draw samples from a Rayleigh distribution",
          args: [],
        },
        {
          name: "standard_cauchy",
          description:
            "Draw samples from a standard Cauchy distribution with mode = 0",
          args: [],
        },
        {
          name: "standard_exponential",
          description:
            "Draw samples from the standard exponential distribution",
          args: [],
        },
        {
          name: "standard_gamma",
          description: "Draw samples from a standard Gamma distribution",
          args: [],
        },
        {
          name: "standard_normal",
          description:
            "Draw samples from a standard Normal distribution (mean=0, stdev=1)",
          args: [],
        },
        {
          name: "standard_t",
          description:
            "Draw samples from a standard Student’s t distribution with df degrees of freedom",
          args: [],
        },
        {
          name: "triangular",
          description:
            "Draw samples from the triangular distribution over the interval [left, right]",
          args: [],
        },
        {
          name: "uniform",
          description:
            "Draw samples from a uniform distribution between 0 and 1",
          args: [],
        },
        {
          name: "vonmises",
          description: "Draw samples from a von Mises distribution",
          args: [],
        },
        {
          name: "wald",
          description:
            "Draw samples from a Wald, or inverse Gaussian, distribution",
          args: [],
        },
        {
          name: "weibull",
          description: "Draw samples from a Weibull distribution",
          args: [{ name: "shape", value: 5 }],
        },
        {
          name: "zipf",
          description: "Draw samples from a Zipf distribution",
          args: [],
        },
      ],
    };
  },

  computed: {
    color() {
      return this.$store.state.settings.appColor;
    },
    distribution() {
      return this.distributionFromName(this.modelParams.distribution);
    },
  },

  watch: {
    "modelParams.distribution"(d) {
      if (this.initializing === false) {
        Object.keys(this.modelParams).forEach((key) => {
          if (!["single", "distribution"].includes(key)) {
            delete this.modelParams[key];
          }
        });

        this.distributionFromName(d).args.forEach((a) => {
          this.modelParams[a.name] = a.value;
        });

        this.update();
      }
    },
  },

  methods: {
    distributionFromName(name) {
      const dists = this.distributions.filter((d) => {
        return d.name === name;
      });
      if (dists.length === 1) return dists[0];
      else return { name: null, description: null, args: [] };
    },
    update() {
      this.$emit("submit-params", this.modelParams);
    },
  },

  mounted() {
    this.modelParams = { ...this.modelParams, ...this.params };
    this.update()
    setTimeout(() => (this.initializing = false), 100);
  },
};
</script>

<template>
  <div class="pa-2">
    <dataset-selector></dataset-selector>
  </div>
</template>

<script>
import DatasetSelector from "@/components/data/DatasetSelector.vue";

export default {
  name: "DatasetManager",

  components: {
    DatasetSelector,
  },
};
</script>

<style></style>

<template>
  <div class="history-container">
    <v-list-item class="px-2 ml-5">
      <v-list-item-title>History</v-list-item-title>
      <!-- <v-btn icon @click.stop="mini = !mini">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn> -->

      <v-list-item-action class="d-flex flex-row align-center mb-0">
        <v-menu offset-y disabled>
          <template v-slot:activator="{ on, attrs }">
            <p
              class="text-wrap text-center text-caption"
              v-bind="attrs"
              v-on="on"
            >
              Date Created
            </p>
          </template>
          <v-list>
            <v-list-item
              v-for="(option, index) in sortByOptions"
              :key="index"
              link
            >
              <v-list-item-title>{{ option.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- sort list -->
        <v-btn disabled icon @click="sortByAscending = !sortByAscending">
          <v-icon x-small>{{
            sortByAscending ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>

    <v-divider class="mx-5"></v-divider>

    <v-list dense three-line class="previous-runs">
      <v-list-item-group v-model="selectedRun" active-class="text--accent-4">
        <template v-for="(run, index) in sortedToolRuns">
          <v-list-item :key="run.CreatedAt" :value="run.CreatedAt">
            <v-list-item-content>
              <v-list-item-title
                class="text-body-1 font-weight-regular"
                v-text="run.ScenarioName"
              ></v-list-item-title>

              <v-list-item-subtitle
                class="text-caption text--black"
                v-text="formatDateString(run.CreatedAt)"
              ></v-list-item-subtitle>
              <v-list-item-subtitle
                class="text-caption"
                v-text="run.ExpressionName"
              ></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action class="mx-auto">
              <!-- <v-icon color="grey lighten-1">{{ run.icon }}</v-icon> -->
              <v-list-item-action-text
                class="text-caption text-wrap text-center"
                v-text="run.Status"
              ></v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
          <v-divider
            v-if="index < previousToolRuns.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { formatDateString } from "@/api/v2";
export default {
  props: {
    previousToolRuns: {
      type: Array,
    },

    selectedNewRun: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      selectedRun: null,
      sortByOptions: [
        { title: "Date Created", value: "CreatedAt" },
        { title: "Scenario Name", value: "ScenarioName" },
      ],
      sortBy: "Created At",
      sortByAscending: false,
      mini: false,
    };
  },

  watch: {
    selectedRun(runKey) {
      if (runKey) this.$emit("selected-run", runKey);
    },

    selectedNewRun(runKey) {
      this.selectedRun = runKey;
    },
  },

  computed: {
    sortedToolRuns() {
      const toolRuns = [...this.previousToolRuns];

      return toolRuns.sort(
        (b, a) => new Date(a.CreatedAt) - new Date(b.CreatedAt)
      );
    },
  },

  methods: {
    formatDateString,
  },
};
</script>

<style lang="scss" scoped>
.history-container {
  width: 256px;
  max-height: calc(100vh - 100px);
}

.previous-runs {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  margin-right: 0.2em;
}
</style>

<template>
  <div class="d-flex justify-center align-center pa-5" v-if="initializing">
    <v-progress-circular indeterminate :color="color"></v-progress-circular>
  </div>
  <div v-else>
    <v-row>
      <v-col sm="4">
        <v-text-field
          v-model="modelParams.distance"
          outline
          number
          label="Distance (m)"
          @input="update"
          :color="color"
          style="padding: 20px;"
        ></v-text-field>
      </v-col>
      <v-col sm="4">
        <v-text-field
          v-model="modelParams.fill"
          label="Buffer Fill Value"
          outline
          number
          :color="color"
          @input="update"
          style="padding: 20px;"
        >
        </v-text-field>
      </v-col>
      <v-col sm="4">
        <v-text-field
          v-model="modelParams.replace_fill"
          label="Non-buffer replacement value (optional)"
          outline
          number
          :color="color"
          @input="update"
          style="padding: 20px;"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Buffer",

  props: { params: Object },

  data() {
    return {
      initializing: true,
      modelParams: {},
    };
  },

  computed: {
    color() {
      return this.$store.state.settings.appColor;
    },
  },

  methods: {
    update() {
      this.$emit("submit-params", this.modelParams);
    },
  },

  beforeMount() {
    this.modelParams = { ...this.modelParams, ...this.params };
    setTimeout(() => (this.initializing = false), 100);
  },
};
</script>

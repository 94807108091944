<template>
  <v-card>
    <v-card-title class="text-h6">New Dataset</v-card-title>
    <v-spacer></v-spacer
    ><v-card-subtitle
      >Create a new Flow dataset by entering a descriptive, unique name and
      selecting the dataset type.</v-card-subtitle
    >

    <v-card-text>
      <div v-if="creating" class="d-flex justify-center">
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
      <v-form v-else ref="form" v-model="valid">
        <v-row>
          <v-col cols="12" md="6">
            <p>Dataset Name</p>
            <v-text-field
              v-model.trim="name"
              required
              :rules="nameRules"
              clearable
              :color="$store.state.settings.appColor"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <p>Select data type</p>
            <v-select
              solo
              :items="dataTypes"
              v-model="selectedDataType"
              :color="$store.state.settings.appColor"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex justify-center">
      <v-btn color="warning" text @click="closeNew(null)">Cancel</v-btn>
      <v-btn text @click="newDatasetConfirm()" :disabled="!formValid"
        >Create Dataset</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { createDataset } from "@/api/v2.js";

export default {
  name: "NewDataset",

  data() {
    return {
      isLoading: false,
      overlay: false,
      name: null,
      dataTypes: ["Raster", "Vector", "Table"],
      selectedDataType: "Raster",
      search: null,
      valid: true,
      creating: false,
      dialogNewDataset: false,
    };
  },

  computed: {
    datasets() {
      return this.$store.state.accountData.datasetList.datasets;
    },

    isDark() {
      return this.$store.getters["settings/isDark"];
    },

    nameRules() {
      const rules = [];
      const max = 80;
      // name length
      if (max) {
        const rule = (v) =>
          (v || "").length <= max ||
          `A maximum of ${max} characters is allowed`;
        rules.push(rule);
      }

      // required
      rules.push((v) => !!v || "A name is required");

      // can't contain characters
      rules.push(
        (v) =>
          (v && !/([#\\])/.test(v)) ||
          "Dataset names cannot contain the following characters (#, \\)"
      );

      // dataset name already exists
      if (this.name) {
        const rule = (v) =>
          !this.datasets.AllDatasets.find(
            (dataset) => dataset.DatasetName === v
          ) || "There is already a dataset with that name";

        rules.push(rule);
      }

      return rules;
    },

    formValid() {
      if (this.valid) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    closeNew(dataset) {
      this.$emit("close-new-dataset-dialog", dataset);
    },
    validateField() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    newDatasetConfirm() {
      this.creating = true;

      createDataset(
        this.name,
        this.$store.state.account.user.attributes.email,
        this.selectedDataType
      )
        .then((response) => {
          if (response.Warnings) {
            this.$showAlert({
              text: response.Warnings,
              type: "warning",
            });
          }
          this.$store.dispatch("accountData/refreshDatasetList");
          this.closeNew(response.NewDatasets[0]);
          return response;
        })
        .catch((error) => {
          if (error) {
            this.$showAlert({
              text: error,
              type: "error",
            });
          }
          if (error.response.data.Errors) {
            this.$showAlert({
              text: error.response.data.Errors,
              type: "error",
            });
          }
        })
        .finally(() => {
          this.creating = false;
        });
    },
  },
};
</script>

<style></style>

import { getAppData } from "@/api/v2";

const state = {};
const mutations = {
  setAppData(state, { name, value }) {
    state[name] = value;
  },
};
const actions = {
  async refreshAppData({ commit }, appDataPath) {
    try {
      const appData = await getAppData(appDataPath);

      // throw error if response contains error
      if (appData.Errors) {
        throw appData.Errors;
      }

      commit("setAppData", { name: appDataPath, value: appData.Value });

      // show warning after data has been refreshed
      if (appData.Warnings) {
        this.commit(
          "alert/showAlert",
          {
            text: appData.Warnings,
            type: "warning",
          },
          { root: true }
        );
      }

      return appData;
    } catch (error) {
      this.commit(
        "alert/showAlert",
        {
          text: error,
          type: "error",
        },
        { root: true }
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};

<template>
  <div>
    <v-btn
      v-if="textNext"
      color="primary"
      @click="$emit('change-step', step + 1)"
      :disabled="disableNext"
    >
      {{ textNext }}
    </v-btn>
    <v-btn v-if="textBack" text @click="$emit('change-step', step - 1)">
      {{ textBack }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "SequenceStepperButtons",
  props: {
    step: { type: Number },
    textNext: { type: String, default: "Next" },
    textBack: { type: String, default: "Back" },
    disableNext: { type: Boolean, default: false },
  },
};
</script>

<style scoped></style>

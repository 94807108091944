<template>
  <div class="d-flex flex-column justify-center">
    <span>The <code>if</code> method takes 3 arguments in the form:</span>
    <code style="padding-bottom: 15px;"
      >if(condition, result if true, result if false)</code
    >
    <v-divider></v-divider>
    <span>Conditions are created using the <code>cond</code> method. For example:</span>
    <code
      ># Select all data with a value of 22<br />if(cond(data(name="landcover") ==
      22), ...</code
    >
    <span
      >The "result if true" and "result if false" arguments can be
      <code>None</code> (no data), a single number, or another expression. For
      Example:</span
    >
    <code
      ># Apply school age where the condition is true, and otherwise discard<br />if(cond(data(name="landcover")
      == 22), data(name="School Age"), None)</code
    >

    <v-divider></v-divider>
    <span>Multiple conditions can be nested using the conditional operators (see <code>cond</code>). For
    example:</span>
    <code
      ># Select all data with a value of 22 or 68<br />if(cond(data(name="landcover")
      == 22) | cond(data(name="landcover") == 68), data(name="School Age"),
      None)</code
    >
  </div>
</template>

<script>
export default {
  name: "If",
};
</script>

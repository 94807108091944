<template>
  <div>
    <dataset-selector
      @update-dataset="update"
      @update-layer="updateLayer"
      :selection-mode="true"
      :batch-select="false"
      :crud-actions="false"
      :multi-layer-select="true"
    ></dataset-selector>

    <div v-if="dataset">
      <div class="d-flex justify-center align-center">
        <v-btn small text @click="showHints = !showHints">{{
          showHints ? "Hide Help" : "Show Help"
        }}</v-btn>
      </div>

      <v-row v-if="dataset" class="d-flex justify-center align-center">
        <v-col cols="12" md="5">
          <v-text-field
            type="number"
            label="Set Time"
            v-model="modelParams.set_time"
            @input="$emit('submit-params', modelParams)"
            :color="color"
            :hint="showHints ? setTimeHint : ''"
            class="pb-3"
            :persistent-hint="showHints"
            prepend-icon="mdi-timeline-clock-outline"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="5">
          <v-select
            label="Snap Time"
            v-model="modelParams.snap_time"
            :items="snapTimeOptions"
            @input="$emit('submit-params', modelParams)"
            :color="color"
            :hint="showHints ? snapTimeHint : ''"
            class="pb-3"
            :persistent-hint="showHints"
            prepend-icon="mdi-routes-clock"
          ></v-select>
        </v-col>
        <v-col cols="12" md="5">
          <v-text-field
            type="number"
            label="Time Delta"
            v-model="modelParams.time_delta"
            @input="$emit('submit-params', modelParams)"
            :color="color"
            :hint="showHints ? timeDeltaHint : ''"
            :persistent-hint="showHints"
            class="pb-3"
            prepend-icon="mdi-sort-clock-descending-outline"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="5">
          <v-text-field
            type="number"
            label="No Data Fill"
            v-model="modelParams.no_data_fill"
            @input="$emit('submit-params', modelParams)"
            :color="color"
            :hint="showHints ? noDataFillHint : ''"
            :persistent-hint="showHints"
            prepend-icon="mdi-view-grid-plus-outline"
            class="pb-3"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="5">
          <v-select
            label="Resample Method"
            v-model="modelParams.resample_method"
            :items="resampleMethods"
            @input="$emit('submit-params', modelParams)"
            :color="color"
            :hint="showHints ? resampleMethodsHint : ''"
            :persistent-hint="showHints"
            prepend-icon="mdi-circle-expand"
          ></v-select>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import DatasetSelector from "@/components/data/DatasetSelector.vue";

export default {
  name: "Dataset",

  components: {
    DatasetSelector,
  },

  props: { params: Object },

  data() {
    return {
      dataset: null,
      showHints: false,
      expandOptionalParameters: false,

      modelParams: {
        name: null,
        scen: null,
        set_time: null,
        snap_time: null,
        time_delta: null,
      },

      snapTimeOptions: ["closest", "forward", "backward"],

      resampleMethods: [
        "nearest",
        "bilinear",
        "cubic",
        "cubic spline",
        "lanczos",
        "average",
        "root mean square",
        "mode",
        "max",
        "min",
        "median",
        "first quartile",
        "third quartile",
        "sum",
      ],

      setTimeHint:
        "Datasets may vary temporally. During a simulation, data are retrieved from the " +
        "same time as the simulation time step. In some cases, manually setting a time to " +
        "retrieve data is warranted. The set_time parameter enables a dataset " +
        "to be always retrieved from the specified time.",
      snapTimeHint:
        "Datasets may vary temporally. During a simulation, data are retrieved from the " +
        "same time as the simulation time step. In some cases, data may not exist for the current " +
        "time step. The snap_time parameter enables data to be selected from the closest time forwards " +
        "or backwards where data are available.",
      timeDeltaHint:
        "Datasets may vary temporally. During a simulation, data are retrieved from the " +
        "same time as the simulation time step. In some cases, data may need to be selected from a time " +
        "before or after the current simulation time. The time_delta parameter enables data to be collected " +
        "from a number of increments before (negative) or after (positive) the current time step.",
      noDataFillHint:
        "Values of no data in a raster take precedence during calculations, and areas with no data in any " +
        "dataset will have no data in expression results. If this is undesireable, the values may be " +
        "replaced with a set value to ensure values result over areas with no data.",
      resampleMethodsHint:
        "Data must be resampled to the specified resolution of a simulation. The resample_method is an " +
        "interpolation or statistical method that is used to assign values to a spatially-modified grid.",
    };
  },

  computed: {
    color() {
      return this.$store.state.settings.appColor;
    },
  },

  methods: {
    update(dataset) {
      this.dataset = dataset;
      if (dataset) {
        this.modelParams.name = dataset.DatasetName;
        this.$emit("submit-params", this.modelParams);
      }
    },

    updateLayer(layers) {
      if (!this.dataset.FilterFieldValues || layers === null) {
        return;
      }
      // single value as string
      else if (layers.length === 1) {
        this.modelParams.feature_name = `'${layers[0].LayerName}'`;
      }

      // send full array

      // TODO:change to layer at some points
      else {
        const layerNames = layers.map((layer) => layer.LayerName);
        this.modelParams.feature_name = layerNames;
      }

      this.$emit("submit-params", this.modelParams);
    },
  },

  beforeMount() {
    this.modelParams = { ...this.modelParams, ...this.params };
  },
};
</script>

<style scoped></style>

<template>
  <div class="pl-2 pr-2 text-lg-center">
    <div v-for="(item, index) in Object.keys(autoCompletes)" :key="index">
      <v-subheader>{{ item }}</v-subheader>
      <v-divider></v-divider>
      <v-row>
        <v-col
          v-for="(listitem, listindex) in autoCompletes[item]"
          :key="listindex"
          xs="12"
          sm="4"
          md="3"
          lg="2"
        >
          <v-list-item
            two-line
            @click="
              sendAutoComplete({
                type: item,
                method: autoCompletes[item][listindex],
              })
            "
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-list-item-content v-on="on">
                  <v-list-item-title
                    ><code
                      class="white--text"
                      style="background-color: rgb(43, 43, 43)"
                      >{{ listitem.s }}</code
                    ></v-list-item-title
                  >

                  <v-list-item-subtitle>{{
                    listitem.description
                      ? listitem.description.length > 40
                        ? listitem.description.slice(0, 37) + "..."
                        : listitem.description
                      : ""
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <span>{{ listitem.description }}</span>
            </v-tooltip>
          </v-list-item>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "AutoComplete",

  props: {
    token: String,
  },

  methods: {
    sendAutoComplete(params) {
      this.$emit("choose-autocomplete", params);
    },
  },

  computed: {
    autoCompletes() {
      return this.$store.getters["expressions/autocompleteMatches"](this.token);
    },
  },
};
</script>

<template>
  <amplify-authenticator>
    <amplify-sign-in
      :header-text="title ? title : 'Authentication'"
      hide-sign-up
      slot="sign-in"
      username-alias="email"
    ></amplify-sign-in>
  </amplify-authenticator>
</template>

<script>
export default {
  name: "AmplifyAuth",
  props: {
    title: String,
  },
};
</script>

<style>
amplify-authenticator {
  display: flex;
  justify-content: center;
  align-items: center;
  --container-height: 50vh;
}
</style>

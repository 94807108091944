<template>
  <div
    style="height: calc(100vh - 125px); border: 2px solid darkgrey"
    class="mb-2"
  >
    <public-auth-login-overlay
      v-if="!isAuthenticated && allowPublicAccess"
    ></public-auth-login-overlay>
    <v-container fluid pa-0 class="d-flex flex-column fill-parent-height">
      <v-row no-gutters class="main-content-row fill-parent-height flex-grow-1">
        <!-- left layers panel -->
        <v-col
          cols="12"
          sm="6"
          md="3"
          lg="3"
          xl="2"
          v-show="layerPanelOpen"
          class="scrollable-panel fill-parent-height map-drawer"
        >
          <map-drawer
            :layer-panel-open="layerPanelOpen"
            :data-selection-type="$store.state.settings.dataSelectionType.type"
            :limited-functionality="isPublicAuth"
            @close-layer-panel="layerPanelOpen = false"
          ></map-drawer>
        </v-col>

        <div
          v-show="!layerPanelOpen"
          style="position: absolute; top: 50px; z-index: 10"
        >
          <v-btn
            tile
            @click="layerPanelOpen = true"
            x-small
            height="70px"
            elevation="8"
            ><v-icon>mdi-layers</v-icon></v-btn
          >
        </div>

        <!-- center main content -->
        <v-col
          class="d-flex flex-column fill-parent-height non-scrollable-panel"
        >
          <div class="flex-grow-1">
            <v-card class="fill-parent-height">
              <map-data-viewer :parent-resize="mapResizing"></map-data-viewer>
            </v-card>
          </div>
          <div v-if="tableDrawerData.length > 0" v-show="tablePanelOpen">
            <table-drawer
              :table-data="tableDrawerData"
              :table-panel-open="tablePanelOpen"
              @close-table-panel="tablePanelOpen = false"
              @open-data-panel="editorPanelOpen = true"
              @drawer-resizing="resizeDrawer"
            >
            </table-drawer>
          </div>
          <div
            v-if="queryBuilderData.length > 0"
            v-show="queryBuilderPanelOpen"
          >
            <query-builder-drawer
              :query-builder-panel-open="queryBuilderPanelOpen"
              @close-query-builder-panel="queryBuilderPanelOpen = false"
              @drawer-resizing="resizeDrawer"
            >
            </query-builder-drawer>
          </div>
        </v-col>

        <!-- right  panel -->
        <!-- data -->
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
          v-show="dataPanelOpen && !editorPanelOpen"
          class="scrollable-panel fill-parent-height data-drawer z-index: 10"
        >
          <data-drawer
            :data-panel-open="dataPanelOpen"
            @close-data-panel="dataPanelOpen = false"
          >
          </data-drawer>
        </v-col>

        <!-- table editor -->
        <v-col
          cols="12"
          sm="4"
          md="4"
          lg="3"
          xl="2"
          v-show="editorPanelOpen"
          class="scrollable-panel fill-parent-height flex-grow-1"
        >
          <table-editor-drawer
            :editor-panel-open="tablePanelOpen"
            @close-editor-panel="editorPanelOpen = false"
            @open-table-panel="tablePanelOpen = true"
          >
          </table-editor-drawer>
        </v-col>

        <div
          v-show="!dataPanelOpen && !editorPanelOpen"
          style="position: absolute; right: 0px; top: 50px; z-index: 10"
        >
          <v-btn
            tile
            @click="
              dataPanelOpen = true;
              editorPanelOpen = false;
            "
            height="70px"
            elevation="8"
            x-small
          >
            <div class="d-flex flex-column">
              <v-icon>mdi-chart-timeline-variant</v-icon>
              <v-icon>mdi-map-legend</v-icon>
            </div>
          </v-btn>
        </div>

        <div
          v-if="
            Object.keys(this.$store.state.mapping.tableEditorData).length > 0
          "
          v-show="!dataPanelOpen && !editorPanelOpen"
          style="position: absolute; right: 0px; top: 120px; z-index: 10"
        >
          <v-btn
            tile
            @click="
              editorPanelOpen = true;
              dataPanelOpen = false;
            "
            height="70px"
            elevation="6"
            x-small
          >
            <v-icon>mdi-table-edit</v-icon>
          </v-btn>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MapDataViewer from "@/components/mapping/MapDataViewer.vue";
import MapDrawer from "@/components/mapping/MapDrawer.vue";
import DataDrawer from "@/components/mapping/DataDrawer.vue";
import TableDrawer from "@/components/mapping/TableDrawer.vue";
import TableEditorDrawer from "@/components/mapping/TableEditorDrawer.vue";
import QueryBuilderDrawer from "@/components/mapping/QueryBuilderDrawer.vue";
import PublicAuthLoginOverlay from "@/components/PublicAuthLoginOverlay.vue";

export default {
  name: "DataViewer",

  components: {
    MapDataViewer,
    MapDrawer,
    DataDrawer,
    TableDrawer,
    TableEditorDrawer,
    QueryBuilderDrawer,
    PublicAuthLoginOverlay,
  },
  data() {
    return {
      layerPanelOpen: true,
      dataPanelOpen: true,
      editorPanelOpen: false,
      tablePanelOpen: true,
      queryBuilderPanelOpen: true,
      mapResizing: false,
    };
  },

  watch: {
    layerPanelOpen(newValue) {
      if (!newValue) this.mapResizing = !this.mapResizing;
    },
    dataPanelOpen(newValue) {
      if (!newValue) this.mapResizing = !this.mapResizing;
    },
    editorPanelOpen(newValue) {
      if (!newValue) this.mapResizing = !this.mapResizing;
    },
    tablePanelOpen(newValue) {
      if (!newValue) this.mapResizing = !this.mapResizing;
    },
    queryBuilderPanelOpen(newValue) {
      if (!newValue) this.mapResizing = !this.mapResizing;
    },
  },

  computed: {
    isAuthenticated() {
      return this.$store.state.account.authenticated;
    },

    allowPublicAccess() {
      return this.$store.state.settings.dataViewerSettings.allowPublicAccess;
    },

    isPublicAuth() {
      let publicAuth = false;

      if (this.isAuthenticated)
        publicAuth =
          this.$store.state.account.user.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].every((group) => group.includes("public"));

      return publicAuth;
    },

    layoutSettings() {
      return this.$store.state.settings.dataViewerSettings.layout;
    },

    tableDrawerData() {
      return this.$store.state.mapping.tableData;
    },

    queryBuilderData() {
      return this.$store.state.mapping.queryBuilderData;
    },

    // showLayerPanel() {
    //   return !this.dataPanelOpen && !this.tablePanelOpen;
    // },
    // showRightPanel() {
    //   return !this.tablePanelOpen && !this.layerPanelOpen;
    // },
    // showTablePanel() {
    //   return !this.layerPanelOpen && !this.dataPanelOpen;
    // },
  },

  methods: {
    resizeDrawer() {
      setTimeout(() => {
        this.mapResizing = !this.mapResizing;
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-content-row {
  min-height: 0;
}

.map-drawer {
  min-width: 300px;
  max-width: 300px;
}

.data-drawer {
  min-width: 400px;
}
.scrollable-panel {
  overflow-y: auto;
}

.non-scrollable-panel {
  overflow-y: hidden;
}

.fill-parent-height {
  height: 100%;
}
</style>

import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import settings from "./modules/settings";
import account from "./modules/account";
import accountData from "./modules/accountData";
import mapping from "./modules/mapping";
import expressions from "./modules/expressions";
import alert from "./modules/alert";
import loadingOverlay from "./modules/loadingOverlay";
import project from "./modules/project";
import appData from "./modules/appData";

Vue.use(Vuex);

const vuexSession = new VuexPersistence({
  key: "vuex",
  storage: window.sessionStorage,
  reducer: (state) => ({ accountData: state.accountData }),
});

const store = new Vuex.Store({
  modules: {
    settings,
    account,
    accountData,
    mapping,
    expressions,
    alert,
    project,
    loadingOverlay,
    appData,
  },
  plugins: [vuexSession.plugin],
});

export default store;

<template>
  <v-stepper v-model="step" vertical>
    <v-stepper-step
      :complete="step > steps.outputForm"
      :step="steps.outputForm"
    >
      Output dataset
      <small v-if="step > steps.outputForm && outputDatasets.length > 0">{{
        outputDatasets[0].DatasetName
      }}</small>
    </v-stepper-step>
    <v-stepper-content :step="steps.outputForm">
      <p>Provide a name for the resulting land cover dataset.</p>
      <v-form ref="outputForm" v-model="validForms.outputDataset">
        <v-text-field
          v-model="datasetName"
          :rules="[rules.required]"
          clearable
          outlined
        ></v-text-field>
      </v-form>
      <sequence-stepper-buttons
        :step="steps.outputForm"
        :textBack="null"
        @change-step="
          (s) => {
            step = s;
            if (s > steps.outputForm) validateForm('outputForm');
          }
        "
      ></sequence-stepper-buttons>
    </v-stepper-content>

    <v-stepper-step :complete="step > steps.simParams" :step="steps.simParams">
      Simulation parameters
    </v-stepper-step>
    <v-stepper-content :step="steps.simParams">
      <p>Configure the region and date range of your landscape simulation.</p>
      <v-form ref="simForm" v-model="validForms.simParams">
        <div>
          <div
            v-for="(param_def, param_name) in rootSequenceParameters"
            :key="param_name"
          >
            <sequence-parameter
              :name="param_name"
              :definition="param_def"
              @input="(data) => setParameter(data, 'sim')"
            ></sequence-parameter>
          </div>
        </div>
      </v-form>
      <sequence-stepper-buttons
        :step="steps.simParams"
        @change-step="
          (s) => {
            step = s;
            if (s > steps.simParams) validateForm('simForm');
          }
        "
      ></sequence-stepper-buttons>
    </v-stepper-content>

    <v-stepper-step
      :complete="step > steps.initSequence"
      :step="steps.initSequence"
    >
      Pick an initialization sequence
      <small v-if="this.init.sequence"
        >Selected: {{ this.init.owner }}/{{ this.init.path }}</small
      >
    </v-stepper-step>
    <v-stepper-content :step="steps.initSequence">
      <p>
        This sequence runs once, before the first timestep, and it initializes
        the land cover type, origin date, origin ID, and flags across the AOI.
        Typically an initialization sequence loads the landscape from an
        existing simulation, or it initializes the landscape from external data.
      </p>
      <sequence-selector
        @sequence-selected="(header) => setSequence(header, 'init')"
      ></sequence-selector>
      <sequence-stepper-buttons
        :step="steps.initSequence"
        :disableNext="!this.init.sequence"
        @change-step="(s) => (step = s)"
      ></sequence-stepper-buttons>
    </v-stepper-content>

    <v-stepper-step
      :complete="step > steps.initParams"
      :step="steps.initParams"
    >
      Initialization sequence parameters
    </v-stepper-step>
    <v-stepper-content :step="steps.initParams">
      <v-form ref="initForm" v-model="validForms.initParams">
        <div v-if="initSequenceParameters">
          <p>
            The initialization sequence that you selected has one or more input
            parameters that you can configure below.
          </p>
          <div
            v-for="(param_def, param_name) in initSequenceParameters"
            :key="param_name"
          >
            <sequence-parameter
              :name="param_name"
              :definition="param_def"
              @input="(data) => setParameter(data, 'init')"
            ></sequence-parameter>
          </div>
        </div>
        <p v-else>
          The initialization sequence that you selected has no input parameters.
          You can continue to the next step.
        </p>
      </v-form>
      <sequence-stepper-buttons
        :step="steps.initParams"
        @change-step="
          (s) => {
            step = s;
            if (s > steps.initParams) validateForm('initForm');
          }
        "
      ></sequence-stepper-buttons>
    </v-stepper-content>

    <v-stepper-step
      :complete="step > steps.loopSequence"
      :step="steps.loopSequence"
    >
      Pick a loop sequence
      <small v-if="this.loop.sequence"
        >Selected: {{ this.loop.owner }}/{{ this.loop.path }}</small
      >
    </v-stepper-step>
    <v-stepper-content :step="steps.loopSequence">
      <p>
        This sequence is run every timestep to model landscape dynamics across
        the AOI. Typically a loop sequence runs a series of actions that modify
        the landscape using external data and modeling algorithms.
      </p>
      <sequence-selector
        @sequence-selected="(header) => setSequence(header, 'loop')"
      ></sequence-selector>
      <sequence-stepper-buttons
        :step="steps.loopSequence"
        :disableNext="!loop.sequence"
        @change-step="(s) => (step = s)"
      ></sequence-stepper-buttons>
    </v-stepper-content>

    <v-stepper-step
      :complete="step > steps.loopParams"
      :step="steps.loopParams"
    >
      Loop sequence parameters
    </v-stepper-step>
    <v-stepper-content :step="steps.loopParams">
      <v-form ref="loopForm" v-model="validForms.loopParams">
        <div v-if="loopSequenceParameters">
          <p>
            The loop sequence that you selected has one or more input parameters
            that you can configure below.
          </p>
          <div
            v-for="(param_def, param_name) in loopSequenceParameters"
            :key="param_name"
          >
            <sequence-parameter
              :name="param_name"
              :definition="param_def"
              @input="(data) => setParameter(data, 'loop')"
            ></sequence-parameter>
          </div>
        </div>
        <p v-else>
          The loop sequence that you selected has no input parameters. You can
          continue to the next step.
        </p>
      </v-form>
      <sequence-stepper-buttons
        :step="steps.loopParams"
        @change-step="
          (s) => {
            step = s;
            if (s > steps.loopParams) validateForm('loopForm');
          }
        "
      ></sequence-stepper-buttons>
    </v-stepper-content>

    <v-stepper-step
      :complete="step > steps.computeResources"
      :step="steps.computeResources"
    >
      Compute resources
    </v-stepper-step>

    <v-stepper-content :step="steps.computeResources">
      <p>
        Configure the compute resources for your simulation. The AOI is split
        into <i>N</i> rectangular tiles, which each run on their own CPU. A
        valid choice for the number of tiles depends on the aspect ratio of the
        AOI. Matt or Liam can send you a list of valid choices for # tiles,
        provided your AOI.
      </p>
      <v-form ref="jobForm" v-model="validForms.job">
        <v-container style="margin-left: 0; margin-right: 0">
          <v-row>
            <v-col sm="6" md="3">
              <v-text-field
                v-model="jobDefinitionCalculator.tiles"
                label="# Tiles"
                :rules="[rules.required, rules.int]"
                append-icon="mdi-find-replace"
                @click:append="findHighestTileCount"
                outlined
              >
                <!-- TODO: need button to find next smallest -->
              </v-text-field>
            </v-col>
            <v-col sm="6" md="3">
              <v-select
                v-model="jobDefinitionCalculator.perTileMemGiB"
                label="Memory (per tile)"
                :items="jobDefinitionCalculator.perTileMemGiBItems"
                outlined
              ></v-select>
            </v-col>
            <v-col sm="6" md="3">
              <v-text-field
                v-model="job.timeoutHours"
                label="Timeout (hours)"
                :rules="[rules.required, rules.float]"
                type="number"
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col sm="6" md="3">
              <v-select
                v-model="job.selectedImageTag"
                label="Version"
                :items="job.imageTags"
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </v-container>

        <v-card class="elevation-1" style="margin: 1em">
          <v-card-text>
            <v-select
              v-model="job.selectedJobDefinition"
              label="Compute resources"
              :items="jobDefinitionItems"
              :rules="[rules.required]"
              outlined
            ></v-select>
            <v-simple-table
              v-if="jobDescription.length > 0"
              :items="jobDescription"
              item-value="key"
            >
              <tbody>
                <tr v-for="item in jobDescription" :key="item.key">
                  <td>{{ item.key }}</td>
                  <td>{{ item.value }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-form>

      <sequence-stepper-buttons
        :step="steps.computeResources"
        @change-step="
          (s) => {
            step = s;
            if (s > steps.computeResources) validateForm('jobForm');
          }
        "
        textNext="Run"
      ></sequence-stepper-buttons>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
import {
  getSequence,
  getDataset2,
  getJobDefinitions,
  getJobImageTags,
  submitJob,
} from "@/api/v2.js";
import SequenceSelector from "@/components/sequence/SequenceSelector.vue";
import SequenceStepperButtons from "@/components/sequence/SequenceStepperButtons.vue";
import SequenceParameter from "@/components/sequence/SequenceParameter.vue";

export default {
  name: "SequenceRunLandscapeSTK",

  components: {
    SequenceSelector,
    SequenceStepperButtons,
    SequenceParameter,
  },

  data() {
    return {
      step: 1,

      steps: {
        outputForm: 1,
        simParams: 2,
        initSequence: 3,
        initParams: 4,
        loopSequence: 5,
        loopParams: 6,
        computeResources: 7,
      },

      init: {
        sequence: null,
        owner: null,
        path: null,
      },
      loop: {
        sequence: null,
        owner: null,
        path: null,
      },
      validForms: {
        outputDataset: false,
        simParams: false,
        initParams: false,
        loopParams: false,
        job: false,
      },

      parameters: {
        sim: {},
        init: {},
        loop: {},
      },

      jobDefinitionCalculator: {
        tiles: 1,
        perTileMemGiB: 4,
        perTileMemGiBItems: [
          { text: "2 GB", value: 2 },
          { text: "4 GB", value: 4 },
          { text: "6 GB", value: 6 },
          { text: "8 GB", value: 8 },
          { text: "12 GB", value: 12 },
          { text: "16 GB", value: 16 },
          { text: "20 GB", value: 20 },
          { text: "24 GB", value: 24 },
        ],
        definitions: [],
      },

      job: {
        imageRepoName: "landsim",
        imageTags: [],
        selectedImageTag: null,
        selectedJobDefinition: null,
        timeoutHours: 6,
      },

      datasetName: "",
      rules: {
        required: (value) => Boolean(value) || "Required",
        float: (value) =>
          value == parseFloat(value) || "Must be a number (integer)",
        int: (value) =>
          value == parseInt(value) || "Must be a number (decimal)",
      },

      rootSequenceParameters: {
        aoi: {
          description:
            "The area of interest. This is the region where the landscape is evaluated. This dataset also defines the coordinate system.",
          type: "flow-vector-data",
        },
        timestep: {
          description: "The frequency of timestps. Options are 'YEARLY' or 'DECADELY'.",
          type: "str",
          default: "YEARLY"
        },
        num_timesteps: {
          description:
            "The number of timesteps. Specify a negative value to run backwards. There must be at least one timestep.",
          type: "int",
        },
        earliest_date: {
          description:
            "The earliest date in the simulation. This is the starting date if the timestep is positive, or the ending date if the timestep is negative.",
          type: "date",
        },
        resolution: {
          description: "The resolution of the simulation in meters.",
          type: "float",
        },
        random_seed: {
          description: "The random number seed.",
          type: "int",
          optional: true,
          default: 42,
        },
        
      },
    };
  },

  watch: {
    jobDefinitionCalculator: {
      handler() {
        this.autoSelectJobDefinition();
      },
      deep: true,
    },
  },

  computed: {
    initSequenceParameters() {
      return this.getSequenceParameters(this.init.sequence);
    },
    loopSequenceParameters() {
      return this.getSequenceParameters(this.loop.sequence);
    },
    outputDatasets() {
      if (this.rules.required(this.datasetName) !== true) {
        return [];
      }
      let datasetOwner = this.$store.state.account.user.attributes.email;
      return [
        { DatasetName: this.datasetName, DatasetOwner: datasetOwner },
        {
          DatasetName: `${this.datasetName} (Flags)`,
          DatasetOwner: datasetOwner,
        },
        {
          DatasetName: `${this.datasetName} (Origin ID)`,
          DatasetOwner: datasetOwner,
        },
        {
          DatasetName: `${this.datasetName} (Origin JDN)`,
          DatasetOwner: datasetOwner,
        },
      ];
    },
    jobDefinitionItems() {
      return this.jobDefinitionCalculator.definitions.map((item) => {
        return {
          text: `${item.nodes} ${item.instanceType} (${item.totalCPUs} CPUs, ${item.perCPUMemGiB} GB/CPU)`,
          value: item.arn,
        };
      });
    },
    jobDescription() {
      let jobDefinition = this.jobDefinitionCalculator.definitions.find(
        (definition) => definition.arn == this.job.selectedJobDefinition
      );
      if (!jobDefinition) {
        return [];
      }

      let tiles = this.jobDefinitionCalculator.tiles;
      let perNodeCPUs = jobDefinition.totalCPUs / jobDefinition.nodes;
      let totalMem = jobDefinition.perCPUMemGiB * jobDefinition.totalCPUs;
      let perNodeMem = perNodeCPUs * jobDefinition.perCPUMemGiB;

      let perNodeMaxTiles = Math.ceil(tiles / jobDefinition.nodes);
      let perTileMinMem = totalMem / tiles;
      let approxCost = jobDefinition.perHourApproxCostUSD.toFixed(2);
      return [
        { key: "Tiles", value: `${tiles} (max per node: ${perNodeMaxTiles})` },
        {
          key: "Provisioned CPUs",
          value: `${jobDefinition.totalCPUs} (${perNodeCPUs} CPUs x ${jobDefinition.nodes} nodes)`,
        },
        {
          key: "Provisioned Memory",
          value: `${totalMem} GB (${perNodeMem} GB x ${jobDefinition.nodes} nodes; at least ${perTileMinMem} GB/tile)`,
        },
        { key: "Approximate cost", value: `$${approxCost}/hour` },
        { key: "Max runtime", value: `${this.job.timeoutHours} hours` },
      ];
    },
    jobSubmissionBody() {
      return {
        jobName: this.datasetName.replaceAll(/[^a-zA-Z0-9-_]/g, ""),
        jobQueue: "flow-ec2-job-queue",
        jobDefinitionArn: this.job.selectedJobDefinition,
        command: [
          `${this.jobDefinitionCalculator.tiles}`,
          "landscape_stk",
          "run",
        ],
        environmentVariables: {
          LANDSCAPE_STK_AOI_URI: this.parameters.sim.aoi,
          LANDSCAPE_STK_OUTPUT_URI: `flow:/dataset/${this.$store.state.account.user.attributes.email}/${this.datasetName}`,
          LANDSCAPE_STK_EARLIEST_DATE: this.parameters.sim.earliest_date,
          LANDSCAPE_STK_RESOLUTION: `${this.parameters.sim.resolution}`,
          LANDSCAPE_STK_TIMESTEPS: `${this.parameters.sim.num_timesteps}`,
          LANDSCAPE_STK_TIMESTEP: `${this.parameters.sim.timestep}`,
          LANDSCAPE_STK_RANDOM_SEED: `${this.parameters.sim.random_seed}`,
          LANDSCAPE_STK_CONTINUE_SIMULATION: "False",
          LANDSCAPE_STK_INIT_SEQ_URI: `s3://landscape-stk/sequences/${this.init.owner}/${this.init.path}`,
          LANDSCAPE_STK_INIT_SEQ_PARAMS: JSON.stringify(this.parameters.init),
          LANDSCAPE_STK_LOOP_SEQ_URI: `s3://landscape-stk/sequences/${this.loop.owner}/${this.loop.path}`,
          LANDSCAPE_STK_LOOP_SEQ_PARAMS: JSON.stringify(this.parameters.loop),
        },
        timeout: `${this.job.timeoutHours * 3600}`,
        tags: {
          model: "landscape_stk",
          requester: this.$store.state.account.user.attributes.email,
        },
      };
    },
  },

  methods: {
    setInitSequence(owner, path, seq) {
      if (!seq) {
        this.init.sequence = null;
        this.init.owner = null;
        this.init.path = null;
      } else {
        this.init.sequence = seq;
        this.init.owner = owner;
        this.init.path = path;
      }
    },
    setLoopSequence(owner, path, seq) {
      if (!seq) {
        this.loop.sequence = null;
        this.loop.owner = null;
        this.loop.path = null;
      } else {
        this.loop.sequence = seq;
        this.loop.owner = owner;
        this.loop.path = path;
      }
    },
    setSequence(header, which) {
      let setSequenceCallback =
        which === "init" ? this.setInitSequence : this.setLoopSequence;
      if (!header) {
        setSequenceCallback(null, null, null);
      }
      getSequence(header.ExpressionOwner, header.ExpressionName)
        .then((sequence) => {
          setSequenceCallback(
            header.ExpressionOwner,
            header.ExpressionName,
            sequence
          );
        })
        .catch(() => {
          setSequenceCallback(null, null, null);
        });
    },
    setParameter(data, which) {
      if (data.value === undefined && data.name in this.parameters[which]) {
        delete this.parameters[which][data.name];
      } else {
        this.parameters[which][data.name] = data.value;
      }
    },
    validateForm(which) {
      let form;
      if (which === "simForm") {
        form = this.$refs.simForm;
      } else if (which === "initForm") {
        form = this.$refs.initForm;
      } else if (which === "loopForm") {
        form = this.$refs.loopForm;
      } else if (which === "outputForm") {
        form = this.$refs.outputForm;
      } else if (which === "jobForm") {
        form = this.$refs.jobForm;
      }
      if (!form.validate()) {
        this.step = this.step - 1;
      }

      if (which === "outputForm") {
        // check that datasets dont exist
        let primaryDataset = this.outputDatasets[0];

        getDataset2(primaryDataset.DatasetOwner, primaryDataset.DatasetName)
          .then(() => {
            this.$showAlert({
              text: `Dataset exists: ${primaryDataset.DatasetName}<br><br>Continuing will <strong>overwrite</strong> the existing dataset. Do you want to continue?`,
              type: "warning",
              choice: true,
            }).then((confirmed) => {
              if (!confirmed) {
                this.step = this.step - 1;
              }
            });
          })
          .catch(() => {
            // doesn't exist -- OK
          });
      } else if (which === "jobForm") {
        submitJob(this.jobSubmissionBody).then(() => {
          this.$emit("submitted");
        });
      }
    },
    getSequenceParameters(sequence) {
      if (!sequence) {
        return null;
      } else if (!sequence.metadata) {
        return null;
      } else if (!sequence.metadata.parameters) {
        return null;
      } else if (sequence.metadata.parameters.length == 0) {
        return null;
      } else {
        return sequence.metadata.parameters;
      }
    },
    autoSelectJobDefinition() {
      let requiredTotalMemory =
        this.jobDefinitionCalculator.tiles *
        this.jobDefinitionCalculator.perTileMemGiB;
      let requiredTotalCPUs = this.jobDefinitionCalculator.tiles;

      let jobDefinition = this.jobDefinitionCalculator.definitions.find(
        (definition) => {
          if (definition.totalCPUs < requiredTotalCPUs) {
            return false;
          }
          let totalMemory = definition.totalCPUs * definition.perCPUMemGiB;
          if (totalMemory < requiredTotalMemory) {
            return false;
          }
          // TODO: handle per node memory
          return true;
        }
      );
      if (jobDefinition === undefined) {
        this.job.selectedJobDefinition = null;
      } else {
        this.job.selectedJobDefinition = jobDefinition.arn;
      }
    },
    findHighestTileCount() {
      this.$showAlert({
        text: "This button isn't hooked up yet.",
        type: "warning",
      });
    },
  },

  beforeMount() {
    getJobDefinitions(this.job.imageRepoName).then((jobDefinitions) => {
      this.jobDefinitionCalculator.definitions.push(...jobDefinitions);
    });
    getJobImageTags(this.job.imageRepoName).then((tags) => {
      this.job.imageTags.push(...tags);
      if (this.job.imageTags.length > 0) {
        this.job.selectedImageTag = this.job.imageTags[0];
      }
    });
  },
};
</script>

<style scoped></style>

// Usage:
// formatDataSize(19993045)
// "19.07 MB"

function formatDataSize(bytes, decimals) {
  if (bytes == 0) return "0 Bytes";
  var m = 1024,
    dm = decimals || 2,
    sizes = ["Bytes", "KB", "MB", "GB"],
    i = Math.floor(Math.log(bytes) / Math.log(m));
  return parseFloat((bytes / Math.pow(m, i)).toFixed(dm)) + " " + sizes[i];
}

// Usage:
// sortCaseInsensitive(["apricot", "Apple", "Orange", "apple"])
// ["Apple","apple","apricot","Orange"]
//
// sortCaseInsensitive([{ id: 1, name: "apricot" },{ id: 2, name: "Apple" }, { id: 3, name: "Orange" },{ id: 4, name: "apple" }], 'name')
// [{"id": 2,"name": "Apple"},{"id": 4,"name": "apple"},{"id": 1,"name": "apricot"},{"id": 3,"name": "Orange"}]

function sortCaseInsensitive(list, property) {
  let sortedItems = [...list];

  if (typeof property !== "undefined") {
    return sortedItems.sort((a, b) => {
      const itemA = a[property].toUpperCase(); // ignore upper and lowercase
      const itemB = b[property].toUpperCase(); // ignore upper and lowercase
      if (itemA < itemB) {
        return -1;
      }
      if (itemA > itemB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
  } else {
    return sortedItems.sort((a, b) => {
      const itemA = a.toUpperCase(); // ignore upper and lowercase
      const itemB = b.toUpperCase(); // ignore upper and lowercase
      if (itemA < itemB) {
        return -1;
      }
      if (itemA > itemB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
  }
}

/**
 * Takes a date string (yyyy-mm-dd) and converts to UTC date
 * * ex usage: convertStringToUTCDate("2021-01-01")
 * @param {string} string
 * @returns
 */

function convertStringToDate(string) {
  const dateParts = string.split("-");
  const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);

  return date;
}

function formatMapTimeLabels(time, layerTimeStep) {
  let day = time.getDate();
  let month = time.getMonth() + 1;
  let year = time.getFullYear();

  switch (layerTimeStep) {
    case "Daily":
      return `${year}/${month}/${day}`;
    case "Monthly":
      return `${year}/${month}/${day}`;
    case "Annually":
    case "SemiDecadally":
    case "Decadally":
      return `${year}`;
    default:
      break;
  }
}

/**
* Takes a number x as an argument and returns a formatted string based on
the conditions you provided. It uses Math.abs() to get the absolute value of x and then compares it with the
thresholds (1e-3 and 1e3) to decide whether to represent the number in scientific notation or decimal notation.
* * ex usage: formatNumber(0.00065) // '6.500e-4'
* @param {number} x
*/

function formatNumber(x) {
  const absX = Math.abs(x);
  if (absX === 0) {
    return absX;
  } else if (absX < 1e-3 || absX > 1e3) {
    return x.toExponential(3); // scientific notation with 3 digits after ".", e.g., "1.233e-4" or "-2.341e7"
  } else {
    return x.toPrecision(4) * 1; // decimal notation with 4 characters and 3 decimal places stripping trailing 000s, e.g., "123.432" or "-12.1"
  }
}

// builds an array of table rows as objects, with the property as the column header
function formatTable(attributeTable) {
  const formattedRows = [];

  for (const row of attributeTable.Rows) {
    const formattedColumns = {};

    for (let i = 0; i < attributeTable.Columns.length; i++) {
      const columnName = attributeTable.Columns[i];
      const rowValue = row[i];

      if (columnName === "") formattedColumns["rowIndex"] = rowValue;
      else formattedColumns[columnName] = rowValue;
    }

    formattedRows.push(formattedColumns);
  }

  return formattedRows;
}

export {
  formatDataSize,
  sortCaseInsensitive,
  convertStringToDate,
  formatMapTimeLabels,
  formatTable,
  formatNumber,
};

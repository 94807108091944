// Authentication
import AmplifyAuth from "@/components/auth/AmplifyAuth.vue";
import GetJWT from "@/components/auth/GetJWT.vue";

// App Elements
import AppBar from "@/components/nav/AppBar.vue";
import TheSplashScreen from "@/components/TheSplashScreen.vue";
import TheLoadingOverlay from "@/components/TheLoadingOverlay.vue";

// Views
import Home from "@/views/Home.vue";
import LoginView from "@/views/LoginView.vue";
import ProjectDashboard from "@/views/ProjectDashboard.vue";
import DatasetManager from "@/views/DatasetManager.vue";
import ExpressionManager from "@/views/ExpressionManager.vue";
import SeasonManager from "@/views/SeasonManager.vue";
import AnalysisTools from "@/views/AnalysisTools.vue";
import LandscapeSimulator from "@/views/LandscapeSimulator.vue";
import JobQueues from "@/views/JobQueues.vue";
import UserManagement from "@/views/admin/UserManagement.vue";

// todo
import Project from "@/components/projects/Project.vue";
//

//
//
// COMPONENT EDITOR ROUTES //  - should not be imported into landmarc
//
//

// Datasets
import LayerSelector from "@/components/datasets/LayerSelector.vue";
import DatasetSelector from "@/components/data/DatasetSelector.vue";
import SingleDatasetSelector from "@/components/data/SingleDatasetSelector.vue";
import ExportDatasetTool from "@/components/data/ExportDatasetTool.vue";
import DatasetProperties from "@/components/datasets/DatasetProperties.vue";

// Tables
import DataTable from "@/components/tables/DataTable.vue";

// Expressions
import ExpressionSelector from "@/components/data/ExpressionSelector.vue";
import ExpressionViewer from "@/components/expressions/ExpressionViewer.vue";

// Mapping
import MapDrawer from "@/components/mapping/MapDrawer.vue";
import MapLayerSymbology from "@/components/mapping/MapLayerSymbology.vue";
import MapDrawerLayerLoader from "@/components/mapping/MapDrawerLayerLoader.vue";

// Sequence
import SequenceRunLandscapeSTK from "@/components/sequence/SequenceRunLandscapeSTK.vue";
import SequenceEditor from "@/views/SequenceEditor.vue";
import JobList from "@/components/tables/JobList.vue";

// Filter Models - not currently used
// import FilterModel from "@/components/expressions/FilterModel";
// import FilterModelEditor from "@/components/expressions/FilterModelEditor";

const base = [
  // Authentication
  {
    path: "/amplify-auth",
    component: AmplifyAuth,
    name: "Amplify Auth",
  },
  {
    path: "/jwt",
    component: GetJWT,
    name: "JSON Web Token",
  },

  // App Elements
  {
    path: "/app-bar",
    component: AppBar,
    name: "App Bar",
  },
  {
    path: "/splash-screen",
    component: TheSplashScreen,
    name: "The Splash Screen",
  },

  {
    path: "/loader-overlay",
    component: TheLoadingOverlay,
    name: "The Loading Overlay",
  },

  {
    path: "/sequence/run/landscape-stk",
    component: SequenceRunLandscapeSTK,
    name: "Sequence: Run: Landscape-STK",
  },

  {
    path: "/job/list",
    component: JobList,
    name: "Job List",
  },

  // Views
  {
    path: "/",
    component: Home,
    name: "Home",
    meta: { requiresAuth: false, allowPublicAuth: true },
  },

  {
    path: "/login",
    component: LoginView,
    name: "Login",
    meta: { requiresAuth: false, allowPublicAuth: true },
  },

  {
    path: "/project-dashboard",
    component: ProjectDashboard,
    name: "Project Dashboard",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/project",
    component: Project,
    name: "Project",
    meta: { requiresAuth: true },
  },
  {
    path: "/landscape-simulator",
    component: LandscapeSimulator,
    name: "Landscape Simulator",
    meta: { requiresAuth: true },
  },

  {
    path: "/sequence/edit",
    component: SequenceEditor,
    name: "Sequence: Editor",
    meta: {
      requiresAuth: true,
      hideNavbar: true,
      hideFooter: true,
    },
  },

  {
    path: "/job-queues",
    component: JobQueues,
    name: "Job Queues",
    meta: { requiresAuth: true },
  },

  {
    path: "/expression-manager",
    component: ExpressionManager,
    name: "Expression Manager",
    meta: { requiresAuth: true },
  },
  {
    path: "/dataset-manager",
    component: DatasetManager,
    name: "Dataset Manager",
    meta: { requiresAuth: true },
  },
  {
    path: "/season-manager",
    component: SeasonManager,
    name: "Season Manager",
    props: true,
    meta: { requiresAuth: true },
  },

  {
    path: "/analysis-tools",
    component: AnalysisTools,
    name: "Analysis Tools",
    meta: { requiresAuth: true },
  },

  {
    path: "/admin/user-management",
    component: UserManagement,
    name: "User Management",
    meta: { requiresAuth: true, requiresAdminAuth: true },
  },

  // Expressions
  {
    path: "/expression-selector",
    component: ExpressionSelector,
    name: "Expression Selector",
    meta: { requiresAuth: true },
  },
  {
    path: "/expression-viewer",
    component: ExpressionViewer,
    name: "Expression Viewer",
    props: true,
    meta: { requiresAuth: true },
  },

  // Datasets
  {
    path: "/dataset-selector",
    component: DatasetSelector,
    name: "Dataset Selector",
  },
  {
    path: "/single-dataset-selector",
    component: SingleDatasetSelector,
    name: "Single Dataset Selector",
  },
  {
    path: "/dataset-properties",
    name: "Dataset Properties",
    component: DatasetProperties,
  },

  {
    path: "/layer-selector",
    component: LayerSelector,
    name: "Layer Selector",
  },

  // Tables
  {
    path: "/data-table",
    name: "Data Table",
    component: DataTable,
  },

  {
    path: "/export-dataset-tool",
    component: ExportDatasetTool,
    name: "Export Dataset Tool",
  },

  // Mapping
  { path: "/map-drawer", component: MapDrawer, name: "Map Drawer" },

  {
    path: "/map-layer-symbology",
    component: MapLayerSymbology,
    name: "Map Layer Symbology",
  },

  {
    path: "/map-drawer-layer-loader",
    component: MapDrawerLayerLoader,
    name: "Map Drawer Layer Loader",
  },
];

export default base;

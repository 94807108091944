<template>
  <div class="d-flex flex-column px-2 information-container">
    <v-dialog
      v-model="dialogUploadForm"
      persistent
      max-width="550px"
      height="400px"
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="py-4 mx-auto">
          <div class="d-flex justify-center">
            <v-btn
              :disabled="!isUpdateable || !editPermission"
              color="blue-grey"
              v-bind="attrs"
              v-on="on"
              class="ma-2 white--text text-capitalize"
            >
              Add File to Dataset
              <v-icon right dark> mdi-cloud-upload </v-icon>
            </v-btn>
          </div>

          <p v-if="!datasetFiles" class="text-caption">
            There are no files yet associated with this dataset.
          </p>
          <p v-if="!isUpdateable" class="text-caption">
            The files within this dataset cannot be modified by you.
          </p>
        </div>
      </template>
      <upload-file-form
        v-if="dialogUploadForm"
        :type="dataset.DatasetType"
        :dataset="dataset"
        @dataset-uploads="datasetUploadLogs"
        @close-upload-form="dialogUploadForm = !dialogUploadForm"
      >
      </upload-file-form>
    </v-dialog>

    <v-divider v-if="datasetFiles"></v-divider>

    <!-- raster files -->
    <v-list v-if="dataset.DatasetType === 'Raster'" three-line dense>
      <v-list-item v-for="(file, index) in datasetFiles" :key="index">
        <v-list-item-content v-for="(f, i) in file.Files" :key="i">
          <v-list-item-title class="text-wrap"
            ><span class="font-weight-black">{{ `${index + 1}. ` }}</span
            >{{ `${f.FileName}` }}</v-list-item-title
          >

          <v-list-item-subtitle class="pl-4"
            ><span class="black--text">Temporal Coverage: </span
            >{{ `${timeRange(file)}` }}</v-list-item-subtitle
          >
          <v-list-item-subtitle class="text--primary pl-4">
            Spatial Extent:
            <v-tooltip bottom color="rgba(0, 0, 0, 0.95)">
              <template v-slot:activator="{ on, attrs }">
                <v-icon small v-bind="attrs" v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>
              <table>
                <tr>
                  <td class="table-header">Xmin:</td>
                  <td>
                    {{ f.Extent[0] }}
                  </td>
                </tr>
                <tr>
                  <td class="table-header">Xmax:</td>
                  <td>
                    {{ f.Extent[1] }}
                  </td>
                </tr>
                <tr>
                  <td class="table-header">Ymin:</td>

                  <td>
                    {{ f.Extent[2] }}
                  </td>
                </tr>
                <tr>
                  <td class="table-header">Ymax:</td>
                  <td>
                    {{ f.Extent[3] }}
                  </td>
                </tr>
              </table>
            </v-tooltip>
          </v-list-item-subtitle>

          <v-list-item-subtitle class="pl-4 text-wrap"
            ><span class="black--text">File: </span>
            <a :href="f.FileUrl">Download</a></v-list-item-subtitle
          >
        </v-list-item-content>

        <v-list-item-action v-if="editFiles">
          <v-list-item-action-text></v-list-item-action-text>

          <v-btn icon disabled>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>

    <!-- vector files -->
    <v-list v-else-if="dataset.DatasetType === 'Vector'" three-line dense>
      <v-list-item v-for="(file, index) in datasetFiles" :key="index">
        <v-list-item-content v-for="(f, i) in file.Files" :key="i">
          <v-list-item-title class="text-wrap"
            ><span class="font-weight-black">{{ `${index + 1}. ` }}</span
            >{{ `${f.LayerName}` }}</v-list-item-title
          >
          <v-list-item-subtitle class="pl-4 py-1"
            ><span class="black--text">Temporal Coverage: </span
            >{{ `${timeRange(file)}` }}</v-list-item-subtitle
          >
          <!-- <v-list-item-subtitle class="pl-4 py-1"
            ><span class="black--text">Filter Field: </span
            >{{ file.filter_field }}</v-list-item-subtitle
          >
          <v-list-item-subtitle class="pl-4 py-1"
            ><span class="black--text">Feature: </span
            >{{ file.filter_value }}</v-list-item-subtitle
          >
          <v-list-item-subtitle class="pl-4 py-1"
            ><span class="black--text">Value Field: </span
            >{{
              file.value_field ? file.value_field : ""
            }}</v-list-item-subtitle
          >

          <v-list-item-subtitle class="pl-4 py-1"
            ><span class="black--text">Layer Name: </span
            >{{ file.layer }}</v-list-item-subtitle
          >
          -->
          <v-list-item-subtitle class="text--primary pl-4 py-1">
            Spatial Extent:
            <v-tooltip bottom color="rgba(0, 0, 0, 0.95)">
              <template v-slot:activator="{ on, attrs }">
                <v-icon small v-bind="attrs" v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>
              <table>
                <tr>
                  <td class="table-header">Xmin:</td>
                  <td>
                    {{ f.Extent[0] }}
                  </td>
                </tr>
                <tr>
                  <td class="table-header">Xmax:</td>
                  <td>
                    {{ f.Extent[1] }}
                  </td>
                </tr>
                <tr>
                  <td class="table-header">Ymin:</td>

                  <td>
                    {{ f.Extent[2] }}
                  </td>
                </tr>
                <tr>
                  <td class="table-header">Ymax:</td>
                  <td>
                    {{ f.Extent[3] }}
                  </td>
                </tr>
              </table>
            </v-tooltip>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action v-if="editFiles">
          <v-list-item-action-text></v-list-item-action-text>

          <v-btn icon disabled>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>

    <!-- table files -->
    <v-list v-else-if="dataset.DatasetType === 'Table'" three-line dense>
      <v-list-item v-for="(file, index) in datasetFiles" :key="index">
        <v-list-item-content v-for="(f, i) in file.Files" :key="i">
          <v-list-item-title class="text-wrap"
            ><span class="font-weight-black">{{ `${index + 1}. ` }}</span
            >{{ `${f.LayerName}` }}</v-list-item-title
          >
          <v-list-item-subtitle class="pl-4 py-1"
            ><span class="black--text">Temporal Coverage: </span
            >{{ `${timeRange(file)}` }}</v-list-item-subtitle
          >
        </v-list-item-content>

        <v-list-item-action v-if="editFiles">
          <v-list-item-action-text></v-list-item-action-text>

          <v-btn icon disabled>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>
  </div>
</template>

<script>
import UploadFileForm from "@/components/datasets/UploadFileForm.vue";

export default {
  name: "DatasetPropertiesFiles",

  components: {
    UploadFileForm,
  },

  props: {
    dataset: { type: Object, default: () => {} },
    fullDataset: { type: Object, default: () => {} },
    editPermission: { type: Boolean, default: false },
  },

  data() {
    return {
      dialogUploadForm: false,
      editFiles: false,
      datasetUploadsLogs: null,
    };
  },

  computed: {
    isUpdateable() {
      const re = new RegExp("SCENARIO:|Subregion");
      const reservedTags = this.dataset.Tags.find((tag) => re.test(tag));
      if (typeof reservedTags === "undefined") {
        return true;
      } else return false;
    },

    datasetFiles() {
      return this.fullDataset.Files;
    },
  },

  methods: {
    timeRange(file) {
      const displayTime = `${file.StartDate}/${file.EndDate}`;
      return displayTime;
    },
    datasetUploadLogs(datasetUploads) {
      this.datasetUploadsLogs = datasetUploads;
    },
  },
};
</script>

<style scoped>
v-list-item-subtitle {
  font-weight: 400 !important;
  font-size: 0.8rem !important;
  line-height: 1.25rem;
}
.list-subtitle {
  font-weight: 400 !important;
  font-size: 0.8rem !important;
  line-height: 1.25rem;
}

.list-group-title {
  font-size: 1rem !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
}

.extent-list-item {
  background-color: blue;
  padding: 0;
  min-height: fit-content;
}
</style>

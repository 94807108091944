<template>
  <div>
    <v-select
      attach
      label="Spatial Reference"
      hint="For calculations involving on earth measurements, select an available spatial reference from the dropdown list or enter an authority string or a custom proj string. The default for web viewing purposes is EPSG:3857. For a complete list of available spatial reference systems and associated proj stings, see: https://spatialreference.org"
      persistent-hint
      :items="
        crsTypeSelected === 'CRS Dropdown'
          ? spatialReferences
          : customProjection
      "
      :item-value="'name'"
      :item-text="'name'"
      v-model="predefinedCRS"
      :menu-props="{ top: true, offsetY: false }"
      :color="$store.state.settings.appColor"
    >
      <template #item="predefinedCRS" v-if="crsTypeSelected === 'CRS Dropdown'">
        <v-tooltip
          class="crs-tooltip"
          color="rgba(0, 0, 0, 0.95)"
          max-width="500"
          top
        >
          <template #activator="{ on, attrs }">
            <v-layout wrap v-on="on" v-bind="attrs">
              <v-list-item-content>
                <v-list-item-title>
                  {{ predefinedCRS.item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-layout>
          </template>
          <table>
            <tr>
              <td class="table-header">Scope:</td>
              <td>
                {{ predefinedCRS.item.description.scope }}
              </td>
            </tr>
            <tr>
              <td class="table-header">Area of Use:</td>
              <td>
                {{ predefinedCRS.item.description.area }}
              </td>
            </tr>
            <tr>
              <td class="table-header">Units:</td>
              <td>
                {{ predefinedCRS.item.description.unit }}
              </td>
            </tr>
          </table>
        </v-tooltip>
      </template>
    </v-select>
    <v-row class="d-flex align-center space-between ma-0">
      <v-checkbox
        class="shrink ml-2 mb-0"
        label="CRS Dropdown"
        value="CRS Dropdown"
        v-model="crsTypeSelected"
        :disabled="crsTypeSelected === 'CRS Dropdown'"
        :color="$store.state.settings.appColor"
        @click="onEnableCRSDropdown"
      >
      </v-checkbox>
      <v-checkbox
        class="shrink ml-2 mb-0"
        label="Authority String"
        value="Authority String"
        v-model="crsTypeSelected"
        :disabled="crsTypeSelected === 'Authority String'"
        :color="$store.state.settings.appColor"
      >
      </v-checkbox>
      <v-checkbox
        class="shrink ml-2 mb-0"
        label="Proj String"
        value="Proj String"
        v-model="crsTypeSelected"
        :disabled="crsTypeSelected === 'Proj String'"
        :color="$store.state.settings.appColor"
      >
      </v-checkbox>
      <v-checkbox
        class="shrink ml-2 mb-0"
        label="WKT"
        value="WKT"
        v-model="crsTypeSelected"
        disabled
        :color="$store.state.settings.appColor"
      >
      </v-checkbox>
    </v-row>
    <v-text-field
      class="authority-string-input pt-5"
      placeholder="EPSG:3857"
      label="Authority String"
      hint="Enter a predefined authority string as AUTHORITY:code ex. EPSG:3857 or ESRI:102013"
      v-model="authorityString"
      :disabled="crsTypeSelected !== 'Authority String'"
      :rules="
        crsTypeSelected === 'Authority String' ? [rules.formatAuthString] : []
      "
      validate-on-blur
      clearable
      :color="$store.state.settings.appColor"
    ></v-text-field>
    <v-row class="align-center ma-0">
      <v-textarea
        class="proj-string-input pt-5"
        placeholder="+proj="
        label="Proj String"
        v-model="projString"
        hint="Enter a custom proj string"
        :disabled="crsTypeSelected != 'Proj String'"
        :rules="
          crsTypeSelected === 'Proj String' ? [rules.formatProjString] : []
        "
        validate-on-blur
        clearable
        :color="$store.state.settings.appColor"
      ></v-textarea>
    </v-row>
    <v-text-field
      class="wkt-string-input pt-5"
      label="WKT"
      v-model="wkt"
      hint="Not yet available"
      disabled
      clearable
      :color="$store.state.settings.appColor"
    ></v-text-field>
  </div>
</template>

<script>
import json from "/src/common/projections.json";

export default {
  name: "ProjectCrsSelector",

  components: {},

  props: {
    projectCRS: String,
  },

  data() {
    return {
      valid: true,
      spatialReferences: json,
      crsTypeSelected: "CRS Dropdown",
      crsString: "",
      predefinedCRS: null,
      authorityString: null,
      projString: null,
      wkt: null,
      rules: {
        formatAuthString: function (value) {
          return (
            /^\b[A-Z]{3,5}\b:[0-9]{3,6}$/.test(value) ||
            "Format as AUTHORITY:code ex. EPSG:3857"
          );
        },
        formatProjString: function (value) {
          return /^([+](proj=))/.test(value) || "String must begin with +proj=";
        },
      },
      // TODO: Change epsg to crs and all downstream parameters expecting "epsg"
      details: {
        name: null,
        description: null,
        epsg: null,
        csx: "auto",
        csy: "auto",
      },
      customProjection: [
        {
          name: "Custom",
          isCustom: true,
          authorityString: null,
          wkt: null,
          projString: null,
          description: {
            scope: null,
            area: null,
            accuracy: null,
            unit: null,
          },
        },
      ],
    };
  },

  beforeMount() {
    this.updateProjectCRS();
  },

  methods: {
    // hierarchy of preferred crs type if multiple types available from crs definition
    setProjectCrs() {
      if (this.authorityString !== null) {
        this.crsString = this.authorityString;
      } else if (this.projString !== null) {
        this.crsString = this.projString;
      } else if (this.wkt !== null) {
        this.crsString = this.wkt;
      }
    },

    onEnableCRSDropdown() {
      if (this.crsTypeSelected === "CRS Dropdown") {
        this.authorityString = null;
        this.projString = null;
        let savedProjection = this.spatialReferences.find((projection) => {
          return projection.authorityString === this.projectCRS;
        });
        if (savedProjection === undefined) {
          savedProjection = this.spatialReferences.find((projection) => {
            return projection.projString === this.projectCRS;
          });
        }
        this.predefinedCRS =
          savedProjection !== undefined ? savedProjection.name : null;
      }
    },

    updateProjectCRS() {
      let isOldFormat = this.projectCRS === 3857 || this.projectCRS === "3857";
      const projectCRS =
        isOldFormat || this.projectCRS === null ? "EPSG:3857" : this.projectCRS;
      if (isOldFormat) {
        this.authorityString = "EPSG:3857";
      }
      if (projectCRS.startsWith("+")) {
        try {
          const savedProjection = this.spatialReferences.find((projection) => {
            return projection.projString === projectCRS;
          });
          this.crsTypeSelected = "CRS Dropdown";
          this.predefinedCRS = savedProjection.name;
        } catch (error) {
          this.crsTypeSelected = "Proj String";
          this.projString = projectCRS;
        }
      } else {
        try {
          const savedProjection = this.spatialReferences.find((projection) => {
            return projection.authorityString === projectCRS;
          });
          this.crsTypeSelected = "CRS Dropdown";
          this.predefinedCRS = savedProjection.name;
        } catch (error) {
          this.crsTypeSelected = "Authority String";
          this.authorityString = projectCRS;
        }
      }
      this.setProjectCrs();
    },
  },

  watch: {
    projectCRS: function () {
      this.crsString = "";
      this.updateProjectCRS();
    },
    crsTypeSelected(val) {
      if (val !== "CRS Dropdown") {
        this.predefinedCRS = "Custom";
        this.authorityString = null;
        this.projString = null;
        this.wkt = null;
      }
    },

    crsString(newValue, oldValue) {
      if (oldValue !== "") {
        // initial value is empty string, and empty string isn't valid.
        // therefore, only emit of oldValue is not empty string
        this.$emit("update-crs", newValue);
      }
    },

    predefinedCRS(val) {
      if (val && this.crsTypeSelected === "CRS Dropdown") {
        const result = this.spatialReferences.find((projection) => {
          return projection.name === val;
        });
        this.authorityString = result.authorityString;
        this.projString = result.projString;
        this.wkt = result.wkt;
      }
      this.setProjectCrs();
    },

    projString(val) {
      if (this.rules.formatProjString(val) === true) this.setProjectCrs();
      if (this.crsTypeSelected === "Proj String") this.details.epsg = val;
    },

    authorityString(val) {
      if (this.rules.formatAuthString(val) === true) this.setProjectCrs();
      if (this.crsTypeSelected === "Authority String") this.details.epsg = val;
    },

    wkt(val) {
      this.setProjectCrs();
      if (this.crsTypeSelected === "WKT") this.details.epsg = val;
    },
  },
};
</script>

<style scoped>
.details-card {
  width: 50vw;
  max-height: 660px;
  overflow-y: auto;
}

.v-tooltip__content td:nth-child(1) {
  color: rgba(255, 0, 0, 1);
  font: 400;
  width: 20%;
  padding-top: 6px;
  padding-bottom: 6px;
}

.v-tooltip__content td:nth-child(2) {
  width: 80%;
  padding-top: 6px;
  padding-bottom: 6px;
}

/* Medium devices (landscape tablets, 768px and less) */
@media only screen and (max-width: 768px) {
  .details-card {
    width: 100vw;
    max-height: 660px;
    overflow-y: auto;
  }
}
</style>

import awsconfig from "@/aws-exports";

const titilerEndpoint = awsconfig.aws_misc.titiler_endpoint;

async function getBounds(fileUrl) {
  const method = "/cog/bounds";

  const response = await fetch(`${titilerEndpoint}${method}?url=${fileUrl}`, {
    method: "GET",
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const fileBounds = await response.json();

  return fileBounds;
}

//info
async function getCogInfo(fileUrl) {
  const method = "/cog/info";

  const response = await fetch(`${titilerEndpoint}${method}?url=${fileUrl}`, {
    method: "GET",
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const fileInfo = await response.json();

  return fileInfo;
}

//info
async function getCogInfoGeoJson(fileUrl) {
  const method = "/cog/info.geojson";

  const response = await fetch(`${titilerEndpoint}${method}?url=${fileUrl}`, {
    method: "GET",
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const fileInfoGeoJson = await response.json();

  return fileInfoGeoJson;
}

// stats
async function getCogStatistics(parameters) {
  const method = "/cog/statistics";

  const urlParams = Object.keys(parameters)
    .map((i) => `${i}=${parameters[i]}`)
    .join("&");

  const response = await fetch(`${titilerEndpoint}${method}?${urlParams}`, {
    method: "GET",
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.detail}`);
  }

  const fileInfo = await response.json();

  return fileInfo;
}

function buildTileJsonURL(parameters) {
  const method = "/cog/tilejson.json";

  const params = { ...parameters };

  if ("colormap_name" in params) {
    params["colormap_name"] = params["colormap_name"].toLowerCase();
  }

  const urlParams = Object.keys(params)
    .map((i) => `${i}=${params[i]}`)
    .join("&");

  const url = `${titilerEndpoint}${method}?${urlParams}`;

  return url;
}

export {
  getBounds,
  getCogInfo,
  getCogInfoGeoJson,
  getCogStatistics,
  buildTileJsonURL,
};

<template>
  <div class="d-flex">
    <v-combobox
      class="pr-5 align-center"
      :items="timeStepTypes"
      v-model="selectedTimeStepType"
      item-text="text"
      item-value="value"
      return-object
      hint="Temporal Range"
      persistent-hint
      :color="$store.state.settings.appColor"
      @change="updateDateRange"
    >
    </v-combobox>
    <div>
      <v-text-field
        v-model="inputStartDate"
        :hint="`Start Date - ${currentLabel}`"
        persistent-hint
        :color="$store.state.settings.appColor"
        dense
        clearable
        :disabled="!selectedTimeStepType"
        @change="updateDateRange"
      >
      </v-text-field>
      <v-text-field
        v-model="inputEndDate"
        persistent-hint
        :hint="`End Date - ${currentLabel}`"
        :color="$store.state.settings.appColor"
        dense
        clearable
        :disabled="!selectedTimeStepType || !inputStartDate"
        @change="updateDateRange"
      >
      </v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadFileFormDateSelector",

  props: {
    filename: { type: String, default: "" },
  },

  data() {
    return {
      inputStartDate: "",
      inputEndDate: "",
      timeStepTypes: [
        {
          value: "Daily",
          text: "Day",
          label: "YYYY-MM-DD",
          interval: 1,
          disabled: false,
        },
        {
          value: "Monthly",
          text: "Month",
          label: "YYYY-MM-01",
          interval: 1,
          disabled: false,
        },
        {
          value: "Annually",
          text: "Year",
          label: "YYYY-01-01",
          interval: 1,
          disabled: false,
        },
        {
          value: "SemiDecadally",
          text: "5-Year",
          label: "YYYY-01-01",
          interval: 5,
          disabled: false,
        },
        {
          value: "Decadally",
          text: "10-Year",
          label: "YYYY-01-01",
          interval: 10,
          disabled: false,
        },
      ],
      selectedTimeStepType: null,
    };
  },

  computed: {
    currentLabel() {
      let label;
      if (this.selectedTimeStepType) {
        label = this.selectedTimeStepType.label;
      } else {
        label = "";
      }
      return label;
    },
  },

  methods: {
    // currently only need single year
    updateDateRange() {
      this.generateEndDate();

      const start = this.inputStartDate;

      const end = this.inputEndDate ? this.inputEndDate : "ind";

      this.$emit("update-date-range", { start, end });
    },
    generateEndDate() {
      if (this.inputStartDate) {
        // start date
        const startDate = this.inputStartDate;

        let newEndDate;

        const timeStep = this.selectedTimeStepType.value;

        const interval = this.selectedTimeStepType.interval;

        if (timeStep === "Daily") {
          // increase
          let tempEndDate = new Date(startDate);
          tempEndDate.setDate(tempEndDate.getDate() + interval);
          newEndDate = tempEndDate;
          newEndDate = tempEndDate.toISOString().split("T", 1)[0];
        } else if (timeStep === "Monthly") {
          //months
          let tempEndDate = new Date(startDate);
          tempEndDate.setMonth(tempEndDate.getMonth() + interval);
          newEndDate = tempEndDate;
          newEndDate = tempEndDate.toISOString().split("T", 1)[0];
        } else {
          //years
          let tempEndDate = new Date(startDate);
          tempEndDate.setFullYear(tempEndDate.getFullYear() + interval);
          newEndDate = tempEndDate.toISOString().split("T", 1)[0];
        }

        this.inputEndDate = newEndDate;
      } else {
        this.inputEndDate = null;
      }
    },
  },
};
</script>

<style scoped></style>

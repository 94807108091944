<template>
  <v-sheet
    v-if="projectPage === 'Expressions'"
    style="flex-grow: 1; display: flex"
  >
    <v-navigation-drawer
      id="expression-list"
      permanent
      style="flex-grow: 0"
      width="400"
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6"> Expressions </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list nav dense>
        <v-list-item-group v-model="selectedSimulation">
          <v-list-item
            v-for="sim in this.activeProject.Simulations"
            :key="`select.${sim.SortValue}`"
            two-line
            :disabled="
              simulation
                ? sim.ExpressionName === simulation.ExpressionName
                : false
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-function-variant</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="text-wrap">
                {{ sim.ExpressionName }}
              </v-list-item-title>

              <v-list-item-subtitle>
                {{ sim.ExpressionOwner }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar v-if="isRunning(sim)">
              <v-icon color="blue">mdi-circle-medium</v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-list-item-group>

        <v-divider></v-divider>

        <v-subheader>Actions</v-subheader>

        <v-list-item
          @click="runAllDialog = true"
          :disabled="!allSimulationsCanRun"
        >
          <v-list-item-title>
            <v-icon left> mdi-play </v-icon>
            Run all
          </v-list-item-title>

          <v-dialog v-model="runAllDialog" max-width="40em">
            <v-card>
              <v-card-title>Scenario Name</v-card-title>

              <v-card-text>
                Enter the name of the scenario:
                <v-text-field
                  outlined
                  v-model="runAllScenarioName"
                ></v-text-field>
              </v-card-text>

              <v-card-actions class="justify-center">
                <v-btn color="warning" text @click="runAllDialog = false">
                  Cancel
                </v-btn>

                <v-btn
                  text
                  @click="submitRunAll"
                  :disabled="
                    !runAllScenarioName || !runAllScenarioNameDoesntExist
                  "
                  :loading="runAllButtonIsLoading"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-list-item>

        <v-list-item @click="newSimulationDialog = true">
          <v-list-item-title>
            <v-icon left> mdi-plus </v-icon>
            Add
          </v-list-item-title>

          <v-dialog v-model="newSimulationDialog">
            <v-card>
              <v-card-title class="text-h5">Expressions</v-card-title>
              <expression-selector
                v-if="newSimulationDialog && !isAdding"
                :single-select="true"
                :crud-actions="false"
                :selection-mode="true"
                :batch-select="false"
                @expression-selected="createSimulation"
              ></expression-selector>
            </v-card>
          </v-dialog>
        </v-list-item>

        <v-dialog v-model="isAdding" persistent hide-overlay width="300">
          <v-card color="primary" dark>
            <v-card-text>
              Adding expression, please wait
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-list-item @click="removeSimulationDialog = true">
          <v-list-item-title>
            <v-icon left> mdi-minus </v-icon>
            Remove
          </v-list-item-title>

          <v-dialog v-model="removeSimulationDialog" width="30em">
            <v-card>
              <v-card-title>
                Remove Expression and Associated Data
              </v-card-title>

              <v-card-text>
                Removing an expression also deletes the runs and output data
                associated with that expression. This cannot be undone.
                <v-select
                  :items="this.activeProject.Simulations"
                  item-text="ExpressionName"
                  v-model="simulationToRemove"
                ></v-select>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  :loading="isDeleting"
                  @click="onRemoveSimulation"
                  :disabled="!simulationToRemove"
                  block
                  color="warning"
                >
                  <v-icon left> mdi-delete </v-icon>
                  Remove expression and delete data
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-row class="fill-height" no-gutters style="flex-grow: 1">
      <v-col cols="12">
        <div v-if="simulation !== null">
          <v-tabs
            v-model="tab"
            :color="$store.state.settings.appColor"
            width="100%"
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab key="configure-simulation">
              <v-icon left>mdi-file-document-plus</v-icon>
              New scenario
            </v-tab>

            <v-tab key="simulation-runs">
              <v-icon left>mdi-file-document-multiple</v-icon>
              Scenarios
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item key="configure-simulation">
              <simulation-settings
                class="simulation-section"
                v-if="simulation"
                :simulation="simulation"
                :previous-simulation="previousSimulation"
                :next-simulation="nextSimulation"
                :input-devices="inputDevices"
                :active-project="activeProject"
                @swapped-with-next="selectedSimulation++"
                @swapped-with-previous="selectedSimulation--"
                @autosave-in-progress="onAutosaveInProgress"
              ></simulation-settings>
            </v-tab-item>

            <v-tab-item key="simulation-runs">
              <v-card>
                <simulation-runs
                  class="simulation-section"
                  v-if="simulation"
                  :simulation="simulation"
                ></simulation-runs>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import SimulationSettings from "@/components/projects/SimulationSettings.vue";
import SimulationRuns from "@/components/projects/SimulationRuns.vue";
import ExpressionSelector from "@/components/data/ExpressionSelector";

import { newSimulation, deleteSimulation, runAll } from "@/api/v2";
import { simulationCanRun } from "@/api/helpers";

export default {
  name: "ProjectSimulations",

  components: {
    SimulationSettings,
    SimulationRuns,
    ExpressionSelector,
  },
  props: {
    projectPage: String,
    project: { type: Object, default: () => {} },
  },

  data() {
    return {
      tab: 0,
      newSimulationDialog: false,
      runAllDialog: false,
      runAllScenarioName: "",
      runAllButtonIsLoading: false,
      removeSimulationDialog: false,
      simulationToRemove: null,
      selectedSimulation: 0,
      isDeleting: false,
      isAdding: false,
    };
  },
  computed: {
    activeProject() {
      return this.project.activeProject;
    },
    simulation() {
      let simulation;
      if (this.project.activeProject.Simulations.length > 0) {
        simulation = this.activeProject.Simulations[this.selectedSimulation];
      } else {
        simulation = null;
      }
      return simulation;
    },
    inputDevices() {
      return this.activeProject.InputDevices;
    },
    previousSimulation() {
      if (this.selectedSimulation <= 0) {
        return null;
      } else {
        return this.activeProject.Simulations[this.selectedSimulation - 1];
      }
    },
    nextSimulation() {
      const numSims = this.activeProject.Simulations;
      if (this.selectedSimulation >= numSims - 1) {
        return null;
      } else {
        return this.activeProject.Simulations[this.selectedSimulation + 1];
      }
    },
    allSimulationsCanRun() {
      let allExpressionsRunnable = this.activeProject.Simulations.every(
        (sim) => {
          return simulationCanRun(this.activeProject, sim) === true;
        }
      );
      return (
        allExpressionsRunnable && this.activeProject.Simulations.length >= 1
      );
    },
    existingScenarios() {
      let simulationScenarios = this.activeProject.Simulations.map((sim) => {
        if (sim.Runs) {
          return sim.Runs.map((run) => {
            return run.ScenarioName;
          });
        } else {
          return [];
        }
      });
      let allScenarios = new Set();
      simulationScenarios.forEach((scenarios) => {
        scenarios.forEach((name) => {
          allScenarios.add(name);
        });
      });
      return allScenarios;
    },
    runAllScenarioNameDoesntExist() {
      return !this.existingScenarios.has(this.runAllScenarioName);
    },
  },

  methods: {
    submitRunAll() {
      let projectOwner = this.activeProject.Properties.ProjectOwner;
      let projectName = this.activeProject.Properties.ProjectName;
      let runOwner = this.$store.state.account.user.attributes.email;
      let scenarioName = this.runAllScenarioName;
      this.runAllButtonIsLoading = true;
      runAll(projectName, projectOwner, runOwner, scenarioName)
        .then((response) => {
          if (response.Warnings) {
            this.$showAlert({ text: response.Warnings, type: "warning" });
          }
          this.$store.dispatch("project/loadProject", {
            projectOwner,
            projectName,
          });
        })
        .catch((e) => {
          if (e.response && e.response.data.Errors) {
            this.$showAlert({ text: e.response.data.Errors, type: "error" });
          } else {
            throw e;
          }
        })
        .finally(() => {
          this.runAllButtonIsLoading = false;
          this.runAllDialog = false;
        });
    },
    isRunning(sim) {
      if (sim.Runs) {
        const result = sim.Runs.some(
          (e) => !(e.Status === "Done" || e.Status === "Failed")
        );
        return result;
      } else {
        return false;
      }
    },
    createSimulation(selectedExpression) {
      let projectOwner = this.activeProject.Properties.ProjectOwner;
      let projectName = this.activeProject.Properties.ProjectName;
      this.newSimulationDialog = false;
      this.isAdding = true;
      newSimulation(
        projectName,
        projectOwner,
        selectedExpression.ExpressionName,
        selectedExpression.ExpressionOwner
      )
        .then((response) => {
          if (response.Warnings) {
            this.$showAlert({ text: response.Warnings, type: "warning" });
          }
          this.$store.dispatch("project/loadProject", {
            projectOwner,
            projectName,
          });
        })
        .catch((e) => {
          if (e.response.data.Errors) {
            this.$showAlert({ text: e.response.data.Errors, type: "error" });
          }
        })
        .finally(() => {
          this.isAdding = false;
        });
    },

    onRemoveSimulation() {
      let projectOwner = this.activeProject.Properties.ProjectOwner;
      let projectName = this.activeProject.Properties.ProjectName;
      this.isDeleting = true;
      deleteSimulation(projectName, projectOwner, this.simulationToRemove)
        .then((response) => {
          if (response.Errors) {
            this.$showAlert({ text: response.Errors, type: "error" });
          }
          if (response.Warnings) {
            this.$showAlert({ text: response.Warnings, type: "warning" });
          } else {
            if (this.selectedSimulation >= 1) {
              this.selectedSimulation--;
            } else {
              this.selectedSimulation = null;
            }
            this.$store.dispatch("project/loadProject", {
              projectOwner,
              projectName,
            });
          }
        })
        .finally(() => {
          this.removeSimulationDialog = false;
          this.simulationToRemove = null;
          this.isDeleting = false;
        });
    },

    onAutosaveInProgress(value) {
      this.runAllButtonIsLoading = value; // not sending request, show "loading" to block sending new run request during autosave
    },
  },
};
</script>

<style scoped>
.simulation-section {
  max-width: 800px;
}
.expression-drawer {
  max-width: 400px;
}
</style>

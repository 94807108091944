import { Auth, API } from "aws-amplify";
import { checkStatus, replaceSlash } from "./helpers";

// Expressions

// returns array of available expressions and basic metadata
async function getExpressionList() {
  const authData = await Auth.currentSession();
  const response = await API.get("flow-api", "/expression/list", {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });

  return response;
}

// returns full expression details
async function getExpression(expressionName, expressionOwner) {
  let endpoint;
  expressionName = replaceSlash(expressionName);
  expressionName = encodeURIComponent(expressionName);
  expressionOwner = encodeURIComponent(expressionOwner);
  endpoint = `/expression?ExpressionName=${expressionName}&ExpressionOwner=${expressionOwner}`;

  const authData = await Auth.currentSession();
  const response = await API.get("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });

  return response;
}

// deletes expression
async function deleteExpression(expressionName, expressionOwner) {
  let endpoint = `/expression`;
  const authData = await Auth.currentSession();
  const response = await API.del("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: { ExpressionName: expressionName, ExpressionOwner: expressionOwner },
  });

  return response;
}

// shares an expression by creating a tag with either EDIT:user or VIEW:user - EDIT grants both read and write privileges
async function shareExpression(expressionName, expressionOwner, tag) {
  let endpoint = `/tag`;
  const authData = await Auth.currentSession();
  const response = await API.put("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      Item: {
        ExpressionName: expressionName,
        ExpressionOwner: expressionOwner,
      },
      Tag: tag,
    },
  });

  return response;
}
// unshares an expression by creating a tag with either EDIT:user or VIEW:user - EDIT grants both read and write privileges
async function unShareExpression(expressionName, expressionOwner, tag) {
  let endpoint = `/tag`;
  const authData = await Auth.currentSession();
  const response = await API.del("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      Item: {
        ExpressionName: expressionName,
        ExpressionOwner: expressionOwner,
      },
      Tag: tag,
    },
  });

  return response;
}

// creates a new expression object
async function newExpression(expressionName, expressionOwner) {
  let endpoint = `/expression`;
  const authData = await Auth.currentSession();
  const response = await API.post("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: { ExpressionName: expressionName, ExpressionOwner: expressionOwner },
  });

  return response;
}

async function duplicateExpression(
  newExpressionName,
  newExpressionOwner,
  expressionName,
  expressionOwner
) {
  expressionName = replaceSlash(expressionName);
  expressionName = encodeURIComponent(expressionName);
  expressionOwner = encodeURIComponent(expressionOwner);
  let endpoint = `/expression?OtherExpressionOwner=${expressionOwner}&OtherExpressionName=${expressionName}`;
  const authData = await Auth.currentSession();
  const response = await API.post("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      ExpressionName: newExpressionName,
      ExpressionOwner: newExpressionOwner,
    },
  });

  return response;
}

// updates expression
async function updateExpression(expressionName, expressionOwner, expression) {
  let endpoint = `/expression`;
  const authData = await Auth.currentSession();
  const response = await API.put("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      ExpressionName: expressionName,
      ExpressionOwner: expressionOwner,
      Expression: expression,
    },
  });

  return response;
}

// add tags to an expression
async function addExpressionTag(expressionName, expressionOwner, tag) {
  let endpoint = `/tag`;
  const authData = await Auth.currentSession();
  const response = await API.put("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      Item: {
        ExpressionName: expressionName,
        ExpressionOwner: expressionOwner,
      },
      Tag: tag,
    },
  });

  return response;
}

// remove tags from an expression
async function removeExpressionTag(expressionName, expressionOwner, tag) {
  let endpoint = `/tag`;
  const authData = await Auth.currentSession();
  const response = await API.del("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      Item: {
        ExpressionName: expressionName,
        ExpressionOwner: expressionOwner,
      },
      Tag: tag,
    },
  });

  return response;
}

// Datasets
// returns array of available datasets and basic metadata
async function getDatasetList() {
  const authData = await Auth.currentSession();
  const response = await API.get("flow-api", "/dataset/list", {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });

  return response;
}

// returns full dataset details
async function getDataset(datasetName, datasetOwner) {
  datasetName = replaceSlash(datasetName);
  datasetName = encodeURIComponent(datasetName);
  datasetOwner = encodeURIComponent(datasetOwner);
  let endpoint = `/dataset?DatasetName=${datasetName}&DatasetOwner=${datasetOwner}`;
  const authData = await Auth.currentSession();
  const response = await API.get("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });

  return checkStatus(response);
}

async function getDataset2(datasetOwner, datasetName) {
  datasetName = encodeURIComponent(datasetName);
  datasetOwner = encodeURIComponent(datasetOwner);
  let endpoint = `/dataset/2/${datasetOwner}/${datasetName}`;
  const authData = await Auth.currentSession();
  const response = await API.get("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });

  return response;
}

// returns full layer details
async function getDatasetLayer(datasetName, datasetOwner, layerName) {
  datasetName = replaceSlash(datasetName);
  datasetName = encodeURIComponent(datasetName);
  datasetOwner = encodeURIComponent(datasetOwner);
  layerName = encodeURIComponent(layerName);
  let endpoint = `/dataset/layer?DatasetName=${datasetName}&DatasetOwner=${datasetOwner}&LayerName=${layerName}`;
  const authData = await Auth.currentSession();
  const response = await API.get("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });

  return response;
}

// returns full dataset details
async function getVirtualDatasetLayer(datasetName, datasetOwner, layerName) {
  datasetName = replaceSlash(datasetName);
  datasetName = encodeURIComponent(datasetName);
  datasetOwner = encodeURIComponent(datasetOwner);
  layerName = encodeURIComponent(layerName);
  let endpoint = `/dataset/virtual_layer?DatasetName=${datasetName}&DatasetOwner=${datasetOwner}&LayerName=${layerName}`;
  const authData = await Auth.currentSession();

  const response = await API.get("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });

  return checkStatus(response);
}

async function updateDatasetLayer(datasetLayer) {
  let endpoint = `/dataset/layer`;
  const authData = await Auth.currentSession();
  const response = await API.put("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: datasetLayer,
  });

  return response;
}

async function deleteDatasetLayer(datasetLayer) {
  let endpoint = `/dataset/layer`;
  const authData = await Auth.currentSession();
  const body = {
    DatasetName: datasetLayer.DatasetName,
    DatasetOwner: datasetLayer.DatasetOwner,
    LayerName: datasetLayer.LayerName,
  };
  const response = await API.del("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: body,
  });

  return response;
}

// adds a new symbology to a dataset
async function updateDatasetSymbology(symbology) {
  let endpoint = `/dataset/symbology`;
  const authData = await Auth.currentSession();
  const response = await API.put("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: symbology,
  });

  return response;
}

// removes a new symbology to a dataset
async function deleteDatasetSymbology(symbology) {
  let endpoint = `/dataset/symbology`;
  const authData = await Auth.currentSession();
  const response = await API.del("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: symbology,
  });

  return response;
}

// creates a new dataset object
async function createDataset(datasetName, datasetOwner, datasetType) {
  let endpoint = `/dataset`;
  const authData = await Auth.currentSession();
  const response = await API.post("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      DatasetName: datasetName,
      DatasetOwner: datasetOwner,
      DatasetType: datasetType,
    },
  });

  return response;
}

// deletes dataset
async function deleteDataset(datasetName, datasetOwner) {
  let endpoint = `/dataset`;
  const authData = await Auth.currentSession();
  const response = await API.del("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      DatasetName: datasetName,
      DatasetOwner: datasetOwner,
    },
  });

  return response;
}

// shares a dataset by creating a tag with either EDIT:user or VIEW:user - EDIT grants both read and write privileges
async function shareDataset(datasetName, datasetOwner, tag) {
  let endpoint = `/tag`;
  const authData = await Auth.currentSession();
  const response = await API.put("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      Item: { DatasetName: datasetName, DatasetOwner: datasetOwner },
      Tag: tag,
    },
  });
  return response;
}

// unshares a dataset by creating a tag with either EDIT:user or VIEW:user - EDIT grants both read and write privileges
async function unShareDataset(datasetName, datasetOwner, tag) {
  let endpoint = `/tag`;
  const authData = await Auth.currentSession();
  const response = await API.del("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      Item: { DatasetName: datasetName, DatasetOwner: datasetOwner },
      Tag: tag,
    },
  });
  return response;
}

// updates a dataset info
async function updateDatasetInfo(datasetName, datasetOwner, newInfo) {
  let endpoint = `/dataset`;
  const authData = await Auth.currentSession();
  const response = await API.put("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      DatasetName: datasetName,
      DatasetOwner: datasetOwner,
      ...newInfo,
    },
  });

  return response;
}

// add tags to an dataset
async function addDatasetTag(datasetName, datasetOwner, tag) {
  let endpoint = `/tag`;
  const authData = await Auth.currentSession();
  const response = await API.put("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      Item: { DatasetName: datasetName, DatasetOwner: datasetOwner },
      Tag: tag,
    },
  });

  return response;
}

// add tags to an dataset
async function removeDatasetTag(datasetName, datasetOwner, tag) {
  let endpoint = `/tag`;
  const authData = await Auth.currentSession();
  const response = await API.del("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      Item: { DatasetName: datasetName, DatasetOwner: datasetOwner },
      Tag: tag,
    },
  });

  return response;
}

// get a presigned url for uploading a file to the s3 data bucket
async function getPresignedPost(fileName) {
  fileName = encodeURIComponent(fileName);
  let endpoint = `/dataset/upload_presigned_post?filename=${fileName}`;
  const authData = await Auth.currentSession();
  const response = await API.get("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });

  return response;
}

// upload a file to da dataset
async function uploadDatasetFile(body) {
  let endpoint = `/dataset/upload`;
  const authData = await Auth.currentSession();
  const response = await API.post("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: body,
  });

  return response;
}

// returns a dataset's upload file list
async function getDatasetUploads(datasetName, datasetOwner) {
  datasetName = replaceSlash(datasetName);
  datasetName = encodeURIComponent(datasetName);
  datasetOwner = encodeURIComponent(datasetOwner);
  let endpoint = `/dataset/uploads?DatasetName=${datasetName}&DatasetOwner=${datasetOwner}`;
  const authData = await Auth.currentSession();
  const response = await API.get("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });

  return response;
}

// Tables

// get table
async function getTable(
  datasetName,
  datasetOwner,
  fileId,
  headersOnly = false
) {
  datasetName = encodeURIComponent(datasetName);
  datasetOwner = encodeURIComponent(datasetOwner);
  fileId = encodeURIComponent(fileId);

  let endpoint = "/table/";
  let api = "flow-api";

  endpoint += `?DatasetName=${datasetName}&DatasetOwner=${datasetOwner}&FileId=${fileId}&NoData=${headersOnly}`;

  const authData = await Auth.currentSession();
  const response = await API.get(`${api}`, endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });

  return response;
}

// update table row
async function updateTableRow(tableInformation, newRowData) {
  let endpoint = `/table/row`;
  let api = "flow-api";

  if (tableInformation.CustomPutRowHandlerEndpoint) {
    endpoint = tableInformation.CustomPutRowHandlerEndpoint;
    api = "flow-geotools";
  }

  let body = {
    DatasetName: tableInformation.DatasetName,
    DatasetOwner: tableInformation.DatasetOwner,
    FileId: tableInformation.FileId,
    Values: newRowData,
  };

  const authData = await Auth.currentSession();

  const response = await API.put(`${api}`, endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body,
  });

  return response;
}

// delete table row
async function deleteTableRow(tableInformation, index) {
  let endpoint = `/table/row`;
  let api = "flow-api";

  if (tableInformation.CustomDeleteRowHandlerEndpoint) {
    endpoint = tableInformation.CustomDeleteRowHandlerEndpoint;
    api = "flow-geotools";
  }

  let body = {
    DatasetName: tableInformation.DatasetName,
    DatasetOwner: tableInformation.DatasetOwner,
    FileId: tableInformation.FileId,
    Index: index.toString(),
  };

  const authData = await Auth.currentSession();
  const response = await API.del(`${api}`, endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body,
  });

  return response;
}

// Users
// returns list of users from same cognito user pool
async function getUserList() {
  const authData = await Auth.currentSession();
  const response = await API.get("flow-api", "/users", {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });

  return response;
}

async function createNewUser(userEmail, userPoolName) {
  userEmail = encodeURIComponent(userEmail);
  userPoolName = encodeURIComponent(userPoolName);

  let endpoint = `/users/?email=${userEmail}&user_pool_name=${userPoolName}`;
  const authData = await Auth.currentSession();
  const response = await API.post("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });

  return response;
}

async function deactivateUser(userEmail, userPoolName) {
  userEmail = encodeURIComponent(userEmail);
  userPoolName = encodeURIComponent(userPoolName);

  let endpoint = `/users/?email=${userEmail}&user_pool_name=${userPoolName}`;
  const authData = await Auth.currentSession();
  const response = await API.del("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });

  return response;
}

async function resetUserPassword(userEmail, userPoolName) {
  userEmail = encodeURIComponent(userEmail);
  userPoolName = encodeURIComponent(userPoolName);

  let endpoint = `/users/reset-password?email=${userEmail}&user_pool_name=${userPoolName}`;
  const authData = await Auth.currentSession();
  const response = await API.post("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });

  return response;
}

// group list (all of the groups they should be part of).
async function updateUserGroups(userEmail, userPoolName, updatedGroups) {
  userEmail = encodeURIComponent(userEmail);
  userPoolName = encodeURIComponent(userPoolName);

  let endpoint = `/users/groups?email=${userEmail}&user_pool_name=${userPoolName}`;
  const authData = await Auth.currentSession();
  const response = await API.put("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: updatedGroups,
  });

  return response;
}

// Projects
async function getProjectList() {
  const authData = await Auth.currentSession();
  const response = await API.get("flow-api", "/project/list", {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });

  return response;
}

async function newProject(projectName, projectOwner) {
  const authData = await Auth.currentSession();
  const response = await API.post("flow-api", `/project`, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: { ProjectName: projectName, ProjectOwner: projectOwner },
  });

  return response;
}

async function copyProject(
  projectName,
  projectOwner,
  otherProjectName,
  otherProjectOwner
) {
  const authData = await Auth.currentSession();
  const response = await API.put("flow-api", `/project/copy`, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      ProjectName: projectName,
      ProjectOwner: projectOwner,
      CreatedFromProjectName: otherProjectName,
      CreatedFromProjectOwner: otherProjectOwner,
    },
  });

  return response;
}

async function getProject(projectName, projectOwner) {
  projectOwner = encodeURIComponent(projectOwner);
  projectName = encodeURIComponent(projectName);

  let endpoint = `/project/?ProjectName=${projectName}&ProjectOwner=${projectOwner}`;
  const authData = await Auth.currentSession();
  const response = await API.get("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });

  return response;
}

async function updateProjectSettings(projectName, projectOwner, newSettings) {
  let endpoint = `/project`;
  const authData = await Auth.currentSession();
  const response = await API.put("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      ProjectOwner: projectOwner,
      ProjectName: projectName,
      ...newSettings,
    },
  });
  return response;
}

async function deleteProject(projectName, projectOwner) {
  let endpoint = `/project`;
  const authData = await Auth.currentSession();
  const response = await API.del("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: { ProjectName: projectName, ProjectOwner: projectOwner },
  });
  return response;
}

// shares a project by creating a tag with either EDIT:user or VIEW:user - EDIT grants both read and write privileges
async function shareProject(projectName, projectOwner, tag) {
  let endpoint = `/tag`;
  const authData = await Auth.currentSession();
  const response = await API.put("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      Item: { ProjectName: projectName, ProjectOwner: projectOwner },
      Tag: tag,
    },
  });
  return response;
}

// unshares a project by creating a tag with either EDIT:user or VIEW:user - EDIT grants both read and write privileges
async function unShareProject(projectName, projectOwner, tag) {
  let endpoint = `/tag`;
  const authData = await Auth.currentSession();
  const response = await API.del("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      Item: { ProjectName: projectName, ProjectOwner: projectOwner },
      Tag: tag,
    },
  });
  return response;
}

async function updateSimulationSettings(
  projectName,
  projectOwner,
  expressionName,
  newSettings
) {
  let endpoint = `/project/simulation`;
  const authData = await Auth.currentSession();
  const response = await API.put("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      ProjectOwner: projectOwner,
      ProjectName: projectName,
      ExpressionName: expressionName,
      ...newSettings,
    },
  });
  return response;
}

async function swapSimulationOrder(
  projectName,
  projectOwner,
  expressionName,
  otherExpressionName
) {
  let endpoint = `/project/simulation/order`;
  const authData = await Auth.currentSession();
  const response = await API.put("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      ProjectOwner: projectOwner,
      ProjectName: projectName,
      ExpressionName: expressionName,
      OtherExpressionName: otherExpressionName,
    },
  });
  return response;
}

async function newSimulation(
  projectName,
  projectOwner,
  expressionName,
  expressionOwner
) {
  let endpoint = `/project/simulation`;
  const authData = await Auth.currentSession();
  const response = await API.post("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      ProjectOwner: projectOwner,
      ProjectName: projectName,
      ExpressionName: expressionName,
      ExpressionOwner: expressionOwner,
    },
  });
  return response;
}

async function deleteSimulation(projectName, projectOwner, expressionName) {
  let endpoint = `/project/simulation`;
  const authData = await Auth.currentSession();
  const response = await API.del("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      ProjectOwner: projectOwner,
      ProjectName: projectName,
      ExpressionName: expressionName,
    },
  });
  return response;
}

async function newSimulationRun(
  projectName,
  projectOwner,
  expressionName,
  runOwner,
  scenarioName
) {
  let endpoint = `/project/simulation/run`;
  const authData = await Auth.currentSession();
  const response = await API.post("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      ProjectOwner: projectOwner,
      ProjectName: projectName,
      ExpressionName: expressionName,
      RunOwner: runOwner,
      ScenarioName: scenarioName,
    },
  });
  return response;
}

async function runAll(projectName, projectOwner, runOwner, scenarioName) {
  let endpoint = `/project/run`;
  const authData = await Auth.currentSession();
  const response = await API.post("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      ProjectOwner: projectOwner,
      ProjectName: projectName,
      RunOwner: runOwner,
      ScenarioName: scenarioName,
    },
  });
  return response;
}

async function deleteSimulationRun(
  projectName,
  projectOwner,
  expressionName,
  runOwner,
  scenarioName
) {
  let endpoint = `/project/simulation/run`;
  const authData = await Auth.currentSession();
  const response = await API.del("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      ProjectOwner: projectOwner,
      ProjectName: projectName,
      ExpressionName: expressionName,
      RunOwner: runOwner,
      ScenarioName: scenarioName,
    },
  });
  return response;
}

async function cancelSimulationRun(
  projectName,
  projectOwner,
  expressionName,
  runOwner,
  scenarioName,
  operation
) {
  let endpoint = `/project/simulation/run/control`;
  const authData = await Auth.currentSession();
  const response = await API.put("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: {
      ProjectOwner: projectOwner,
      ProjectName: projectName,
      ExpressionName: expressionName,
      RunOwner: runOwner,
      ScenarioName: scenarioName,
      Operation: operation,
    },
  });
  return response;
}

async function getRunLog(
  projectName,
  projectOwner,
  expressionName,
  runOwner,
  scenarioName
) {
  projectOwner = encodeURIComponent(projectOwner);
  projectName = encodeURIComponent(projectName);
  expressionName = replaceSlash(expressionName);
  expressionName = encodeURIComponent(expressionName);
  scenarioName = encodeURIComponent(scenarioName);
  runOwner = encodeURIComponent(runOwner);
  let endpoint = `/project/simulation/run/log?ProjectOwner=${projectOwner}&ProjectName=${projectName}&ExpressionName=${expressionName}&RunOwner=${runOwner}&ScenarioName=${scenarioName}`;
  const authData = await Auth.currentSession();
  const response = await API.get("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });
  return response;
}

async function getRunStatus(
  projectName,
  projectOwner,
  expressionName,
  runOwner,
  scenarioName
) {
  projectOwner = encodeURIComponent(projectOwner);
  projectName = encodeURIComponent(projectName);
  expressionName = replaceSlash(expressionName);
  expressionName = encodeURIComponent(expressionName);
  scenarioName = encodeURIComponent(scenarioName);
  runOwner = encodeURIComponent(runOwner);
  let endpoint = `/project/simulation/run/status?ProjectOwner=${projectOwner}&ProjectName=${projectName}&ExpressionName=${expressionName}&RunOwner=${runOwner}&ScenarioName=${scenarioName}`;
  const authData = await Auth.currentSession();
  const response = await API.get("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });
  return response;
}

async function getAppData(path) {
  let endpoint = `/appdata/?AppDataName=${path}`;
  const authData = await Auth.currentSession();
  const response = await API.get("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });
  return response;
}

async function updateInputDeviceValue(inputDeviceName, newValue) {
  inputDeviceName = encodeURIComponent(inputDeviceName);
  let endpoint = `/input_device/${inputDeviceName}`;
  const authData = await Auth.currentSession();
  const response = await API.put("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body: { Value: newValue },
  });
  return response;
}

//Miscellaneous
async function getDateList(startDate, endDate, timestep) {
  startDate = encodeURIComponent(startDate);
  endDate = encodeURIComponent(endDate);
  let endpoint = `/misc/date_list?StartDate=${startDate}&EndDate=${endDate}&Timestep=${timestep}`;
  const authData = await Auth.currentSession();
  const response = await API.get("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });

  // return checkStatus(response);
  return response;
}

async function getSequence(owner, path) {
  owner = encodeURIComponent(owner);
  path = encodeURIComponent(path);
  let endpoint = `/sequence/${owner}/${path}`;
  const authData = await Auth.currentSession();
  const response = await API.get("flow-api", endpoint, {
    headers: {
      Authorization: "Bearer " + authData.idToken.jwtToken,
    },
  });
  return response;
}

async function getSequenceRaw(owner, path) {
  owner = encodeURIComponent(owner);
  path = encodeURIComponent(path);
  let endpoint = `/sequence/raw/${owner}/${path}`;
  const authData = await Auth.currentSession();
  const response = await API.get("flow-api", endpoint, {
    headers: {
      Authorization: "Bearer " + authData.idToken.jwtToken,
    },
  });
  return response;
}

async function putSequenceRaw(owner, path, text) {
  owner = encodeURIComponent(owner);
  path = encodeURIComponent(path);
  let endpoint = `/sequence/raw/${owner}/${path}`;
  const authData = await Auth.currentSession();
  const response = await API.put("flow-api", endpoint, {
    headers: {
      Authorization: "Bearer " + authData.idToken.jwtToken,
    },
    body: {
      text: text,
    },
  });
  return response;
}

async function deleteSequence(owner, path) {
  owner = encodeURIComponent(owner);
  path = encodeURIComponent(path);
  let endpoint = `/sequence/${owner}/${path}`;
  const authData = await Auth.currentSession();
  const response = await API.del("flow-api", endpoint, {
    headers: {
      Authorization: "Bearer " + authData.idToken.jwtToken,
    },
  });
  return response;
}

async function renameSequence(owner, path, toOwner, toPath) {
  owner = encodeURIComponent(owner);
  path = encodeURIComponent(path);
  let endpoint = `/sequence/rename/${owner}/${path}`;
  const authData = await Auth.currentSession();
  const response = await API.put("flow-api", endpoint, {
    headers: {
      Authorization: "Bearer " + authData.idToken.jwtToken,
    },
    body: {
      newOwner: toOwner,
      newPath: toPath,
    },
  });
  return response;
}

function formatDateString(date_string) {
  let now = new Date();
  let date = new Date(Date.parse(date_string));
  let timeDiffMinutes = Math.round(Math.abs(date - now) / 60e3);
  if (timeDiffMinutes < 60) {
    return `${timeDiffMinutes} minutes ago`;
  } else if (timeDiffMinutes < 720) {
    // 12 hours
    let timeDiffHours = Math.round(timeDiffMinutes / 60.0);
    return `${timeDiffHours} hours ago`;
  } else if (timeDiffMinutes < 7200) {
    // 5 days
    // same week
    let day = date.toLocaleString("en-us", { weekday: "long" });
    if (date.getDay() == now.getDay()) {
      day = "Today";
    } else if (date.getDay() == now.getDay() - 1) {
      day = "Yesterday";
    }
    let time = date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return `${day}, ${time}`;
  } else {
    return date.toLocaleString("en-us", {
      weekday: "short",
      month: "short",
      year: "numeric",
      day: "numeric",
    });
  }
}

async function getJobDefinitions(repoName) {
  repoName = encodeURIComponent(repoName);
  let endpoint = `/job/definitions/${repoName}`;
  const authData = await Auth.currentSession();
  const response = await API.get("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });
  return response;
}

async function getJobImageTags(repoName) {
  repoName = encodeURIComponent(repoName);
  let endpoint = `/job/image/tags/${repoName}`;
  const authData = await Auth.currentSession();
  const response = await API.get("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });
  return response;
}

async function getJobList(jobQueue) {
  jobQueue = encodeURIComponent(jobQueue);
  let endpoint = `/job/list/${jobQueue}`;
  const authData = await Auth.currentSession();
  const response = await API.get("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });
  return response;
}

async function getLogText(logStreamName) {
  logStreamName = encodeURIComponent(logStreamName);
  let endpoint = `/job/log/${logStreamName}`;
  const authData = await Auth.currentSession();
  const response = await API.get("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
  });
  return response;
}

async function submitJob(body) {
  let endpoint = `/job/submit`;
  const authData = await Auth.currentSession();
  const response = await API.post("flow-api", endpoint, {
    headers: { Authorization: "Bearer " + authData.idToken.jwtToken },
    body,
  });
  return response;
}

export {
  // expressions
  getExpressionList,
  getAppData,
  newProject,
  getExpression,
  deleteExpression,
  shareExpression,
  unShareExpression,
  duplicateExpression,
  newExpression,
  updateExpression,
  addExpressionTag,
  removeExpressionTag,

  // sequence
  getSequence,
  getSequenceRaw,
  putSequenceRaw,
  deleteSequence,
  renameSequence,

  // datasets
  getDatasetList,
  getDataset,
  getDataset2,
  getDatasetLayer,
  getVirtualDatasetLayer,
  updateDatasetLayer,
  deleteDatasetLayer,
  updateDatasetSymbology,
  deleteDatasetSymbology,
  createDataset,
  deleteDataset,
  shareDataset,
  unShareDataset,
  updateDatasetInfo,
  addDatasetTag,
  removeDatasetTag,

  // compute
  getJobDefinitions,
  getJobImageTags,
  getJobList,
  getLogText,
  submitJob,

  // tables
  getTable,
  updateTableRow,
  deleteTableRow,

  // users
  getUserList,
  createNewUser,
  deactivateUser,
  resetUserPassword,
  updateUserGroups,

  // projects
  getProjectList,
  getProject,
  updateProjectSettings,
  deleteProject,
  copyProject,
  shareProject,
  unShareProject,

  // simulations
  newSimulationRun,
  deleteSimulationRun,
  cancelSimulationRun,
  getRunLog,
  getRunStatus,
  newSimulation,
  deleteSimulation,
  updateInputDeviceValue,
  updateSimulationSettings,
  swapSimulationOrder,
  runAll,

  // miscellaneous
  getPresignedPost,
  uploadDatasetFile,
  getDatasetUploads,
  formatDateString,
  getDateList,
};

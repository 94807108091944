<template>
  <v-card>
    <v-card-title class="text-h6"
      ><span class="text-h5">Modify Permissions</span></v-card-title
    >
    <v-spacer></v-spacer>

    <v-card-text v-if="shareType === 'Datasets'" class="pb-2">
      Give others <b>view</b> or <b>edit</b> access to your dataset. You can
      grant access to an individual or a group of users. Giving someone
      <b>view</b> access allows them to see and use your dataset. Giving someone
      <b>edit</b> access also allows them to edit your dataset settings.
      <p class="pt-1">A dataset can only be deleted by its owner.</p>
      <p v-if="batchEdit">
        While in batch share mode, existing user permissions for the selected
        datasets will not be visible. To batch revoke access, simply check and
        uncheck the desired permissions.
      </p>
    </v-card-text>

    <v-card-text v-if="shareType === 'Expressions'" class="pb-2">
      Give others <b>view</b> or <b>edit</b> access to your expression. You can
      grant access to an individual or a group of users. Giving someone
      <b>view</b> access allows them to see and use your expression. Giving
      someone <b>edit</b> access also allows them to edit your expression.
      Sharing an expression will also share nested expressions and datasets with
      <b>view</b> permission only.
      <p class="pt-1">An expression can only be deleted by its owner.</p>
      <p v-if="batchEdit">
        While in batch share mode, existing user permissions for the selected
        expressions will not be visible. To batch revoke access, simply check
        and uncheck the desired permissions.
      </p>
    </v-card-text>

    <v-card-subtitle v-if="shareType === 'Project'" class="subtitle-2"
      >Grant individuals or groups within your organization edit and/or view
      privileges to your project</v-card-subtitle
    >

    <data-share-user-list
      :selected-data="selectedData"
      :user-permissions="currentSharedPermissions"
      :requestingPermissions="requestingPermissions"
      @add-permission="addPermission"
      @remove-permission="removePermission"
    >
    </data-share-user-list>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="warning"
        text
        @click="closeShare"
        :disabled="requestingPermissions.length > 0"
        >Close</v-btn
      >
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import DataShareUserList from "@/components/data/DataShareUserList.vue";
import { shareDataset, unShareDataset } from "@/api/v2.js";
import { shareExpression, unShareExpression } from "@/api/v2.js";
import { shareProject, unShareProject } from "@/api/v2.js";

export default {
  name: "DataShare",

  components: { DataShareUserList },

  props: {
    selectedData: { type: Array, default: () => [] },
    batchEdit: { type: Boolean, default: false },
    shareType: { type: String, default: "Project" },
  },

  data() {
    return {
      isLoading: false,
      userList: [],
      updatedPermissions: [],
      requestingPermissions: [],
    };
  },

  computed: {
    // set current shared permissions for single data objects only. For batch sharing, do not.
    currentSharedPermissions() {
      let currentPermissions = [];
      let tagsPath = null;

      if (this.shareType === "Project") {
        tagsPath = this.selectedData[0].Properties.Tags;
      } else if (this.shareType === "Datasets") {
        tagsPath = this.selectedData[0].Tags;
      } else if (this.shareType === "Expressions") {
        tagsPath = this.selectedData[0].Tags;
      }

      if (this.batchEdit) {
        return;
      } else {
        // parses tags for edit/view permissions
        tagsPath.forEach((tag) => {
          let modifiedTag = tag.split(":");
          if (modifiedTag.includes("EDIT")) {
            currentPermissions.push({
              user: modifiedTag[1],
              edit: true,
              view: true,
            });
          }
          if (modifiedTag.includes("VIEW")) {
            currentPermissions.push({
              user: modifiedTag[1],
              edit: false,
              view: true,
            });
          }
        });

        // merge user permissions
        const mergedPermission = currentPermissions.reduce(
          (currentPerm, { user, edit, view }) => {
            let permission = currentPerm.find((perm) => perm.user === user);

            if (!permission) {
              return [...currentPerm, { user, edit: edit, view: view }];
            }
            permission.edit = permission.edit ? permission.edit : edit;
            return currentPerm;
          },
          []
        );
        return mergedPermission;
      }
    },
  },

  methods: {
    closeShare() {
      this.$emit("close-share-dialog");
    },

    addPermission(userPermission, checkboxModified, userList) {
      let data = this.selectedData;

      let isBatch = Array.isArray(data);

      if (!isBatch) {
        data = new Array(data);
      }

      this.requestingPermissions.push(userPermission.user);

      // build promise list
      let promises = [];

      for (let i = 0; i < data.length; i++) {
        let tag;
        if (checkboxModified === "edit") {
          tag = `EDIT:${userPermission.user}`;
        } else {
          tag = `VIEW:${userPermission.user}`;
        }

        if (this.shareType === "Datasets") {
          promises.push(
            shareDataset(data[i].DatasetName, data[i].DatasetOwner, tag)
          );
        } else if (this.shareType === "Expressions") {
          promises.push(
            shareExpression(
              data[i].ExpressionName,
              data[i].ExpressionOwner,
              tag
            )
          );
        } else if (this.shareType === "Project") {
          promises.push(
            shareProject(
              data[i].Properties.ProjectName,
              data[i].Properties.ProjectOwner,
              tag
            )
          );
        }
      }

      this.modifyPermissions(promises, userList, userPermission);
    },

    removePermission(userPermission, checkboxModified, userList) {
      let data = this.selectedData;

      let isBatch = Array.isArray(data);

      if (!isBatch) {
        data = new Array(data);
      }

      this.requestingPermissions.push(userPermission.user);

      // build promise list
      let promises = [];

      for (let i = 0; i < data.length; i++) {
        let tag;
        if (checkboxModified === "edit") {
          tag = `EDIT:${userPermission.user}`;
        } else {
          tag = `VIEW:${userPermission.user}`;
        }
        if (this.shareType === "Datasets") {
          promises.push(
            unShareDataset(data[i].DatasetName, data[i].DatasetOwner, tag)
          );
        } else if (this.shareType === "Expressions") {
          promises.push(
            unShareExpression(
              data[i].ExpressionName,
              data[i].ExpressionOwner,
              tag
            )
          );
        } else if (this.shareType === "Project") {
          promises.push(
            unShareProject(
              data[i].Properties.ProjectName,
              data[i].Properties.ProjectOwner,
              tag
            )
          );
        }
      }
      this.modifyPermissions(promises, userList, userPermission);
    },

    modifyPermissions(promises, userList, userPermission) {
      Promise.all(promises)
        .then(() => {
          if (this.shareType === "Project") {
            this.reformatProjectTags(userList);
          }
        })
        .catch((error) => {
          if (error) {
            this.$showAlert({
              text: error,
              type: "error",
            });
          }
          if (error.response.data.Warnings) {
            this.$showAlert({
              text: error.response.data.Warnings,
              type: "warning",
            });
          }
          if (error.response.data.Errors) {
            this.$showAlert({
              text: error.response.data.Errors,
              type: "error",
            });
          }
        })
        .finally(() => {
          this.requestingPermission = false;

          const itemIndex = this.requestingPermissions.indexOf(
            userPermission.user
          );
          if (itemIndex > -1) {
            this.requestingPermissions.splice(itemIndex, 1);
          }
        });
    },

    // send values to store for projects
    reformatProjectTags(userList) {
      let newTags = [];
      userList.forEach((userPermission) => {
        if (userPermission.edit) {
          newTags.push(`EDIT:${userPermission.user}`);
        }

        if (userPermission.view) {
          newTags.push(`VIEW:${userPermission.user}`);
        }
      });
      this.$store.commit("project/updateProjectPermissions", newTags);
    },
  },
};
</script>

<style></style>

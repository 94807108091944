import { render, staticRenderFns } from "./UploadFileFormVectorFile.vue?vue&type=template&id=3820029c&scoped=true&"
import script from "./UploadFileFormVectorFile.vue?vue&type=script&lang=js&"
export * from "./UploadFileFormVectorFile.vue?vue&type=script&lang=js&"
import style0 from "./UploadFileFormVectorFile.vue?vue&type=style&index=0&id=3820029c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3820029c",
  null
  
)

export default component.exports
<template>
  <div>
    <div class="py-4">
      <div class="d-flex justify-space-evenly align-center">
        <v-btn
          fab
          small
          :disabled="datasetFileUploadLogs.length === 0"
          color="blue-grey"
          class="ma-2 white--text text-capitalize"
          @click="getDatasetLogs"
        >
          <v-icon dark> mdi-refresh </v-icon>
        </v-btn>
        <p class="text-caption mb-0">
          Click the refresh button to check for updates to the status of file
          uploads.
        </p>
      </div>
      <p
        v-if="datasetFileUploadLogs.length === 0"
        class="pt-4 d-flex justify-center text-caption"
      >
        There are no file upload logs associated with this dataset yet.
      </p>
    </div>

    <v-divider></v-divider>

    <!-- raster logs -->
    <v-list v-if="dataset.DatasetType === 'Raster'" three-line dense>
      <template v-for="(upload, index) in datasetFileUploadLogs">
        <v-list-item :key="upload.UploadInstanceId">
          <v-list-item-content>
            <v-list-item-title
              :class="
                upload.Status === 'Done'
                  ? 'success--text'
                  : upload.Status === 'Failed'
                  ? 'error--text'
                  : upload.Status === 'Provisioning'
                  ? 'blue--text'
                  : 'orange--text'
              "
              >{{ upload.Status }}</v-list-item-title
            >
            <v-list-item-subtitle class="text--primary pl-4">
              <span class="font-weight-black"> Filename: </span>
              {{ upload.UploadedFiles[0].Filename }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text--primary pl-4">
              <span class="font-weight-black"> Upload Instance ID: </span>
              {{ upload.UploadInstanceId }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text--primary pl-4">
              <span class="font-weight-black"> Date Range Start: </span>
              {{ upload.UploadedFiles[0].DateRangeStart }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text--primary pl-4">
              <span class="font-weight-black"> Date Range End: </span>
              {{ upload.UploadedFiles[0].DateRangeEnd }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text--primary pl-4">
              <span class="font-weight-black"> Error Message: </span>
              {{ upload.ErrorMessage ? upload.ErrorMessage : "None" }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          v-if="index < datasetFileUploadLogs.length - 1"
          :key="index"
        ></v-divider>
      </template>
    </v-list>

    <!-- vector logs -->
    <v-list v-if="dataset.DatasetType === 'Vector'" three-line dense>
      <template v-for="(upload, index) in datasetFileUploadLogs">
        <v-list-item :key="upload.UploadInstanceId">
          <v-list-item-content>
            <v-list-item-title
              :class="
                upload.Status === 'Done'
                  ? 'success--text'
                  : upload.Status === 'Failed'
                  ? 'error--text'
                  : upload.Status === 'Provisioning'
                  ? 'blue--text'
                  : 'orange--text'
              "
              >{{ upload.Status }}</v-list-item-title
            >
            <v-list-item-subtitle class="text--primary pl-4">
              <span class="font-weight-black"> Filename: </span>
              {{ `${upload.UploadedFiles[0].Filename}` }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text--primary pl-4">
              <span class="font-weight-black"> Upload Instance Id: </span>
              {{ `${upload.UploadInstanceId}` }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text--primary pl-4">
              <span class="font-weight-black"> Date Range Start: </span>
              {{ upload.UploadedFiles[0].DateRangeStart }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text--primary pl-4">
              <span class="font-weight-black"> Date Range End: </span>
              {{ upload.UploadedFiles[0].DateRangeEnd }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text--primary pl-4">
              <span class="font-weight-black"> Layer Name: </span>
              {{ upload.LayerName ? upload.LayerName : "" }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text--primary pl-4">
              <span class="font-weight-black"> Error Message: </span>
              {{ upload.ErrorMessage ? upload.ErrorMessage : "None" }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          v-if="index < datasetFileUploadLogs.length - 1"
          :key="index"
        ></v-divider>
      </template>
    </v-list>

    <!-- vector logs -->
    <v-list v-if="dataset.DatasetType === 'Table'" three-line dense>
      <template v-for="(upload, index) in datasetFileUploadLogs">
        <v-list-item :key="upload.UploadInstanceId">
          <v-list-item-content>
            <v-list-item-title
              :class="
                upload.Status === 'Done'
                  ? 'success--text'
                  : upload.Status === 'Failed'
                  ? 'error--text'
                  : upload.Status === 'Provisioning'
                  ? 'blue--text'
                  : 'orange--text'
              "
              >{{ upload.Status }}</v-list-item-title
            >
            <v-list-item-subtitle class="text--primary pl-4">
              <span class="font-weight-black"> Filename: </span>
              {{ `${upload.UploadedFiles[0].Filename}` }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text--primary pl-4">
              <span class="font-weight-black"> Upload Instance Id: </span>
              {{ `${upload.UploadInstanceId}` }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text--primary pl-4">
              <span class="font-weight-black"> Upload Batch Job: </span>
              {{ upload.BatchJobId ? upload.BatchJobId : "Not Found" }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text--primary pl-4">
              <span class="font-weight-black"> Date Range Start: </span>
              {{ upload.UploadedFiles[0].DateRangeStart }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text--primary pl-4">
              <span class="font-weight-black"> Date Range End: </span>
              {{ upload.UploadedFiles[0].DateRangeEnd }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text--primary pl-4">
              <span class="font-weight-black"> Index Column: </span>
              {{ upload.IndexColumn ? upload.IndexColumn : "" }}
            </v-list-item-subtitle>

            <v-list-item-subtitle class="text--primary pl-4">
              <span class="font-weight-black text-wrap"> Error Message: </span>
              {{ upload.ErrorMessage ? upload.ErrorMessage : "None" }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          v-if="index < datasetFileUploadLogs.length - 1"
          :key="index"
        ></v-divider>
      </template>
    </v-list>
  </div>
</template>

<script>
import { getDatasetUploads } from "@/api/v2.js";
export default {
  name: "DatasetPropertiesUploadLogs",

  props: {
    dataset: { type: Object, default: () => {} },
    editPermission: { type: Boolean, default: false },
  },

  data() {
    return {
      datasetFileUploadLogs: [],
    };
  },

  computed: {},

  methods: {
    async getDatasetLogs() {
      try {
        const datasetUploads = await getDatasetUploads(
          this.dataset.DatasetName,
          this.dataset.DatasetOwner
        );
        this.datasetFileUploadLogs = datasetUploads.Uploads;
        if (datasetUploads.Warnings) {
          this.$showAlert({
            text: datasetUploads.Warnings,
            type: "warning",
          });
        }
      } catch (error) {
        if (error) {
          this.$showAlert({
            text: error,
            type: "error",
          });
        }
        if (error.response.data.Errors) {
          this.$showAlert({
            text: error.response.data.Errors,
            type: "error",
          });
        }
      }
    },
  },
  async created() {
    await this.getDatasetLogs();
  },
};
</script>

<style scoped></style>

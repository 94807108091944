<template>
  <v-container fluid style="height: 100%; background-color: #ffffff">
    <v-row no-gutters class="d-flex flex-grow-1" style="height: 100%">
      <v-col cols="12" :md="tableColumns">
        <!-- header -->
        <div class="d-flex justify-space-between ma-4">
          <div class="text-h6 font-weight-regular l-0">Grazing Seasons</div>
          <v-dialog v-model="newSeasonDialog" max-width="900">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="text-capitalize"
                color="#B7CDAD"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left>mdi-calendar-range</v-icon>New Season</v-btn
              >
            </template>
            <new-season
              v-if="newSeasonDialog"
              :ranch-name="ranchName"
              @close-new-season-dialog="newSeasonDialog = false"
            ></new-season>
          </v-dialog>
        </div>

        <div
          class="d-flex flex-row align-center ml-4"
          v-if="ranchSeasonList.seasonTable"
        >
          <v-btn icon small @click="validateSeasons">
            <v-icon small> mdi-refresh </v-icon>
          </v-btn>
          <p
            class="text-caption align-center mb-0"
            @click="validateSeasons"
            title="Click to update status"
            style="cursor: pointer"
          >
            <span class="text-caption font-weight-bold"
              >Season upload status: </span
            >{{ seasonStatus ? seasonStatus : "Click for upload updates" }}
          </p>
        </div>

        <data-table
          v-if="ranchSeasonList.seasonTable"
          :table-information="ranchSeasonList.seasonTable"
          no-data-text="No seasons found.."
          :updated-selected-row="selectedRow"
          :actions="[downloadGrazingSheetButton]"
          @take-action="handleAction"
          @property-headers="setPropertyHeaders"
          @selected-row="selectRow"
        >
        </data-table>
        <v-subheader v-else class="d-flex justify-center">
          To complete creating a new ranch, add the first grazing season.
        </v-subheader>
      </v-col>
      <v-col cols="12" md="4">
        <!-- aside -->
        <table-row-editor
          v-if="selectedRow"
          row-title="Season"
          table-header-color="#ECECEC"
          table-selection-color="#F8F4E3"
          :table-information="ranchSeasonList.seasonTable"
          :self-update-mode="false"
          :row-definitions="propertyHeaders"
          :row-data="selectedRow"
          :row-file-definitions="fileHeaders"
          :row-file-data="null"
          :table-saving="tableSaving"
          :row-deleting="rowDeleting"
          @save-row-data-changes="saveChangedRowData"
          @delete-row="deleteTableRow"
        ></table-row-editor>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataTable from "@/components/tables/DataTable.vue";
import NewSeason from "@/components/data/NewSeason.vue";
import TableRowEditor from "@/components/tables/TableRowEditor.vue";
import { deleteSeason, getGrazingSheet } from "@/api/geoprocessing";
// import { base64ToPdf } from "@/api/helpers";

export default {
  name: "SeasonManager",

  components: { NewSeason, TableRowEditor, DataTable },

  props: {
    newRanchName: { type: String },
  },

  data() {
    return {
      propertyHeaders: [],
      fileHeaders: [
        {
          text: "Paddock Boundaries",
          value: "PaddockBoundaries",
          editable: false,
          icon: "mdi-table-arrow-up",
        },
        {
          text: "Grazing Data",
          value: "GrazingData",
          editable: false,
          icon: "mdi-file-upload",
        },
      ],
      selectedRow: null,
      updatedSelectedRow: {},
      newSeasonDialog: false,
      tableSaving: false,
      rowDeleting: false,
      seasonStatus: null,
      downloadGrazingSheetButton: {
        text: "Grazing Sheet",
        value: "download",
        button: true,
        color: "primary",
        icon: "mdi-download",
        handler: "downloadGrazingSheet",
        loading: false,
        description: "Export grazing sheet for this season.",
      },
    };
  },

  computed: {
    ranchSeasonList() {
      return this.$store.state.accountData.seasonList;
    },

    seasonList() {
      return this.ranchSeasonList.seasons;
    },

    ranchName() {
      return this.$store.state.mapping.singleDataset?.DatasetName
        ? this.$store.state.mapping.singleDataset.DatasetName
        : this.newRanchName;
    },

    currentUser() {
      return this.$store.state.account.user.attributes.email;
    },

    tableColumns() {
      return this.selectedRow ? 8 : 12;
    },
  },

  methods: {
    selectRow(newRow) {
      this.selectedRow = newRow;
    },

    setPropertyHeaders(propertyHeaders) {
      this.propertyHeaders = propertyHeaders;
    },

    seasonUploadCount(seasonRows, paddockFiles) {
      return seasonRows.length - paddockFiles.length;
    },

    async saveChangedRowData(newRowData) {
      this.tableSaving = true;

      // request api to update row
      const seasonUpdated = await this.$store.dispatch(
        "accountData/updateTable",
        {
          tableInformation: this.ranchSeasonList.seasonTable,
          newRowData,
        }
      );

      if (seasonUpdated) {
        this.selectRow(newRowData);
        this.updatedSelectedRow = newRowData;
      }

      // return new data
      this.tableSaving = false;
    },

    async deleteTableRow(rowData) {
      const tableInformation = this.ranchSeasonList.seasonTable;
      const sID = rowData["SID"];

      const preDeleteSeasonListLength = this.seasonList.length;

      try {
        this.rowDeleting = true;

        await deleteSeason(tableInformation, sID);

        this.selectRow(null);

        // return home
        if (preDeleteSeasonListLength === 1) {
          this.$showAlert({
            text: "Season successfully deleted. <br><br> Ranches with no seasons are automatically deleted. <br><br> You will now be returned to the home page.",
            type: "success",
            timeout: 5000,
          });

          this.$store.commit("accountData/resetAccountData");

          setTimeout(() => {
            window.location.reload();
          }, 5000);
        } else {
          this.$showAlert({
            text: "Season successfully deleted.",
            type: "success",
            timeout: 3000,
          });

          // get updated paddock boundaries dataset
          this.$store.dispatch("mapping/getSingleDataset", {
            datasetName: this.$store.state.mapping.singleDataset.DatasetName,
            datasetOwner: this.$store.state.mapping.singleDataset.DatasetOwner,
          });

          // get updated season list
          await this.$store.dispatch("accountData/refreshSeasonList", {
            datasetName: this.$store.state.mapping.singleDataset.DatasetName,
            datasetOwner: this.$store.state.mapping.singleDataset.DatasetOwner,
          });

          this.selectRow(null);
        }
      } catch (error) {
        if (error.response.data) {
          this.$showAlert({
            text: error.response.data.Errors,
            type: "error",
          });
        } else {
          this.$showAlert({
            text: error,
            type: "error",
          });
        }
      } finally {
        this.rowDeleting = false;
      }
    },

    async downloadGrazingSheet(itemAction) {
      console.log(itemAction);

      try {
        const response = await getGrazingSheet(
          this.ranchName,
          itemAction.item["Season start date*"],
          "pdf"
        );

        window.open(response.url);
      } catch (error) {
        this.$showAlert({
          text: error,
          type: "error",
        });
      } finally {
        itemAction.action.loading = false;
      }
    },

    handleAction(itemAction) {
      if (itemAction.action.handler === "downloadGrazingSheet") {
        this.downloadGrazingSheet(itemAction);
      }
    },

    // check to see if seasons are ready to be viewed in the data viewer by comparing the length of the paddock boundary dataset to the rows in the season table
    async validateSeasons() {
      // update paddock boundaries dataset in store
      this.seasonStatus = `Checking for status updates, please wait.`;

      const dataset = await this.$store.dispatch("mapping/getSingleDataset", {
        datasetName: this.$store.state.mapping.singleDataset.DatasetName,
        datasetOwner: this.$store.state.mapping.singleDataset.DatasetOwner,
      });

      const datasetFiles = dataset.Files;

      const seasonRows = this.seasonList;

      const viewableSeasonCount = this.seasonUploadCount(
        seasonRows,
        datasetFiles
      );

      this.seasonStatus = `${viewableSeasonCount} season(s) uploading...`;

      if (viewableSeasonCount !== 0) {
        // const viewableSeasons = seasonRows.filter((season) => {
        //   const matchedFile = datasetFiles.find(
        //     (file) => file.StartDate === season["Season start date*"]
        //   );
        //   if (matchedFile === -1) {
        //     return matchedFile;
        //   }
        // });
      }
    },
  },

  mounted() {
    if (this.newRanchName && this.seasonList.length === 0) {
      this.newSeasonDialog = true;
    } else {
      this.validateSeasons();
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep tbody {
  tr.v-data-table__selected {
    background-color: #f8f4e3 !important;
  }
}

::v-deep tbody {
  tr:hover {
    background-color: #f8f4e3 !important;
  }
}
::v-deep .v-data-table-header {
  background-color: #ececec;
  // background-color: #f8f4e3;
}

.aside-right {
  border-left: 2px solid black;
}

.aside-small {
  border-left: none;
}
</style>

<template>
  <v-sheet height="100%" width="100%" style="display: flex;">
    <project-nav-drawer
      :project="project"
      :project-page="currentPage"
      @change-page="changePage"
    />
    <project-settings :project="project" :project-page="currentPage" />
    <project-variables :project="project" :project-page="currentPage" />
    <project-simulations :project="project" :project-page="currentPage" />
  </v-sheet>
</template>

<script>
import ProjectNavDrawer from "@/components/projects/ProjectNavDrawer.vue";
import ProjectSettings from "@/components/projects/ProjectSettings.vue";
import ProjectVariables from "@/components/projects/ProjectVariables.vue";
import ProjectSimulations from "@/components/projects/ProjectSimulations.vue";

export default {
  name: "Project",

  components: {
    ProjectNavDrawer,
    ProjectSettings,
    ProjectVariables,
    ProjectSimulations,
  },

  data() {
    return {
      currentPage: "Project",
    };
  },

  computed: {
    project() {
      return this.$store.state.project;
    },
  },

  methods: {
    changePage(pageName) {
      this.currentPage = pageName;
    },
  },
  mounted() {
    if (
      this.$store.state.project.activeProject.Properties.CreatedFromProjectOwner
    ) {
      this.currentPage = "Variables";
    }
  },
};
</script>

<style scoped></style>

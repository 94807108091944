<template>
  <div class="d-flex justify-center align-center pa-5" v-if="initializing">
    <v-progress-circular indeterminate :color="color"></v-progress-circular>
  </div>
  <div v-else>
    Vector Data
    In progress...
  </div>
</template>

<script>
export default {
  name: "VectorData",

  props: { params: Object },

  data() {
    return {
      initializing: true,
      modelParams: {},
    };
  },

  computed: {
    color() {
      return this.$store.state.settings.appColor;
    },
  },

  methods: {
    update() {
      this.$emit("submit-params", this.modelParams);
    },
  },

  beforeMount() {
    this.modelParams = { ...this.modelParams, ...this.params };
    this.update()
    setTimeout(() => this.initializing = false, 100);
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"py-4"},[_c('div',{staticClass:"d-flex justify-space-evenly align-center"},[_c('v-btn',{staticClass:"ma-2 white--text text-capitalize",attrs:{"fab":"","small":"","disabled":_vm.datasetFileUploadLogs.length === 0,"color":"blue-grey"},on:{"click":_vm.getDatasetLogs}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-refresh ")])],1),_c('p',{staticClass:"text-caption mb-0"},[_vm._v(" Click the refresh button to check for updates to the status of file uploads. ")])],1),(_vm.datasetFileUploadLogs.length === 0)?_c('p',{staticClass:"pt-4 d-flex justify-center text-caption"},[_vm._v(" There are no file upload logs associated with this dataset yet. ")]):_vm._e()]),_c('v-divider'),(_vm.dataset.DatasetType === 'Raster')?_c('v-list',{attrs:{"three-line":"","dense":""}},[_vm._l((_vm.datasetFileUploadLogs),function(upload,index){return [_c('v-list-item',{key:upload.UploadInstanceId},[_c('v-list-item-content',[_c('v-list-item-title',{class:upload.Status === 'Done'
                ? 'success--text'
                : upload.Status === 'Failed'
                ? 'error--text'
                : upload.Status === 'Provisioning'
                ? 'blue--text'
                : 'orange--text'},[_vm._v(_vm._s(upload.Status))]),_c('v-list-item-subtitle',{staticClass:"text--primary pl-4"},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" Filename: ")]),_vm._v(" "+_vm._s(upload.UploadedFiles[0].Filename)+" ")]),_c('v-list-item-subtitle',{staticClass:"text--primary pl-4"},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" Upload Instance ID: ")]),_vm._v(" "+_vm._s(upload.UploadInstanceId)+" ")]),_c('v-list-item-subtitle',{staticClass:"text--primary pl-4"},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" Date Range Start: ")]),_vm._v(" "+_vm._s(upload.UploadedFiles[0].DateRangeStart)+" ")]),_c('v-list-item-subtitle',{staticClass:"text--primary pl-4"},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" Date Range End: ")]),_vm._v(" "+_vm._s(upload.UploadedFiles[0].DateRangeEnd)+" ")]),_c('v-list-item-subtitle',{staticClass:"text--primary pl-4"},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" Error Message: ")]),_vm._v(" "+_vm._s(upload.ErrorMessage ? upload.ErrorMessage : "None")+" ")])],1)],1),(index < _vm.datasetFileUploadLogs.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2):_vm._e(),(_vm.dataset.DatasetType === 'Vector')?_c('v-list',{attrs:{"three-line":"","dense":""}},[_vm._l((_vm.datasetFileUploadLogs),function(upload,index){return [_c('v-list-item',{key:upload.UploadInstanceId},[_c('v-list-item-content',[_c('v-list-item-title',{class:upload.Status === 'Done'
                ? 'success--text'
                : upload.Status === 'Failed'
                ? 'error--text'
                : upload.Status === 'Provisioning'
                ? 'blue--text'
                : 'orange--text'},[_vm._v(_vm._s(upload.Status))]),_c('v-list-item-subtitle',{staticClass:"text--primary pl-4"},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" Filename: ")]),_vm._v(" "+_vm._s(`${upload.UploadedFiles[0].Filename}`)+" ")]),_c('v-list-item-subtitle',{staticClass:"text--primary pl-4"},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" Upload Instance Id: ")]),_vm._v(" "+_vm._s(`${upload.UploadInstanceId}`)+" ")]),_c('v-list-item-subtitle',{staticClass:"text--primary pl-4"},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" Date Range Start: ")]),_vm._v(" "+_vm._s(upload.UploadedFiles[0].DateRangeStart)+" ")]),_c('v-list-item-subtitle',{staticClass:"text--primary pl-4"},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" Date Range End: ")]),_vm._v(" "+_vm._s(upload.UploadedFiles[0].DateRangeEnd)+" ")]),_c('v-list-item-subtitle',{staticClass:"text--primary pl-4"},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" Layer Name: ")]),_vm._v(" "+_vm._s(upload.LayerName ? upload.LayerName : "")+" ")]),_c('v-list-item-subtitle',{staticClass:"text--primary pl-4"},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" Error Message: ")]),_vm._v(" "+_vm._s(upload.ErrorMessage ? upload.ErrorMessage : "None")+" ")])],1)],1),(index < _vm.datasetFileUploadLogs.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2):_vm._e(),(_vm.dataset.DatasetType === 'Table')?_c('v-list',{attrs:{"three-line":"","dense":""}},[_vm._l((_vm.datasetFileUploadLogs),function(upload,index){return [_c('v-list-item',{key:upload.UploadInstanceId},[_c('v-list-item-content',[_c('v-list-item-title',{class:upload.Status === 'Done'
                ? 'success--text'
                : upload.Status === 'Failed'
                ? 'error--text'
                : upload.Status === 'Provisioning'
                ? 'blue--text'
                : 'orange--text'},[_vm._v(_vm._s(upload.Status))]),_c('v-list-item-subtitle',{staticClass:"text--primary pl-4"},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" Filename: ")]),_vm._v(" "+_vm._s(`${upload.UploadedFiles[0].Filename}`)+" ")]),_c('v-list-item-subtitle',{staticClass:"text--primary pl-4"},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" Upload Instance Id: ")]),_vm._v(" "+_vm._s(`${upload.UploadInstanceId}`)+" ")]),_c('v-list-item-subtitle',{staticClass:"text--primary pl-4"},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" Upload Batch Job: ")]),_vm._v(" "+_vm._s(upload.BatchJobId ? upload.BatchJobId : "Not Found")+" ")]),_c('v-list-item-subtitle',{staticClass:"text--primary pl-4"},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" Date Range Start: ")]),_vm._v(" "+_vm._s(upload.UploadedFiles[0].DateRangeStart)+" ")]),_c('v-list-item-subtitle',{staticClass:"text--primary pl-4"},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" Date Range End: ")]),_vm._v(" "+_vm._s(upload.UploadedFiles[0].DateRangeEnd)+" ")]),_c('v-list-item-subtitle',{staticClass:"text--primary pl-4"},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" Index Column: ")]),_vm._v(" "+_vm._s(upload.IndexColumn ? upload.IndexColumn : "")+" ")]),_c('v-list-item-subtitle',{staticClass:"text--primary pl-4"},[_c('span',{staticClass:"font-weight-black text-wrap"},[_vm._v(" Error Message: ")]),_vm._v(" "+_vm._s(upload.ErrorMessage ? upload.ErrorMessage : "None")+" ")])],1)],1),(index < _vm.datasetFileUploadLogs.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
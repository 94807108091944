<template>
  <v-data-table
    class="px-4"
    v-model="selectedRow"
    :headers="propertyHeaders"
    :items="builtTableData"
    :item-key="indexColumn"
    single-select
    hide-default-footer
    :items-per-page="-1"
    :no-data-text="noDataText"
    @click:row="selectRow"
    fixed-header
    :height="dataTableHeight"
  >
    <!-- This template looks for headers with formatters and executes them -->
    <template
      v-for="header in propertyHeaders.filter((header) =>
        header.hasOwnProperty('formatter')
      )"
      v-slot:[`item.${header.value}`]="{ item }"
    >
      <template v-if="header.hasOwnProperty('formatter')">
        {{ header.formatter(item[header.value]) }}
      </template>
      <template v-else>
        {{ item[header.value] }}
      </template>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-btn
        v-for="action in actions"
        :key="action.value"
        :color="action.color"
        :loading="action.loading"
        small
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="action.icon"
              color=""
              v-bind="attrs"
              v-on="on"
              @click.stop="emitTakeAction({ item: item, action: action })"
            >
              {{ action.icon }}
            </v-icon>
          </template>
          <span>{{ action.description }}</span>
        </v-tooltip>
      </v-btn>
    </template>
    <!-- <template v-slot:header="{ props: { headers } }">
          <th v-for="header in headers" :key="header.StartDate">
            {{ header.text }}
            <v-btn icon>
              <v-icon x-small>mdi-information</v-icon>
            </v-btn>
          </th>
        </template> -->
  </v-data-table>
</template>

<script>
import { formatTable, formatNumber } from "@/helpers/formatting";

export default {
  name: "DataTable",

  props: {
    tableInformation: {
      type: Object,
    },

    noDataText: {
      type: String,
      default:
        "To complete creating a new ranch, add the first grazing season.",
    },
    updatedSelectedRow: Object,

    dataTableHeight: {
      type: String,
    },
    actions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      builtTableData: [],
      selectedRow: [],
      propertyHeaders: [],
    };
  },

  watch: {
    updatedSelectedRow(savedSelectedRow) {
      this.selectedRow = [savedSelectedRow];
    },
    tableRowsLength() {
      this.buildData(this.tableInformation);
    },
  },

  computed: {
    indexColumn() {
      const indexColumn = (column) => {
        if (column === "") return "rowIndex";
        else return column;
      };
      return indexColumn(this.tableInformation.IndexColumn);
    },

    tableRowsLength() {
      return this.tableInformation.Rows.length;
    },
  },

  methods: {
    buildPropertyHeaders(tableInformation) {
      const headers = tableInformation.Columns;
      const headerTypes = tableInformation.ColumnTypes;
      const immutableColumns = tableInformation.ImmutableColumns
        ? tableInformation.ImmutableColumns
        : [];

      const headerType = (type) => {
        switch (type) {
          case "String":
            return "text";

          case "StringDate":
            return "date";

          case "ChoiceString":
            return "select";

          case "Integer":
          case "Float":
            return "number";
          default:
            return "text";
        }
      };

      const headerValue = (header) => {
        if (header === "") return "rowIndex";
        else return header;
      };

      const builtHeaders = headers.map((header, index) => ({
        value: headerValue(header),
        text: header.replace("*", ""),
        sortable: false,
        editable: !immutableColumns.includes(header),
        type: headerType(headerTypes[index]),
        formatter: (x) => (typeof x === "number" ? formatNumber(x) : x),
      }));

      if (this.actions.length > 0)
        builtHeaders.push({
          text: `${
            this.actions.length === 1 ? this.actions[0].text : "Actions"
          }`,
          value: "actions",
          sortable: false,
        });

      return builtHeaders;
    },

    buildData(tableInformation) {
      this.builtTableData = formatTable(tableInformation);
    },

    selectRow(item, data) {
      if (!data.isSelected) {
        this.selectedRow.splice(0, 1);
        this.selectedRow.unshift(data.item);
      }

      // remove actions from headers
      this.$emit("property-headers", this.propertyHeaders.slice(0, -1));
      this.$emit("selected-row", this.selectedRow[0]);
    },

    emitTakeAction(itemAction) {
      itemAction.action.loading = true;
      this.$emit("take-action", itemAction);
    },
  },

  created() {
    this.propertyHeaders = this.buildPropertyHeaders(this.tableInformation);
    this.$emit("property-headers", this.propertyHeaders);
  },

  mounted() {
    this.buildData(this.tableInformation);
  },
};
</script>

<style lang="scss" scoped></style>

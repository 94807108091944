<template>
  <div class="d-flex justify-center align-center pa-5" v-if="initializing">
    <v-progress-circular indeterminate :color="color"></v-progress-circular>
  </div>
  <div v-else>
    <v-select
      style="padding: 10px;"
      v-model="inputType"
      :items="inputTypes"
      label="Result selection type"
      :color="color"
    ></v-select>

    <v-text-field
      v-if="inputType === 'delta'"
      style="padding: 10px;"
      v-model="modelParams.delta"
      label="Delta"
      hint="Note: Delta must be a negative integer"
      :color="color"
      type="number"
      @input="update"
    ></v-text-field>

    <v-date-picker
      v-else-if="inputType === 'absolute'"
      v-model="modelParams.absolute"
      label="Absolute Time"
      :color="color"
      @input="update"
    ></v-date-picker>

    <v-text-field
      v-else
      style="padding: 10px;"
      v-model="modelParams.index"
      label="Index"
      hint="Note: The index must be an integer"
      :color="color"
      type="number"
      @input="update"
    ></v-text-field>

    <span v-if="message" class="red--text">{{ `Warning: ${message}` }}</span>
  </div>
</template>

<script>
export default {
  name: "Result",

  props: { params: Object },

  data() {
    return {
      inputType: "delta",
      inputTypes: ["delta", "absolute", "index"],
      initializing: true,
      modelParams: { delta: -1 },
      message: null,
    };
  },

  computed: {
    color() {
      return this.$store.state.settings.appColor;
    },
  },

  watch: {
    inputType() {
      const defaultValue = this.modelParams.default;
      this.modelParams = { default: defaultValue };
    },
  },

  methods: {
    update() {
      if (this.inputType === "delta") {
        if (this.modelParams.delta >= 0) {
          this.message = "Delta must be a negative integer";
          return;
        } else this.message = null
        this.modelParams.delta = parseInt(Math.round(this.modelParams.delta));
      } else if (this.inputType === "index")
        this.modelParams.index = parseInt(Math.round(this.modelParams.index));

      this.$emit("submit-params", this.modelParams);
    },
  },

  beforeMount() {
    this.modelParams = { ...this.modelParams, ...this.params };

    if (this.modelParams.absolute) this.inputType = "absolute"
    else if (this.modelParams.index) this.inputType = "index"

    this.update();
    setTimeout(() => (this.initializing = false), 100);
  },
};
</script>

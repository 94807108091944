<template>
  <div class="d-flex justify-center align-center pa-5" v-if="initializing">
    <v-progress-circular indeterminate :color="color"></v-progress-circular>
  </div>
  <div v-else>
    <div class="d-flex justify-center align-center">
      <v-switch
        v-model="modelParams.single"
        @change="clearSelections = true"
        label="Single Input"
        :color="color"
        hint="With a single input, a data reduction will occur (a single-number result). With multiple inputs, stats will be applied spatially over datasets."
        persistent-hint
      >
      </v-switch>
      <v-text-field
        v-if="methodName === 'percentile'"
        type="number"
        label="percentile"
        v-model="modelParams.q"
      ></v-text-field>
    </div>
    <v-fade-transition>
      <dataset-selector
        :selection-mode="modelParams.single"
        :single-select="!modelParams.single"
        :batch-select="false"
        :crud-actions="false"
        :clear-selections="clearSelections"
        @selections-cleared="clearSelections = false"
        @update-dataset="updateDataset"
        @update-datasets="updateDatasets"
      ></dataset-selector>
    </v-fade-transition>
  </div>
</template>

<script>
import DatasetSelector from "@/components/data/DatasetSelector.vue";

export default {
  name: "Stats",

  components: {
    DatasetSelector,
  },

  props: {
    context: Object,
    params: Object,
  },

  data() {
    return {
      initializing: true,
      modelParams: { single: true },
      clearSelections: false,
    };
  },

  computed: {
    color() {
      return this.$store.state.settings.appColor;
    },
    methodName() {
      return this.context.name ? this.context.name.token : null;
    },
  },

  watch: {
    "modelParams.single"(newVal) {
      if (this.initializing === false) {
        this.modelParams = { single: newVal };
      }
    },
  },

  methods: {
    update() {
      this.$emit("submit-params", this.modelParams);
    },
    updateDataset(ds) {
      this.modelParams.input = `<__code__>data(name='${ds.DatasetName}')`;
      this.update();
    },
    updateDatasets(dsts) {
      let datasets = [];

      dsts.forEach((ds) => {
        datasets.push(`data(name='${ds.DatasetName}')`);
      });

      this.modelParams.input = `<__code__>[${datasets.join(", ")}]`;
      this.update();
    },
  },

  beforeMount() {
    this.modelParams = { ...this.modelParams, ...this.params };
    if (this.methodName === "percentile" && !this.modelParams.q)
      this.modelParams.q = 50;
    this.update();
    setTimeout(() => (this.initializing = false), 100);
  },
};
</script>

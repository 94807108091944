export default function hexToRGB(hex) {
  let r = 0,
    g = 0,
    b = 0,
    a = 1;

  // 3 digits no alpha
  if (hex.length == 4) {
    r = "0x" + hex[1] + hex[1];
    g = "0x" + hex[2] + hex[2];
    b = "0x" + hex[3] + hex[3];

    // 6 digits no alpha
  } else if (hex.length == 7) {
    r = "0x" + hex[1] + hex[2];
    g = "0x" + hex[3] + hex[4];
    b = "0x" + hex[5] + hex[6];

    // 4 digits with alpha
  } else if (hex.length == 5) {
    r = "0x" + hex[1] + hex[1];
    g = "0x" + hex[2] + hex[2];
    b = "0x" + hex[3] + hex[3];
    a = "0x" + hex[4] + hex[4];

    a = +(a / 255).toFixed(3);

    // 8 digits with alpha
  } else if (hex.length == 9) {
    r = "0x" + hex[1] + hex[2];
    g = "0x" + hex[3] + hex[4];
    b = "0x" + hex[5] + hex[6];
    a = "0x" + hex[7] + hex[8];

    a = +(a / 255).toFixed(3);
  }

  return [+r, +g, +b, a];
}

const state = {
  alert: null,
};
const mutations = {
  showAlert(state, alert) {
    state.alert = alert;
  },
  alertConfirm(state, confirm) {
    state.alert.confirmed = confirm;
  },
  showErrorAlert(state, error) {
    const { data, status } = error.response;

    const formattedAlert = {
      text: `(${status}) - ${data.Errors}`,
      type: data.Errors ? "error" : "warning",
    };

    state.alert = formattedAlert;
  },
};
const getters = {};
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.projectList.length === 0 &&
    !_vm.$store.state.accountData.projectList.fetchingUpdates
  )?_c('div',{staticClass:"text-center py-2"},[_c('h2',[_vm._v("No Projects Found")])]):(
    _vm.projectList.length === 0 &&
    _vm.$store.state.accountData.projectList.fetchingUpdates
  )?_c('div',[_c('v-subheader',{staticClass:"text-h5 font-weight-black black--text pt-2"},[_vm._v(" My Projects ")]),_c('v-row',_vm._l((10),function(loader){return _c('v-col',{key:loader,attrs:{"cols":"12","sm":"12","md":"4","lg":"3"}},[_c('v-skeleton-loader',{attrs:{"type":"card","max-height":"200"}})],1)}),1),_c('v-divider'),_c('v-subheader',{staticClass:"text-h5 font-weight-black black--text pt-2"},[_vm._v(" Projects Shared with Me ")]),_c('v-row',_vm._l((10),function(loader){return _c('v-col',{key:loader,attrs:{"cols":"12","sm":"12","md":"4","lg":"3"}},[_c('v-skeleton-loader',{attrs:{"type":"card","max-height":"200"}})],1)}),1)],1):_c('div',[_c('v-subheader',{staticClass:"text-h5 font-weight-black black--text pt-2"},[_vm._v(" My Projects ")]),(_vm.userProjects.length === 0)?_c('h3',{staticClass:"text-center py-2"},[_vm._v(" No Projects Found ")]):_vm._e(),_c('v-row',{staticClass:"pa-5"},_vm._l((_vm.userProjects),function(project){return _c('v-col',{key:project.ProjectName,attrs:{"sm":"12","md":"4","lg":"3"}},[(
          _vm.projectLoading && _vm.projectLoading.ProjectName === project.ProjectName
        )?_c('v-skeleton-loader',{attrs:{"type":"card","max-height":"200"}}):_c('v-card',{staticClass:"mx-auto",staticStyle:{"cursor":"pointer"},attrs:{"elevation":"3"},on:{"click":function($event){return _vm.openProject(project)}}},[_c('v-img',{attrs:{"height":"120","gradient":"to bottom right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0)","src":require(`@/assets/project-img/${
              project.ProjectImage
                ? project.ProjectImage
                : 'jack-hamilton-jR311W7n9fk-unsplash.jpg'
            }`)}},[_c('div',{staticClass:"d-flex white--text"},[_c('div',[_c('v-card-title',[_vm._v(_vm._s(project.ProjectName))]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(project.ProjectOwner)),_c('br'),_vm._v(" "+_vm._s(_vm.formatDateString(project.UpdatedAt))+" ")])],1)])]),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Description")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(project.Description ? project.Description : "No description"))])],1)],1)],1)],1)}),1),_c('v-divider'),_c('v-subheader',{staticClass:"text-h5 font-weight-black black--text pt-2"},[_vm._v(" Projects Shared with Me ")]),(_vm.userProjects.length === 0)?_c('h3',{staticClass:"text-center py-2"},[_vm._v(" No Projects Found ")]):_vm._e(),_c('v-row',{staticClass:"pa-5"},_vm._l((_vm.sharedProjects),function(project){return _c('v-col',{key:project.ProjectName,attrs:{"sm":"12","md":"4","lg":"3"}},[(
          _vm.projectLoading && _vm.projectLoading.ProjectName === project.ProjectName
        )?_c('v-skeleton-loader',{attrs:{"type":"card","max-height":"200"}}):_c('v-card',{staticClass:"mx-auto",staticStyle:{"cursor":"pointer"},attrs:{"elevation":"3"},on:{"click":function($event){return _vm.openProject(project)}}},[_c('v-img',{attrs:{"height":"120","gradient":"to bottom right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0)","src":require(`@/assets/project-img/${
              project.ProjectImage
                ? project.ProjectImage
                : 'jack-hamilton-jR311W7n9fk-unsplash.jpg'
            }`)}},[_c('div',{staticClass:"d-flex white--text"},[_c('div',[_c('v-card-title',[_vm._v(_vm._s(project.ProjectName))]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(project.ProjectOwner)),_c('br'),_vm._v(" "+_vm._s(_vm.formatDateString(project.UpdatedAt))+" ")])],1)])]),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Description")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(project.Description ? project.Description : "No description"))])],1)],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
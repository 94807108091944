const getMathParams = () => {
  /*
    Collect all math constants and functions
     */
  const constants = {
    E: { description: "Euler's Number" },
    PI: { description: "Approximation of Pi" },
    SQRT2: { description: "The Square Root of 2" },
    SQRT1_2: { description: "The Square Root of 1/2" },
    LN2: { description: "The Natural Logarithm of 2" },
    LN10: { description: "The Natural Logarithm of 10" },
    LOG2E: { description: "Base 2 Logarithm of E" },
    LOG10E: { description: "Base 10 Logarithm of E" },
  };

  const mathDescriptions = {
    abs: "f(x)	Returns the absolute value of x",
    acos: "f(x)	Returns the arccosine of x, in radians",
    acosh: "f(x)	Returns the hyperbolic arccosine of x",
    asin: "f(x)	Returns the arcsine of x, in radians",
    asinh: "f(x)	Returns the hyperbolic arcsine of x",
    atan:
      "f(x)	Returns the arctangent of x as a numeric value between -PI/2 and PI/2 radians",
    atan2: "f(y, x)	Returns the arctangent of the quotient of its arguments",
    atanh: "f(x)	Returns the hyperbolic arctangent of x",
    cbrt: "f(x)	Returns the cubic root of x",
    ceil: "f(x)	Returns x, rounded upwards to the nearest integer",
    cos: "f(x)	Returns the cosine of x (x is in radians)",
    cosh: "f(x)	Returns the hyperbolic cosine of x",
    exp: "f(x)	Returns the value of Ex",
    expm1: "f(x) Returns ex - 1, where e is the base of the natural logarithm",
    floor: "f(x)	Returns x, rounded downwards to the nearest integer",
    log: "f(x)	Returns the natural logarithm (base E) of x",
    round: "f(x)	Rounds x to the nearest integer",
    sin: "f(x)	Returns the sine of x (x is in radians)",
    sinh: "f(x)	Returns the hyperbolic sine of x",
    sqrt: "f(x)	Returns the square root of x",
    tan: "f(x)	Returns the tangent of an angle",
    tanh: "Returns the hyperbolic tangent of a number",
    trunc: "f(x)	Returns the integer part of a number (x)",
  };

  const removedMathParams = [
    "pow",
    "random",
    "min",
    "max",
    "mean",
    "sign",
    "imul",
    "clz32",
    "fround",
    "log2",
    "log10",
    "log1p",
    "hypot"
  ];

  let params = [];
  Object.getOwnPropertyNames(Math).forEach((name) => {
    if (constants[name]) {
      params.push({
        s: name,
        // TODO: Configure the next two attrs for use in the UI
        description: constants[name].description,
        params: [],
      });
    } else if (!removedMathParams.includes(name)) {
      params.push({
        s: name,
        // TODO: Configure the next two attrs for use in the UI
        description: mathDescriptions[name],
        params: [],
      });
    }
  });

  return params;
};

export default getMathParams;

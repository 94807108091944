<template>
  <div class="container">
    <div class="left-col">
      <span
        >{{ definition.type !== undefined ? `${name}:` : name }}
        <i>{{ definition.type !== undefined ? definition.type : "" }}</i></span
      >
      <p style="margin-left: 1em" v-if="definition.description">
        {{ definition.description }}
      </p>
    </div>

    <v-text-field
      v-if="definition.type === 'str'"
      v-model="value"
      class="right-col"
      :placeholder="
        definition.default !== undefined ? definition.default : null
      "
      :rules="[rules.optional]"
      clearable
      outlined
    ></v-text-field>
    <v-text-field
      v-else-if="definition.type === 'int'"
      type="number"
      v-model="value"
      class="right-col"
      :placeholder="
        definition.default !== undefined ? definition.default : null
      "
      :rules="[rules.optional, rules.int]"
      clearable
      outlined
    ></v-text-field>
    <v-text-field
      v-else-if="definition.type === 'float'"
      type="number"
      v-model="value"
      class="right-col"
      :placeholder="
        definition.default !== undefined ? definition.default : null
      "
      :rules="[rules.optional, rules.float]"
      clearable
      outlined
    ></v-text-field>
    <v-text-field
      v-else-if="definition.type === 'date'"
      v-model="value"
      class="right-col"
      :placeholder="
        definition.default !== undefined ? definition.default : null
      "
      :rules="[rules.optional, rules.date]"
      clearable
      outlined
    ></v-text-field>
    <v-radio-group
      v-else-if="definition.type === 'bool'"
      v-model="value"
      :rules="[rules.optionalBool]"
      column
    >
      <v-radio
        :label="`True ${definition.default === true ? '(default)' : ''}`"
        :value="true"
      ></v-radio>
      <v-radio
        :label="`False ${definition.default === false ? '(default)' : ''}`"
        :value="false"
      ></v-radio>
    </v-radio-group>
    <div v-else-if="definition.type === 'flow-vector-data'" style="width: 100%">
      <v-text-field
        v-model="value"
        class="right-col"
        :placeholder="
          definition.default !== undefined ? definition.default : null
        "
        :rules="[rules.optional]"
        @click:append="() => (datasetSelection.active = true)"
        clearable
        outlined
      >
        <template v-slot:append>
          <v-icon color="black" @click="() => (datasetSelection.active = true)">
            mdi-database-search
          </v-icon>
        </template>
      </v-text-field>
      <v-dialog
        v-model="datasetSelection.active"
        width="80%"
        height="70%"
        persistent
        scrollable
      >
        <v-card class="d-flex flex-grow-1" elevation="0">
          <v-card-text class="px-0">
            <dataset-selector
              :selection-mode="true"
              :batch-select="false"
              :single-select="false"
              :multi-layer-select="false"
              :crud-actions="false"
              :active-project-tag="false"
              :initial-type-filters="['Vector']"
              :initial-tags="[]"
              :data-table-height="300"
              @update-dataset="
                (dataset) => {
                  datasetSelection.dataset = dataset;
                }
              "
              @update-layer="(layer) => (datasetSelection.layer = layer)"
              style="margin: 1em"
            ></dataset-selector>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="d-flex justify-center align-center">
            <v-btn
              text
              @click="
                () => {
                  datasetSelection.active = false;
                  setValueFromDatasetSelection();
                }
              "
              >OK</v-btn
            >

            <v-btn
              color="warning"
              text
              @click="() => (datasetSelection.active = false)"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import DatasetSelector from "@/components/data/DatasetSelector.vue";
export default {
  name: "SequenceParameter",

  data() {
    return {
      value: ["str", "int", "float", "date", "flow-vector-data"].includes(
        this.definition.type
      )
        ? ""
        : null,
      rules: {
        optional: (value) =>
          (typeof value === "string" && value.trim() !== "") ||
          !this.isRequired ||
          "Required",
        optionalBool: (value) =>
          value !== null || !this.isRequired || "Required",
        float: (value) =>
          value == parseFloat(value) || "Must be a number (integer)",
        int: (value) =>
          value == parseInt(value) || "Must be a number (decimal)",
        date: (value) =>
          /\d{4}-\d{2}-\d{2}/.test(value) ||
          "Must be a date (format: YYYY-MM-DD)",
      },

      datasetSelection: {
        active: false,
        dataset: null,
        layer: null,
      },
    };
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    definition: {
      type: Object,
      required: true,
    },
  },

  methods: {
    foo() {
      console.log(this.defaults);
    },

    isValid(value) {
      if (value === null) {
        return false;
      }
      if (this.type === "str") {
        return Boolean(value);
      } else if (this.type === "int") {
        return this.rules.int(value) === true;
      } else if (this.type === "float") {
        return this.rules.float(value) === true;
      } else if (this.type === "date") {
        return this.rules.date(value) === true;
      } else if (this.type === "flow-vector-data") {
        return Boolean(value);
      }
      return false;
    },

    setValueFromDatasetSelection() {
      if (this.datasetSelection.dataset !== null) {
        this.value = `flow:/dataset/${this.datasetSelection.dataset.DatasetOwner}/${this.datasetSelection.dataset.DatasetName}?layer=${this.datasetSelection.layer.LayerName}`;
      }
      this.value;
    },
  },

  computed: {
    type() {
      if (this.definition.type === undefined) {
        return "str";
      } else {
        return this.definition.type;
      }
    },
    hasDefault() {
      return this.definition.default !== undefined;
    },
    isRequired() {
      if (this.hasDefault) {
        return false;
      } else {
        return !this.definition.optional;
      }
    },
  },

  watch: {
    value() {
      if (this.value === this.definition.default) {
        this.$emit("input", { name: this.name, valid: true });
      } else if (!this.isValid(this.value)) {
        this.$emit("input", { name: this.name, valid: false });
      } else {
        // emit the right type
        try {
          let value = this.value;
          if (this.type === "int") {
            value = parseInt(value);
          } else if (this.type === "float") {
            value = parseFloat(value);
          }
          this.$emit("input", {
            name: this.name,
            value: value,
            valid: this.isValid(this.value),
          });
        } finally {
          // empty
        }
      }
    },
  },

  components: {
    DatasetSelector,
  },
};
/*
    name: display name
    type: int/float/str/date/bool  flow-dataset
    default
    description
    optional
    */
</script>

<style scoped>
.container {
  display: flex;
  margin-left: 0;
  align-items: center;
}
.left-col {
  border: 1px;
  width: 30em;
  margin-right: 5em;
}

.right-col {
  border: 1px;
}
</style>

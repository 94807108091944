<template>
  <div>
    <div id="logo-title">
      <v-row align="center">
        <v-col class="ma-6 d-flex justify-center">
          <v-img
            v-if="
              appName !== '' && appName !== 'ALCES Flow Development Staging'
            "
            :src="require(`@/assets/img/logo.png`)"
            max-width="200"
          ></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center">
          <h2>{{ appName }}</h2>
        </v-col>
      </v-row>
    </div>

    <amplify-auth title="Sign in"></amplify-auth>
  </div>
</template>

<script>
import AmplifyAuth from "@/components/auth/AmplifyAuth";

export default {
  name: "LoginView",

  components: { AmplifyAuth },

  data() {
    return {};
  },

  computed: {
    authenticated() {
      return (
        !this.$store.state.settings.authRequired ||
        this.$store.state.account.authenticated
      );
    },
    appName() {
      return this.$store.state.settings.appName;
    },
  },
};
</script>

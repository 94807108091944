<template>
  <!-- title  -->
  <v-card
    class="ma-0 pa-0 d-flex flex-column justify-space-between"
    flat
    style="overflow: hidden; background-color: transparent"
  >
    <v-card-title class="py-3 justify-center" v-if="!selfUpdateMode">
      {{ rowTitle }} Editor
    </v-card-title>

    <v-card-text
      class="d-flex flex-column flex-grow-2"
      style="min-height: 50vh"
    >
      <!-- table row editor -->

      <v-card
        v-if="rowData"
        class="table-row-edit-section d-flex flex-column flex-grow-1 justify-space-between"
        flat
      >
        <div>
          <v-card-title class="py-3 px-2 text-subtitle-1 font-weight-medium">
            {{ rowTitle }} Properties
          </v-card-title>

          <v-card-text class="px-2" style="overflow-y: auto">
            <v-row
              v-for="property in rowDefinitions"
              :key="property.value"
              no-gutters
            >
              <v-col cols="4">
                <p class="text-caption font-weight-bold black--text">
                  {{ property.text }}
                </p>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-if="property.type === 'number'"
                  class="text-body-2 white"
                  :disabled="!property.editable"
                  v-model.number="inputRowData[property.value]"
                  :type="property.type"
                  color="black"
                  hide-details
                  height="28"
                  flat
                  outlined
                >
                </v-text-field>
                <!-- selection type -->
                <v-select
                  v-else-if="property.type === 'select'"
                  class="text-body-2 white"
                  :disabled="!property.editable"
                  v-model="inputRowData[property.value]"
                  :items="tableInformation.ColumnChoices[property.value]"
                  color="black"
                  hide-details
                  height="28"
                  flat
                  outlined
                >
                </v-select>
                <v-text-field
                  v-else
                  class="text-body-2 white"
                  :disabled="!property.editable"
                  v-model="inputRowData[property.value]"
                  :type="property.type"
                  color="black"
                  hide-details
                  height="28"
                  flat
                  outlined
                  @change="checkRowDataModified(rowData, inputRowData)"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2" class="px-2"> </v-col>
            </v-row>
          </v-card-text>
        </div>

        <!-- table row actions -->
        <v-card-actions class="d-flex justify-center">
          <v-btn
            :v-show="false"
            v-if="rowDataModified"
            class="text-capitalize"
            color="success"
            small
            @click="updateChanges(inputRowData)"
            :loading="tableSaving || storeTableSaving"
            :disabled="rowDeleting"
            >Save Changes
          </v-btn>
          <v-btn
            v-if="!rowDataModified"
            class="text-capitalize"
            color="error"
            small
            :disabled="tableSaving || storeTableSaving"
            @click="deleteRow(rowData)"
            :loading="rowDeleting"
            >Delete {{ rowTitle }}</v-btn
          >
          <v-btn
            v-else
            class="text-capitalize"
            color="warning"
            small
            :disabled="tableSaving || storeTableSaving"
            @click="resetRow"
            >Cancel Changes</v-btn
          >
        </v-card-actions>
      </v-card>

      <!-- table row files -->
      <v-card
        v-if="!selfUpdateMode"
        class="table-row-edit-section d-flex flex-column flex-grow-1"
        flat
      >
        <v-card-title class="py-3 px-2 text-subtitle-1 font-weight-medium">
          {{ rowTitle }} Files
        </v-card-title>

        <v-card-text class="px-2" style="overflow-y: auto">
          <v-row
            v-for="property in rowFileDefinitions"
            :key="property.value"
            class="d-flex"
            no-gutters
          >
            <v-col cols="4">
              <p class="text-caption font-weight-bold black--text">
                {{ property.text }}
              </p>
            </v-col>
            <v-col cols="6">
              <v-file-input
                class="text-body-2 white"
                style="overflow-wrap: unset"
                :disabled="!property.editable"
                v-model="inputRowFileData[property.value]"
                color="black"
                hide-details
                height="28"
                flat
                outlined
                truncate-length="15"
                prepend-icon=""
                :clearable="false"
              >
              </v-file-input>
            </v-col>
            <v-col cols="2" class="px-2"
              ><v-icon>{{ property.icon }}</v-icon>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- table row actions -->

        <v-card-actions class="d-flex justify-center">
          <!-- <v-btn class="text-capitalize" color="error" small
            >Delete {{ rowTitle }}</v-btn
          > -->
        </v-card-actions>
      </v-card>
    </v-card-text>

    <!-- table actions -->
    <v-card-actions
      v-if="selfUpdateMode && tableInformation"
      class="table-actions-section d-flex justify-center"
    >
      <v-btn class="text-capitalize" color="success" small @click="addNewRow"
        >Add {{ rowTitle }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "TableRowEditor",

  props: {
    selfUpdateMode: {
      type: Boolean,
      default: true,
    },
    rowTitle: {
      type: String,
      default: "Row",
    },
    tableInformation: {
      type: Object,
    },
    rowDefinitions: {
      type: Array,
    },
    rowData: {
      type: Object,
    },
    rowFileDefinitions: {
      type: Array,
    },
    tableSaving: {
      type: Boolean,
      default: false,
    },
    rowDeleting: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      inputRowData: { ...this.rowData },
      inputRowFileData: {},
      rowDataModified: false,
      storeTableSaving: false,
      creatingNewRow: false,
    };
  },

  watch: {
    rowData(newSavedData) {
      this.inputRowData = { ...newSavedData };
    },

    inputRowData: {
      handler(newInputData) {
        this.checkRowDataModified(this.rowData, newInputData);
      },
      deep: true,
    },
  },

  methods: {
    checkRowDataModified(lastSavedRowData, newRowData) {
      this.rowDataModified =
        JSON.stringify(lastSavedRowData) !== JSON.stringify(newRowData);
    },

    updateChanges(newRowData) {
      if (this.selfUpdateMode) {
        this.saveChangedRowData(newRowData);
      } else {
        this.$emit("save-row-data-changes", newRowData);
      }
    },
    deleteRow(rowData) {
      if (this.selfUpdateMode) {
        this.deleteRowData(rowData);
      } else {
        this.$emit("delete-row", rowData);
      }
    },
    resetRow() {
      this.inputRowData = { ...this.rowData };
    },

    async saveChangedRowData(newRowData) {
      this.storeTableSaving = true;

      // request api to update row
      await this.$store.dispatch("mapping/updateTable", {
        tableInformation: this.tableInformation,
        newRowData,
      });

      // if (tableUpdated) {
      //   this.selectRow(newRowData);
      //   this.updatedSelectedRow = newRowData;
      // }

      // return new data
      this.storeTableSaving = false;
    },

    async deleteRowData(rowData) {
      this.storeTableSaving = true;

      const indexColumn = (index) =>
        index === "" ? "rowIndex" : this.tableInformation.IndexColumn;

      // request api to update row
      await this.$store.dispatch("mapping/deleteTableRow", {
        tableInformation: this.tableInformation,
        index: rowData[indexColumn(this.tableInformation.IndexColumn)],
      });

      // return new data
      this.storeTableSaving = false;
    },

    addNewRow() {
      this.creatingNewRow = true;
      // create blank row
      let newRow = {};

      // if index column is the row number, add one to the length of the table
      if (this.tableInformation.IndexColumn === "") {
        newRow.rowIndex = this.tableInformation.Rows.length + 1;
      }

      this.$store.commit("mapping/updateSelectedRow", newRow);
    },
  },
};
</script>

<style scoped>
.table-row-edit-section {
  padding: 6px;
  border-radius: 4px;
  background-color: #e1e1e0;
}

.table-actions-section {
  background-color: #e1e1e0;
}

.table-row-edit-section + .table-row-edit-section {
  margin: 6px 0;
}

::v-deep .v-text-field--outlined .v-input__control .v-input__slot {
  min-height: unset;
}

::v-deep .v-input__icon {
  display: none;
}
</style>

<template>
  <div class="cm-editor">
    <div id="sequence-text-area"></div>
  </div>
</template>

<script>
import { EditorState } from "@codemirror/state";
import {
  EditorView,
  keymap,
  lineNumbers,
  // updateListener
  // hoverTooltip,
} from "@codemirror/view";
import {
  indentWithTab,
  indentSelection,
  defaultKeymap,
} from "@codemirror/commands";
import { oneDark } from "@codemirror/theme-one-dark";
import { linter } from "@codemirror/lint";
import {
  json5,
  json5ParseLinter,
  // json5Language,
} from "codemirror-json5";
import {
  json5SchemaLinter,
  // json5SchemaHover,
  // json5Completion,
} from "codemirror-json-schema/json5";
export default {
  name: "SequenceCodeEditor",

  props: {
    id: {
      type: String,
      default: null,
    },
    buffer: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      text: "",
      view: null,
      seqSchema: {
        $schema: "http://json-schema.org/draft-04/schema#",
        type: "object",
        properties: {
          metadata: {
            type: "object",
          },
          toolkits: {
            type: "array",
            items: {
              type: "string",
            },
          },
          include: {
            type: "object",
            patternProperties: {
              ".*": { type: "string" },
            },
          },
          run: {
            type: "array",
            items: [
              {
                type: "object",
                properties: {
                  op: {
                    type: "string",
                  },
                },
                required: ["op"],
              },
              {
                type: "integer",
              },
              {
                type: "float",
              },
              {
                type: "string",
              },
            ],
          },
        },
        required: ["run"],
      },
    };
  },

  methods: {
    save() {
      this.$emit("save");
    },
  },

  mounted() {
    this.view = new EditorView({
      state: EditorState.create({
        doc: this.buffer,
        extensions: [
          lineNumbers(),
          json5(),
          linter(json5ParseLinter(), {
            delay: 500,
          }),
          linter(json5SchemaLinter(this.seqSchema), {
            delay: 500,
          }),
          keymap.of([
            defaultKeymap,
            indentWithTab,
            { key: "Mod-s", run: this.save, preventDefault: true },
          ]),
          // hoverTooltip(json5SchemaHover(this.seqSchema)),
          // json5Language.data.of({
          //   autocomplete: json5Completion(this.seqSchema),
          // }),
          oneDark,
          EditorView.updateListener.of((e) => {
            this.$emit("update", e.state.doc.toString());
          }),
        ],
      }),
      parent: document.getElementById("sequence-text-area"),
    });
    indentSelection({
      state: this.view.state,
      dispatch: (transaction) => this.view.update([transaction]),
    });
  },
};
</script>

<style scoped>
.v-system-bar {
  background-color: #282c34 !important;
  color: #abb2bf !important;
}
.cm-editor {
  max-height: calc(100vh - 3em);
  width: calc(100% - 18.2em);
  overflow-y: auto;
}
</style>

<template>
  <div class="analysis-view">
    <v-navigation-drawer
      permanent
      class="tools-navigation"
      :mini-variant.sync="mini"
      mini-variant-width="56"
    >
      <v-list dense nav>
        <v-list-item-group
          v-model="selectedAnalysisTool"
          active-class="text--accent-4"
          mandatory
        >
          <v-list-item
            v-for="tool in analysisTools"
            :key="tool.title"
            :value="tool.value"
            :disabled="tool.disabled"
            class="px-2"
          >
            <v-list-item-content class="d-flex flex-column justify-center">
              <v-list-item-action class="mx-auto">
                <v-icon>{{ tool.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-title
                class="text-caption text-wrap text-center"
                v-if="!mini"
                >{{ tool.title }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <div class="history-panel">
      <!-- analysis tool history -->
      <analysis-tool-history
        :key="selectedToolDefinition.ExpressionName"
        class="history-runs"
        v-if="selectedToolDefinition"
        :previous-tool-runs="currentToolRuns"
        :selected-new-run="selectedRunKey"
        @selected-run="updateSelectedRun"
      >
      </analysis-tool-history>

      <div class="new-analysis-button" v-if="selectedToolDefinition">
        <!-- new analysis -->
        <v-btn text class="text-capitalize" block @click="newAnalysis"
          ><v-icon large color="success">mdi-plus-circle</v-icon> New
          Analysis</v-btn
        >
      </div>
    </div>

    <div class="main-section">
      <!-- analysis tool -->
      <analysis-tool-configuration
        :key="selectedToolDefinition.ExpressionName"
        v-if="selectedToolDefinition && !selectedRun"
        :tool-definition="selectedToolDefinition"
        :input-device-definitions="toolInputDevices"
        @update-project="updateProject"
      ></analysis-tool-configuration>

      <analysis-tool-run
        class="new-run"
        :key="selectedToolDefinition.ExpressionName"
        v-else-if="selectedRun"
        :tool-definition="selectedToolDefinition"
        :previous-run="selectedRun"
        :input-device-definitions="toolInputDevices"
      >
      </analysis-tool-run>
    </div>
  </div>
</template>

<script>
import AnalysisToolHistory from "@/components/analysis_tools/AnalysisToolHistory.vue";
import AnalysisToolConfiguration from "@/components/analysis_tools/AnalysisToolConfiguration.vue";
import AnalysisToolRun from "@/components/analysis_tools/AnalysisToolRun.vue";

export default {
  name: "AnalysisTools",

  components: {
    AnalysisToolHistory,
    AnalysisToolConfiguration,
    AnalysisToolRun,
  },

  props: {},

  data: () => ({
    analysisTools: [
      {
        title: "Change Analysis",
        value: "CAPI Change Analysis",
        icon: "mdi-swap-horizontal",
        disabled: false,
      },
      {
        title: "Natural Land Cover Risk",
        value: "CAPI Natural Land Cover Risk",
        icon: "mdi-forum",
        disabled: false,
      },
      {
        title: "Restoration Prioritization",
        value: "CAPI Restoration Prioritization",
        icon: "mdi-sprout",
        disabled: false,
      },
    ],

    selectedAnalysisTool: null,
    selectedRunKey: null,
    mini: false,
  }),

  watch: {
    // selectedAnalysisTool(toolName) {
    //   if (toolName)
    //     this.selectedToolDefinition = this.expressionAnalysisTools.find(
    //       (tool) => tool.ExpressionName === toolName
    //     );
    // },
  },

  computed: {
    // expressions within a project will define the tool and house the simulation runs.
    project() {
      return this.$store.state.project.activeProject;
    },

    expressionAnalysisTools() {
      return this.$store.state.project.activeProject.Simulations;
    },

    selectedToolDefinition() {
      try {
        return this.expressionAnalysisTools.filter(
          (tool) => tool.ExpressionName === this.selectedAnalysisTool
        )[0];
      } catch (error) {
        return null;
      }
    },

    currentToolRuns() {
      return this.selectedToolDefinition.Runs
        ? this.selectedToolDefinition.Runs
        : [];
    },

    toolInputDevices() {
      const toolInputDeviceNames = this.selectedToolDefinition.InputDeviceNames;

      const toolInputDeviceDefinitions = Object.fromEntries(
        Object.entries(this.project.InputDevices).filter((device) =>
          toolInputDeviceNames.includes(device[0])
        )
      );

      return toolInputDeviceDefinitions;
    },

    selectedRun() {
      const runKey = this.selectedRunKey;

      if (runKey)
        return this.currentToolRuns.filter(
          (run) => run.CreatedAt === runKey
        )[0];
      else return runKey;
    },

    // projectInputDevices() {
    //   const toolInputDeviceNames = this.selectedAnalysisTool.InputDeviceNames;

    //   const toolInputDeviceDefinitions = this.project.InputDevices.filter(
    //     (device) => toolInputDeviceNames.includes(device.ExpressionName)
    //   );

    //   return toolInputDeviceDefinitions;
    // },
  },

  methods: {
    updateSelectedRun(runKey) {
      this.selectedRunKey = runKey;
    },

    newAnalysis() {
      this.selectedRunKey = null;
    },

    async updateProject(projectDetails) {
      await this.$store.dispatch("project/loadProject", projectDetails);

      this.$nextTick(() => {
        const run = this.currentToolRuns.find(
          (run) => run.ScenarioName === projectDetails.scenarioName
        );

        this.updateSelectedRun(run.CreatedAt);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.analysis-view {
  display: flex;
  height: calc(100vh - 125px);
  min-width: 768px;
  background-color: white;
}

.tools-navigation {
  max-width: 110px;
}

.history-panel {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 256px;
  border-left: 0.1em solid black;
  border-right: 0.1em solid black;
}

.history-runs {
  margin: auto;
  flex: 1;
  overflow: hidden;
}

.new-analysis-button {
  margin: auto;
  background-color: white;
  align-self: flex-end;
  text-align: center;
  padding: 10px;
  border-top: 0.1em solid lightgrey;
}
.main-section {
  flex: 2;
  overflow-y: hidden;
  margin-left: 5px;
}
</style>

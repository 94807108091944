<template>
  <div>
    <div class="query-row" v-if="queryMapLayer">
      <div class="query-data" tabindex="0">
        <v-select
          v-model="rowValues.dataLayer"
          :items="[rowValues.dataLayer]"
          disabled
          return-object
          append-icon=""
          outlined
          hide-details
        >
          <template v-slot:selection="{ item }"
            >{{
              item.layer.LayerName === "Default"
                ? item.layer.DatasetName
                : `${item.layer.DatasetName} - ${item.layer.LayerName}`
            }}
          </template>
        </v-select>
      </div>
      <div class="operator" tabindex="0">
        <v-select
          v-model="rowValues.operator"
          :items="
            queryComponents.operators.filter((operator) =>
              operator.dataTypes.includes(queryLayerDataType)
            )
          "
          item-text="title"
          item-value="value"
          append-icon=""
          outlined
          hide-details
          clearable
          @change="updateRowValues"
        >
          <template v-slot:selection="{ item }">{{ item.title }} </template>
        </v-select>
      </div>
      <div
        class="value"
        tabindex="0"
        v-if="queryLayerDataType === 'Categorical'"
      >
        <v-select
          v-model="rowValues.value"
          :items="
            buildCategoryList(queryMapLayer.originalLayer.layer.Categories)
          "
          multiple
          item-text="title"
          item-value="value"
          return-object
          append-icon=""
          outlined
          hide-details
          clearable
          @change="updateRowValues"
        >
          <template v-slot:selection="{ item }">{{ item.title }}, </template>
        </v-select>
      </div>
      <div v-else class="value">
        <v-text-field
          outlined
          hide-details
          v-model="rowValues.value"
          type="number"
          clearable
          @change="updateRowValues"
        >
        </v-text-field>
      </div>
    </div>

    <div class="target-layer-row" v-else-if="targetMapLayerOptions">
      <div class="target-data" tabindex="0">
        <v-select
          v-model="rowValues.dataLayer"
          :items="targetMapLayerOptions"
          item-text="layer.LayerName"
          return-object
          append-icon=""
          outlined
          hide-details
          clearable
          @change="updateRowValues"
        >
          <template v-slot:selection="{ item }"
            >{{
              item.layer.LayerName === "Default"
                ? item.layer.DatasetName
                : `${item.layer.DatasetName} - ${item.layer.LayerName}`
            }}
          </template>
        </v-select>
      </div>
      <div class="operator" tabindex="0">
        <v-select
          v-model="rowValues.operator"
          :items="queryComponents.operators"
          item-text="title"
          item-value="value"
          append-icon=""
          outlined
          hide-details
          clearable
          @change="updateRowValues"
        >
          <template v-slot:selection="{ item }">{{ item.title }} </template>
        </v-select>
      </div>
      <div class="value" tabindex="0">
        <v-text-field
          v-model="rowValues.value"
          outlined
          hide-details
          clearable
          @change="updateRowValues"
        >
        </v-text-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    queryMapLayer: {
      type: Object,
      default: null,
    },
    targetMapLayerOptions: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      queryComponents: {
        operators: [
          {
            title: "=",
            value: "==",
            dataTypes: ["Categorical", "Continuous", "Mask"],
          },
          { title: "<=", value: "<=", dataTypes: ["Continuous"] },
          { title: ">=", value: ">=", dataTypes: ["Continuous"] },
        ],
        connectors: [
          { title: "AND", value: "&" },
          { title: "OR", value: "|" },
        ],
        layerQueryTemplate: {
          categorical: {
            dataLayer: null,
            operator: null,
            value: null,
            operator2: null,
            value2: null,
          },
          quantitative: {
            dataLayer: null,
            operator: null,
            value: null,
            operator2: null,
            value2: null,
          },
        },
        vectorLayer: {},

        field: [],

        value: {},
      },
      rowValues: {
        dataLayer: null,
        operator: null,
        value: null,
        operator2: null,
        value2: null,
      },
    };
  },

  watch: {},

  computed: {
    queryLayerDataType() {
      return this.queryMapLayer
        ? this.queryMapLayer.originalLayer.layer.TypeOfData
        : null;
    },
  },

  methods: {
    buildCategoryList(categoryObject) {
      return Object.keys(categoryObject).map((key) => ({
        title: key,
        value: { [key]: categoryObject[key] },
      }));
    },

    // TODO:
    isQueryValid() {},

    updateRowValues() {
      // only update if query is valid.
      if (this.rowValues.operator && this.rowValues.value) {
        this.$emit("values-updated", this.rowValues);
      }
    },
  },

  beforeMount() {
    if (this.queryMapLayer) {
      this.rowValues.dataLayer = this.queryMapLayer.originalLayer;
    }
  },
};
</script>

<style scoped>
.query-row,
.target-layer-row {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 6px;
  gap: 15px;
  align-items: center;
}

.query-data,
.target-data,
.operator,
.field,
.value {
  overflow: hidden;
  text-overflow: clip;
  text-align: center;
  justify-content: center;
  font-weight: bold;
  word-wrap: break-word;
  margin-bottom: 0px;
  border-radius: 0.1325rem 0.1325rem 0rem 0rem;
  border: 0.594px solid #7f7668;
  min-height: 3em;
  height: fit-content;
}

.query-data {
  background-color: #e1ffeb;
  width: 12rem;
}

.target-data {
  background-color: #fff5e1;
  width: 12rem;
}

.operator {
  background-color: #ddf3cc;
  width: 6rem;
}

.field {
  background-color: #e2ccf3;
  width: 10rem;
}

.value {
  background-color: #ffd9ad;
  width: 20rem;
}

::v-deep .v-input .v-select__selections,
::v-deep .v-text-field {
  justify-content: center;
  font-size: 0.75rem;
  font-weight: bold;
}
::v-deep .v-input .v-select__selections input {
  display: none;
}
</style>

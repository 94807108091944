<template>
  <div class="d-flex justify-center align-center pa-5" v-if="initializing">
    <v-progress-circular indeterminate :color="color"></v-progress-circular>
  </div>
  <div v-else>
    <v-select
      style="padding: 10px;"
      v-model="modelParams.method"
      label="Statistical Method"
      :items="methods"
      @input="update"
      :color="color"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "Patches",

  props: { params: Object },

  data() {
    return {
      initializing: true,
      modelParams: {
        method: "sum",
      },
      methods: [
        "mean",
        "min",
        "max",
        "sum",
        "std",
        "var",
        "median",
        "area",
        "boundary",
        "edge",
      ],
    };
  },

  computed: {
    color() {
      return this.$store.state.settings.appColor;
    },
  },

  methods: {
    update() {
      this.$emit("submit-params", this.modelParams);
    },
  },

  beforeMount() {
    this.modelParams = { ...this.modelParams, ...this.params };
    this.update()
    setTimeout(() => (this.initializing = false), 100);
  },
};
</script>

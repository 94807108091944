<template>
  <v-card flat class="table-editor-drawer" min-height="100%">
    <v-card-title class="py-2">
      <div v-if="editorPanelOpen">
        <v-btn icon @click="$emit('close-editor-panel')">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <v-icon dark class="my-auto mx-0 pr-4">mdi-table-edit</v-icon>
      <span class="text-h6">Table Editor</span>
      <v-spacer></v-spacer>
    </v-card-title>

    <v-divider></v-divider>

    <table-row-editor
      v-show="tableEditorData"
      table-header-color="#ECECEC"
      table-selection-color="#F8F4E3"
      :tableInformation="tableEditorData.table"
      :row-definitions="tableEditorData.tableHeaders"
      :row-data="tableEditorData.selectedRow"
      :row-file-definitions="null"
      :row-file-data="null"
      :table-saving="null"
      :row-deleting="null"
    >
    </table-row-editor>
  </v-card>
</template>

<script>
import TableRowEditor from "@/components/tables/TableRowEditor.vue";

export default {
  name: "TableEditorDrawer",

  components: {
    TableRowEditor,
  },

  props: {
    editorPanelOpen: { type: Boolean, default: false },
  },

  data() {
    return {
      drawerOpen: false,
      dataLoad: false,
      tableSaving: false,
    };
  },

  computed: {
    datasets() {
      return this.$store.state.mapping.datasets;
    },

    tableEditorData() {
      return this.$store.state.mapping.tableEditorData;
    },

    showOnlyEditor() {
      if (Object.keys(this.tableEditorData).length > 0) return true;
      else return false;
    },
  },

  watch: {
    drawerOpen() {
      this.$emit("drawer-status", this.drawerOpen);
    },
  },

  methods: {},
};
</script>

<style scoped>
.table-editor-drawer-container {
  display: flex;
}

.table-editor-drawer {
  background-color: #7f8e98;
  border-left: 2px solid black;
}
</style>

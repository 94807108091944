<template>
  <v-container fluid style="height: 100%; background-color: #ffffff">
    <v-row
      no-gutters
      class="d-flex flex-grow-1 justify-center flex-wrap-reverse"
      style="height: 100%"
    >
      <!-- Left Panel: User Table and Page Header -->
      <v-col
        cols="12"
        :sm="selectedUser ? tableColumns - 1 : tableColumns"
        :md="tableColumns"
      >
        <v-card>
          <v-card-title class="d-flex justify-space-between align-center">
            <div>User Accounts</div>
            <v-dialog v-model="newUserDialog" width="700" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="text-capitalize"
                  color="#B7CDAD"
                  v-bind="attrs"
                  v-on="on"
                  @click="setNewUserDefaultValues"
                >
                  <v-icon left>mdi-plus</v-icon>Add New User</v-btn
                >
              </template>

              <v-card>
                <v-card-title class="text-h5">New User</v-card-title>
                <v-spacer></v-spacer
                ><v-card-subtitle class="subtitle-2"
                  >Add a new user and optionally assign privileged roles by
                  adding the user to specific groups. By default, all users are
                  provided basic access to your organization.</v-card-subtitle
                >

                <v-card-text>
                  <div v-if="creatingNewUser" class="d-flex justify-center">
                    <v-progress-circular indeterminate></v-progress-circular>
                  </div>
                  <v-form v-else ref="form" v-model="newUserValid">
                    <v-text-field
                      v-model="newUserTemplate.email.value"
                      :rules="emailRules"
                      label="Email"
                      required
                      color="black"
                    ></v-text-field>
                    <v-select
                      v-model="newUserTemplate.groups.value"
                      :items="existingUserGroups"
                      item-disabled="disabled"
                      item-text="name"
                      item-value="name"
                      multiple
                      label="Groups"
                      required
                      color="black"
                    ></v-select>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    class="text-capitalize"
                    color="warning"
                    @click="closeNewUserDialog"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    class="text-capitalize"
                    @click="
                      createNewUserConfirm(
                        newUserTemplate.email.value,
                        currentUserPoolName,
                        newUserTemplate.groups.value
                      )
                    "
                    :loading="creatingNewUser"
                    :disabled="!newUserValid"
                    >Create New</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="users"
              item-key="email"
              single-select
              @click:row="setCurrentUser"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Right Panel: User Details & Actions -->
      <v-col cols="12" sm="5" md="4" v-show="selectedUser" ref="details">
        <v-card class="user-card" flat v-if="selectedUser">
          <v-card-title class="user-details-title text-body-1 text-md-h6">
            {{ selectedUser.username }}</v-card-title
          >

          <v-divider class="user-details-separator"></v-divider>
          <v-card-text>
            <v-list dense class="user-details">
              <v-list-item
                v-for="userProperty in filteredUserProperties"
                :key="userProperty.key"
                :selectable="!editableUserDetails.includes(userProperty.key)"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-capitalize pl-0">{{
                    userProperty.key
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    v-if="!editableUserDetails.includes(userProperty.key)"
                    >{{ userProperty.value }}</v-list-item-subtitle
                  >
                  <div v-if="userProperty.key === 'groups'">
                    <v-checkbox
                      v-for="group in existingUserGroups"
                      v-model="selectedUser.updatedGroups"
                      :disabled="group.disabled"
                      :label="group.name"
                      :key="group.name"
                      :value="group.name"
                      dense
                      color="black"
                      @change="
                        updateUserGroups(
                          selectedUser.email,
                          currentUserPoolName,
                          selectedUser.updatedGroups,
                          selectedUser.groups
                        )
                      "
                    >
                    </v-checkbox>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>

          <v-card-actions class="user-details-actions">
            <v-divider></v-divider>
            <v-btn
              v-for="(action, index) in userActions.filter(
                (action) => action.actionName !== 'Update User Groups'
              )"
              :key="index"
              class="text-capitalize flex-wrap"
              :color="action.activeColor"
              @click="
                doAction(
                  action.actionName,
                  selectedUser.email,
                  currentUserPoolName,
                  selectedUser.groups
                )
              "
              >{{ action.actionName }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  getUserList,
  createNewUser,
  deactivateUser,
  resetUserPassword,
  updateUserGroups,
} from "@/api/v2";

import awsconfig from "@/aws-exports";

export default {
  name: "UserManagement",

  data() {
    return {
      headers: [
        { text: "#", value: "id" },
        { text: "Username", value: "username", align: "start" },
        { text: "Email", value: "email" },
        // { text: "Status", value: "status" },
        { text: "Groups", value: "updatedGroups" },
      ],
      users: [],
      existingUserGroups: [],
      clientShortName: null,
      selectedUser: null,
      selectedUserDetailsUpdated: false,
      editableUserDetails: ["groups"],
      userActions: [
        {
          actionName: "Reset User Password",
          icon: null,
          loading: false,
          activeColor: "#8CC0F4",
          disabled: false,
        },
        {
          actionName: "Delete User",
          icon: null,
          loading: false,
          activeColor: "error",
          disabled: false,
        },
        {
          actionName: "Update User Groups",
          icon: null,
          loading: false,
          activeColor: "error",
          disabled: false,
        },
      ],
      newUserTemplate: {
        email: {
          type: "v-text-field",
          emptyValue: "",
          value: "",
          label: "Email",
          placeholder: "alice@example.com",
        },
        groups: {
          type: "v-select",
          emptyValue: [],
          value: [],
          label: "Groups",
          placeholder: null,
        },
      },
      newUserDialog: false,
      creatingNewUser: false,
      userActionLoading: false,
      newUserValid: true,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => this.isEmail(v) || "E-mail must be valid",
        (v) =>
          !this.users.find((user) => user.email === v) || "User already exists",
      ],
    };
  },

  computed: {
    filteredUserProperties() {
      if (this.selectedUser) {
        const filterProps = ["id", "username", "email", "updatedGroups"];
        // Filter out 'id' property and return the rest as an array of objects
        return Object.keys(this.selectedUser)
          .filter((key) => !filterProps.includes(key))
          .map((key) => ({
            key,
            value: this.selectedUser[key],
          }));
      }
      return [];
    },
    tableColumns() {
      return this.selectedUser ? 8 : 10;
    },
    currentUser() {
      return this.$store.state.account.user.attributes.email;
    },
    currentUserPoolName() {
      const userPoolName = awsconfig.aws_user_pool_name;
      return userPoolName;
    },

    userGroupsEdited() {
      let saveRequired = false;

      if (this.selectedUser) {
        saveRequired =
          !this.selectedUser.groups === this.selectedUser.updatedGroups;
      }

      return saveRequired;
    },
  },
  methods: {
    setCurrentPanel(item) {
      console.log(item);

      // Implement logic to change the current panel based on the clicked item
    },
    setCurrentUser(selected) {
      this.selectedUser = selected;

      const userDetailsContainer = this.$refs["details"];
      userDetailsContainer.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    },

    setClientShortName(groupList) {
      return groupList.find(
        (defaultUserGroup) => !defaultUserGroup.includes("-")
      );
    },

    isEmail(emailString) {
      const re = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g;

      return re.test(emailString);
    },

    validate() {
      this.$refs.form.validate();
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },

    isGroupDisabled(group) {
      let disabled = false;

      if (this.selectedUser === this.currentUser) {
        disabled = true;
        return;
      } else if (group === this.clientShortName) {
        disabled = true;
      }

      return disabled;
    },

    doAction(actionName, userEmail, currentUserPoolName, userGroups) {
      if (actionName === "Delete User") {
        this.deactivateUserConfirm(userEmail, currentUserPoolName);
      } else if (actionName === "Reset User Password") {
        this.resetPasswordConfirm(userEmail, currentUserPoolName);
      } else if (actionName === "Update User Groups") {
        this.updateGroups(userEmail, currentUserPoolName, userGroups);
      }
    },

    async getUserList() {
      try {
        const userList = await getUserList();

        //   create list of existing groups
        const existingUserGroups = [
          ...new Set(Object.values(userList.UserGroups).flat()),
        ];

        //   default user group
        this.clientShortName = this.setClientShortName(existingUserGroups);

        this.existingUserGroups = existingUserGroups.map((group) => ({
          name: group,
          disabled: this.isGroupDisabled(group),
        }));

        let id = 0;

        const formattedUserList = userList.Users.filter((user) =>
          this.isEmail(user)
        ).map((user) => ({
          id: id++,
          username: user,
          email: user,
          // status: "-",
          groups: userList.UserGroups[user],
          updatedGroups: userList.UserGroups[user],
        }));

        return formattedUserList;
      } catch (error) {
        this.$showAlert({
          text: error,
          type: "error",
        });
      }
    },

    async createNewUserConfirm(userEmail, currentUserPoolName, updatedGroups) {
      this.creatingNewUser = true;

      try {
        // will add user to default group automatically
        await createNewUser(userEmail, currentUserPoolName);

        // update user groups if needed
        if (updatedGroups.length > 1)
          await this.updateUserGroups(
            userEmail,
            currentUserPoolName,
            updatedGroups
          );

        this.$showAlert({
          text: `User created successfully.`,
          type: "success",
          timeout: 3000,
        });

        this.users = await this.getUserList();
      } catch (error) {
        this.$showAlert({
          text: error,
          type: "error",
        });
      } finally {
        this.creatingNewUser = false;
        this.closeNewUserDialog();
      }
    },
    async deactivateUserConfirm(userEmail, currentUserPoolName) {
      const confirmed = await this.$showAlert({
        text: `Are you sure you want to deactivate the account associated with <strong>${userEmail}</strong>?
        </br>The account user will immediately lose access to Flow and will be removed from your user administration list. `,
        type: "warning",
        choice: true,
      });

      if (confirmed) {
        try {
          await deactivateUser(userEmail, currentUserPoolName);

          this.users = this.users.filter((user) => user.email !== userEmail);

          this.$showAlert({
            text: `The account associated with ${userEmail} has been successfully deactivated.`,
            type: "success",
            timeout: 3000,
          });
        } catch (error) {
          this.$showAlert({
            text: error,
            type: "error",
          });
        }
      }
    },
    async resetPasswordConfirm(userEmail, currentUserPoolName) {
      const confirmed = await this.$showAlert({
        text: `Are you sure you want to reset the password for <strong>${userEmail}</strong>?

        </br> The user will be sent an email with a new temporary generated password and
        </br> will prompted to create a new password following their next log in attempt.`,
        type: "warning",
        choice: true,
      });

      if (confirmed) {
        try {
          await resetUserPassword(userEmail, currentUserPoolName);

          this.$showAlert({
            text: `Password reset sent successfully.`,
            type: "success",
            timeout: 3000,
          });
        } catch (error) {
          this.$showAlert({
            text: error,
            type: "error",
          });
        }
      }
    },
    async updateUserGroups(
      userEmail,
      currentUserPoolName,
      updatedGroups,
      originalUserGroups = null
    ) {
      try {
        const updatedUserGroups = updateUserGroups(
          userEmail,
          currentUserPoolName,
          updatedGroups
        );

        return updatedUserGroups;
      } catch (error) {
        this.selectedUser.groups = originalUserGroups;
        this.$showAlert({
          text: error,
          type: "error",
        });
      }
    },

    setNewUserDefaultValues() {
      this.newUserTemplate.groups.value.push(this.clientShortName);
    },

    closeNewUserDialog() {
      Object.values(this.newUserTemplate).forEach(
        (property) => (property.value = property.emptyValue)
      );
      this.newUserDialog = false;
    },
  },
  async created() {
    // populate userlist
    this.users = await this.getUserList();
  },
};
</script>

<style scoped>
.user-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.2rem;
  padding: 0px;
  margin: 1rem;
  border-radius: 4px;
  background-color: #e1e1e0;
  overflow: hidden;
}

.user-details-title {
  height: 5rem;
  margin: 1em 1em 0 2em;
  overflow: hidden;
}

.user-details-separator {
  border: dashed;
  border-width: thin;
  width: 80%;
  align-self: center;
}

.user-details {
  padding-left: 0rem;
  background-color: #e1e1e0;
}
.user-details-actions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 1rem 1rem 1rem;
  background-color: #e1e1e0;
}
</style>

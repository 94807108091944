var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","disabled":_vm.disabled},on:{"click":function($event){_vm.statsLayerOverlay = !_vm.statsLayerOverlay}}},on),[_c('v-icon',[_vm._v("mdi-numeric")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.disabled ? "No associated statistics" : "View layer statistics table")+" ")]),_c('v-dialog',{attrs:{"max-width":"1000","scrollable":""},model:{value:(_vm.statsLayerOverlay),callback:function ($$v) {_vm.statsLayerOverlay=$$v},expression:"statsLayerOverlay"}},[(
        (this.fullResolutionStatsAvailable ||
          this.lowResolutionStatsAvailable) &&
        this.groupFeaturesBy === 'none'
      )?_c('v-card',{staticClass:"pa-4 card-container",attrs:{"light":""}},[(!this.fullResolutionStatsAvailable)?_c('div',[_c('v-subheader',{staticClass:"px-4 pb-4"},[_vm._v("Note: Statistics shown are an approximation based on a lower resolution sample of the image. Actual statistics are now being calculated and should update within 5 - 30 mins depending on the size of the study area. ")]),_c('v-divider'),(this.statsAttempts > 15)?_c('v-subheader',{staticClass:"text--red px-4 pt-4"},[_vm._v("There seems to be an issue with calculating the statistics. Please check again later. If this message persists, contact an administrator. ")]):_vm._e()],1):_vm._e(),_c('v-card-text',{staticClass:"data-table-container px-0"},[_c('v-data-table',{attrs:{"fixed-header":"","height":"70vh","items-per-page":15,"headers":_vm.headers,"items":_vm.filteredFileStats ? _vm.filteredFileStats : _vm.preparedFileStats,"dense":""},scopedSlots:_vm._u([_vm._l((_vm.headers.filter((header) =>
              header.hasOwnProperty('formatter')
            )),function(header){return {key:`item.${header.value}`,fn:function({ item }){return [(header.hasOwnProperty('formatter'))?[_vm._v(" "+_vm._s(header.formatter(item[header.value]))+" ")]:[_vm._v(" "+_vm._s(item[header.value])+" ")]]}}})],null,true)})],1)],1):(
        this.featureStatsAvailable && this.groupFeaturesBy !== 'none'
      )?_c('v-card',{staticClass:"pa-4",attrs:{"light":""}},[_c('v-data-table',{attrs:{"items-per-page":15,"headers":_vm.featureStatsHeaders,"items":_vm.preparedFeatureStats}})],1):_c('v-card',{staticClass:"d-flex pa-2 justify-center align-center",attrs:{"light":"","flat":"","height":"350"}},[_c('v-card-title',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.statsMessage)+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-row class="d-flex justify-space-between align-center">
      <v-col cols="12" md="6">
        <div>
          <v-file-input
            accept=".csv"
            hint="Accepts: .csv"
            persistent-hint
            label="Table File"
            placeholder=""
            persistent-placeholder
            truncate-length="30"
            show-size
            v-model="file"
            @change="updateFileInfo"
          >
            <template v-slot:selection="{ text }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text-wrap text-subtitle-2"
                    v-bind="attrs"
                    v-on="on"
                    >{{ text }}</span
                  >
                </template>
                <span>{{ filename }}</span>
              </v-tooltip>
            </template>
          </v-file-input>
        </div>
      </v-col>

      <v-col cols="1" class="d-none d-md-block"
        ><v-divider vertical></v-divider
      ></v-col>
      <v-col cols="12" md="5">
        <div class="d-flex d-md-block justify-md-center">
          <v-text-field
            dense
            :disabled="!file"
            class="layer-name py-2 px-1"
            @change="updateFileInfo"
            v-model="tableOptions.indexColumn"
            placeholder="Optional"
            persistent-placeholder
            hint="Layer name from file"
          >
            <template v-slot:label>
              <div>
                Index Column
                <v-tooltip
                  left
                  content-class="tool-tip"
                  color="white"
                  max-width="300px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-bind="attrs" v-on="on">
                      mdi-information
                    </v-icon>
                  </template>
                  <v-card>
                    <v-card-title> Index Column </v-card-title>
                    <v-card-subtitle class="pt-2 text-subtitle-2">
                      If the contents within the table being imported will be
                      joined to a vector dataset or joined by another table, an
                      Index Column must be identified during import. The values
                      within the Index Column must be unique, such as an ID and
                      will be common between two or more datasets.
                    </v-card-subtitle>
                  </v-card>
                </v-tooltip>
              </div>
            </template>
          </v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col cols="12" md="10">
        <upload-file-form-date-selector
          :filename="filename"
          @update-date-range="updateDateRange"
        >
        </upload-file-form-date-selector>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UploadFileFormDateSelector from "@/components/datasets/UploadFileFormDateSelector.vue";
export default {
  name: "UploadFileFormTableFile",

  components: {
    UploadFileFormDateSelector,
  },

  data() {
    return {
      file: null,
      filename: null,
      dateRange: { start: "ind", end: "ind" },
      tableOptions: {
        indexColumn: null,
      },
    };
  },
  methods: {
    updateFileInfo() {
      this.file ? (this.filename = this.file.name) : (this.filename = null);

      const updatedFile = {
        file: this.file,
        filename: this.filename,
        dateRange: this.dateRange,
        tableOptions: this.tableOptions,
      };
      this.$emit("update-file", updatedFile);
    },
    updateDateRange(newDateRange) {
      if (newDateRange.start) {
        this.dateRange = newDateRange;
      } else {
        this.dateRange = { start: "ind", end: "ind" };
      }
      this.updateFileInfo();
    },
  },
};
</script>

<style scoped>
.tool-tip {
  opacity: 1 !important;
  padding: 0;
  color: white;
}
</style>

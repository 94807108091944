import { render, staticRenderFns } from "./DatasetPropertiesFiles.vue?vue&type=template&id=43104bea&scoped=true&"
import script from "./DatasetPropertiesFiles.vue?vue&type=script&lang=js&"
export * from "./DatasetPropertiesFiles.vue?vue&type=script&lang=js&"
import style0 from "./DatasetPropertiesFiles.vue?vue&type=style&index=0&id=43104bea&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43104bea",
  null
  
)

export default component.exports
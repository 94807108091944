<template>
  <div class="d-flex justify-center align-center pa-5" v-if="initializing">
    <v-progress-circular indeterminate :color="color"></v-progress-circular>
  </div>
  <div v-else>
    <filter-model-selector
      @update-model="update"
      :selection-mode="true"
    ></filter-model-selector>
  </div>
</template>

<script>
import FilterModelSelector from "@/components/data/FilterModelSelector.vue";

export default {
  name: "FilterModelMethod",

  props: { params: Object },

  components: {
    FilterModelSelector,
  },

  data() {
    return {
      initializing: true,
      modelParams: {},
    };
  },

  computed: {
    color() {
      return this.$store.state.settings.appColor;
    },
  },

  methods: {
    update() {
      this.$emit("submit-params", this.modelParams);
    },
  },

  beforeMount() {
    this.modelParams = { ...this.modelParams, ...this.params };
    this.update();
    setTimeout(() => (this.initializing = false), 100);
  },
};
</script>

<template>
  <div class="d-flex justify-center align-center pa-5" v-if="initializing">
    <v-progress-circular indeterminate :color="color"></v-progress-circular>
  </div>
  <div v-else>
    <v-text-field
      style="padding: 10px;"
      v-model="modelParams.radius"
      label="Radius (m)"
      :color="color"
      type="number"
      @input="update"
    ></v-text-field>

    <v-select
      style="padding: 10px;"
      v-model="modelParams.method"
      label="Statistical Method"
      :items="methods"
      :color="color"
      @input="update"
    ></v-select>

    <v-text-field
      :disabled="modelParams.method !== 'percentile'"
      style="padding: 10px;"
      v-model="modelParams.q"
      label="Percentile (0-100%)"
      hint="Note: q may also be populated using arbitrary expression syntax"
      :color="color"
      type="number"
      @input="update"
    ></v-text-field>

    <span v-if="message"  class="red--text">{{ `Warning: ${message}` }}</span>
  </div>
</template>

<script> 
export default {
  name: "MovingWindow",

  props: { params: Object },

  data() {
    return {
      initializing: true,
      modelParams: { radius: null, method: "mean", q: 25 },
      methods: [
        "mean",
        "min",
        "max",
        "sum",
        "std",
        "var",
        "range",
        "median",
        "percentile",
      ],
      message: null,
    };
  },

  computed: {
    color() {
      return this.$store.state.settings.appColor;
    },
  },

  methods: {
    update() {
      if (this.modelParams.method === "percentile") {
        if (this.modelParams.q < 0) {
          this.message = "q must be greater than 0";
          return;
        } else if (this.modelParams.q > 100) {
          this.message = "q must be less than 100";
          return;
        } else this.message = null
      }

      this.$emit("submit-params", this.modelParams);
    },
  },

  beforeMount() {
    this.modelParams = { ...this.modelParams, ...this.params };
    this.update();
    setTimeout(() => (this.initializing = false), 100);
  },
};
</script>

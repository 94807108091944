import {
  updateDatasetSymbology,
  deleteDatasetSymbology,
  getDataset,
  getTable,
  updateTableRow,
  deleteTableRow,
  getDateList,
  // getVirtualDatasetLayer,
} from "@/api/v2";
import {
  formatLayerDates,
  createExtraFiles,
  createTempLayerName,
  filesFromDataset,
  convertMapLayerToMask,
  flowSymbologyToTiTiler,
} from "@/api/mapping";

import { buildTileJsonURL } from "@/api/titiler";

import { getDatasetLayerAttributeTable } from "@/api/geoprocessing";

const state = {
  mbglToken: process.env.VUE_APP_MAPBOX_TOKEN,
  basemaps: [
    {
      title: "Streets",
      url: "mapbox://styles/mapbox/streets-v11",
    },
    {
      title: "Outdoors",
      url: "mapbox://styles/mapbox/outdoors-v11",
    },
    {
      title: "Light",
      url: "mapbox://styles/mapbox/light-v10",
    },
    {
      title: "Dark",
      url: "mapbox://styles/mapbox/dark-v10",
    },
    {
      title: "Satellite",
      url: "mapbox://styles/mapbox/satellite-v9",
    },
    {
      title: "Satellite Streets",
      url: "mapbox://styles/mapbox/satellite-streets-v11",
    },
    {
      title: "Navigation Day",
      url: "mapbox://styles/mapbox/navigation-day-v1",
    },
    {
      title: "Navigation Night",
      url: "mapbox://styles/mapbox/navigation-night-v1",
    },
  ],
  currentBasemap: {
    title: "Light",
    url: "mapbox://styles/mapbox/light-v10",
  },
  studyAreas: [],
  datasets: [],
  visibleDatasets: [],
  visibleLayers: [],
  visibleFeatures: [],
  chartData: [],
  tableData: [],
  tableEditorData: {},
  queryBuilderData: [],
  queryBuilderLayerMasks: [],
  mapTime: "",
  limitedMapTimeBounds: { min: null, max: null },
  datasetUpdated: null,
  singleDataset: null,

  // Store current id of layer that has opacity or other style properties being updated
  // Return to null when complete
  layerStyleUpdating: null,
  layerColorUpdating: null,
  layerFilterUpdating: null,
  layerQueryUpdating: null,
  zoomToExtent: null,
};

// check layerIDs within each loaded dataset for equality
// const equalsCheck = (a, b) =>
//   a.length === b.length && a.every((v, i) => v === b[i]);

const mutations = {
  changeBasemap(state, value) {
    state.currentBasemap = value;
  },

  setSingleDataset(state, dataset) {
    state.singleDataset = dataset;
  },

  clearSingleDataset(state) {
    state.singleDataset = null;
  },

  addDataset(state, dataset) {
    let alreadyAdded = false;
    state.datasets.forEach((ds) => {
      if (ds.key === dataset.key) {
        alreadyAdded = true;

        const alertMessage =
          dataset.mapLayerPurpose === "DatasetMapLayer"
            ? "Dataset has already been added, try selecting a different layer if applicable."
            : dataset.mapLayerPurpose === "StudyAreaMapLayer"
            ? "Study area has already been added, try selecting a different layer if applicable."
            : "Unknown";

        this.commit("alert/showAlert", {
          text: alertMessage,
          type: "error",
        });
      }
    });

    if (!alreadyAdded) {
      // dataset.visible = true;

      const newDatasets = [...state.datasets, dataset];
      state.datasets = newDatasets;

      this.commit(
        "mapping/toggleFlowLayerVisibility",
        { datasetKey: dataset.key, visibility: "visible" },
        {
          root: true,
        }
      );
    }
  },

  removeDataset(state, dataset) {
    this.commit(
      "mapping/toggleFlowLayerVisibility",
      { datasetKey: dataset.key, visibility: "none" },
      {
        root: true,
      }
    );

    this.commit("mapping/removeLayerFromChart", dataset, {
      root: true,
    });

    this.commit("mapping/removeLayerTableDrawer", dataset, {
      root: true,
    });

    const newDatasets = state.datasets.filter((ds) => {
      return !(ds.key === dataset.key);
    });
    state.datasets = newDatasets;
  },

  updateDatasetQueryLayer(state, dataset) {
    const currentShowingDataset = state.datasets.find(
      (ds) => ds.key === dataset.key
    );

    const datasetQueryLayer = state.queryBuilderData.find(
      (ds) => ds.originalLayer.key === dataset.key
    );

    // update visible map layer with queried layer
    Object.assign(currentShowingDataset, dataset);

    // update store version of query layer
    Object.assign(datasetQueryLayer.queryLayer, dataset);
  },

  setLimitedMapTimeBounds(state, allTargetLayerTimes) {
    // target layers from a query result may affect the upper and lower time bounds

    state.limitedMapTimeBounds.min = allTargetLayerTimes.min;
    state.limitedMapTimeBounds.max = allTargetLayerTimes.max;
  },

  toggleFlowLayerVisibility(state, datasetProperties) {
    const dataset = state.datasets.find(
      (ds) => ds.key === datasetProperties.datasetKey
    );

    dataset.visible = datasetProperties.visibility;

    if (dataset.visible === "visible")
      state.visibleDatasets.push(datasetProperties.datasetKey);
    else {
      const newVisibleDatasets = state.visibleDatasets.filter((key) => {
        return !(key === datasetProperties.datasetKey);
      });
      state.visibleDatasets = newVisibleDatasets;
    }
  },

  updateLayerFileVisibility(state, layerProperties) {
    const dataset = state.datasets.find(
      (dataset) => dataset.key === layerProperties.datasetKey
    );

    const layerFile = dataset.files.filter(
      (file) => file.mapLayer.id === layerProperties.layerID
    );

    layerFile[0].mapLayer.layout.visibility = layerProperties.visibility;
  },

  updateMaskLayerFileVisibility(state, layerProperties) {
    const dataset = state.queryBuilderLayerMasks.find(
      (dataset) => dataset.dataLayer.key === layerProperties.datasetKey
    );

    const layerFile = dataset.dataLayer.files.filter(
      (file) => file.mapLayer.id === layerProperties.layerID
    );

    layerFile[0].mapLayer.layout.visibility = layerProperties.visibility;
  },

  clearDatasets(state) {
    state.datasets = [];
  },

  updateLayerFileAttributeTables(state, updatedMapLayer) {
    const oldMapLayer = state.datasets.find(
      (ds) => ds.key === updatedMapLayer.key
    );

    for (let index = 0; index < oldMapLayer.files.length; index++) {
      const file = oldMapLayer.files[index];

      file.fileAttributeTable = updatedMapLayer.files[index].fileAttributeTable;
    }
  },

  updateLayerSymbology(state, updatedDataset) {
    const oldDataset = state.datasets.find(
      (ds) => ds.key === updatedDataset.key
    );

    Object.assign(oldDataset, updatedDataset);

    state.layerColorUpdating = updatedDataset;
  },

  updateDataset(state, updatedDataset) {
    const oldDataset = state.datasets.find(
      (ds) => ds.key === updatedDataset.key
    );

    if (oldDataset !== undefined) {
      Object.assign(oldDataset, updatedDataset);
    }
  },

  clearLayerColorUpdating(state) {
    state.layerColorUpdating = null;
  },

  // Sets updated opacity properties
  updateLayerOpacity(state, dataset) {
    state.datasets.forEach((ds) => {
      if (ds.key === dataset.key) {
        state.layerStyleUpdating = dataset.key;
        ds.styles.opacityValue = dataset.opacityValue;
      }
    });
  },

  // Updates rendered features
  updateLayerFeatures(state, { dataset, layerID, features }) {
    state.datasets.forEach((ds) => {
      if (ds.key === dataset.key) {
        ds.renderedFeatures = { ...ds.renderedFeatures, [layerID]: features };
      }
    });
  },

  // updates selected features
  updateLayerFeatureFilters(
    state,
    { dataset, selectedFeatures, groupByFilter }
  ) {
    state.datasets.forEach((ds) => {
      if (ds.key === dataset.key) {
        ds.visibleFeatures = selectedFeatures;
        ds.groupFeaturesBy = groupByFilter;
      }

      // update map filters
      state.layerFilterUpdating = ds;
    });
  },

  clearLayerFilterUpdating(state) {
    state.layerFilterUpdating = null;
  },

  // updates selected raster categories
  updateLayerRasterFilter(state, { dataset, unselectedCategories }) {
    state.datasets.forEach((ds) => {
      if (ds.key === dataset.key) {
        ds.hiddenCategories = unselectedCategories;

        for (let index = 0; index < dataset.files.length; index++) {
          const file = dataset.files[index];

          const originalColormap = Object.keys(
            file.colormap.flowCategoryStyle.Style.classes
          );

          const reducedColormap = originalColormap
            .filter(
              (categoryKey) => !unselectedCategories.includes(categoryKey)
            )
            .reduce((object, categoryKey) => {
              return Object.assign(object, {
                [categoryKey]:
                  file.colormap.flowCategoryStyle.Style.classes[categoryKey],
              });
            }, {});

          if (Object.keys(reducedColormap).length === 0)
            file.colormap.displayParams.colormap = JSON.stringify({
              0: [0, 0, 0, 0],
            });
          else
            file.colormap.displayParams.colormap = JSON.stringify(
              flowSymbologyToTiTiler(reducedColormap)
            );

          // build url query string
          const newParams = { ...file.colormap.displayParams };

          newParams.url = file.baseURL;

          const url = buildTileJsonURL(newParams);

          file.mapLayer.source.url = url;
        }

        // update map filters
        state.layerColorUpdating = ds;
      }
    });
  },

  clearLayerRasterFilterUpdating(state) {
    state.layerRasterFilterUpdating = null;
  },

  clearLayerStyleUpdating(state) {
    state.layerStyleUpdating = null;
  },

  updateDatasetSavedSymbology(state, symbology) {
    const dataset = state.datasets.find(
      (flowDataset) => flowDataset.dataset.DatasetName === symbology.DatasetName
    );

    // only need to update store state if dataset is loaded in a map
    if (typeof dataset !== "undefined")
      Object.assign(dataset.dataset.Symbology, symbology);
  },
  addLayerToChart(state, dataset) {
    const mapLayer = state.datasets.find((ds) => ds.key === dataset.key);

    state.chartData.push(mapLayer);
  },
  removeLayerFromChart(state, dataset) {
    const mapLayerIndex = state.chartData.findIndex(
      (ds) => ds.key === dataset.key
    );

    if (mapLayerIndex !== -1) {
      state.chartData.splice(mapLayerIndex, 1);
    }
  },

  addLayerToTableDrawer(state, dataset) {
    let alreadyAdded = false;

    const mapLayer = state.datasets.find((ds) => ds.key === dataset.key);

    state.tableData.forEach((ds) => {
      if (ds.key === dataset.key) {
        alreadyAdded = true;

        // update dataset in table array
        Object.assign(ds, mapLayer);
      }
    });

    // add dataset to table array
    if (!alreadyAdded) {
      setTimeout(() => {
        state.tableData.push(mapLayer);
      }, 300);
    }
  },
  removeLayerTableDrawer(state, dataset) {
    const mapLayerIndex = state.tableData.findIndex(
      (ds) => ds.key === dataset.key
    );

    if (mapLayerIndex !== -1) {
      state.tableData.splice(mapLayerIndex, 1);
      state.tableEditorData = {};
    }
  },

  addLayerToQueryBuilder(state, dataset) {
    const mapLayer = state.datasets.find((ds) => ds.key === dataset.key);

    state.queryBuilderData.push({
      originalLayer: structuredClone(mapLayer),
      queryLayer: {},
      targetLayers: [],
    });
  },
  removeLayerFromQueryBuilder(state, dataset) {
    const mapLayerIndex = state.queryBuilderData.findIndex(
      (ds) => ds.originalLayer.key === dataset.key
    );

    // remove from query builder
    if (mapLayerIndex !== -1) {
      // replace with original layer
      this.commit(
        "mapping/updateLayerSymbology",
        state.queryBuilderData[mapLayerIndex].originalLayer,
        {
          root: true,
        }
      );

      state.queryBuilderData.splice(mapLayerIndex, 1);
    }

    // destroying component does the trick.
    if (state.queryBuilderData.length === 0) {
      this.commit("mapping/clearAllQueryData");
    }
    this.commit("mapping/updateQueryRefreshTime", new Date());
  },

  buildQueryMasks(state, targetLayersValues) {
    this.commit("mapping/clearQueryMasks");

    if (targetLayersValues.length > 0) {
      // for each target layer, update existing map files and store layer
      const updatedTargetLayers = targetLayersValues.map((targetLayer) => {
        targetLayer.dataLayer = convertMapLayerToMask(targetLayer.dataLayer);
        return targetLayer;
      });

      targetLayersValues.forEach((targetLayer) => {
        this.commit("mapping/toggleFlowLayerVisibility", {
          datasetKey: targetLayer.dataLayer.key,
          visibility: "none",
        });
      });

      // toggle visibility of parent target map layer
      state.queryBuilderLayerMasks = updatedTargetLayers;
    }
  },

  // to be used when applying a new query or removing any layers from the dataviewer that are included in the query.
  clearQueryMasks(state) {
    state.queryBuilderLayerMasks = [];
  },

  clearAllQueryData(state) {
    state.queryBuilderData = [];
    state.queryBuilderLayerMasks = [];
    state.limitedMapTimeBounds.min = null;
    state.limitedMapTimeBounds.max = null;
    this.commit("mapping/updateQueryRefreshTime", new Date());
  },

  updateQueryRefreshTime(state, date) {
    state.layerQueryUpdating = date;
  },

  addRowToTableEditor(state, { table, tableHeaders, selectedRow }) {
    state.tableEditorData = { table, tableHeaders, selectedRow };
  },
  updateSelectedRow(state, selectedRow) {
    state.tableEditorData.selectedRow = selectedRow;
  },

  updateDatasetTable(state, refreshedTable) {
    const oldTable = state.tableData[0].associatedTableData.find(
      (t) => t.table.DatasetName === refreshedTable.DatasetName
    );

    Object.assign(oldTable.table, refreshedTable);
  },

  zoomToLayerExtent(state, dataset) {
    state.zoomToExtent = dataset === null ? null : dataset;
  },

  // removes all layers from map and chart
  removeAllLayers(state) {
    state.datasets.forEach((ds) => {
      this.commit("mapping/removeDataset", ds, {
        root: true,
      });
    });
  },
};
const getters = {
  // find mapLayer
  findMapLayer(state, dataset) {
    return state.datasets.find((ds) => ds.key === dataset.key);
  },
};

const actions = {
  async updateDatasetSymbology({ commit }, updatedSymbology) {
    try {
      const response = await updateDatasetSymbology(updatedSymbology);

      // throw error if response contains flow error
      if (response.Errors) {
        throw new Error(response.Errors);
      }

      commit("updateDatasetSavedSymbology", {
        updatedSymbology,
      });

      // show warning after list has been refreshed
      if (response.Warnings) {
        this.commit(
          "alert/showAlert",
          {
            text: response.Warnings,
            type: "warning",
          },
          { root: true }
        );
      }
      return response;
    } catch (error) {
      this.commit(
        "alert/showAlert",
        {
          text: error,
          type: "error",
        },
        { root: true }
      );
    }
  },
  async deleteDatasetSymbology({ commit }, symbology) {
    try {
      const response = await deleteDatasetSymbology(symbology);

      // throw error if response contains flow error
      if (response.Errors) {
        throw response.Errors;
      }

      commit("updateDatasetSavedSymbology", symbology);

      // show warning after list has been refreshed
      if (response.Warnings) {
        this.commit(
          "alert/showAlert",
          {
            text: response.Warnings,
            type: "warning",
          },
          { root: true }
        );
      }
      return response;
    } catch (error) {
      this.commit(
        "alert/showAlert",
        {
          text: error,
          type: "error",
        },
        { root: true }
      );
    }
  },

  async getSingleDataset({ commit }, datasetInfo) {
    try {
      const dataset = await getDataset(
        datasetInfo.datasetName,
        datasetInfo.datasetOwner
      );

      // throw error if response contains flow error
      if (dataset.Errors) {
        throw dataset.Errors;
      }

      commit("setSingleDataset", dataset);

      // show warning after list has been refreshed
      if (dataset.Warnings) {
        this.commit(
          "alert/showAlert",
          {
            text: dataset.Warnings,
            type: "warning",
          },
          { root: true }
        );
      }
      return dataset;
    } catch (error) {
      this.commit(
        "alert/showAlert",
        {
          text: error,
          type: "error",
        },
        { root: true }
      );
    }
  },
  async getAssociatedTables({ commit }, mapLayer) {
    const associatedTables = mapLayer.layer.AssociatedTables;

    // // add dataset to table array to populate the drawer
    // mapLayer.associatedTableData = null;
    // commit("addLayerToTableDrawer", mapLayer);

    const getTables = async (associatedTables) => {
      const tables = await Promise.allSettled(
        associatedTables.map(async (tableDetails) => {
          const tableDataset = await getDataset(
            tableDetails.DatasetName,
            tableDetails.DatasetOwner
          );

          const findFileId = (files, mapLayerStartDate) =>
            files.find((file) => file.StartDate === mapLayerStartDate).Files[0]
              .TablePK;

          const tableFileId = findFileId(
            tableDataset.Files,
            mapLayer.layer.StartDate
          );

          const table = await getTable(
            tableDataset.DatasetName,
            tableDataset.DatasetOwner,
            tableFileId,
            false
          );

          return { table: table, tableDataset: tableDataset };
        })
      );
      return tables;
    };

    try {
      const tables = await getTables(associatedTables);

      const removeRejected = (tables) =>
        tables.reduce(function (filtered, table) {
          if (table.status === "fulfilled") {
            const mappedTable = table.value;
            filtered.push(mappedTable);
          }
          return filtered;
        }, []);

      // update associatedTableData with getTable responses.
      mapLayer.associatedTableData = removeRejected(tables);
      // mapLayer.associatedTableDatasets = tables.tableDatasets;

      // re-add dataset to update the data in the table drawer
      commit("addLayerToTableDrawer", mapLayer);
    } catch (error) {
      console.error(error);
    }
  },

  async getFeatureAttributeTables({ commit }, { mapLayer, studyAreaInfo }) {
    const mapLayerCopy = structuredClone(mapLayer);

    const getTables = async (mapLayerCopy, studyAreaInfo) => {
      await Promise.all(
        mapLayerCopy.files.map(async (file) => {
          const datasetInfo = mapLayerCopy.layer;
          const startDate = file.time.start;

          if (startDate !== "ind") {
            datasetInfo.date = startDate;
          }

          const attributeTable = await getDatasetLayerAttributeTable(
            datasetInfo,
            studyAreaInfo,
            23,
            6
          );

          attributeTable.Date = datasetInfo.date;

          const formattedTable = (attributeTable) => {
            const formattedRows = [];

            for (const row of attributeTable.Rows) {
              const formattedColumns = {};

              for (let i = 0; i < attributeTable.Columns.length; i++) {
                const columnName = attributeTable.Columns[i];
                const rowValue = row[i];

                formattedColumns[columnName] = rowValue;
              }

              formattedRows.push(formattedColumns);
            }

            return formattedRows;
          };

          attributeTable.formattedTable = formattedTable(attributeTable);

          file.fileAttributeTable = attributeTable;
        })
      );
    };

    await getTables(mapLayerCopy, studyAreaInfo);

    commit("updateLayerFileAttributeTables", mapLayerCopy);
  },

  async updateTable({ commit }, newDataInfo) {
    try {
      const updatedTable = await updateTableRow(
        newDataInfo.tableInformation,
        Object.values(newDataInfo.newRowData)
      );

      // throw error if response contains flow error
      if (updatedTable.Errors) {
        throw new Error(updatedTable.Errors);
      }

      // show warning after list has been refreshed
      if (updatedTable.Warnings) {
        this.commit(
          "alert/showAlert",
          {
            text: updatedTable.Warnings,
            type: "warning",
          },
          { root: true }
        );
      }

      const refreshedTable = await getTable(
        newDataInfo.tableInformation.DatasetName,
        newDataInfo.tableInformation.DatasetOwner,
        newDataInfo.tableInformation.FileId,
        false
      );

      // throw error if response contains flow error
      if (refreshedTable.Errors) {
        throw new Error(refreshedTable.Errors);
      }

      commit("updateDatasetTable", refreshedTable);

      commit("updateSelectedRow", newDataInfo.newRowData);

      // show warning after list has been refreshed
      if (refreshedTable.Warnings) {
        this.commit(
          "alert/showAlert",
          {
            text: refreshedTable.Warnings,
            type: "warning",
          },
          { root: true }
        );
      }

      return refreshedTable;
      // const seasonUpdated = commit("updateSeason", updatedSeason);
    } catch (error) {
      this.commit(
        "alert/showAlert",
        {
          text: error,
          type: "error",
        },
        { root: true }
      );
    }
  },
  async deleteTableRow({ commit }, rowDataInfo) {
    try {
      const deletedRow = await deleteTableRow(
        rowDataInfo.tableInformation,
        rowDataInfo.index
      );

      // throw error if response contains flow error
      if (deletedRow.Errors) {
        throw new Error(deletedRow.Errors);
      }

      // show warning after list has been refreshed
      if (deletedRow.Warnings) {
        this.commit(
          "alert/showAlert",
          {
            text: deletedRow.Warnings,
            type: "warning",
          },
          { root: true }
        );
      }

      const refreshedTable = await getTable(
        rowDataInfo.tableInformation.DatasetName,
        rowDataInfo.tableInformation.DatasetOwner,
        rowDataInfo.tableInformation.FileId,
        false
      );

      // throw error if response contains flow error
      if (refreshedTable.Errors) {
        throw new Error(refreshedTable.Errors);
      }

      commit("updateDatasetTable", refreshedTable);

      commit("updateSelectedRow", null);

      // show warning after list has been refreshed
      if (refreshedTable.Warnings) {
        this.commit(
          "alert/showAlert",
          {
            text: refreshedTable.Warnings,
            type: "warning",
          },
          { root: true }
        );
      }

      return refreshedTable;
      // const seasonUpdated = commit("updateSeason", updatedSeason);
    } catch (error) {
      this.commit(
        "alert/showAlert",
        {
          text: error,
          type: "error",
        },
        { root: true }
      );
    }
  },

  async addDataset({ commit }, details) {
    try {
      const {
        selectedDataset,
        selectedLayer,
        mapExtent,
        studyAreas,
        layerMasks,
        layerContext,
        allowFeatureSelection,
      } = details;

      if (
        selectedLayer.StartDate === "ind" ||
        selectedLayer.EndDate === "ind"
      ) {
        selectedLayer.Dates = null;
      }

      // don't create dates if time invariant or formatted dates array already exists
      if (!selectedLayer.Dates && selectedLayer.Timestep) {
        try {
          const newDateList = await getDateList(
            selectedLayer.StartDate,
            selectedLayer.EndDate,
            selectedLayer.Timestep
          );

          selectedLayer.Dates = newDateList.Dates;
          selectedLayer.DefaultEndDate = newDateList.DefaultEndDate;
        } catch (error) {
          console.error(error);
        }
      }

      // add end date to layer dates
      const formattedLayerDates = formatLayerDates(
        selectedLayer.Dates,
        selectedLayer.EndDate
          ? selectedLayer.EndDate
          : selectedLayer.DefaultEndDate
      );

      // update Dataset.Files to match layer dates or create a copy of a file and duplicate if there is a query for a vector dataset
      selectedDataset.Files = createExtraFiles(
        selectedDataset,
        selectedLayer,
        formattedLayerDates
      );

      selectedDataset.Style.DisplayName = createTempLayerName(
        selectedDataset,
        selectedLayer
      );

      // run this for each study area
      const preparedFiles = await filesFromDataset(
        selectedDataset,
        selectedLayer,
        studyAreas,
        layerContext,
        layerMasks,
        mapExtent,
        allowFeatureSelection
      );

      // throw error if entire dataset is no data
      if (preparedFiles.preparedDatasets.length === 0) {
        const error = new Error();

        error.name = "no valid data";

        error.response = {
          status: null,
          data: {
            Errors:
              "All dataset years contained no valid data and will not be displayed",
            Warnings: null,
          },
        };
      }

      const dataset = {
        dataset: selectedDataset,
        files: preparedFiles.preparedDatasets,
        mask: preparedFiles.preparedStudyAreaMask,
        layer: selectedLayer,
        styles: {
          layerIDs: preparedFiles.preparedDatasets.map(
            (files) => files.mapLayer.id
          ),
          opacityType: preparedFiles.preparedDatasets[0].mapLayer.type,
          opacityValue:
            preparedFiles.preparedDatasets[0].mapLayer.paint["fill-opacity"],
        },
        groupFeaturesBy: null,
        visibleFeatures: [],
        hiddenCategories: [],
      };

      // add a unique key
      // dataset.key = `${dataset.DatasetName} + ${dataset.styles.layerIDs}`;
      dataset.key = `${dataset.layer.DatasetName} + ${dataset.layer.LayerName}`;

      dataset.mapLayerPurpose = layerContext;
      commit("addDataset", dataset);
    } catch (error) {
      this.commit("alert/showErrorAlert", error, { root: true });
    }
  },

  async buildQuery({ commit }, details) {
    const { queryLayerDetails, targetLayers } = details;

    const { layerMasks } = queryLayerDetails;

    // if the query layer is time invariant and the target layers have associated time, the user should select a time to associate the output with or just notify that the output will be snapped to the most recent date for each target layer.
    if (
      !queryLayerDetails.selectedLayer.Timestep &&
      layerMasks.some((target) => target.layer.Timestep)
    ) {
      layerMasks.forEach((target) => {
        if (target.layer.Timestep) {
          // grab newest year from sorted dates array
          const mostRecentYear =
            target.layer.Dates.Dates[target.layer.Dates.Dates.length - 1];

          // const foundRecentYear = layer.dataLayer.files[2].time.start
          target.files = target.files.filter(
            (file) => file.time.start === mostRecentYear
          );
        }
      });
    }

    try {
      const {
        selectedDataset,
        selectedLayer,
        mapExtent,
        studyAreas,
        layerMasks,
        layerContext,
        allowFeatureSelection,
      } = queryLayerDetails;

      const preparedFiles = await filesFromDataset(
        selectedDataset,
        selectedLayer,
        studyAreas,
        layerContext,
        layerMasks,
        mapExtent,
        allowFeatureSelection
      );

      // throw error if entire dataset is no data
      if (preparedFiles.preparedDatasets.length === 0) {
        const error = new Error();

        error.name = "no valid data";

        error.response = {
          status: null,
          data: {
            Errors:
              "All dataset years contained no valid data and will not be displayed",
            Warnings: null,
          },
        };
      }

      const dataset = {
        dataset: selectedDataset,
        files: preparedFiles.preparedDatasets,
        mask: preparedFiles.preparedGeoJSONMask,
        layer: selectedLayer,
        styles: {
          layerIDs: preparedFiles.preparedDatasets.map(
            (files) => files.mapLayer.id
          ),
          opacityType: preparedFiles.preparedDatasets[0].mapLayer.type,
          opacityValue:
            preparedFiles.preparedDatasets[0].mapLayer.paint["fill-opacity"],
        },
        groupFeaturesBy: null,
        visibleFeatures: [],
        hiddenCategories: [],
      };

      // add a unique key
      // dataset.key = `${dataset.DatasetName} + ${dataset.styles.layerIDs}`;
      dataset.key = `${dataset.layer.DatasetName} + ${dataset.layer.LayerName}`;

      dataset.mapLayerPurpose = layerContext;

      commit("buildQueryMasks", targetLayers);
      commit("updateDatasetQueryLayer", dataset);
      commit("updateLayerSymbology", dataset);
      commit("updateQueryRefreshTime", new Date());
    } catch (error) {
      // const error = new Error();

      error.name = "no valid data";

      error.response = {
        status: null,
        data: {
          Errors:
            "There was an issue building the query, please try again later.",
          Warnings: null,
        },
      };

      this.commit("alert/showErrorAlert", error, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

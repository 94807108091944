<template>
  <v-overlay style="z-index: 9999">
    <v-card class="alert-container">
      <div class="d-flex pa-3 justify-center">
        <v-icon class="text-lg-center" large :color="alertColor">{{
          alertIcon
        }}</v-icon>
      </div>
      <v-card-text class="text-center" v-html="alertText"></v-card-text>
      <div class="d-flex pa-3 justify-center">
        <div v-if="newAlert.choice">
          <v-btn
            color="success"
            text
            @click="$store.commit('alert/alertConfirm', true)"
            >Yes</v-btn
          >
          <v-btn
            color="error"
            text
            @click="$store.commit('alert/alertConfirm', false)"
            >No</v-btn
          >
        </div>
        <v-btn
          v-else-if="!newAlert.timeout"
          text
          @click="$store.commit('alert/showAlert', null)"
        >
          ok
        </v-btn>
      </div>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  name: "Alert",

  computed: {
    newAlert() {
      return this.$store.state.alert.alert;
    },
    alertIcon() {
      return this.newAlert.icon
        ? this.newAlert.icon
        : this.newAlert.type === "error"
        ? "mdi-close-circle-outline"
        : this.newAlert.type === "warning"
        ? "mdi-alert-outline"
        : "mdi-check-circle-outline";
    },
    alertColor() {
      return this.newAlert.type;
    },
    alertText() {
      let alert = this.newAlert.text;
      if (alert.message) alert = alert.message;
      return alert.replace("\n", "<br>").slice(0, 2000);
    },
  },
};
</script>

<style scoped>
.alert-container {
  max-width: 1000px;
}
</style>

<template>
  <div id="legend" class="pa-3 ma-0" v-if="currentDatasets.length">
    <v-card light flat class="pa-2">
      <v-row justify="center">
        <v-expansion-panels multiple accordion>
          <v-expansion-panel
            class="legend-item"
            v-for="(dataset, index) in currentDatasets"
            :key="index"
          >
            <v-expansion-panel-header class="legend-header pa-1">
              <v-card-subtitle class="legend-title subtitle-1 pa-1">{{
                dataset.dataset.Style.DisplayName
              }}</v-card-subtitle>
              <v-card-subtitle class="legend-subtitle subtitle-1 pa-1"
                ><strong>Units: </strong
                >{{ dataset.layer.Units }}</v-card-subtitle
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content class="legend-content">
              <MapDataViewerLegendItem
                v-if="(dataset, index) in currentDatasets"
                :key="dataset.dataset.Style.DisplayName"
                :dataset="dataset"
              >
              </MapDataViewerLegendItem>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import MapDataViewerLegendItem from "@/components/mapping/MapDataViewerLegendItem.vue";

export default {
  components: {
    MapDataViewerLegendItem,
  },

  data() {
    return {
      itemIndex: null,
    };
  },

  computed: {
    currentDatasets() {
      return this.$store.state.mapping.datasets.filter((dataset) => {
        return (
          dataset.dataset.DatasetType === "Raster" ||
          (dataset.dataset.DatasetType === "Vector" &&
            dataset.mapLayerPurpose !== "StudyAreaMapLayer")
        );
      });
    },
  },
};
</script>

<style scoped>
.legend-title {
  width: 60%;
  font-size: 0.875rem !important;
  font-weight: 500;
  line-height: 1.1rem;
}

.legend-subtitle {
  width: 40%;
  font-size: 0.775rem !important;
  color: rgba(0, 0, 0, 0.6);
  line-height: 1.1rem;
}

.legend-content {
  justify-content: space-between;
}
</style>

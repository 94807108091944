<template>
  <div>
    <v-divider></v-divider>
    <v-expansion-panels v-if="simulation.Runs" accordion>
      <v-expansion-panel
        v-for="(scenario, index) in sortedRuns"
        :key="scenario.ScenarioName"
        :index="index"
      >
        <v-expansion-panel-header class="d-flex justify-space-between">
          <h3>
            <v-icon class="px-1" :color="checkStatus(scenario.Status)"
              >mdi-circle-medium</v-icon
            >
            {{ scenario.ScenarioName }}
            <span class="text-caption">
              - {{ formatDateString(scenario.CreatedAt) }}</span
            >
          </h3>
          <div
            v-if="canCancel(scenario.Status)"
            class="d-flex justify-end pr-8"
          >
            <v-btn
              text
              x-small
              color="error"
              @click.stop="cancelScenario(scenario)"
            >
              cancel</v-btn
            >
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Requested by</v-list-item-subtitle>
                <v-list-item-title>{{ scenario.RunOwner }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Requested at</v-list-item-subtitle>
                <v-list-item-title>{{ scenario.CreatedAt }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="scenario.RuntimeSeconds != null">
              <v-list-item-content>
                <v-list-item-subtitle>Execution time</v-list-item-subtitle>
                <v-list-item-title>{{
                  `${scenario.RuntimeSeconds} seconds`
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="scenario.SupportCode != null">
              <v-list-item-content>
                <v-list-item-subtitle>Support Code</v-list-item-subtitle>
                <v-list-item-title>
                  <pre>{{ scenario.SupportCode }}</pre>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Output dataset</v-list-item-subtitle>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        scenario.OutputDataset.DatasetName
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        scenario.OutputDataset.DatasetOwner
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="scenario.CachedDatasets.length > 0">
              <v-list-item-content>
                <v-list-item-subtitle
                  >Datasets from nested expression</v-list-item-subtitle
                >
                <v-list>
                  <v-list-item
                    v-for="item in sortedCachedDatasets(
                      scenario.CachedDatasets
                    )"
                    :key="item"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ item }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        scenario.ProjectOwner
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  Status
                  <v-btn
                    @click="updateStatus(scenario)"
                    icon
                    :loading="statusLoading"
                    :disabled="!canCancel(scenario.Status)"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </v-list-item-subtitle>
                <v-stepper
                  alt-labels
                  flat
                  :value="getStatusIndex(scenario.Status)"
                >
                  <v-stepper-header>
                    <v-stepper-step
                      step="1"
                      :complete="getStatusIndex(scenario.Status) > 1"
                    >
                      Provisioning
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                      step="2"
                      :complete="getStatusIndex(scenario.Status) > 2"
                      :rules="[() => scenario.Status !== 'Failed']"
                      error-icon="mdi-alert-octagon"
                    >
                      In Progress
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                      step="3"
                      :complete="getStatusIndex(scenario.Status) >= 3"
                      :rules="[() => scenario.Status !== 'Failed']"
                      error-icon="mdi-close-circle"
                      :color="checkStatus(scenario.Status)"
                    >
                      {{ scenario.Status === "Failed" ? "Failed" : "Done" }}
                    </v-stepper-step>
                  </v-stepper-header>
                </v-stepper>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle style="margin-bottom: 1em"
                  >Progress</v-list-item-subtitle
                >

                <v-progress-linear :value="scenario.Progress" height="25">
                  <strong>{{ Math.round(scenario.Progress) }}%</strong>
                </v-progress-linear>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle> Scenario </v-list-item-subtitle>
                <pre>{{ JSON.stringify(scenario.Scenario, null, 4) }}</pre>
              </v-list-item-content>
            </v-list-item>

            <v-divider style="margin-top: 1em"></v-divider>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  Log
                  <v-btn
                    @click="retrieveLog(scenario, index)"
                    icon
                    :loading="logLoading"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </v-list-item-subtitle>
                <pre>{{
                  !scenario.tempLog
                    ? "Click the refresh button to load the log file"
                    : scenario.tempLog
                }}</pre>
              </v-list-item-content>
            </v-list-item>

            <v-divider style="margin-top: 1em"></v-divider>

            <v-subheader>
              Danger zone
              <v-btn icon @click="expandDangerZone = !expandDangerZone">
                <v-icon>{{
                  expandDangerZone ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-subheader>

            <v-list-item v-show="expandDangerZone">
              <v-list-item-content>
                <v-btn
                  :disabled="canCancel(scenario.Status)"
                  color="error"
                  @click="deleteScenario(scenario)"
                >
                  <v-icon left>mdi-delete</v-icon> Delete
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-else>
      <span>This simulation has not been run</span>
    </div>
    <v-divider></v-divider>
  </div>
</template>

<script>
import {
  getRunLog,
  getRunStatus,
  deleteSimulationRun,
  cancelSimulationRun,
  formatDateString,
} from "@/api/v2";

export default {
  name: "SimulationRuns",

  props: {
    simulation: Object,
  },

  data() {
    return {
      expandDangerZone: false,
      statusLoading: false,
      logLoading: false,
    };
  },

  computed: {
    // sorts copy of simulation runs
    sortedRuns() {
      if (this.simulation && this.simulation.Runs) {
        let runs = [...this.simulation.Runs];
        runs.sort((a, b) => new Date(a.CreatedAt) - new Date(b.CreatedAt));
        return runs;
      } else {
        return null;
      }
    },
  },

  methods: {
    formatDateString,

    sortedCachedDatasets(datasets) {
      let sortedDatasets = [...datasets];
      sortedDatasets.sort((a, b) => a > b);
      return sortedDatasets;
    },

    checkStatus(status) {
      if (status === "Done") {
        return "success";
      } else if (status === "Failed") {
        return "red";
      } else if (status === "In Progress") {
        return "blue";
      } else if (status === "Provisioning") {
        return "blue";
      } else {
        return "yellow";
      }
    },

    getStatusIndex(status) {
      if (status === null) {
        return 0;
      } else if (status === "Provisioning") {
        return 1;
      } else if (status === "In Progress") {
        return 2;
      } else {
        return 3;
      }
    },

    canCancel(status) {
      if (
        status === "Provisioning" ||
        status === "In Progress" ||
        status === "Pending"
      ) {
        return true;
      } else {
        false;
      }
    },

    deleteScenario(scenario) {
      this.$showAlert({
        text: `This also deletes the output dataset associated with the scenario.
        <br> <b>Are you sure you want to delete this scenario, this cannot be undone?</b>
        <br>  - ${scenario.ScenarioName}`,
        type: "warning",
        choice: true,
      }).then((confirmed) => {
        if (confirmed) {
          const projectOwner = scenario.ProjectOwner;
          const projectName = scenario.ProjectName;

          this.$showLoadingOverlay({
            title: "Deleting, please wait.",
            updateMessage: `Deleting <b>${scenario.ScenarioName}<b>`,
          });
          deleteSimulationRun(
            projectName,
            projectOwner,
            scenario.ExpressionName,
            scenario.RunOwner,
            scenario.ScenarioName
          )
            .then((response) => {
              if (response.Warnings) {
                this.$showAlert({ text: response.Warnings, type: "warning" });
              }
              this.$store.dispatch("project/loadProject", {
                projectOwner,
                projectName,
              });
              this.$store.dispatch("accountData/refreshDatasetList");
            })
            .catch((error) => {
              let errorMessage = error;
              if (error.response) {
                errorMessage = error.response.data.Errors;
              }
              this.$showAlert({ text: errorMessage, type: "error" });
            })
            .finally(() => {
              this.$nextTick(() => {
                this.$showLoadingOverlay();

                // re-load project anyways to remove scenario if there was a 502 network error
                this.$store.dispatch("project/loadProject", {
                  projectOwner,
                  projectName,
                });
              });
            });
        }
      });
    },

    cancelScenario(scenario) {
      const projectOwner = scenario.ProjectOwner;
      const projectName = scenario.ProjectName;

      this.$showAlert({
        text: `Are you sure you want to cancel this scenario run?
        <br>  - ${scenario.ScenarioName}`,
        type: "warning",
        choice: true,
      }).then((confirmed) => {
        if (confirmed) {
          this.$showLoadingOverlay({
            title: "Cancelling scenario run, please wait.",
            updateMessage: `Cancelling <strong>${scenario.ScenarioName}<strong>`,
          });

          const operation = "Cancel";
          cancelSimulationRun(
            projectName,
            projectOwner,
            scenario.ExpressionName,
            scenario.RunOwner,
            scenario.ScenarioName,
            operation
          )
            .then((response) => {
              if (response.Warnings) {
                this.$showAlert({ text: response.Warnings, type: "warning" });
              }
              // Updates local state only
              const newScenarioStatus = {
                statusDetails: {
                  Status: response.Status,
                  Progress: response.Progress,
                },
              };

              let updatedScenario = {
                ...scenario,
                ...newScenarioStatus.statusDetails,
              };

              let update = this.simulation.Runs.map((run) => {
                if (run.ScenarioName === updatedScenario.ScenarioName) {
                  run = updatedScenario;
                  return run;
                }
                return run;
              });
              this.simulation.Runs = update;
            })
            .catch((error) => {
              let errorMessage = error;
              if (error.response) {
                errorMessage = error.response.data.Errors;
              }
              this.$showAlert({ text: errorMessage, type: "error" });
            })
            .finally(() => {
              this.$nextTick(() => {
                this.$showLoadingOverlay();
              });
            });
        }
      });
    },

    updateStatus(scenario) {
      let projectOwner = scenario.ProjectOwner;
      let projectName = scenario.ProjectName;
      let expressionName = scenario.ExpressionName;
      let runOwner = scenario.RunOwner;
      let scenarioName = scenario.ScenarioName;
      // prevent api calls if done or failed
      if (scenario.Status === "Done" || scenario.Status === "Failed") {
        return;
      } else {
        this.statusLoading = true;
        getRunStatus(
          projectName,
          projectOwner,
          expressionName,
          runOwner,
          scenarioName
        )
          .then((response) => {
            if (!response.Warnings) {
              // Updates local state only
              const newScenarioStatus = {
                statusDetails: {
                  Status: response.Status,
                  Progress: response.Progress,
                },
              };

              let updatedScenario = {
                ...scenario,
                ...newScenarioStatus.statusDetails,
              };

              let update = this.simulation.Runs.map((run) => {
                if (run.ScenarioName === updatedScenario.ScenarioName) {
                  run = updatedScenario;
                  return run;
                }
                return run;
              });
              this.simulation.Runs = update;

              // refresh dataset list when status is returned "Done"
              if (response.Status === "Done") {
                this.$store.dispatch("accountData/refreshDatasetList");
              }
            }
          })
          .catch((error) => {
            let errorMessage = error;
            if (error.response) {
              errorMessage = error.response.data.Errors;
            }
            this.$showAlert({ text: errorMessage, type: "error" });
          })
          .finally(() => {
            this.statusLoading = false;
          });
      }
    },

    retrieveLog(scenario) {
      let projectOwner = scenario.ProjectOwner;
      let projectName = scenario.ProjectName;
      let expressionName = scenario.ExpressionName;
      let runOwner = scenario.RunOwner;
      let scenarioName = scenario.ScenarioName;
      this.logLoading = true;

      getRunLog(
        projectName,
        projectOwner,
        expressionName,
        runOwner,
        scenarioName
      )
        .then((response) => {
          if (response.Warnings) {
            this.$showAlert({ text: response.Warnings, type: "warning" });
          }

          const newScenarioLog = {
            statusDetails: {
              tempLog: response.Log,
            },
          };

          let updatedScenario = {
            ...scenario,
            ...newScenarioLog.statusDetails,
          };

          let update = this.simulation.Runs.map((run) => {
            if (run.ScenarioName === updatedScenario.ScenarioName) {
              run = updatedScenario;
              return run;
            }
            return run;
          });
          this.simulation.Runs = update;
        })
        .catch((error) => {
          let errorMessage = error;
          if (error.response) {
            errorMessage = error.response.data.Errors;
          }
          this.$showAlert({ text: errorMessage, type: "error" });
        })
        .finally(() => {
          this.logLoading = false;
        });
    },
  },
};
</script>

<style scoped>
pre {
  max-width: 50vw;
  margin-top: 1em;
  max-height: 40vh;
  overflow: auto;
  font-size: small;
}
</style>

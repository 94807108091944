<template>
  <v-card
    flat
    class="data-drawer d-flex flex-column flex-grow-1"
    min-height="100%"
  >
    <v-card-title class="py-2">
      <div v-if="dataPanelOpen">
        <v-btn icon @click="$emit('close-data-panel')">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
    </v-card-title>

    <v-divider></v-divider>

    <data-chart
      :class="{ 'chart-baseline': !chartPopup, 'chart-popup': chartPopup }"
      @chart-popup-overlay="chartPopup = !chartPopup"
    ></data-chart>

    <v-divider></v-divider>
    <map-data-viewer-legend></map-data-viewer-legend>
  </v-card>
</template>

<script>
import DataChart from "@/components/mapping/DataChart.vue";
import MapDataViewerLegend from "@/components/mapping/MapDataViewerLegend.vue";

export default {
  name: "DataDrawer",

  components: {
    DataChart,
    MapDataViewerLegend,
  },

  props: {
    dataPanelOpen: { type: Boolean, default: false },
  },

  data() {
    return {
      chartPopup: false,
    };
  },

  computed: {
    datasets() {
      return this.$store.state.mapping.datasets;
    },
  },

  watch: {},
};
</script>

<style scoped>
.data-drawer {
  background-color: #7f8e98;
  border-left: 2px solid black;
}

.chart-baseline {
  width: initial !important;
}

/* .chart-popup {
  width: calc(100vw - 700px);
  position: absolute;
  overflow: auto;
  right: 595px;
} */

.chart-popup {
  position: fixed;
  z-index: 999;
  top: 20%;
  left: 25%;
  width: 800px;
  margin-left: -150px;
  overflow: auto;
}

/* media breakpoint for large desktop screens */
@media (min-width: 2100px) {
  .chart-popup {
    width: calc(100vw - 1000px);
    right: 795px;
  }
}
</style>

<template>
  <v-card class="content-container">
    <v-card-text class="content-container">
      <div class="query-builder-section">
        <div class="query-layer-section">
          <p class="text-caption">
            Query Layer - Raster map layer that you wish to identify the
            location of land cover types based on conditions being met with a
            vector based target layer. The query layer can also have it’s own
            filter condition applied.
          </p>

          <query-builder-row
            :query-map-layer="queryLayer"
            @values-updated="updateQueryLayer"
          >
          </query-builder-row>
          <p
            v-if="!queryLayer.originalLayer.layer.Timestep"
            class="text-caption font-weight-medium"
          >
            Note: Since there is no time associated with this query layer, if
            applicable, the most recent time associated with the target layer
            will be used for calculations.
          </p>
        </div>

        <div class="target-layer-section">
          <p class="text-caption">
            Target Layer - A loaded vector map layer to be used as a test
            condition.
          </p>

          <query-builder-row
            v-if="availableTargetLayers.length > 0"
            :target-map-layer-options="availableTargetLayers"
            @values-updated="updateTargetQueryLayers"
          >
          </query-builder-row>
          <p v-else class="text-caption font-weight-medium">
            No eligible target layers available.
          </p>
        </div>
      </div>
    </v-card-text>
    <v-divider class="mx-4"></v-divider>

    <v-card-actions class="d-flex">
      <!-- actions -->
      <v-spacer></v-spacer>
      <div class="action-section">
        <v-btn
          text
          color="success"
          class="text-capitalize"
          @click="applyQuery"
          :loading="queryLoading"
          >Apply Query</v-btn
        >
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import QueryBuilderRow from "@/components/mapping/QueryBuilderRow.vue";

export default {
  components: {
    QueryBuilderRow,
  },

  props: {
    queryLayer: {
      type: Object,
    },
  },

  data() {
    return {
      queryComponents: {
        operators: [
          {
            title: "=",
            value: "==",
            dataTypes: ["Categorical", "Quantitative", "Mask"],
          },
          {
            title: "<",
            value: "<",
            dataTypes: ["Quantitative"],
          },
          { title: ">", value: ">", dataTypes: ["Quantitative"] },
          { title: "<=", value: ">=", dataTypes: ["Quantitative"] },
          {
            title: "!=",
            value: "!=",
            dataTypes: ["Categorical", "Quantitative", "Mask"],
          },
        ],
        connectors: [
          { title: "AND", value: "and" },
          { title: "OR", value: "|" },
        ],
        rasterLayer: {},
        vectorLayer: {},

        field: [],

        value: {},
      },
      targetLayerCount: 1,
      updatedQueryLayer: null,
      targetLayers: [],
      queryLoading: false,
    };
  },

  computed: {
    availableTargetLayers() {
      const layers = this.$store.state.mapping.datasets.filter(
        (dataset) =>
          dataset.key !== this.queryLayer.originalLayer.key &&
          dataset.dataset.DatasetType !== "Raster" &&
          dataset.mapLayerPurpose !== "StudyAreaMapLayer"
      );

      return structuredClone(layers);
    },
  },

  methods: {
    mapValuesToLayer(updatedValues, originalLayer) {
      const copyOfValues = structuredClone(updatedValues);

      const { dataLayer, operator, value } = copyOfValues;

      const originalLayerDefinition = originalLayer;

      const { ValueField, Query, TypeOfData } = originalLayerDefinition;

      if (dataLayer.dataset.DatasetType === "Raster") {
        if (TypeOfData === "Continuous") {
          switch (operator) {
            case "==":
              dataLayer.layer.MinValue = value;
              dataLayer.layer.MaxValue = value;
              break;

            case "<=":
              dataLayer.layer.MinValue = null;
              dataLayer.layer.MaxValue = value;
              break;

            case ">=":
              dataLayer.layer.MinValue = value;
              dataLayer.layer.MaxValue = null;
              break;

            default:
              break;
          }
        }

        if (TypeOfData === "Categorical") {
          // update Categories

          const categoryObject = (array, key) => {
            const initialValue = {};
            return array.reduce((obj, item) => {
              return {
                ...obj,
                [item[key]]: item.value[item[key]],
              };
            }, initialValue);
          };

          switch (operator) {
            case "==":
              dataLayer.layer.Categories = categoryObject(value, "title");
              break;

            default:
              break;
          }
        } else {
          if (TypeOfData === "Mask") {
            // update Categories

            const categoryObject = (array, key) => {
              const initialValue = {};
              return array.reduce((obj, item) => {
                return {
                  ...obj,
                  [item[key]]: item.value[item[key]],
                };
              }, initialValue);
            };

            switch (operator) {
              case "==":
                dataLayer.layer.Categories = categoryObject(value, "title");
                break;

              default:
                break;
            }
          }
        }
      } else {
        if (TypeOfData === "Continuous") {
          // create query
          const newQuery = `COLUMN VIRTUAL_FIELD = \`${ValueField}\` ${operator} ${value}`;

          // if there is an existing query that the new query depends on such as a table join, we need to append a new query
          dataLayer.layer.Query = Query
            ? `${dataLayer.layer.Query};${newQuery}`
            : newQuery;

          // filter field and filter value (true) and
          dataLayer.layer.FilterField = "VIRTUAL_FIELD";
          dataLayer.layer.FilterValue = "true";
          dataLayer.layer.TypeOfData = "Mask";
          dataLayer.layer.FilterExpression = [
            "case",
            ["==", ["get", "VIRTUAL_FIELD"], "true"],
            "rgba(0,0,0,1)", // Set color to transparent if the value is true and
          ];
        }

        // if (dataLayer.layer.TypeOfData === "Mask") {
        //   // const originalValueField = this.availableTargetLayers.find(
        //   //   (target) => target.layer.key === dataLayer.layer.key
        //   // ).layer.ValueField;
        //   // create query
        //   dataLayer.layer.Query = `COLUMN VIRTUAL_FIELD = \`${dataLayer.layer.ValueField}\` ${operator} ${value}`;
        //   // dataLayer.layer.ValueField = "VIRTUAL_FIELD";

        //   // filter field and filter value (true) and
        //   dataLayer.layer.FilterField = "VIRTUAL_FIELD";
        //   dataLayer.layer.FilterValue = "True";
        //   // dataLayer.layer.TypeOfData = "Mask";
        // }
      }

      return copyOfValues;
    },

    updateQueryLayer(queryValues) {
      const updatedQueryValues = this.mapValuesToLayer(
        queryValues,
        this.queryLayer.originalLayer.layer
      );

      this.updatedQueryLayer.queryLayer = updatedQueryValues.dataLayer;
    },

    updateTargetQueryLayers(layerValues) {
      const existingDefinition = this.targetLayers.find(
        (target) => target.dataLayer.key === layerValues.dataLayer.key
      );

      const originalLayerDefinition = this.availableTargetLayers.find(
        (target) => target.key === layerValues.dataLayer.key
      ).layer;

      const updatedValues = this.mapValuesToLayer(
        layerValues,
        originalLayerDefinition
      );

      this.updatedQueryLayer.targetLayers = [updatedValues.dataLayer];
      // update layer definition
      // add to targetLayers
      if (existingDefinition === undefined) {
        this.targetLayers.push(updatedValues);
      } else Object.assign(existingDefinition, updatedValues);
    },

    async applyQuery() {
      this.queryLoading = true;
      const { dataset, layer } = this.updatedQueryLayer.queryLayer;

      // check if ind

      // store action to re-build map layer
      const queryLayerDetails = {
        selectedDataset: dataset,
        selectedLayer: layer,
        mapExtent: this.$store.state.settings.mapSettings.maxMapBounds,
        studyAreas: this.$store.state.mapping.datasets.filter(
          (dataset) => dataset.mapLayerPurpose === "StudyAreaMapLayer"
        ),
        layerMasks: this.updatedQueryLayer.targetLayers,
        layerContext: "DatasetMapLayer",
        allowFeatureSelection: false,
      };

      await this.$store.dispatch("mapping/buildQuery", {
        queryLayerDetails,
        targetLayers: this.targetLayers,
      });

      this.queryLoading = false;
      this.$emit("minimize-query-builder");
    },
  },

  mounted() {
    this.updatedQueryLayer = structuredClone(this.queryLayer);
    this.updatedQueryLayer.queryLayer = structuredClone(
      this.updatedQueryLayer.originalLayer
    );
  },

  unmounted() {
    this.$store.commit("mapping/clearQueryMasks");
  },
};
</script>

<style scoped>
.content-container {
  display: flex;
  flex: 0 1;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  max-height: 50vh;
}

.query-builder-section {
  display: flex;
  flex-direction: column;
  flex: 2;
  overflow-y: auto;
  min-height: 40vh;
}

.query-layer-section,
.target-layer-section {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  gap: 0.5em;
}

.action-section {
  display: flex;
  align-self: flex-end;
}
</style>

import getMathParams from "./math";

const state = {
  methods: {
    // Operators can be changed
    Operators: [
      {
        s: "+",
        description: "Add",
        params: [],
        noParams: true,
      },
      {
        s: "-",
        description: "Subtract",
        params: [],
        noParams: true,
      },
      {
        s: "*",
        description: "Multiply",
        params: [],
        noParams: true,
      },
      {
        s: "/",
        description: "Divide",
        params: [],
        noParams: true,
      },
      {
        s: "**",
        description: "Exponent",
        params: [],
        noParams: true,
      },
      {
        s: "%",
        description: "Modulo",
        params: [],
        noParams: true,
      },
    ],

    // Math
    Math: [...getMathParams()],

    // Logical operators
    Logical: [
      {
        s: "if",
        description: "Conditional if statement",
        params: [],
        component: "If",
        altText:
          "Logical if statements take the form: <code>if(condition, result if true, result if false)</code>",
      },
      {
        s: "cond",
        description: 'Condition statement to be used within "if" statements',
        params: [],
        component: "Cond",
        altText:
          'Example <code>cond(data(name="My Data", scen="My Scen") == 2)</code>',
      },
      {
        s: "&",
        description: "Logical and",
        params: [],
        noParams: true,
      },
      {
        s: "|",
        description: "Logical or",
        params: [],
        noParams: true,
      },
      {
        s: "True",
        description: "Value of True",
        params: [],
        noParams: true,
      },
      {
        s: "False",
        description: "Value of False",
        params: [],
        noParams: true,
      },
      {
        s: "None",
        description: "Denotes no data in a raster dataset",
        params: [],
        noParams: true,
      },
    ],

    // Base variables for common variables and conditionals
    Data: [
      {
        s: "data",
        description: "A cloud data source",
        params: [
          {
            name: "name",
            required: true,
            default: null,
            description: "Name of the dataset",
          },
          {
            name: "scen",
            required: false,
            default: null,
            description: "Scenario name",
          },
          {
            name: "feature_name",
            required: false,
            default: null,
            description: "Isolate a specific feature from a vector dataset",
          },
          {
            name: "set_time",
            required: false,
            default: null,
            description:
              "Collect data from a set time regardless of simulation time",
          },
          {
            name: "snap_time",
            required: false,
            default: null,
            description:
              "Snap time to an available time that data exist if they do not for a simulation time. Use one of 'closest', 'backward', or 'forward'",
          },
          {
            name: "time_delta",
            required: false,
            default: null,
            description:
              "Collect data from a time that is offset from the current simulation time by a specified amount",
          },
          {
            name: "no_data_fill",
            required: false,
            default: null,
            description: "Replace no data values with a set value",
          },
          {
            name: "resample_method",
            required: false,
            default: null,
            description:
              "Interpolation or statistical method to spatially modify a grid. Defaults to 'near'.",
          },
          {
            name: "as_mask",
            required: false,
            default: null,
            description:
              "Return a boolean mask with areas that contain valid data or rasterized vector features as True",
          },
          {
            name: "valid_min",
            required: false,
            default: null,
            description:
              "Specifies the lower bound of the valid data range. Values less than valid_min are masked.",
          },
          {
            name: "valid_max",
            required: false,
            default: null,
            description:
              "Specifies the upper bound of the valid data range. Values greater than valid_max are masked.",
          },
          {
            name: "buffer",
            required: false,
            default: null,
            description:
              "Buffer in meters that is applied to points/lines/polygons. Only valid for vector datasets.",
          },
        ],
        component: "Dataset",
      },
      {
        s: "ex",
        description: "Nest another expression within",
        params: [
          {
            name: "name",
            required: true,
            default: null,
            description: "Name of the Expression",
          },
          {
            name: "cat",
            required: false,
            default: null,
            description: "Category of the Expression",
          },
        ],
        component: "Expression",
        noSubmitButton: true,
      },
      {
        s: "filter_model",
        description: "Collect the results of a filter model",
        params: [
          {
            name: "name",
            required: true,
            default: null,
            description: "Name of the Filter Model",
          },
          {
            name: "cat",
            required: true,
            default: null,
            description: "Category of the Filter Model",
          },
        ],
        component: "FilterModelMethod",
      },
    ],

    // Time-based operations
    Time: [
      {
        s: "temporal_modifier",
        description: "Apply a time-variant coefficient",
        params: [
          {
            name: "input",
            required: true,
            default: null,
            description: "Expression as input",
          },
          {
            name: "increments",
            required: true,
            default: "-",
            description:
              "Coefficients at increments used to modify a dataset over time",
          },
          {
            name: "gen_error",
            required: false,
            default: "False",
            description:
              "Generate an error if data do not exist at the specified time",
          },
          {
            name: "extend_backward",
            required: false,
            default: "True",
            description:
              "Enable data to be snapped backwards in time if not available",
          },
          {
            name: "extend_forward",
            required: false,
            default: "True",
            description:
              "Enable data to be snapped forwards in time if not available",
          },
        ],
      },
      {
        s: "time_since_change",
        description:
          "Track the duration since a cell value changes during a simulation",
        params: [
          {
            name: "input",
            required: true,
            default: null,
            description: "Expression providing the tracked values",
          },
          {
            name: "default",
            required: false,
            default: 0,
            description: "Starting values of the tracked time",
          },
        ],
      },
      {
        s: "result",
        description: "Collect results from a previous time step mid-simulation",
        component: "Result",
        params: [
          {
            name: "delta",
            required: false,
            default: -1,
            description:
              "Number of increments in the past to reference. Must be a negative integer.",
          },
          {
            name: "absolute",
            required: false,
            default: null,
            description:
              'An absolute time step to reference (ex. "YYYY-MM-DD HH:MM")',
          },
          {
            name: "index",
            required: false,
            default: null,
            description:
              "An increment from the beginning of the simulation, where a simulation runs from time step 0 to n.",
          },
          {
            name: "default",
            required: false,
            default: 0,
            description:
              "Default values to return if delta or index are not within the range [0,  current time step - 1]",
          },
        ],
      },
    ],

    Constants: [
      {
        s: "XCoordinate",
        description:
          "The x-coordinate of each raster cell of the published dataset during calculation",
        params: [],
      },
      {
        s: "YCoordinate",
        description:
          "The y-coordinate of each raster cell of the published dataset during calculation",
        params: [],
      },
      {
        s: "time_step",
        description:
          "Current time step as in increment (i.e times range from 0 to n)",
        params: [],
      },
      {
        s: "year",
        description: "Year of the current time step",
        params: [],
      },
    ],

    // Methods are custom functions run on data using parameters derived from the UI
    Methods: [
      {
        s: "allocate_random",
        description: "",
        params: [
          {
            name: "likelihood",
            required: true,
            default: "-",
            description: "Relative likelihood of being allocated",
          },
          {
            name: "area",
            required: true,
            default: "-",
            description: "Total area to be allocated",
          },
        ]
      },
      {
        s: "allocate_cluster",
        description: "",
        params: [
          {
            name: "likelihood",
            required: true,
            default: "-",
            description: "Relative likelihood of being allocated",
          },
          {
            name: "area",
            required: true,
            default: "-",
            description: "Total area to be allocated",
          },
          {
            name: "cluster_area_min",
            required: true,
            default: "-",
            description: "The minimum allowed cluster size",
          },
          {
            name: "cluster_area_max",
            required: true,
            default: "-",
            description: "The maximum allowed cluster size",
          },
          {
            name: "exponent",
            required: false,
            default: 1.2,
            description: "Power Law exponent controlling the distribution of cluster size",
          },
        ]
      },
      {
        s: "normalize",
        description: "Convert a data range to another specified min and max",
        params: [
          {
            name: "input",
            required: true,
            default: "-",
            description: "Expression as input",
          },
          {
            name: "min",
            required: true,
            default: 0,
            description: "Minimum normalized value",
          },
          {
            name: "max",
            required: true,
            default: 1,
            description: "Maximum normalized value",
          },
        ],
        component: "Normalize",
      },
      {
        s: "inverse",
        description:
          "Invert data values so that the maximum value becomes the minimum and vice-versa",
        params: [
          {
            name: "input",
            required: true,
            default: "-",
            description: "Expression as input",
          },
        ],
      },
      {
        s: "random",
        component: "Random",
        description:
          "Compute a random number, or an array of random numbers using a distribution from a random number generator<br>Note: Extra parameters may be required depending on the chosen distribution.",
        params: [
          {
            name: "single",
            required: false,
            default: "True",
            description:
              "Generate a single random number for all cells instead of a different one",
          },
          {
            name: "distribution",
            required: false,
            default: null,
            description:
              "A statistical distribution to use to generate the random number(s). Must be one of 'uniform', 'normal', 'cauchy', 'gamma', or 'exponential'.",
          },
          {
            name: "seed",
            required: false,
            default: null,
            description:
              "Specifies a custom seed for the random number (integer)",
          },
        ],
      },
      {
        s: "sum",
        description:
          "Aggregate data or compute a sum element-wise (between grid cells of multiple data)",
        component: "Stats",
        params: [
          {
            name: "input",
            required: true,
            default: "-",
            description: "Expression as input",
          },
        ],
      },
      {
        s: "mean",
        description:
          "Aggregate data or compute a mean element-wise (between grid cells of multiple data)",
        component: "Stats",
        params: [
          {
            name: "input",
            required: true,
            default: "-",
            description: "Expression as input",
          },
        ],
      },
      {
        s: "std",
        description:
          "Aggregate data or compute standard deviation element-wise (between grid cells of multiple data)",
        component: "Stats",
        params: [
          {
            name: "input",
            required: true,
            default: "-",
            description: "Expression as input",
          },
        ],
      },
      {
        s: "var",
        description:
          "Aggregate data or compute variance element-wise (between grid cells of multiple data)",
        component: "Stats",
        params: [
          {
            name: "input",
            required: true,
            default: "-",
            description: "Expression as input",
          },
        ],
      },
      {
        s: "min",
        description:
          "Aggregate data or compute a minimum element-wise (between grid cells of multiple data)",
        component: "Stats",
        params: [
          {
            name: "input",
            required: true,
            default: "-",
            description: "Expression as input",
          },
        ],
      },
      {
        s: "max",
        description:
          "Aggregate data or compute a minimum element-wise (between grid cells of multiple data)",
        component: "Stats",
        params: [
          {
            name: "input",
            required: true,
            default: "-",
            description: "Expression as input",
          },
        ],
      },
      {
        s: "percentile",
        description: "Calculate a percentile",
        component: "percentile",
        params: [
          {
            name: "input",
            required: true,
            default: "-",
            description: "Expression as input",
          },
          {
            name: "q",
            required: false,
            default: 50,
            description: "Percentile threshold",
          },
        ],
      },
      {
        s: "cell_area",
        description:
          "The area of each raster cell of the published indicator during calculation",
        params: [
          {
            name: "units",
            required: false,
            default: "square metres",
            description:
              "One of 'square metres', 'square kilometres', 'hectares', 'square miles', or 'acres'",
          },
        ],
        component: "CellSize",
      },
      {
        s: "buffer",
        description: "Perform a buffer at a specified distance",
        params: [
          {
            name: "input",
            required: true,
            default: "-",
            description: "Expression as input",
          },
          {
            name: "distance",
            required: false,
            default: 0,
            description:
              "Distance to apply a buffer in the output spatial reference",
          },
          {
            name: "fill",
            required: false,
            default: 1,
            description: "Values to fill buffered cells with",
          },
        ],
        component: "Buffer",
      },
      {
        s: "univariate_modifier",
        description: "Change data values using coefficients",
        params: [
          {
            name: "input",
            required: true,
            default: "-",
            description: "Expression as input",
          },
          {
            name: "min",
            required: false,
            default: 0,
            description: "Minimum coefficient to use",
          },
          {
            name: "max",
            required: false,
            default: 1,
            description: "Maximum coefficient to use",
          },
          {
            name: "use_trend",
            required: false,
            default: "True",
            description:
              "Apply the coefficient over the range of data using a trend",
          },
          {
            name: "trend_type",
            required: false,
            default: "linear",
            description: "Trend type to use for remapping data",
          },
          {
            name: "reverse",
            required: false,
            default: "False",
            description: "Reverse the selected trend",
          },
          {
            name: "base",
            required: false,
            default: "None",
            description: "Base for a logarithmic trend",
          },
          {
            name: "decay",
            required: false,
            default: "None",
            description: "Decay for a logarithmic trend",
          },
          {
            name: "points",
            required: false,
            default: "None",
            description: "A mapping of breakpoints if a trend is not used",
          },
        ],
        component: "UnivariateModifier",
      },
      {
        s: "regional_modifier",
        description: "Apply multipliers over region areas",
        params: [
          {
            name: "input",
            required: true,
            default: "-",
            description: "Expression as input",
          },
          {
            name: "regions",
            required: true,
            default: "-",
            description: "A region dataset",
          },
          {
            name: "area_scale",
            required: false,
            default: "False",
            description:
              "Scale the result of the multiplier by area of a cell overlapped by the selected regions",
          },
        ],
        component: "RegionalModifier",
      },
      {
        s: "region_stats",
        description:
          "Apply zonal statistics variably using the areal extent of regions",
        params: [
          {
            name: "input",
            required: true,
            default: "-",
            description: "Expression as input",
          },
          {
            name: "regions",
            required: true,
            default: "-",
            description:
              "Regions used to constrain areal statistical calculations",
          },
        ],
        component: "RegionStats",
      },
      {
        s: "patches",
        description: "Perform summary statistics over contiguous regions",
        params: [
          {
            name: "input",
            required: true,
            default: "-",
            description: "Expression as input",
          },
          {
            name: "method",
            required: false,
            default: "mean",
            description: "Statistical method to reduce data over regions",
          },
        ],
        component: "Patches",
      },
      {
        s: "proximity",
        description:
          "Calculate the distance to any valid data in a region for every raster cell",
        params: [
          {
            name: "input",
            required: true,
            default: "-",
            description: "Expression as input",
          },
        ],
        altText:
          "No other parameters are required, and distance is recorded in metres",
      },
      {
        s: "least_cost",
        description:
          "Generate a least cost surface and paths from points of origin over a cost surface",
        params: [
          {
            name: "input",
            required: true,
            default: "-",
            description: "Expression as input",
          },
          {
            name: "gen_cost",
            required: false,
            default: "True",
            description: "Generate a cost surface instead of paths",
          },
          {
            name: "sources",
            required: false,
            default: "None",
            description: "Source locations for paths if cost is False",
          },
          {
            name: "source_method",
            required: false,
            default: "random from top 10%",
            description: "Method for the selection of source cell locations",
          },
          {
            name: "num_sources",
            required: false,
            default: 1,
            description: "Number of source cells to select",
          },
          {
            name: "endpoints",
            required: false,
            default: "None",
            description: "Endpoint locations for paths",
          },
          {
            name: "endpoint_method",
            required: false,
            default: "None",
            description: "Method for the selection of endpoint cell locations",
          },
          {
            name: "num_endpoints",
            required: false,
            default: "None",
            description: "Number of endpoint to connect source points to",
          },
          {
            name: "iterations",
            required: false,
            default: "None",
            description:
              "Iterative calculate paths from all sources to endpoints and return a count of paths for each cell",
          },
        ],
        component: "LCP",
      },
      {
        s: "moving_window",
        description:
          "Calculate summary statistics over a neighbourhood of cells and apply the result to each cell",
        params: [
          {
            name: "input",
            required: true,
            default: "-",
            description: "Expression as input",
          },
          {
            name: "radius",
            required: true,
            default: "sqrt(cell_size_y ** 2 + cell_size_x ** 2)",
            description:
              "Distance of the summarization window (euclidean kernel)",
          },
          {
            name: "method",
            required: true,
            default: "mean",
            description: "Statistical method to apply for each kernel sample",
          },
          {
            name: "q",
            required: false,
            default: 25,
            description: "Percentile to use if the percentile method is used",
          },
        ],
        component: "MovingWindow",
      },
      {
        s: "transformation",
        description: "Use interpolation methods to transform data",
        params: [
          {
            name: "input",
            required: true,
            default: "-",
            description: "Expression as input",
          },
          {
            name: "method",
            required: false,
            default: "linear",
            description: "Transformation method",
          },
        ],
      },
      {
        s: "scalar_ln",
        description: "Natural logarithm of a scalar",
        params: [
          {
            name: "x",
            required: true,
            default: "-",
            description: "Value",
          },
        ]
      },
      {
        s: "scalar_log10",
        description: "Base-10 logarithm of a scalar",
        params: [
          {
            name: "x",
            required: true,
            default: "-",
            description: "Value",
          },
        ]
      },
      {
        s: "scalar_exp",
        description: "Exponential of a scalar",
        params: [
          {
            name: "x",
            required: true,
            default: "-",
            description: "Value",
          },
        ]
      },
      {
        s: "scalar_pow",
        description: "Calculate x^y where x and y are scalars",
        params: [
          {
            name: "x",
            required: true,
            default: "-",
            description: "Base",
          },
          {
            name: "y",
            required: true,
            default: "-",
            description: "Exponent",
          },
        ]
      },
      {
        s: "scalar_ceil",
        description: "Round a scalar up",
        params: [
          {
            name: "x",
            required: true,
            default: "-",
            description: "Value",
          },
        ]
      },
      {
        s: "scalar_floor",
        description: "Round a scalar down",
        params: [
          {
            name: "x",
            required: true,
            default: "-",
            description: "Value",
          },
        ]
      },
      {
        s: "scalar_round",
        description: "Round a scalar to nearest integer",
        params: [
          {
            name: "x",
            required: true,
            default: "-",
            description: "Value",
          },
        ]
      },
      {
        s: "scalar_abs",
        description: "Absolute value of a scalar",
        params: [
          {
            name: "x",
            required: true,
            default: "-",
            description: "Value",
          },
        ]
      },
      {
        s: "scalar_if",
        description: "If-else statement where true and false values are scalars",
        params: [
          {
            name: "x",
            required: true,
            default: "-",
            description: "Value",
          },
        ]
      },
      {
        s: "scalar_pdf",
        description: "Evaluate a pdf. Additional arguments are passed to the underlying function call.",
        params: [
          {
            name: "name",
            required: true,
            default: "-",
            description: "Name of the distribution. E.g., 'lognorm'. See https://docs.scipy.org/doc/scipy/reference/stats.html#continuous-distributions for a complete list.",
          },
          {
            name: "x",
            required: true,
            default: "-",
            description: "Value to evaluate the PDF at.",
          },
          {
            name: "shape",
            required: true,
            default: "-",
            description: "Shape parameters for the chosen distribution. Refer to https://docs.scipy.org/doc/scipy/reference/stats.html#continuous-distributions for the shape parameters for your chose distribution.",
          },
        ]
      },
      {
        s: "scalar_cdf",
        description: "Evaluate a cdf. Additional arguments are passed to the underlying function call.",
        params: [
          {
            name: "name",
            required: true,
            default: "-",
            description: "Name of the distribution. E.g., 'lognorm'. See https://docs.scipy.org/doc/scipy/reference/stats.html#continuous-distributions for a complete list.",
          },
          {
            name: "x",
            required: true,
            default: "-",
            description: "Value to evaluate the CDF at.",
          },
          {
            name: "shape",
            required: true,
            default: "-",
            description: "Shape parameters for the chosen distribution. Refer to https://docs.scipy.org/doc/scipy/reference/stats.html#continuous-distributions for the shape parameters for your chose distribution.",
          },
        ]
      },
      {
        s: "scalar_study_area_m2",
        description: "Returns the study area in square meters",
        params: []
      },
      {
        s: "scalar_study_area_km2",
        description: "Returns the study area in square kilometers",
        params: []
      },
      {
        s: "reduce_to_scalar",
        description: "Returns the study area in square kilometers",
        params: [
          {
            name: "x",
            required: true,
            default: "-",
            description: "Expression whose result will be reduce to a scalar",
          },
          {
            name: "method",
            required: false,
            default: "mean",
            description: "Method used to reduce the array (raster) to a scalar value. Options are: sum, min, max, std, mean, area_m2, area_km2",
          },
        ]
      }
    ],
  },
};
const mutations = {};
const getters = {
  autocompleteMatches: (state) => (searchString) => {
    if (searchString) {
      const lowerSearchString = searchString.toLowerCase();
      let autoCompletes = {};

      Object.keys(state.methods).forEach((codeType) => {
        let matchNumbers = [];
        let matches = state.methods[codeType].filter((attrs) => {
          let test = attrs.s.toLowerCase().includes(lowerSearchString);
          if (test) {
            matchNumbers.push(lowerSearchString.length / attrs.s.length);
          }
          return test;
        });
        if (matches.length > 0) {
          // Sort matches by matchNumbers
          let sort = [];
          let i = -1;
          matchNumbers.forEach((mn) => {
            i++;
            sort.push(Object.assign(matches[i], { mn: mn }));
          });
          sort
            .sort((a, b) => {
              if (a.mn < b.mn) {
                return -1;
              }
              if (a.mn > b.mn) {
                return 1;
              }
              return 0;
            })
            .reverse();
          autoCompletes[codeType] = sort;
        }
      });

      return autoCompletes;
    } else return {};
  },
  routeFromMethodName: (state) => (methodName) => {
    let method;
    Object.values(state.methods).forEach((methods) => {
      methods.forEach((_method) => {
        if (_method.s === methodName) method = _method;
      });
    });
    return method;
  },
};
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

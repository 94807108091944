<template>
  <v-card class="pa-6" id="image-card">
    <v-card-subtitle class="text-h6 pl-0">
      Pick a banner image to customize the appearance of your project.
    </v-card-subtitle>
    <v-row class="d-flex wrap justify-center">
      <v-col
        class="d-flex"
        cols="6"
        sm="4"
        md="3"
        v-for="(image, index) in images"
        :key="index"
      >
        <div v-if="newImageLoading" class="d-flex justify-center">
          <v-progress-circular indeterminate></v-progress-circular>
        </div>
        <v-img
          :src="require(`@/assets/project-img/${image}`)"
          @click="selectImage(index)"
          class="image"
          :aspect-ratio="1"
          :gradient="
            selectedImage === index
              ? 'to bottom right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)'
              : null
          "
        >
          <v-icon v-if="selectedImage === index" color="white"
            >mdi-checkbox-marked-circle-outline</v-icon
          >
        </v-img>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "ProjectImageSelector",

  props: {
    inputImage: String,
  },

  data() {
    return {
      selectedImage: null,
      images: [
        "jack-anstey-HtUBBdNDxpQ-unsplash.jpg",
        "avinash-kumar-rEIDzqczN7s-unsplash.jpg",
        "jonas-weckschmied--N_UwPdUs7E-unsplash.jpg",
        "federico-bottos-Z3NceSeZqgI-unsplash.jpg",
        "agustin-ljosmyndun-vp4F4_K0P5w-unsplash.jpg",
        "thomas-bennie-yj0BKllXEu0-unsplash.jpg",
        "taylor-r-clkI9_xcuis-unsplash.jpg",
        "moritz-kindler-q8xObc1brr4-unsplash.jpg",
        "clement-mabula-UYZdcNH7OKg-unsplash.jpg",
        "guillaume-henrotte-bNOh-Y-ECSg-unsplash.jpg",
        "guille-pozzi-bInJ2wIkITo-unsplash.jpg",
        "andrew-neel-a_K7R1kugUE-unsplash.jpg",
        "jon-flobrant-rB7-LCa_diU-unsplash.jpg",
        "emerson-peters-PR0ENPY0P9c-unsplash.jpg",
        "jack-hamilton-jR311W7n9fk-unsplash.jpg",
      ],
      newImageLoading: false,
    };
  },

  methods: {
    selectImage(idx) {
      this.selectedImage = idx;
      this.newImageLoading = true;
      this.$emit("update-image", this.images[idx]);
    },
  },

  mounted() {
    if (this.inputImage) {
      this.images.forEach((image, idx) => {
        if (this.inputImage === image) this.selectedImage = idx;
      });
    }
  },
};
</script>

<style scoped>
.image {
  cursor: pointer;
}
#image-card {
  overflow-x: hidden;
}
</style>

<template>
  <div
    class="text-center py-2"
    v-if="
      projectList.length === 0 &&
      !$store.state.accountData.projectList.fetchingUpdates
    "
  >
    <h2>No Projects Found</h2>
  </div>

  <div
    v-else-if="
      projectList.length === 0 &&
      $store.state.accountData.projectList.fetchingUpdates
    "
  >
    <v-subheader class="text-h5 font-weight-black black--text pt-2">
      My Projects
    </v-subheader>
    <v-row>
      <v-col cols="12" sm="12" md="4" lg="3" v-for="loader in 10" :key="loader">
        <v-skeleton-loader type="card" max-height="200"> </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-subheader class="text-h5 font-weight-black black--text pt-2">
      Projects Shared with Me
    </v-subheader>
    <v-row>
      <v-col cols="12" sm="12" md="4" lg="3" v-for="loader in 10" :key="loader">
        <v-skeleton-loader type="card" max-height="200"> </v-skeleton-loader>
      </v-col>
    </v-row>
  </div>

  <div v-else>
    <v-subheader class="text-h5 font-weight-black black--text pt-2">
      My Projects
    </v-subheader>
    <h3 v-if="userProjects.length === 0" class="text-center py-2">
      No Projects Found
    </h3>

    <v-row class="pa-5">
      <v-col
        sm="12"
        md="4"
        lg="3"
        v-for="project in userProjects"
        :key="project.ProjectName"
      >
        <v-skeleton-loader
          v-if="
            projectLoading && projectLoading.ProjectName === project.ProjectName
          "
          type="card"
          max-height="200"
        >
        </v-skeleton-loader>
        <v-card
          v-else
          class="mx-auto"
          elevation="3"
          @click="openProject(project)"
          style="cursor: pointer"
        >
          <v-img
            height="120"
            gradient="to bottom right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0)"
            :src="
              require(`@/assets/project-img/${
                project.ProjectImage
                  ? project.ProjectImage
                  : 'jack-hamilton-jR311W7n9fk-unsplash.jpg'
              }`)
            "
          >
            <div class="d-flex white--text">
              <div>
                <v-card-title>{{ project.ProjectName }}</v-card-title>
                <v-card-subtitle>
                  {{ project.ProjectOwner }}<br />
                  {{ formatDateString(project.UpdatedAt) }}
                </v-card-subtitle>
              </div>
            </div>
          </v-img>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Description</v-list-item-title>
              <v-list-item-subtitle>{{
                project.Description ? project.Description : "No description"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <v-subheader class="text-h5 font-weight-black black--text pt-2">
      Projects Shared with Me
    </v-subheader>

    <h3 v-if="userProjects.length === 0" class="text-center py-2">
      No Projects Found
    </h3>

    <v-row class="pa-5">
      <v-col
        sm="12"
        md="4"
        lg="3"
        v-for="project in sharedProjects"
        :key="project.ProjectName"
      >
        <v-skeleton-loader
          v-if="
            projectLoading && projectLoading.ProjectName === project.ProjectName
          "
          type="card"
          max-height="200"
        >
        </v-skeleton-loader>
        <v-card
          v-else
          class="mx-auto"
          elevation="3"
          @click="openProject(project)"
          style="cursor: pointer"
        >
          <v-img
            height="120"
            gradient="to bottom right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0)"
            :src="
              require(`@/assets/project-img/${
                project.ProjectImage
                  ? project.ProjectImage
                  : 'jack-hamilton-jR311W7n9fk-unsplash.jpg'
              }`)
            "
          >
            <div class="d-flex white--text">
              <div>
                <v-card-title>{{ project.ProjectName }}</v-card-title>
                <v-card-subtitle>
                  {{ project.ProjectOwner }}<br />
                  {{ formatDateString(project.UpdatedAt) }}
                </v-card-subtitle>
              </div>
            </div>
          </v-img>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Description</v-list-item-title>
              <v-list-item-subtitle>{{
                project.Description ? project.Description : "No description"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { formatDateString } from "@/api/v2";

export default {
  name: "ProjectList",
  props: {
    projectList: Array,
    projectLoading: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isLoading: false,
      selectedProject: null,
    };
  },

  methods: {
    openProject(project) {
      this.$emit("open-project", project);
    },

    formatDateString,
  },

  computed: {
    currentUser() {
      return this.$store.state.account.user.attributes.email;
    },

    userProjects() {
      return this.projectList.filter((project) => {
        return (
          project.ProjectOwner ===
          this.$store.state.account.user.attributes.email
        );
      });
    },

    sharedProjects() {
      return this.projectList.filter((project) => {
        return (
          project.ProjectOwner !==
          this.$store.state.account.user.attributes.email
        );
      });
    },
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <v-row class="d-flex justify-space-between">
      <v-col cols="12" md="6">
        <div>
          <v-file-input
            accept=".tif, .tiff, .zip"
            hint="Accepts: .tif or .tiff or .zip"
            persistent-hint
            truncate-length="30"
            show-size
            v-model="file"
            @change="addNewFile"
          >
            <template v-slot:selection="{ text }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text-wrap text-subtitle-2"
                    v-bind="attrs"
                    v-on="on"
                    >{{ text }}</span
                  >
                </template>
                <span>{{ filename }}</span>
              </v-tooltip>
            </template>
          </v-file-input>
        </div>
      </v-col>

      <v-col cols="12">
        <upload-file-form-date-selector
          :filename="filename"
          @update-date-range="updateDateRange"
        >
        </upload-file-form-date-selector>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UploadFileFormDateSelector from "@/components/datasets/UploadFileFormDateSelector.vue";

export default {
  name: "UploadFileFormRasterFile",

  components: {
    UploadFileFormDateSelector,
  },

  data() {
    return {
      file: null,
      filename: null,
      dateRange: { start: "ind", end: "ind" },
    };
  },
  methods: {
    updateDateRange(newDateRange) {
      if (newDateRange.start) {
        this.dateRange = newDateRange;
      } else this.dateRange = { start: "ind", end: "ind" };
      this.addNewFile();
    },

    addNewFile() {
      this.file ? (this.filename = this.file.name) : (this.filename = null);

      const updatedFile = {
        file: this.file,
        filename: this.filename,
        dateRange: this.dateRange,
      };
      this.$emit("update-file", updatedFile);
    },
  },
};
</script>

<style scoped></style>

import { render, staticRenderFns } from "./SequenceCodeEditor.vue?vue&type=template&id=37f7ffce&scoped=true&"
import script from "./SequenceCodeEditor.vue?vue&type=script&lang=js&"
export * from "./SequenceCodeEditor.vue?vue&type=script&lang=js&"
import style0 from "./SequenceCodeEditor.vue?vue&type=style&index=0&id=37f7ffce&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37f7ffce",
  null
  
)

export default component.exports
const awsmobile = {
  aws_project_region: "us-west-2",
  aws_cognito_region: "us-west-2",
  aws_user_pools_id: process.env.VUE_APP_AWS_USER_POOLS_ID,
  aws_user_pool_name: process.env.VUE_APP_AWS_USER_POOL_NAME,
  aws_user_pools_web_client_id:
    process.env.VUE_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  aws_cloud_logic_custom: [
    {
      name: "flow-api",
      endpoint: process.env.VUE_APP_FLOW_API_ENDPOINT,
      region: "us-west-2",
    },
    {
      name: "flow-geotools",
      endpoint: process.env.VUE_APP_FLOW_GEOTOOLS_ENDPOINT,
      region: "us-west-2",
    },
  ],
  aws_misc: {
    titiler_endpoint: process.env.VUE_APP_TITILER_ENDPOINT,
    flow_attribute_joiner_endpoint:
      process.env.VUE_APP_FLOW_TILESERVER_ENDPOINT,
    data_bucket: "alces-flow-data",
  },
};

export default awsmobile;

export function simulationCanRunCheckDateFormat(date) {
  return (
    /^([0-9]{4}(-[01][0-9](-[0-3][0-9])?)?|ind)/.test(date) ||
    "Date format must be 'YYYY-MM-DD' (e.g., 2021-01-01)"
  );
}
export function simulationCanRun(project, simulation) {
  let problems = [];
  let isValid = true;
  if (!project.Settings.StudyAreaDatasetName) {
    isValid = false;
    problems.push("Project study area is not defined.");
  }
  if (simulationCanRunCheckDateFormat(simulation.StartDate) !== true) {
    isValid = false;
    problems.push("Scenario start date is not defined.");
  }
  if (simulationCanRunCheckDateFormat(simulation.EndDate) !== true) {
    isValid = false;
    problems.push("Scenario end date is not defined.");
  }
  if (!simulation.ExpressionSyntaxIsValid) {
    isValid = false;
    problems.push("Expression has a syntax error.");
  }
  if (isValid) {
    return true;
  } else {
    return problems;
  }
}

export function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    let args = arguments;
    let that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

// helper function to replace forward slash to back slash in expressionNames
// API will do the reverse and swap back
export function replaceSlash(name) {
  return name.replaceAll("/", "\\");
}

export function throwTestError(
  errorMessage = ":( oh no",
  code = "808",
  warningMessage = null
) {
  const error = new Error();
  error.name = "TestError";

  error.response = {
    status: code,
    data: { Errors: errorMessage, Warnings: warningMessage },
  };

  throw error;
}

export function checkStatus(response) {
  if (response.Errors) {
    const error = new Error();

    error.name = "Internal Flow Error";

    error.response = {
      status: response.status,
      data: { Errors: response.Errors, Warnings: response.Warnings },
    };

    // return formatted error
    throw error;
  } else {
    return response;
  }
}

export function base64ToPdf(base64String) {
  // Convert base64 to a Blob
  const binaryString = atob(base64String);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  const blob = new Blob([bytes.buffer], { type: "application/pdf" });

  // Create an object URL from the Blob
  const url = window.URL.createObjectURL(blob);

  // Open the PDF in a new tab
  window.open(url);

  // Or download the PDF
  const link = document.createElement("a");
  link.href = url;
  link.download = "output.pdf";
  link.click();
}

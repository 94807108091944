<template> 
  <div class="pt-2">
    <tag-filter
      :is-loading="isLoading"
      :all-tagged-items="expressionsToBeFiltered"
      :tag-selected-in-parent="clickedTag"
      :filter-by-project="false"
      :filter-by-expression="false"
      :default-tags="defaultTags"
      @updated="updatedExpressionsFilteredByTags"
      @clear-selected-tag="clearClickedTag"
    >
    </tag-filter>

    <v-data-table
      :height="dataTableHeight"
      class="data-table py-2"
      :search="search"
      :headers="defaultHeaders"
      fixed-header
      :items="expressionsAfterTagFilter"
      loading="isLoading"
      loading-text="Loading Expressions... Please wait"
      :show-select="batchEdit"
      :single-select="singleSelect"
      v-model="selectedExpressions"
      item-key="CreatedAt"
      show-expand
      :expanded.sync="expanded"
      expand-icon="mdi-information"
      @click:row="rowSelected"
    >
      <template v-slot:top>
        <v-row>
          <v-col
            cols="12"
            class="d-flex-wrap d-lg-flex align-center justify-center"
          >
            <v-col cols="12" md="12" lg="5">
              <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Search for an Expression"
                hide-details
                :color="$store.state.settings.appColor"
              ></v-text-field>
            </v-col>
            <v-divider class="mx-2 d-none d-md-flex" inset vertical></v-divider>
            <v-col
              v-if="crudActions"
              cols="12"
              md="12"
              lg="5"
              class="d-md-flex align-center justify-center"
            >
              <v-btn
                color="secondary"
                outlined
                block
                @click="newExpression()"
                :disabled="isLoading"
                ><v-icon>mdi-plus</v-icon>New Expression</v-btn
              >
            </v-col>
          </v-col>
        </v-row>

        <v-dialog v-model="dialogShare" max-width="500px" scrollable persistent>
          <data-share
            v-if="dialogShare"
            :selected-data="selectedExpressions"
            :share-type="'Expressions'"
            :batch-edit="batchEdit"
            @close-share-dialog="closeShareDialog()"
          >
          </data-share>
        </v-dialog>
        <v-row>
          <v-col
            cols="12"
            class="d-flex-wrap d-md-flex justify-center"
            v-if="selectedExpressions.length > 1"
          >
            <v-col cols="12" md="5" lg="3">
              <v-btn
                v-if="selectedExpressions.length >= 1"
                v-show="batchEdit && crudActions"
                block
                color="blue"
                @click.stop="shareExpressions"
                ><v-icon class="px-2">mdi-share</v-icon>Share Selected
                <span class="font-weight-black px-1">
                  {{ `(${selectedExpressions.length})` }}
                </span>
              </v-btn>
            </v-col>

            <v-col cols="12" md="5" lg="3">
              <v-btn
                v-show="batchEdit && crudActions"
                v-if="selectedExpressions.length >= 1"
                block
                color="red"
                @click="deleteExpressions(selectedExpressions)"
                ><v-icon class="px-2">mdi-delete</v-icon>Delete Selected
                <span class="font-weight-black px-1">
                  {{ `(${selectedExpressions.length})` }}
                </span>
              </v-btn>
            </v-col>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="7" class="d-flex mb-2 pl-6">
            <v-switch
              v-if="batchSelect"
              v-model="batchEdit"
              hide-details
              dense
              class="px-2"
            >
              <template v-slot:label>
                <p class="text-body-2 mb-0">Toggle batch select</p>
              </template></v-switch
            >
            <v-switch
              v-if="showCurrentUserExpressionsOnlyToggle"
              v-model="showCurrentUserExpressionsOnly"
              label="Show Only My Expressions"
              hide-details
              dense
              class="px-2"
            >
              <template v-slot:label>
                <p class="text-body-2 mb-0">Show my expressions only</p>
              </template></v-switch
            >
          </v-col>
          <v-col class="d-flex pr-6 justify-end align-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div id="expression-list-refresh" class="d-flex align-end">
                  <p class="mb-0 text-caption font-weight-medium">
                    Last Updated:
                    <span class="text-caption"> {{ lastUpdated }}</span>
                  </p>
                </div>
                <v-btn
                  class="pl-2"
                  :loading="expressionListLoading"
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  @click="refreshExpressionList"
                >
                  <v-icon> mdi-refresh </v-icon>
                </v-btn>
              </template>
              <span>Refresh the expressions list</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td class="text-caption" :colspan="headers.length">
          <span class="text-subtitle-2">Description:</span>
          {{ item.Description }}
        </td>
      </template>
      <template v-slot:item.CreatedAt="{ item }">
        {{ convertedDate(item.CreatedAt) }}
      </template>
      <template v-slot:item.ExpressionOwner="{ item }">
        <span>{{ item.ExpressionOwner }}</span>
      </template>
      <template v-slot:item.UpdatedAt="{ item }">
        {{ convertedDate(item.UpdatedAt) }}
      </template>
      <template v-slot:item.Tags="{ item }">
        <v-chip-group column>
          <v-chip
            v-for="(tag, i) in item.Tags"
            :key="i"
            x-small
            ripple
            @click.stop="updateFilterTags(tag)"
            :color="getChipColor(tag)"
          >
            {{ tag }}
          </v-chip>
        </v-chip-group>
      </template>
      <template v-slot:item.actions="{ item }">
        <td class="action-column">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click.stop="shareExpressions(item)"
              >
                mdi-share
              </v-icon>
            </template>
            <span>Share an Expression</span>
          </v-tooltip>
          <v-tooltip
            left
            v-if="
              item.ExpressionOwner ===
              $store.state.account.user.attributes.email
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click.stop="deleteExpressions(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Delete an Expression</span>
          </v-tooltip>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { getExpression, deleteExpression, formatDateString } from "@/api/v2.js";
import { sortCaseInsensitive } from "@/helpers/formatting.js";
import { getChipColor } from "@/helpers/chipColors.js";
import DataShare from "@/components/data/DataShare.vue";
import TagFilter from "@/components/data/TagFilter.vue";

export default {
  name: "ExpressionSelector",

  components: {
    DataShare,
    TagFilter,
  },

  props: {
    selectionMode: {
      type: Boolean,
      default: false,
    },
    batchSelect: {
      type: Boolean,
      default: true,
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    crudActions: {
      type: Boolean,
      default: true,
    },
    showCurrentUserExpressionsOnlyToggle: {
      type: Boolean,
      default: true,
    },
    defaultTags: {
      type: Array,
      default: () => []
    },
    dataTableHeight: {
      type: Number,
      default: 700
    },
  },

  data() {
    return {
      isLoading: false,
      expressionListLoading: false,
      tagFilterValues: [],
      selectedExpressions: [],
      search: "",
      expanded: [],
      dialogShare: false,
      dialogNewExpression: false,
      batchEdit: false,
      expressionsAfterTagFilter: [],
      clickedTag: null,
      showCurrentUserExpressionsOnly: false,
    };
  },

  computed: {
    expressions() {
      return this.$store.state.accountData.expressionList.expressions;
    },

    allExpressions() {
      return this.expressions.AllExpressions;
    },

    lastUpdated() {
      return formatDateString(
        this.$store.state.accountData.expressionList.lastUpdated
      );
    },

    currentUser() {
      return this.$store.state.account.user.attributes.email;
    },

    allTags() {
      return sortCaseInsensitive(this.expressions.AllTags);
    },

    currentUserExpressionsOnly() {
      if (this.allExpressions) {
        return this.allExpressions.filter((ex) => {
          return ex.ExpressionOwner === this.currentUser;
        });
      } else {
        return [];
      }
    },

    expressionsToBeFiltered() {
      return this.showCurrentUserExpressionsOnly
        ? this.currentUserExpressionsOnly
        : this.allExpressions;
    },

    defaultHeaders() {
      let headers = [
        { value: "data-table-expand" },
        {
          text: `Expression Name`,
          align: "start",
          sortable: true,
          value: `ExpressionName`,
        },
        // { text: "Units", value: "Units" },

        { text: "Created", value: "CreatedAt" },
        { text: "Created By", value: `ExpressionOwner` },
        { text: "Updated", value: "UpdatedAt" },
        {
          text: "Tags",
          value: "Tags",
          filter: this.filterByTag,
        },
      ];

      // render actions header if prop passed
      if (this.crudActions && !this.batchEdit) {
        headers.push({
          text: "Actions",
          value: "actions",
          sortable: false,
        });
      }

      return headers;
    },

    availableHeaders() {
      let availableHeaders = [];
      for (const [key] of Object.entries(this.expressions.AllExpressions[0])) {
        let header = {
          text: key.split(/(?=[A-Z])/).join(" "),
          value: key,
        };
        availableHeaders.push(header);
      }
      return availableHeaders;
    },
  },
  watch: {
    selectedExpressions() {
      if (this.selectedExpressions.length >= 1 && this.batchEdit) {
        this.batchEdit = true;
      } else {
        this.batchEdit = false;
      }
    },
  },

  methods: {
    getChipColor,

    sortCaseInsensitive,

    updatedExpressionsFilteredByTags(expression) {
      this.expressionsAfterTagFilter = expression;
    },

    // converts date to user local time
    convertedDate(utcDate) {
      let date = new Date(utcDate);
      let localDate = date.toLocaleString();
      return localDate;
    },

    // will skip filtering by tag if tag list is empty
    filterByTag(value) {
      if (!this.tagFilterValues.length > 0) {
        return true;
      }

      return this.tagFilterValues.every((tag) => value.includes(tag));
    },

    // updates tag list if tag clicked on within data table row
    updateFilterTags(tag) {
      this.clickedTag = tag;
    },

    // clear clicked tag
    clearClickedTag() {
      this.clickedTag = null;
    },

    // selects row from single click rather than checkbox
    rowSelected(item, data) {
      if (this.selectionMode) {
        this.emitExpression(data.item);
        this.selectedExpressions = [];
      }

      if (!data.isSelected) {
        this.selectedExpressions.push(data.item);
      }
      this.selectedExpressions.pop(data[data.index]);

      if (!this.singleSelect && !this.batchEdit) {
        this.editExpression(data.item);
      }
      if (this.singleSelect && !this.batchEdit && !this.selectionMode) {
        this.selectDatasets(this.selectedDatasets);
      }
    },

    emitExpression(expression) {
      this.$emit("expression-selected", expression);
    },

    async refreshExpressionList() {
      this.expressionListLoading = true;
      await this.$store.dispatch("accountData/refreshExpressionList");
      this.expressionListLoading = false;
    },

    // Crud Actions

    editExpression(expression) {
      this.editedIndex = this.expressions.AllExpressions.indexOf(expression);
      this.$router.push({
        name: "Expression Viewer",
        params: { inputExpr: expression },
      });
    },

    newExpression() {
      this.isLoading = true;
      this.$router.push({
        name: "Expression Viewer",
        params: { expressions: this.expressions },
      });
    },

    // Share Expressions

    shareExpressions(expression) {
      this.isLoading = true;
      if (!this.batchEdit) {
        this.selectedExpressions = [];
        getExpression(expression.ExpressionName, expression.ExpressionOwner)
          .then((expression) => {
            this.selectedExpressions.push(expression);
            this.dialogShare = true;
          })
          .catch((error) => {
            if (error.response.data.Warnings) {
              this.$showAlert({
                text: error.response.data.Warnings,
                type: "warning",
              });
            } else if (error.response.data.Errors) {
              this.$showAlert({
                text: error.response.data.Errors,
                type: "error",
              });
            } else {
              this.$showAlert({
                text: error,
                type: "error",
              });
            }
          });
      } else {
        this.dialogShare = true;
      }
    },

    closeShareDialog() {
      this.selectedExpressions = [];
      this.dialogShare = false;
    },

    // Delete Expressions

    // takes an array of or a single expressions as input
    deleteExpressions(expressions) {
      let isBatch = Array.isArray(expressions);

      if (!isBatch) {
        expressions = new Array(expressions);
      }
      const expressionNames = [];

      expressions.forEach((expression) => {
        expressionNames.push(expression.ExpressionName);
      });
      this.$showAlert({
        text:
          expressions.length > 1
            ? `<b>Are you sure you want to delete (${
                expressions.length
              }) expressions, this cannot be undone?</b> <br>- ${expressionNames.join(
                " <br>- "
              )}`
            : `<b>Are you sure you want to delete this expression, this cannot be undone?</b> <br>- ${expressions[0].ExpressionName}`,
        type: "warning",
        choice: true,
      }).then((confirmed) => {
        if (confirmed) {
          this.isLoading = true;
          let promises = [];

          for (let i = 0; i < expressions.length; i++) {
            promises.push(
              deleteExpression(
                expressions[i].ExpressionName,
                expressions[i].ExpressionOwner
              )
            );
          }

          Promise.all(promises)
            .then((response) => {
              if (response.Warnings) {
                this.$showAlert({
                  text: response.Warnings,
                  type: "warning",
                });
              }
              this.refreshExpressionList();
              this.$showAlert({
                text:
                  expressions.length > 1
                    ? `(${expressions.length}) Expressions were successfully deleted`
                    : `The Expression was successfully deleted`,
                timeout: 4000,
                type: "success",
              });
            })
            .catch((errors) => {
              if (errors) {
                this.$showAlert({
                  text: errors,
                  type: "error",
                });
              }
              if (errors.response.data.Errors) {
                this.$showAlert({
                  text: errors.response.data.Errors,
                  type: "error",
                });
              }
            })
            .finally(() => {
              this.selectedExpressions = [];
              this.isLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.data-table >>> tbody td {
  cursor: pointer;
}

.data-table >>> tbody td.action-column {
  cursor: default;
}

#expression-list-refresh {
  text-align: center;
}

.embedded-card {
  color: red;
}
</style>

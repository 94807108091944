<template>
  <transition name="fade">
    <div id="splash-loader">
      <div id="logo">
        <v-img
          :src="require(`@/assets/img/alces_flow_white.png`)"
          contain
          max-height="50vh"
        ></v-img>
      </div>
      <div id="progress-container" class="pt-10">
        <v-progress-linear
          color="light-green darken-4"
          height="20"
          rounded
          stream
          :buffer-value="value"
        ></v-progress-linear>
        <h1 class="text-h5 pt-6">{{ message }}</h1>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "TheSplashScreen",
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      value: 0,
      message: "",
      interval: 0,
      loading: [
        { value: 30, message: "Initializing ALCES Flow" },
        { value: 60, message: "Loading user data" },
        { value: 90, message: "Finishing up" },
      ],
    };
  },

  methods: {
    startBuffer() {
      let index = 0;
      let currentStep = 0;
      this.value = currentStep;
      this.message = this.loading[index].message;

      this.interval = setInterval(() => {
        if (
          currentStep > this.loading[index].value &&
          index < this.loading.length - 1
        ) {
          this.message = this.loading[index].message;
          index += 1;
        }

        this.value = currentStep;

        if (currentStep === 99) {
          this.interval = clearInterval(this.interval);
        } else if (currentStep > 90) {
          currentStep += 1;
        } else if (currentStep > 60) {
          currentStep += 5;
        } else if (currentStep > 30) {
          currentStep += 10;
        } else {
          currentStep += 10;
        }
      }, 500);
    },
  },

  mounted() {
    this.startBuffer();
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style>
#splash-loader {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(163, 218, 223);
  background: radial-gradient(
    circle,
    rgba(163, 218, 223, 1) 0%,
    rgba(101, 127, 147, 1) 100%
  );
  color: black;
  display: block;
  position: fixed;
  overflow: hidden;
  padding-top: 20vh;
  text-align: center;
  z-index: 100;
  margin: auto;
}

#logo {
  margin: auto;
}

#progress-container {
  max-width: 50vw;
  margin: auto;
}

.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-leave-to {
  opacity: 0;
}
</style>

<template>
  <div class="cm-merge-editor">
    <div
      v-if="bufferA === bufferB"
      style="
        height: 100ch;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <span style="color: #abb2bf; font-size: 3em"> No changes </span>
    </div>

    <div id="sequence-text-area" v-show="bufferA !== bufferB"></div>
  </div>
</template>

<script>
import { EditorState, Compartment } from "@codemirror/state";
import { EditorView } from "@codemirror/view";
import { unifiedMergeView } from "@codemirror/merge";
import { oneDark } from "@codemirror/theme-one-dark";
import { json5 } from "codemirror-json5";
export default {
  name: "SequenceMerge",

  props: {
    bufferA: {
      type: String,
      default: "",
    },
    bufferB: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      view: null,
      bufferACompartment: null,
    };
  },

  methods: {},

  mounted() {
    console.log("mounting merge");
    this.bufferACompartment = new Compartment();
    this.view = new EditorView({
      state: EditorState.create({
        doc: this.bufferB !== null ? this.bufferB : "",
        extensions: [
          json5(),
          EditorView.editable.of(false),
          oneDark,
          this.bufferACompartment.of(
            unifiedMergeView({
              original: this.bufferA !== null ? this.bufferA : "",
              gutters: true,
              mergeControls: false,
            })
          ),
        ],
      }),
      parent: document.getElementById("sequence-text-area"),
    });
  },
};
</script>

<style scoped>
.v-system-bar {
  /* background-color: #282C34 !important; */
  /* color: #ABB2BF !important; */
}

.cm-merge-editor {
  max-height: calc(100vh - 3em);
  width: calc(100% - 18.2em);
  overflow-y: auto;
}
</style>

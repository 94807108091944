<template>
  <div v-if="chartDatasets.length > 0" class="data-chart pa-2">
    <v-card light flat class="pa-2">
      <v-container class="pa-0">
        <v-row>
          <v-col class="d-flex justify-start" v-if="!chartPopup">
            <v-btn icon small @click="showChartPopup">
              <v-icon
                small
                class="chart-open-popup"
                :color="$store.state.settings.appColor"
              >
                mdi-open-in-new
              </v-icon>
            </v-btn>
          </v-col>
          <v-col class="d-flex justify-end" v-else>
            <v-btn icon small @click="showChartPopup">
              <v-icon small color="error"> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <apexchart
        width="100%"
        type="line"
        :options="chartOptions"
        :series="series"
      ></apexchart>
      <v-container class="chart-selectors py-1">
        <v-row>
          <v-col>
            <v-radio-group
              v-show="chartStatsPicked !== 'feature-values'"
              class="chart-stats-selector mt-0"
              v-model="chartStatsPicked"
              row
              dense
              hide-details
            >
              <v-radio
                class="text-caption"
                value="avg-stats"
                :disabled="!currentDatasetTypes.includes('Continuous')"
                :color="$store.state.settings.appColor"
              >
                <template v-slot:label>
                  <p class="text-caption mb-0">Average</p>
                </template></v-radio
              >
              <v-radio
                value="sum-stats"
                :disabled="!currentDatasetTypes.includes('Continuous')"
                :color="$store.state.settings.appColor"
              >
                <template v-slot:label>
                  <p class="text-caption mb-0">Sum</p>
                </template>
              </v-radio>
              <v-radio
                value="area-categories"
                :disabled="!currentDatasetTypes.includes('Categorical')"
                :color="$store.state.settings.appColor"
              >
                <template v-slot:label>
                  <p class="text-caption mb-0">Categories Areas</p>
                </template></v-radio
              >
            </v-radio-group>
          </v-col>
          <v-col v-if="chartTypes.length > 1">
            <v-select
              class="chart-type-selector"
              @change="updateChartType"
              v-model="selectedChart"
              :items="chartTypes"
              :item-color="$store.state.settings.appColor"
              dense
              hide-details
              >none</v-select
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
  <div
    v-else-if="allDatasets.length > 0 && chartDatasets.length < 1"
    class="data-chart pa-2"
  >
    <v-card
      light
      flat
      class="d-flex flex-column pa-2 justify-center align-center"
      height="350"
    >
      <v-card-title
        class="grey--text text-center"
        style="word-break: break-word"
      >
        Select a time series layer from the layer list to populate chart.
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        <v-icon large color="grey">mdi-chart-line</v-icon>
        <v-icon large color="grey">mdi-chart-bar</v-icon>
        <v-icon large color="grey">mdi-chart-line-stacked</v-icon>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

import { convertStringToDate, formatNumber } from "@/helpers/formatting";

export default {
  components: {
    apexchart: VueApexCharts,
  },

  name: "DataChart",

  props: {},

  data() {
    return {
      chartPopup: false,
      series: [],
      //   Base Chart settings
      chartOptions: {
        chart: {
          type: "line",
          zoom: {
            enabled: true,
            type: "xy",
            autoScaleYaxis: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 3,
        },
        legend: {
          markers: {
            width: 9,
            height: 9,
            radius: 9,
          },
          horizontalAlign: "left",
          showForSingleSeries: true,
        },
        xaxis: {
          type: "datetime",
          tickPlacement: "on",
          labels: {
            datetimeFormatter: {
              year: "yyyy",
              month: "",
              day: "",
              hour: "",
            },
            rotate: 0,
          },
        },
        yaxis: {
          logarithmic: true,
          labels: {
            formatter: (value) =>
              typeof value === "number"
                ? formatNumber(value)
                : typeof value === "undefined"
                ? "no data"
                : value,
          },
        },
        stroke: {
          curve: "straight",
          width: 1.5,
        },
        tooltip: {
          enabled: true,
          followCursor: true,

          // TODO: currently cannot share tooltip with datasets that do not have the same time range, uncaught error:
          // TypeError: can't access property "toExponential", val is null.
          shared: false,
          x: {
            format: "yyyy",
          },
          y: {
            formatter: (value) =>
              typeof value === "number"
                ? formatNumber(value)
                : typeof value === "undefined"
                ? "no data"
                : value,
            title: {
              formatter: (seriesName) =>
                this.chartStatsPicked === "feature-values" ||
                this.chartStatsPicked === "area-categories"
                  ? seriesName
                  : seriesName.replace(seriesName, ""),
            },
          },
        },
      },

      selectedChart: "line",
      chartTypes: ["line", "area", "bar"],
      chartStatsPicked: "avg-stats",
    };
  },

  computed: {
    clientChartSettings() {
      return this.$store.state.settings.dataViewerSettings.chartSettings;
    },

    chartDatasets() {
      return this.$store.state.mapping.chartData;
    },
    allDatasets() {
      return this.$store.state.mapping.datasets;
    },

    // returns first datasets group features by
    visibleFeatures() {
      if (this.chartDatasets.length > 0) {
        return this.chartDatasets[0].visibleFeatures;
      } else return false;
    },

    buildData() {
      let series = [];
      this.chartDatasets.forEach((dataset) => {
        let singleSeries = [];
        let categorySeries = [];
        let featureSeries = [];

        // grab unique features from dataset
        if (this.chartStatsPicked === "feature-values") {
          const singleFileFeatureValues = dataset.visibleFeatures;

          featureSeries = singleFileFeatureValues.map((category) => ({
            name: category,
            data: [],
          }));
        }

        // grab unique categories from first file
        else if (dataset.layer.TypeOfData === "Categorical") {
          const singleFileStats = dataset.files[0].stats.fullResolutionStats
            ? dataset.files[0].stats.fullResolutionStats
            : dataset.files[0].stats.lowResolutionStats;

          categorySeries = singleFileStats.CategoryNames.map((category) => {
            const newCategory = { name: category, data: [] };
            return newCategory;
          }).filter(
            (category) => !dataset.hiddenCategories.includes(category.name)
          );
        }

        let datasetName = `${dataset.dataset.Style.DisplayName}`;

        dataset.files.forEach((ds) => {
          if (ds.time.start !== "ind") {
            let y;
            let x;

            let currentStats = ds.stats.fullResolutionStats
              ? ds.stats.fullResolutionStats
              : ds.stats.lowResolutionStats;

            const isQueryLayer =
              this.$store.state.mapping.queryBuilderData.find(
                (ds) => ds.queryLayer.key === dataset.key
              );

            const limitedMapTimeBounds =
              this.$store.state.mapping.limitedMapTimeBounds;

            const isWithinTimeRange = (date) => {
              if (
                isQueryLayer &&
                limitedMapTimeBounds.min &&
                limitedMapTimeBounds.max
              )
                return (
                  convertStringToDate(date) >= limitedMapTimeBounds.min &&
                  convertStringToDate(date) <= limitedMapTimeBounds.max
                );
              else return true;
            };

            if (this.chartStatsPicked === "avg-stats") {
              x = new Date(ds.time.start);
              y = parseFloat(currentStats.Mean);

              if (isWithinTimeRange(ds.time.start))
                singleSeries.push({ x: x, y: y });
            }
            //
            else if (this.chartStatsPicked === "sum-stats") {
              x = new Date(ds.time.start);
              y = currentStats.Sum;

              if (isWithinTimeRange(ds.time.start))
                singleSeries.push({ x: x, y: y });
            }
            //
            else if (this.chartStatsPicked === "area-categories") {
              categorySeries.map((category) => {
                for (let i = 0; i < currentStats.CategoryNames.length; i++) {
                  if (category.name === currentStats.CategoryNames[i]) {
                    x = new Date(ds.time.start);
                    y = this.convertSqmToSkm(currentStats.CategoryAreas[i]);
                    const newSeriesValue = { x: x, y: y };

                    if (isWithinTimeRange(ds.time.start))
                      category.data.push(newSeriesValue);
                  }
                }
              });
            }
            //
            else if (this.chartStatsPicked === "feature-values") {
              currentStats = ds.stats.featureValueStats;
              featureSeries.forEach((feature) => {
                const featureName = feature.name;
                x = new Date(ds.time.start);
                y = currentStats[featureName];
                const newSeriesValue = { x: x, y: y };
                if (isWithinTimeRange(ds.time.start))
                  feature.data.push(newSeriesValue);
              });
            }
          }
        });

        if (this.chartStatsPicked === "feature-values") {
          series = featureSeries;
        } else if (this.chartStatsPicked === "area-categories") {
          series = categorySeries;
        } else {
          series.push({ name: datasetName, data: singleSeries });
        }
      });

      return series;
    },

    currentDatasetTypes() {
      return this.chartDatasets.map((dataset) => dataset.layer.TypeOfData);
    },
  },

  // watches changes in the length of the datasets available and runs updateChart, does not run when removing the last dataset from the map.
  watch: {
    buildData(series) {
      if (this.chartDatasets.length > 0) {
        this.updateChart(series);
      }
    },

    chartDatasets(newDatasets) {
      if (newDatasets.length > 0) {
        // set updateSelectedType to first dataset type
        if (
          this.chartDatasets[0].groupFeaturesBy === "" ||
          this.chartDatasets[0].groupFeaturesBy === null
        ) {
          this.updateSelectedType(this.chartDatasets[0].layer.TypeOfData);
        } else {
          this.updateSelectedType("FeatureValues");
        }
      }
    },

    visibleFeatures() {
      if (this.chartDatasets.length > 0) {
        const groupByValue = this.chartDatasets[0].groupFeaturesBy;
        // set updateSelectedType to first dataset type
        if (groupByValue === "" || groupByValue === null) {
          this.updateSelectedType(this.chartDatasets[0].layer.TypeOfData);
        } else {
          this.updateSelectedType("FeatureValues");
        }
      }
    },
  },

  methods: {
    // Updates series data and yaxis values and labels, runs when new data is added, or when the user selects how the stats are displayed.
    updateChart(series) {
      this.series = series;
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          yaxis: {
            tickAmount: 5,
            labels: {
              formatter: (value) =>
                typeof value === "number"
                  ? formatNumber(value)
                  : typeof value === "undefined"
                  ? "no data"
                  : value,
            },
            title: {
              text: [
                this.chartStatsPicked === "avg-stats"
                  ? `${this.chartDatasets[0].layer.Units} - average`
                  : this.chartStatsPicked === "sum-stats"
                  ? `${this.chartDatasets[0].layer.Units} - sum`
                  : this.chartStatsPicked === "area-categories"
                  ? "Area - km2"
                  : this.chartStatsPicked === "feature-values"
                  ? this.chartDatasets[0].layer.Units
                  : "unknown",
              ],
            },
          },
        },
      };
    },

    convertSqmToSkm(value) {
      let km2 = value / 1000000;
      // let newValue = 0;
      // if (km2 === 0.0) newValue = 0;
      // if (km2 <= 0.01) newValue = parseFloat(km2.toPrecision(4));
      // else newValue = parseFloat(km2.toFixed(2));
      return km2;
    },

    showChartPopup() {
      // this.$store.commit("mapping/cloneDataChart", this.$data)
      this.$emit("chart-popup-overlay");
      this.chartPopup = !this.chartPopup;
    },

    // allows the user to choose desired chart type.
    updateChartType() {
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          chart: {
            type: this.selectedChart,
          },
        },
      };
    },

    updateSelectedType(type) {
      this.chartStatsPicked =
        type === "Categorical"
          ? "area-categories"
          : type === "Continuous"
          ? "avg-stats"
          : "feature-values";
    },
  },

  created() {
    // set default settings
    this.chartTypes = this.clientChartSettings.chartTypes;
    this.selectedChart = this.chartTypes[0];
    this.updateChartType(); // set default chart type
  },
};
</script>

<style scoped>
.chart-selectors {
  display: flex;
  align-items: center;
}
.chart-type-selector {
  margin-top: 0;
  padding-top: 3px;
}
.chart-open-popup {
  transform: rotateY(180deg);
}
</style>

<template>
  <div class="d-flex justify-center align-center pa-5" v-if="initializing">
    <v-progress-circular indeterminate :color="color"></v-progress-circular>
  </div>
  <div v-else>
    <v-row>
      <v-col sm="6">
        <v-text-field
          type="number"
          v-model="modelParams.min"
          :color="color"
          label="Minimum Value"
          @input="update"
        ></v-text-field>
      </v-col>
      <v-col sm="6">
        <v-text-field
          type="number"
          v-model="modelParams.max"
          :color="color"
          label="Maximum Value"
          @input="update"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Normalize",

  props: { params: Object },

  data() {
    return {
      initializing: true,
      modelParams: {
        min: 0,
        max: 1,
      },
    };
  },

  computed: {
    color() {
      return this.$store.state.settings.appColor;
    },
  },

  methods: {
    update() {
      this.$emit("submit-params", this.modelParams);
    },
  },

  beforeMount() {
    this.modelParams = { ...this.modelParams, ...this.params };
    this.update()
    setTimeout(() => (this.initializing = false), 100);
  },
};
</script>

<template>
  <div>
    <v-skeleton-loader v-if="dataLoading" type="card"></v-skeleton-loader>

    <div v-else class="map" id="map" ref="mapContainer"></div>
    <div
      v-if="map"
      id="info"
      @click="showMapInfo"
      title="Display zoom and coordinates"
    >
      <v-icon id="info-icon" style="display: none" color="white"
        >mdi-information</v-icon
      >
      <pre id="coordinates">{{
        "zoom: " + JSON.stringify(this.currentMouseCoordinates)
      }}</pre>
      <pre id="zoom"> {{ "zoom: " + JSON.stringify(this.currentZoom) }}</pre>
    </div>
    <map-data-viewer-print-overlay
      v-if="map"
      :mapCanvas="$refs.mapContainer"
      :map="map"
      :accessToken="mbglToken"
    ></map-data-viewer-print-overlay>

    <map-data-viewer-help-overlay
      v-if="$store.state.settings.dataViewerSettings.helpOverlayDetails"
      class="help-button"
    ></map-data-viewer-help-overlay>

    <map-data-viewer-time-slider
      v-if="mapTime"
      id="time-slider"
      :map-times="mapTimes"
      :datasets="datasets"
      :disallow-timestep-selection="layerControlsTimestep"
      @map-time-updated="updateMapTime"
    ></map-data-viewer-time-slider>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { filesFromDataset } from "@/api/mapping";
import { getDataset } from "@/api/v2";
import { convertStringToDate, formatNumber } from "@/helpers/formatting";

import MapDataViewerTimeSlider from "@/components/mapping/MapDataViewerTimeSlider.vue";
import MapDataViewerPrintOverlay from "@/components/mapping/MapDataViewerPrintOverlay.vue";
import MapDataViewerHelpOverlay from "@/components/mapping/MapDataViewerHelpOverlay.vue";

import {
  DPI,
  Format,
  MapboxExportControl,
  PageOrientation,
  Size,
} from "@watergis/mapbox-gl-export";
import "@watergis/mapbox-gl-export/css/styles.css";
import * as mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

export default {
  name: "MapDataViewer",

  components: {
    MapDataViewerTimeSlider,
    MapDataViewerPrintOverlay,
    // DocumentationControl,
    MapDataViewerHelpOverlay,
  },

  props: {
    defaultDatasets: { type: Array, default: () => [] },
    // parentResize - true/false has no meaning, prop to trigger map.resize() on change
    parentResize: { type: Boolean, value: false },
    allowDefaultData: { type: Boolean, value: false },
  },

  data() {
    return {
      map: null,
      dataLoading: true,
      mapTimes: [],
      minMapTimeAllowed: null,
      maxMapTimeAllowed: null,
      mapTime: null,
      layerTimes: {},
      studyAreaExtents: [],
      authData: null,
      currentZoom: 0,
      currentMouseCoordinates: { lng: "", lat: "" },
      mapInfoCondensed: false,
      datasetsMinZoom: null,
      form: {
        outputOptions: "png",
        unitOptions: "mm",
        styleSelect: null,
      },
      showPrintableMapElements: false,
    };
  },

  computed: {
    mbglToken() {
      return this.$store.state.mapping.mbglToken;
    },

    clientMapSettings() {
      return this.$store.state.settings.mapSettings;
    },

    layerControlsTimestep() {
      return this.$store.state.settings.dataViewerSettings
        .layerControlsTimestep;
    },

    studyAreaBounds() {
      let bounds;
      if (this.studyAreas.length > 0) {
        bounds = this.getBounds(this.studyAreas[0].files[0]);
      } else bounds = null;
      return bounds;
    },

    mapOptions() {
      return {
        container: "map",
        center: this.clientMapSettings.center,
        zoom: this.clientMapSettings.zoom,
        maxBounds: [
          this.clientMapSettings.maxMapBounds.sw,
          this.clientMapSettings.maxMapBounds.ne,
        ],
        style: this.basemap,
        accessToken: this.mbglToken,
        attributionControl: false,
        transformRequest: (url, resourceType) => {
          const tempUrl = new URL(url);
          const urlParams = new URLSearchParams(tempUrl.search);

          if (resourceType === "Tile" && urlParams.has("data-layer")) {
            // need to replace any spaces with a +
            const dataLayer = urlParams.get("data-layer").replaceAll(" ", "+");
            tempUrl.searchParams.delete("data-layer");
            return {
              url: tempUrl.href,
              headers: {
                "data-layer": dataLayer,
                Authorization: "Bearer " + this.authData.idToken.jwtToken,
              },
            };
          }
        },
        height: "100%",
        width: "100%",
        // onStyleLoad: (map) => map.resize(),

        // TODO:export image of map
        preserveDrawingBuffer: true,
      };
    },
    firstSymbolId() {
      let firstSymbolId;
      let mapboxLayers = this.map.getStyle().layers;
      for (let i = 0; i < mapboxLayers.length; i++) {
        if (mapboxLayers[i].type === "symbol") {
          firstSymbolId = mapboxLayers[i].id;
          break;
        }
      }
      return firstSymbolId;
    },
    basemap() {
      return this.$store.state.mapping.currentBasemap.url;
    },
    datasets() {
      return this.$store.state.mapping.datasets;
    },

    visibleDatasets() {
      return this.$store.state.mapping.visibleDatasets;
    },
    studyAreas() {
      return this.$store.state.mapping.datasets.filter(
        (dataset) => dataset.mapLayerPurpose === "StudyAreaMapLayer"
      );
    },

    flowMapboxLayers() {
      return this.map
        .getStyle()
        .layers.filter(
          (layer) =>
            layer.id.startsWith("flow") ||
            layer.id.startsWith("studyAreaMask") ||
            layer.id.startsWith("queryMask")
        );
    },
    mapboxLayers() {
      return this.map.getStyle().layers;
    },
    mapboxSources() {
      return this.map.getStyle().sources;
    },
    flowLayerFeatures() {
      const features = [];
      this.flowMapboxLayers.forEach((layer) => {
        if (layer.filter) {
          features.push(this.findLayerFeatures(layer.id, layer.filter));
        } else {
          features.push(this.findLayerFeatures(layer.id));
        }
      });

      return features;
    },

    layerStyleUpdating() {
      return this.$store.state.mapping.layerStyleUpdating;
    },

    layerStyles() {
      let layerStyles = {};
      if (this.$store.state.mapping.layerStyleUpdating) {
        let filteredDatasets = this.datasets.filter(
          (dataset) =>
            dataset.key === this.$store.state.mapping.layerStyleUpdating
        );
        layerStyles = filteredDatasets[0];
      }
      return layerStyles;
    },

    layerColorUpdating() {
      return this.$store.state.mapping.layerColorUpdating;
    },

    layerFilterUpdating() {
      return this.$store.state.mapping.layerFilterUpdating;
    },

    layerQueryUpdating() {
      return this.$store.state.mapping.layerQueryUpdating;
    },

    zoomToLayerExtent() {
      return this.$store.state.mapping.zoomToExtent;
    },

    // access prop from parent
    resizeMap() {
      return this.parentResize;
    },

    queryBuilderData() {
      return this.$store.state.mapping.queryBuilderData;
    },

    queryBuilderLayerMasks() {
      return this.$store.state.mapping.queryBuilderLayerMasks;
    },
  },

  watch: {
    basemap(newVal) {
      if (this.map) this.map.setStyle(newVal);
    },
    datasets() {
      this.addDatasets("Add datasets watcher called this");
    },

    visibleDatasets(newVisibleDatasets) {
      this.datasets.forEach((dataset) => {
        if (newVisibleDatasets.includes(dataset.key)) {
          dataset.files.forEach((file) => {
            // only allow map layers to be visible if layer intersects the current mapTime
            if (
              this.dataSourceTimeIntersects(
                this.mapTime,
                this.layerTimes[file.mapLayer.id]
              )
            )
              this.map.setLayoutProperty(
                file.mapLayer.id,
                "visibility",
                "visible"
              );
          });
        } else {
          dataset.styles.layerIDs.forEach((layer) =>
            this.map.setLayoutProperty(layer, "visibility", "none")
          );
        }
      });
    },

    // studyBounds() {
    //   this.map.fitBounds(this.studyBounds);
    // },

    mapTime() {
      this.datasets.forEach((dataset) => {
        // only allow map layers to be visible if dataset is toggled as visible
        if (dataset.visible === "visible") {
          dataset.styles.layerIDs.forEach((layerID) => {
            if (
              this.dataSourceTimeIntersects(
                this.mapTime,
                this.layerTimes[layerID]
              )
            ) {
              this.map.setLayoutProperty(layerID, "visibility", "visible");

              this.$store.commit("mapping/updateLayerFileVisibility", {
                datasetKey: dataset.key,
                layerID: layerID,
                visibility: "visible",
              });
            } else {
              this.map.setLayoutProperty(layerID, "visibility", "none");
              this.$store.commit("mapping/updateLayerFileVisibility", {
                datasetKey: dataset.key,
                layerID: layerID,
                visibility: "none",
              });
            }
          });
        }
      });
      this.queryBuilderLayerMasks.forEach((dataset) => {
        const currentDataset = dataset.dataLayer;
        currentDataset.styles.layerIDs.forEach((layerID) => {
          if (
            this.dataSourceTimeIntersects(
              this.mapTime,
              this.layerTimes[layerID]
            )
          ) {
            this.map.setLayoutProperty(layerID, "visibility", "visible");
            this.$store.commit("mapping/updateMaskLayerFileVisibility", {
              datasetKey: dataset.dataLayer.key,
              layerID: layerID,
              visibility: "visible",
            });
          } else {
            this.map.setLayoutProperty(layerID, "visibility", "none");
            this.$store.commit("mapping/updateMaskLayerFileVisibility", {
              datasetKey: dataset.dataLayer.key,
              layerID: layerID,
              visibility: "none",
            });
          }
        });
      });
    },

    layerColorUpdating(updatedLayers) {
      if (updatedLayers) {
        updatedLayers.files.forEach((file) => {
          this.flowMapboxLayers.forEach((lyr) => {
            if (lyr.id === file.mapLayer.id) {
              if (updatedLayers.dataset.DatasetType === "Raster") {
                const source = this.map.getSource(lyr.source);
                // update source (mapbox 2.13 will have official methods for resetting tiles)
                source.url = file.mapLayer.source.url;
                source._options.url = file.mapLayer.source.url;
                source.load(() => this.map.style._clearSource(lyr.source));
              }

              // vector
              else {
                this.map.setPaintProperty(
                  lyr.id,
                  "fill-color",
                  file.mapLayer.paint["fill-color"]
                );
              }
            }
          });
        });

        this.$store.commit("mapping/clearLayerColorUpdating");
      }
    },

    layerFilterUpdating(updatedLayers) {
      if (updatedLayers) {
        updatedLayers.files.forEach((file) => {
          this.flowMapboxLayers.forEach((lyr) => {
            if (lyr.id === file.mapLayer.id) {
              if (
                updatedLayers.groupFeaturesBy === "" ||
                updatedLayers.groupFeaturesBy === null
              ) {
                // select everything
                this.map.setFilter(file.mapLayer.id, null);
              } else {
                // selected nothing
                if (updatedLayers.visibleFeatures.length === 0) {
                  this.map.setFilter(file.mapLayer.id, false);
                } else {
                  // selects checked only features
                  this.map.setFilter(file.mapLayer.id, [
                    "match",
                    ["get", updatedLayers.groupFeaturesBy],
                    updatedLayers.visibleFeatures.map((feature) => {
                      return feature;
                    }),
                    true,
                    false,
                  ]);
                }
              }
            }
          });
        });
        this.$store.commit("mapping/clearLayerFilterUpdating");
      }
    },

    layerQueryUpdating(updatedTime) {
      if (updatedTime) {
        this.addDatasets("layer query updated called this");
        this.$store.commit("mapping/updateQueryRefreshTime", null);
      }
    },

    // Watches opacityValue of current dataset being updated and updates mapbox opacity values
    "layerStyles.styles.opacityValue"() {
      if (typeof this.layerStyles !== "undefined") {
        this.layerStyles.files.forEach((file) => {
          this.map.setPaintProperty(
            file.mapLayer.id,
            `${file.mapLayer.type}-opacity`,
            this.layerStyles.styles.opacityValue / 100
          );
        });
      } else {
        this.$store.commit("mapping/clearLayerStyleUpdating");
      }
    },

    zoomToLayerExtent(currentVisibleLayerFile) {
      // clear store value
      this.$store.commit("mapping/zoomToLayerExtent", null);
      if (currentVisibleLayerFile !== null) {
        const bounds = this.getBounds(currentVisibleLayerFile);

        if (bounds) {
          this.zoomTo(bounds);
        }
      }
    },

    // function to resize map to fit parent container, currently this function is called when data viewer drawers are opened
    // TODO: resize on window change
    resizeMap() {
      this.map.resize();
    },
  },

  methods: {
    loadDefaultData() {
      this.dataLoading = true;

      const availableDatasets = this.defaultDatasets.filter((ds) => {
        return ds && ds.DatasetName;
      });

      // TODO:change default dataset to
      Promise.all(
        availableDatasets.map((availableDataset) => {
          return getDataset(
            availableDataset.DatasetName,
            availableDataset.DatasetOwner
          );
        })
      )
        .then((datasets) => {
          Promise.all(
            datasets.map((dataset, idx) => {
              return filesFromDataset(
                dataset.DatasetName,
                dataset.item,
                availableDatasets[idx].filter_value
              );
            })
          )
            .then((allFeatures) => {
              allFeatures.forEach((features, datasetIdx) => {
                const outputDataset = {
                  dataset: datasets[datasetIdx],
                  files: features,
                  feature: availableDatasets[datasetIdx].filter_value,
                };

                this.$store.commit("mapping/addDataset", outputDataset);
              });
            })
            .catch((err) => {
              this.$showAlert({
                text: err,
                type: "error",
              });
            });
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },

    // getUniqueFeatures(features, comparatorProperty) {
    //   const uniqueIds = new Set();
    //   const uniqueFeatures = [];
    //   for (const feature of features) {
    //     const id = feature.properties[comparatorProperty];
    //     if (!uniqueIds.has(id)) {
    //       uniqueIds.add(id);
    //       uniqueFeatures.push(feature);
    //     }
    //   }
    //   return uniqueFeatures;
    // },

    updateMapTimes() {
      let allTimes = new Set();

      this.datasets.forEach((dataset) => {
        if (dataset.layer.Timestep) {
          dataset.files.forEach((file) => {
            const time = file.time.start;
            // add to set to create list of unique times
            allTimes.add(time);
          });
        }
      });

      // format time string to datetime
      const uniqueTimes = [...allTimes].map((time) =>
        convertStringToDate(time)
      );

      // sort times in ascending order
      const sortedTimes = uniqueTimes.sort((a, b) => {
        return a - b;
      });

      let filteredTimes = sortedTimes;

      let allQueryLayerTimes = new Set();
      let allTargetLayerTimes = new Set();

      // let allLayerTimes = new Set();

      let uniqueQueryLayerTimes = null;
      let uniqueTargetLayerTimes = null;

      if (this.queryBuilderData.length > 0) {
        const queryBuilderDataset = this.queryBuilderData[0];

        if (Object.keys(queryBuilderDataset.queryLayer).length > 0) {
          if (this.queryBuilderLayerMasks.length > 0) {
            this.queryBuilderLayerMasks.forEach((dataset) => {
              if (dataset.dataLayer.layer.Timestep) {
                dataset.dataLayer.files.forEach((file) => {
                  const timeStart = file.time.start;
                  const timeEnd = file.time.end;
                  // add to set to create list of unique times
                  allTargetLayerTimes.add(timeStart);
                  allTargetLayerTimes.add(timeEnd);
                });
              }
            });
          }

          uniqueTargetLayerTimes =
            [...allTargetLayerTimes].length > 0
              ? [...allTargetLayerTimes]
                  .map((time) => convertStringToDate(time))
                  .sort((a, b) => {
                    return a - b;
                  })
              : null;

          // only need to limit times if
          if (uniqueTargetLayerTimes) {
            if (queryBuilderDataset.queryLayer.layer.Timestep) {
              queryBuilderDataset.queryLayer.files.forEach((file) => {
                const timeStart = file.time.start;
                const timeEnd = file.time.end;
                // add to set to create list of unique times
                allQueryLayerTimes.add(timeStart);
                allQueryLayerTimes.add(timeEnd);
              });

              uniqueQueryLayerTimes = [...allQueryLayerTimes]
                .map((time) => convertStringToDate(time))
                .sort((a, b) => {
                  return a - b;
                });

              const minMax = (items) => {
                return items.reduce((acc, val) => {
                  acc[0] = acc[0] === undefined || val < acc[0] ? val : acc[0];
                  acc[1] = acc[1] === undefined || val > acc[1] ? val : acc[1];
                  return acc;
                }, []);
              };

              const reduceDates = (items, minMax) => {
                return items.filter(
                  (item) => item >= minMax[0] && item <= minMax[1]
                );
              };

              // trim target layer times to fit within the min max of the query layer times
              const reducedTargetLayersTimes = reduceDates(
                uniqueTargetLayerTimes,
                minMax(uniqueQueryLayerTimes)
              );

              // trim query layer times if the extend past target layer times
              const reducedQueryLayersTimes = reduceDates(
                uniqueQueryLayerTimes,
                minMax(reducedTargetLayersTimes)
              );

              const minStartMaxEndTimes = minMax(reducedQueryLayersTimes);

              this.$store.commit("mapping/setLimitedMapTimeBounds", {
                min: minStartMaxEndTimes[0],
                max: minStartMaxEndTimes[1],
              });

              this.mapTimes = reduceDates(
                uniqueTargetLayerTimes,
                minStartMaxEndTimes
              );
            } else {
              this.$store.commit("mapping/setLimitedMapTimeBounds", {
                min: uniqueTargetLayerTimes[0],
                max: uniqueTargetLayerTimes[1],
              });

              uniqueTargetLayerTimes.length = 1;

              this.mapTimes = uniqueTargetLayerTimes;
            }
          }
        }

        // limit target data to query layer extent
        // use min and max of target layer to further reduce query layer
      } else {
        this.$store.commit("mapping/setLimitedMapTimeBounds", {
          min: null,
          max: null,
        });

        this.mapTimes = filteredTimes;
      }

      this.updateMapTime(this.mapTimes[0]);
    },

    // sets maps time
    updateMapTime(newTime) {
      this.mapTime = newTime;
    },

    // checks if map layers intersect mapTime from slider
    dataSourceTimeIntersects(currentStep, dataSourceTime) {
      const start =
        dataSourceTime.start === "ind"
          ? dataSourceTime.start
          : convertStringToDate(dataSourceTime.start);
      const end =
        dataSourceTime.start === "ind"
          ? dataSourceTime.start
          : convertStringToDate(dataSourceTime.end);

      let intersects = false;

      if (start === "ind" && end === "ind") intersects = true;
      else if (start === "ind" && currentStep < end) intersects = true;
      else if (end === "ind" && currentStep >= start) intersects = true;
      else if (currentStep >= start && currentStep < end) {
        intersects = true;
      }

      return intersects;
    },

    initMap() {
      if (this.dataLoading) {
        // The style will exist when the user has been collected
        setTimeout(this.initMap, 100);
        return;
      }

      // Initiate the map instance
      if (this.map) {
        this.map.remove();
        this.map = null;
      }

      this.map = new mapboxgl.Map(this.mapOptions);

      this.map.addControl(
        new mapboxgl.AttributionControl({
          compact: true,
        })
      );

      this.map.addControl(
        new mapboxgl.NavigationControl("bottom-right"),
        "bottom-right"
      );

      // create control with specified options
      this.map.addControl(
        new MapboxExportControl({
          accessToken: this.mbglToken,
          PageSize: Size.A3,
          PageOrientation: PageOrientation.Portrait,
          Format: Format.PNG,
          DPI: DPI[96],
          Crosshair: true,
          PrintableArea: true,
        }),
        "bottom-right"
      );

      this.map.addControl(
        new mapboxgl.ScaleControl({
          maxWidth: 200,
          unit: "metric",
        })
      );

      this.map.on("load", () => {
        this.currentZoom = this.map.getZoom();
        this.map.resize();
        this.addLayers();
        this.map.on("style.load", () => {
          this.addLayers();
        });
      });

      this.map.on("zoom", () => {
        if (this.mapInfoCondensed) {
          document.getElementById("zoom").innerHTML = "";
        } else {
          this.currentZoom = this.map.getZoom();
          document.getElementById("zoom").innerHTML =
            "zoom: " + JSON.stringify(this.currentZoom);
        }
      });

      this.map.on("mousemove", (e) => {
        this.currentMouseCoordinates = e.lngLat;

        if (this.mapInfoCondensed) {
          document.getElementById("coordinates").innerHTML = "";
        } else {
          document.getElementById("coordinates").innerHTML = JSON.stringify(
            e.lngLat
          );
        }
      });
    },

    showMapInfo() {
      this.mapInfoCondensed = !this.mapInfoCondensed;

      if (this.mapInfoCondensed) {
        document.getElementById("coordinates").style.display = "none";
        document.getElementById("zoom").style.display = "none";
        document.getElementById("info-icon").style.display = "block";
      } else {
        document.getElementById("coordinates").style.display = "block";
        document.getElementById("zoom").style.display = "block";
        document.getElementById("info-icon").style.display = "none";
      }
    },

    addLayers() {
      this.map.addSource("dem", {
        type: "raster-dem",
        url: "mapbox://mapbox.terrain-rgb",
      });
      this.map.addLayer(
        {
          id: "hillshading",
          source: "dem",
          type: "hillshade",
          paint: {
            "hillshade-highlight-color": "#ada282",
            "hillshade-accent-color": "#4d4222",
          },
        },
        this.firstSymbolId
      );

      this.addDatasets("Add Layers called this");
    },

    // addSource(sourceID) {
    //   try {
    //     this.map.addSource(sourceID, source);
    //   } catch (err) {
    //     if (this.replaceSource) {
    //       this.map.removeSource(this.sourceId);
    //       this.map.addSource(this.sourceId, source);
    //     }
    //   }
    // },

    removeLayer(id) {
      try {
        this.map.removeLayer(id);
      } catch (err) {
        this.$emit("layer-does-not-exist", {
          layerId: this.sourceId,
          error: err,
        });
      }
    },

    removeSource(id) {
      this.map.removeSource(id);
    },

    addStudyAreaMask(mapSources, mapLayers, studyAreas) {
      const studyAreaDatasets = [...studyAreas];

      if (studyAreaDatasets.length > 0) {
        if (studyAreaDatasets[0].mask) {
          let maskLayer = studyAreaDatasets[0].mask.mapLayer;
          // let layerTime = studyAreaDatasets[0].mask.time;

          let sourceID = maskLayer.id;

          if (!mapSources.includes(sourceID)) {
            // add the dataset source if not already in source list
            this.map.addSource(sourceID, maskLayer.source);
          }

          // add the layer source if not already in source list
          if (!mapLayers.includes(maskLayer.id)) {
            maskLayer.source = maskLayer.id;
            this.map.addLayer(maskLayer, this.firstSymbolId);
          }

          // mask id can be used to insert layers under
          return maskLayer.id;
        } else {
          {
            this.$showAlert({
              text: "There was a problem generating the study area mask, try removing and re-adding the study area.",
              type: "warning",
            });
          }

          return null;
        }
      }
    },

    addLayerMasks(mapSources, mapLayers, maskLayers, studyAreaMaskLayerID) {
      const copyMaskLayers = [...maskLayers];
      let layerIDs = [];

      if (!maskLayers.length) {
        return layerIDs;
      } else {
        copyMaskLayers.forEach((mask) => {
          let maskLayer = mask.mapLayer;
          let layerTime = mask.time;

          if (!mapSources.includes(maskLayer.id)) {
            // add the dataset source if not already in source list

            this.map.addSource(maskLayer.id, maskLayer.source);
            mapSources.push(maskLayer.id);
          }

          // add the layer if source was added
          if (!mapLayers.includes(maskLayer.id)) {
            maskLayer.source = maskLayer.id;
            this.map.addLayer(
              maskLayer,
              studyAreaMaskLayerID ? studyAreaMaskLayerID : this.firstSymbolId
            );

            layerIDs.push(maskLayer.id);

            // Set as visible if the map time intersects
            // if (this.dataSourceTimeIntersects(layerTime))
            //   maskLayer.layout.visibility = "visible";
            // else maskLayer.layout.visibility = "none";

            this.layerTimes[maskLayer.id] = layerTime;
          }
        });
      }

      return layerIDs;
    },

    clearUnusedLayers() {},

    addDatasets(caller) {
      this.updateMapTimes(caller);

      const mapStyle = this.map.getStyle();
      const mapSources = Object.keys(mapStyle.sources);
      const mapLayers = mapStyle.layers.map((lyr) => lyr.id);
      let layerIDs = [];
      let bounds;

      const studyAreas = this.datasets.filter(
        (dataset) => dataset.mapLayerPurpose === "StudyAreaMapLayer"
      );

      const layerMasks = this.queryBuilderLayerMasks.map(
        (dataset) => dataset.dataLayer
      );

      const datasets = this.datasets.filter(
        (dataset) => dataset.mapLayerPurpose === "DatasetMapLayer"
      );

      const addSingleDataset = (data, layerPositionID) => {
        for (const source of data.files) {
          const layer = { ...source.mapLayer };

          const sourceID = layer.id;

          if (!mapSources.includes(sourceID)) {
            // Do not add the tileset more than once
            this.map.addSource(sourceID, layer.source);
            mapSources.push(sourceID);
          }

          layerIDs.push(layer.id);

          if (!mapLayers.includes(layer.id)) {
            // Set as visible if the map time intersects
            if (this.dataSourceTimeIntersects(this.mapTime, source.time))
              layer.layout.visibility = "visible";
            else layer.layout.visibility = "none";

            layer.source = sourceID;

            if (
              layer.type === "fill" &&
              this.clientMapSettings.outlineAllPolygons
            )
              layer.paint["fill-outline-color"] = "rgba(0,0,0,1)";
            // insert layer below mask if present
            this.map.addLayer(layer, layerPositionID);
          }

          const extentCoordinates =
            source.extentLayer.source.geometry.coordinates[0];

          // check to make sure latitude valid (cannot be outside visible bounds or mapbox fitbounds fails)
          let lat1 = extentCoordinates[0][1];
          let lat2 = extentCoordinates[2][1];

          if (lat1 < -90) {
            lat1 = -90;
          }
          if (lat1 > 90) {
            lat1 = 90;
          }
          if (lat2 < -90) {
            lat2 = -90;
          }
          if (lat2 > 90) {
            lat2 = 90;
          }

          if (!bounds) {
            const sw = new mapboxgl.LngLat(extentCoordinates[0][0], lat1);
            const ne = new mapboxgl.LngLat(extentCoordinates[2][0], lat2);

            bounds = new mapboxgl.LngLatBounds(sw, ne);
          } else {
            const sw = new mapboxgl.LngLat(extentCoordinates[0][0], lat1);
            const ne = new mapboxgl.LngLat(extentCoordinates[2][0], lat2);

            const coords = new mapboxgl.LngLatBounds(sw, ne);

            bounds.extend(coords);
          }

          this.layerTimes[layer.id] = source.time;

          // create pop up for vector datasets that have a value field

          if (
            data.layer.TypeOfData !== "Mask" &&
            data.dataset.DatasetType === "Vector"
          ) {
            const defaultValueToShow = data.layer.ValueField
              ? [data.layer.ValueField]
              : data.layer.FilterValue
              ? [data.layer.FilterValue]
              : null;

            const layerDisplayProperties = data.layer.PopupFields;

            if (defaultValueToShow || layerDisplayProperties) {
              this.map.on("click", layer.id, (e) => {
                const filteredFields = (displayProperties, featureProperties) =>
                  Object.keys(featureProperties)
                    .filter((key) => displayProperties.includes(key))
                    .reduce((obj, key) => {
                      obj[key] = featureProperties[key];
                      return obj;
                    }, {});

                const formattedFields = (propertyList, featureProperties) => {
                  let fields = "";

                  for (const [key, value] of Object.entries(
                    filteredFields(propertyList, featureProperties)
                  )) {
                    fields += `<p><strong>${key}:</strong> ${
                      typeof value === "number" ? formatNumber(value) : value
                    }</p>`;
                  }

                  return fields;
                };

                // Change the cursor style as a UI indicator.
                new mapboxgl.Popup({ className: "mapbox-popup" })
                  .setLngLat(e.lngLat)
                  .setHTML(
                    formattedFields(
                      layerDisplayProperties
                        ? layerDisplayProperties
                        : defaultValueToShow,
                      e.features[0].properties
                    )
                  )

                  .setMaxWidth("none")
                  .addTo(this.map);
              });

              // Change the cursor to a pointer when
              // the mouse is over the states layer.
              this.map.on("mouseenter", layer.id, () => {
                this.map.getCanvas().style.cursor = "pointer";
              });

              // Change the cursor back to a pointer
              // when it leaves the states layer.
              this.map.on("mouseleave", layer.id, () => {
                this.map.getCanvas().style.cursor = "";
              });
            }
          }
        }

        return layerIDs;
      };

      // add study area
      studyAreas.forEach((data) => {
        addSingleDataset(data, this.firstSymbolId);
      });

      const studyAreaID = layerIDs.find((id) => id.startsWith("studyAreaMask"));

      // add layer masks
      layerMasks.forEach((data) => {
        addSingleDataset(
          data,
          studyAreaID !== undefined ? studyAreaID : this.firstSymbolId
        );
      });

      const layerMaskId = mapLayers.find((id) => id.startsWith("queryMask"));

      // add datasets
      datasets.forEach((data) => {
        addSingleDataset(
          data,
          layerMaskId !== undefined
            ? layerMaskId
            : studyAreaID !== undefined
            ? studyAreaID
            : this.firstSymbolId
        );
      });

      // Remove layers that are not present and remove source and associated masks
      mapLayers.forEach((id) => {
        if (id.startsWith("flowSource") && !layerIDs.includes(id)) {
          this.map.removeLayer(id);
          this.map.removeSource(id);
        }

        if (id.startsWith("queryMask") && !layerIDs.includes(id)) {
          this.map.removeLayer(id);
          this.map.removeSource(id);
          delete this.layerTimes[id];
        }
        if (id.startsWith("studyAreaMask") && !layerIDs.includes(id)) {
          this.map.removeLayer(id);
          this.map.removeSource(id);
        }
        // if (id.startsWith("flowBackground") && !layerIDs.includes(id)) {
        //   this.map.removeLayer(id);
        //   this.map.removeSource(id);
        // }
        // !layerIDs.includes(id.replace("studyAreaMask", "flowSource"))
      });

      // Zoom to extent of all layers

      if (this.studyAreaBounds) {
        this.zoomTo(this.studyAreaBounds);
      } else if (bounds) {
        this.zoomTo(bounds);
      }
    },

    findLayerFeatures(layerID, filter) {
      // Find all features in one source layer in a vector source
      const features = this.map.queryRenderedFeatures({
        layers: [layerID],
        filter: filter,
      });

      // need to make a copy of each feature to access geometry
      const featureCopies = features.map((feature) => ({
        type: feature.type,
        layer: { ...feature.layer },
        properties: { ...feature.properties },
        geometry: { ...feature.geometry },
      }));

      return featureCopies;
    },
    findSourceFeatures(sourceID, sourceLayer, filter) {
      // Find all features in one source layer in a vector source
      const features = this.map.querySourceFeatures(sourceID, {
        sourceLayer: sourceLayer,
        filter: filter,
      });

      return features;
    },
    getBounds(currentVisibleLayerFile) {
      if (currentVisibleLayerFile.mapLayer.source === "raster") {
        let bounds;
        const extentCoordinates =
          currentVisibleLayerFile.extentLayer.source.geometry.coordinates[0];
        // check to make sure latitude valid (cannot be outside visible bounds or mapbox fitbounds fails)
        let lat1 = extentCoordinates[0][1];
        let lat2 = extentCoordinates[2][1];
        if (lat1 < -90) {
          lat1 = -90;
        }
        if (lat1 > 90) {
          lat1 = 90;
        }
        if (lat2 < -90) {
          lat2 = -90;
        }
        if (lat2 > 90) {
          lat2 = 90;
        }

        const sw = new mapboxgl.LngLat(extentCoordinates[0][0], lat1);
        const ne = new mapboxgl.LngLat(extentCoordinates[2][0], lat2);
        bounds = new mapboxgl.LngLatBounds(sw, ne);

        if (bounds) return bounds;
      }
      // vector
      else {
        const extentCoordinates = currentVisibleLayerFile.stats
          .fullResolutionStats
          ? currentVisibleLayerFile.stats.fullResolutionStats.Extent
          : currentVisibleLayerFile.stats.lowResolutionStats.Extent;

        // TODO: this will search for actual features in the dataset and zoom to them.
        // const features = this.findLayerFeatures(
        //   currentVisibleLayerFile.mapLayer.id,
        //   currentVisibleLayerFile.mapLayer.filter
        // );

        // features.forEach((feature) => {
        //   const coordinates = feature.geometry.coordinates[0];

        //   coordinates.forEach((coordinate) => {
        //     if (!bounds) {
        //       bounds = new mapboxgl.LngLatBounds(
        //         coordinate[0][0],
        //         coordinate[0][0]
        //       );
        //     } else {
        //       bounds.extend(coordinate);
        //     }
        //   });
        // });

        const sw = new mapboxgl.LngLat(
          extentCoordinates[0],
          extentCoordinates[1]
        );
        const ne = new mapboxgl.LngLat(
          extentCoordinates[2],
          extentCoordinates[3]
        );
        const bounds = new mapboxgl.LngLatBounds(sw, ne);

        if (bounds) return bounds;
      }
    },
    zoomTo(extentBounds) {
      this.map.fitBounds(extentBounds, {
        padding: { top: 50, bottom: 120, left: 50, right: 50 },
      });
    },
  },
  beforeMount() {
    this.loadDefaultData();
  },
  async mounted() {
    this.initMap();

    this.authData = await Auth.currentSession();
  },

  unmounted() {
    this.map.remove();
    this.map = null;
  },
};
</script>

<style>
.map {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

#time-slider {
  position: absolute;
  width: calc(80% - 80px);
  margin-left: auto;
  margin-right: auto;
  bottom: 65px;
  left: 0;
  right: 0;
  z-index: 1;
}

.mapbox-popup {
  min-width: 5em;
  /* max-width: 12.5em; */
  font: 1em/1.5em "Roboto";
}
/* .mapboxgl-ctrl-group {
  margin-bottom: 100px !important;
} */

.mapboxgl-ctrl {
  font-family: "Open Sans", sans-serif;
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.mapboxgl-ctrl-print {
  background: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  padding: 5px;
  font-size: 14px;
  cursor: pointer;
}

.mapboxgl-ctrl-print-expand {
  position: absolute;
  top: 300px;
  right: 400px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  padding: 5px;
  font-size: 14px;
  cursor: pointer;
}
.mapboxgl-ctrl-icon {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
}
.mapboxgl-ctrl-icon:hover {
  background-color: #f0f0f0;
}

.help-button {
  position: absolute;
  bottom: 30px;
  right: 50px;
}

#info {
  display: table;
  position: relative;
  margin: 0px auto;
  word-wrap: anywhere;
  white-space: pre-wrap;
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
  background: #323232;
  opacity: 0.7;
  cursor: pointer;
}
</style>

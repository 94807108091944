<template>
  <v-app-bar flat :color="color" :dark="dark" height="65">
    <v-menu
      v-if="!isPublic"
      v-model="menu"
      transition="slide-x-transition"
      :close-on-content-click="true"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-app-bar-nav-icon v-bind="attrs" v-on="on"></v-app-bar-nav-icon>
      </template>

      <v-card max-height="80vh">
        <v-list>
          <v-list-item
            v-for="(item, i) in navItems"
            :key="i"
            :disabled="
              $route.name === item.name ||
              (item.name === 'Project' &&
                !$store.state.project.activeProject) ||
              (item.name === 'Dataset Manager' &&
                !$store.state.accountData.datasetList.datasets) ||
              (item.name === 'Expression Manager' &&
                !$store.state.accountData.expressionList.expressions) ||
              (item.name === 'Data Viewer' &&
                !$store.state.accountData.datasetList.datasets)
            "
            @click="$router.push({ name: item.name }).catch((err) => {})"
          >
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <div
      class="d-none d-sm-flex pl-sm-8"
      v-if="appName !== '' && appName !== 'ALCES Flow Development Staging'"
    >
      <img :src="require(`@/assets/img/logo-header.png`)" alt="logo" />
    </div>

    <v-toolbar-title id="app-bar-title" class="text-subtitle-2 text-sm-h6 px-4">
      {{ appName }}
    </v-toolbar-title>

    <v-toolbar-title id="app-bar-subtitle" class="text-caption px-4">
      {{ subAppName }}
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <!-- admin settings  -->
    <v-menu
      v-if="isAdmin && !isPublic"
      v-model="adminSettingsMenu"
      transition="slide-y-transition"
      :close-on-content-click="true"
      :nudge-width="200"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-account-cog</v-icon>
        </v-btn>
      </template>

      <v-card class="d-flex justify-center flex-column">
        <v-list>
          <v-list-item
            v-for="(item, i) in adminNavItems"
            :key="i"
            @click="$router.push({ name: item.name }).catch((err) => {})"
          >
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <!-- basic user settings -->
    <v-menu
      v-if="!isPublic"
      v-model="userSettingsMenu"
      transition="slide-y-transition"
      :close-on-content-click="true"
      :nudge-width="200"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-card class="d-flex justify-center flex-column">
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <img :src="require(`@/assets/img/user-avatar.jpg`)" alt="User" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ user }}</v-list-item-title>
              <v-list-item-subtitle v-if="authenticated">{{
                appName
              }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="authenticated">{{
                subAppName
              }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action class="d-flex align-center">
              <v-list-item-action-text>{{
                online ? "Online" : "Offline"
              }}</v-list-item-action-text>
              <v-icon :class="online ? 'primary--text' : 'red--text'"
                >mdi-broadcast</v-icon
              >
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>

        <v-card-actions
          v-if="$store.state.settings.authRequired && authenticated"
        >
          <GetJWT
            v-if="$store.state.settings.environment === 'Testing'"
          ></GetJWT>
          <v-spacer></v-spacer>
          <div>
            <amplify-sign-out
              button-text="Sign Out"
              :style="`color: ${color};`"
            ></amplify-sign-out>
          </div>
        </v-card-actions>
      </v-card>
    </v-menu>

    <!-- button that signs-out public user and redirects to login page -->
    <div v-if="isPublic">
      <v-btn @click="login" text class="text-capitalize">Analyst Sign In</v-btn>
    </div>
  </v-app-bar>
</template>

<script>
import GetJWT from "@/components/auth/GetJWT";
import { Auth } from "aws-amplify";

Auth.configure({
  authenticationFlowType: "USER_SRP_AUTH",
});

export default {
  name: "AppBar",

  components: {
    GetJWT,
  },

  data() {
    return {
      menu: false,
      userSettingsMenu: false,
      adminSettingsMenu: false,
    };
  },

  computed: {
    online() {
      return window.navigator.onLine;
    },

    authenticated() {
      return (
        !this.$store.state.settings.authRequired ||
        this.$store.state.account.authenticated
      );
    },
    user() {
      const user = this.$store.state.account.user;
      if (user) return user.attributes.email;
      else return this.$store.state.settings.appName;
    },
    isAdmin() {
      return this.$store.state.account.user.signInUserSession.accessToken.payload[
        "cognito:groups"
      ].some((group) => group.includes("admin"));
    },
    isPublic() {
      return this.$store.state.account.user.signInUserSession.accessToken.payload[
        "cognito:groups"
      ].every((group) => group.includes("public"));
    },
    appName() {
      return this.$store.state.settings.appName;
    },
    subAppName() {
      return this.$store.state.settings.subAppName;
    },
    routeName() {
      return this.$route.name;
    },
    color() {
      return this.$store.state.settings.appColor;
    },
    dark() {
      return this.$store.getters["settings/isDark"];
    },
    acceptedRoutes() {
      return this.$store.state.settings.acceptedRoutes;
    },

    navItems() {
      const allRoutes = this.acceptedRoutes === false;

      return this.$router.options.routes
        .filter((route) => {
          return (
            route.name &&
            (allRoutes || this.acceptedRoutes.includes(route.name))
          );
        })
        .filter((route) => !route.path.includes("/admin/"))
        .sort();
    },
    adminNavItems() {
      return this.$router.options.routes.filter((route) =>
        route.path.includes("/admin/")
      );
    },
  },

  methods: {
    login() {
      // we need to sign out a public user first.
      this.signOut();
    },

    async signOut() {
      try {
        await Auth.signOut();
      } catch (error) {
        console.log("error signing out: ", error);
      }
    },
  },
};
</script>
<style scoped>
#app-bar-title {
  white-space: normal;
  text-align: center;
}
</style>
